import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        fontSize: '30px',
        fontWeight: '500',
        animation: 'fadein 2s',
    },
    text: {
        fontSize: '18px',
        animation: 'fadein 2s',
        color: 'rgba(0,0,0,0.5)',
    },
    progress: {
        height: 8,
        maxWidth: 300,
        marginTop: 10,
        backgroundColor: 'transparent',
        border: '1px solid #E0E0E0',
        borderRadius: 20,
    }
});

const EmptyState = ({ title, text, titleStyles, showLoading = false }) => {
    const classes = useStyles();

    const gridProps = {
        container: true,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        style: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    }

    const titleStylesProp = {}
    if (titleStyles) titleStylesProp.style = titleStyles;

    return <Grid {...gridProps}>
    <Grid item>
      <Box textAlign='center'>
        <Typography {...titleStylesProp} className={classes.title}>{title}</Typography>
        {text && <Typography className={classes.text}>{text}</Typography>}
        {showLoading && <LinearProgress className={classes.progress} />}
      </Box>
    </Grid>
  </Grid>
}

EmptyState.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    titleStyles: PropTypes.object,
}

export default EmptyState;
