import React, {createContext, useEffect, useState} from 'react'

// Data
import {useLazyQuery} from '@apollo/client';

import {
    GET_MEMBERS_PATIENTS,
    GET_MEMBERS_FAMILY,
    GET_MEMBERS_STAFF,
    GET_MEMBERS_DISCHARGED_PATIENTS,
    GET_MEMBERS_DISCHARGED_FAMILY,
} from 'data/graphql/members';

export const MembersContext = createContext();
MembersContext.displayName = 'MembersContext';

const MembersContextProvider = ({children}) => {

    // State
    const [membersLoaded, setMembersLoaded] = useState(false);

    // GraphQL Queries
    const [getPatients, { 
        loading: loadingPatients, error: errorPatients, 
        data: dataPatients, refetch: refetchPatients, called: calledPatients
    }] = useLazyQuery(GET_MEMBERS_PATIENTS, {
        onCompleted: console.log('[MembersContext] GET_MEMBERS_PATIENTS successful')
    });

    const [getFamily, {
        loading: loadingFamily, error: errorFamily,
        data: dataFamily, refetch: refetchFamily, called: calledFamily
    }] = useLazyQuery(GET_MEMBERS_FAMILY, {
        onCompleted: console.log('[MembersContext] GET_MEMBERS_FAMILY successful')
    });

    const [getStaff, { 
        loading: loadingStaff, error: errorStaff, 
        data: dataStaff, refetch: refetchStaff, called: calledGetStaff,
    }] = useLazyQuery(GET_MEMBERS_STAFF, {
        onCompleted: console.log('[MembersContext] GET_MEMBERS_STAFF successful')
    });

    const [getDischargedPatients, {
        loading: loadingDischargedPatients, error: errorDischargedPatients, 
        data: dataDischargedPatients, refetch: refetchDischargedPatients,
        called: calledGetDischargedPatients,
    }] = useLazyQuery(GET_MEMBERS_DISCHARGED_PATIENTS, {
        onCompleted: console.log('[MembersContext] GET_MEMBERS_DISCHARGED_PATIENTS successful')
    });

    const [getDischargedFamily, {
        loading: loadingDischargedFamily, error: errorDischargedFamily, 
        data: dataDischargedFamily, refetch: refetchDischargedFamily, 
        called: calledGetDischargedFamily,
    }] = useLazyQuery(GET_MEMBERS_DISCHARGED_FAMILY, {
        onCompleted: console.log('[MembersContext] GET_MEMBERS_DISCHARGED_FAMILY successful')
    });
    
    useEffect(() => {
        if (!calledPatients) getPatients()
        if (!calledFamily) getFamily()
        if (!calledGetStaff) getStaff()
    }, [])

    useEffect(() => {
        if(membersLoaded) {
            getDischargedPatients()
            getDischargedFamily()
        }
    }, [membersLoaded])
    
    useEffect(() => {
        // Member data status
        const patientsLoaded = calledPatients && !loadingPatients;
        const familyLoaded = calledFamily && !loadingFamily;

        if(patientsLoaded && familyLoaded) setMembersLoaded(true);
    
    }, [calledPatients, loadingPatients, calledFamily, loadingFamily]);

    useEffect(() => {
        console.log('[MembersContext] mounted')
        return () => console.log('[MembersContext] unmounted')
    }, [])

    const refetchMembers = async () => {
        console.log('[MembersContext] refetchMembers ran')
        refetchPatients();
        refetchFamily();
        calledGetStaff ? refetchStaff() : getStaff();
    }

    const refetchDischargedMembers = async () => {
        dischargedPatients ? refetchDischargedPatients() : getDischargedPatients();
        dischargedFamily ? refetchDischargedFamily() : getDischargedFamily();
    }

    // Data Setup
    const patients = dataPatients?.getDashboardData?.patients || [];
    const family = dataFamily?.getDashboardData?.familyList || [];
    const dischargedPatients = dataDischargedPatients?.getDashboardData?.dischargedPatients || [];
    const dischargedFamily = dataDischargedFamily?.getDashboardData?.dischargedFamilyList || [];
    const staff = dataStaff?.getStaff || [];
    
    // Data Segments
    const activeMemberFilter = member => member.status === 'ACTIVE' || member.status === 'ACTIVATED';
    const intakeMemberFilter = member => member.status === 'PRE_ENROLLED' || member.status === 'IMPORTED';
    const monitoringMemberFilter = member => member.subType?.code === 'MONITORING_ONLY';
    const activePatients = patients?.filter(activeMemberFilter);
    const activeFamily =  family?.filter(activeMemberFilter);
    const intakePatients = patients?.filter(intakeMemberFilter);
    const intakeFamily = family?.filter(intakeMemberFilter);
    const monitoringPatients = activePatients?.filter(monitoringMemberFilter)

    const contextValues = {
        // General
        refetchMembers,
        refetchStaff,
        refetchDischargedMembers,

        // Patients
        loadingPatients,
        calledPatients,
        errorPatients: !!errorPatients,        
        patients,
        patientsCount: patients?.length,
        activePatients,
        activePatientCount: activePatients?.length,
        intakePatients,
        intakePatientCount: intakePatients?.length,
        monitoringPatients,
        monitoringPatientCount: monitoringPatients?.length,
        dischargedPatients,
        loadingDischargedPatients,
        calledGetDischargedPatients,
        errorDischargedPatients: !!errorDischargedPatients,

        // Family
        loadingFamily,
        calledFamily,
        errorFamily: !!errorFamily,
        family,
        familyCount: family?.length,
        activeFamily,
        activeFamilyCount: activeFamily?.length,
        intakeFamily,
        intakeFamilyCount: intakeFamily?.length,
        dischargedFamily,
        loadingDischargedFamily,
        calledGetDischargedFamily,
        errorDischargedFamily: !!errorDischargedFamily,

        // Staff
        loadingStaff,
        calledGetStaff,
        errorStaff: !!errorStaff,
        staff,
        staffCount: staff?.length,
    }

    return (
        <MembersContext.Provider value={contextValues}>
            {children}
        </MembersContext.Provider>
    )
}

export default MembersContextProvider;


// Utils: Module Functions
const getMemberById = (members = [], id, key = '_id') => {
    const memberData = members?.find((member) => member?.[key] === id);
    return memberData
}

// Setup flags for membersOnly (patient/family)
const getMembersById = ({members, id, memberType, discharged = false}) => {
    if (memberType) {
        console.log('getMembersById for memberType', memberType)
        if (discharged) return getMemberById(members[memberType]?.discharged, id)
        return getMemberById(members[memberType]?.active, id)
    }
    
    if (discharged) {
        console.log('getMembersById for discharged')
        const dischargedMembersMerged = [...members.patient.discharged, ...members.family.discharged]
        return getMemberById(dischargedMembersMerged, id)
    }
    const membersMerged = [...members.patient.active, ...members.family.active, ...members.staff.active]
    console.log('getMembersById running', membersMerged, id)
    return getMemberById(membersMerged, id)
}

export {getMemberById, getMembersById}