import React from "react";
import {
  Box,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./SmallList.module.scss";
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

const Item = ({ 
  item,
  disabled,
  showContact, 
  isCareTeam, 
  isFamily,
  isSoberCircle,
  memberType
}) => {

  // Data
  const { _id, memberId, firstName, lastName, image, emrPic, relation, address, phone, email, locationName } = item;
  const lineFormat = data => `${!!data ? data : ''}`;
  const displayTeam = lineFormat(locationName);
  const displayRelation = lineFormat(relation?.name);
  const displayAddress = lineFormat(address?.city);
  const displayPhone = !!phone ? `Phone: ${lineFormat(phone)}` : '';
  const displayEmail = lineFormat(email);

  const additionalInfo = [
    ...showContact ? [displayPhone] : [],
    ...isCareTeam ? [displayTeam, displayAddress, displayPhone] : [],
    ...isFamily ? [displayRelation, displayPhone, displayEmail] : [],
    ...isSoberCircle ? [displayAddress] : []
  ]

  const avatarProps = {
    _id: memberId ? memberId : _id, 
    type: memberType || null,
    firstName, 
    lastName,
    image: image || emrPic,
    disabled: disabled,
    size: 'small'
  }
  
  return (
    <ListItem className={styles.item}>
      <div style={{marginRight: 15}}>
        <MemberAvatar {...avatarProps} />
      </div>
      <Box className={styles.main}>
        <ListItemText className={styles.name}>
          {firstName} {lastName}
        </ListItemText>
        <ListItemText className={styles.addition}>
          <ul>
            {additionalInfo.map((info, i) => (
              <li key={`info-${i}`}>{info}</li>
            ))}
          </ul>
         </ListItemText>
      </Box>
    </ListItem>
  );
};

Item.propTypes = {
  data: PropTypes.array,
  additionInfo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default Item;
