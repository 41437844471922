import React, { useState, useEffect } from 'react';
import { Messages } from 'config/settings';

import MemberList from 'components/Member/List/List';
import useMembersContext from 'context/Members/useMembersContext';
import useAccountContext from 'context/Account/useAccountContext';
import { processMembers, sortMembers } from 'common/helpers/memberHelpers';

const FamilyList = (props) => {

  const { 
    activeFamily: data,
    loadingFamily: dataLoading,
    errorFamily: dataError,
  } = useMembersContext();

  const {locations} = useAccountContext();

  const [processedData, setProcessedData] = useState([])

  useEffect(() => {
    if(data?.length && locations?.length) {
      const sortedData = sortMembers(data, 'firstName')
      const processed = processMembers(sortedData, locations)
      setProcessedData(processed)
    }
  }, [data, locations])

  // Render
  const headerProps = {
    listBreadcrumbs: [{
      url: "/family",
      name: "Family",
      disabled: true,
    }]
  }

  const filterFields = {
    familyName: {
      label: 'Family Name',
      initialValue: [],
      placeholder: '',
      fieldPath: 'lastName',
      fieldValPath: '',
    },
    // activated: {
    //   label: 'Activated',
    //   initialValue: [],
    //   placeholder: '',
    //   fieldPath: 'activatedAccount',
    //   fieldValPath: '',
    // },
    status: {
      label: 'Status',
      initialValue: [],
      placeholder: 'Choose a status',
      fieldPath: 'status',
      fieldValPath: '',
    },
  }

  const filterToggles = [{
    // active: true,
    filterCondition: false,
    title: 'Pending Activation',
    filterPath: 'activatedAccount',
  },
  {
    // active: true,
    filterCondition: 'FLAGGED_FOR_DISCHARGE',
    title: 'Flagged for discharge',
    filterPath: 'status',
  }
]

  const filtersProps = {
    data: processedData,
    hasSearch: true,
    fields: filterFields,
    filterToggles: filterToggles,
    searchLocalBy: ['firstName', 'lastName', "mrn"],
    initialFilterCondition: {
      filterCondition: 'FLAGGED_FOR_DISCHARGE',
      condition: 'ne',
      filterPath: 'status',
    },
  }

  const memberListProps = {
    data: processedData,
    dataLoading,
    dataError,

    headerProps,
    filtersProps,
    baseUrl: '/family',

    emptyData: {
      title: Messages.FAMILY_LIST_EMPTY_TITLE,
      text: Messages.FAMILY_LIST_EMPTY_TEXT,
    },

    emptySearch: {
      title: Messages.FAMILY_LIST_SEARCH_EMPTY_TITLE,
      text: Messages.FAMILY_LIST_SEARCH_EMPTY_TEXT,
    },
    showRelated: true,
  }

  return <MemberList {...memberListProps} />;
}


export default FamilyList;
