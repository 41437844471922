import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

// Account Context: User Data
import useAccountContext from 'context/Account/useAccountContext'

export const GuideContext = createContext();

const GuideContextProvider = ({ children, value }) => {
    
    //console.log('[GuideContext] Running')
    
    // Context State
    const [launchedGuide, setLaunchedGuide] = useState(false);
    const [isSetupComplete, setSetupComplete] = useState(false);
    const [welcomeOpen, setWelcomeOpen] = useState(false);
    const [setupGuideOpen, setSetupGuideOpen] = useState(false);
    const [setupProgress, setSetupProgress] = useState(0);
    const [completedSteps, setCompletedSteps] = useState(0);
    const [stepStatus, setStepStatus] = useState();
    const [nextStep, setNextStep] = useState();
    const [activeStep, setActiveStep] = useState();
    const [backdropOn, setBackdropOn] = useState(false);
    
    // Setup Guide State
    const [hasAvatar, setHasAvatar] = useState(false);
    const [hasWebexLink, setHasWebexLink] = useState(false);
    const [hasMobileNumber, setHasMobileNumber] = useState(false);
    const [hasWorkingHours, setHasWorkingHours] = useState(false);
    const [hasConnectedGoogle, setHasConnectedGoogle] = useState(false);
    const [hasConnectedOutlook, setHasConnectedOutlook] = useState(false);
        
    // Redux Data
    const {user: currentUser} = useAccountContext()
    
    // Hooks
    const navigate = useNavigate();

    // Data Sets
    const setupGuideConfig = { 
        name: 'setup-guide',
        hasGamification: true,
        guideTitle: "Setup Guide",
        welcomeTitle: "Welcome to your Setup Guide",
        welcomeMessage: "We'll get you up and running in 5 steps.",
        exitButton : "I'll do this later",
        startButton: "Great, let's do this!",
        continueButton: "Continue to Finish Step",
    };

    const setupGuideCheckList = [
        {  
            name: 'profile-avatar',
            checkIf: hasAvatar,
            checkUpdate: setHasAvatar,
            checkField: `image`,
            title: "Upload Avatar Photo",
            description: "Make inRecovery more personal (and prettier).",
            location: 'Account Settings > Profile',
            link: '/account',
        },
        { 
            name: 'profile-phone',
            checkIf: hasMobileNumber,
            checkUpdate: setHasMobileNumber,
            checkField: `phone`,
            title: "Verify Mobile Number",
            description: "This will help us notify you on urgent matters.",
            location: 'Account Settings > Profile',
            link: '/account',
        },
        { 
            name: 'outlook-calendar',
            checkIf: hasConnectedOutlook,
            checkUpdate: setHasConnectedOutlook,
            checkField: `account.hasOutlookToken`,
            title: "Connect Outlook Calendar",
            description: "Required to sync your Caron Calendar with inRecovery.",
            location: 'Account Settings > External Calendars',
            link: '/account/externalCalendars',
            callBackURL: true,
        },
        { 
            name: 'working-hours',
            checkIf: hasWorkingHours,
            checkUpdate: setHasWorkingHours,
            checkField: `account.openingHours`,
            title: "Set Working Hours",
            description: "Here you specify your normal working hours.",
            location: 'Account Settings > Working Hours',
            link: '/account/workingHours',
        },
        {             
            name: 'webex-link',
            checkIf: hasWebexLink,
            checkUpdate: setHasWebexLink,
            checkField: `webexLink`,
            title: "Verify Webex Link",
            description: "Enter your Caron Webex link for faster bookings.",
            location: 'Account Settings > Profile',
            link: '/account',
        },
    ];

    const totalSteps = setupGuideCheckList?.length;
    const progressMessage = 
        (setupProgress === 100) ? `All done! 🎉 ` : 
        (setupProgress > 50) ? `Almost there 👍` : `To Do: `
    
    // Handlers
    const handleRouteTo = (route) => navigate(route);

    const handleCallback = () => {
        setSetupGuideOpen(true)
    }

    // Initialize
    useEffect(()=> {
        
         // Check Step Status
         const doneAvatar = !!currentUser?.image;
         const doneMobileNumber = !!currentUser?.phone;
         const doneConnectedOutlook = !!currentUser?.account.hasOutlookToken;
         const doneConnectedGoogle = !!currentUser?.account.hasGoogleToken;
         const doneWorkingHours = !!currentUser?.account.openingHours;
         const doneWebexLink = !!currentUser?.webexLink;
 
         // Update State
         setHasAvatar(doneAvatar);
         setHasMobileNumber(doneMobileNumber);
         setHasConnectedOutlook(doneConnectedOutlook);
         setHasConnectedGoogle(doneConnectedGoogle);
         setHasWorkingHours(doneWorkingHours);
         setHasWebexLink(doneWebexLink);

        // Note: Follow list order
        // To Do: Make this dynamic to avoid human error
        const guideTaskStatus = [
            doneAvatar,
            doneMobileNumber,
            doneConnectedOutlook,
            doneWorkingHours,
            doneWebexLink,
        ]

        // const guideTaskStatus = setupGuideCheckList.map(i => { 
        //     // TODO: check if nested object
        //     const taskStatus = !!currentUser[i.checkField];
        //     i.checkUpdate(taskStatus);
        //     return taskStatus;
        // });
        const completedTasks = guideTaskStatus.filter(Boolean).length;
        const nextStep = guideTaskStatus.indexOf(false);
         
        setNextStep(nextStep);
        setStepStatus(guideTaskStatus);
        setCompletedSteps(completedTasks);
        setSetupProgress((completedTasks/totalSteps)*100);

        // console.log(
        //     '[GuideContext] Status',
        //     ' \n hasAvatar: ', hasAvatar,
        //     ' \n hasMobileNumber: ', hasMobileNumber,
        //     ' \n hasConnectedOutlook: ', hasConnectedOutlook,
        //     ' \n hasWorkingHours: ', hasWorkingHours,
        //     ' \n hasWebexLink: ', hasWebexLink,
        //     ' \n stepStatus', stepStatus,
        //     ' \n nextStep', nextStep,
        //     ' \n completedSteps', completedSteps,
        //     ' \n setupProgress', setupProgress,
        // )

    },[welcomeOpen, currentUser])

    useEffect(() => {
        setSetupComplete(setupProgress === 100);
    }, [setupProgress])
    
    useEffect(() => {
        //console.log('[GuideContext] activeStep: ', activeStep)
    }, [activeStep])

    const contextValues = {
        setupGuideConfig,
        setupGuideCheckList,
        totalSteps,
        progressMessage,
        handleRouteTo,
        isSetupComplete, setSetupComplete,
        launchedGuide, setLaunchedGuide,
        welcomeOpen, setWelcomeOpen,
        setupGuideOpen, setSetupGuideOpen,
        setupProgress, setSetupProgress,
        completedSteps, setCompletedSteps,
        stepStatus, setStepStatus,
        nextStep, setNextStep,
        activeStep, setActiveStep,
        backdropOn, setBackdropOn,
    }

    return(
        <GuideContext.Provider value={contextValues}>
            { children }
        </GuideContext.Provider>
    )

};

export default GuideContextProvider;
