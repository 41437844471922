import { handleActions } from 'redux-actions';
import * as actions from '../actions';
import _ from 'lodash';

const screens = {
  forgotPass: 'FORGOTPASSWORD',
  resetPass: 'RESETPASSWORD',
}

const initialState = {
  codeError: null,
  codeLoading: false,
  codeSuccessMessage: '',
  codeIsSent: false,
  codeSentEmail: '',

  resetError: null,
  resetLoading: false,
  resetSuccessMessage: '',

  currentScreen: screens.forgotPass,
};

export default handleActions({

  // Reset
  [actions.resetPasswordReset]: (state, action) => ({
    ...state, 
    codeError: null,
    codeLoading: false,
    codeSuccessMessage: '',
    resetError: null,
    resetLoading: false,
    resetSuccessMessage: '',
  }),

  // Code Request
  [actions.requestCodeStarted]: (state, action) => ({
    ...state, 
    codeError: null,
    codeLoading: true,
    codeSuccessMessage: '',
    currentScreen: screens.forgotPass,
  }),
  [actions.requestCodeSuccess]: (state, action) => ({
    ...state, 
    codeError: null,
    codeIsSent: true,
    codeLoading: false,
    currentScreen: screens.resetPass,
    codeSentEmail: action.payload.email,
    codeSuccessMessage: _.get(
      action, 
      ['payload', 'data', 'CodeDeliveryDetails', 'Destination'], 
      'your email'
    ),
  }),
  [actions.requestCodeError]: (state, action) => ({
    ...state, 
    codeLoading: false,
    codeSuccessMessage: '',
    codeError: _.get(action, ['payload', 'message'], 'Request code error'),
  }),

  // Reset Submit
  [actions.resetPasswordStarted]: (state, action) => ({
    ...state,
    resetError: null,
    resetLoading: true,
    resetSuccessMessage: '',
  }),
  [actions.resetPasswordSuccess]: (state, action) => ({
    ...state,
    resetError: null,
    resetLoading: false,
    resetSuccessMessage: 'Password was successfully reset',
  }),
  [actions.resetPasswordError]: (state, action) => ({
    ...state,
    resetLoading: false,
    resetSuccessMessage: '',
    resetError: _.get(action, ['payload', 'message'], 'Reset password error'),
  }),

}, initialState);
