import React, {useState} from 'react';
import { EDIT_PATIENT, GET_FULL_PATIENT_INFO } from 'data/graphql/patients';
import { Grid, Button, Box } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import TextField from 'common/components/InputFields/TextField/TextField';
import Datepicker from 'common/components/InputFields/KeyboardDatepicker/Datepicker';
import * as Yup from 'yup';
import RadioButton from 'common/components/InputFields/Radio/Radio';
import RadioGroup from 'common/components/InputFields/Radio/RadioGroup';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import styles from './PatientEdit.module.scss';
import FormikCheckbox from 'common/components/InputFields/FormikCheckbox/FormikCheckbox';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import AvatarDialog from 'common/components/AvatarDialog/AvatarDialog'
import { fuzzyDateToMoment } from 'common/helpers/timeHelpers';
import { FormDevTools } from 'common/components/Formik/FormDevTools';
import PhoneInputField from 'common/components/InputFields/PhoneInput/PhoneInputField';
import { phoneValidator, emailValidator } from 'common/helpers/validations';

// Context
import { useMemberStateContext, useMemberUpdaterContext } from 'context/Member/useMemberContext';

const PatientProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Please enter a longer First Name')
    .max(50, 'Please enter a short First Name')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Please enter a longer Last Name')
    .max(50, 'Please enter a short Last Name')
    .required('Required'),
  phone: Yup.string().test('phone', 'Invalid phone number', val =>
    phoneValidator(val)
  ),
  email: emailValidator('email', 'Email is not valid'),
  appleId: emailValidator('appleId', 'Apple ID has to be a valid email ').nullable(),
  googleId: emailValidator('googleId', 'Google ID has to be a valid email').nullable(),
});

const PatientEditProfile = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Context State
  const {memberData: patient} = useMemberStateContext(); 
  const {patientRefetch} = useMemberUpdaterContext();

  // State
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarFile, setAvatarFile] = useState();
  
  // Data
  const [editPatient, editPatientState] = useMutation(EDIT_PATIENT, {
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient?._id } },
    ],
    onError: () => enqueueSnackbar('Error Updating', { variant: 'error' }),
    onCompleted: () => {
      patientRefetch();
      enqueueSnackbar('Profile Updated', { variant: 'success' });
    },
  });

  // Handlers
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleAvatarSubmit = result => {
    setAvatarPreview(result.content)
    setAvatarFile(result)
    handleModalClose()
    console.log('avatar ready to submit', result)
  }

  const handlePatientProfileEdit = data => {
    console.log('handlePatientProfileEdit', data);
    const patientData = {
      patient: {
        _id: patient?._id,
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: data.birthDate,
        highProfile: data.highProfile,
        nickName: data.nickName,
        phone: data.phone,
        gender: data.gender,
        address: {
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: {
            code: 'US',
            name: 'United States of America',
          },
        },
        deviceType: data.deviceType,
        appleId: data.deviceType === 'ios' ? data.appleId : null,
        googleId: data.deviceType === 'android' ? data.googleId : null,
        ...(!patient?.activatedAccount ? { email: data.email } : {}),
      },
    };
    editPatient({ variables: patientData });
  };

  const avatarProps = {
    _id: patient?._id,
    firstName: patient?.firstName,
    lastName: patient?.lastName,
    image: patient?.avatarPreview || patient?.image || patient?.emrPic,
    type: patient?.type,
    label: `${patient?.firstName} ${patient?.lastName}`,
    showLabel: true,
    disabled: true,
    size: 'xlarge'
  };

  return (
    <Grid className={styles.wrapper}>
      <Grid container className={styles.module_edit}>
        <Formik
          initialValues={{
            firstName: patient?.firstName,
            lastName: patient?.lastName,
            birthDate: patient?.birthDate
              ? fuzzyDateToMoment(patient?.birthDate)
              : '',
            gender: patient?.gender,
            email: patient?.email,
            phone: patient?.phone,
            highProfile: patient?.highProfile,
            nickName: patient?.nickName,
            deviceType: patient?.deviceType,
            appleId: patient?.appleId,
            googleId: patient?.googleId,
            street1: _.get(patient, 'address.street1', ''),
            street2: _.get(patient, 'address.street2', ''),
            city: _.get(patient, 'address.city', ''),
            country: 'United States of America',
            state: _.get(patient, 'address.state', ''),
            zip: _.get(patient, 'address.zip', ''),
          }}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={PatientProfileSchema}
          onSubmit={handlePatientProfileEdit}
        >
          {({ values, errors }) => (
            <Form>
              <Grid container item xs={12} className={styles.wrapper}>
                <Grid item xs={3}>
                  <div className={styles.avatar_wrapper}>
                    <MemberAvatar {...avatarProps} />

                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleModalOpen}
                    >
                      Change Photo
                    </Button>

                    <AvatarDialog
                      open={modalOpen}
                      handleClose={handleModalClose}
                      handleSubmit={handleAvatarSubmit}
                    />
                  </div>
                </Grid>

                <Grid
                  container
                  item
                  xs={9}
                  direction="column"
                  justify="space-between"
                  className={styles.container}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mb={2} className={styles.section_title}>
                        Patient Details
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label
                        className={styles.section_label}
                        htmlFor="firstName"
                      >
                        First Name
                      </label>
                      <Field name="firstName" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label
                        className={styles.section_label}
                        htmlFor="lastName"
                      >
                        Last Name
                      </label>
                      <Field name="lastName" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label
                        className={styles.section_label}
                        htmlFor="nickName"
                      >
                        Alias
                      </label>
                      <Field name="nickName" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label} htmlFor="date">
                        Date of Birth
                      </label>
                      <Field name="birthDate" component={Datepicker} openTo= {"year"}
                        views= {["year", "month", "date"]}
                        format={"YYYY-MM-DD"}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label} htmlFor="Sex">
                        Sex
                      </label>
                      <Field component={RadioGroup} name="gender" row>
                        <Field
                          name="gender"
                          type="radio"
                          value="f"
                          label="Female"
                          component={RadioButton}
                        />
                        <Field
                          name="gender"
                          type="radio"
                          value="m"
                          label="Male"
                          component={RadioButton}
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field
                        name="highProfile"
                        component={FormikCheckbox}
                        label="High Profile"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box className={styles.section_title} mb={2}>
                        Contact Info
                      </Box>
                    </Grid>

                    <Grid item xs={10} md={6}>
                      <label className={styles.section_label} htmlFor="email">
                        Email
                      </label>
                      <Field
                        name="email"
                        component={TextField}
                        disabled={!!patient?.activatedAccount}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label} htmlFor="phone">
                        Phone
                      </label>
                      <Field name="phone" component={PhoneInputField} />
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                      <Box className={styles.section_title} mb={2}>
                        Mobile Device Type
                      </Box>
                    </Grid>

                    <Grid item xs={10} md={6}>
                      <Field row component={RadioGroup} name="deviceType">
                        <Field
                          name="deviceType"
                          type="radio"
                          value="ios"
                          label="iOS"
                          component={RadioButton}
                        />
                        <Field
                          name="deviceType"
                          type="radio"
                          value="android"
                          label="Android"
                          component={RadioButton}
                        />
                        <Field
                          name="deviceType"
                          type="radio"
                          value="unknown"
                          label="Unknown"
                          component={RadioButton}
                        />
                      </Field>
                    </Grid>

                    {values.deviceType === 'ios' && (
                      <Grid item xs={12} md={6}>
                        <label
                          className={styles.section_label}
                          htmlFor="appleId"
                        >
                          Apple ID
                        </label>
                        <Field name="appleId" component={TextField} />
                      </Grid>
                    )}
                    {values.deviceType === 'android' && (
                      <Grid item xs={12} md={6}>
                        <label
                          className={styles.section_label}
                          htmlFor="googleId"
                        >
                          Google ID
                        </label>
                        <Field name="googleId" component={TextField} />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Box className={styles.section_title} mb={2}>
                        Address
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label}>
                        Address Line 1
                      </label>
                      <Field name="street1" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label}>
                        Address Line 2
                      </label>
                      <Field name="street2" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label}>City</label>
                      <Field name="city" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label}>State</label>
                      <Field name="state" component={TextField} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label}>Postal</label>
                      <Field name="zip" component={TextField} width={517} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className={styles.section_label} htmlFor="Country">
                        Country
                      </label>
                      <Field
                        name="country"
                        component={TextField}
                        disabled
                        width={247}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button type="submit" color="primary" variant="contained">
                        Update
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <FormDevTools values={values} errors={errors} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PatientEditProfile;
