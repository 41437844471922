import { Auth } from 'aws-amplify';

import * as types from './types';

import * as AppActions from "./globalActions";

import moment from 'moment';

export const onLogin = (userName, password) => dispatch => {
  dispatch({
    type: types.ON_AUTH_START,
  });

  Auth.signIn(userName, password)
    .then(signInResponse => {
      console.log('Signed in user => ', signInResponse);

      if (signInResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch({
          type: types.ON_CHALLENGE_REQUESTED,
          payload: signInResponse,
        });
      } else {
        dispatch({
          type: types.ON_AUTH_SUCCESS,
          signInResponse,
        });
      }

    })
    .catch(error => {
      console.warn(`notification permissions denied`, error);
      dispatch({
        type: types.ON_AUTH_FAIL,
        payload: error
      });
    });
};

// export const refreshToken = (restartTimer) => async (dispatch, getState) => {
//   try {
//     const cognitoUser = await Auth.currentAuthenticatedUser();
//     const { refreshToken } = await Auth.currentSession();
//     console.log(`%c 👌 REFRESH COGNITO TOKEN`, "color:blue")

//     cognitoUser.refreshSession(refreshToken, (e, session) => {
//       console.log(`%c 👌 REFRESH COGNITO TOKEN ${session}, error ${e}`, "color:blue");
//       console.log("session", session)
//       if (e) {
//         console.error('Unable to refresh session', e);

//         dispatch(logOut())
//         return
//       }
//       // dispatch({
//       //   type: types.REFRESH_TOKEN_SUCCESS,
//       //   payload: session,
//       // })
//       if (restartTimer) {
//         const cognitoTokenExpiryCheckerMinutes = getState()?.dashboard?.dashboardData?.organization?.setup?.find(s => s.name === "cognitoTokenExpiryCheckerMinutes")?.value || 15;
//         restartTimer(moment(session.idToken.payload.exp, 'X').subtract(cognitoTokenExpiryCheckerMinutes, 'minutes'));
//       }

//     })
//   } catch (e) {
//     console.error('Unable to refresh token', e);

//     dispatch(logOut())
//   }
// }

// export const logOut = () => dispatch => {
//   console.log(`%c 👌 LOGOUT`, "color:red")
//   dispatch({
//     type: types.ON_AUTH_REQUEST,
//   });

//   const onComplete = () => {
//     dispatch({
//       type: types.ON_AUTH_SIGN_OUT,
//     });
//     dispatch({
//       type: AppActions.RESET_STORE,
//     });

//     window.location = '/'
//   }

//   Auth.signOut({ global: true })
//     .then(onComplete)
//     .catch(err => {
//       console.log('Signed out global error -> ', err)

//       Auth.signOut()
//         .then(onComplete)
//         .catch(err => {
//           console.log('Signed out error -> ', err)

//           onComplete()
//         })
//     })
// };

// export const checkCurrentSession = () => dispatch => {
//   dispatch({
//     type: types.ON_AUTH_REQUEST,
//   });

//   Auth.currentSession()
//     .then(session => {
//       console.log('session => ', session);
//       const authenticated = session && true;
//       dispatch({
//         type: types.ON_AUTH_CURRENT_SESSION,
//         session,
//         authenticated,
//       });
//     })
//     .catch(err => {
//       console.warn('No logged in user when networking via Apollo', err);
//       dispatch({
//         type: types.ON_AUTH_FAIL,
//         session: null,
//       });
//     });
// };

export const acceptAgreement = () => dispatch => {
  dispatch({
    type: types.ACCEPT_TERMS_AND_CONDITIONS_AGREEMENT,
  });
};

export const onPasswordChallenge = (password, cognitoUser) => dispatch => {
  dispatch({
    type: types.ON_CHALLENGE_STARTED,
  });

  Auth.completeNewPassword(cognitoUser, password, [])
    .then((signInResponse) => {
      console.log("! Auth.completeNewPassword", signInResponse);
      dispatch({
        type: types.ON_AUTH_SUCCESS,
        signInResponse,
      });
    })
    .catch(error => {
      console.warn(`notification permissions denied ${error}`);
      dispatch({
        type: types.ON_AUTH_FAIL,
        payload: error
      });
    });

};
