import { combineReducers } from 'redux';
import authentication from './authentication'
import forgotPassword from './forgotPassword';
import dashboard from './dashboard';
import scheduler from './scheduler';
import appointmentBooker from './appointmentBooker';
import notes from './notes';
import MyAccount from './myAccount';
import PatientEdit from './patientEdit';
import notifications from './notifications'
import PatientRelations from './patientRelations'
import {RESET_STORE} from "../actions/globalActions";

// v2
// import subscribeChannel from 'redux/reducers/subscribeChannel.reducer';
import memberModule from 'redux/reducers/memberModule.reducer';
import udsLabs from 'redux/reducers/udsLabs.reducer';

const appReducer = combineReducers({
    authentication,
    forgotPassword,
    dashboard,
    scheduler,
    appointmentBooker,
    notes,
    MyAccount,
    PatientEdit,
    PatientRelations,
    notifications,
    // subscribeChannel,
    memberModule,
    udsLabs,
});

const initialState = appReducer({}, {})

export default (state, action) => {
    if (action.type === RESET_STORE) {
        state = {...initialState}
    }

    return appReducer(state, action)
}