import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Router
import { Link } from 'react-router-dom';

// Styles
import cn from 'classnames';
import styles from './MemberAvatar.module.scss';

// Components
import { Box } from '@material-ui/core';
import UserAvatar from './UserAvatar';

// Data
import { ChatContext } from 'context/Chat/Chat';

// Routes (Temporary)
const routes = {
  patient: '/patients',
  family: '/family',
  staff: '/staff',
}

const MemberAvatar = ({
  _id = "", 
  firstName = "", 
  lastName = "", 
  image, 
  type = "member",
  size = 'large',
  label,
  showLabel = false,
  showIndicator = true,
  disabled,
  isOnlineProp,
  style,
  ...restProps
}) => {
  
  // Data
  const {onlineUsers} = useContext(ChatContext);
  const isOnline = isOnlineProp || onlineUsers?.indexOf(_id?.toString()) !== -1;
  const userType = type === 'careProvider' ? 'staff' : type;
  const defaultLabel = `${firstName} ${lastName}`;

  // Setup
  const baseRoute = routes[userType];

  const RenderAvatar = () => (
    <>
      <UserAvatar
        src={image}
        firstName={firstName}
        lastName={lastName}
        size={size}
        alt={`${firstName}'s avatar`}
      />
      {isOnline && showIndicator ? <Box className={styles.indicator}/> : null}
    </>
  );

  return (
    <>
      <Box
        className={cn(styles.avatar_box, restProps.className, {
          [styles.xlarge_box]: size === 'xlarge',
          [styles.medium_box]: size === 'medium' || size === 'small2',
          [styles.small_box]: size === 'small', 
          [styles.xsmall_box]: size === 'xsmall' || size === 'xxsmall'
        })}
      >
      { disabled 
        ? <RenderAvatar />
        : <Link to={`${baseRoute}/${_id}`}><RenderAvatar /></Link>
      }
      </Box>
      {showLabel && <div className={styles.display_name}>{label ? label : defaultLabel}</div>}
    </>
  );
};

MemberAvatar.propTypes = {
  // Avatar Data
  _id: PropTypes.oneOfType(['string', 'number']),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  type: PropTypes.oneOf(['patient','family','staff','careProvider', 'member']),
  image: PropTypes.string,
  // Settings
  disabled: PropTypes.bool,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showIndicator: PropTypes.bool,
  isOnlineProp: PropTypes.bool,
  size: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'small2', 'medium', 'large', 'xlarge']),
};

export default MemberAvatar;
