import React from 'react';

// Context
import useGuideContext from 'context/Guides/useGuideContext';

// Material UI
import { 
  Typography, 
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import { CheckListItem } from 'common/components/Support/SetupCheckList';
import SetupProgress from 'common/components/Support/SetupProgress';
import CompletedGuide from './CompletedGuide';
import Fireworks from './Fireworks';

const SetupGuideBox = (props) => {

  // Context Data
  const { 
    isSetupComplete,
    launchedGuide,
    totalSteps, 
    progressMessage, 
    handleRouteTo,
    setWelcomeOpen,
    setSetupGuideOpen,
    completedSteps,
    setupProgress,
    setActiveStep,
    setupGuideConfig,
    setupGuideCheckList,
  } = useGuideContext();

  // Hooks
  const classes = useStyles(props);

  // Internal Data
  const guideConfig = setupGuideConfig;
  const guideList = setupGuideCheckList;

  // Handlers
  const onClose = () => {
    setWelcomeOpen(true)
    setSetupGuideOpen(false)
  }

  const handleGoToTask = (item, link) => {
    setActiveStep(item)
    handleRouteTo(link)
  }

  const handleFinish = () => {
    setWelcomeOpen(false)
    setSetupGuideOpen(false)
    handleRouteTo('/dashboard')
  }

  // Component Props
  const setupProgressProps = {
    setupProgress,
    progressMessage,
    completedSteps,
    totalSteps,
  }
  
  const fireworksProps = {
    fireIt: launchedGuide,
    conditionCheck: isSetupComplete
  }

  return (
  <div className={classes.guideBox}>
    <div className={classes.titleBar}>
      <Typography variant="h6">{guideConfig.guideTitle}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
      ) : null}
    </div>

    { isSetupComplete 
      ? 
      <>
        <CompletedGuide handleFinish={handleFinish} />
        {guideConfig.hasGamification && <Fireworks {...fireworksProps} /> }
      </>
      :
      <>
        <div className={classes.guideBoxList}>
        <ul>
          {guideList.map((item, i) => {
          const {checkIf, title, link } = item;
          return <li className={classes.guideBoxItem} onClick={() => handleGoToTask(i, link)} key={i}>
              <CheckListItem 
                checkIf={checkIf}
                title={title}
                link={link}
                showLink
              />
            </li>
          })}
          </ul>
        </div>

        <div className={classes.progress}>
          <SetupProgress {...setupProgressProps} />
        </div>
      </>
    }
    
  </div>
  )
  
}

export default SetupGuideBox;


const useStyles = makeStyles(theme => ({
  guideBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed',
    left: 20, 
    bottom: 15,
    height: props => props.height || 250,
    width: props => props.width || 250,
    padding: 20,
    zIndex: 99999,
    background: '#fff',
    borderRadius: 10,
    border: '1px solid #ccc',
    boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
    marginLeft: props => props.enabled ? 0 : -500,
    transition: 'margin-left 350ms ease 0ms',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.75),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  guideBoxList: {
    display: 'flex', 
    flex: 1, 
    marginTop: 25
  },
  guideBoxItem: {
    margin: '0 0 15px 0'
  },
  icon: {
    '& svg': {
      height: '140px',
      width: '140px',
    },
  },
  progress: {
    width: '100%', 
    marginBottom: 10 
  }
}));