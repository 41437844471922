import {gql} from "@apollo/client";

export const GET_TERAPIST_AVAIBILITY = gql`
query getTherapistAvailability(
  $startDate: DateTime!
  $endDate: DateTime!
  $duration: Int
) 
{
  getTherapistAvailability(startDate: $startDate, endDate: $endDate, duration: $duration) {
    day
    dayOfWeek
    slots {
      startTime
      endTime
    }
  }
}`
  