import React, {useState} from 'react';

// Material UI
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// Formik
import { Formik, Form, Field, FieldArray } from 'formik'
import { 
  TextField,
  CheckboxWithLabel,
} from 'formik-material-ui';
import * as Yup from 'yup';
import { FormDevTools } from "common/components/Formik/FormDevTools";

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

// GraphQL
import { useMutation } from '@apollo/client';
import {DISCHARGE_MEMBER} from 'data/graphql';
import { useSnackbar } from 'notistack';

// Styles
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  dialogFormDiv: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 64px)', 
  },
}));

// TO DO:
// - Verify issues with Family Module
// - Move logic to MemberContext 

const DischargeMember = ({data, open, handleClose, refetch}) => {

  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
    
  // Form State
  const [selectedFamily, setSelectedFamily] = useState({});
  const [selectedTeamLead, setSelectedTeamLead] = useState();

  // Setup: Family
  const isPatient = data?.type === 'patient';
  const familyMembers = data?.relations || [];
  const hasFamilyMembers = familyMembers.length > 0;
  const familyToDischarge = Object.keys(selectedFamily).filter(k => selectedFamily[k]);
  const familyToDischargeRender = familyMembers?.filter(member => familyToDischarge?.includes(member.memberId));
  console.log('[Discharge Flow] familyToDischarge', familyToDischarge, 'renderInfo', familyToDischargeRender)

  // Setup: SoberCircle
  const soberCircle = data?.soberCircle;
  const soberCircleMembers = soberCircle?.members;
  const isSoberCircleLead = soberCircleMembers?.find(me => me._id === data?._id)?.isTeamLead
  const soberCircleNewLead = soberCircleMembers?.find(member => member._id === selectedTeamLead);
  console.log('[Discharge Flow] isSoberCircleLead', isSoberCircleLead, 'selectedTeamLead', selectedTeamLead, 'soberCircleNewLead', soberCircleNewLead, 'soberCircle', soberCircleMembers)

  const avatarProps = {
    _id: data?.memberId,
    firstName: data?.firstName,
    lastName: data?.lastName,
    image: data?.image || data?.emrPic,
    showLabel: true,
    disabled: true,
    size: 'small',
  }

  // Form Handlers
  const handleChangeTeamLead = (event) => {
    setSelectedTeamLead(event.target.value);
  };

  // Discharge Mutation Variables
  const dichargeMutationSetup = { 
      memberId: data?._id,
      familyMembers: familyToDischarge,
      options: {
        ...(selectedTeamLead ? { soberCircleTeamLead: selectedTeamLead } : {}),
      }
  }
  console.log('[Discharge Flow] dischargeMutationSetup', dichargeMutationSetup)
  
  // Mutations
  const [dischargePatient] = useMutation(
    DISCHARGE_MEMBER, {
      variables: dichargeMutationSetup,
      onCompleted: () => {
        setSelectedFamily({})
        enqueueSnackbar('Member has been queued for discharge', { variant: 'success' })
        setTimeout(() => {
          refetch();
        }, 250);
        handleClose();
      },
      onError: (e) => {
          console.log("DISCHARGE_MEMBER", e);
          enqueueSnackbar('Error during discharge', { variant: 'error' })
      },
    }
  );

  const DischargeSummary = () => {
    return (
      <div>
        <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 20}}>
          Summary of Discharge
        </div>
        
        <div style={{marginBottom: 15}}>
          <div style={{marginBottom: 10, textTransform: 'capitalize'}}>{data?.type}:</div>
          <ul style={{listStyle: 'inside'}}>
            <li style={{marginBottom: 5}}>{data.firstName}'s future activities will be cancelled</li>
            <li>All future Team Consults will be removed</li>
          </ul>
        </div>

        {familyToDischarge.length > 0 && 
          <div style={{marginBottom: 15}}>
            <div style={{marginBottom: 10}}>Family:</div>
            <ul style={{listStyle: 'inside'}}>
            {familyToDischargeRender.map(member => {
              return <li>{member.firstName} {member.lastName?.[0]} {`(${member.relation?.name})`} will be discharged and activities cancelled</li>
            })}
            </ul>
          </div>
        }

        {selectedTeamLead && 
          <div style={{marginBottom: 15}}>
            <div style={{marginBottom: 10}}>Sober Circle:</div>
            <ul style={{listStyle: 'inside'}}>
              <li>Reassign Team Lead role to {`${soberCircleNewLead.firstName} ${soberCircleNewLead.lastName}`}</li>
            </ul>
          </div>
        }
      </div>
     )
  }
  // Formik Controls
  const formikInitialValues = {
    dischargeConfirm: '',
    familyMembersForDischarge: [],
  }

  const formikHandleSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    dischargePatient();
    // setTimeout(() => {     
    //   alert(JSON.stringify(values, null, 2));
    // }, 0);
  }

  const DischargeValidationSchema = Yup.object().shape({
    dischargeConfirm: Yup.string()
      .required('Must contain word: discharge')
      .matches(/^discharge$/, 'Must contain word: discharge'),
  });

  const formikProps = {
    validateOnMount: true,
    initialValues: formikInitialValues,
    validationSchema: DischargeValidationSchema,
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="sm"
    fullWidth
    >
      <Formik 
      {...formikProps}
      onSubmit={(values, {setSubmitting}) => formikHandleSubmit(values, setSubmitting)}>
      {({ values, errors, submitForm, isValid, isSubmitting }) => (
      <Form>
      
      <div className={classes.dialogFormDiv}>

        <DialogTitle className={classes.dialogTitle}>
          Discharge Member
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc', padding: 10, marginBottom: 20}}>
            <MemberAvatar {...avatarProps} /> 
            <div style={{marginLeft: 5, color: '#999'}}>
              MRN: {data.mrn}
            </div>
          </div>
        
          {isPatient && hasFamilyMembers &&
            <div style={{marginBottom: 20, paddingBottom: 10, borderBottom: '1px solid #ccc'}}>
            <h1>Which family members should be discharged?</h1>
            <FieldArray name="familyMembersForDischarge">
              {arrayHelpers => (
                <div>
                {familyMembers?.map((member, index) => {
                  const fullName = `${member.firstName} ${member.lastName}`
                  const relationship = member.relation ? `(${member.relation?.name})` : null;
                  return (
                    <div>
                      <Field
                        key={index}
                        type="checkbox"
                        value={member.memberId}
                        component={CheckboxWithLabel}
                        name="familyMembersForDischarge"
                        checked={values.familyMembersForDischarge.includes(member.memberId)}
                        Label={{ label: `${fullName} ${relationship}` }}
                      />
                    </div>
                  )
                })}
                </div>
              )}
            </FieldArray>
            </div>
          }
                
          {/* {isSoberCircleLead &&
            <div style={{marginBottom: 20, paddingBottom: 10, borderBottom: '1px solid #ccc'}}>
              <h1>{data.firstName} is team lead for {soberCircle?.teamName}</h1>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div>Reassign to:</div>
                <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="sober-circle-lead">Choose Team Lead</InputLabel>
                  <Select
                    id="sober-circle-lead"
                    value={selectedTeamLead}
                    onChange={handleChangeTeamLead}
                    label="Choose Team Lead"
                  >
                    {soberCircleMembers
                      .filter(current => current._id !== data._id) //remove current patient
                      .map((member, i) => {
                      return <MenuItem key={i} value={member._id}>{`${member.firstName} ${member.lastName}`}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                </div>
              </div>
            </div>
          }   */}

          <DischargeSummary />

          <div>
            <Field
              style={{width: '100%'}}
              component={TextField}
              name="dischargeConfirm"
              label='Type the word "discharge" to proceed'
            />
          </div>
          
          {isSubmitting && <LinearProgress />}

          <FormDevTools values={values} errors={errors} />
          
        </DialogContent>

        <DialogActions style={{marginBottom: 5}}>
            <Button
              fullWidth
              style={{width: '100%'}}
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
              onClick={submitForm}
            >
              Proceed to Discharging
            </Button>
        </DialogActions>
      </div>
      </Form>
      )}
    </Formik>
  </Dialog>
  )
}

export default DischargeMember;