import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

// Redux
import { useSelector } from 'react-redux';
import { getUserById } from 'selectors';

// Material UI
import { Select, MenuItem, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';


// Hooks (Dev)
import { useCountRenders } from 'common/hooks/useCountRenders';
import useMembersContext from 'context/Members/useMembersContext';
import { getMemberById } from 'context/Members/MembersContext';

const MemberSelect = ({
  data,
  heading,
  memberType,
  isEditing = true,
  hasPermission = true,
  options = null,
  disabled=false,
  handleChange
}) => {
  // Hooks
  useCountRenders('MemberSelect.js');
  const classes = useStyles();
  
  // Context
  const {family: familyList, patients: memberList, staff} = useMembersContext();

  // State
  const [memberEditList, setMemberEditList] = useState([]);

  // Setup
  const isMemberType = memberType === 'patient' || memberType === 'family';
  const isStaffType = memberType === 'staff';

  const members = [...memberList, ...familyList];
  const member = getMemberById(isStaffType ? staff : members, data?.memberId);
  
  if((!member || _.isEmpty(member)) && data.memberInfoWhenNoAccess){
    member = data.memberInfoWhenNoAccess
  }

  // Load EditMode Data
  useEffect(() => {
    if(options){
      const optionsData = [...options]
      optionsData.sort((a, b) => a.lastName.localeCompare(b.lastName))
      return setMemberEditList(optionsData)
    }
    else{
        if (isMemberType) {
        const memberData = _.uniqBy([...members], member => member._id)
          .sort((a, b) => a.lastName.localeCompare(b.lastName));
        if (memberData?.length > 0) return setMemberEditList(memberData);
      }

      if (isStaffType) {
        const staffData = _.uniqBy([...staff], staff => staff._id)
          .sort((a, b) => a.lastName.localeCompare(b.lastName));
        if (staffData?.length > 0) return setMemberEditList(staffData);
      }
    }
  }, []);

  // Member Data Setup
  const displayName = `${member?.title || ''} ${member?.firstName || ''} ${member?.lastName || ''}`;
  const avatarProps = {
    image: member?.image,
    firstName: member?.firstName,
    lastName: member?.lastName,
    disabled: true,
    size: 'small',
  };

  const editMemberProps = {
    member,
    disabled,
    memberEditList,
    handleChange,
  };

  const displayMemberProps = {
    displayName,
    avatarProps,
  }

  const setEditMode = isEditing && hasPermission;

  return (
    <Fragment>
      <div className={classes.heading}>{heading}</div>
      {setEditMode ? <EditMember {...editMemberProps} /> : <DisplayMember {...displayMemberProps} />}
    </Fragment>
  );
};

export default MemberSelect;

const useStyles = makeStyles({
  heading: {
    marginBottom: 10,
    fontFamily: 'Avenir Next Demi Bold',
  },
});

// Element Components
const DisplayMember = ({displayName, avatarProps}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <MemberAvatar {...avatarProps} />
      <span style={{ marginLeft: 10 }}>{displayName}</span>
    </div>
  );
};

const EditMember = ({member, disabled, memberEditList, handleChange}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <Select
        id="memberEdit-options"
        value={member}
        disabled={disabled}
        onChange={e => handleChange(e.target?.value)}
        renderValue={m => <MemberSelectRender member={m} />}
      >
        {memberEditList?.map((member,i) => (
          <MenuItem key={i} value={member}>
            <MemberSelectRender member={member} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Select Components - To extract to common
const MemberSelectRender = ({member}) => {
  const { image, firstName, lastName, title, type } = member;
  const displayName = `${title || ''} ${firstName} ${lastName}`;
  const avatarProps = {
    image,
    firstName,
    lastName,
    type,
    size: 'small',
    disabled: true,
  };
  return (
  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <MemberAvatar {...avatarProps} small/>
    <span style={{marginLeft: 5}}>{displayName}</span>
  </div>
  )
}
