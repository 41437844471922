import React from 'react'
import styles from './ChipInput.module.scss'

// Material UI
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { useController } from 'react-hook-form'
import Loading from 'common/components/Forms/Loading'

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar'

const ChipAutosuggest = ({
  control,
  name,
  loading,
  suggestions,
  field,
  placeholder,
  required = true,
  multiple = true,
  isSelected = false,
  disabled = false,
  ...restProps
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
  })

  if (loading) return <Loading />

  let memberLabel = (option) => `${option.firstName} ${option.lastName}`
  let typeLabel = (option) => option.text

  return (
    <Autocomplete
      {...inputProps}
      inputRef={ref}
      multiple={multiple ? true : false}
      disabled={disabled}
      options={suggestions}
      filterSelectedOptions
      error={error}
      value={value}
      onChange={(event, value) => {
        onChange({
          // send value to hook form
          ...event,
          target: {
            value,
          },
        })
      }}
      className={`${styles.chip} ${restProps.className}`}
      getOptionLabel={(option) => {
        if (field === 'member' && option.firstName) return memberLabel(option)
        if (field === 'type' && option.text) return typeLabel(option)
      }}
      renderTags={(value, getTagProps) => {
        if (multiple)
          return value.map((option, index) => {
            return (
              <Chip
                variant="outlined"
                avatar={<ChipAvatar data={option} />}
                label={field === 'member' ? memberLabel(option) : typeLabel(option)}
                {...getTagProps({ index })}
              />
            )
          })
        else
          return (
            <Chip
              variant="outlined"
              avatar={<ChipAvatar data={value} />}
              label={field === 'member' ? memberLabel(value) : typeLabel(value)}
              {...getTagProps(1)}
            />
          )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            error={invalid}
            helperText={error?.message}
            margin="none"
            classes={{ root: styles.textfield }}
            variant="outlined"
            InputProps={{ ...params.InputProps, endAdornment: null }}
            placeholder={params.InputProps.startAdornment ? null : placeholder}
          />
        )
      }}
    />
  )
}

export default ChipAutosuggest

const ChipAvatar = ({ data }) => {
  const avatarProps = {
    image: data?.image,
    firstName: data?.firstName,
    lastName: data?.lastName,
    disabled: true,
    size: 'xxsmall',
  }

  return <MemberAvatar {...avatarProps} />
}
