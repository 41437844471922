import {gql} from '@apollo/client';
export const GET_NOTIFICATIONS = gql`
  query getNotifications($channel: String, $unread: Boolean) {
    getNotifications(channel: $channel, unread: $unread) {
      _id
      name
      description
      content
      channels
      name
      priority {
        name
        rank
      }
      lastAdded
      items {
        _id
        sourceId
        source
        seen
        sourceCollection
        parsedContent
        parsedLink
        patientId
        member{
          _id
          type
          firstName
          lastName
          image
        }
      }
      tags
    }
  }
`;
export const CLEAR_NOTIFICATIONS = gql`
  mutation clearNotifications(
    $all: Boolean
    $notificationId: ObjectID
    $itemId: ObjectID
  ) {
    clearNotifications(
      all: $all
      notificationId: $notificationId
      itemId: $itemId
    )
  }
`;

export const CLEAR_URGENT_ISSUE = gql`
  mutation clearNotifications(
    $notificationId: ObjectID
    $itemId: ObjectID
  ) {
    clearNotifications(
      all: false
      notificationId: $notificationId
      itemId: $itemId
    )
  }
`;
