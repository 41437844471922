import { gql } from '@apollo/client'
import { NOTE_FIELDS } from './notes'
// * Queries //
export const BASIC_ACTIVITY_FIELDS = `
  _id
  name
  description
    image
    host
    createdBy
    type
    status
    webexLink
    processType
    worksheetQuestions {
      _id
      text
      possibleAnswers
    }
    invitedMembers
    locationId
    address {
      street1
      street2
      city
      country {
        code
        name
      }
      state
      zip
      displayLine
    }
    points
    tags
    mandatory
     scheduling {
        start
        end
        duration
        originalActivityId
        isPrimaryActivity
        recurrenceText
      }
      cohosts
    `

export const GET_ACTIVITIES = gql`
  query activities($filters: ActivityFilters) {
    activities(filters: $filters) {
      _id
      name
      note {
        note
      }
      description
      image
      host
      createdBy
      type
      status
      webexLink
      processType
      worksheetQuestions {
        _id
        text
        possibleAnswers
      }
      invitedMembers
      locationId
      address {
        street1
        street2
        city
        country {
          code
          name
        }
        state
        zip
        displayLine
      }
      points
      tags
      mandatory
      patientParticipationInfo {
        checkedIn
        feedback {
          rating
        }
      }
      participationInfo {
        memberId
        checkedIn
        responseStatus
        feedback {
          rating
          answers {
            questionId
            answer
          }
          textFeedback
          tagFeedback
        }
      }
      scheduling {
        start
        end
        duration
        originalActivityId
        isPrimaryActivity
        recurrenceText
      }
      lab {
        status
        result
      }
      cohosts
    }
  }
`

export const GET_CALLS_WITHOUT_NOTES = gql`
  query getCallsWithoutNotes($filters: ActivityFilters) {
    getCallsWithoutNotes(filters: $filters) {
      _id
      name
      note {
        note
      }
      type
      appointment {
        callType
      }
      scheduling {
        start
        end
        duration
        originalActivityId
        isPrimaryActivity
        recurrenceText
      }
    }
  }
`

export const GET_ALL_EVENTS = gql`
  query getAllEventsForTherapist($filters: EventFilters) {
    getAllEventsForTherapist(filters: $filters) {
      _id
      name
      type
      startDate
      endDate
    }
  }
`

export const GET_CALL_ACTIVITIES = gql`
  query getCallActivities($filters: ActivityFilters) {
    getCallActivities(filters: $filters) {
      _id
      name
      type
      host
      status
      createdBy
      webexLink
      invitedMembers
      cohosts
      members { 
        _id
        firstName
        lastName
        type
        image
        emrPic
      }
      callInfo {
        callStatus
      }
      scheduling {
        duration
        end
        start
      }
      note {
        ${NOTE_FIELDS}
      }
    }
  }
`

export const GET_CALL_PLAN = gql`
  query getCallActivity($_id: ObjectID!) {
    getCallActivity(_id: $_id) {
      callInfo {
        prepCallNote
      }
    }
  }
`

export const GET_CALL_ACTIVITY = gql`
  query getCallActivity($_id: ObjectID!) {
    getCallActivity(_id: $_id, withMemberDetails: true) {
      _id
      callInfo {
        callStatus
        prepCallNote
        timerDuration
        timerMaxDuration
        startedAt
        endedAt
        completedAt
      }
      note {
        note
        signed
        analysis {
          emotionalState
          engagementLevel
        }
        relapsedAt
        relapsed
      }
      noteDraft {
        _id
        note
        noteDate
        modifiedAt
        createdAt
        relapsed
        analysis {
          emotionalState
          engagementLevel
        }
        relapsedAt
      }
      name
      nextActivity {
        _id
        name
        scheduling {
          start
        }
        invitedMembers
      }
      status
      tags
      points
      invitedMembers
      members {
        _id
        firstName
        emotionalState
        engagementLevel
        image
        lastName
        levelOfCare
        todaysGoal
        riskLevel
        alerts
      }
      scheduling {
        start
        end
        duration
      }
    }
  }
`

export const GET_CALL_ACTIVITY_NOTE = gql`
  query getCallActivity($_id: ObjectID!) {
    getCallActivity(_id: $_id, withMemberDetails: true) {
      _id
      note {
        note
      }
      callInfo {
        callStatus
        prepCallNote
      }
      noteDraft {
        _id
        note
        noteDate
        modifiedAt
        createdAt
        relapsed
        analysis {
          emotionalState
          engagementLevel
        }
        relapsedAt
      }
      invitedMembers
      members {
        _id
      }
    }
  }
`

export const GET_CALL_ACTIVITY_DETAILS = gql`
  query getCallActivity($_id: ObjectID!) {
    getCallActivity(_id: $_id, withMemberDetails: true) {
      _id
      callInfo {
        prepCallNote
      }

      name
      nextActivity {
        _id
        name
        scheduling {
          start
        }
        invitedMembers
      }
      invitedMembers
      scheduling {
        start
        end
        duration
      }
      members {
        _id
        firstName
        emotionalState
        engagementLevel
        image
        lastName
        levelOfCare
        todaysGoal
        riskLevel
        alerts
      }
    }
  }
`

export const GET_CALL_ACTIVITY_PATIENT_DATA = gql`
  query getCallActivity($_id: ObjectID!) {
    getCallActivity(_id: $_id, withMemberDetails: true) {
      _id
      name
      callInfo {
        callStatus
        timerDuration
        timerMaxDuration
        startedAt
        endedAt
        completedAt
      }
      nextActivity {
        _id
        name
        scheduling {
          start
        }
        invitedMembers
      }
      status
      tags
      points
      invitedMembers
      members {
        _id
        firstName
        emotionalState
        engagementLevel
        image
        lastName
        levelOfCare
        todaysGoal
        riskLevel
        alerts
      }
      scheduling {
        start
        end
        duration
      }
    }
  }
`

// * Mutations //

export const INSERT_ACTIVITY = gql`
  mutation insertActivity($activity: ActivityInsertInput) {
    insertActivity(activity: $activity) {
      _id
      name
      description
      image
      type
      invitedMembers
      scheduling {
        start
        end
      }
    }
  }
`

export const EDIT_ACTIVITY = gql`
  mutation editActivity(
    $activityId: ObjectID!
    $activityInfo: ActivityEditInput
    $scheduling: ScheduleInputForEdit
    $seriesFlag: EditRecurrentCases
  ) {
    editActivity(
      activityId: $activityId
      activityInfo: $activityInfo
      scheduling: $scheduling
      seriesFlag: $seriesFlag
    ) {
      _id
      status
      note {
        note
        analysis {
          emotionalState
          engagementLevel
        }
      }
    }
  }
`

export const EDIT_ACTIVITY_APPOINTMENT = gql`
  mutation editAppointment(
    $activityId: ObjectID!
    $activityInfo: ActivityEditInput
    $scheduling: ScheduleInputForEdit
    $seriesFlag: EditRecurrentCases
  ) {
    editAppointment(
      activityId: $activityId
      activityInfo: $activityInfo
      scheduling: $scheduling
      seriesFlag: $seriesFlag
    ) {
      _id
      status
      note {
        note
        analysis {
          emotionalState
          engagementLevel
        }
      }
    }
  }
`

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($_id: ObjectID!, $deleteAllRecurrentActivities: Boolean) {
    deleteActivity(_id: $_id, deleteAllRecurrentActivities: $deleteAllRecurrentActivities)
  }
`

export const CANCEL_ACTIVITY = gql`
  mutation cancelActivity($_id: ObjectID!, $seriesOption: DeleteRecurrentCases) {
    cancelActivity(_id: $_id, seriesOption: $seriesOption)
  }
`
export const RESCHEDULE_ACTIVITY = gql`
  mutation rescheduleActivity($activityId: ObjectID!, $scheduling: ScheduleInput) {
    rescheduleActivity(activityId: $activityId, scheduling: $scheduling) {
      _id
      name
      description
      image
      scheduling {
        start
        end
      }
    }
  }
`
export const CLONE_ACTIVITY = gql`
  mutation cloneActivity($activityId: ObjectID!, $scheduling: ScheduleInput) {
    cloneActivity(activityId: $activityId, scheduling: $scheduling) {
      _id
      name
      description
      image
      scheduling {
        start
        end
      }
    }
  }
`

export const SET_ACTIVITY_STATUS = gql`
  mutation editCallStatus($activityId: ObjectID!, $callDetails: CallDetailsInput) {
    editCallStatus(activityId: $activityId, callDetails: $callDetails) {
      name
      status
      _id
      callDetails {
        status
        startedAt
        durationString
        prepCallNote
      }
    }
  }
`

export const SAVE_NOTE = gql`
  mutation savePatientNoteNew($patientId: ObjectID!, $note: NoteInput!) {
    savePatientNoteNew(patientId: $patientId, note: $note) {
      note
    }
  }
`

export const ADD_ACTIVITY = gql`
  mutation addActivity($activityDetails: ActivityFromTemplateInput!) {
    addActivity(activityDetails: $activityDetails) {
      _id
      name
      description
      image
      createdBy
      type
      status
      processType
      invitedMembers
      points
      mandatory
      worksheetQuestions {
        text
        _id
        possibleAnswers
      }
      participationInfo {
        memberId
        checkedIn
        responseStatus
      }
      note {
        note
      }
      scheduling {
        start
        end
        duration
      }
    }
  }
`
// callInfo {
//   callStatus
//   prepCallNote
//   timerDuration
//   timerMaxDuration
//   startedAt
//   endedAt
//   completedAt
// }
export const ACCEPT_ACTIVITY_INVITE = gql`
  mutation acceptActivityInvite($activityId: ObjectID) {
    acceptActivityInvite(activityId: $activityId) {
      _id
      name
      description
      image
      status
    }
  }
`

export const EDIT_CALL_INFO = gql`
  mutation editCallInfo($callId: ObjectID!, $content: CallInfoInput!) {
    editCallInfo(callId: $callId, content: $content) {
      callInfo {
        prepCallNote
      }
    }
  }
`

export const SAVE_NOTE_DRAFT = gql`
  mutation saveMemberNoteDraft($memberId: ObjectID!, $draft: NoteDraftInput!) {
    saveMemberNoteDraft(memberId: $memberId, draft: $draft) {
      _id
      note
      noteDate
      modifiedAt
      createdAt
      relapsed
      analysis {
        emotionalState
        engagementLevel
      }
      relapsedAt
    }
  }
`

export const SAVE_NOTE_FROM_DRAFT = gql`
  mutation saveNoteFromDraft($draftToNote: DraftToNote!) {
    saveNoteFromDraft(draftToNote: $draftToNote) {
      _id
      note
      analysis {
        engagementLevel
        emotionalState
      }
    }
  }
`

export const GET_ALL_PARTICIPANT_ACTIVITIES = gql`
  query getAllParticipantActivities($filters: EventFilters) {
    getAllParticipantActivities(filters: $filters) {
      _id
      name
      type
      invitedMembers
      host
      cohosts
      scheduling {
        start
        end
        duration
        originalActivityId
        isPrimaryActivity
        recurrenceText
      }
    }
  }
`

export const GET_ACTIVITY_TEMPLATES = gql`
  query getActivityTemplates($filters: ActivityTemplateFilters) {
    getActivityTemplates(filters: $filters) {
      _id
      name
      category
      code
    }
  }
`

const BASIC_TEMPLATE_DETAILS = `
    name
    description
    room
    image
    type
    host
    points
    mandatory
    locationId
    address {
      street1
      street2
      city
      country {
        code
        name
      }
      state
      zip
    }
    scheduling {
      start
      end
      duration
      originalActivityId
      isPrimaryActivity
      recurrenceText
    }
    addWorksheetQuestions
    tags
    courses
    billingCode
    reminders{
      method
      minutes
    }
    processType
`
export const GET_ACTIVITY_TEMPLATE_DETAILS = gql`
  query getActivityTemplate($_id: ObjectID!) {  
   getActivityTemplate(_id: $_id) {   
     _id 
     code
     template{
       ${BASIC_TEMPLATE_DETAILS}
     }   
  }}
`

export const SAVE_NEW_TEMPLATE = gql`
  mutation saveActivityTemplate($activityTemplate: ActivityTemplateInput!) {
    saveActivityTemplate(activityTemplate: $activityTemplate) {
      _id
      code
    }
  }
`

export const GET_ACTIVITY_BEFORE_EDIT = gql`
  query getActivityForEdit($activityId: ObjectID!) {
    getActivityForEdit(activityId: $activityId) {
      _id
      regardingMember
      canEdit
      canDelete
      isSeries
      isMaster
      isSynced
      appointment {
        nextInSeries {
          start
        }
      }
      uiNotes {
        type
        title
        message
        actions
      }
    }
  }
`

export const GET_RECURRENCE_INFO = gql`
  query getRecurrenceInfo($startDate: DateTime!, $recurrence: RecurrenceInput!) {
    getRecurrenceInfo(startDate: $startDate, recurrence: $recurrence) {
      rule
      text
    }
  }
`
