import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Switch, Collapse, Select, MenuItem, FormControl, FormControlLabel } from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'
import Button from 'common/components/Forms/Button'

import moment from 'moment'
import { getWeekOfMonth } from 'date-fns'

// Components
import RecurrenceText from './RecurrenceText'
import Popup from 'common/components/Popup/Popup'
import CustomRecurrenceSetter from 'screens/Activities/CustomRecurrenceSetter'
import { GET_RECURRENCE_INFO } from 'data/graphql/activity'

// Util
import { setMinMax } from '../../utils/dateHelper'

import Loading from 'common/components/Forms/Loading'

//Constants
import { DAYS_ENUM, DAYS_WEEKDAYS, WEEKS_OPTIONS, REPEAT_OPTIONS } from 'common/constants/activityConstants'

const Repeat = ({ selectedTime, setRecurrence, showTitle, loading, width = 200 }) => {
  // State
  const [recurrenceType, setRecurrenceType] = useState(REPEAT_OPTIONS[0])
  const [weekIndex, setWeekIndex] = useState(WEEKS_OPTIONS[0])
  const [selectedDay, setSelectedDay] = useState(DAYS_ENUM[0])
  const [selectedDate, setSelectedDate] = useState(moment(selectedTime))
  const [showCustomRepeatSetter, setShowCustomRepeatSetter] = useState(false)
  const [hasRecurrence, setHasRecurrence] = useState(false)

  const [getRecurrenceInfo, { data: recurrenceInfo, loading: recurrenceCalculated, error: recurrenceInfoError }] =
    useLazyQuery(GET_RECURRENCE_INFO)

  // Data
  const frequency = recurrenceType?.frequency

  useEffect(() => {
    const updatedTime = moment(selectedTime)
    const updatedWeekOfMonth = setMinMax(getWeekOfMonth(updatedTime.toDate()), 1, 5)

    setSelectedDate(updatedTime)
    setSelectedDay(DAYS_ENUM[updatedTime.days()])
    setWeekIndex(WEEKS_OPTIONS[updatedWeekOfMonth - 1]) // force week 6 down to 5

    if (hasRecurrence) {
      handleChange(recurrenceType)
    }
  }, [selectedTime])

  // Handler
  const handleRecurrenceToggle = () => setHasRecurrence((prev) => !prev)

  const handleChange = (value) => {
    setRecurrenceType(value)

    const frequency = value?.frequency
    const repeatType = value?.repeatType

    // Recurrence Types
    const isDaily = frequency === 'daily'
    const isMonthly = frequency === 'monthly'
    const isDailyWeekdays = repeatType === 'dailyWeekdays'
    const isCustom = repeatType === 'custom'

    if (isCustom) {
      setShowCustomRepeatSetter(true)
    }

    // Setup
    const index = getWeekOfMonth(selectedDate.toDate())
    const bysetpos = WEEKS_OPTIONS[index - 1].BYSETPOS

    const daysOfWeek = isDailyWeekdays ? DAYS_WEEKDAYS : DAYS_ENUM[selectedDate.days()]

    const endDate = selectedDate.add(value?.endDate)

    // Mutation Data
    const recurrenceInputData = {
      frequency,
      interval: value?.interval,
      index: isMonthly ? index : null,
      bysetpos: isMonthly ? bysetpos : null,
      byweekday: !isDaily ? daysOfWeek : null,
      endDate,
    }

    setRecurrence(recurrenceInputData)
    // console.log(
    //   '[Repeat] RecurrenceInput mutation data:  bogi',
    //   recurrenceInputData,
    //   daysOfWeek,
    //   selectedDate.days(),
    //   DAYS_ENUM[selectedDate.days()]
    // );
  }

  const handleCustomClose = (data) => {
    if (data) {
      getRecurrenceInfo(data)
    }
    setShowCustomRepeatSetter(false)
  }

  const handleCancel = () => {
    setShowCustomRepeatSetter(false)
    setRecurrenceType(REPEAT_OPTIONS[0])
  }

  const recurrenceProps = {
    frequency,
    week: weekIndex,
    weekOptions: WEEKS_OPTIONS,
    day: selectedDay,
    dayOptions: DAYS_ENUM,
    onChange: null,
  }

  if (loading) return <Loading />

  return (
    <Box style={{ marginTop: 10 }}>
      <FormControlLabel
        label="Repeat"
        control={<Switch color="primary" onChange={handleRecurrenceToggle} checked={hasRecurrence} />}
      />
      <Collapse in={hasRecurrence}>
        <FormControl style={{ minWidth: width }}>
          <Select
            select
            autoWidth
            id="repeat-options"
            variant="outlined"
            value={recurrenceType}
            onChange={(e) => handleChange(e.target?.value)}
          >
            {REPEAT_OPTIONS?.map((repeat) => (
              <MenuItem key={repeat?.key} value={repeat}>
                {repeat?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Collapse>

      <Popup
        open={showCustomRepeatSetter}
        onClose={handleCancel}
        title="Custom Recurrence"
        style={{ width: '500px', margin: 'auto' }}
      >
        <CustomRecurrenceSetter
          selectedTime={selectedTime}
          onClose={handleCustomClose}
          setRecurrence={setRecurrence}
          onCancel={handleCancel}
          selectedDay={selectedDay}
        />
      </Popup>

      <div>
        {hasRecurrence ? <RecurrenceText {...recurrenceProps} text={recurrenceInfo?.getRecurrenceInfo?.text} /> : null}
      </div>
    </Box>
  )
}

export default Repeat

Repeat.propTypes = {
  selectedTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  setRecurrence: PropTypes.func,
  showTitle: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.number,
}

const CustomSetterActions = ({ onCancel, onSubmit }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button variant="contained" onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onSubmit} variant="contained" color="primary">
        OK
      </Button>
    </div>
  )
}
