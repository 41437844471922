import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import classNames from 'classnames';
import MomentUtils from '@date-io/moment';
import { Typography, Box } from '@material-ui/core';

import styles from './Datepicker.module.scss';

const Datepicker = ({
  currentDate,
  field,
  form: { setFieldValue, errors },
  ...restProps
}) => {

  const handleChange = value => {
    console.log('💁‍♀️ setting value to => ', moment(value).format());
    setFieldValue(field.name, moment(value).format());
  }

  const isDisabled = field.value && !errors[field.name];

  const keyboartDPProps = {
    autoOk: true,
    ToolbarComponent: () => <></>,
    orientation: "landscape",
    variant: "inline",
    openTo: "date",
    format: "MMMM DD",
    placeholder: "Choose a date...",
    value: field.value || null,
    onChange: handleChange,
    className: classNames(restProps.className, {
      [styles.disabled]: isDisabled
    }),
    InputProps: { disableUnderline: true },
    InputAdornmentProps: {
      position: 'start',
      classes: { root: styles.filled },
    },
    ...restProps,
  }

  return (
    <Box className={styles.container}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker {...keyboartDPProps}/>
      </MuiPickersUtilsProvider>
      {errors && errors[field.name] && (
        <Typography className={styles.errors}>{errors[field.name]}</Typography>
      )}
    </Box>
  )
};

export default Datepicker;
