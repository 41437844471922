import { useContext } from 'react';
import {ActivitiesContext, ScheduleModuleContext} from './ActivitiesContext';

const useScheduleModuleContext = () => {
	const context = useContext(ScheduleModuleContext);
	if (!context) {
		throw new Error(`This component cannot be rendered outside the ScheduleModuleContext`);
	}
	return context;
};

const useActivitiesContext = () => {
	const context = useContext(ActivitiesContext);
	if (!context) {
		throw new Error(`This component cannot be rendered outside the ActivitiesContext`);
	}
	return context;
};

export {useScheduleModuleContext, useActivitiesContext};
