import {gql} from '@apollo/client';

const SETUP_FIELDS = `
  setup {
    value
    name
  }
`;

const LOCATION_FIELDS = `
  locations {
    _id
    name
  }
`;

const DEPARTMENT_FIELDS = `
  department {
    _id
    name
  }
`;

const PROGRAM_FIELDS = `
  program {
    _id
    caronId
    name
    displayName
  }
`;

const LEVEL_OF_CARE_FIELDS = `
  levelOfCare {
    _id
    caronId
    name
  }
`;

const FAMILY_RELEASE_FIELDS = `
  familyRelease {
    _id
    caronId
    name
  }
`;

const TEMPLATE_CATEGORY_FIELDS = `
  templateCategories {
    name
  }
`;

const LAB_PANEL_FIELDS = `
  labPanels {
    name
    _id
    caronId
  }
`;

const USER_GUIDE_FIELDS = `
  userGuides {
    _id
    code
    name
  }
`;

const NOTE_SETUP_FIELDS = `
  noteSetup {
    displayName
    types {
      code
      displayName
      subTypes {
        displayName
        code
        description
        memberTypes
        fields {
          name
          type
          timeline
          options
          title
        }
      }
    }
  }
`;

export const GET_ACCOUNT_ORGANIZATION = gql`
  query getAccountOrg {
    getCurrentOrganization {
      _id
      name
      supportedAppVersion
      termsAndConditions
      ${SETUP_FIELDS}
      ${LOCATION_FIELDS}
      ${DEPARTMENT_FIELDS}
      ${PROGRAM_FIELDS}
      ${LEVEL_OF_CARE_FIELDS}
      ${FAMILY_RELEASE_FIELDS}
      ${LAB_PANEL_FIELDS}
      ${USER_GUIDE_FIELDS}
      ${NOTE_SETUP_FIELDS}
      ${TEMPLATE_CATEGORY_FIELDS}
    }
  }
`