import React, { useState, Fragment, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';

// Material-UI
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import styles from './Entry.module.scss';

// Components
import ConfirmPassword from 'common/components/ConfirmPassword/ConfirmPassword';
import Datepicker from 'common/components/InputFields/Datepicker/Datepicker';
import QuillNote from 'common/components/InputFields/QuillNote';
import GroupedDropdown from 'common/components/InputFields/Dropdown/GroupedDropdown';
import FormBuilderInput from 'common/components/Formik/FormBuilder/FormBuilderInput/FormBuilderInput';
import TextField from 'common/components/InputFields/TextField/TextField';

// Formik
import { Formik, Field, Form } from 'formik';
import { FormDevTools } from "common/components/Formik/FormDevTools";

// Nav
import { useLocation } from 'react-router-dom-v5-compat';

// Redux: Deprecate!
import { useSelector } from "react-redux";
import { getNoteTypesForMemberType } from "selectors";

// Data
import { useMutation } from '@apollo/client';
import { ADD_MEMBER_NOTE, ADD_DRAFT_NOTE, SET_DRAFT_TO_NOTE, GET_DRAFT_NOTES } from 'data/graphql/notes';
import { useSnackbar } from 'notistack';
import { 
  NOTE_TYPES, 
  withGroupNote,
  noteTypeSchema,
  getFieldsForSubType,
  FormikCareNoteSchema,
  mapCallTypeToNoteType,
  callNoteTypes,
  prepareNoteEntry
} from '../common/utils';

// Context
import useAccountContext from 'context/Account/useAccountContext';
import {useMemberStateContext, useMemberUpdaterContext} from 'context/Member/useMemberContext';

const NoteEntry = (
  {
    data = {},
    isDraft = false,
    isUserConfirm,
    formikRef,
  }, 
  ref
) => {

  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  
  //Context Data
  const { userId, userEmail } = useAccountContext();
  const { memberId, memberType, memberData: member, isPatient } = useMemberStateContext();
  const { organization } = useAccountContext()
  const { refetch: refetchMember } = useMemberUpdaterContext();

  // Setup: Data
  const note = location?.state?.draftNote || data?.selected?.draft;
  const noteType = note?.subType?.code; //|| (data?.selected?.appointment?.callType ? mapCallTypeToNoteType(data?.selected?.appointment?.callType, NOTE_TYPES) : null);
  const noteSetup = useSelector(state => getNoteTypesForMemberType(state, memberType));
  console.log('[Note Entry] note from state', note,'org from AccountContext', organization, 'noteSetup', noteSetup)

  // Setup: Members
  const familyMembers = isPatient ? member?.relations : member?.relatedFamily || [];
  const hasFamilyMembers = !!familyMembers;
  const relatedPatients = !isPatient ? member?.relatedPatients : [] || [];
  const hasRelatedPatients = relatedPatients?.length > 0;

  // Group Note Setup
  const [selectedMembers, setSelectedMembers] = useState({});
  const [selectedPatients, setSelectedPatients] = useState({});
  const handleMemberSelect = (event) => {
    setSelectedMembers(prevSelected => ({ 
      ...prevSelected, 
      [event.target.name]: event.target.checked
    }));
  };
  const handlePatientSelect = (event) => {
    setSelectedPatients(prevSelected => ({ 
      ...prevSelected, 
      [event.target.name]: event.target.checked
    }));
  }
  const familyTargetList = Object.keys(selectedMembers).filter(k => selectedMembers[k]);
  const patientTargetList = Object.keys(selectedPatients).filter(k => selectedPatients[k]);
  const memberTargetList = [...familyTargetList, ...patientTargetList];
  const hasMemberTargets = memberTargetList?.length > 0;


  // Mutations: Add
  const [saveMemberNote] = useMutation(ADD_MEMBER_NOTE, {
    onCompleted: () => { 
      setTimeout(() => { refetchMember(); }, 250); 
      enqueueSnackbar('Note Saved', { variant: 'success' });
    },
    onError: error => {
      console.log('saveMemberNote error', error)
      enqueueSnackbar('Error. Note not saved.', { variant: 'error' })
    }
  });
  const [saveDraftNote] = useMutation(ADD_DRAFT_NOTE, {
    refetchQueries: [{ query: GET_DRAFT_NOTES, variables: { filters: { memberId } } }],
    onCompleted: () => { 
      enqueueSnackbar('Draft Saved', { variant: 'success' });
    },
    onError: error => {
      console.log('saveDraftNote error', error)
      enqueueSnackbar('Error. Draft not saved.', { variant: 'error' })
    }
  });
  const [saveDraftToNote] = useMutation(SET_DRAFT_TO_NOTE, {
    refetchQueries: [{ query: GET_DRAFT_NOTES, variables: {filters: { memberId }} }],
    onCompleted: () => { 
      setTimeout(() => { refetchMember(); }, 250); 
      enqueueSnackbar('Note Signed and Saved! Removing Draft.', { variant: 'success' });
    },
    onError: error => {
      console.log('saveDraftToNote error', error)
      enqueueSnackbar('Error. Note not saved.', { variant: 'error' })
    }
  });

  // Mutation Triggers
  const onNoteSubmit = async values => {
    try {
      const memberNoteEntry = prepareNoteEntry({values, data})
      await saveMemberNote({ variables: memberNoteEntry })
    } catch (error) {
      console.log('[NoteEntry] saveMemberNote error', error)
    }
  }
  const onNoteDraftSave = async values => {
    try {
      const memberNoteEntry = prepareNoteEntry({values, data, isDraft: true})
      await saveDraftNote({ variables: memberNoteEntry })
    } catch (error) {
      console.log('[NoteEntry] saveDraftNote error', error)
    }
  }
  const onDraftToNoteSubmit = async values => {
    try {
      const memberNoteEntry = prepareNoteEntry({values, data, isDraftToNote: true})
      await saveDraftToNote({ variables: memberNoteEntry })
    } catch (error) {
      console.log('[NoteEntry] saveDraftToNote error', error)
    }
  }
  const onCopyNoteToMember = async (targetMemberId, values) => {
    try {
      const memberNoteEntry = prepareNoteEntry({values, data, targetMemberId, isCopy: true})
      await saveMemberNote({ variables: memberNoteEntry })
    } catch (error) {
      console.log('[NoteEntry] Group Note: saveMemberNote error', error)
    }
  }

  // Submit Handlers
  const handleSubmitNote = async (values) => {
    try {
      const isFamilyNote = values?.subType === NOTE_TYPES.FAMILY;

      let noteEntryCount = 0;
      await onNoteSubmit(values);
      noteEntryCount++

      if(hasMemberTargets) {
        if(isFamilyNote) {
          familyTargetList.forEach(member => {
            onCopyNoteToMember(member, values)
            noteEntryCount++
            console.log('[Note Entry] Family Note copied to member:', member)
          })
        } else {
          memberTargetList.forEach(member => {
            onCopyNoteToMember(member, values)
            noteEntryCount++
            console.log('[Note Entry] Note copied to member:', member)
          })
        }
      }
      console.log('[Note Entry] Completed. Total Notes Entered:', noteEntryCount);

      // Reset State and Close
      setSelectedMembers({});
      setSelectedPatients({});
      
    } catch (error) {
      console.log('[Note Entry] Error Submitting Note', error)
    }
  }

  const handleSaveDraftNote = async (values) => {
    try {
      await onNoteDraftSave(values);
      console.log('[Note Entry] Completed. Saved Draft Note');
      
      // Reset State and Close
      setSelectedMembers({});
      setSelectedPatients({});
      
    } catch (error) {
      console.log('[Note Entry] Error Saving Draft Note', error)
    }
  }

  const handleConvertDraftToNote = async (values) => {
    console.log('raw values values', values)
    try {

      await onNoteDraftSave(values);
      console.log('[Note Entry] Resaving draft')

      // Submit Note 
      console.log('[Note Entry] Converting Draft to Note')
      await onDraftToNoteSubmit(values)

      // Reset State
      setSelectedMembers({});
      setSelectedPatients({});

    } catch (error) {
      console.log('[Note Entry] Error Submitting Draft to Note', error)
    }
  }

  // For External Triggering
  useImperativeHandle(ref, () => ({
    triggerSaveDraft: () => handleSaveDraftNote(formikRef.current.values),
    triggerConvertDraftToNote: () => handleConvertDraftToNote(formikRef.current.values),
  }));

  const initDraftValues = {
    memberId: note?.memberId || memberId,
    id: note?._id,
    note: note?.note || '',
    noteDate: note?.noteDate || data?.selected?.scheduling?.start || moment().format(),
    engagementLevel: note?.analysis?.engagementLevel || null,
    emotionalState: note?.analysis?.emotionalState || null,
    relatedMembers: note?.relatedMemberIds || [],
    subType: noteType || '',
    actualDuration: note?.actualDuration || data?.selected?.scheduling?.duration,
    signed: note?.signed || false,
    signedByEmail: userEmail,
  }
  
  const initNewValues = {
    memberId,
    note: "",
    subType: noteType,
    noteDate: data?.selected?.scheduling?.start || moment().format(),
    actualDuration: data?.selected?.scheduling?.duration || 0,
    engagementLevel: null,
    relapsed: false,
    signedBy: userId,
    createdBy: userId,
    signedByEmail: userEmail,
  }

  const formikProps = {
    innerRef: formikRef,
    initialValues: isDraft ? initDraftValues : initNewValues,
    onSubmit: isDraft ? handleConvertDraftToNote : handleSubmitNote,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: FormikCareNoteSchema,
  }

  return (
    <Formik {...formikProps}>
      {({ values, errors, setSubmitting, isSubmitting, setFieldValue, handleChange, setErrors }) => {
        
        // Formik Setup
        const isConferenceNote = values?.subType === NOTE_TYPES.CONFERENCE;
        const hasCopyToMembers = withGroupNote.includes(values?.subType);

        return (
          <Form>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* {(callNoteTypes.indexOf(noteType) !== -1) &&
                  <div className={styles.note_tools}>
                    <Grid item xs={12}>
                      <div className={styles.label}>Note for Call</div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <TodayIcon />
                        {moment(data?.selected?.scheduling?.start).format('LL')}
                        @{moment(data?.selected?.scheduling?.start).format('h')}
                        {(moment(data?.selected?.scheduling?.start).format('m') !== '0') ? ':' + moment(data?.selected?.scheduling?.start).format('m'):''}
                        {' '}-{' '}{moment(data?.selected?.scheduling?.end).format('LT')}
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center'}}>
                      <div className={styles.label} style={{ flex: 3, marginTop: 25 }}>
                        Actual duration?
                        <div style={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.5)" }}> minutes</div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Field
                          type="number"
                          name="actualDuration"
                          value={values.actualDuration}
                          component={TextField}
                        />
                      </div>
                    </Grid>
                  </div>
                } */}

                <div className={styles.note_tools}>
                  <Grid container alignItems="center" spacing={2}>

                    {/* {(callNoteTypes.indexOf(noteType) === -1) && */}
                      <Fragment>
                        <Grid item xs={12}>
                          <div className={styles.label}>Note Type:</div>
                          <Field
                            name="subType"
                            value={values.subType}
                            options={noteSetup}
                            groupSchema={noteTypeSchema}
                            disabled={!!data?.selected?.appointment?.callType}
                            component={GroupedDropdown} />
                        </Grid>

                        <Grid item xs={12}>
                          <div className={styles.label}>Observation Date:</div>
                          <Field
                            name="noteDate"
                            value={values.noteDate}
                            disableFuture={true}
                            disabled={!!data?.selected?.scheduling?.start}
                            component={Datepicker} />
                        </Grid>
                      </Fragment>
                    {/* } */}

                    {getFieldsForSubType(values.subType, noteSetup, memberType).map((field, i) =>
                      <Grid item xs={12} key={i}>
                        <FormBuilderInput 
                          {...field} 
                          labelColumn={true} 
                          disableFuture={true} 
                          labelClass={styles.label} 
                          {...{ values, handleChange, setFieldValue, errors, setErrors }} 
                          />
                        {errors && errors[field.name] && <FormHelperText error children={errors[field.name]} />}
                      </Grid>
                    )}

                    {!isPatient && isConferenceNote && hasRelatedPatients && 
                      <Grid item xs={12}>
                        <div className={styles.label}>Copy to Patient{relatedPatients?.length > 1 && `s`}:</div>
                        <FormGroup>
                          {relatedPatients?.map((r, i) => {
                            const fullName = `${r.firstName} ${r.lastName}`
                            return <FormControlLabel
                                key={i}
                                control={<Checkbox color="primary" checked={selectedPatients[r._id]} name={r._id} onChange={handlePatientSelect} />}
                                label={`${fullName}`}
                              />
                            }
                          )}
                        </FormGroup>
                      </Grid>
                    }

                    {hasCopyToMembers && hasFamilyMembers && 
                      <Grid item xs={12}>
                        <div className={styles.label}>Copy to Family Member:</div>
                        <FormGroup>
                          {familyMembers?.map((r, i) => {
                            const id = isPatient ? r.memberId : r._id; // Normalize difference in data structure
                            const fullName = `${r.firstName} ${r.lastName}`
                            const relationship = r.relation ? `(${r.relation?.name})` : '';
                            return <FormControlLabel
                                key={i}
                                disabled={!r.activatedAccount}
                                control={<Checkbox color="primary" checked={selectedMembers[id]} name={id} onChange={handleMemberSelect} />}
                                label={`${fullName} ${relationship}`}
                              />
                            }
                          )}
                        </FormGroup>
                      </Grid>
                    }
                  </Grid>

                </div>
              </div>
              <div className={styles.note_body} >
                {!isUserConfirm ? (
                  <div className={styles.editor_box}>
                    <QuillNote
                      name="note"
                      placeholder="Enter your note..." />
                  </div>
                ) : (
                    <ConfirmPassword />
                  )}
                <div>
                  <FormDevTools values={values} errors={errors} />
                </div>
              </div>

            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default forwardRef(NoteEntry);