import React, { /* useState */ } from 'react';
// import { NOTIFICATION_SUB } from '../../../graphql/subscriptions';
import {
  GET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
} from '../../../../data/graphql/notifications';
// import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ReactSVG from 'react-svg';
import Badge from '@material-ui/core/Badge';
import imgRing from '../../../assets/notification-bell.svg';
import styles from './NotificationsPopup.module.scss';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useQuery, useMutation } from '@apollo/client';
import {setNotifications} from 'actions/notifications'
import {useSelector, useDispatch} from 'react-redux'
import {useSnackbar} from 'notistack'
import {readAllNotifications, addNotificationToStore} from 'actions/notifications'
import _ from 'lodash'

import NotificationModule from '../CollabseNotification/CollabseNotification';
import UrgentList from '../../Header/Urgent/List';

const NotificationsPopup = () => {
  const notifications = useSelector(state => state?.notifications)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  //console.log(notifications.all.length)

  useQuery(GET_NOTIFICATIONS, {
    variables: {
      channel: 'dashboard',
      unread: true,
    },
    onCompleted: async (data) => {
      // console.log("onCompleted", data)
      let tmp = []
      data.getNotifications.forEach(item => {
        if (item.priority.name === 'HIGH') tmp.push(item.items)
      })

      await dispatch(setNotifications(data.getNotifications))
    }
  });
  
  const [clearNotifications] = useMutation(CLEAR_NOTIFICATIONS, {
    variables: { all: true },
    onCompleted: () => {
      dispatch(readAllNotifications())
      enqueueSnackbar('All notifications were removed', { variant: 'success' })
    },
    onError: () => enqueueSnackbar("Error: Notifications not removed", { variant: 'error' })
  });

  return (
    <div className={styles.container}>
      <PopupState variant="popover" popupId="notification-popover">
        {popupState => (
          <div>
            <Button className={styles.link} {...bindTrigger(popupState)}>
              <Badge
                color="secondary"
                variant="dot"
                overlap="rectangular"
                invisible={!notifications.all.length}
              >
                <ReactSVG className={styles.icon} src={imgRing} />
              </Badge>
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className={styles.popup}
            >
              <Box className={styles.wrapper}>
                <Box className={styles.header}>
                  <Typography variant="h6" className={styles.title}>
                    Notifications
                  </Typography>
                  {notifications.all.length > 0 && (
                    <Button
                      onClick={clearNotifications}
                      className={styles.button_read_all}
                    >
                      Mark all as read
                    </Button>
                  )}
                </Box>
                <Box className={styles.urgent_notifications}>
                  {
                    notifications.urgent.length > 0 && (
                      <>
                        <Typography className={styles.urgent_title}>Urgent</Typography>
                        <UrgentList 
                          className={styles.urgent_component} 
                          notifications={_.reverse(_.clone(notifications.urgent))}/>
                      </>
                    )
                  }
                </Box>
                <Box>
                  {notifications.all.length > 0 ?
                    <NotificationModule notifications={notifications.all} />
                    : (
                      <Typography className={styles.none_notification}>
                        You don't have notifications.
                    </Typography>
                    )}
                </Box>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default NotificationsPopup;
