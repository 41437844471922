import React, { Fragment } from 'react';
import moment from 'moment';
import cn from 'classnames';
import styles from './View.module.scss';

import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import useMembersContext from 'context/Members/useMembersContext';
import { useMemberStateContext } from 'context/Member/useMemberContext';

import { stringCapitalize } from 'common/helpers/textHelpers';
import { getMemberById } from 'context/Members/MembersContext';
import { useParams } from 'react-router-dom-v5-compat';

const NoteView = () => {

  const params = useParams();
  const {staff} = useMembersContext()
  
  const {memberData: member} = useMemberStateContext()
  const memberNotes = member?.notes || []
  let noteData = memberNotes.find(note => note?._id === params?.noteId) || {}
  const { 
    relapsed, relapsedAt, note, noteDate, 
    signedBy, createdAt, analysis, type, 
    subType, activityId, actualDuration 
  } = noteData;

  const hasCallInfo = activityId || actualDuration;

  const signer = getMemberById(staff, signedBy) || {};

  const noteInfo = [
    {
      title: 'Type',
      content: type ? stringCapitalize(type) : '--',
    },
    {
      title: 'Note Type',
      content: subType ? subType.displayName : '--',
    },
    {
      title: 'Member',
      content: `${member?.firstName} ${member?.lastName}`,
    },
    {
      title: 'Date',
      content: moment(noteDate).format('dddd MM/D/YYYY, h:mm a') || '--',
    },
    {
      title: 'Signed',
      content: `${signer.title ? `${signer.title}` : ''} ${signer.firstName} ${signer.lastName}`,
      signature: true,
    },
    {
      title: '',
      content: moment(createdAt).format('ll'),
    },
  ];

  const RenderNoteInfo = () =>
    noteInfo.map(({ title, content, signature }, i) => (
      <div className={styles.noteInfoRow} key={i}>
        <div className={styles.noteInfoColTitle}>{title}</div>
        <div
          className={signature ? styles.signature : styles.noteInfoColContent}
        >
          {content}
        </div>
      </div>
    ));

  const NoteText = (
    <ReactQuill
      readOnly
      value={note || ''}
      theme={null}
      className={styles.noteQuill}
    />
  );

  const bottomInfo = [
    {
      title: 'Note',
      content: NoteText,
      marginBottom: '35px',
      muted: true,
    },
    ...analysis?.engagementLevel ? [{
      title: 'Engagement',
      content: `${analysis.engagementLevel} / 100`,
      marginBottom: '12px',
      muted: true,
      textMedium: true,
    }] : [],
    ...analysis?.emotionalState ? [{
      title: 'Emotional state',
      content: analysis?.emotionalState
        ? stringCapitalize(analysis.emotionalState)
        : '--',
      marginBottom: '35px',
      muted: true,
      textMedium: true,
    }] : [],
    ...relapsed ? [{
      title: 'Relapsed',
      content: relapsed
        ? "Yes"
        : '--',
      marginBottom: '12px',
      muted: true,
      textMedium: true,
    },
    {
      title: 'Relapse Date',
      content: !!relapsedAt ? moment(relapsedAt).format('ll') || '--' : null,
      marginBottom: '35px',
      muted: true,
      textMedium: true,
    }
    ] : []
  ];

  const avatarProps = {
    _id: member?._id,
    firstName: member?.firstName,
    lastName: member?.lastName,
    image: member?.image || member?.emrPic,
    type: member?.type,
    showIndicator: false, 
    disabled: true, 
  }

  return (
    <Fragment>
      <div className={styles.dialogRow} style={{ marginBottom: '40px' }}>
        <div className={styles.dialogColTitle}>
          <MemberAvatar {...avatarProps} />
        </div>
        <div className={styles.dialogColContent}>
          <RenderNoteInfo />
        </div>
      </div>

      {hasCallInfo ? (
        <div className={styles.dialogRow} style={{marginBottom: '35px'}}>
          <div className={cn(styles.dialogColTitle, styles.textMuted)}>
            Call Duration
          </div>
          <div className={styles.dialogColContent}>
            {`${actualDuration} minutes`}
          </div>
        </div>
      ) : null}
      
      {bottomInfo.map(
        ({ title, content, muted = false, marginBottom, textMedium }, i) => (
          <div key={i} className={styles.dialogRow} style={{ marginBottom }}>
            <div
              className={cn(styles.dialogColTitle, {
                [styles.textMuted]: muted,
              })}
            >
              {title}
            </div>
            <div
              className={styles.dialogColContent}
              style={textMedium && { fontWeight: '500' }}
            >
              {content}
            </div>
          </div>
        )
      )}
    </Fragment>
  );
};


export default NoteView;
