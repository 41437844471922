import React from 'react'
import PropTypes from 'prop-types'

// Components
import MemberSelect from './MemberSelect';
import StaffSelect from './StaffSelect';
import FamilyToggles from './FamilyToggles';

const AttendeeSelectors = ({hasRegardingMember, member, family, host, staff}) => {

  return (
    <div>
      {!hasRegardingMember ? <MemberSelect {...member} /> : null}
      <FamilyToggles {...family} />
      <MemberSelect {...host} />
      <StaffSelect {...staff} />
    </div>
  )
}

AttendeeSelectors.propTypes = {
  member: PropTypes.object,
  family: PropTypes.object,
  host: PropTypes.object,
  staff: PropTypes.object,
}

export default AttendeeSelectors
