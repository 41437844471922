import React, { Fragment } from 'react';

// Main components
import { MemberBodySkeleton } from './Skeleton/Skeleton';
import ErrorScreen from 'common/components/Errors/DefaultError';
import PreloadHeader from './TopModule/PreloadHeader';

// Navigation
import TabNavigation from 'common/components/PanelHeader/PanelHeader';

// Action Dialogs
import DischargeMember from 'components/Member/Actions/DischargeMember';
import ActivateMember from 'components/Member/Actions/ActivateMember';

// Custom tabs components
import Overview from './Modules/Overview/Overview';
import OverviewHeader from './Modules/Overview/OverviewHeader';

import Messaging from './Modules/Messaging/MessagingBody';
import MessagingHeader from './Modules/Messaging/MessagingHeader';

import Activities from 'components/Scheduler/main';
import ActivitiesHeader from './Modules/Activities/ActivitiesHeader';

import Labs from './Modules/Labs/Labs';
import LabsHeader from './Modules/Labs/LabsHeader';

import Surveys from './Modules/Surveys/';

// Context
import {useMemberStateContext} from 'context/Member/useMemberContext';

// Navigation
import { Routes, Route, useLocation, Outlet, useNavigate, NavLink } from 'react-router-dom-v5-compat';

// UI
import MemberHeader from './common/MemberHeader';
import NotesModule from './Modules/Notes';
import ActivitiesContext from 'context/Activities/ActivitiesContext';
import { ChevronRightRounded } from '@material-ui/icons/';
import { makeStyles } from '@material-ui/core/styles';
import OverflowMenu from 'common/components/Nav/OverflowMenu';
import EditProviderSetup from '../Edit/Modules/EditProviderSetup';
import EditClinical from '../Edit/Modules/EditClinical';
import EditFamily from '../Edit/Modules/EditFamily';
import EditPeers from '../Edit/Modules/EditPeers';
import EditProfile from '../Edit/Modules/EditProfile';

const MemberModule = ({
  data,
  error,
  loading,
  overviewHeaderProps = {},
  headerProps,
  listTabs,
  asideCollapseProps,
  // dialogProps,
  // actionDialogProps,
  // memberId,
  // memberType,
}) => {

  const location = useLocation();

  console.log('[Member Module] nav data location', location);
  console.log('[Member Module] Raw Member Data', data);
  console.log('[Member Module] useLocation', location)

  // Data: State
  const { memberId, memberType, isPatient } = useMemberStateContext();
  
  const staffMetrics = overviewHeaderProps?.textList;
  
  console.log('[MemberModule] listTabs', listTabs)
  // Actions: Setup
  // const hasActivateDialog = !data?.activatedAccount;
  // const hasDischargeDialog = data?.status?.includes('FLAGGED_FOR_DISCHARGE');
  // const hasActionDialogs = hasDischargeDialog || hasActivateDialog;

  // const dischargeDialogProps = {};

  // const ActionDialogs = props => {
  //   return (
  //     <>
  //       {hasDischargeDialog ? <DischargeMember {...dischargeDialogProps} /> : null}
  //       {hasActivateDialog ? <ActivateMember {...dialogProps} /> : null}
  //     </>
  //   );
  // };

  // Render
  if (error) return <ErrorScreen />;

  // const patientRoutes = [
  //   { 
  //     path: '/patients/:id', 
  //     element: <Fragment><PreloadHeader memberId={memberId} memberType={memberType} /><Outlet /></Fragment>,
  //     children: [
  //       {path: "messaging", element: <Messaging />},
  //       {path: "activities", element: <p>Schedule</p>},
  //       {path: "notes", element: <NotesWrapper />, children: [
  //         {
  //           path: "create",
  //           element:
  //           <ModalRoute title="Add Note" handleClose={handleClose}>
  //             <NoteAddOptions />
  //           </ModalRoute>,
  //         },
  //       ]},
  //       { path: "labs", element: <Labs />},
  //     ]
  //   },
  // ]

  const isEditing = location?.state?.isEditing === true

  return (
    <Fragment>

      {isEditing 
        ? <MemberEditModuleNav isEditing={true} memberId={memberId} overflowMenuList={headerProps?.overflowMenu} />
        : <MemberModuleNav memberId={memberId} overflowMenuList={headerProps?.overflowMenu} />
      }

      <Routes>
        <Route path="/patients/:id">
          <Route index element={loading && isPatient ? <PreloadHeader memberId={memberId} memberType={memberType} /> : <OverviewWrapper config={asideCollapseProps} />} />
          <Route path="messaging" element={<MessagingModule />} />
          <Route path="activities" element={
            <Fragment>
              <ActivitiesContext>
                <Activities _id={memberId} isEmbedded isMemberModule />
                <Outlet />
              </ActivitiesContext>
            </Fragment>
          } />
          <Route path="notes/*" element={<NotesModule />} />
          <Route path="labs" element={<LabsWrapper />} />
          <Route path="surveys" element={<SurveysWrapper />} />
          <Route path="requests" element={<RequestsWrapper />} />
        </Route>
      </Routes>

      <Routes>
        <Route path="/patients/:id/edit">
          <Route index element={<EditWrapper />} />
          <Route path="caron" element={<EditProviderSetup />} />
          <Route path="clinical" element={<EditClinical />} />
          <Route path="family" element={<EditFamily />} />
          <Route path="peers" element={<EditPeers />} />
        </Route>
      </Routes>

    </Fragment>
  );
};

export default MemberModule;

const EditWrapper = () => {
  return (
  <Fragment>
    <EditProfile />
    <Outlet />
  </Fragment>
  )
}

const MemberModuleNav = ({memberId, overflowMenuList }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 40px', borderRadius: '8px 8px 0 0', backgroundColor: '#fff', borderBottom: '1px solid #E0E0E0'}}>
      <nav style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '0 20px 0 0'}}>
        <NavLink 
          to="/patients/"
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: '10px 10px 10px 0', color: '#000'}}
        >
          Patients
        </NavLink>
        <ChevronRightRounded color="disabled" />
        <NavLink 
          to={`/patients/${memberId}`}
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: 10, color: '#000'}}
        >
          Profile
        </NavLink>
      </nav>
      
      <nav style={{display: 'flex', flexDirection: 'row', flex: 1, margin: '0 20px'}}>
        <NavLink end to={`/patients/${memberId}`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Overview</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/messaging`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Messaging</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/activities`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Schedule</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/notes`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Notes</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/labs`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Labs</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/surveys`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Surveys</NavTab>
          )}
        </NavLink>
      </nav>

      <nav>
        <OverflowMenu list={overflowMenuList} />
      </nav>
    </div>
  )
};


const MemberEditModuleNav = ({memberId, overflowMenuList, isEditing }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 40px', borderRadius: '8px 8px 0 0', backgroundColor: '#fff', borderBottom: '1px solid #E0E0E0'}}>
      <nav style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '0 20px 0 0'}}>
        <NavLink 
          to="/patients/"
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: '10px 10px 10px 0', color: '#000'}}
        >
          Patients
        </NavLink>
        <ChevronRightRounded color="disabled" />
        <NavLink 
          to={`/patients/${memberId}/edit`}
          state={{isEditing: true }}
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: 10, color: '#000'}}
        >
          Edit
        </NavLink>
      </nav>
      
      <nav style={{display: 'flex', flexDirection: 'row', flex: 1, margin: '0 20px'}}>
        <NavLink end to={`/patients/${memberId}/edit`} state={{isEditing: true}}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Profile</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/caron`} state={{isEditing: true }}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Caron</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/clinical`} state={{isEditing: true }}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Clinical Path</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/family`} state={{isEditing: true }}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Family</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/peers`} state={{isEditing: true }}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Peers</NavTab>
          )}
        </NavLink>
      </nav>

      <nav>
        <OverflowMenu list={overflowMenuList} />
      </nav>
    </div>
  )
}


const NavTab = ({isActive, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.tab}>
      <div className={classes.tabLabel}>{children}</div>
      <div className={classes.tabIndicator} style={{backgroundColor: isActive ? '#245C98' : 'transparent'}}></div>
    </div>
  )
};


const useStyles = makeStyles(theme => ({
  tab: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
  },
  tabLabel: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '20px 15px',
    fontSize: 16,
    fontFamily: 'Averta Semibold',
    color: '#245C98',
  },
  tabIndicator: {
    height: 6, 
    borderRadius: '3px 3px 0 0',
  },
}))

const ScheduleWrapper = ({_id, isEmbedded, isMemberModule}) => {
  return (
    <ActivitiesContext>
      <Activities _id={_id} isEmbedded={isEmbedded} isMemberModule={isMemberModule} />
    </ActivitiesContext>
  )
}

const LabsWrapper = () => {
  return (
    <Fragment>
      <MemberHeader children={<LabsHeader />} />
      <Labs />
      <Outlet />
    </Fragment>
  )
}

const SurveysWrapper = () => {
  return (
    <Fragment>
      <MemberHeader />
      <Surveys />
      <Outlet />
    </Fragment>
  )
}

const RequestsWrapper = () => {
  return (
    <Fragment>
      <MemberHeader children={<LabsHeader />} />
      <Labs />
      <Outlet />
    </Fragment>
  )
}

const MessagingModule = () => {
  return (
    <Fragment>
      <MemberHeader children={null} />
      <Messaging />
      <Outlet />
    </Fragment>
  )
}

const OverviewWrapper = ({config}) => {
  return (
    <Fragment>
      <MemberHeader children={<OverviewHeader staffMetrics={{}} />} />
      <Overview asideCollapseProps={config} />
      <Outlet />
    </Fragment>
  )
}