import React from "react";
import { Box, Grid, Button } from "@material-ui/core";

import styles from "./index.module.scss";
import ReactSVG from "react-svg";
import imgLogo from "common/assets/light-logo.svg";
import Footer from "common/components/Footer/Footer";
import {useAuthNContext} from "context/Auth/useAuthContext";

const AuthError = () => {

  const {handleUserSignout} = useAuthNContext();

  const handleLogout = () => {
    handleUserSignout();
  };

  return <Grid container component="main">
    <Grid container className={styles.container}>
      <Grid item xs={12}>
        <ReactSVG src={imgLogo} className={styles.logo}/>
      </Grid>

      <Grid item xs={12} className={styles.wrapper}>
        <Grid item xs={11} sm={10} md={6}>

        <Box>
            <Box align="center" className={styles.greet}>
              <Box mb={4} fontSize={40}>Oops!</Box>
            </Box>
          </Box>

          <Box p={8} className={styles.box}>
            <Box textAlign="center">
              <Box mb={4} fontSize={22}>Something went wrong. Please sign out and try again.</Box>

              <Button
                className={styles.btn}
                variant="outlined"
                color="primary"
                onClick={handleLogout}>
                Sign Out
              </Button>

            </Box>
          </Box>

        </Grid>
      </Grid>

      <Footer/>
    </Grid>
  </Grid>;
};

export default AuthError;