import React, { useEffect, useState } from 'react';
import styles from './MyAccount.module.scss';
import { Grid, Link } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import TextField from 'common/components/InputFields/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import Button from 'common/components/Button/ButtonSimple';
import { GET_OUTLOOK_URL, DISCONNECT_OUTLOOK_TOKEN, REFRESH_OUTLOOK_TOKEN } from 'data/graphql/outlook';
import { DISCONNECT_GOOGLE_TOKEN, GET_GOOGLE_CALENDAR_URL, SAVE_GOOGLE_TOKEN } from 'data/graphql/googleCalendar';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';

const ExternalCalendars = ({ staff, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    hasGoogleToken: staff.account.hasGoogleToken,
    hasOutlookToken: staff.account.hasOutlookToken,
    googleToken: '',
    changed: [],
    outlookExpiresAt: staff.account.outlookExpiresAt,
    isGoogleConnectClicked: false,
  });

  const { hasOutlookToken, hasGoogleToken } = staff.account;

  const [disconnectOutlookToken, { data: disconnected, loading }] = useMutation(DISCONNECT_OUTLOOK_TOKEN);
  const [disconnectGoogleToken, { data: disconnectedGoogle /* , loading: loadingGoogle */ }] = useMutation(
    DISCONNECT_GOOGLE_TOKEN
  );
  const [refreshOutlookToken, { data: refreshed }] = useMutation(REFRESH_OUTLOOK_TOKEN, {
    onCompleted: data => {
      setState({ ...state, outlookExpiresAt: data.refreshOutlookToken.outlookExpiresAt });
      if (data) {
        enqueueSnackbar('Outlook token refreshed', { variant: 'success' });
      } else {
        enqueueSnackbar('Outlook connection issue', { variant: 'error' });
      }

      //need to commit this and push it to beta as locally I can't reproduce the calendar disconnecting after a couple of seconds
      // setTimeout(() => {
      //   refetchDashboard(); // TODO get the new outlook events !!!!
      // }, 250);
    },
  });
  const [saveGoogleToken, { data: googleResponse }] = useMutation(SAVE_GOOGLE_TOKEN);

  const [getOutlookURL, { data: outlookURL, loading: loadingOutlookURL }] = useLazyQuery(GET_OUTLOOK_URL);
  const [getGoogleURL, { data: googleURL /* , loading: loadingGoogleURL */ }] = useLazyQuery(GET_GOOGLE_CALENDAR_URL);

  useEffect(() => {
    (async () => {
      if (outlookURL) {
        console.log('[outlookURL]', outlookURL);
        window.location.href = outlookURL.getOutlookAuthURL;
      }
      if (googleURL && !state.isGoogleConnectClicked) {
        console.log('[googleURL]', outlookURL);
        window.open(googleURL.getGoogleAuthURL);
        setState({ ...state, isGoogleConnectClicked: true });
      }
      if (disconnected) {
        enqueueSnackbar('Outlook Calendar disconnected.', {
          variant: 'success',
        });
        await refetch();
      }
      if (disconnectedGoogle) {
        enqueueSnackbar('Google Calendar disconnected.', {
          variant: 'success',
        });
        await refetch();
      }
      if (googleResponse) {
        enqueueSnackbar('Google Calendar connected.', { variant: 'success' });
        await refetch();
      }
      if (refreshed) {
        // Valerii, this token refresh takes a very long time given it's waiting on Microsoft.
        // Please setup a loading state for the "Refresh" button.
        // When clicked, disabled the button and add a small spinner next to it so user knows it's in progress.

        enqueueSnackbar('Outlook Calendar token refreshed.', {
          variant: 'success',
        });
        await refetch();
      }
    })();
  }, [outlookURL, googleURL, disconnected, disconnectedGoogle, staff, googleResponse]);

  return (
    <Formik
      initialValues={{
        outlookToken: state.outlookToken,
        googleToken: state.googleToken,
      }}
    >
      {props => (
        <Form>
          <Grid container direction="column" spacing={1} className={styles.external_calendars}>
            <Grid item>
              <Typography variant="h3">Connect with External Calendars</Typography>
            </Grid>
            <Grid item className={styles.subtitle}>
              <Grid container xs={5}>
                <Typography variant="subtitle1">
                  Sync inRecovery with your Microsoft Outlook and Google Calendar.
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid container item xs={12} alignItems="baseline">
                  <Grid item xs={3}>
                    <Typography variant="h4" htmlFor="state.outlookToken">
                      Microsoft Outlook
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item container xs={3} content="flex-end">
                    <Link>How to?</Link>
                  </Grid>
                </Grid>
                <Grid item direction="column">
                  {hasOutlookToken && (
                    <Typography variant="caption" className={styles.help_text}>
                      <WarningIcon fontSize="default" /> Your token expires at{' '}
                      {moment(state.outlookExpiresAt).format('MMM DD,YYYY - hh:mm a')}
                    </Typography>
                  )}
                  {hasOutlookToken && moment(state.outlookExpiresAt).isBefore(moment()) && (
                    <Button
                      type="button"
                      disabled={loading}
                      className={classnames(styles.grid_padding)}
                      onClick={async () => await refreshOutlookToken()}
                    >
                      Refresh
                    </Button>
                  )}
                  {hasOutlookToken && (
                    <Typography variant="caption" className={styles.help_text}>
                      <LinkIcon fontSize="default" /> Connected
                    </Typography>
                  )}
                  {!hasOutlookToken && (
                    <Typography variant="caption" className={styles.help_text}>
                      <LinkOffIcon fontSize="default" /> Your calendar is disconnected
                    </Typography>
                  )}
                  {hasOutlookToken ? (
                    <Button
                      type="button"
                      disabled={loading}
                      className={classnames(styles.disconnect, styles.grid_padding)}
                      onClick={async () => await disconnectOutlookToken()}
                    >
                      Disconnect
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      disabled={loadingOutlookURL}
                      className={classnames(styles.grid_padding)}
                      onClick={() => getOutlookURL()}
                    >
                      {moment(state.outlookExpiresAt).isSameOrBefore(moment().format()) ? 'Refresh Token' : 'Connect'}
                    </Button>
                  )}
                </Grid>

                <Grid container item xs={12} alignItems="baseline">
                  <Grid item xs={3}>
                    <Typography variant="h4" htmlFor="state.googleToken">
                      Google Calendar
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item container xs={3} content="flex-end">
                    <Link>How to?</Link>
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="baseline">
                  <Grid item xs={6} className={styles.google_grid}>
                    <Field
                      name="googleToken"
                      component={TextField}
                      placeholder="Enter your google code"
                      style={{ display: hasGoogleToken ? 'none' : 'flex' }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-start">
                  <Grid item direction="column">
                    {!hasGoogleToken ? (
                      <Typography variant="caption" className={styles.help_text}>
                        <LinkOffIcon fontSize="default" /> Your calendar is disconnected
                      </Typography>
                    ) : (
                      <Typography variant="caption" className={styles.help_text}>
                        <LinkIcon fontSize="default" /> Connected
                      </Typography>
                    )}
                    {hasGoogleToken ? (
                      <Button
                        className={classnames(styles.disconnect, styles.grid_padding)}
                        type="button"
                        onClick={() => disconnectGoogleToken()}
                      >
                        Disconnect
                      </Button>
                    ) : (
                      <>
                        <Button
                          className={classnames(styles.grid_padding)}
                          onClick={() => getGoogleURL()}
                          type="button"
                        >
                          Connect
                        </Button>
                        <Button
                          style={{ marginLeft: 15 }}
                          type="button"
                          className={classnames(styles.grid_padding)}
                          disabled={props.values.googleToken.length < 10}
                          onClick={() =>
                            saveGoogleToken({
                              variables: { code: props.values.googleToken },
                            })
                          }
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ExternalCalendars;
