import React, { useState, useContext, useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';

import styles from './ChatPage.module.scss';
import { Box, Typography } from '@material-ui/core';
import SearchButton from 'common/components/Button/SearchButton/SearchButton';
import { ButtonDots } from 'common/components/Button/Button';
import MessageInput from 'common/components/InputFields/MessageInput/MessageInput';
import soberCircleImage from 'common/assets/soberCircleCropped.png';
import UsersTyping from './UsersTyping';
import InfiniteScrollReverse from 'react-infinite-scroll-reverse';
import ChatMessageItemNew from './ChatMessageItemNew';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import classNames from 'classnames';

import { ChatContext } from 'context/Chat/Chat';
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';
import { getMemberById } from 'context/Members/MembersContext';

const ChatPage = props => {
  const {
    roomId,
    hideHeader,
    shortVariant,
  } = props;

  const {
    enterChannel,
    currentChannel,
    currentChannelMessages,
    sendMessage: sendMessageSB,
    leaveChannel
  } = useContext(ChatContext);

  let scrollRef = React.useRef(null);

  const { userId } = useAccountContext();
  const {activePatients, activeFamily, staff} = useMembersContext();

  const memberCollection = [...activePatients, ...activeFamily, ...staff]
  const chatUser = currentChannel?.members.find(u => u.userId !== userId);
  const member = getMemberById(memberCollection, chatUser?.userId)

  console.log('[ChatPage] user', member, 'chatUser', chatUser, 'memberCollection', memberCollection)
 

  const [searchText, setSearch] = useState([]);
  const [myTimeout, setMyTimeout] = useState(null);

  // const isPrivateSession = customData.type === 'PrivateSession';
  const isSoberCircle = currentChannel?.customType === 'SoberCircle';

  const scrollToBottom = () => {
    if (scrollRef && !scrollRef.hasOwnProperty('current')) {
      scrollRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    enterChannel(roomId);
    return () => leaveChannel(roomId);
  }, [roomId]);

  const sendMessage = message => {
    sendMessageSB(message);
    scrollToBottom();
  };

  const onUserTyping = () => {
    currentChannel.startTyping();

    clearTimeout(myTimeout);

    const _myTimeout = setTimeout(() => {
      currentChannel.endTyping();
    }, 2000);

    setMyTimeout(_myTimeout);
  }

  const onSearchChange = e => {
    setSearch(e.split(' '));
  };

  const avatarProps = { 
    _id: member?._id,
    firstName: member?.firstName, 
    lastName: member?.lastName,
    image: member?.image || member?.emrPic,
    type: member?.type,
    size: 'small',
  }

  let renderAvatar = <MemberAvatar {...avatarProps} />;

  if (isSoberCircle) {
    renderAvatar = (
      <Avatar
        src={soberCircleImage}
        className={styles.image}
        alt={`Sober Circle Avatar`}
      />
    )
  }

  return (
    <Box className={styles.room}>
      <Box>
        <Box className={styles.header} style={{ display: hideHeader ? 'none' : 'flex' }}>
          <Box className={styles.avatars}>
            {renderAvatar}
            <Typography className={styles.room_name}>
              {isSoberCircle
                ? currentChannel.name : member
                  ? `${member?.firstName} ${member?.lastName}`
                  : ''
              }
            </Typography>
          </Box>

          <Box className={styles.right_menu}>
            <SearchButton onChange={onSearchChange} />
            <ButtonDots />
          </Box>

        </Box>

        <Box className={styles.chatBox}>
          <Box className={styles.messages_wrapper}>

            <InfiniteScrollReverse
              className={classNames(
                styles.messages,
                {
                  [styles.short]: shortVariant
                }
              )}
              hasMore={!'chatkit.hasMoreMessages'}
              isLoading={!'chatkit.loadMessagesStarted'}
              loadMore={() => 'fetchMoreData'}
              loadArea={10}>

              {currentChannelMessages.map((item, key) => (
                <ChatMessageItemNew {...{ searchText, key, ...item }} userId={userId} member={member} />
              ))}

            </InfiniteScrollReverse>

            <Box className={styles.chatroom_footer}>
              <UsersTyping memberId={member?._id} member={member} />
            </Box>

          </Box>

          <MessageInput
            onClick={sendMessage}
            onTyping={onUserTyping} />

        </Box>

      </Box>
    </Box>
  );
};

export default ChatPage;
