import React, { useContext } from 'react';
import QuillNoteBare from 'common/components/InputFields/QuillNote/QuillNoteBare';

// Data
import { BookingContext } from 'context/BookingContext';

const Description = props => {
    const {description, setDescription} = useContext(BookingContext);
    const handleOnChange = value => {
        setDescription(value);
    }
    const quillNoteProps = {
        name: "description",
        value: description,
        onChange: handleOnChange, 
        placeholder: "Appointment description"
    }
    return <QuillNoteBare {...quillNoteProps}/>
};

export default Description;
