import {
  GET_DASHBOARD_QUERY,
  GET_AGREEMENT,
  ACCEPT_TERMS_AND_CONDITIONS_AGREEMENT,
  STORE_DASHBOARD_REFETCH,
} from 'actions/types';
const initialDashboardData = {
  user: { permissions: [], account: {} },
  organization: {},
  patients: [],
  familyList: [],
  staff: [],
  careProviders: [],
  dischargedPatients: [],
  dischargedFamilyList: [],
};
const initialState = {
  enumValues: {},
  dashboardData: initialDashboardData,
  isNotStaff: null,
  hasError: false,
  isNewUser: false,
  isAgreementTerms: null,
  error: null,
  refetchFn: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_QUERY:
      const { dashboardData, error } = action.payload;
      if (dashboardData) {
        const enumValues = {};

        if (dashboardData.enumValues) {
          dashboardData.enumValues.forEach(item => {
            enumValues[item.name] = item.values;
          });

          delete dashboardData.enumValues;
        }
        return {
          ...state,
          enumValues,
          dashboardData: { ...state.dashboardData, ...dashboardData },
          hasError: false,
          isNotStaff: false,
          isNewUser:
            dashboardData.user && dashboardData.user.permissions && dashboardData.user.permissions.length === 0,
        };
      }
      if (error && error.message && error.message.includes("You don't have access"))
        return {
          ...state,
          isNotStaff: true,
        };
      else
        return {
          ...state,
          hasError: true,
        };
    case GET_AGREEMENT:
      return {
        ...state,
        isAgreementTerms: action.isAgreementTerms,
      };

    case ACCEPT_TERMS_AND_CONDITIONS_AGREEMENT:
      return {
        ...state,
        isAgreementTerms: true,
      };

    case STORE_DASHBOARD_REFETCH:
      return {
        ...state,
        refetchFn: action.payload,
      };
    //TODO view info from DashboardQueryWrapper HOC !!!
    // case STORE_PATIENTS:
    //   return {
    //     ...state,
    //     patients: action.payload.patients,
    //     refetchPatients: action.payload.refetch,
    //   };

    default:
      return state;
  }
};

export default reducer;
