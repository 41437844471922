import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom-v5-compat';
import moment from 'moment';

import { Button, makeStyles } from '@material-ui/core';
import {ViewListIcon, ViewGridIcon } from '@heroicons/react/outline';
import NoteCard from '../common/NoteCard';
import EmptyState from 'common/components/EmptyState/EmptyState';

const PendingNotes = ({drafts = [], loading}) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const [layout, setLayout] = useState('grid');
  const isGrid = layout === 'grid';
  const hasDrafts = drafts?.length;

  console.log('[Pending Notes] drafts', drafts)

  return (
    <div className={styles.wrapper}>
      <div style={{flex: 1}}>
      
        <div className={styles.header}>
          <div style={{flex: 1}}><h1 className={styles.title}>My Drafts</h1></div>
          <div>
            <Button onClick={() => setLayout('list')}><ViewListIcon className={layout === 'list' ? styles.activeIcon : null} /></Button>
            <Button onClick={() => setLayout('grid')}><ViewGridIcon className={layout === 'grid' ? styles.activeIcon : null} /></Button>
          </div>
        </div>
        
        <div className={styles.body}>
          {hasDrafts 
            ? <div className={styles.contentBody}>
              {drafts.map(note => <NoteCard
                  key={note?._id}
                  isGrid={isGrid}
                  width={isGrid ? 240 : '100%'}
                  height={isGrid ? 180 : 'auto'}
                  direction={isGrid ? 'column' : 'row'}
                  date={moment(note?.noteDate).format('LL')}
                  type={note?.subType?.displayName}
                  content={note?.note}
                  buttonTitle="Edit Draft"
                  handleClick={() => navigate(
                    `draft/${note?._id}`, 
                    {state: { isDraft: true, draftId: note?._id, draftNote: note } }
                  )}
                />
              )}
            </div>
            : loading ? <EmptyState title="Loading Drafts" text="Give us a sec..." /> : <EmptyState title="No draft notes 👍" text="Great job, keep it up!" />
          }
        </div>
      </div>    
    </div>
  )
}

export default PendingNotes;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: 500,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    margin: '10px 0',
    fontSize: '1.5em',
    fontFamily: 'Avenir Next Demi Bold',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  activeIcon: {
    color: '#60a5fa'
  },
});