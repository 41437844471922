import React from 'react'
import clsx from 'clsx'
import { Button as MaterialButton, makeStyles } from '@material-ui/core'

export const Button = ({ children, className, ...props }) => {
    const stylesBase = useStylesBase();
    const classNames = clsx(className, stylesBase.button)

    return <MaterialButton {...props} className={classNames} > 
        {children}
    </MaterialButton>
}

const useStylesBase = makeStyles({
    button: {
        borderRadius: '50px',
        textTransform: 'initial',
    }
});