import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    maxHeight: 200,
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: '#1e1e1e',
    borderRadius: 5,
  },
  header: {
    flex: 1,
    padding: 5,
    paddingLeft: 15,
    color: 'white',
    backgroundColor: '#37383b',
    position: 'sticky',
    fontFamily: 'Courier, monospace'
  },
  data: {
    padding: 20,
    display: 'flex',
    fontFamily: 'Courier, monospace'
  },
  title: { color: '#21d726' },
  values: { width: '50%', color: '#bfbfbf' },
  errors: { width: '50%', color: '#e30a17' }
}));

const reactEnv = process.env.REACT_APP_STAGE;
const canDisplay = reactEnv === 'local'//; || 'dev';

export const FormDevTools = ({ values, errors }) => {
  const classes = useStyles();

  // if (!canDisplay) return null;

  return (
    <div className={classes.container}>
      <div className={classes.header}>f0rm1k output | env: {reactEnv}</div>

      <div className={classes.data}>

        <div className={classes.values}>
          <span className={classes.title}>values:</span>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(values, null, 2)}
          </pre>
        </div>

        <div className={classes.errors}>
          <span className={classes.title}>errors:</span>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(errors, null, 2)}
          </pre>
        </div>

      </div>
    </div>
  )
};