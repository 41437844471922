import React, {useState} from 'react'
import PropTypes from 'prop-types'

// Material
import {Dialog, DialogContent, DialogActions, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

// Components
import Title from './Title';
import Skeleton from './Skeleton';
import BookingSetup from './BookingSetup';
import MultiScheduler from './MultiScheduler';

// Context
import {useCountRenders} from 'common/hooks/useCountRenders';
import {useMemberStateContext} from 'context/Member/useMemberContext'
import {useScheduleModuleContext} from 'context/Activities/useActivitiesContext';

const AppointmentBooker = ({loading = false}) => {
  useCountRenders('AppointmentBooker v2')
  
  const styles = useStyles()
  const {memberId} = useMemberStateContext();
  const {bookAppointmentOpen, setBookAppointmentOpen} = useScheduleModuleContext();
  
  // State
  const [open, setOpen] = useState(false);
  const isDataLoaded = true;

  const multiSchedulerProps = { 
    _id: memberId, 
    isEmbedded: true,
    myAvailability: true,
    isMemberModule: true,
    editingFormVisible: false,
  }  

  // Handlers
  const handleClose = () => setBookAppointmentOpen(false);
  
  return (
    <Dialog
      open={bookAppointmentOpen}
      onClose={handleClose}
      maxWidth={'lg'}
    >
      <Title onClose={handleClose} />
      <DialogContent classes={{root: styles.root}}>      
        <BookingSetup memberId={memberId} />
        <div style={{ overflow: 'scroll' }}>
          {isDataLoaded ? (
            <MultiScheduler { ...multiSchedulerProps } />
          ) : (
            <Skeleton />
          )}
        </div>
    </DialogContent>
    </Dialog>
  )
}

AppointmentBooker.propTypes = {
  loading: PropTypes.bool,
}

export default AppointmentBooker;

const useStyles = makeStyles({
  root: { 
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    minHeight: 625,
    overflow: 'hidden',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    backgroundColor: 'white',
  }, 
});
