/**
 * Pluralize any string based on data (Array only for now)
 * @param {string} string string to pluralize
 * @param {Array} data array object with data 
 * @param {string} ending ending for pluralization (defaults to 's')
 * @returns {string} pluralized string
 */
const pluralize = (string, data, ending = "s") => {
    const checkArray = Array.isArray(data)
    const pluralize = checkArray ? data?.length > 1 : false;
    return pluralize ? `${string}${ending}` : `${string}`
} 

const stringCapitalize = (string) => {
    if (typeof string === 'string' && string.length >= 2) {
        const words = string.split(' ');
        return words.reduce((fullWord, currWord) => {
            const [firstLetter, ...rest] = currWord;
            return `${fullWord} ${firstLetter?.toUpperCase() + rest.join('')?.toLowerCase()}`
        }, '');
    } else {
        return string;
    }
}

const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const booleanToYesNo = (value) => {
    if (typeof value === "boolean") {
        return value === true ? "Yes" : "No"
    } else {
        return value
    }
};

// For Avatar Colors
const numberFromText = text => { 
    const charCodes = text
      .split('')
      .map(char => char.charCodeAt(0))
      .join('');
    return parseInt(charCodes, 10);
};

const generateColors = (colors, data) => {
    return colors[numberFromText(data) % colors.length];
}

function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(' ');  
    if (lastIndexOfSpace === -1) return str;
    return str.substring(0, lastIndexOfSpace);
}

export { 
  pluralize,
  stringCapitalize,
  removeLastWord,
  numberFromText,
  generateColors,
  booleanToYesNo,
  bytesToSize,
}