import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'common/components/Pagination/Pagination';

const WithPagination = settings => Component => {
    
    const dataKeyName = settings?.dataKeyName ? settings.dataKeyName : 'data';

    const WithPaginationComponent = ({ ...props }) => {

        const [total, setTotal] = useState(props[dataKeyName]?.length);
        const [current, setCurrent] = useState(1);
        const perPageOptions = [8, 16, 32, 48, { label: 'All', value: total }];
        const [perPage, setPerPage] = useState(perPageOptions[0]);

        const dataLoading = props?.dataLoading;
        const dataCount = props?.data?.length;

        useEffect(() => {
            if(!dataLoading) setTotal(dataCount)
        }, [dataLoading, dataCount])

        // Methods
        const handlePageChange = nextPage => setCurrent(nextPage);

        const handlePerPageChange = ({ target: { value } }) => {
            setCurrent(1);
            setPerPage(value); 
        };

        const handleTotalUpdate = length => {
            setCurrent(1);
            setTotal(length);
        }

        const paginationProps = {
            perPageOptions,
            handlePageChange,
            handlePerPageChange,
            total: total, 
            current, perPage,
        }

        return <Component {...props} 
            paginTotal={total}
            paginPerPage={perPage}
            paginCurrenPage={current}
            paginTotalUpdate={handleTotalUpdate}
            Pagination={() => <Pagination {...paginationProps}/>} 
        />
    }

    return WithPaginationComponent;
}

WithPagination.propTypes = {
    dataKeyName: PropTypes.string,
}


export default WithPagination;