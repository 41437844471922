import React from 'react'
import CallItemSkeleton from '../../CallItem/Skeleton/Skeleton'


const CallListSkeleton = ({ items = 5 }) => {

  const elements = new Array(items).fill(0);
  let callItemSkeletons = [];

  const createSkeletonsList = () => {
    for (const [index] of elements.entries()) {
      callItemSkeletons.push(<CallItemSkeleton key={index} />);
    }
  };
  createSkeletonsList();

  return <React.Fragment>{callItemSkeletons}</React.Fragment>;
    
}

export default CallListSkeleton