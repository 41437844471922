import React, { Fragment } from 'react';

// Main components
import ErrorScreen from 'common/components/Errors/DefaultError';

import EditClinical from './Modules/EditClinical';
import EditFamily from './Modules/EditFamily';
import EditProfile from './Modules/EditProfile';
import EditProviderSetup from './Modules/EditProviderSetup';
import EditPeers from './Modules/EditPeers';

// Context
import {useMemberStateContext} from 'context/Member/useMemberContext';

// Navigation
import { Routes, Route, useLocation, Outlet, useNavigate, NavLink } from 'react-router-dom-v5-compat';

// UI
import { ChevronRightRounded } from '@material-ui/icons/';
import { makeStyles } from '@material-ui/core/styles';
import OverflowMenu from 'common/components/Nav/OverflowMenu';

const MemberEditModule = ({
  data,
  error,
  loading,
  headerProps,
}) => {

  const location = useLocation();

  // Data: State
  const { memberId, memberType, isPatient } = useMemberStateContext();


  // Render
  if (error) return <ErrorScreen />;

  return (
    <Fragment>
      
      <MemberEditModuleNav isEditing={true} memberId={memberId} overflowMenuList={headerProps?.overflowMenu} />

      <Routes>
        <Route path="/patients/:id/edit">
          <Route index element={<EditWrapper />} />
          <Route path="caron" element={<EditProviderSetup />} />
          <Route path="clinical" element={<EditClinical />} />
          <Route path="family" element={<EditFamily />} />
          <Route path="peers" element={<EditPeers />} />
        </Route>
      </Routes>
      
    </Fragment>
  );
};

export default MemberEditModule;

const EditWrapper = () => {
  return (
  <Fragment>
    <EditProfile />
    <Outlet />
  </Fragment>
  )
}

const MemberEditModuleNav = ({memberId, overflowMenuList, isEditing }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 40px', borderRadius: '8px 8px 0 0', backgroundColor: '#fff', borderBottom: '1px solid #E0E0E0'}}>
      <nav style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '0 20px 0 0'}}>
        <NavLink 
          to="/patients/"
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: '10px 10px 10px 0', color: '#000'}}
        >
          Patients
        </NavLink>
        <ChevronRightRounded color="disabled" />
        <NavLink 
          to={`/patients/${memberId}/edit`}
          style={{fontSize: 22, fontFamily: 'Averta Bold', margin: 10, color: '#000'}}
        >
          Edit
        </NavLink>
      </nav>
      
      <nav style={{display: 'flex', flexDirection: 'row', flex: 1, margin: '0 20px'}}>
        <NavLink end to={`/patients/${memberId}/edit`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Profile</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/caron`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Caron</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/clinical`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Clinical Path</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/family`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Family</NavTab>
          )}
        </NavLink>
        <NavLink to={`/patients/${memberId}/edit/peers`}>
          {({ isActive }) => (
            <NavTab isActive={isActive}>Peers</NavTab>
          )}
        </NavLink>
      </nav>

      <nav>
        <OverflowMenu list={overflowMenuList} />
      </nav>
    </div>
  )
}

const NavTab = ({isActive, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.tab}>
      <div className={classes.tabLabel}>{children}</div>
      <div className={classes.tabIndicator} style={{backgroundColor: isActive ? '#245C98' : 'transparent'}}></div>
    </div>
  )
};

const useStyles = makeStyles(theme => ({
  tab: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
  },
  tabLabel: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '20px 15px',
    fontSize: 16,
    fontFamily: 'Averta Semibold',
    color: '#245C98',
  },
  tabIndicator: {
    height: 6, 
    borderRadius: '3px 3px 0 0',
  },
}))
