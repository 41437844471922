import React from 'react';
import styles from './styles.module.scss';
// import RadioButton from './Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
// import PropTypes from 'prop-types';

const RadioGroup = ({
  value,
  touched,
  id,
  label,
  className,
  children,
  form: { setFieldValue, errors },
  defaultValue,
  field: { name },
  disabled = false,
  ...props
}) => {
  return (
    <MuiRadioGroup className={styles.radio_group} {...props}>
      {children.map((child, key) => (
        <React.Fragment key={key}>
          {React.cloneElement(child, { disabled })}
        </React.Fragment>
      ))}
      <Typography className={styles.error}>{errors[name]}</Typography>
    </MuiRadioGroup>
  );
};

// !FIXIT pass correct prop
// RadioGroup.propTypes = {
//   children: PropTypes.shape({type: PropTypes.oneOf([RadioButton]) })
// }

export default RadioGroup;
