import { Messages } from 'config/settings';

/**
 * Patient Member Activation Warning Messages 
 * @param {Object} data Patient member data object 
 * @param {Object} routes Dashboard navigation routes
 * @returns {Array} warningDetails with warning data 
 */
export const activationWarnings = (data) => {
    const warningMessage = {
        deviceTokens: {
            item: Messages.PATIENT_DEVICE_TOKEN_MISSING,
            help: Messages.PATIENT_DEVICE_TOKEN_MISSING_HELP,
        },
    }

    const warningDetails = [
        ...(!data?.deviceTokens || data?.deviceTokens?.length < 1) ? [warningMessage.deviceTokens] : [],
    ];
    return warningDetails;

}
