import React from 'react';
import { Button } from "@material-ui/core";
import { shadows } from "styles/variables";

// Utils
import parse from 'html-react-parser'
import { removeLastWord } from 'common/helpers/textHelpers';

const NoteCard = ({
  isGrid, 
  date, 
  type, 
  content, 
  buttonTitle, 
  handleClick, 
  width = 'auto', 
  height = 'auto', 
  direction = 'row'
}) => {

  const parsedPreview = parse(String(content).slice(0, 90)+'...')
  
  return (
    <div style={{
      display: 'flex', 
      flexDirection: direction,
      flexWrap: 'wrap',
      justifyContent: 'space-between', 
      margin: isGrid ? '0.6em' : '0.4em', 
      padding: 20, 
      width: width,
      height: height,
      borderRadius: 5, 
      border: '1px solid #e4e4e7', 
      boxShadow: isGrid ? shadows.light : null, 
    }}>
      <div style={{flex: 1}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: isGrid ? 'space-between' : 'flex-start', alignItems: 'center', flex: 1, marginBottom: isGrid ? 20 : 5}}>
          <p style={{ color: '#71717a', fontWeight: 700, fontSize: '0.9em', marginRight: 10}}>{date}</p>
          {type 
            ? <div style={{padding: '4px 10px', borderRadius: 25, backgroundColor: '#e4e4e7'}}><p style={{color: '#71717a', fontSize: '0.8em'}}>{removeLastWord(type)}</p></div> 
            : null
          }
        </div>
        <div>
          <p style={{ fontSize: '1em', lineHeight: 1.25}}>{parsedPreview}</p>
        </div>
      </div>
      <Button variant="text" color="primary" onClick={handleClick}>{buttonTitle}</Button>
    </div>
  )
}

export default NoteCard;

