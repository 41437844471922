import React, { useRef, useState } from 'react';

// Hooks
import { withStyles } from '@material-ui/styles';
import { makeStyles, darken } from '@material-ui/core/styles';

// Components
import {
  DialogTitle, 
  DialogContent, 
  Button, Dialog,
} from '@material-ui/core';
import Cropper from 'react-cropper';
import DropZone from "common/components/Dropzone";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: '100%',
    maxWidth: 550,
  },
  title: {

  },
  content: {
    maxWidth: 550,
  },
  cropContainer: {
    display: 'flex',
    height: 490,
  },
  controls: {
    display: 'flex',
    position: 'absolute', 
    bottom: 0,
    left: '50%',
    width: '50%',
    transform: 'translateX(-50%)',
    height: 80,
    alignItems: 'center'
  },
  slider: {
    padding: '22px 0px',
  },
  footerActions: {
    display: 'flex',
    marginTop: theme.spacing(2),

    '& > button + button': {
      marginLeft: 'auto',
    }
  }
}));

const ButtonSuccess = withStyles({
  label: { color: '#fff' },
  root: { 
    backgroundColor: '#2fb87e',
    "&:hover": { backgroundColor: darken('#2fb87e', .12) }
  }
})(Button);

const AvatarDialog = ({ open, handleClose, handleSubmit }) => {

  const classes = useStyles();
  const cropperEl = useRef(null);

  const [avatar, setAvatar] = useState(null);

  const handleFilesChange = file => setAvatar(file);

  const onAvatarClose = () => {
    handleClose();
    setAvatar(null);
  }

  const onSave = () => handleSubmit({
    content: cropperEl?.current.getCroppedCanvas({ fillColor: '#fff' }).toDataURL(),
    name: avatar.name,
    type: avatar.type
  });

  // Props
  const dropzoneProps = {
    maxSize: 5 * 1024 * 1024,
    onChange: handleFilesChange,
    accept: "image/png,image/jpeg",
  }

  const cropperProps = {
    ref: cropperEl,
    aspectRatio: 1,
    src: avatar?.content,
    style: { height: 485 },
    zoomable: false,
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={onAvatarClose}>
      
      <DialogTitle disableTypography className={classes.title} children="Upload Profile Photo"/>
      
      <DialogContent dividers className={classes.content}>

        {!avatar ? (
          <DropZone {...dropzoneProps}/>
        ) : (
          <Cropper {...cropperProps}/>
        )}

        <div className={classes.footerActions}>
          <Button color="primary" variant="contained" onClick={onAvatarClose} children="Cancel"/>
          {!!avatar && <ButtonSuccess variant="contained" onClick={onSave} children="Save"/>}
        </div>

      </DialogContent>

    </Dialog>
  )
}

AvatarDialog.propTypes = {

}

export default AvatarDialog;