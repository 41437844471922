import { createSelector } from 'reselect';

const getNoteSetup = state => state.dashboard.dashboardData.organization.noteSetup;

export const getNoteTypesForMemberType = createSelector(
  getNoteSetup,
  (_, type) => type, (noteSetup, type) => {

    if (noteSetup !== null) {
      return [...noteSetup.types]
        .map(a => { return { ...a, subTypes: [...a.subTypes]} })
        .filter(t => t.subTypes = t.subTypes.filter(st => st.memberTypes.includes(type)))
        .filter(t => t.subTypes.length > 0);
    } else { 
      console.log('[Notes] createSelector: noteSetup.types is null');
    }
    
  }
);
