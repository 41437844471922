import React from 'react';
import moment from 'moment';
import ReactSVG from 'react-svg';
import { useSelector } from "react-redux";
import { Grid } from '@material-ui/core';

// Style
import styles from './Login.module.scss';
import logo from 'common/assets/light-logo.svg';

// Components
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm"
import ResetPasswordForm from "./ForgotPasswordForm/ResetPasswordForm"

const ForgotPassword = () => {
    const screen = useSelector(state => state.forgotPassword.currentScreen);

    const AuthResetForm = () => {
        if (screen === "RESETPASSWORD") return <ResetPasswordForm/>
        return <ForgotPasswordForm/>
     };

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={12}>
                <ReactSVG src={logo} className={styles.logo} />
            </Grid>

            <Grid item lg={12} xs={12} md={12} sm={12} className={styles.wrapper}>
                <h2 className={styles.greet}>Hey there 👋</h2>
                <div className={styles.form}>
                    <AuthResetForm />
                </div>
            </Grid>

            <p className={styles.copyright}>&copy; {moment().year()} inRecovery Co.</p> 
        </Grid>
    )
};

export default ForgotPassword;