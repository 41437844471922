import React, { useState } from 'react';
import styles from './TermsAndConditions.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMutation } from '@apollo/client';
import { ACCEPT_TERMS_AND_CONDITION_AGREEMENT } from 'data/graphql/dashboard';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
import {controlDialogClose} from 'common/helpers/material';
import { acceptAgreement } from 'actions/authentication';
import { useAuthNContext } from 'context/Auth/useAuthContext';

const TermsAndConditions = ({ acceptAgreement, dashboardData, refetchFn }) => {
  const [isOpen, setIsOpen] = useState(true);
  const {handleUserSignout} = useAuthNContext();
  
  const [acceptAgreementMutation] = useMutation(ACCEPT_TERMS_AND_CONDITION_AGREEMENT, {
    onCompleted: () => {
      refetchFn();
    },
  });

  const handleClose = () => {
    setIsOpen(!isOpen);
    acceptAgreement();
    acceptAgreementMutation();
  };

  return (
    <Dialog 
      open={isOpen}
      onClose={(_, reason) => controlDialogClose(reason, handleClose)}
      scroll={'paper'}
      disableEscapeKeyDown={true}
      aria-labelledby="terms-and-conditions-modal"
      className={styles.terms_and_conditions}>
      <DialogTitle id="terms-and-conditions-title" className={styles.title}>
        Terms & Conditions
      </DialogTitle>
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.text}>
          <span className={styles.subtitle}>Your Agreement</span>

          <div
            dangerouslySetInnerHTML={{
              __html: dashboardData.organization.termsAndConditions,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={() => handleUserSignout()} className={styles.decline}>
          Decline
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          Yes, I agree to Terms
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { isAgreementTerms } = state.dashboard;
  const { dashboardData } = state.dashboard;
  const { refetchFn } = state.dashboard;

  return {
    ...ownProps,
    dashboardData,
    refetchFn,
    isAgreementTerms,
  };
};

const mapDispatchToProps = dispatch => ({
  acceptAgreement: bindActionCreators(acceptAgreement, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
