import React, { createContext, useState, useEffect } from 'react';

// Hooks
import { useSnackbar } from 'notistack';

// Data
import { Messages } from 'config/settings';
import useMembersContext from 'context/Members/useMembersContext';


// GraphQL
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_FULL_PATIENT_INFO } from 'data/graphql/patients';
import { GET_FAMILY_MEMBER } from 'data/graphql/family';
import { GET_FULL_STAFF_INFO } from 'data/graphql/staff';
import { 
    ACTIVATE_MEMBER, 
    RESEND_ACTIVATE_MEMBER, 
    CANCEL_DISCHARGE_MEMBER 
} from 'data/graphql/member';

export const MemberStateContext = createContext();
export const MemberUpdaterContext = createContext(); 
MemberStateContext.displayName = 'MemberStateContext';
MemberUpdaterContext.displayName = 'MemberUpdaterContext';

const MemberContext = ({ children, value }) => {
    console.log('[MemberContext] value', value)
    // Setup
    const memberId = value?.memberId;
    const memberType = value?.memberType;
    const isPatient = memberType === 'patient';
    const isFamily = memberType === 'family';
    const isStaff = memberType === 'staff';
    console.log('[MemberContext]', memberId, memberType, isPatient, isFamily, isStaff)

    // Context Data
    const { refetchMembers } = useMembersContext();

    // Hooks
    const { enqueueSnackbar } = useSnackbar();

    // Context State
    const [isDischarging, setIsDischarging] = useState(false);
    const [isMemberLoaded, setIsMemberLoaded] = useState(false);
    const [memberData, setMemberData] = useState();

    /// Move this to the Overview Wrapper or Context
    // Activation Dialog State
    const [activateOpen, setActivateOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const handleActivateOpen = () => setActivateOpen(true);
    const handleActivateClose = () => setActivateOpen(false);

    // Discharge Dialog
    const [openDischargeDialog, setDischargeOpen] = useState(false)
    const handleDischargeOpen = () => setDischargeOpen(true)
    const handleDischargeClose = () => setDischargeOpen(false)

    // Member Queries
    const [getMemberPatient,
        { loading: patientLoading, data: patientData, error: patientError, refetch: patientRefetch }
    ] = useLazyQuery(GET_FULL_PATIENT_INFO, {
        variables: { _id: memberId },
        onCompleted: () => console.log('[MemberContext] Successfully fetched GET_FULL_PATIENT_INFO:', memberId),
        onError: () => console.log('[MemberContext] Query Error: GET_FULL_PATIENT_INFO for', memberId),
    });
    const [getMemberFamily,
        { loading: familyLoading, data: familyData, error: familyError, refetch: familyRefetch }
    ] = useLazyQuery(GET_FAMILY_MEMBER, {
        variables: { memberId: memberId },
        onCompleted: () => console.log('[MemberContext] Successfully fetched GET_FAMILY_MEMBER:', memberId),
        onError: () => console.log('[MemberContext] Query Error: GET_FAMILY_MEMBER for', memberId),
    });
    const [getMemberStaff, 
        { loading: staffLoading, data: staffData, error: staffError, refetch: staffRefetch }
    ] = useLazyQuery(GET_FULL_STAFF_INFO, {
        variables: { memberId: memberId },
        onCompleted: () => console.log('[MemberContext] Successfully fetched GET_FULL_STAFF_INFO:', memberId),
        onError: () => console.log('[MemberContext] Query Error: GET_FULL_STAFF_INFO for', memberId),
    });

    const getMember = () => {
        if (isPatient) return getMemberPatient();
        if (isFamily) return getMemberFamily();
        if (isStaff) return getMemberStaff();
    }

    const refetch = () => {
        if(isPatient && patientData) return patientRefetch();
        if(isFamily && familyData) return familyRefetch();
        if(isStaff && staffData) return staffRefetch();
    }

    // Setup Data
    const processMemberData = async (id, data, type, key) => {
        try {
            console.log(`[MemberContext] Processing Member Data`);
            setMemberData(data?.[key]);
            setIsMemberLoaded(true);
        } catch (error) {
            console.log(`[MemberContext] Error Fetching! ${type}`, id, 'Error Details:', error);
        }
    }

    // Initialize Context
    useEffect(() => {
        console.log('[MemberContext] Mounted successfully');
        return () => console.log('[MemberContext] Unmounted, peace out!')
    }, [])

    useEffect(() => {
        if(memberId) console.log(`[MemberContext] Initializing ${memberType}`, memberId);
        return () => { 
            if(memberId) console.log(`[MemberContext] Exiting ${memberType}`, memberId);
        }
    }, [memberId, memberType])

    // Initialize: Load Data
    useEffect(() => {
        if(memberType) getMember();
    }, [memberType])

    // Initialize: Data
    useEffect(() => {
        if(memberId) {
            if(isPatient) processMemberData(memberId, patientData, 'patient', 'patientFullInfo')
            if(isFamily) processMemberData(memberId, familyData, 'family', 'getFullFamilyMemberInfo')
            if(isStaff) processMemberData(memberId, staffData, 'staff', 'getFullStaffInfo')
        }
    }, [patientData, familyData, staffData])


    useEffect(() => {
        if(isMemberLoaded) console.log('[MemberContext] Member Setup Success', memberData)
    }, [isMemberLoaded])

    // Mutations: Member Activation
    const [handleActivate, { loading: activateLoading }] = useMutation(
        ACTIVATE_MEMBER,
        {
            variables: { memberId },
            onCompleted: () => {
                setShowSuccess(true);
                setTimeout(() => {
                    refetch();
                    refetchMembers();
                }, 250);
                setTimeout(() => handleActivateClose(), 3000);
                setTimeout(() => setShowSuccess(false), 3500);
            },
        }
    );

    // const handleSendAppInvite = () => console.log('[MemberContext] handleSendAppInvite')
    const [handleSendAppInvite, { error: resendError }] = useMutation(RESEND_ACTIVATE_MEMBER, {
        variables: { memberId },
        onCompleted: ({ resendCognitoInvitation }) => {
            if (resendCognitoInvitation) {
            enqueueSnackbar(`${Messages.MEMBER_RESEND_INVITATION_SUCCESS}`, { //to: ${value.email}
                variant: 'success',
            })
        }},
        onError: (error) => {
            enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
                variant: 'error',
            })
            console.log('[MemberContext] handleSendAppInvite Error', error)
        },
    });

    useEffect(() => {
        if (resendError)
            enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
            variant: 'error',
        });
    }, [resendError]);

    
    const handleDischargePatient = () => console.log('[MemberContext] handleDischargePatient')
    // // Mutations: Member Discharge
    // const [handleDischargePatient] = useMutation(
    //     DISCHARGE_MEMBER, {
    //         //variables: null,
    //         onCompleted: () => {
    //             console.log('[MemberContext] Discharge Successful for', memberId)
    //             enqueueSnackbar('Patient has been queued for discharge', { variant: 'success' })
    //             setTimeout(() => {
    //                 refetch();
    //             }, 250);
    //             handleDischargeClose();
    //         },
    //         onError: (e) => {
    //             console.log("DISCHARGE_MEMBER", e);
    //             enqueueSnackbar('Error during discharge', { variant: 'error' })
    //         },
    //     }
    // );

    // const handleCancelDischarge = () => console.log('[MemberContext] handleCancelDischarge')
    const [handleCancelDischarge] = useMutation(
        CANCEL_DISCHARGE_MEMBER, {
            variables: { 
                memberId: memberId,
            },
            onCompleted: () => {
                enqueueSnackbar('Discharge has been canceled', { variant: 'success' })
                setTimeout(() => {
                    refetch();
                }, 250);
            },
            onError: (e) => {
                console.log("CANCEL_DISCHARGE_MEMBER", e);
                enqueueSnackbar('Error while cancelling', { variant: 'error' })
            },
        }
    );

    const handleDischargeTest = (dischargeSetup = 'nada') => {
        setIsDischarging(true);
        console.log('[MemberContext] handleDischargeTest', dischargeSetup)
        setTimeout(() => {
            setIsDischarging(false)
            handleDischargeClose()
        }, 3500);
        //handleDischargePatient({variables: dischageSetup})
    }

    console.log('[MemberContext] memberData', memberData)

    // Additional Data
    const memberRelations = memberData?.relations || [];
    const isDischarged = memberData?.status === 'DISCHARGED';
    const hasMultiEpisodes = memberData?.episodeOfCare?.length > 1;
    const hasPastEpisode = isDischarged || hasMultiEpisodes;
    const deviceTokens = memberData?.deviceTokens;

    const contextValues = { 
        
        //Status
        isDischarging,
        // Data
        memberData,
        memberId,
        memberType,
        memberRelations,
        isPatient,
        isFamily,
        isStaff,
        isDischarged,
        hasMultiEpisodes,
        hasPastEpisode,
        deviceTokens,
        // Patient
        patientLoading,
        patientError,
        // patientRefetch,
        // Family
        familyLoading,
        familyError,
        // familyRefetch,
        // Staff
        staffLoading,
        staffError,
        // staffRefetch,
        // Activation
        showSuccess,
        activateOpen,
        activateLoading,

        // Discharge
        openDischargeDialog,
    }

    const updaterValues = {
        // Refetch
        refetch,
        patientRefetch,
        familyRefetch,
        staffRefetch,
        // Activation
        handleActivate,
        handleSendAppInvite,
        handleActivateClose,
        handleActivateOpen,
        // Discharge
        handleDischargeTest,
        handleDischargeOpen,
        handleDischargeClose,
        handleCancelDischarge,
        handleDischargePatient,
    }
    
    return(
        <MemberStateContext.Provider value={contextValues}>
            <MemberUpdaterContext.Provider value={updaterValues}>
                { children }
            </MemberUpdaterContext.Provider>
        </MemberStateContext.Provider>
    )

};

export default MemberContext;