import React from 'react';
import logo from '../../assets/oncall.png';
import {makeStyles} from '@material-ui/core';

const OnCall = ({isOnCall}) => {
  const styles = useStyles();
  return (
    <div>
      {isOnCall ? <img src={logo} className={styles.logo} alt="On-Call" /> : null}
    </div>
  );
};
export default OnCall;

const useStyles = makeStyles({
  logo: {
    width: 85,
  },
})