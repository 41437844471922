import {
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_LOADING,
  CONFIRM_USER_FAILTURE,
} from './actionTypes';
import { Auth } from 'aws-amplify';

export const checkCurrentUser = (userName, password) => async dispatch => {
  try {
    dispatch({
      type: CONFIRM_USER_LOADING,
    });
    await Auth.signIn(userName, password);
    dispatch({ type: CONFIRM_USER_SUCCESS });
  } catch (error) {
    dispatch({
      type: CONFIRM_USER_FAILTURE,
      payload: 'Incorrect password',
    });
  }
};
