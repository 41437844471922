import React from 'react'
import { Box } from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import styles from './Skeleton.module.scss';

const CallListSkeleton = ({items = 2}) => {

  const LabItemSkeleton = () => {

  return <div className={styles.skeleton}>
      <Skeleton animation="wave" className={styles.name} />
      <Skeleton animation="wave" className={styles.result} />
      <Skeleton animation="wave" className={styles.button} />
    </div>
  }

  return <Box height={345}>
    <LabItemSkeleton />
    <LabItemSkeleton />
  </Box>;
    
}

export default CallListSkeleton