import React, { useState } from 'react';
import styles from './ChipInput.module.scss';

// Material UI
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

/* 
TODO 
x Insert delete icon
x Use MemberAvatar
- Add delete handler
- Color only on avatar region
- make controlled component
*/

const ChipAutosuggest = ({
  suggestions,
  field,
  onSelectChange,
  placeholder,
  multiple = true,
  isSelected = false,
  disabled = false,
  ...restProps
}) => {
  console.log('Multi ChipAutosuggest', restProps, suggestions);

  let memberLabel = option => `${option.firstName} ${option.lastName}`;
  let typeLabel = option => option.text;

  return (
    <Autocomplete
      multiple={multiple ? true : false}
      disabled={disabled}
      options={suggestions}
      filterSelectedOptions
      // value={isSelected ? isSelected : null}
      onChange={(e, items) => {
        console.log(
          'ChipMultiiiii',
          'items:',
          items,
          'default:',
          restProps.default
        );
        onSelectChange(items);
      }}
      className={`${styles.chip} ${restProps.className}`}
      getOptionLabel={option => {
        if (field === 'member' && option.firstName) return memberLabel(option);
        if (field === 'type' && option.text) return typeLabel(option);
      }}
      //getOptionSelected={(option, value) => option._id === value._id}
      renderTags={(value, getTagProps) => {
        console.log('renderTags chipinput', multiple, isSelected, value);
        if (multiple)
          return value.map((option, index) => {
            console.log('[ChipInput] options');
            return (
              <Chip
                variant="outlined"
                avatar={<ChipAvatar data={option} />}
                label={
                  field === 'member' ? memberLabel(option) : typeLabel(option)
                }
                {...getTagProps({ index })}
              />
            );
          });
        else
          return (
            <Chip
              variant="outlined"
              avatar={<ChipAvatar data={value} />}
              label={field === 'member' ? memberLabel(value) : typeLabel(value)}
              {...getTagProps(1)}
            />
          );
      }}
      renderInput={params => {
        console.log('renderInput', isSelected, params);
        return (
          <TextField
            {...params}
            fullWidth
            margin="none"
            classes={{ root: styles.textfield }}
            variant="outlined"
            InputProps={{ ...params.InputProps, endAdornment: null }}
            placeholder={params.InputProps.startAdornment ? null : placeholder}
          />
        );
      }}
    />
  );
};

export default ChipAutosuggest;

const ChipAvatar = ({data}) => {

  console.log('ChipAvatar data', data);

  const avatarProps = {
    image: data?.image,
    firstName: data?.firstName,
    lastName: data?.lastName,
    disabled: true,
    size: 'xxsmall',
  };

  return <MemberAvatar {...avatarProps} />;
};