import React from 'react'
import { Controller } from 'react-hook-form'
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'

const CheckboxList = ({ control, name, items }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...inputProps }, fieldState: { invalid, error } }) => {
        const handleOnChange = (e) => {
          const val = e?.target?.value
          const checked = e?.target?.checked
          if (checked) {
            onChange([...value, val])
          } else {
            onChange(value.filter((v) => v !== val))
          }
        }
        return (
          <FormGroup {...inputProps}>
            {items.map(({ firstName, lastName, relation, _id }) => {
              let label = `${firstName} ${lastName}`
              relation?.name && (label += `(${relation.name})`)
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={handleOnChange}
                      value={_id}
                      checked={value?.indexOf(_id) !== -1}
                      color="primary"
                    />
                  }
                  label={label}
                />
              )
            })}
          </FormGroup>
        )
      }}
    />
  )
}

export default CheckboxList
