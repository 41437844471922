import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useWatch } from 'react-hook-form'

//Components
import Input from 'common/components/Forms/Input'
import Select from 'common/components/Forms/Select'
import Button from 'common/components/Forms/Button'
import SummaryItem from 'common/components/Forms/SummaryItem'
import Switch from 'common/components/Forms/Switch/Switch'
import { Multiselect } from 'common/components/Forms/Autocomplete/Multiselect'
import { MultiselectInput } from 'common/components/Forms/Autocomplete/MultiselectInput'
import ChipAutosuggest from 'common/components/InputFields/ChipInput/ChipAutosuggest'
import Repeat from 'components/Scheduler/common/Recurrence/Repeat'
import Datetime from 'common/components/Forms/Date/Datetime'
import { LocationInput } from 'common/components/Forms/Autocomplete'
import Radio from 'common/components/Forms/Radio'

import { TAG_DATA, TYPES } from 'components/Scheduler/components/AppointmentForm/data'

const durationOptions = [
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
  { value: '120', label: '120' },
]

const QUESTIONS_MAX = 5
const TAGS_MAX = 5

const tags = TAG_DATA

const BaseActivityForm = ({
  onSubmit,
  onBack,
  backButtonTitle,
  submitButtonTitle,
  loading,
  disabled,
  patients,
  setRecurrence,
  processTypes,
  templateDetails,
  editActivity,
  defaultValues,
  resolver,
  templateId,
}) => {
  const classes = useStyles()

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues,
    resolver,
  })

  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [])

  useEffect(() => {
    if (templateDetails && templateId) {
      const {
        type,
        name: title,
        scheduling: { start: date, duration },
        address: location,
        points,
        tags,
        processType,
        addWorksheetQuestions,
      } = templateDetails
      reset({
        ...defaultValues,
        type,
        title,
        duration,
        location,
        points,
        tags,
        processType,
        addWorksheetQuestions,
      })
    }
  }, [templateDetails, templateId, reset])

  useEffect(() => {
    if (editActivity) {
      console.log('[EditActivityPopup] data ', editActivity)
      const {
        type,
        title,
        scheduling: { start: date, duration },
        address: { displayLine: location },
        points,
        tags,
        processType,
        questions: addWorksheetQuestions,
        invitedMembers,
      } = editActivity
      const participants = invitedMembers.map((memberId) => patients.find((p) => p._id === memberId))
      reset({
        ...defaultValues,
        type,
        title,
        duration,
        location,
        points,
        tags,
        processType,
        addWorksheetQuestions,
        date,
        participants: participants,
      })
    }
  }, [editActivity, reset])

  const showWorksheetQuestions = watch('processType') === 'worksheet'
  const deafaultDateValue = editActivity ? editActivity.scheduling.start : new Date()
  const selectedTime = useWatch({ control, name: 'date', defaultValue: deafaultDateValue })

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit((data) => onSubmit(data, dirtyFields))} data-testid="base-form">
        <div className={classes.body}>
          <div className={`${classes.activityDetaits} ${classes.margin10}`}>
            <div className={classes.detailsBody}>
              <SummaryItem title="Type">
                <Radio id="type" name="type" items={TYPES} control={control} loading={loading} />
              </SummaryItem>
              <SummaryItem title="Title">
                <Input
                  id="title"
                  control={control}
                  name="title"
                  fullWidth
                  loading={loading}
                  inputProps={{ 'data-testid': 'base-input' }}
                />
              </SummaryItem>
              <SummaryItem title="Patient(s)">
                <ChipAutosuggest
                  suggestions={patients}
                  field="member"
                  name="participants"
                  control={control}
                  loading={loading}
                />
              </SummaryItem>
              <SummaryItem title="Date">
                <Datetime
                  id="date"
                  control={control}
                  name="date"
                  fullWidth
                  loading={loading}
                  disabled={disabled?.date}
                />
              </SummaryItem>
              <SummaryItem title="Repeat">
                <Repeat
                  id="repeat"
                  selectedTime={selectedTime}
                  setRecurrence={setRecurrence}
                  showTitle={false}
                  loading={loading}
                />
              </SummaryItem>
              <SummaryItem title="Duration">
                <Select
                  id="duration"
                  control={control}
                  name="duration"
                  items={durationOptions}
                  loading={loading}
                  disabled={disabled?.duration}
                />
              </SummaryItem>
              <SummaryItem title="Location">
                <LocationInput id="location" control={control} fullWidth name="location" loading={loading} />
              </SummaryItem>
            </div>
          </div>

          <div className={`${classes.inputBox} ${classes.margin10}`}>
            <div className={classes.sectionTitle}>inRecovery/EMMA</div>
            <div className={`${classes.sectionSubtitle} ${classes.small}`}>
              Help drive enagement and personalization
            </div>
            <SummaryItem title="Points">
              <div className={classes.row}>
                <Input
                  id="points"
                  control={control}
                  name="points"
                  loading={loading}
                  inputProps={{ 'data-testid': 'points-input' }}
                />
                <div className={classes.small}>Motivate patients to do the activity.</div>
              </div>
            </SummaryItem>
            <SummaryItem title="Tags">
              <Multiselect
                id="tags"
                control={control}
                items={tags}
                name="tags"
                loading={loading}
                maxLength={TAGS_MAX}
                style={{ maxHeight: '70px', overflowY: 'auto' }}
              />
            </SummaryItem>
            <SummaryItem title="After">
              <Select
                id="after"
                control={control}
                items={processTypes}
                name="processType"
                fullWidth
                loading={loading}
              />
            </SummaryItem>
            {showWorksheetQuestions ? (
              <MultiselectInput
                control={control}
                items={templateDetails?.addWorksheetQuestions}
                name="addWorksheetQuestions"
                disabled
                maxLength={QUESTIONS_MAX}
              />
            ) : null}
          </div>
        </div>
        <Box className={classes.buttonsBox}>
          <Button variant="contained" className={classes.cancelBtn} onClick={onBack}>
            {backButtonTitle}
          </Button>
          <Switch
            name="saveAsTemplate"
            control={control}
            label={'Save as new template'}
            defaultValue={defaultValues.saveAsTemplate}
          />

          <Button type="submit" variant="contained" color="primary">
            {submitButtonTitle}
          </Button>
        </Box>
      </form>
    </div>
  )
}

// Styles
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: 'solid 1px lightgray',
    padding: 20,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '17px',
    // padding: '5px',
    marginBottom: '5px',
    marginTop: '2px',
  },
  inputBox: {
    border: '1px solid lightgray',
    borderRadius: '10px',
    boxShadow: '2px 2px 2px lightgray, -2px -2px 2px lightgray',
    width: '300px',
    padding: '10px',
  },
  activityDetaits: {
    flexGrow: 1,
    padding: '10px',
  },
  activityHeader: {
    display: 'flex',
    flexDirection: 'row',
    // border: 'solid 1px green',
    alignItems: 'center',
  },
  sectionSubtitle: {
    marginBottom: '20px',
    // border: '1px solid orange',
  },
  categoryTitle: {
    width: '100px',
    fontWeight: 'bold',
  },
  inputTitle: {
    width: '100px',
    fontWeight: 'bold',
    paddingTop: '10px',
  },
  buttonsBox: {
    // border: 'solid 1px red',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    backgroundColor: 'white',
    marginRight: '10px',
    boxShadow: 'none',
    color: 'gray',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  small: {
    fontSize: '11px',
    padding: '0 5px',
    flexGrow: '2',
  },
  margin10: {
    margin: '10px',
  },
})

export default BaseActivityForm
