import React from 'react'
import {Skeleton} from '@material-ui/lab'
import styles from './Skeleton.module.scss'

const CallItemSkeleton = () => {
    return (
        <div className={styles.skeleton}>
            <Skeleton animation="wave" className={styles.time} />
            <Skeleton animation="wave" className={styles.image} variant="circle"/>
            <Skeleton animation="wave" className={styles.name}/>
            <Skeleton animation="wave" className={styles.action}/>
        </div>       
    )
}
export default CallItemSkeleton