import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';

const getActivities = state => state.callscreen.activities;
const getPatients = state => state.dashboard.dashboardData.patients;
const getMembers = state => [...state.dashboard.dashboardData.patients, ...state.dashboard.dashboardData.familyList];
const getStaff = state => [...state.dashboard.dashboardData.staff, ...state.dashboard.dashboardData.careProviders];

export const getFirstActivity = createSelector(
    getActivities,
    items => items && items.length && items[0]
)

export const getLastActivity = createSelector(
    getActivities,
    items => items && items.length && items[items.length - 1]
)

//TODO I believe this activitiesSelector is a big mess, it should be removed
export const activitiesSelector = createSelector(
    getMembers,
    getActivities,
    (members, activities) => {
        let activitiesWithImage = [];
        if (!members.length || !activities?.length) return activitiesWithImage
        activities.forEach(({
            scheduling, type, host, 
            createdBy, status, name, 
            _id, invitedMembers, callDetails, 
            callInfo: { callStatus }, cohosts
        }) => {
            members.forEach(patient => {
                if (patient._id === invitedMembers[0]) {
                    activitiesWithImage.push({
                        callStatus, 
                        createdBy, status, name, 
                        invitedMembers, callDetails, 
                        host, scheduling, type, _id, 
                        cohosts,
                        avatar: patient.image,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                    })
                }
            }) 
                
        });
    
        return activitiesWithImage;
    }
)

export const getClosestCall = createSelector(
    getActivities, getMembers,
    (_activities, members) => {

        const mutateActivity = (activity, members) => {
            const patientId = activity.invitedMembers[0];
            const patient = members.find(p => p._id === patientId);
        
            const timeTillStart = moment(
                moment(activity.scheduling.start).diff()
            ).subtract(10, 'minutes');
            console.log('timeTillStart', timeTillStart)

            // const hour = 1 * 60 * 60 * 1000;
            // const moreThanHour = timeTillStart >= hour;
        
            const shouldSetTimer = timeTillStart >= 10 * 60 * 1000; // moreThanHour || +timeTillStart.format('mm') >= 10;
        
            return patient ? {
                image: patient.image,
                name: patient.firstName + ' ' + patient.lastName[0],
                initials: patient.firstName[0] + patient.lastName[0],
                startsAt: moment(activity.scheduling.start).format('hh:mm a'),
                msTillStart: timeTillStart,
                shouldSetTimer: shouldSetTimer,
            } : null;
        }

        // Filter past activities & sort from closest to latest activity;
        const activities = _activities
            .filter(a => moment(a.scheduling.start).diff(moment()) > 0)
            .sort((a, b) => new Date(a.scheduling.start) - new Date(b.scheduling.start));

        // case: NO ACTIVITIES
        if (!activities.length) return null;

        // case: ONE OR MORE ACTIVITIES
        const closestActivity = activities[0];

        return mutateActivity(closestActivity, members);

    }
)


export const getActivityMembers = createSelector(
    getMembers,
    getStaff,
    (_, call) => call,
    ( members, staff, call)=> {
        let attendees = [];
        _.forEach(call.invitedMembers, i => {
            let member = members.find(m => m._id === i);
            if (member) attendees.push(member)
        })
        _.forEach(call.cohosts, c => {
            let member = staff.find(m => m._id === c);
            if (member) attendees.push(member)
        })
      return attendees;
    }
)