import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse, IconButton } from '@material-ui/core';

import classes from './NotesItem.module.scss';

const NotesItem = ({ date, type, author, noteType, text }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => setExpanded(!expanded);

    return (
        <div className={classNames(
            classes["notes-card"],
            { [classes[`notes-card--${type.toLowerCase()}`]]: type },
        )}>
            <div className={classes["notes-card-content"]}>
                <div className={classes["col-date"]}>
                    <div className={classes.date}>{date}</div>
                </div>
                <div className={classes["col-auth"]}>
                    <div className={classes.author}>{author}</div>
                    <div className={classes.type}>{noteType}</div>
                </div>
                <div className={classes["col-text"]}>
                    <Collapse 
                        in={expanded} 
                        timeout="auto"
                        collapsedSize="44px">
                        <div className={classes.text}>{text}</div>
                    </Collapse>
                </div>
                <IconButton
                    className={classNames(classes.expand, {
                        [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more">
                    <ExpandMoreIcon />
                </IconButton>
            </div>
        </div>
    )
}

NotesItem.propTypes = {
    type: PropTypes.oneOf(['PATIENT', 'LAB', 'ADMIN', 'COMPLIANCE']).isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    noteType: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default NotesItem;
