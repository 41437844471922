import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

const Textarea = ({ control, name, defaultValue = '', required = false, maxRows, ...props }) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
    //   formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  return (
    <TextField
      {...inputProps}
      {...props}
      inputRef={ref}
      multiline={true}
      variant="outlined"
      error={invalid}
      helperText={error?.message}
    />
  );
};

Textarea.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};

export default Textarea;
