import React, { useState, useEffect } from 'react';

// UI
import { Box } from '@material-ui/core';
import styles from '../DataTables.module.scss';

// Utils
import _ from 'lodash';
import moment from 'moment';

// Data
import { useQuery } from '@apollo/client';
import { GET_STAFF_REPORT } from 'data/graphql/analytics';

// Table
import SortableTable from 'common/components/Table/SortableTable';
import Filters from 'common/components/PanelFilters/PanelFilters';

const columns = [
  { id: 'name', label: 'Name', width: 120 },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'departmentName', label: 'Department', width: 100 },
  { id: 'lastActive', label: 'Last Active', width: 100, handleColumnSort: 'lastActive' },
  { id: 'totalCaseLoad', label: 'Total', width: 100 },
  { id: 'activatedCaseLoad', label: 'Activated', width: 100 },
  { id: 'totalCalls', label: 'Total Calls', width: 100 },
  { id: 'upcomingCalls', label: 'Upcoming Calls', width: 100 },
  { id: 'totalNotes', label: 'Notes', width: 100 },
  { id: 'totalLabs', label: 'Labs', width: 100 },
  // { id: 'totalSent', label: 'Sent', width: 100 },
  // { id: 'totalReceived', label: 'Received', width: 100 },
  // { id: 'actions', label: 'Actions', width: 100 },
];

const StaffDashActions = ({ data }) => {};

const toTableData = data =>
  !data
    ? []
    : data.map((item, i) => ({
        name: String(`${item.title ? item.title : ''}  ${item.firstName} ${item.lastName}`).trim(),
        status: item.status,
        departmentName: item.department?.name,
        lastActive: item.lastActive ? moment(item.lastActive).fromNow() : 'Not active yet',
        totalCaseLoad: item.totalCaseLoad,
        activatedCaseLoad: item.activatedCaseLoad,
        totalCalls: item.totalCalls,
        upcomingCalls: item.upcomingCalls,
        totalNotes: item.totalNotes,
        totalLabs: item.totalLabs,
       
        // date: item.lastActive 
        // more: !moment(item.dueDate).isAfter() ? (
        //   <LabDashActions data={item} />
        // ) : (
        //   <div className={styles.action_text}>Scheduled</div>
        // ),
      }));

const DataTables = () => {
  // State
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const [staffData, setStaffData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);

  const [momentToday] = useState(moment());
  const [momentOneWeekAhead] = useState(moment().add(1, 'week'));
  const [momentSubstractMonth] = useState(moment().subtract(1, 'months'));
  const [momentSubstractWeek] = useState(moment().subtract(1, 'week'));
  // Fetch Data
  const { data, error, loading, refetch } = useQuery(GET_STAFF_REPORT, {
    variables: {
      filters: {
        startDate: momentSubstractWeek.format(format),
        endDate: momentToday.format(format),
      },
    },
  });

  useEffect(() => {
    if (data) {
      try {
        const converted = toTableData(_.sortBy(data.getStaffStatusReport, 'lastActive').reverse());
        console.log('converted', converted);
        setStaffData(converted);
        setfilteredData(converted);
      } catch (err) {
        console.log(err);
      }
    }
  }, [data]);

  if (loading || error) return <div className={styles.loading}>Loading ...</div>;

  // Methods
  const handleFiltersSubmit = updatedList => setfilteredData(updatedList);

  const filtersProps = {
    data: staffData,
    hasSearch: true,
    noDateColumn: true,
    searchLocalBy: ['name', 'status'],
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: GET_STAFF_REPORT,
      mutate: toTableData,
      variables: { filters: {} },
      variablesPath: 'filters',
      queryName: 'getStaffStatusReport',
    },
    fields: {
      department: {
        label: 'Department',
        initialValue: [],
        placeholder: 'Choose a department',
        fieldPath: 'departmentName',
        fieldValPath: '',
      },
      name: {
        label: 'Status',
        initialValue: [],
        placeholder: 'Choose a status',
        fieldPath: 'status',
        fieldValPath: '',
      },
    },
    initDates: {
      minDate: momentSubstractWeek,
      maxDate: momentToday,
    },
  };

  return (
    <Box className={styles.wrapper}>
      <Filters {...filtersProps} />

      <Box className={styles.data_tables}>
        <Box className={styles.results}>
          <SortableTable
            columns={columns}
            data={filteredData}
            emptyTitle="No analytics yet."
            emptyMessage="Once we receive results, you know where to find them."
            pagination={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataTables;
