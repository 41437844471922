import React from 'react';
import { makeStyles } from '@material-ui/styles';

// Components
import ChipAutosuggest from 'common/components/InputFields/ChipInputMultiple/ChipInput';

// Context
import useMembersContext from 'context/Members/useMembersContext';

const StaffSelect = ({ cohosts, setCohosts, disabled }) => {
  const classes = useStyles();

  // Data
  const {staff} = useMembersContext();
  const activeStaff = staff.filter(m => m.activatedAccount);
  
  // Render Props
  const onStaffSelectChange = selectedStaffs => {
    setCohosts(selectedStaffs.map(s => s._id));
  };
  const createOptionLabel = option => option && `${option.firstName} ${option.lastName}`;

  // Data to bring in:
  const staffSelectProps = {
    suggestions: activeStaff,
    field: 'member',
    onSelectChange: onStaffSelectChange,
    getOptionLabel: createOptionLabel,
    classes: { listbox: classes.patientPopup },
    selectedStaff: cohosts,
    disabled,
  };

  return (
    <div className={classes.patientSelect}>
      <span style={{ marginBottom: 5 }}>Invited Specialists</span>
      <ChipAutosuggest {...staffSelectProps} />
    </div>
  );
};

export default StaffSelect;

const useStyles = makeStyles(theme => ({
  patientSelect: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 20,
  },
  patientPopup: {
    zIndex: 1300,
  },
}));
