import { Messages } from 'config/settings';
/**
 * Family Member Activation Warning Messages 
 * @param {Object} data Family member data object 
 * @param {Object} routes Dashboard navigation routes
 * @returns {Array} warningDetails with warning data 
 */
export const activationWarnings = data => {

  const warningMessage = {
    email: {
      item: Messages.FAMILY_ACTIVATION_WARNING_EMAIL, 
      help: Messages.FAMILY_ACTIVATION_WARNING_EMAIL_HELP,
      route: `/family/${data?._id}/edit/profile`,
    },
    careTeam: {
      item: Messages.FAMILY_ACTIVATION_WARNING_CARETEAM, 
      help: Messages.FAMILY_ACTIVATION_WARNING_CARETEAM_HELP,
      route: `/family/${data?._id}/edit/organization`,
    },
    relatedPatient: {
      item: Messages.FAMILY_ACTIVATION_WARNING_PATIENT, 
      help: Messages.FAMILY_ACTIVATION_WARNING_PATIENT_HELP,
      route: `/patients`,
    }
  }

  const warningDetails = [
    ...(!!!data?.email) ? [warningMessage.email] : [],
    ...(data?.careTeamDetails?.length < 1) ? [warningMessage.careTeam] : [],
    ...(data?.relatedPatients?.length < 1) ? [warningMessage.relatedPatient] : [],
  ];
  return warningDetails;

}


 // Merge Multi-Episode Notes
 /**
  * Merge Multi-episode notes
  * @param {Array} data raw member data from member query 
  * @returns {Array} merged notes including current and all past notes
  */
 export const mergeEpisodeNotes = (data = []) => {
    
  const currentNotes = data?.notes || [];

  const pastEncounters = data?.episodeOfCare
    .map(ep => ep)
    .filter(ep => ep.status === 'finished')
    .map(ep => ep.encounters)

  const pastEncounterNotes = pastEncounters?.map(en => { 
    return { 
      period: en?.[0]?.period, 
      notes: en?.[0]?.notes 
    }
  });
  const pastNotes = pastEncounterNotes?.[0]?.notes || [];

  // Merge Notes
  return [...currentNotes, ...pastNotes]
  
}