import { combineReducers } from "redux";
import keyBy from "lodash/keyBy";
import cid from "common/helpers/cid";
import {
  PATIENT_FAMILY_ADD_RELATION,
  PATIENT_FAMILY_SET_RELATIONS,
  PATIENT_FAMILY_REMOVE_RELATION,
  PATIENT_FAMILY_CHANGE_RELATION
} from "actions/types";
import { RESET_STORE } from "../actions/globalActions";

const relations = (prev = {}, action) => {
  let items;

  switch (action.type) {
    case RESET_STORE:
      return {};
    case PATIENT_FAMILY_CHANGE_RELATION:

      items = { ...prev };

      items[action.cid] = {
        ...items[action.cid],
        ...action.payload
      };

      return items;
    case PATIENT_FAMILY_SET_RELATIONS:

      items = action.payload.map(item => {
        return {
          ...item, 
          cid: cid()
        }
      });

      return keyBy(items, "cid");
    case PATIENT_FAMILY_ADD_RELATION:
      return {
        ...prev,
        [action.payload.cid]: action.payload
      };
    case PATIENT_FAMILY_REMOVE_RELATION:

      items = { ...prev };

      delete items[action.payload.cid];

      return items;
    default:
      return prev;
  }
};

export default combineReducers({
  relations
});