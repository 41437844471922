import React, {useEffect, useRef} from 'react';
import { GET_DRAFT_NOTES } from 'data/graphql/notes';
import {useLazyQuery} from '@apollo/client';
import { useMemberStateContext } from 'context/Member/useMemberContext';

// Navigation
import { Routes, Route, useNavigate } from 'react-router-dom-v5-compat';
import ModalRoute from 'common/navigation/ModalRoute';

// Components: MemberModule
import MemberModuleBase from 'components/Member/View/common/MemberModule';
import MemberHeader from 'components/Member/View/common/MemberHeader';
import DialogTitleAvatar from 'common/components/Dialog/DialogTitleAvatar';

// Components: Notes
import NotesHeaderActions from './common/NotesHeaderActions';
import Notes from './List';
import NoteView from './View/';
import NoteEntry from './Entry/NoteEntry';
import PendingNotes from './List/PendingNotes';
import NoteAddActions from './common/NoteAddActions';

const NotesModule = () => {

  const { memberId, memberData } = useMemberStateContext();
  const navigate = useNavigate();

  // Refs
  const noteEntryRef = useRef();
  const formikRef = useRef();

  const avatarProps = {
    _id: memberData?._id,
    firstName: memberData?.firstName,
    lastName: memberData?.lastName,
    image: memberData?.image || memberData?.emrPic,
    type: memberData?.type,
  }

  const warningNoteDiscard = {
    warnClose: true,
    warnTitle: "Discard Changes",
    warnConfirm: "Discard my work",
    warnMessage: "You didn't save your note. Are you sure you want to discard? Press 'Cancel' to return and Save in Drafts.",
  }

  const [getDraftNotes, {loading: loadingDrafts, data: dataDrafts, refetch: refetchDrafts}] = useLazyQuery(GET_DRAFT_NOTES, {
    variables: {filters: { memberId }},
    onCompleted: (data) => console.log('[Pending Notes] Drafts loaded successfully', data),
    onError: () => console.log('[Pending Notes] Error loading drafts')
  });

  useEffect(() => {
    if(!dataDrafts && memberId) getDraftNotes()
  }, [])

  const draftCount = dataDrafts?.getNoteDrafts?.length;

  const baseUrl = `/patients/${memberId}/notes`;
  const handleClose = () => navigate(baseUrl);

  return (
    <MemberModuleBase>
      <MemberHeader children={<NotesHeaderActions drafts={draftCount} />} />
      <Notes />
      <Routes>
        <Route path="create" 
          element={
            <ModalRoute
              title="Pending Notes"
              memberAvatar={<DialogTitleAvatar memberData={avatarProps} />}
              handleClose={handleClose}
            >
              <PendingNotes drafts={dataDrafts?.getNoteDrafts} loading={loadingDrafts} />
            </ModalRoute>
          }
        />
        <Route path="create/new" 
          element={
            <ModalRoute
              fullscreen 
              maxWidth='lg'
              title="Add Note"
              memberAvatar={<DialogTitleAvatar memberData={avatarProps} />}
              handleClose={handleClose}
              actions={<NoteAddActions formikRef={formikRef} noteEntryRef={noteEntryRef} />}
              {...warningNoteDiscard}
            >
              <NoteEntry formikRef={formikRef} ref={noteEntryRef} />
            </ModalRoute>
          }
        />
        <Route path="create/draft/:noteId" 
          element={
            <ModalRoute 
              fullscreen
              maxWidth='lg'
              title="Edit Draft" 
              memberAvatar={<DialogTitleAvatar memberData={avatarProps} />}
              handleClose={handleClose}
              actions={<NoteAddActions isDraft={true} formikRef={formikRef} noteEntryRef={noteEntryRef}  />}
              {...warningNoteDiscard}
            >
              <NoteEntry isDraft={true} formikRef={formikRef} ref={noteEntryRef} />
            </ModalRoute>
          } 
        />
        <Route path="view/:noteId" 
          element={
            <ModalRoute 
              title="View Note" 
              handleClose={handleClose}
            >
              <NoteView />
            </ModalRoute>
          }
        />
      </Routes>
    </MemberModuleBase>
  )
}

export default NotesModule;