import React from 'react';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './FormikCheckbox.module.scss'

const FormikCheckbox = ({
  field,
  label,
  form: { setFieldValue },
  ...restProps
}) => (
  <FormControlLabel
      classes={{ label: styles.label }}
      className={styles.wrapper}
      control={
      <Checkbox
        name={field.name}
        checked={field.value}
        classes={{ checked: styles.checked }}
        onChange={e => {
          setFieldValue(field.name, e.target.checked);
        }}
        {...restProps}
      />
      }
      label={label || null}
      {...restProps}
    />
);

export default FormikCheckbox;
