import React from 'react';
import TextField from '../TextField/TextField'

const Timepicker = ({...props}) => (
    <TextField
    type="time"
    //defaultValue={Date.now()}
    //className={classes.textField}
    InputLabelProps={{
      shrink: false,
    }}
    inputProps={{
      step: 900, // 15 min
    }}
    {...props}
  />
)

export default Timepicker;