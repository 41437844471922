import React from 'react'
import {
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions, 
  Button,
  TextField, 
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ActivateMemberNew = ({data, isOpen, triggerDialog}) => {
  
  // Data
  const { firstName, lastName, email, phone, mrn, type} = data;
  const hasClinical = ['patient', 'family'];

  // Hooks
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={triggerDialog}
      maxWidth="sm"
      fullWidth
      >
      <DialogTitle className={classes.dialogTitle}>
          Activate Member
        <IconButton aria-label="close" className={classes.closeButton} onClick={triggerDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{marginBottom: 10}}>
          <span style={{fontSize: 18}}>{`${firstName} ${lastName}`}</span>
          <div style={{color: '#ccc'}}>{`(MRN: ${mrn})`}</div>
        </div>
        <div>
          Verify Mobile Phone
          <TextField 
            placeholder={phone}
            variant="outlined"
            margin="none"
            fullWidth
          />
        </div>
        <div>
          Verify Email
          <TextField 
            placeholder={email}
            variant="outlined"
            margin="none"
            fullWidth
          />
        </div>
        {hasClinical.includes(type) &&
          <>
          <div>Care Team Assignment: </div>
          <div>Verify Program: </div>
          </>
        }
      </DialogContent>
      <DialogActions style={{marginBottom: 5}}>
          <Button
            fullWidth
            style={{width: '100%'}}
            variant="contained"
            color="primary"
          >
            Proceed to Activate
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActivateMemberNew;