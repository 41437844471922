import React, { Fragment, useContext } from 'react';
import {makeStyles} from '@material-ui/styles';
import {
    Box,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';

// Data
import { BookingContext } from 'context/BookingContext';
const DURATION_OPTIONS = [15, 30, 45, 50, 60, 90, 120];

const Duration = props => {
    const styles = useStyles();
    const {duration, setDuration} = useContext(BookingContext);
    return (
    <Box>
        <Box>
        <Typography style={{ opacity: 0.6 }}>Duration</Typography>
        <div className={styles.durationSelector}>
            <Select
                labelId="duration-of-call-selector"
                id="duration-of-call-selector"
                variant="outlined"
                defaultValue={duration}
                onChange={e => setDuration(e.target.value)}
            >
                {DURATION_OPTIONS.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
            </Select>
            <span className={styles.durationUnit}>minutes</span>
        </div>
        </Box>
    </Box>
    );
};

const useStyles = makeStyles(theme => ({
    inputPicker: {
        textAlign: 'center',
    },
    durationSelector: {
        display: 'flex',
        alignItems: 'center',
        jutifyContent: 'flex-start',
    },
    durationUnit: {
        marginLeft: 10,
    },
}));

export default Duration;
