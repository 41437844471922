import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';

const styles = theme => ({
  toolbarRoot: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  flexibleSpace: {
    display: 'flex',
    margin: '0 auto 0 0',
    flex: '1 0 0',
    justifyContent: 'flex-end',
  },
  prioritySelector: {
    margin: '0 15px',
  },
  prioritySelectorItem: {
    display: 'flex',
    alignItems: 'center',
  },
  priorityBullet: {
    borderRadius: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'inline-block',
  },
  defaultBullet: {
    background: theme.palette.divider,
  },
  filter: {
    margin: '0px 20px',
  },
});

export const ToolbarWithLoading = withStyles(styles, { name: 'Toolbar' })(
  ({ children, classes, ...restProps }) => (
    <div className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <LinearProgress className={classes.progress} />
    </div>
  )
);

export const ToolBarFlexibleSpace = withStyles(styles, {
  name: 'FlexibleSpace',
})(
  ({
    classes,
    hideAvailability,
    ...restProps
  }) => (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      {/* {!hideAvailability && <AvailabilitySwitcher />} */}
    </Toolbar.FlexibleSpace>
  )
);
