import React from 'react';

const RecurrenceText = ({
  day,
  week,
  frequency,
  text
}) => {
  console.log(frequency);

  return (
  <div style={{ fontSize: '18px', marginTop: '10px', marginBottom: '5px' }}>
    {frequency === 'monthly' &&
      <span>{week?.label} </span>
    }

    {(frequency === 'weekly' || frequency === 'monthly') &&
      <span>{day}</span>
    }
    {(frequency === 'custom') && 
    <span>{text}</span>}
  </div>
  )

}

export default RecurrenceText;