import React from 'react';
import dayjs from 'dayjs';
import { agendaRange } from 'config/settings';

// GraphQL
import { useQuery } from '@apollo/client';
import { GET_CALL_ACTIVITIES, GET_OUTLOOK_EVENTS, GET_GOOGLE_EVENTS } from 'data/graphql';

// Icons
import outlookLogo from 'common/assets/outlook.svg';
import googleLogo from 'common/assets/google-calendar.svg';

const mapExternalEvents = (data, dataKey) =>
  data[dataKey].map(e => ({
    _id: e._id,
    invitedMembers: [],
    scheduling: {
      start: e.startDate,
      end: e.endDate,
    },
    name: e.name,
    type: e.type,
    host: e.host,
    avatar: e.type === 'google' ? googleLogo : outlookLogo,
  }));

const withEvents = (range = agendaRange) => Component => {
  if (['day', 'week', 'month', 'quarter'].indexOf(range) === -1) {
    console.error("Range shoud be one of ['day', 'week', 'month', 'quarter']");
  }
  
  const queryRangeFilter = {
    startDate: dayjs().startOf(range),
    endDate: dayjs().endOf(range),
  };

  const WithEventsComponent = ({ ...props }) => {
    // inRecovery Events
    const { data: irData, error: irError, loading: irLoading } = useQuery(GET_CALL_ACTIVITIES, {
      variables: {
        filters: {
          ...queryRangeFilter,
          type: ['therapySession', 'familySession'],
        },
      },
      pollInterval: 1 * 60000,
    });
    const irEvents = !irError ? irData?.getCallActivities : [];

    // External Events
    const eventsData = [];

    const { data: gData, error: gError, loading: gLoading } = useQuery(GET_GOOGLE_EVENTS, {
      variables: { filters: queryRangeFilter },
    });

    const { data: oData, error: oError, loading: oLoading } = useQuery(GET_OUTLOOK_EVENTS, {
      variables: { filters: queryRangeFilter },
    });

    const gValid = !gLoading && !gError && gData.getGoogleEvents.length;
    const oValid = !oLoading && !oError && oData.getOutlookEvents.length;

    // Merge External Events
    if (gValid) eventsData.push(mapExternalEvents(gData, 'getGoogleEvents'));
    if (oValid) eventsData.push(mapExternalEvents(oData, 'getOutlookEvents'));

    console.log('WithEvents errors: inRecovery', irError, 'Google', gError, 'Outlook', oError)

    return (
      <Component
        {...props}
        events={irEvents}
        eventsLoading={irLoading}
        loadingGoogle={gLoading}
        loadingOutlook={oLoading}
        externalEvents={eventsData.flat()}
      />
    );
  };

  return WithEventsComponent;
};

export default withEvents;
