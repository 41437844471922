import { createSelector } from 'reselect';

const getPatients = state => state.dashboard.dashboardData.patients;

export const getPatientRelations = createSelector(
  getPatients,
  (_, selectedMemberId) => selectedMemberId, (patients, selectedMemberId) => {
    const findResult = patients.find(p => p._id === selectedMemberId);
    if (findResult) return findResult.relations;
    return [];
  }
);
