import {useContext} from 'react';
import { SessionContext } from './SessionContext';

const useSessionContext = () => {
    
    const context = useContext(SessionContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the SessionContext`) 
    };

    return context

};

export default useSessionContext;