import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

const FormSkeleton = ({ repeat = 3 }) => (
    Array.from(Array(repeat)).map((_, i) => (
        <React.Fragment key={i}>
            <Skeleton animation="wave" height={14} width={140}/>
            <Skeleton animation="wave" height={56}/>
            <Grid container spacing={2}>
                {Array.from(Array(2)).map((_, i) => (
                    <Grid key={i} item xs={6}>
                        <Skeleton animation="wave" height={14} width={140}/>
                        <Skeleton animation="wave" height={56}/>
                    </Grid>
                ))}
            </Grid>    
        </React.Fragment>
    ))
)

export default FormSkeleton;