import React from 'react';

import { Button, Box } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import { Formik, Form } from 'formik';
import FormSkeleton from 'common/components/Formik/FormSkeleton/FormSkeleton';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';
import { FormDevTools } from 'common/components/Formik/FormDevTools';

import * as Yup from 'yup';
import { EDIT_FAMILY_MEMBER } from 'data/graphql';
import { trimObjectStrings } from 'common/helpers/validations';
import useMembersContext from 'context/Members/useMembersContext';
import useAccountContext from 'context/Account/useAccountContext';

const useStyles = makeStyles({
  formTitle: {
    fontSize: 20,
    marginBottom: 28,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, .8)',
  },
});

const validationSchema = Yup.object().shape({
  // location: Yup.string().nullable().required('Location is required'),
});

const Organization = ({ loading, error, data, avatarFile, organizationName, refetch, refetchDashboard }) => {
  console.log('ORGANZIATION BOGI', data);
  
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  
  // Data
  const { staff, refetchMembers } = useMembersContext()
  const { locations } = useAccountContext();

  // Temp: Add fullName to staff object for Autocomplete
  const cliniciansList = staff?.map(member => {
    return {
      ...member,
      fullName: `${member?.firstName} ${member?.lastName}`, 
    }
  });

  const currentLocation = locations.find(loc => loc._id === data?.locationId);
  console.log('currentLocation', currentLocation, locations)

  // Methods
  const onFormSubmit = ({ primaryClinician, secondaryClinician, location, admitDate, dischargeDate }) => {
    console.log('Mihail primaryClinician', primaryClinician);
    const primaryClinicianId = getIdByName(primaryClinician);

    const secondaryClinicianIds = secondaryClinician
      .map(name => getIdByName(name))
      .filter(id => !!id && id !== primaryClinicianId);

    const updatedMember = {
      primaryClinicianId,
      secondaryClinicianIds,
      locationId: location ? getLicationIdByName(location) : null,
      ...(avatarFile ? { imageContent: avatarFile } : {}),
    };

    editMember({
      variables: {
        memberId: data?._id,
        familyInfo: trimObjectStrings(updatedMember),
      },
    });
  };

  const getIdByName = name => {
    const result = cliniciansList.find(c => c.fullName === name);
    if (result) return result._id;
    return '';
  };

  const getLicationIdByName = name => {
    const findResult = locations.find(l => l.name === name);
    return findResult ? findResult._id : '';
  };

  // Mutations
  const [editMember, { loading: editLoading }] = useMutation(EDIT_FAMILY_MEMBER, {
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar('Account updated', { variant: 'success' });

      setTimeout(() => {
        try {
          refetch();
          refetchMembers();
        } catch (error) {
          console.log(error);
        }
      }, 250);
    },
  });

  const getPrimaryClinician = () => {
    const result = data?.careTeamDetails.find(m => m.primary);
    if (!result) return '';
    return `${result.firstName} ${result.lastName}`;
  };

  const getSecondaryClinician = () => {
    const result = data?.careTeamDetails.filter(m => !m.primary) || [];
    return result.map(m => `${m.firstName} ${m.lastName}`);
  };

  // Render Props
  const fieldsGridSetup = [
    [
      {
        name: 'mrn',
        title: 'MRN',
        disabled: true,
        value: data?.mrn || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        type: 'autocomplete',
        name: 'location',
        title: 'Location',
        value: currentLocation?.name,
        options: locations.map(l => l.name),
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        disabled: true,
        name: 'encounterId',
        title: 'Encounter ID',
        value: data?.encounterId || '',
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        type: 'date',
        name: 'admitDate',
        title: 'Admit Date',
        value: data?.admitDate,
        grid: { xs: 12, sm: 6 },
      },
      {
        type: 'date',
        name: 'dischargeDate',
        title: 'Discharge Date',
        value: data?.dischargeDate,
        grid: { xs: 12, sm: 6 },
      },
    ],
  ];

  const fieldsGridCare = [
    [
      {
        type: 'autocomplete',
        name: 'primaryClinician',
        title: 'Primary Clinician',
        value: getPrimaryClinician(),
        options: cliniciansList.map(c => c.fullName),
        grid: { xs: 12 },
      },
      {
        multiple: true,
        type: 'autocomplete',
        name: 'secondaryClinician',
        title: 'Secondary Clinician',
        value: getSecondaryClinician(),
        options: cliniciansList.map(c => c.fullName),
        grid: { xs: 12 },
      },
    ],
  ];

  const formikProps = {
    onSubmit: onFormSubmit,
    initialValues: toInitialValues([...fieldsGridSetup, ...fieldsGridCare]),
    validationSchema: validationSchema,
  };

  const btnSubmitProps = {
    type: 'submit',
    color: 'primary',
    variant: 'contained',
  };

  return loading ? (
    <FormSkeleton />
  ) : (
    <Formik {...formikProps}>
      {({ values, handleChange, setFieldValue, errors }) => {
        const hasErrors = !!(errors && Object.keys(errors).length);
        return (
          <Form>
            <p className={classes.formTitle}>{organizationName} setup</p>
            <FormBuilder data={fieldsGridSetup} {...{ setFieldValue, values, errors, handleChange }} />

            <Box mt={4} children={<p className={classes.formTitle}>Care team</p>} />
            <FormBuilder data={fieldsGridCare} {...{ setFieldValue, values, errors, handleChange }} />

            <Box
              display="flex"
              justifyContent="flex-end"
              mt={3}
              children={
                <Button
                  {...btnSubmitProps}
                  disabled={editLoading || hasErrors}
                  children={editLoading ? 'Loading...' : 'Update'}
                />
              }
            />

            <FormDevTools values={values} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};

Organization.propTypes = {};

export default Organization;
