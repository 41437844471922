import React from 'react';
import {makeStyles} from '@material-ui/styles';

// Nav
import {Route, Switch, Redirect} from 'react-router-dom';
import routes, {routeSettings, publicRoutes} from './common/routes'

// Components
import Login from 'screens/Auth/main';
import ForgotPassword from 'screens/Auth/ForgotPassword/main';
import AppLoader from './AppLoader';

// Context
import {useAuthNContext} from 'context/Auth/useAuthContext';
import { useCountRenders } from 'common/hooks/useCountRenders';

const AuthLoader = () => {
    const styles = useStyles();
    useCountRenders('AuthLoader')
    const {hasToken} = useAuthNContext();

    return (
    <div className={styles.hyperContainer}>
    {hasToken ? (
        <AppLoader>
            <Switch>
            {routeSettings.map((route, i) =>
                !route.redirectTo
                ? <Route key={route.path} {...route} />
                : <Redirect key={route.path} to={route.redirectTo} {...route} />
            )}
            <Redirect path="*" to={routes.root} />
            </Switch>
        </AppLoader>
        ) : (
        <Switch>
            <Route path={publicRoutes.login} component={Login} />
            <Route exact path={publicRoutes.forgotPassword} component={ForgotPassword} />
            <Route path="/" component={Login} />
        </Switch>
    )}
    </div>
    )
}

export default AuthLoader;

const useStyles = makeStyles({
    hyperContainer: { 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
    },
});
