import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { Grid, Button, CircularProgress, Box } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import BulletdIcon from '@material-ui/icons/FiberManualRecord';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import styles from './MyAccount.module.scss';
import { passRequirementsValidator } from 'common/helpers/validations';
import { resetPasswordReset, initRequestCode, initResetPassword } from 'actions';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';
import { useAuthNContext } from 'context/Auth/useAuthContext';

const AccountResetPassword = ({
  // props
  user,
  // dispatch
  resetPasswordReset,
  initRequestCode, initResetPassword,
  // redux state
  codeError, codeLoading, codeSuccessMessage, codeIsSent,
  resetError, resetLoading, resetSuccessMessage,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {handleUserSignout} = useAuthNContext();

  const [customErrors, setCustomErrors] = useState(null);

  const handleRequestCode = e => {
    e.preventDefault();
    initRequestCode();
  }

  const onFormSubmit = ({ resetCode, password }) => {
    initResetPassword(user.email, resetCode, password);
  }

  // On Mount
  useEffect(() => {
    resetPasswordReset();
  }, []);

  // On Code Request Success
  useEffect(() => {
    codeSuccessMessage && enqueueSnackbar(
      `Code was successfully sent to ${codeSuccessMessage}`,
      { variant: 'success' }
    );
  }, [codeSuccessMessage]);

  // On Code Request Error
  useEffect(() => {
    codeError && enqueueSnackbar(codeError, { variant: 'error' });
  }, [codeError]);

  // On Reset Success
  useEffect(() => {
    if (resetSuccessMessage) {
      enqueueSnackbar(resetSuccessMessage, { variant: 'success' });
      setTimeout(() => handleUserSignout(), 2000);
    }
  }, [resetSuccessMessage]);

  // On Reset Error
  useEffect(() => {
    resetError && enqueueSnackbar(resetError, { variant: 'error' });
  }, [resetError]);


  const RequestCodeBtn = () => (
    <Box mt='6px' className={styles.loadingWrapper}>
      <Button
        color="primary"
        disabled={codeLoading}
        onClick={handleRequestCode}
        children="Request Reset Code" />
      {codeLoading && <CircularProgress size={20} className={styles.buttonProgress} />}
    </Box>
  );

  const ClinicianProfileSchema = Yup.object().shape({
    resetCode: Yup.number()
      .required('Required')
      .typeError('Reset code should be a number')
      .test('len', 'Must be exactly 6 characters', val => val && val.toString().length === 6),
    password: Yup.string()
      .test('customErrors', 'Is not valid', val => passRequirementsValidator(val, setCustomErrors))
      .required('Required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });

  const fieldsGridCode = [
    [{
      name: 'resetCode',
      disabled: !codeIsSent,
      placeholder: 'Enter 6-Digit Code',
      value: '',
      grid: { xs: 4 }
    }, {
      render: <RequestCodeBtn />,
      grid: { xs: 8 }
    }],
  ];

  const fieldsGridPassword = [
    [{
      name: 'password',
      title: 'New Password',
      value: '',
      type: 'password',
      grid: { xs: 12 }
    }, {
      name: 'passwordConfirm',
      title: 'Confirm Password',
      value: '',
      type: 'password',
      grid: { xs: 12 }
    }],
  ];

  const formikProps = {
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      ...toInitialValues(fieldsGridCode),
      ...toInitialValues(fieldsGridPassword),
    },
    onSubmit: onFormSubmit,
    validationSchema: ClinicianProfileSchema,
  }

  const RequirementItem = ({ text, errKey }) => {

    const hasError = customErrors && customErrors[errKey];

    const textClasses = cn(styles.listText, {
      [styles.listTextCheck]: hasError !== null && hasError,
      [styles.listTextError]: hasError !== null && !hasError,
    });

    const iconClasses = icon => cn(styles.listIcon, styles[`listIcon${icon}`]);

    const Icon = () => <div className={styles.iconWrapper}>
      {!customErrors
        ? <BulletdIcon className={iconClasses('Bullet')} />
        : hasError
          ? <CheckIcon className={iconClasses('Check')} />
          : <ErrorOutlineIcon className={iconClasses('Error')} />}
    </div>

    return (
      <div className={styles.listItem}>
        <Icon />
        <div className={textClasses}>{text}</div>
      </div>
    )
  };

  const RequirementsList = errors => {
    const itemsList = {
      minLength: {
        text: 'Minimun 8 digits',
        error: false
      },
      hasDigit: {
        text: 'A number',
        error: false
      },
      hasUppercase: {
        text: 'Uppercase Letter',
        error: false
      },
      hasLowercase: {
        text: 'Lowercase Letter',
        error: false
      },
      hasSpecialChars: {
        text: 'Special Character',
        error: false
      },
    }

    for (let key in customErrors) {
      itemsList[key].error = customErrors[key];
    }

    return (
      <div className={styles.requirementsList}>
        {Object.keys(itemsList).map((key, i) => (
          <RequirementItem key={i} text={itemsList[key].text} errKey={key} />
        ))}
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>

        <label className={styles.section_title}>Reset Password</label>

        <Formik {...formikProps}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form autoComplete="off">

              <Box mb={1}>
                <FormBuilder
                  values={values}
                  errors={errors}
                  data={fieldsGridCode}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue} />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <FormBuilder
                    values={values}
                    errors={errors}
                    data={fieldsGridPassword}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue} />
                </Grid>
                <Grid item xs={5}>
                  <Box mt={3}>
                    <div className={styles.requirementsTitle}>Password Requirements</div>

                    <RequirementsList errors={errors} />

                  </Box>
                </Grid>
              </Grid>

              <Box mt={2} className={styles.submitProgressWrapper}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={resetLoading || !codeIsSent}
                  children="Reset Password" />
                {resetLoading && <CircularProgress size={20} className={styles.submitProgress} />}
              </Box>

            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  codeError: state.forgotPassword.codeError,
  codeLoading: state.forgotPassword.codeLoading,
  codeSuccessMessage: state.forgotPassword.codeSuccessMessage,
  codeIsSent: state.forgotPassword.codeIsSent,
  resetError: state.forgotPassword.resetError,
  resetLoading: state.forgotPassword.resetLoading,
  resetSuccessMessage: state.forgotPassword.resetSuccessMessage,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetPasswordReset: () => dispatch(resetPasswordReset()),
  initRequestCode: () => dispatch(initRequestCode(ownProps.user.email)),
  initResetPassword: (email, resetCode, password) => dispatch(
    initResetPassword({ email, resetCode, password })
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountResetPassword);
