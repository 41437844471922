import React, { useState, useEffect } from 'react';

// UI
import {
  Box
} from '@material-ui/core';
import styles from './DataTables.module.scss';

// Utils
import moment from 'moment';
import { truncateString } from 'common/helpers/dataHelpers';
import { checkIfDaysOld } from 'common/helpers/timeHelpers';

// Data
import { useQuery } from '@apollo/client';
import { GET_LABS } from 'data/graphql/labs';
import { useSelector } from 'react-redux';

// Table
import SortableTable from 'common/components/Table/SortableTable';
import Filters from 'common/components/PanelFilters/PanelFilters';

// Dialogs
import DialogContainer from 'common/components/Dialog/DialogContainer';
import LabDetails from 'common/components/Labs/LabDetails';
import EnterLabResults from '../LabsEntry/EnterResults';
import AddLabDetails from '../LabsEntry/AddDetails';
import SetLabStatus from '../LabsEntry/SetStatus';

const columns = [
  { id: 'date', label: 'Date', width: 120 },
  { id: 'authNumber', label: 'Auth. Number', width: 150 },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'patient', label: 'Patient', width: 175 },
  { id: 'type', label: 'Type', width: 200 },
  { id: 'more', label: 'Actions', width: 150 }
];

const LabDashActions = ({ data }) => {
  switch (data.status) {
    case 'NEW':
    case 'EXTENDED':
      return <DialogContainer component={SetLabStatus} data={data} title="Set Status" />
    case 'COLLECTED':
      return <DialogContainer component={EnterLabResults} data={data} title="Enter Results" />
    case 'MISSED':
      return checkIfDaysOld(data?.dueDate, 7) ? <DialogContainer component={SetLabStatus} data={data} isUpdate={true} title="Change Status" /> : null
    case "EXCUSED":
      return null
    case 'PARTIAL':
      return <DialogContainer component={AddLabDetails} data={data} title="Add Details" />
    case 'COMPLETED':
      return <DialogContainer component={LabDetails} data={data} title="View Report" />
    default:
      return null;
  }
}

const toTableData = data => !data ? [] : data.map((item, i) => ({
  date: moment(item.dueDate).format('YYYY-MM-DD'),
  authNumber: truncateString(item.authorizationNumber, 16),
  status: item.status,
  patient: `${item.patient.firstName} ${item.patient.lastName}`,
  type: item.panel.name,
  more: !moment(item.dueDate).isAfter() ? <LabDashActions data={item} /> : <div className={styles.action_text}>Scheduled</div>
}));

const DataTables = ({ _id }) => {

  // State
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const [labsData, setLabsData] = useState([]);
  const [filteredLabsData, setfilteredLabsData] = useState([]);
  const addedRequestsIds = useSelector(state => state.udsLabs.addedRequestsIds);
  const addedStatusIds = useSelector(state => state.udsLabs.addedStatusIds);
  const addedResultsIds = useSelector(state => state.udsLabs.addedResultsIds);
  const addedDetailsIds = useSelector(state => state.udsLabs.addedDetailsIds);

  const [momentOneWeekAhead] = useState(moment().add(1, 'week'));
  const [momentSubstractMonth] = useState(moment().subtract(1, 'months'));

  // Fetch Data
  const { data, error, loading, refetch } = useQuery(GET_LABS, {
    variables: {
      filters: {
        startDate: momentSubstractMonth.format(format),
        endDate: momentOneWeekAhead.format(format),
      }
    }
  });
  console.log('[Labs] Raw data', data)

  console.log('[Labs] ', data)
  
  useEffect(() => {
    if (!data) return;
    try {
      const converted = toTableData(data.getLabs);
      setLabsData(converted);
      setfilteredLabsData(converted);
    } catch (err) { 
      console.log(err)
    }
  }, [data]);

  useEffect(() => {
    if (addedRequestsIds && addedRequestsIds.length) setTimeout(refetch, 100);
  }, [addedRequestsIds]);

  useEffect(() => {
    if (addedStatusIds && addedStatusIds.length) setTimeout(refetch, 100);
  }, [addedStatusIds]);

  useEffect(() => {
    if (addedResultsIds && addedResultsIds.length) setTimeout(refetch, 100);
  }, [addedResultsIds]);

  useEffect(() => {
    if (addedDetailsIds && addedDetailsIds.length) setTimeout(refetch, 100);
  }, [addedDetailsIds]);

  if (loading || error) return <div className={styles.loading}>Loading Labs...</div>;

  // Methods
  const handleFiltersSubmit = updatedList => setfilteredLabsData(updatedList);

  const filtersProps = {
    data: labsData,
    hasSearch: true,
    searchLocalBy: ['authNumber', 'patient', 'type', 'status'],
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: GET_LABS,
      mutate: toTableData,
      variables: { filters: {} },
      variablesPath: 'filters',
      queryName: 'getLabs',
    },
    fields: {
      patient: {
        label: 'Patient',
        placeholder: 'Choose a patient',
        initialValue: [],
        fieldPath: 'patient',
        fieldValPath: ''
      },
      types: {
        label: 'Panel Type',
        placeholder: 'Choose a panel',
        initialValue: [],
        fieldPath: 'type',
        fieldValPath: ''
      },
      status: {
        label: 'Status',
        placeholder: 'Choose lab status',
        initialValue: [],
        fieldPath: 'status',
        fieldValPath: ''
      }
    },
    initDates: {
      minDate: momentSubstractMonth,
      maxDate: momentOneWeekAhead,
    }
  }

  return (
    <Box className={styles.wrapper}>

      <Filters {...filtersProps} />

      <Box className={styles.data_tables}>
        <Box className={styles.results}>
          <SortableTable
            columns={columns}
            data={filteredLabsData}
            emptyTitle="No lab results yet."
            emptyMessage="Once we receive results, you know where to find them."
            pagination={false}
          />
        </Box>
      </Box>


    </Box>
  );
};

export default DataTables;
