import React from 'react';
import PropTypes from 'prop-types';
import {checkPermissions} from 'data/permissions';
import {useAuthZContext} from 'context/Auth/useAuthContext';

const Can = ({
  policy,
  origin,
  module,
  perform,
  dashboard,
  memberId,
  memberType = 'patient',
  yes,
  no,
}) => {

  const {permissions} = useAuthZContext();

  return checkPermissions({
    permissions,
    policy,
    origin,
    module,
    action: perform,
    dashboard,
    memberId,
    memberType,
  })
    ? yes() 
    : no()
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;

Can.propTypes = {
  policy: PropTypes.string.isRequired,
  origin: PropTypes.string,
  module: PropTypes.string,
  perform: PropTypes.string,
  dashboard: PropTypes.string,
  memberId: PropTypes.string,
  memberType: PropTypes.string,
  yes: PropTypes.elementType,
  no: PropTypes.elementType,
};  


export const PermissionDeny = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'inherit',
      flex: 1,
      fontSize: 20,
      color: 'rgba(0,0,0,0.5)',
    }}
  >
    {children}
  </div>
);