import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    line: {
        height: '2px',
        borderTop: `2px ${theme.palette.primary.main} solid`,
        width: '100%',
        transform: 'translate(0, -1px)',
    },
    circle: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.primary.main,
    },
    nowIndicator: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        top: ({ top }) => top,
    },
}))
export const TimeIndicator = ({
    top = 10, ...restProps
    // #FOLD_BLOCK
}) => {
    const classes = useStyles({ top });
    return (
        <div {...restProps}>
            <div className={classNames(classes.nowIndicator, classes.circle)} />
            <div className={classNames(classes.nowIndicator, classes.line)} />
        </div>
    );
};