import React from 'react';
import styles from './Appointments.module.scss';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ReactSVG from 'react-svg';
import OutlookIcon from 'common/assets/outlook.svg'
import GoogleIcon from 'common/assets/google-calendar.svg'
import PropTypes from 'prop-types';

const overridedStyles = theme => ({
  appointment: {
    fontFamily: 'inherit',
    height: '100%',
    color: 'white',
  },
  monthAppointment: {
    height: 'max-content',
    color: 'black',
    backgroundColor: 'transparent',
  },
});

const ExternalAppointment = withStyles(overridedStyles)(({
  classes,
  data,
  style,
  children,
  type,
  ...restProps
}) => {
  let typeIcon
  if (type === 'outlook') typeIcon = OutlookIcon
  else if (type === 'google') typeIcon = GoogleIcon

  return (
    <Appointments.Appointment
      {...restProps}
      className={styles.external_appointment}
      type={data.type}
      style={{
        ...style,
        width: '100%',
        border: '1px solid #fff',
        background: 'repeating-linear-gradient(-45deg,#ebebeb,#ebebeb 5px,#fff 6px)'
      }}
      onDoubleClick={null}
      onClick={null}
      data={data}
    >
    <Box className={styles.wrapper}>
     <Typography variant="caption">{data.title.length > 40 ? `${String(data.title).slice(0, 40)}...` : data.title}</Typography>
     <ReactSVG src={typeIcon} className={styles.icon} />
    </Box>    
    </Appointments.Appointment>
  );
});

ExternalAppointment.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
}

export default ExternalAppointment;
