import React from 'react';
import CallItem from '../CallItem/CallItem';
import { List } from '@material-ui/core';

import useMembersContext from 'context/Members/useMembersContext';

// ToDo implement day break for weekly agenda

const CallList = ({ range, calls }) => {

  const {patients, family, staff} = useMembersContext();
  const mergedMembers = [...patients, ...family];

  return (
    <div style={{
      overflowX: 'hidden',
      overFlowY: 'scroll',
      maxHeight: '50vh',
      minHeight: 350 
      }}
    >
      <List> 
        {calls.map((call, index) => {
          const hasInvitedMembers = !!call?.invitedMembers?.length;
          const hasMultiHosts = Array.isArray(call.host);
          console.log('CallList', call, 'hasInivitedMembers', hasInvitedMembers, 'hasMultiHost', hasMultiHosts)
          
          const callItemProps = {
            key: call?._id,
            call,
            index,
            mergedMembers: hasInvitedMembers ? mergedMembers : [],
            staff,
            hasInvitedMembers,
            hasMultiHosts,
          }

          return <CallItem {...callItemProps} />
        })}
      </List>
    </div>
  )
};

export default CallList;
