import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Messages } from 'config/settings';
import styles from './LabResult.module.scss';

// GraphQL
import { useQuery } from '@apollo/client';
import { GET_POSITIVE_LABS } from '../../../data/graphql/labs';

// Components
import LabList from './LabList/LabList';
import LabListSkeleton from './LabList/Skeleton/Skeleton'

const LabResult = () => {
  const { loading, data } = useQuery(GET_POSITIVE_LABS, {
    variables: {
      filters: {
        startDate: dayjs().subtract(1, 'month'),
      }
    }
  });

  return <LabsList loading={loading} data={data} />
};

export default LabResult;

const LabsList = ({loading, data}) => { 

  const hasLabs = data?.getLabs?.length;

  if (loading) return <LabListSkeleton items={2} />
  return (
    <div>
      {hasLabs ? (
        <LabList labs={data?.getLabs} />
      ) : ( 
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 15,
          padding: 5,
          fontSize: 18
        }}>
          {Messages.CALLDASH_LABS_EMPTY}
        </div>
      )}
    </div>
  );
};