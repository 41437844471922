import Insights from './Insights'
import { convertMonthlyAverages } from 'common/helpers/reportHelpers';
import { sortByDate } from 'common/helpers/dataHelpers';

const NoteInsights = ({ hasNotes, notes }) => {

    const graphData_enagement = convertMonthlyAverages(notes, 'engagement').sort(sortByDate('x'));

    const insightsProps = {
        hasNotes: hasNotes,
        data: hasNotes ? graphData_enagement : [{}],
        selectors: [
        {
            id: 'memberEngagement',
            title: 'Engagement',
            type: 'line',
        },
        ],
    };

      
    return (
        <Insights {...insightsProps} />
    )
};

export default NoteInsights;