import React, { useContext, useEffect, useState } from 'react';
import { Button, Box } from '@material-ui/core';

// GraphQL
import { useMutation } from '@apollo/client';
import { CREATE_GROUP_CHANNEL } from 'data/graphql';

// Components
import ChatPage from "common/components/Messages/RoomBox/ChatPage";
import styles from './Messaging.module.scss';

// Context
import { ChatContext } from 'context/Chat/Chat';
import {useMemberStateContext} from 'context/Member/useMemberContext';
import useAccountContext from 'context/Account/useAccountContext';

const MessagingBody = () => {

    // State
    const [roomId, setRoomId] = useState('');
    
    // Context
    const {memberId} = useMemberStateContext();
    const {userId} = useAccountContext();
    const { getChannelsList, channelsList } = useContext(ChatContext);

    // Apollo hook
    const [createGroupChannel, { data }] = useMutation(CREATE_GROUP_CHANNEL);

    // Load channels list on mount
    useEffect(getChannelsList, []);

    // Listener for created channel
    useEffect(() => {
        if (data) {
            getChannelsList();
            setRoomId(data.createGroupChannel.url);
        }
    }, [data]);

    useEffect(() => {
        if (memberId && channelsList?.length) {
            // console.log('channelsList', channelsList);

            const patientChannel = channelsList.find(({ members }) => {
                return members.find(member => member.userId === memberId);
            });

            if (patientChannel) setRoomId(patientChannel.url);
        }
    }, [memberId, channelsList]);

    // Methods
    const createChannel = () => {
        if (memberId) {
            const variables = { groupChannel: { userIds: [memberId, userId] } };
            createGroupChannel({ variables });
        }
    }

    // Render
    return roomId ? (
        <ChatPage roomId={roomId} shortVariant hideHeader />
    ) : (
            <div className={styles.loading}>
                <Box mb={2}>Do you want to create a room with this patient?</Box>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={createChannel}
                    children="Yes, create a room." />
            </div>
        );

    // to-do
    // 1. <div className={styles.loading}>Creating your room, one sec.</div>
    // 2. <div className={styles.loading}>Loading chat...</div>

};

export default MessagingBody;
