import React from "react";
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

// Material-UI
import { 
  Card, 
  CardContent,  
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #e0e0e0',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '28.5px 0',
  },
  title: {
    color: '#000',
    fontSize: '16px',
    marginTop: '16px',
  },
  icon: {
    marginBottom: 10,
    '& svg': {
      height: '80px',
      width: '80px',
    },
  }
});

const SupportCard = ({ title, icon, link }) => {

  const classes = useStyles();

  const CardItem = () => {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <ReactSVG className={classes.icon} src={icon} />
          <div className={classes.title}>{title}</div>
        </CardContent>
      </Card>
    )  
  }

  return <> 
    {link?.type === 'external' 
      ? <a href={link?.url}><CardItem /></a>
      : <Link to={link?.url}><CardItem /></Link>
    }
  </>

}

export default SupportCard;