// Apollo
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import config from 'config/config';

// Auth
import {createAuthLink, authOptions, AUTH_TYPE} from './authLink';
import {createErrorLink} from './errorLink';

// Cache
import {cache} from './cache';

export const makeClient = (handleRefreshToken) => {
  console.log('[ApolloClient] makeClient Hello!')
  const authType = AUTH_TYPE.COGNITO_OPENID;

  // HttpLink
  const httpLink = new HttpLink({ uri: `${config.endpoint}/graphql` });
  const namedLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
        uri: `${config.endpoint}/graphql?${operation.operationName}`,
      })
    );
    return forward(operation)
  });
  
  // Error Handler
  const error = {
    message: '[ApolloClient] errorLink',
    handler: () => handleRefreshToken(),
  }

  // Client Data for Studio
  const clientData = {
    name: "web_dashboard",
    version: "2.0",
  }

  const link = ApolloLink.from([
    createErrorLink({error}),
    createAuthLink({auth: authOptions[authType]}),
    namedLink,
    httpLink,
  ]);
  
  return new ApolloClient({
    link,
    cache,
    ...clientData,
  });
};
