import React, {createContext, useEffect, useState} from 'react'
import timezone from 'moment-timezone';

// Data
import {useMutation, useQuery} from '@apollo/client';
import {GET_ACCOUNT_ORGANIZATION, GET_ACCOUNT_USER} from 'data/graphql/account'
import {EDIT_STAFF_TIMEZONE} from 'data/graphql/staff';

export const AccountContext = createContext();
AccountContext.displayName = 'AccountContext';

const AccountContexProvider = ({children}) => {

    // State
    const [initLoad, setInitLoad] = useState(true);

    // GraphQL Queries
    const {
        data: dataAccount, loading: loadingAccount, error: errorAccount
    } = useQuery(GET_ACCOUNT_ORGANIZATION, {
        onCompleted: console.log('[AccountContext] GET_ACCOUNT_ORGANIZATION successful')
    });

    const { 
        data: dataUser, loading: loadingUserData,
        error: errorUserData, refetch: refetchUser,
    } = useQuery(GET_ACCOUNT_USER, {
        onCompleted: console.log('[AccountContext] GET_ACCOUNT_USER successful')
    });

    const [updateTimezone] = useMutation(EDIT_STAFF_TIMEZONE, {
        variables: {
            account: { timezone: timezone.tz.guess(true) }
        },
        onCompleted: () => console.log('[AccountContext] Timezone Updated')
    });

    useEffect(() => {
        if(!loadingAccount && dataAccount) setInitLoad(false);
    }, [loadingAccount])
    
    useEffect(() => {
        console.log('[AccountContext] mounted')
        updateTimezone()
        return () => console.log('[AccountContext] unmounted')
    }, [])

    console.log('[AccountContext] dataUser', dataUser, 'dataAccount', dataAccount);

    const user = dataUser?.meStaff;
    const isOnCall = user?.permissions?.find(p => p.code === 'ONCALL')
    const organization = dataAccount?.getCurrentOrganization;
    const webexPrefix = organization?.setup?.find(s => s.name === 'webexLinkPrefix');
    const loading = loadingAccount || loadingUserData;

    const contextValues = {
        initLoad,
        loading,
        
        // Organization
        loadingAccount,
        organization,
        organizationName: organization?.name,
        locations: organization?.locations || [],
        departments: organization?.department || [], 
        webexPrefix,
        errorAccount: !!errorAccount,
        
        // User
        user,
        isOnCall,
        loadingUserData,
        userId: user?._id,
        userEmail: user?.email,
        userFirstName: user?.firstName,
        userLastName: user?.lastName,
        permissions: user?.permissions,
        errorUserData: !!errorUserData,
        termsAccepted: user?.termsAndConditionsAccepted,
        caseload: dataUser?.meStaff?.careTeam,
        refetchUser,
    }

    return (
        <AccountContext.Provider value={contextValues}>
            {children}
        </AccountContext.Provider>
    )
}


export default AccountContexProvider
