import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Messages } from 'config/settings';

import MemberList from 'components/Member/List/List';
import useMembersContext from 'context/Members/useMembersContext';

import {sortMembers} from 'common/helpers/memberHelpers';

const StaffList = (props) => {

  const { 
    staff: data,
    loadingStaff: dataLoading,
    errorStaff: dataError,
    calledGetStaff: dataQueryCalled,
  } = useMembersContext()

  const [processedData, setProcessedData] = useState([])

  useEffect(() => {
    if(data?.length) {
      const sortedData = sortMembers(data, 'firstName')
      setProcessedData(sortedData)
    }
  }, [data])

  const headerProps = {
    listBreadcrumbs: [{
      url: "/staff",
      name: "Staff",
      disabled: true,
    }]
  }
  const filterToggles = [{
    // active: true,
    filterCondition: false,
    title: 'Pending Activation',
    filterPath: 'activatedAccount',
  }]
  const filtersProps = {
    data: processedData,
    fields: {},
    hasSearch: true,
    filterToggles: filterToggles,
    searchLocalBy: ['firstName', 'lastName', "staffNo"],
  }

  const memberListProps = {
    data: processedData,
    dataLoading,
    dataQueryCalled,
    dataError,
    headerProps,
    filtersProps,
    baseUrl: '/staff',

    emptyData: {
      title: Messages.STAFF_LIST_EMPTY_TITLE,
      text: Messages.STAFF_LIST_EMPTY_TEXT,
    },

    emptySearch: {
      title: Messages.STAFF_LIST_SEARCH_EMPTY_TITLE,
      text: Messages.STAFF_LIST_SEARCH_EMPTY_TEXT,
    },
    hideChat: true,
  }

  // Render
  return <MemberList {...memberListProps} />;
}

StaffList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
}

export default StaffList;