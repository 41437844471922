import * as Yup from "yup";

const schema = Yup.object().shape({
  teamName: Yup.string().required("Team name is required"),
  teamLeads: Yup.array()
    .min(1, "One team leader is required")
    .max(1, "Only one team leader is required")
    .of(Yup.object().shape({
      _id: Yup.string().required("Team leader is required")
    })),
  members: Yup.array()
    .min(1, "Add at least 1 team member")
    .of(Yup.object().shape({
      _id: Yup.string().required("Team member is required")
    })),
});

export default schema;