import React, { useState } from 'react';
import styles from './SearchInput.module.scss';
import { TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const SearchInput = ({ setOpen, onChange, ...rest }) => {
  const [value, setValue] = useState('');

  const handleClick = () => {
    if (!value.length) setOpen(false)
    else {
      setValue('');
      onChange('');
    }
  }
  const handleChange = e => {
    setValue(e.target.value); 
    if (onChange) onChange(e.target.value)
  }
  return (
    <TextField {...rest}
      id="outlined-name"
      className={styles.search_input}
      value={value}
      onChange={e => handleChange(e)}
      margin="none"
      type="text"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <CancelIcon onClick={() => handleClick()} className={styles.cancel} />
        ),
      }}
    />
  );
};

export default SearchInput;
