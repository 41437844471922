import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Title from './Title';

const Popup = ({ children, title, onClose, open, titleComponent, actionsComponent, ...props }) => {
  const dialogProps = {
    ...props,
    open: open,
    fullWidth: true,
    onClose: onClose,
  };

  return (
    <Dialog {...dialogProps}>
      <Title onClose={onClose} title={title}>
        {titleComponent ? titleComponent : null}
      </Title>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
