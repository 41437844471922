import React, { /* useState */ } from 'react';

// UI
import {
  Grid, Box,
  Button, Dialog,
  DialogContent, DialogTitle, Radio,
  FormControlLabel, RadioGroup, withStyles
} from '@material-ui/core';
import cn from 'classnames';
import styles from './LabsEntry.module.scss';

// Utils
import _ from 'lodash';
import moment from 'moment';

// Data
import { useMutation } from '@apollo/client';
import { EDIT_LAB_RESULT, GET_LABS } from 'data/graphql/labs';
import { editLabStatus } from 'redux/actions/udsLabs';
import { useDispatch } from "react-redux";

// Forms
import * as Yup from "yup";
import { Form, Formik, Field } from 'formik';
import { FormDevTools } from 'common/components/Formik/FormDevTools';
import { useSnackbar } from 'notistack';

//Components
import Datepicker from 'common/components/InputFields/Datepicker/Datepicker';


const ButtonLarge = withStyles({
  root: {
    borderRadius: '24px',
    padding: '6px 20px',
  },
  label: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
  }
})(Button);

const SetLabStatus = ({
  data: {
    _id,
    patient,
    dueDate,
    panel,
    authorizationNumber,
    status: labStatus,
  },
  open,
  handleClose,
  isUpdate
}) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formInitValues = {
    _id,
    dueDate: moment().add(1, "day").format(),
    status: labStatus,
    reason: null,
  };

  const [editLab, labQuery] = useMutation(EDIT_LAB_RESULT, {
    refetchQueries: [{ query: GET_LABS }],
    onCompleted: status => {

      enqueueSnackbar('Lab Status saved', { variant: 'success' });

      dispatch(
        editLabStatus(_.get(status, ['editLab', '_id']), null)
      );

      handleClose();
    },
    onError: e => {
      enqueueSnackbar(e && e.message
        ? e.message
        : 'Error: Lab Status not saved.', { variant: 'error' });
    }
  });

  const isLoading = labQuery.loading;

  const LabStatusSchema = Yup.object().shape({
    status: Yup.string()
      .required('Lab Status is required.'),
  });


  const handleEnterStatus = async (data, { resetForm }) => {

    // TODO: Run query for labstatus before running

    // List of statuses with change permissions
    const allowedStatus = ['NEW', 'EXTENDED', 'MISSED'];
    const validateChange = allowedStatus.some(i => i === labStatus);

    console.log('[Labs] handleEnterStatus data', data)
    console.log('[Labs] SetStatus: validateChange: ', validateChange);

    if (validateChange) {

      const withDueDate = data.status === "EXTENDED";

      const labStatusEntry = {
        labId: data._id,
        lab: { 
          status: data.status, 
          ...(withDueDate ? { dueDate: data.dueDate } : {})
        },
        ...(data.reason ? { reason: data.reason } : {})
      };

      console.log('[Labs] SetStatus: labStatusEntry ', labStatusEntry);
      
      await editLab({ variables: labStatusEntry });

    }
    resetForm();
  };


  const labInfo = [
    { title: 'Due Date', content: moment(dueDate).format('ll') },
    { title: 'Drug Panel', content: panel.name },
    { title: 'Authorization #', content: authorizationNumber },
  ];

  const formikProps = {
    initialValues: formInitValues,
    validationSchema: LabStatusSchema,
    onSubmit: handleEnterStatus,
  }

  // Render
  const LabInfoList = () => (
    <div className={styles.resultInfo}>
      {labInfo.map(({ title, content }, i) => (
        <div className={styles.dl} key={i}>
          <div className={cn(styles.dt, styles.section_title)}>{title}</div>
          <div className={styles.dd}>{content}</div>
        </div>
      ))}
    </div>
  );

  const FormRadioToggle = ({ values, handleChange, setFieldValue }) => (
    <div className={styles.dl}>
      <div className={cn(styles.dt, styles.section_title)} style={{ alignSelf: 'flex-start' }}>Lab Status</div>
      <div className={styles.dd}>
        <div className="radios">
          <RadioGroup
            className={styles.result}
            name="status" value={values?.status}
            onChange={e => { handleChange(e); }}>

            <div className={cn(styles.result_row, styles.result_compliant)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='COLLECTED' label='Lab Collected'
                className={styles.result_item} />
            </div>

            <div className={cn(styles.result_row, styles.result_positive)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='MISSED' label='Patient No Show'
                className={styles.result_item} />
            </div>
            <div className={cn(styles.clear_row)}>
            </div>
            <div className={cn(styles.result_row, styles.result_excused)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='EXCUSED' label='Patient Excused'
                className={styles.result_item} />
            </div>

            <div className={cn(styles.result_row, styles.result_extended)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='EXTENDED' label='Period Extended'
                className={styles.result_item} />
              <div style={{ marginTop: 10 }}> 
                <Field
                  name="dueDate"
                  placeholder="Choose a date"
                  disablePast={true}
                  maxDate={moment().add(1, 'w')}
                  component={Datepicker} />
              </div>
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );

  const FormUpdateReason = ({ values, handleChange, setFieldValue }) => {
    return (
      <div className={styles.dl}>
        <div className={cn(styles.dt, styles.section_title)} style={{ alignSelf: 'flex-start' }}>Reason for Change</div>

        <div className={styles.dd}>

          <RadioGroup
            className={styles.result}
            name="reason" 
            value={values.reason}
            onChange={e => { handleChange(e); }}>
              
              <FormControlLabel
                  control={<Radio color="primary" />}
                  value='clinicianRequest' 
                  label="Clinician requested"
                  className={styles.result_item} />
              <FormControlLabel
                control={<Radio color="primary" />}
                value='labMistake' 
                label="Lab miscommunication"
                className={styles.result_item} />
              <FormControlLabel
                control={<Radio color="primary" />}
                value='patientMistake' 
                label="Patient miscommunication"
                className={styles.result_item} />
              <FormControlLabel
                control={<Radio color="primary" />}
                value='entryError' 
                label="Oops! Just entry mistake :)"
                className={styles.result_item} />
          </RadioGroup>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      className={styles.dialog}>

      <DialogTitle disableTypography className={styles.dialogTitle}>
        {isUpdate ? "Change" : "Enter"} Lab Status for {patient.firstName} {patient.lastName}
      </DialogTitle>

      <DialogContent dividers className={styles.DialogContent}>

        <LabInfoList />

        <Formik {...formikProps}>
          {({ values, errors, handleChange, setFieldValue }) => {
            
            const isExtended = values?.status === 'EXTENDED';
            const isDueDateUpdated = dueDate !== moment(values?.dueDate).format('YYYY-MM-D');
            const isReasonSet = values?.reason !== null;
            const isStatusUpdated = labStatus !== values?.status;

            const requireReason = isUpdate && isStatusUpdated;
            const validateReason = requireReason ? isReasonSet : true;
            const validateValueChange = isExtended ? isDueDateUpdated : isStatusUpdated;
         
            return (
              <Form>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormRadioToggle
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue} />
                  </Grid>

                  {requireReason &&
                    <Grid item xs={12}>
                      <FormUpdateReason
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue} />
                    </Grid>
                  }
                  
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                      <ButtonLarge
                        variant="text"
                        onClick={handleClose}
                      >Cancel</ButtonLarge>
                      <ButtonLarge
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ width: '230px' }}
                        disabled={isLoading || !validateValueChange || !validateReason}
                      >
                        {isLoading 
                          ? 'Saving...' 
                          : isUpdate 
                            ? 'Change Lab Status' 
                            : 'Confirm Lab Status'
                        }
                      </ButtonLarge>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormDevTools values={values} errors={errors} />
                  </Grid>
                </Grid>

              </Form>
            )
          }}
        </Formik>

      </DialogContent>

    </Dialog>
  )
};

export default SetLabStatus;