import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SOBER_CIRCLES_LIST } from "data/graphql/soberCircleQueries";

const SoberCircleAutocomplete = ({ value, onChange }) => {

  const { data, loading } = useQuery(GET_SOBER_CIRCLES_LIST);

  const options = data ? data.getSoberCirclesList : [];

  const onSelectChange = (e, selected) => {
    onChange(selected);
  };

  const getOptionLabel = value => {
    let teamName = "-", teamLead = "No team leader";

    const match = value.members ? value.members.find(item => item.isTeamLead) : null;

    if (value.teamName) {
      teamName = value.teamName;
    }
    if (match) {
      if (match.firstName && match.lastName) {
        teamLead = `${match.firstName} ${match.lastName}`;
      } else {
        teamLead = "Unknown team leader";
      }
    }

    return `"${teamName}" ${teamLead}`;
  };


  return <Autocomplete
    loading={loading}
    options={options}
    onChange={onSelectChange}
    getOptionLabel={getOptionLabel}
    value={value}
    renderInput={params => (
      <TextField
        {...params}
        fullWidth
        variant="outlined"
        placeholder="Type team name ..."/>
    )}/>;
};

export default SoberCircleAutocomplete;