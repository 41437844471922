import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TableSortLabel,
  TablePagination,
  Typography,
  makeStyles,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import nanoid from 'nanoid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { truncateString } from 'common/helpers/dataHelpers';

import styles from './Table.module.scss';

const desc = (a, b, orderBy) => {
  const compareDefault = (a, b) => (a[orderBy] > b[orderBy] ? 1 : a[orderBy] < b[orderBy] ? -1 : 0);

  switch (orderBy) {
    // Sort by date
    case 'date':
      return moment(a[orderBy]).isAfter(b[orderBy]) ? 1 : moment(a[orderBy]).isBefore(b[orderBy]) ? -1 : 0;

    case 'type':
      const isTypeLabel =
        _.isObject(a[orderBy]) &&
        a[orderBy].hasOwnProperty('props') &&
        a[orderBy].props.hasOwnProperty('name') &&
        a[orderBy].props.name === 'typeLabel';

      if (isTypeLabel) {
        return a[orderBy].props.label > b[orderBy].props.label
          ? 1
          : a[orderBy].props.label < b[orderBy].props.label
          ? -1
          : 0;
      }

      return compareDefault(a, b);

    case 'result':
      const isResultLabel =
        _.isObject(a[orderBy]) &&
        a[orderBy].hasOwnProperty('props') &&
        a[orderBy].props.hasOwnProperty('name') &&
        a[orderBy].props.name === 'resultLabel';

      if (isResultLabel) {
        if (a[orderBy].props.label === null && b[orderBy].props.label === null) return 0;
        if (a[orderBy].props.label === null) return 1;
        if (b[orderBy].props.label === null) return -1;
        return a[orderBy].props.label > b[orderBy].props.label
          ? 1
          : a[orderBy].props.label < b[orderBy].props.label
          ? -1
          : 0;
      }

      return compareDefault(a, b);

    // Default sort
    default:
      return compareDefault(a, b);
  }
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const SortableTableHead = ({ classes, order, orderBy, onRequestSort, columns }) => {
  const createSortHandler = property => e => {
    onRequestSort(e, property);
  };

  return (
    <TableHead classes={{ root: styles.head_root }}>
      <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width }}
          >
            <TableSortLabel
              IconComponent={() => <ArrowDropDownIcon classes={{ root: styles.sort_icon }} />}
              //hideSortIcon
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SortableTableFooter = ({columns}) => {
  // TO DO:
  // Implement data by column
  // Calculate sum
  
  const options = ['sum', 'avg', 'count'];
  
  return (
  <TableFooter>
    <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            style={{ width: headCell.width }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
  </TableFooter>
  );
};

SortableTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
};

export default function SortableTable({ data, columns, emptyTitle, emptyMessage, loading, loadingMessage }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const classes = useStyles();

  const cellHeight = 53;
  const hasFooter = false;
  const isEmpty = data.length === 0;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleRequestSort = (e, property) => {
    console.log('handleRequestSort', property, orderBy);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  // const handleClick = (e, _id) => {
  //   console.log('click', _id);
  // };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const generateId = () => {
    return nanoid();
  };

  const EmptyState = () => {
    return (
      <TableCell align="center" colSpan={columns.length}>
        <Typography className={styles.emptyTitle}>{emptyTitle}</Typography>
        <Typography className={styles.emptyMessage}>{emptyMessage}</Typography>
      </TableCell>
    );
  };
  const LoadingState = () => {
    return (
      <TableCell align="center" colSpan={columns.length}>
        <Typography className={styles.emptyMessage}>{loadingMessage}</Typography>
      </TableCell>
    );
  };

  return (
    <div className={styles.root}>
      <Paper className={classes.paper} elevation={0}>
        <div className={styles.tableWrapper}>
          <Table className={styles.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            {!isEmpty && (
              <SortableTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                columns={columns}
              />
            )}

            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={generateId()}
                      //onClick={e => handleClick(e, data.more)}
                    >
                      {Object.values(data).map(r => (
                        <TableCell
                          key={generateId()}
                          classes={{
                            root: index & 1 ? styles.body_cell_darken : styles.body_cell,
                          }}
                        >
                          {truncateString(r, 125)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: cellHeight * emptyRows }}>
                  {loading ? <LoadingState /> : isEmpty ? <EmptyState /> : <TableCell colSpan={columns.length} />}
                </TableRow>
              )}
            </TableBody>

            {!isEmpty && hasFooter && (
              <SortableTableFooter
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                columns={columns}
              />
            )}

          </Table>
        </div>

        {isEmpty ? (
          <div style={{ height: 25 }} />
        ) : (
          <TablePagination
            rowsPerPageOptions={[8, 16]}
            component="div"
            count={data.length}
            labelRowsPerPage={`Showing ${rowsPerPage} of ${data.length} results`}
            labelDisplayedRows={({ from, to, count }) => `Per Page`}
            rowsPerPage={rowsPerPage}
            page={page}
            classes={{
              root: styles.pagination,
              spacer: styles.spacer,
              caption: styles.caption,
              select: styles.select,
            }}
            backIconButtonProps={{
              'aria-label': 'previous page',
              classes: {
                label: styles.button_color,
                disabled: styles.button_disabled,
              },
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
              classes: {
                label: styles.button_color,
                disabled: styles.button_disabled,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: 'transparent',
    margin: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
