import React from 'react';
import PropTypes from 'prop-types';
import {useActivitiesContext} from 'context/Activities/useActivitiesContext';
import { useSelector, useDispatch } from 'react-redux';

import Popup from 'common/components/Popup/Popup';

// Data
import { withApollo } from '@apollo/client/react/hoc';

// Utils
import _ from 'lodash';


const EditActivityPopup = ({ open, handleClose }) => {
  const {
    editComponent,
    editPayload: { title },
  } = useActivitiesContext();
  const onClose = e => {
    handleClose(e);
  };

  return (
    <Popup title={title} onClose={onClose} open={open} maxWidth={'md'} style={{ minWidth: '825px' }}>
      {editComponent({onClose})}
    </Popup>
  );
};

// AppointmentForm.propTypes = {
// 	selectedPatientId: PropTypes.string
// };

export default withApollo(EditActivityPopup);
