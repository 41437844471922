import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';
import cn from 'classnames';
import moment from 'moment';
import qs from 'query-string';
import { Formik } from 'formik';
import { compose } from 'redux';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
  Box,
  Typography,
  Chip,
  Collapse,
  Button,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Form from './Form/Form';
import styles from './PanelFilters.module.scss';
import FilterIcon from 'common/assets/filter.svg';
import { getArrMaxDate, getArrMinDate } from 'common/helpers/timeHelpers';
import { ButtonWithIcon } from 'common/components/Button/Button';
import SearchButton from 'common/components/Button/SearchButton/SearchButton';

const multipleFilter = (toFilter, options, path, pathVal) =>
  toFilter.filter(item => {
    let matches = false;
    for (let val of options) {
      if (!matches) {
        if (pathVal) {
          matches = _.get(item, `${path}.${pathVal}`) === _.get(val, pathVal);
        } else {
          matches = _.get(item, path) === val;
        }
      }
    }

    return matches;
  });
/**
 * @param {Object} initialFilterCondition - it is send in when there is an initial filtering condition by default on the
 * table list, example: flagged for discharge is not shown by default
 * @param {boolean} noDateColumn - it is sent in as true when the data has no date column and the calculations
 * are done based on some calculated values, example period for analytics how many new notes in a period and
 * the backend only sends back the sum
 */
const Filters = ({
  onSubmit, //method which will update the data for the table after a filter query is made
  data = [],
  fields,
  hasSearch,
  filterQuery,
  client: { query },
  searchLocalBy,
  initDates,
  history,
  filterToggles,
  initialFilterCondition,
  noDateColumn = false,
}) => {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hasFilters, setHasFilters] = useState(null);
  const [dateMinText, setDateMinText] = useState('');
  const [dateMaxText, setDateMaxText] = useState('');
  const [formFields, setFormFields] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [initValuesAdded, setInitValuesAdded] = useState(false);
  const [formInitValues, setFormInitValues] = useState({
    dateMin: '',
    dateMax: '',
  });
  const [queryPreselected, setQueryPreselected] = useState(false);
  const [togglesState, setToggleState] = useState(null);
  const formikFormRef = useRef(null);

  useEffect(() => {
    try {
      if (data && data.length) {
        setFormData(data);
        setTimeText(
          data,
          dateMinText === '' && dateMinText === '' ? initDates : {}
        );

        if (filterToggles?.length) {
          const boolArray = filterToggles.map(item => !!item.active);

          setToggleState(boolArray);

          boolArray.forEach((state, i) => {
            if (state) filterToggleData(i);
          });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [data]);

  useEffect(() => {
    if (initDates) {
      setFormInitValues(obj => ({
        ...obj,
        dateMin: initDates.minDate,
        dateMax: initDates.maxDate,
      }));
    }
  }, [initDates]);

  // Set preselected fields with data from query params
  useEffect(() => {
    if (formikFormRef.current && !queryPreselected) {
      const searchParams = history.location.search;

      if (searchParams) {
        setHasFilters(true);

        const filterValues = qs.parse(searchParams);

        Object.keys(filterValues).forEach(key => {
          if (filterValues[key]) {
            const keyInitValue =
              typeof filterValues[key] === 'object'
                ? filterValues[key]
                : [filterValues[key]];
            formikFormRef.current.setFieldValue(key, keyInitValue);
          }
        });
      }

      setQueryPreselected(true);
    }
  });

  // Update list using filters from query
  useEffect(() => {
    const searchParams = history.location.search;

    if (searchParams) {
      const filterValues = qs.parse(searchParams);

      for (let key in filterValues) {
        filterValues[key] =
          typeof filterValues[key] === 'object'
            ? filterValues[key]
            : [filterValues[key]];
      }

      submitFilteredData(filterValues);
    }
  }, []);
  useEffect(() => {
    if (!hasFilters) applyInitialFilters(data);
  }, [hasFilters]);
  // Methods
  const handleExpandClick = () => setExpanded(!expanded);

  const resetFilters = () => {
    try {
      formikFormRef.current.resetForm();
      formikFormRef.current.handleSubmit();
      setSelectedTypes([]);
      setExpanded(false);
    } catch (err) {
      console.log(err);
    }
  };

  const setTimeFromData = data => {
    if (data[0].hasOwnProperty('date')) {
      const minDate = getArrMinDate(data, 'date');
      const maxDate = getArrMaxDate(data, 'date');

      setDateMinText(minDate.format('MMM D'));
      setDateMaxText(maxDate.format('MMM D'));
    }
  };
  const setTimeFromFilters = dates => {
    if (dates) {
      if (dates.minDate) setDateMinText(dates.minDate.format('MMM D'));
      if (dates.maxDate) setDateMaxText(dates.maxDate.format('MMM D'));
    }
  };
  const setTimeText = (data, dates) => {
    if (noDateColumn) {
      setTimeFromFilters(dates);
    } else setTimeFromData(data);
  };

  const setFormData = data => {
    Object.keys(fields).forEach(key => {
      const {
        initialValue,
        label,
        placeholder,
        fieldPath,
        fieldValPath,
      } = fields[key];
      const objPath = `${fieldPath + (fieldValPath && `.${fieldValPath}`)}`;

      setFormInitValues(obj => ({
        ...obj,
        [key]: initialValue,
      }));
      setFormFields(obj => ({
        ...obj,
        [key]: {
          label,
          placeholder,
          options: _.uniqBy(data, objPath)
            .map(item => item[fieldPath])
            .filter(i => i !== '--' || i === ''),
        },
      }));
    });

    setInitValuesAdded(true);
  };

  const setSearchParams = values => {
    const keysWithValues = Object.keys(values).filter(key => {
      if (
        (typeof values[key] === 'string' && values[key] === '') ||
        (Array.isArray(values[key]) && !values[key].length) ||
        typeof values[key][0] === 'object'
      ) {
        return false;
      }

      return true;
    });
    const searchParams = keysWithValues.reduce((obj, key) => {
      const item = values[key];
      obj[key] = item;
      return obj;
    }, {});
    // To Do: Bring back but do it right.
    // history.push({
    //   ...history.location,
    //   search: qs.stringify(searchParams)
    // });
  };

  const submitFilteredData = async values => {
    let filteredList = [...data];

    const { dateMax, dateMin } = values;
    setTimeFromFilters({ minDate: moment(dateMin), maxDate: moment(dateMax) });
    const shoundQuery =
      (dateMin || dateMax) && filterQuery && !_.isEmpty(filterQuery);

    if (shoundQuery) {
      setIsLoading(true);

      const {
        reqQuery,
        variables,
        variablesPath,
        mutate,
        queryName,
      } = filterQuery;

      if (dateMin) {
        if (variablesPath) {
          variables[variablesPath].startDate = moment(dateMin).format();
        } else {
          variables.startDate = moment(dateMin).format();
        }
      }

      if (dateMax) {
        if (variablesPath) {
          variables[variablesPath].endDate = moment(dateMax).format();
        } else {
          variables.endDate = moment(dateMax).format();
        }
      }
      const { data } = await query({
        query: reqQuery,
        variables: variables,
        onCompleted: () => setIsLoading(false),
      });

      filteredList = mutate(data[queryName]);
    }

    // Filter by passed fields
    Object.keys(fields).forEach(key => {
      const { fieldPath, fieldValPath, initialValue } = fields[key];
      const options = values[key] ? values[key] : initialValue;

      if (options && options.length) {
        filteredList = multipleFilter(
          filteredList,
          options,
          fieldPath,
          fieldValPath
        );
      }
    });

    // Update min/max date text
    try {
      if (
        !noDateColumn &&
        filteredList.length &&
        filteredList[0].hasOwnProperty('date')
      ) {
        const minDate = getArrMinDate(filteredList, 'date');
        const maxDate = getArrMaxDate(filteredList, 'date');

        if (minDate) setDateMinText(minDate.format('MMM D'));
        if (maxDate) setDateMaxText(maxDate.format('MMM D'));
      }
    } catch (error) {
      console.log('error', error);
    }

    onSubmit(filteredList);
    setExpanded(false);

    if (values?.types?.length) {
      setSelectedTypes(values?.types);
    }
  };

  const updateHasFilters = values => {
    const _hasFilters = Object.values(values).some(item => {
      if (typeof item === 'string') return !!item;
      if (typeof item === 'object') return !!Object.keys(item).length;
      return false;
    });
    setHasFilters(_hasFilters);
  };

  const onFormSubmit = (values, { resetForm }) => {
    submitFilteredData(values);
    updateHasFilters(values);
    setSearchParams(values);
    if (togglesState) {
      setToggleState(prevState =>
        prevState.reduce(accum => [...accum, false], [])
      );
    }
  };

  const onQuerySearch = _.debounce(async text => {
    if (!text) {
      return onSubmit(data);
    }

    const {
      reqQuery,
      variables,
      variablesPath,
      mutate,
      queryName,
    } = filterQuery;

    if (variablesPath) {
      variables[variablesPath].searchText = text;
    } else {
      variables.searchText = text;
    }

    const { data: searchData } = await query({ query: reqQuery, variables });
    onSubmit(mutate(searchData[queryName]));
  }, 500);

  const onSearchType = !searchLocalBy
    ? onQuerySearch
    : text => {
        if (!text) {
          return onSubmit(data);
        }

        try {
          if (togglesState) {
            setToggleState(prevState =>
              prevState.reduce(accum => [...accum, false], [])
            );
          }

          const searchResult = data.filter(patient => {
            const fields = searchLocalBy
              .map(fieldName => patient[fieldName]?.toLowerCase())
              .filter(a => a);
            return fields.some(
              field => field.indexOf(text?.toLowerCase()) !== -1
            );
          });

          onSubmit(searchResult);
        } catch (err) {
          console.log(err);
          onSubmit(data);
        }
      };

  const filterToggleData = index => {
    const updatedData = data?.filter(m => {
      const path = _.get(m, filterToggles[index].filterPath);
      const condition = filterToggles[index].filterCondition;
      return path === condition;
    });

    onSubmit(updatedData);
  };
  const applyInitialFilters = (data = []) => {
    if (!initialFilterCondition) return;
    const updatedData = !data.length || data?.filter(m => {
      const path = _.get(m, initialFilterCondition.filterPath);
      const value = initialFilterCondition.filterCondition;
      const condition = initialFilterCondition.condition;
      switch (condition) {
        case 'eq':
          return path === value;
        case 'ne':
          return path !== value;
        default:
          return path === value;
      }
    });
    onSubmit(updatedData);
  };
  const handleToggle = index => {
    // Update toggle index bool state
    setToggleState(prevState => {
      const updatedState = [...prevState];
      updatedState[index] = !updatedState[index];

      // Filter data & submit
      updatedState[index] ? filterToggleData(index) : applyInitialFilters(data);

      return updatedState;
    });
  };

  const filterValidation = Yup.object().shape({
    dateMin: Yup.date(),
    dateMax: Yup.date(),
  });

  const formikProps = {
    initialValues: formInitValues,
    validationSchema: filterValidation,
    onSubmit: onFormSubmit,
  };

  // Render
  // TO DO: Create isLoading state when fetching new data.
  if (isLoading && !data.length) {
    return (
      <Skeleton animation="wave" variant="rect" width="100%" height={58} />
    );
  } else if (!data.length) {
    return null;
  }

  const showToggleList = !!(togglesState && filterToggles?.length);

  const renderToggles =
    showToggleList &&
    filterToggles.map((item, i) => (
      <Box key={i} className={styles.colRight}>
        <div className={styles.filterDates}>
          <FormControlLabel
            label={item.title}
            control={
              <Switch
                color="primary"
                value={togglesState[i]}
                checked={togglesState[i]}
                onChange={() => handleToggle(i)}
              />
            }
          />
        </div>
      </Box>
    ));

  return (
    <Box className={styles.filtersRow}>
      <ButtonWithIcon
        icon={FilterIcon}
        children={'Filters'}
        classes={cn(styles.filtersToggle, {
          [styles.filtersToggleActive]: expanded,
        })}
        onClick={handleExpandClick}
      />

      <div style={{ zIndex: '3' }}>
        <Button
          classes={{
            root: styles.btnClear,
            label: styles.btnClearLabel,
          }}
          className={cn({ [styles.btnClearActive]: hasFilters })}
          onClick={resetFilters}
        >
          Clear All
        </Button>
      </div>

      <div
        className={cn(styles.filtersHeader, {
          [styles.filtersHeaderFaded]: expanded,
        })}
      >
        {!!selectedTypes.length && (
          <div className={styles.filteredList}>
            <Typography className={styles.listTitle}>Type</Typography>

            {selectedTypes.map((item, i) => {
              return <Chip className={styles.listItem} key={i} label={item} />;
            })}
          </div>
        )}

        {moment(dateMinText).isValid() && (
          <Box ml="auto" className={styles.colRight}>
            <div className={styles.filterDates}>
              <CalendarTodayIcon className={styles.filterDatesIcon} />
              <Typography
                className={styles.filterDatesDates}
              >{`${dateMinText} - ${dateMaxText}`}</Typography>
            </div>
          </Box>
        )}

        {renderToggles}

        {hasSearch && (
          <div className={styles.search}>
            <SearchButton hideText onChange={onSearchType} />
          </div>
        )}
      </div>

      <Collapse
        in={expanded}
        timeout="auto"
        collapsedSize="57px"
        className={styles.filtersContent}
      >
        <div
          className={cn(styles.filtersContentInner, {
            [styles.filtersContentInnerFaded]: !expanded,
          })}
        >
          {initValuesAdded && (
            <Formik {...formikProps}>
              {props => <Form
                  {...props}
                  ref={formikFormRef}
                  formFields={formFields}
                  hasDateRange={moment(dateMinText).isValid()}
                  onCancel={() => setExpanded(false)}
                />
              }
            </Formik>
          )}
        </div>
      </Collapse>
    </Box>
  );
};

Filters.propTypes = {
  data: PropTypes.array,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired,
      fieldPath: PropTypes.string.isRequired,
      fieldValPath: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default compose(withApollo, withRouter)(Filters);
