import React from 'react';
import { Messages } from 'config/settings';

// Components
import MemberList from 'components/Member/List/List';

// Data
import { GET_DISCHARGED_FAMILY } from 'data/graphql';
import useMembersContext from 'context/Members/useMembersContext';

const FamilyList = () => {
  
  const { 
    dischargedFamily: data,
    loadingDischargedFamily: dataLoading,
    errorDischargedFamily: dataError,
  } = useMembersContext()

  const filterQuery = {
    reqQuery: GET_DISCHARGED_FAMILY,
    queryName: 'dischargedFamily', 
  }

  const headerProps = {
    listBreadcrumbs: [
      {
        name: "Discharged Family",
        url: "/family/discharged",
        disabled: true,
      },
    ],
  }

  const filtersProps = {
    data,
    hasSearch: true,
    filterQuery: filterQuery,
    searchLocalBy: ['firstName', 'lastName', "mrn"],
  }

  const memberListProps = {
    data,
    dataLoading,
    dataError,

    headerProps,
    filtersProps,
    baseUrl: '/family',

    emptyData: {
      title: Messages.FAMILY_LIST_EMPTY_TITLE,
      text: Messages.FAMILY_LIST_EMPTY_TEXT,
    },

    emptySearch: {
      title: Messages.FAMILY_LIST_SEARCH_EMPTY_TITLE,
      text: Messages.FAMILY_LIST_SEARCH_EMPTY_TEXT,
    },
    showRelated: true,
    useBaseMemberRoute: true,
    baseMemberRoute: '/family',
  }

  return <MemberList {...memberListProps} />;
}

export default FamilyList;