import React from 'react';
import { RadioGroup, Radio as MURadio, FormControlLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BaseRadio } from './BaseRadio';

export const RadioWoControl = ({ items, className, value, disabled }) => {
  return <BaseRadio value={value} items={items} className={className} disabled={disabled} />;
};

RadioWoControl.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  labelKey: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

