import {gql} from '@apollo/client';

export const GET_MEMBERS_STAFF = gql`
  query getMembersStaff {
    getStaff {
      _id
      firstName
      lastName
      type
      jobTitle
      image
      activatedAccount
      status
      lastActive
      staffNo
      signature
    }
  }
`
export const GET_ROLES = gql`
  query GetRoles {
    getRoles {
      _id
      name
    }
  }
`;