import {combineReducers} from 'redux'
import {
  PATIENT_SOBER_CIRCLE_JOIN,
  PATIENT_EDIT_SET_AVATAR,
  PATIENT_EDIT_RESET,
  PATIENT_EDIT_TOGGLE_UPLOAD_MODAL
} from 'actions/types'

const isUploadModalVisible = (prev = false, action) => {
  switch (action.type) {
    case PATIENT_EDIT_RESET:
      return false
    case PATIENT_EDIT_TOGGLE_UPLOAD_MODAL:
      if (action.payload !== undefined) {
        return action.payload
      }

      return !prev
    default:
      return prev
  }
}

const originalAvatar = (prev = null, action) => {
  switch (action.type) {
    case PATIENT_EDIT_RESET:
    case PATIENT_EDIT_TOGGLE_UPLOAD_MODAL:
      return null
    case PATIENT_EDIT_SET_AVATAR:
      return action.payload
    default:
      return prev
  }
}

const joinSoberCircle = (prev = null, action) => {
  switch (action.type) {
    case PATIENT_EDIT_RESET:
      return null
    case PATIENT_SOBER_CIRCLE_JOIN:
      return action.payload
    default:
      return prev
  }
}

export default combineReducers({
  isUploadModalVisible,
  originalAvatar,
  joinSoberCircle,
})