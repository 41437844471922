import { Auth } from 'aws-amplify';
import * as types from './types';
import { createAction } from 'redux-actions';

// Reset State
export const resetPasswordReset = createAction(types.RESET_PASSWORD_RESET);

// Code Request
export const requestCodeStarted = createAction(types.REQUEST_CODE_STARTED);
export const requestCodeSuccess = createAction(types.REQUEST_CODE_SUCCESS);
export const requestCodeError = createAction(types.REQUEST_CODE_ERROR);

// Reset Submit
export const resetPasswordStarted = createAction(types.RESET_PASSWORD_STARTED);
export const resetPasswordSuccess = createAction(types.RESET_PASSWORD_SUCCESS);
export const resetPasswordError = createAction(types.RESET_PASSWORD_ERROR);

// Init Code Request
export const initRequestCode = email => dispatch => {
    dispatch(requestCodeStarted());
    Auth.forgotPassword(email)
        .then(data => dispatch(requestCodeSuccess({ data, email })))
        .catch(error => dispatch(requestCodeError(error)));
}

// Init Reset Submit
export const initResetPassword = ({ email, resetCode, password }) => dispatch => {
    dispatch(resetPasswordStarted());

    if (!email || !password || !resetCode) {
        return dispatch(resetPasswordError('One of required fields is missing'));
    }
    
    Auth.forgotPasswordSubmit(email, resetCode, password)
        .then(() => dispatch(resetPasswordSuccess()))
        .catch(error => dispatch(resetPasswordError(error)));
}
