import { useState } from 'react';
import useMembersContext from 'context/Members/useMembersContext';

const useActivitiesState = (activitiesInitState = []) => {

  const {patients} = useMembersContext(); 

  const [activitiesState, setActivitiesState] = useState(activitiesInitState);

  const mutateAndUpdate = (activities = []) => {

    let updatedState = activities;

    if (patients?.length) {
    updatedState = activities.map(activity => {

      let findPatient = null;

      for (let id of activity.invitedMembers) {
        const findResult = patients.find(p => p._id === id);
        
        if (findResult) {
          findPatient = findResult;
          break;
        }
      }

      return {
        ...activity,
        ...findPatient ? {
          avatar: findPatient.image,
          lastName: findPatient.lastName,
          firstName: findPatient.firstName,
        } : {}
      }
    });
    }

    setActivitiesState(updatedState);
  }

  return [activitiesState, mutateAndUpdate];
}

export default useActivitiesState;