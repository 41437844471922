import React from 'react';
import styles from './Messages.module.scss';
import { Box } from '@material-ui/core';

import ChatRoomContainer from 'common/components/Messages/RoomBox/main';
import RoomsList from './RoomsList';

const Messages = () => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.messages_module}>
        <RoomsList/>
      </Box>
      <Box className={styles.room_module}>
        <ChatRoomContainer/>
      </Box>
    </Box>
  );
};

export default Messages;
