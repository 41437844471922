import {gql} from '@apollo/client';


export const GET_LABS_RESULTS = gql`
  query getLabsResults($filters: LabFilters) {
    getLabs(filters: $filters) {
      result
    }
  }
`;

export const GET_LABS_WITH_FILTERS = gql`
  query getLabs($filters: LabFilters) {
    getLabs(filters: $filters) {
      _id
      authorizationNumber
      createdAt
      dueDate
      specialNote
      panel {
        _id
        name
        caronId
      }
      status
      type
      result
      detailedResults {
        _id
        substance
        value
        result
        baseLevel
      }
      patient {
        _id
        firstName
        lastName
        image
        mrn
      }
      documents {
        url
        name
        type
        tags
      }
    }
  }
`;

export const GET_LABS = gql`
  query getLabs($filters: LabFilters) {
    getLabs(filters: $filters) {
      _id
      authorizationNumber
      createdAt
      dueDate
      specialNote
      withETG
      patient {
        _id
        firstName
        lastName
        image
        mrn
      }
      panel {
        _id
        name
        caronId
      }
      status
      type
      result
      detailedResults {
        _id
        substance
        value
        result
        baseLevel
        confirmLevel
      }
      documents {
        url
        name
        type
        tags
      }
    }
  }
`;

export const GET_LAB = gql`
  query getLab($_id: ObjectID) {
    getLab(_id: $_id) {
      _id
      authorizationNumber
      createdAt
      dueDate
      panel {
        _id
        name
        caronId
      }
      status
      type
      result
      patient {
        _id
        firstName
        lastName
        image
        mrn
      }
      documents {
        _id
        url
        name
        type
        tags
      }
      detailedResults {
        _id
        substance
        value
        result
        baseLevel
      }
    }
  }
`;

export const GET_POSITIVE_LABS = gql`
  query GetPositiveLabs {
    getLabs(filters: { result: POSITIVE }) {
      _id
      result
      dueDate
      specialNote
      patient {
        _id
        lastName
        firstName
      }
      panel { 
        name
      }
      withETG
      detailedResults {
        _id
        substance
        value
        result
        baseLevel
      }
    }
  }
`;

export const ADD_LAB_REQUEST = gql`
  mutation insertNewQuestRequest($lab: QuestLabInput) {
    insertNewQuestRequest(lab: $lab) {
      newLab { 
        _id
      }
    }
  }
`;

export const EDIT_LAB_RESULT = gql`
  mutation editLab($labId: ObjectID, $lab: EditLabInput, $reason: String) {
    editLab(labId: $labId, lab: $lab, reason: $reason) {
        _id
    }
  }
`

export const ADD_LAB_DETAILS = gql`
  mutation addLabDetailedResultEntry($labId: ObjectID, $entry: DetailedResultInput) {
    addLabDetailedResultEntry(labId: $labId, entry: $entry) {
        _id
        substance
    }
  }
`