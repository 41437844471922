import React, { useEffect } from "react";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import { Button, Box } from '@material-ui/core';

import styles from "../Form.module.scss";
import { initResetPassword, resetPasswordReset } from 'actions';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';

const ResetPasswordForm = ({
    history: { push: navigate },
    initResetPassword, resetPasswordReset,
    codeSuccessMessage, resetError, resetLoading,
    resetSuccessMessage, codeSentEmail,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleCancel = () => navigate('/login');

    useEffect(() => {
        if (resetSuccessMessage) {
            enqueueSnackbar(resetSuccessMessage, { variant: 'success' });
            setTimeout(() => {
                resetPasswordReset();
                navigate('/login');
            }, 2000);
        }
    }, [resetSuccessMessage]);

    const fieldsGrid = [
        [{
            name: 'resetCode',
            title: 'Enter Verification Code',
            value: '',
            placeholder: '6-Digit Code',
            grid: { xs: 12 }
        }, {
            name: 'password',
            title: 'Choose New Password',
            value: '',
            type: 'password',
            grid: { xs: 12 }
        }, {
            name: 'passwordConfirm',
            title: 'Confirm Password',
            value: '',
            type: 'password',
            grid: { xs: 12 }
        }],
    ];

    const validationSchema = Yup.object().shape({
        resetCode: Yup.number()
            .required('Please enter verification code.')
            .typeError('Reset code should be a number')
            .test('len', 'Must be exactly 6 characters', val => val && val.toString().length === 6),
        password: Yup.string()
            .required('Required'),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
    })

    const onFormSubmit = ({ resetCode, password }) => {
        if (codeSentEmail) {
            initResetPassword(codeSentEmail, resetCode, password);
        } else {
            navigate('/');
        }
    }

    const formikProps = {
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: toInitialValues(fieldsGrid),
        onSubmit: onFormSubmit,
        validationSchema: validationSchema,
    }

    return (
        <div className={styles.login}>

            <h4 className={styles.title}>Reset your password</h4>
            <Box mb={3}>
                <p className={styles.text}>We just emailed you a 6-Digit Verification Code to enter below:</p>
            </Box>

            <Formik {...formikProps}>
                {({ values, errors, handleChange, setFieldValue }) => (
                    <Form autoComplete="off">

                        <FormBuilder
                            values={values}
                            errors={errors}
                            data={fieldsGrid}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue} />

                        {resetError && <p className={styles.error}>{resetError}</p>}

                        <Box display="flex" justifyContent="space-between" mt={3} mb={3}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={resetLoading}>
                                {resetLoading ? 'Loading...' : 'Submit'}
                            </Button>
                        </Box>

                    </Form>
                )}
            </Formik>

        </div>
    );
};


const mapStateToProps = state => ({
    codeSuccessMessage: state.forgotPassword.codeSuccessMessage,
    codeSentEmail: state.forgotPassword.codeSentEmail,
    resetError: state.forgotPassword.resetError,
    resetLoading: state.forgotPassword.resetLoading,
    resetSuccessMessage: state.forgotPassword.resetSuccessMessage,
});

const mapDispatchToProps = dispatch => ({
    resetPasswordReset: () => dispatch(resetPasswordReset()),
    initResetPassword: (email, resetCode, password) => dispatch(
        initResetPassword({ email, resetCode, password })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(ResetPasswordForm)
);
