const authPrefix = 'Auth/';
const myAccountPrefix = 'MyAccount/';
const patientEditPrefix = 'PatientEdit/';
const patientFamilyPrefix = 'PatientFamily/';

// NOTIFICATIONS
export const SELECT_URGENT_NOTIFICATIONS = 'SELECT_URGENT_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const MARK_URGENT_NOTIFICATION_AS_READ = 'MARK_URGENT_NOTIFICATION_AS_READ';
export const MARK_ALL_NOTIFICATION_AS_READ = 'MARK_ALL_NOTIFICATION_AS_READ';
export const ADD_NOTIFICATION_TO_STORE = 'ADD_NOTIFICATION_TO_STORE';

// SCHEDULE
export const CLEAN_REDUCER_STATE = 'CLEAN_REDUCER_STATE';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY';
export const FILTER_OUT_ACTIVITY = 'FILTER_OUT_ACTIVITY';
export const FILTER_CHANGE = 'FILTER_CHANGE';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_IS_NEW_APPOINTMENT = 'SET_IS_NEW_APPOINTMENT';
export const SET_ADDED_APPOINTMENT = 'SET_ADDED_APPOINTMENT';
export const SET_VIEW_NAME = 'SET_VIEW_NAME';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const ACCEPT_APPOINTMENT = 'ACCEPT_APPOINTMENT';
export const CHANGE_FIELD_ID = 'CHANGE_FIELD_ID';
export const TOGGLE_MY_SCHEDULE = 'TOGGLE_MY_SCHEDULE';
export const TOGGLE_MY_AVAILABILITY = 'TOGGLE_MY_AVAILABILITY';
export const TOGGLE_GOOGLE_CALENDAR = 'TOGGLE_GOOGLE_CALENDAR';
export const TOGGLE_OUTLOOK_CALENDAR = 'TOGGLE_OUTLOOK_CALENDAR';
export const SET_EDITING_ACTIVITY = 'SET_EDITING_ACTIVITY';
export const SET_APPOINTMENT_TO_BE_CANCELED = 'SET_APPOINTMENT_TO_BE_CANCELED';
export const SET_APPOINTMENT_TO_BE_CANCELED_FLAG = 'SET_APPOINTMENT_TO_BE_CANCELED_FLAG';

// DASHBOARD
export const GET_DASHBOARD_QUERY = 'GET_DASHBOARD_QUERY';
export const ACCEPT_TERMS_AND_CONDITIONS_AGREEMENT = 'ACCEPT_TERMS_AND_CONDITIONS_AGREEMENT';
export const GET_AGREEMENT = 'GET_AGREEMENT';
export const STORE_DASHBOARD_REFETCH = 'STORE_DASHBOARD_REFETCH';
export const SET_LAST_CLICK_TIME = 'SET_LAST_CLICK_TIME';

// FORGOT PASSWORD
export const RESET_PASSWORD_RESET = `${authPrefix}RESET_PASSWORD_RESET`;
export const REQUEST_CODE_STARTED = `${authPrefix}REQUEST_CODE_STARTED`;
export const REQUEST_CODE_SUCCESS = `${authPrefix}REQUEST_CODE_SUCCESS`;
export const REQUEST_CODE_ERROR = `${authPrefix}REQUEST_CODE_ERROR`;

export const RESET_PASSWORD_STARTED = `${authPrefix}RESET_PASSWORD_STARTED`;
export const RESET_PASSWORD_SUCCESS = `${authPrefix}RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${authPrefix}RESET_PASSWORD_ERROR`;

// AUTH
export const REFRESH_TOKEN_SUCCESS = authPrefix + 'REFRESH_TOKEN_SUCCESS';
export const TOKEN_EXPIRES_SOON = authPrefix + 'TOKEN_EXPIRES_SOON';
export const ON_AUTH_REQUEST = authPrefix + 'ON_AUTH_REQUEST';
export const ON_AUTH_START = authPrefix + 'ON_AUTH_START';
export const ON_AUTH_SUCCESS = authPrefix + 'ON_AUTH_SUCCESS';
export const ON_AUTH_FAIL = authPrefix + 'ON_AUTH_FAIL';
export const ON_AUTH_SIGN_OUT = authPrefix + 'ON_AUTH_SIGN_OUT';
export const ON_AUTH_CURRENT_SESSION = authPrefix + 'ON_AUTH_CURRENT_SESSION';
export const ON_AUTH_CURRENT_USER = authPrefix + 'ON_AUTH_CURRENT_USER';
export const ON_CHALLENGE_REQUESTED = authPrefix + 'ON_CHALLENGE_REQUESTED';
export const ON_CHALLENGE_STARTED = authPrefix + 'ON_CHALLENGE_STARTED';
export const ON_CHALLENGE_SUCCESS = authPrefix + 'ON_CHALLENGE_SUCCESS';
export const ON_CHALLENGE_FAIL = authPrefix + 'ON_CHALLENGE_FAIL';

// APPOINTMENT BOOKER
export const SET_DATE = 'SET_DATE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CHANGE_TO_NEXT_WEEK = 'CHANGE_TO_NEXT_WEEK';
export const CHANGE_TO_PREV_WEEK = 'CHANGE_TO_PREV_WEEK';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_DAYS = 'SET_DAYS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SENDING = 'SET_SENDING';
export const RESCHEDULE = 'RESCHEDULE';
export const CREATE_NEW_SESSION = 'CREATE_NEW_THERAPY_SESSION';
export const SET_CALL_DURATION = 'SET_CALL_DURATION';
export const SET_RESCHEDULED_ACTIVITY = 'SET_RESCHEDULED_ACTIVITY';
export const SELECT_MEMBER = 'SELECT_MEMBER';
export const SET_BOOKER_VIEW_NAME = 'SET_BOOKER_VIEW_NAME';
export const SET_MULTI_SCHEDULER_ACTIVITIES = 'SET_MULTI_SCHEDULER_ACTIVITIES';
export const GET_MULTI_SCHEDULER_ACTIVITIES = 'GET_MULTI_SCHEDULER_ACTIVITIES';
export const SET_STAFF = 'SET_STAFF';
export const SET_HOST = 'SET_HOST';
export const SET_TEMPLATE_CODE = 'SET_TEMPLATE_CODE';
export const SET_ACTIVITIES_LOADING = 'SET_ACTIVITIES_LOADING';
export const TOGGLE_MSCH_VISIBILITY = 'TOGGLE_MSCH_VISIBILITY';
export const SET_RECURRENCE = 'SET_RECURRENCE';
export const LOAD_DATA_FROM_SERVER = 'LOAD_DATA_FROM_SERVER';
export const SET_INVITED_MEMBERS = 'SET_INVITED_MEMBERS';
export const RESET_MULTI_SCHEDULER_ACTIVITIES = 'RESET_MULTI_SCHEDULER_ACTIVITIES';

// MY ACCOUNT
export const TOGGLE_UPLOAD_MODAL = myAccountPrefix + 'TOGGLE_UPLOAD_MODAL';
export const RESET = myAccountPrefix + 'RESET';
export const SET_AVATAR = myAccountPrefix + 'SET_AVATAR';

// PATIENT EDIT
export const PATIENT_EDIT_TOGGLE_UPLOAD_MODAL = patientEditPrefix + 'TOGGLE_UPLOAD_MODAL';
export const PATIENT_EDIT_RESET = patientEditPrefix + 'RESET';
export const PATIENT_EDIT_SET_AVATAR = patientEditPrefix + 'SET_AVATAR';

// USD LABS
export const ADD_LAB_REQUEST = 'ADD_LAB_REQUEST';
export const EDIT_LAB_STATUS = 'EDIT_LAB_STATUS';
export const EDIT_LAB_RESULTS = 'EDIT_LAB_RESULTS';
export const ADD_LAB_DETAILS = 'ADD_LAB_DETAILS';

//PATIENT EDIT Sober circle
export const PATIENT_SOBER_CIRCLE_JOIN = patientFamilyPrefix + 'PATIENT_SOBER_CIRCLE_JOIN';

//PATIENT EDIT FAMILY
export const PATIENT_FAMILY_SET_RELATIONS = patientFamilyPrefix + 'FETCH_RELATIONS_SUCCESS';
export const PATIENT_FAMILY_ADD_RELATION = patientFamilyPrefix + 'ADD_RELATION';
export const PATIENT_FAMILY_REMOVE_RELATION = patientFamilyPrefix + 'REMOVE_RELATION';
export const PATIENT_FAMILY_CHANGE_RELATION = patientFamilyPrefix + 'CHANGE_RELATION';

// MEMBER MODULE
export const SET_MEMBER_TYPE = 'SET_MEMBER_TYPE';
export const SET_MEMBER_DATA = 'SET_MEMBER_DATA';
export const SET_EDIT_MEMBER_MODE = 'SET_EDIT_MEMBER_MODE';
