import React from 'react';
import { 
  DialogTitle, 
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const Title = ({
  onClose, 
  title,
  children
}) => {

  const classes = useStyles();
  
  return (
    <DialogTitle disableTypography className={classes.root}>

      <div className={classes.titleTop}>
        {title ? <h4 style={{marginRight: 12}}>{title}</h4> : children}
      </div>
 
      {onClose 
        ? <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        : null
      }
    </DialogTitle>
  );
};

export default Title;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 20px 15px 20px',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  titleTop: {
    marginTop: 10,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.75),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));
