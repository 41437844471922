import React, { useState, useContext, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UserMenu from './UserMenu';
import Popover from './Popover/Popover';
// import styles from './Header.module.scss';

import MessagesList from './Messages/MessagesList';
import UrgentMessagesContainer from './Urgent';

import { Messages } from 'config/settings';
import { ChatContext } from 'context/Chat/Chat';

import {ReactComponent as Logo} from '../../assets/logo-light.svg';
import { NavLink } from 'react-router-dom';
import routes from 'common/routes';

const Header = () => {
  const styles = useStyles();

  const { totalUnread } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = useState(null);
  
  useEffect(() => {
    if (!totalUnread) setAnchorEl(null);
  }, [totalUnread]);

  const open = Boolean(anchorEl);

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.searchBox}>Search</div> */}
      <UserMenu />
    </div>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    marginTop: 15,
    marginBottom: 15,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
    width: 150,
    paddingLeft: 10,
    padding: 5,
    color: '#999',
    backgroundColor: '#ffffff',
    borderRadius: 25,
  }
}));
      
    // const handleUnreadClick = event => {
      //   if (totalUnread > 0) setAnchorEl(event.currentTarget);
      // };
      // const handleClose = () => {
      //   setAnchorEl(null);
      // };
      // <div className={styles.header_nav}>
      //   <ul className={styles.list}>
      //     <li className={styles.unreadMessages}>
      //       <span className={styles.unread}>{totalUnread}</span>
      //       <Button
      //         className={styles.unread_button}
      //         aria-controls="unread_messeges"
      //         aria-haspopup="true"
      //         variant="contained"
      //         color="primary"
      //         onClick={handleUnreadClick}
      //       >
      //         {Messages.HEADER_TITLE_UNREAD}
      //       </Button>
      //       <Popover
      //         id={'unread_messeges'}
      //         open={open}
      //         classes={{ paper: styles.urgent_popover }}
      //         className={`${styles.popover} ${styles.urgent_popover}`}
      //         anchorEl={anchorEl}
      //         anchorOrigin={{
      //           vertical: 'bottom',
      //           horizontal: 'left',
      //         }}
      //         onClose={handleClose}
      //         title={Messages.HEADER_TITLE_UNREAD}
      //       >
      //         <MessagesList />
      //       </Popover>
      //     </li>
      //     <li className={styles.urgentMessages}>
      //       <UrgentMessagesContainer />
      //     </li>
      //   </ul>
      // </div> 