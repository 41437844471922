import React, { useEffect } from 'react';

// Material-UI
import { Button, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Styles
import { makeStyles } from '@material-ui/styles';

// Components
import Repeat from '../common/Recurrence/Repeat';
import MemberSelect from './components/MemberSelect';
import Duration from './components/Duration';
import BookingPreview from './components/BookingPreview';
import WebexLink from './components/WebexLink';
import Description from './components/Description';


// New Components
import BookingTypeTabs from './components/BookingTypeTabs';
import AttendeeSelectors from './components/AttendeeSelectors';

// Context
import {useCountRenders} from 'common/hooks/useCountRenders';
import useBookingContext from 'context/useBookingContext';

const BookingSetup = props => {
  
  // Hooks
  const classes = useStyles();
  useCountRenders('BookingSetup.jsx');

  // Context Data
  const {
    isLoading,
    canEditHost,
    bookingType,
    attendeeConfig, // add attendeeList
    setBookingType,
    hasRegardingMember,
    hasInvitedFamily,
    memberType,
    memberData,
    hostEditMode,
    memberEditMode,
    bookingOptions,
    mainPatient,
    setMainPatient,
    invitedMembers,
    setInvitedMembers,
    handleOnSubmit,
    host,
    setHost,
    webexLink,
    setWebexLink,
    setRecurrence,
    cohosts,
    setCohosts,
    handleClearData,
    selectedTime,
    bookingInProgress,
    submitDisabled,
    activitiesLoading,
    handleRefetchMemberEvents,
    duration,
  } = useBookingContext();

  const staffSelectProps = {
    cohosts,
    setCohosts,
    disabled: activitiesLoading,
  };

  const familyTogglesProps = {
    isFamilySession: hasInvitedFamily,
    invitedMembers,
    disabled: activitiesLoading,
    setInvitedMembers,
    mainPatient,
  };

  const hostSelectProps = {
    data: {
      memberId: host,
    },
    hasPermission: canEditHost,
    disabled: activitiesLoading,
    isEditing: hostEditMode,
    memberType: 'staff',
    heading: 'Specialist (Host)',
    handleChange: value => setHost(value._id),
  };
  console.log('hostSelectProps', hostSelectProps);

  const memberSelectProps = {
    data: {
      memberId: mainPatient,
    },
    hasPermission: memberType === 'family' ? memberData?.relatedPatients?.length > 1 : false,
    isEditing: memberType === 'family' ? memberData?.relatedPatients?.length > 1 : memberEditMode,
    isInvited: true,
    memberType: memberType,
    disabled: activitiesLoading,
    handleChange: value => setMainPatient(value._id),
    heading: 'Patient',
    ...(memberType === 'family' && memberData?.relatedPatients?.length > 1
      ? { options: memberData.relatedPatients }
      : {}),
  };

  const forMemberSelectProps = {
    data: {
      memberId: mainPatient,
      memberInfoWhenNoAccess: memberData?.relatedPatients?.find(p => p._id === mainPatient),
    },
    hasPermission: true,
    isEditing: memberType === 'family' ? memberData?.relatedPatients?.length > 1 : false,
    isInvited: true,
    disabled: activitiesLoading,
    memberType: memberType,
    options: memberData?.relatedPatients,
    handleChange: value => setMainPatient(value._id),
    heading: null,
  };

  const bookingTypeTabsProp = {
    types: bookingOptions,
    bookingType,
    setBookingType,
  };

  const webexLinkProps = {
    webexLink,
    setWebexLink,
  };
  const recurrenceProps = {
    showTitle: true,
    selectedTime,
    setRecurrence,
  };
  
  useEffect(() => {
    return () => handleClearData();
  }, []);

  return (
    <div className={classes.container}>
      <BookingTypeTabs {...bookingTypeTabsProp} />

      {hasRegardingMember && (
        <div className={classes.forMember}>
          <span style={{ margin: '0 10px 0 5px' }}>For Patient</span> <MemberSelect {...forMemberSelectProps} />
        </div>
      )}

      <div className={classes.setupContainer}>
        <div className={classes.section}>
            <SectionTitle isFirst={true}>Attendees</SectionTitle>
            <div className={classes.sectionBody}>
              <AttendeeSelectors
                hasRegardingMember={hasRegardingMember}
                member={memberSelectProps}
                family={familyTogglesProps}
                host={hostSelectProps}
                staff={staffSelectProps}
              />
              <Button variant="text" onClick={() => handleRefetchMemberEvents()}>Refetch</Button>
            </div>
        </div>
      
        <div className={classes.section}>
          <SectionTitle>Scheduling</SectionTitle>
          <div className={classes.sectionBody}>
            {selectedTime ? 
              <React.Fragment>
                <BookingPreview selectedTime={selectedTime} duration={duration} />
                <br />
                <Duration />
                <Repeat {...recurrenceProps} />
                <WebexLink {...webexLinkProps} />
              </React.Fragment>
            : 'Choose a Time Slot'}
          </div>
        </div>

        <div className={classes.section}>
          <SectionTitle collapse={true}>Description</SectionTitle>
          <div className={classes.sectionBody}>
            <Description />
          </div>
        </div>        
      </div>

      <div className={classes.static_bottom}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleOnSubmit}
          disabled={submitDisabled}
          className={classes.button}
        >
          {bookingInProgress ? 'Booking' : 'Book a Meeting'}
        </Button>
      </div>
    </div>
  );
};

export default BookingSetup;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: '1px solid #e0e0e0',
    margin: 0,
    width: 300,
    minWidth: 300,
    padding: '20px 25px',
    backgroundColor: '#fbfbfb',
    overflowX: 'scroll',
  },
  button: {
    minWidth: 300,
  },
  accordion_container: {
    flex: 1,
    margin: '20px 0',
  },
  setupContainer: {
    marginTop: 10,
    marginBottom: 10,
    border: '1px solid #e0e0e0',
    borderRadius: 10,
    backgroundColor: '#fff'
  },
  section: {
    marginTop: 5, 
    marginBottom: 5,
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    borderTop: '1px solid #e0e0e0',
    fontSize: 17,
    fontFamily: 'Avenir Next Demi Bold',
  },
  sectionBody: {
    padding: 15,
  },
  forMember: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e0e0e0',
    background: '#fff',
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
  },
  static_bottom: {
    // bottom: 20,
    // position: 'absolute',
  },
}));

const SectionTitle = ({children, isFirst, collapse}) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle} style={isFirst ? {borderTop: 'none'} : {}}>
      {children}
      {collapse ? (
        <IconButton
        aria-label="open-section"
        onClick={() => console.log('open section')}
        edge="start"
        style={{color: '#CDD4DA'}}
      >
        <MenuIcon />
      </IconButton> 
      ) : null}
    </div>
  )
};