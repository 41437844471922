import React, { useEffect, useState } from "react";
import _ from "lodash";
import nanoid from "nanoid";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Chip, Grid } from "@material-ui/core";

import styles from "./PatientEdit.module.scss";
import { EDIT_PATIENT, GET_FULL_PATIENT_INFO, SEARCH_DISORDERS } from "data/graphql/patients";
import TextField from "common/components/InputFields/TextField/TextField";
import Datepicker from "common/components/InputFields/KeyboardDatepicker/Datepicker";
import Dropdown from "common/components/InputFields/Dropdown/Dropdown";
import FormikCheckbox from "common/components/InputFields/FormikCheckbox/FormikCheckbox";
import TextChip from "common/components/InputFields/TextChip/TextChip";
import MemberAvatar from "common/components/Member/Avatar/MemberAvatar";
import { fuzzyDateToMoment } from "common/helpers/timeHelpers";
import { FormDevTools } from "common/components/Formik/FormDevTools";

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';
import useAccountContext from 'context/Account/useAccountContext';

const PatientEditClinicalPath = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Data
  const {memberData: patient} = useMemberStateContext(); 
  const {organization} = useAccountContext();

  // Setup
  const orgLevelOfCare = organization.levelOfCare;
  const orgPrograms = organization?.program;
  const orgFamilyRelease = organization?.familyRelease;
  const levelsOfCare = orgLevelOfCare.map(c => (c.id, c.name))
  const programs = orgPrograms.map(c => (c.name))
  const familyReleases = orgFamilyRelease.map(c => (c.name))
  
  const coDisorders = _.get(patient, 'coDisorders', [])
  const [getDisorders, { /* loadingDisorders,  */data }] = useLazyQuery(SEARCH_DISORDERS);
  const [searchTitle, setSearchTitle] = useState("N/A")
  const [hasSearched, setHasSearched] = useState(false)

  useEffect(() => {
    if (data) {
      try {
        setSuggestionChipData(data.getDisorders
          .map((l, index) => ({ key: nanoid(index), label: `${l.code} ${l.name}` }))
          .filter(l => !coDisorderChipData.map(l => l.label).includes(l.label))
        )
      } catch (err) {
        console.log(err)
      }
    }
  }, [data]);

  const [editPatient] = useMutation(EDIT_PATIENT, {
    refetchQueries: [{ query: GET_FULL_PATIENT_INFO, variables: { _id: patient._id } }],
    onError: () => enqueueSnackbar('Error Updating', { variant: 'error' }),
    onCompleted: () => enqueueSnackbar('Profile Updated', { variant: 'success' })
  });

  const handleSuggestionChipClick = chipClicked => () => {
    setSuggestionChipData(chips => chips.filter(chip => chip.key !== chipClicked.key));
    setCoDisorderChipData(chips => [...chips, chipClicked]);

  };

  const handleCodisorderChipDelete = chipClicked => () => {
    setCoDisorderChipData(chips => chips.filter(chip => chip.key !== chipClicked.key));
  };

  const [suggestionChipData, setSuggestionChipData] = React.useState([]);


  const [coDisorderChipData, setCoDisorderChipData] = React.useState(coDisorders ?
    coDisorders.map((l) => ({ key: nanoid(), label: `${l.code} ${l.name}` }))
    : []
  )

  const handlePatientClinicalEdit = (data) => {

    const levelOfCareSelected = orgLevelOfCare.filter(l => (l.name === data.levelOfCare)).map(l => (l._id))[0]
    const programSelected = orgPrograms.filter(p => (p.name === data.program)).map(p => (p._id))[0]
    const familyReleaseSelected = orgFamilyRelease.filter(r => (r.name === data.familyReleaseE)).map(r => (r._id))[0]
    const coDisordersSelected = coDisorderChipData
      .map(r => ({ 
        code: r.label.split(' ')[0], 
        name: r.label.slice(r.label.split(' ')[0].length + 1, r.label.length) 
      }))

    const updatedDrugChoice = data.drugChoice
      ? data.drugChoice.map(chip => ({ name: chip }))
      : [];

    const patientData = {
      patient: {
        _id: patient._id,
        drugChoice: updatedDrugChoice,
        lastUsed: data.lastUsed,
        gamificationDisabled: !data.gamification,
        clinicalOverview: data.clinicalOverview,
        alerts: data.alerts,
        tags: data.tags,
        levelOfCare: levelOfCareSelected,
        familyRelease: familyReleaseSelected,
        programId: programSelected,
        coDisorders: coDisordersSelected
      }
    }

    editPatient({ variables: patientData })
  }

  const preventEnter = e => ((e.charCode || e.keyCode) === 13) && e.preventDefault();

  const { _id, firstName, lastName, image, emrPic, type } = patient;
  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type,
    label: `${firstName} ${lastName}`,
    showLabel: true,
    disabled: true,
    size: 'xlarge',
  }
  
  return (
    <Grid className={styles.wrapper}>
      <Grid container className={styles.module_edit}>
        <Formik
          initialValues={{
            levelOfCare: _.get(patient, 'levelOfCareE.name', 'Outpatient'),
            drugChoice: patient.drugChoice ? patient.drugChoice.map(chip => chip.name) : [],
            lastUsed: patient.lastUsed ? fuzzyDateToMoment(patient.lastUsed) : '',
            program: _.get(patient, 'program.name', 'MyFYR'),
            gamification: !patient.gamificationDisabled || false,
            coDisorders: coDisorders || '',
            coDisordersSearch: '',
            familyReleaseE: _.get(patient, 'familyReleaseE.name', 'Pending Task'),
            clinicalOverview: patient.clinicalOverview,
            alerts: patient.alerts,
            tags: patient.tags
          }}
          onSubmit={handlePatientClinicalEdit}
          validateOnChange={false}
          validateOnBlur={false}>
          {({ values, errors }) => (
            <Form onKeyDown={preventEnter}>
              <Grid container className={styles.wrapper}>

                <Grid item xs={3}>
                  <div className={styles.avatar_wrapper}>
                    <MemberAvatar {...avatarProps} />
                  </div>
                </Grid>

                <Grid item xs={9}>
                  <Grid container direction='column' spacing={2}>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Clinical Path
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label>
                        Level of Care
                      </label>
                      <Field
                        name='levelOfCare'
                        component={Dropdown}
                        value={values.levelOfCare}
                        items={levelsOfCare}
                        renderValue={v => `${v}`} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label htmlFor="lastUsed">Date of Last Use</label>
                      <Field
                        name='lastUsed'
                        component={Datepicker}
                        disablePast={false} />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Primary Substance(s)</label>
                      <Field 
                        name='drugChoice'
                        component={TextChip}
                        value={values.drugChoice} 
                        />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Program</label>
                      <Field
                        name='program'
                        component={Dropdown}
                        value={values.program}
                        items={programs}
                        renderValue={v => `${v}`} />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="gamification"
                        component={FormikCheckbox}
                        label="Gamification Enabled"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Clinical Overview
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <label>Patient Clinical Overview</label>
                      <Field
                        name='clinicalOverview'
                        component={TextField}
                        multiline
                        rows="4" />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Alerts</label>
                      <Field
                        freeSolo
                        name='alerts'
                        value={values.alerts}
                        component={TextChip}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Tags</label>
                      <Field
                        freeSolo
                        name='tags'
                        value={values.tags} 
                        component={TextChip}
                        />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Family Release</label>
                      <Field
                        name='familyReleaseE'
                        component={Dropdown}
                        value={values.familyReleaseE}
                        items={familyReleases}
                        renderValue={v => `${v}`} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Co-occuring Disorders
                      </Box>
                    </Grid>

                    <div style={{padding: '15px', border: '1px solid #ccc'}}>
                    <Grid item xs={12}>
                      <label htmlFor="location">Search ICD-10 Database</label>
                      <Field
                        name='coDisordersSearch'
                        component={TextField}
                        style={{margin:'10px 0'}}
                        placeholder="Type name of Diagnosis or ICD-10 code" />

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setHasSearched(true)
                          getDisorders({ variables: { searchTerm: values.coDisordersSearch } })
                          setSearchTitle(values.coDisordersSearch)
                        }}>
                      Search for Disorders
                      </Button>
                    </Grid>

                    { hasSearched && suggestionChipData.length > 0 ?
                      <div style={{marginTop: '10px', padding:'5px'}}>
                        <label>Search Results for: "{searchTitle}", click on disorder below to add to Patient</label>
                      </div>
                      : searchTitle !== "N/A" && suggestionChipData.length === 0 ?
                          <div style={{marginTop: '10px', padding:'5px'}}>
                            <label>No results for "{searchTitle}"</label>
                          </div>
                        : ""
                    }

                    {suggestionChipData.length > 0 &&
                      suggestionChipData.map(data => {
                        return (
                          <div style={{ padding: '5px'}}>
                            <Chip
                              key={data.key}
                              label={data.label}
                              color="primary"
                              variant="outlined"
                              onClick={handleSuggestionChipClick(data)}
                              clickable={true}
                            />
                          </div>
                        );
                      })
                    }
                    </div>
                    
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <label>Patient's Co-occurring Disorders</label>
                      </Grid>
                      {coDisorderChipData.length > 0 ?
                        coDisorderChipData.map(data => {
                          return (
                            <Grid item xs={12}>
                              <Chip
                                key={data.key}
                                label={data.label}
                                onDelete={handleCodisorderChipDelete(data)}
                              />
                            </Grid>
                          );
                        })
                        :
                        <Grid item xs={12}>
                          <Chip
                            label={'No Co-occurring Disorders. Search to add...'} />
                        </Grid>}
                    </Grid>


                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained">
                        Update
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <FormDevTools values={values} errors={errors} />
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default PatientEditClinicalPath;