import moment from 'moment';
import _ from 'lodash';

/**
 * Convert Data to Monthly to Averages
 * @param {Object} data Object to convert
 * @param {String} accessor name of object property to average 
 * @returns {Array} Array from Object 
 */
export const convertMonthlyAverages = (data, accessor) => {

  // Group by Month
  const grouped = _.groupBy(data, (note) => moment(note.date).startOf('month').format('YYYY-MM'));
  const tempArray = Object.entries(grouped);

  // Extract Averages
  const monthlyAverages = tempArray.map(n => {
   
    const notes = n[1].map(n2 => n2[accessor]); // reach 2nd level array
    const averages = notes.reduce((acc,cur) => Math.round(acc + cur / notes.length), 0);

    return { 
      'x': n[0], 
      'y': averages
    }

  });

  return monthlyAverages;
} 