import React, { Fragment, useEffect, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid, Avatar, Box, Button } from '@material-ui/core';

import { graphql } from '@apollo/client/react/hoc';
import { makeStyles } from '@material-ui/core/styles';

import { Switch, Route, Redirect } from 'react-router-dom';
import { GET_FAMILY_MEMBER } from 'data/graphql';

// Components
import AvatarDialog from 'common/components/AvatarDialog/AvatarDialog';
import Header from 'common/components/PanelHeader/PanelHeader';
import ErrorScreen from 'common/components/Errors/DefaultError';

// Tabs
import EditProfile from './Tabs/Profile/Profile';
import EditOrganization from './Tabs/Organization/Organization';
import EditClinicalPath from './Tabs/ClinicalPath/ClinicalPath';

import Svg from 'react-svg';
import IconPlus from 'common/assets/plus-rounded.svg';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationName } from 'selectors';
import { setEditMode } from 'redux/actions/memberModule';


const useStyles = makeStyles({
  root: {

  },
  pageContent: {
    padding: 40,
  },
  avatar: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    width: '160px !important',
    height: '160px !important',
    backgroundColor: '#e5e5e5 !important',
  },
  user: {
    width: 200,
    textAlign: 'center',
  },
  avatarText: {
    marginTop: 20,
  },
  plusIcon: {
    '& svg': {
      width: 70,
      height: 70,
      fill: '#bebebe',
      display: 'block',
    }
  }
});


const FamilyMemberEdit = ({ familyMember, match: { url, params } }) => {

  const { error, loading, getFullFamilyMemberInfo: data, refetch } = familyMember;

  // State
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const organizationName = useSelector(getOrganizationName);
  const refetchDashboard = useSelector(state => state.dashboard.refetchFn);

  const urlNoSlash = url.replace(/\/+$/g, '');

  // Dialog
  const [showSuccess, setShowSuccess] = useState(false);
  const [avatarOpen, setAvatarOpen] = useState(false);

  const handleAvatarOpen = () => setAvatarOpen(true);
  const handleAvatarClose = () => setAvatarOpen(false);

  
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  // const avatarEl = useRef(null);

  // Member Module Setup
  const updateEditMode = (data) => dispatch(setEditMode(data))

  // To Do: fix when changing tabs
  useEffect(()=> {
    updateEditMode(true)
    return () => updateEditMode(false);
  })

  const handleAvatarSubmit = result => {
    setAvatarPreview(result.content);
    setAvatarFile(result);
    handleAvatarClose();
  }


  // Render Props
  const listTabs = [{
    label: "Profile",
    url: `${urlNoSlash}/profile`,
    component: () => <EditProfile {...{
      loading, error, data,
      avatarFile, refetch, refetchDashboard,
    }} />,
  }, {
    label: organizationName,
    url: `${urlNoSlash}/organization`,
    component: () => <EditOrganization {...{
      loading, error, data, avatarFile,
      organizationName, refetch, refetchDashboard,
    }} />,
  }, {
    label: "Clinical Path",
    url: `${urlNoSlash}/clinical-path`,
    component: () => <EditClinicalPath {...{
      loading, error, data,
      avatarFile, refetch, refetchDashboard,
    }} />,
  }];

  const headerProps = {
    listBreadcrumbs: [
      { name: "Family", url: "/family" },
      { name: "Profile", url: `/family/${params.id}` },
    ],
    listTabs,
    overflowMenu: [{
      name: 'Back to Profile',
      src: `/family/${params.id}`,
      // permission: {
      //   perform: "EDIT_FAMILY",
      //   patientId: params.id,
      //   module: "FAMILY",
      // }
    }],
  }

  // Render

  if (error) {
    return <ErrorScreen />
  }

  const User = () => {

    let renderAvatar = (
      <Box display="flex" flexDirection="column" alignItems="center" width="200px">
        <Skeleton animation="wave" variant="circle" width={160} height={160} />
        <Skeleton animation="wave" width={170} />
      </Box>
    )

    if (data) {
      const buttonTitle = (data.image || avatarPreview) ? 'Change photo' : 'Add photo';
      const fullName = `${data.title ? `${data.title} ` : ''}${data.firstName} ${data.lastName}`;

      const avatarProps = {
        classes: { root: classes.avatar },
        ...data.image ? {
          src: avatarPreview ? avatarPreview : data.image
        } : {
            onClick: handleAvatarOpen,
            style: { cursor: 'pointer' },
            ...avatarPreview ? {
              src: avatarPreview
            } : {
                children: <Svg className={classes.plusIcon} src={IconPlus} />,
              }
          }
      }

      renderAvatar = (
        <div className={classes.user}>
          <Avatar {...avatarProps} />
          <div className={classes.avatarText} children={fullName} />
          <Box mt={1} children={
            <Button
              color="primary"
              children={buttonTitle}
              onClick={handleAvatarOpen}
            />
          } />
        </div>
      )
    }

    return renderAvatar;
  }

  const Layout = ({ aside, children }) => (
    <div className={classes.pageContent}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>{aside}</Grid>
        <Grid item xs={12} md={8} lg={5}>{children}</Grid>
      </Grid>
    </div>
  );

  return (
    <Fragment>

      <Header {...headerProps} />

      <Route exact path={urlNoSlash} children={<Redirect to={`${urlNoSlash}/profile`} />} />

      <Layout aside={<User />}>
        <Switch>
          {listTabs.map(({ url, component }, i) => (
            <Route exact key={i} path={url} component={component} />
          ))}
        </Switch>
      </Layout>

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

      <AvatarDialog
        open={avatarOpen}
        handleClose={handleAvatarClose}
        handleSubmit={handleAvatarSubmit} />

    </Fragment>
  )
}

FamilyMemberEdit.propTypes = {

}

export default graphql(GET_FAMILY_MEMBER, {
  name: 'familyMember',
  options: props => ({
    variables: { memberId: props.match.params.id }
  })
})(FamilyMemberEdit);
