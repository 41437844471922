import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import styles from './LabsActions.module.scss'

const LabsHeaderSkeleton = () => (
    <Box className={styles.skeleton}>
        {Array(4).fill().map((_, index) => <Skeleton key={`skeleton-LabActions-${index}`} animation="wave" variant='text' width={65} height={90} className={styles.item}/>)}
    </Box>
);

export default LabsHeaderSkeleton;