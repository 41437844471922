export const metrics = {
  radius_normal: 10,
  radius_small: 5,
  header_height: 100,
  layout_margin: '0 25px 25px 25px',
  default_margin: 8,
  titleBar_height: 60,
  titleBar_padding: '0 25px 0 25px',
  titleBar_padding_sm: '0 10px 0 20px',
  titleBar_padding_chatroom: '0 25px 0 10px',
}

export const radius = {
  normal: 10,
  small: 5,
}

export const shadows = {
  dark: '0 0 4px 0 rgba(0,0,0,0.25)',
  light: '0 0 4px 0 rgba(0,0,0,0.10)',
  google: '0 1px 6px 0 rgba(32,33,36,0.28)',
}

export const borders = {
  card_border: '1px solid $gray',
}

export const typography = {
  helvetica_light: 'Helvetica Neue Light', // 300
  avenir_regular: 'Avenir Next Regular', //400
  avenir_medium: 'Avenir Next Medium', // 500
  avenir_demi_bold: 'Avenir Next Demi Bold', //600
  averta_bold: 'Averta Bold',
  averta_regular: 'Averta Regular',
  nexa_bold: 'Nexa Bold',
  nexa_light: 'Nexa Light',
  nexa_regular: 'Nexa Regular',
  signature: 'Signature',
}

export const fontSizes = {
  size_caption: 12,
  size_body2: 14,
  size_body: 16,
  size_subhead: 18,
  size_title: 20,
  size_headline: 22,
  size_headline2: 24,
  size_display: 30, 
  size_display2: 36,
}

export const quill = {
  text_editor_height: 250,
}

export const colors = {
  white: {
    base: '#fff',
    darken: '#f0f0f0',
  },
  grey: {
    base: '#e0e0e0',
    cool: '#f5f6f8',
    darken: '#b4b4b4',
  },
  green: {
    base: '#19cb35',
    light: '#2fb87e',
    darken: '#00b500',
    text: '#2ac940',
  },
  red: {
    base: '#e90100',
    text: '#e02020'
  },
  yellow: {
    base: '#e5cb29',
    rating: '#f8cc1c'
  },
  orange: {
    text: '#fca00e',
  },
  black: {
    base: '#000',
    lighten: 'rgba(0, 0, 0, 0.9)',
  },
  blue: {
    base: '#245c98', //rgba(50,105,165,1)
    light: '#4a7dac',
    text: '#2a96ff',
  }
} 

export const colorsElements = {
  primary_button: '#0072bd',
  addiditon_text: 'rgba(0, 0, 0, 0.5)',
  base_notification: '#630460',
  addition_notification: '#8a4388',
  text_disabled: '#979899',
  chat_typing_indicator: 'rgba(42,150,255,0.25)',
  background_chat: '#e1e9f1',
  blue_chat: '#6184a8',
  header_background: colors.blue.base,
  header_text: colors.white.darken,
  tooltip_blue: '#0f2f4e',
}

export const gradients = {
  background_gradient: 'linear-gradient(135deg, #245C98 30%, #3269A5 70%)',
  background_gradient_new: 'linear-gradient(104.14deg, #304CA0 22.05%, #324EA1 26.1%, #3E5BA7 57.46%)',
}

export const eventColors = {
  core: {
    bg: '#eff6ff',
    outline: '#93c5fd',
  },
  personal: {
    bg: '#ecfdf5',
    outline: '#6ee7b7',
  },
  therapySession: {
    bg: '#fdf2f8',
    outline: '#f9a8d4',
  },
  general: {
    bg: '#eff6ff',
    outline: '#93c5fd',
  },
};