import moment from 'moment';

/**
 * Find ordinal suffix for a number using modulus 
 * @param {Number} num; 
 * @return {String} ordinal suffix for number
 */
export const getOrdinal = (num) => {
  let s=["th","st","nd","rd"],
  v=num%100;
  return num+(s[(v-20)%10]||s[v]||s[0]);
}

/**
 * Find week of the month for any date 
 * @param {Object} input moment date; 
 * @param {Number} max maximum number of weeks
 * @return {Number} week of the month
 */
export const weekOfMonth = (input, max = 4) => {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

    const weekNumber = Math.ceil((input.date() + offset) / 7);
    return setMinMax(weekNumber, 1, 4)
}

export const setMinMax = (input, min = 1, max = 4) => {
  return Math.min(Math.max(input, min), max)
}

const splitTime = slots => {
  const freeTime = [];
  slots.forEach(slot => {
    const end = moment(slot.endTime);
    const currentTime = moment(slot.startTime);
    while (currentTime.diff(end) < 0) {
      // to round
      if (currentTime.get('minutes') !== 30 || currentTime.get('minutes') !== 0)
        roundMinutes(currentTime);

      freeTime.push(currentTime.format('HH:mm'));
      currentTime.add(30, 'minutes');
    }
  });

  return freeTime;
};

/**
 * round minutes to 30 or 0
 * @param {Date} time
 */
const roundMinutes = time => {
  if (time.get('minutes') > 0 && time.get('minutes') < 30) {
    time.set('minutes', 30);
  } else if (time.get('minutes') > 30 && time.get('minutes') <= 59) {
    time.add(1, 'hour');
    time.set('minutes', 0);
  }
};

export const startOfWeek = date => {
  const start = moment(date).startOf('week');
  start.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  return start.utc();
};

export const endOfWeek = date => {
  const end = moment(date).endOf('week');
  end.set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
  return end.utc();
};

export const changeWeek = (selectedWeek, type) => {
  if (type === 'next') {
    return moment(selectedWeek).add(1, 'week');
  }
  if (type === 'prev') {
    return moment(selectedWeek).subtract(1, 'week');
  }

  return moment(selectedWeek);
};

export const parseDays = (array, startDate, endDate) => {
  const days = [];
  const start = moment(startDate);
  const end = moment(endDate);
  for (
    let currentDay = start;
    currentDay.diff(end) <= 0;
    currentDay.add(1, 'day')
  ) {
    const item = array.find(i => moment(i.day).isSame(currentDay, 'day'));
    if (item) {
      const date = moment(item.day);
      const day = {
        date: date.format('MM-DD-YYYY'),
        name: date.format('ddd'),
        freeTime: splitTime(item.slots),
      };
      days.push(day);
    } else
      days.push({
        date: currentDay.format(),
        name: currentDay.format('ddd'),
        freeTime: [],
      });
  }
  console.info('parseDaysEND', days);
  return days;
};
