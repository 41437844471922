import {combineReducers} from 'redux'
import {
RESET,
TOGGLE_UPLOAD_MODAL,
SET_AVATAR
} from 'actions/types'

const isUploadModalVisible = (prev = false, action) => {
  switch (action.type) {
    case RESET:
      return false
    case TOGGLE_UPLOAD_MODAL:
      if (action.payload !== undefined) {
        return action.payload
      }

      return !prev
    default:
      return prev
  }
}

const originalAvatar = (prev = null, action) => {
  switch (action.type) {
    case RESET:
    case TOGGLE_UPLOAD_MODAL:
      return null
    case SET_AVATAR:
      return action.payload
    default:
      return prev
  }
}

export default combineReducers({
  isUploadModalVisible,
  originalAvatar,
})