import React, { useState } from 'react';
import timezone from 'moment-timezone';

// UI
import {
  Grid,
  Button,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import styles from './MyAccount.module.scss';

// Formik
import { Formik, Field, Form } from 'formik';
import { ClinicianProfileSchema } from './schema';
import { FormDevTools } from "common/components/Formik/FormDevTools";
import Timepicker from 'common/components/InputFields/Timepicker/Timepicker';
import TextField from 'common/components/InputFields/TextField/TextField';
import Dropdown from 'common/components/InputFields/Dropdown/Dropdown';
import FormikCheckbox from 'common/components/InputFields/FormikCheckbox/FormikCheckbox';

// Data
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { EDIT_STAFF_ACCOUNT, ME_STAFF } from 'data/graphql/staff';
import { getDuration, toUTCTime, fromUTCToLocal } from 'common/helpers/timeHelpers';
import { stringCapitalize } from 'common/helpers/textHelpers';

const AccountWorkingHours = ({ staff }) => {

  //console.log('%c[My Account] Raw staff prop', 'background-color:yellow; color:red', staff)

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Data
  const accountData = staff?.account || {};
  const openingHours = accountData?.openingHours || {};
  const paddingTime = ['0', '15', '30', '45', '60'];
  const defaultHours = { start: '09:00', end: '17:00', padding: 0 };

  // State
  const [mondayClosed, setMondayClosed] = useState(!!openingHours?.monday?.closed);
  const [tuesdayClosed, setTuesdayClosed] = useState(!!openingHours?.tuesday?.closed);
  const [wednesdayClosed, setWednesdayClosed] = useState(!!openingHours?.wednesday?.closed);
  const [thursdayClosed, setThursdayClosed] = useState(!!openingHours?.thursday?.closed);
  const [fridayClosed, setFridayClosed] = useState(!!openingHours?.friday?.closed);
  const [saturdayClosed, setSaturdayClosed] = useState(!!openingHours?.saturday?.closed);
  const [sundayClosed, setSundayClosed] = useState(!!openingHours?.sunday?.closed);

  // GraphQL
  const [editStaff] = useMutation(EDIT_STAFF_ACCOUNT, {
    refetchQueries: [{ query: ME_STAFF }],
    onError: (e) => {
      enqueueSnackbar(e && e.message
        ? e.message
        : 'Error Updating', { variant: 'error' });
    },
    onCompleted: () =>
      enqueueSnackbar('Account Updated', { variant: 'success' }),
  });

  // Handler
  const handleAccountProfileEdit = async data => {
    const paddingTime = Number(data.paddingTime) || defaultHours.padding;

    const staffDataToMutate = {
      account: {
        minHours: data.startHours,
        maxHours: data.endHours,
        duration: getDuration(data.endHours, data.startHours),
        timezone: timezone.tz.guess(true),
        paddingTime,
        openingHours: {
          monday: {
            closed: mondayClosed,
            ...mondayClosed ? {} : {
              duration: getDuration(data.mondayEnd, data.mondayStart),
              from: toUTCTime(data.mondayStart),
              to: toUTCTime(data.mondayEnd)
            },
          },
          tuesday: {
            closed: tuesdayClosed,
            ...tuesdayClosed ? {} : {
              duration: getDuration(data.tuesdayEnd, data.tuesdayStart),
              from: toUTCTime(data.tuesdayStart),
              to: toUTCTime(data.tuesdayEnd)
            },
          },
          wednesday: {
            closed: wednesdayClosed,
            ...wednesdayClosed ? {} : {
              duration: getDuration(data.wednesdayEnd, data.wednesdayStart),
              from: toUTCTime(data.wednesdayStart),
              to: toUTCTime(data.wednesdayEnd)
            },
          },
          thursday: {
            closed: thursdayClosed,
            ...thursdayClosed ? {} : {
              duration: getDuration(data.thursdayEnd, data.thursdayStart),
              from: toUTCTime(data.thursdayStart),
              to: toUTCTime(data.thursdayEnd)
            },
          },
          friday: {
            closed: fridayClosed,
            ...fridayClosed ? {} : {
              duration: getDuration(data.fridayEnd, data.fridayStart),
              from: toUTCTime(data.fridayStart),
              to: toUTCTime(data.fridayEnd)
            },
          },
          saturday: {
            closed: saturdayClosed,
            ...saturdayClosed ? {} : {
              duration: getDuration(data.saturdayEnd, data.saturdayStart),
              from: toUTCTime(data.saturdayStart),
              to: toUTCTime(data.saturdayEnd)
            },
          },
          sunday: {
            closed: sundayClosed,
            ...sundayClosed ? {} : {
              duration: getDuration(data.sundayEnd, data.sundayStart),
              from: toUTCTime(data.sundayStart),
              to: toUTCTime(data.sundayEnd)
            },
          },
        },
      }
    };
    console.log('staffDataToMutate: ', staffDataToMutate);
    try {
      await editStaff({ variables: staffDataToMutate });
    } catch (err) {
      console.log('[Account] Update Working Hours mutation failed: ', err)
    }
  };

  return (
    <div>
      <Formik
        validationSchema={ClinicianProfileSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          startHours: defaultHours.start,
          endHours: defaultHours.end,
          paddingTime: accountData?.paddingTime || defaultHours.padding,
          mondayStart: openingHours?.monday?.from ? fromUTCToLocal(openingHours?.monday?.from) : defaultHours.start,
          mondayEnd: openingHours?.monday?.to ? fromUTCToLocal(openingHours?.monday?.to) : defaultHours.end,
          tuesdayStart: openingHours?.tuesday?.from ? fromUTCToLocal(openingHours?.tuesday?.from) : defaultHours.start,
          tuesdayEnd: openingHours?.tuesday?.to ? fromUTCToLocal(openingHours?.tuesday?.to) : defaultHours.end,
          wednesdayStart: openingHours?.wednesday?.from ? fromUTCToLocal(openingHours?.wednesday?.from) : defaultHours.start,
          wednesdayEnd: openingHours?.wednesday?.to ? fromUTCToLocal(openingHours?.wednesday?.to) : defaultHours.end,
          thursdayStart: openingHours?.thursday?.from ? fromUTCToLocal(openingHours?.thursday?.from) : defaultHours.start,
          thursdayEnd: openingHours?.thursday?.to ? fromUTCToLocal(openingHours?.thursday?.to) : defaultHours.end,
          fridayStart: openingHours?.friday?.from ? fromUTCToLocal(openingHours?.friday?.from) : defaultHours.start,
          fridayEnd: openingHours?.friday?.from ? fromUTCToLocal(openingHours?.friday?.to) : defaultHours.end,
          saturdayStart: openingHours?.saturday?.from ? fromUTCToLocal(openingHours?.saturday?.from) : defaultHours.start,
          saturdayEnd: openingHours?.saturday?.to ? fromUTCToLocal(openingHours?.saturday?.to) : defaultHours.end,
          sundayStart: openingHours?.sunday?.from ? fromUTCToLocal(openingHours?.sunday?.from) : defaultHours.start,
          sundayEnd: openingHours?.sunday?.to ? fromUTCToLocal(openingHours?.sunday?.to) : defaultHours.end,
          mondayClosed, tuesdayClosed, wednesdayClosed, thursdayClosed, fridayClosed, saturdayClosed, sundayClosed,
          timezone: accountData?.timezone
        }}
        onSubmit={handleAccountProfileEdit}
      >
        {({ values, errors, handleChange }) => (
          <Form>
            <Grid
              container
              item
              xs={9}
              direction="column"
              spacing={1}
              justify="space-between"
              className={styles.working_hours}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h3">Setup</Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <label style={{ marginBottom: 10 }} htmlFor="paddingTime">
                    Padding Time between calls
                  </label>
                  <Field
                    name="paddingTime"
                    items={paddingTime}
                    component={Dropdown}
                    disabled={true}
                    value={values.paddingTime}
                    style={{ marginRight: 10 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span style={{ marginRight: 25 }}>min.</span>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label style={{ marginBottom: 10 }} htmlFor="timezone">
                    Timezone
                  </label>
                  <Field
                    name="timezone"
                    value={values.timezone}
                    component={TextField}
                    disabled={true}
                  ></Field>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={12}>
                  <Typography variant="h3">My Availability</Typography>
                </Grid>

                <WorkDayRow day="monday" isClosed={mondayClosed} updateState={setMondayClosed} />
                <WorkDayRow day="tuesday" isClosed={tuesdayClosed} updateState={setTuesdayClosed} />
                <WorkDayRow day="wednesday" isClosed={wednesdayClosed} updateState={setWednesdayClosed} />
                <WorkDayRow day="thursday" isClosed={thursdayClosed} updateState={setThursdayClosed} />
                <WorkDayRow day="friday" isClosed={fridayClosed} updateState={setFridayClosed} />
                <WorkDayRow day="saturday" isClosed={saturdayClosed} updateState={setSaturdayClosed} />
                <WorkDayRow day="sunday" isClosed={sundayClosed} updateState={setSundayClosed} />

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Update Working Hours
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <FormDevTools values={values} errors={errors} />
                </Grid>

              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountWorkingHours;


const WorkDayRow = ({ day, isClosed, updateState }) => {

  const handleChange = () => updateState(!isClosed);
  const value = [day + 'Toggle'];

  return (
    <>
      <Grid item xs={3}>
        <Field
          name={value}
          checked={!isClosed}
          onChange={handleChange}
          component={FormikCheckbox}
          label={stringCapitalize(day)}
        />
      </Grid>
      {!isClosed ?
        <>
          <Grid item xs={3}>
            <Field
              name={[day + 'Start']}
              component={Timepicker}
              disabled={isClosed}
            />
          </Grid>
          <Grid item xs={1}>to</Grid>
          <Grid item xs={3}>
            <Field
              name={[day + 'End']}
              component={Timepicker}
              disabled={isClosed}
            />
          </Grid>
        </>
        : <Grid item xs={7}><div style={{ display: 'flex', alignItems: 'center', height: 50, opacity: 0.5 }}>Not working on this day.</div></Grid>
      }
    </>
  )
};