import React from 'react';
import styles from './styles.module.scss';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography'
import { stringCapitalize } from 'common/helpers/textHelpers';

const Dropdown = ({
  field: { name, value },
  form: { setFieldValue, touched, errors },
  renderValue,
  width,
  items,
  labelKey,
  ...props
}) => {
  const [localValue, setValue] = React.useState('');

  const handleChange = event => {
    setValue(event.target.value);
    console.log('selected value', localValue);
    setFieldValue(name, event.target.value);
    console.log('👨‍🚀 Setting value to =>', value);
  };

  return (
    <FormControl variant="outlined" className={styles.container}>
      <Select
        displayEmpty={true}
        id={`${name}-dropdown`}
        value={localValue}
        onChange={e => handleChange(e)}
        {...props}
        renderValue={renderValue}
        classes={{root: styles.input}}>
        <MenuItem value="" disabled>Choose an option</MenuItem>
        {items &&
          items.map((item, index) => (
            <MenuItem value={item} key={index}>{labelKey && item[labelKey] ? stringCapitalize(item[labelKey]) : stringCapitalize(item)}</MenuItem>
          ))
        }
      </Select>
      {touched[name] && errors[name] && (
        <Typography className={styles.error}>{errors[name]}</Typography>
      )}
    </FormControl>
  );
};

export default Dropdown;
