import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {any} from 'prop-types'

import styles from './index.module.scss'

const MyDropzone = (props) => {

  const onDrop = useCallback((acceptedFiles) => {

    const file = acceptedFiles[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      props.onChange({
        content: reader.result,
        name: file.name,
        type: file.type,
      })
    }

    reader.readAsDataURL(file)
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return <div className={styles['dropzone-wrapper']}>
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      {isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drop some files here, or click to select files</p>}
    </div>
  </div>
}

MyDropzone.propsTypes = {
  onChange: any.isRequired
}

export default MyDropzone