
import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, List, ListItem, ListItemText, Link as LinkMUI } from '@material-ui/core';


const OverflowMenu = ({ classes, onClick, list }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const styles = useStyles();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Render
  const popoverProps = {
    id, open, anchorEl,
    onClose: handleClose,
    className: styles.overflowMenu,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  }

  return (
    <Fragment>
      {list && (
        <div className={`${styles.overflowMenu} ${classes}`}>
          <MoreVert onClick={e => handleClick(e)} className={styles.overflowIcon} />
          <Popover {...popoverProps}>
            <List>
              {list && list.map(item => (
                <ListItem key={item.name}>
                  <ListItemText children={
                    ('onClick' in item) ? (
                      <LinkMUI style={{ cursor: 'pointer' }} onClick={item.onClick}>{item.name}</LinkMUI>
                    ) : (
                      <Link to={item.src?.to} state={item.src?.state} onClick={onClick}>{item.name}</Link>
                    )
                  }/>
                </ListItem>
              ))}
            </List>
          </Popover>
        </div>
      )}
    </Fragment>
  )
}

export default OverflowMenu;

const useStyles = makeStyles(() => ({
  overflowMenu: {
    border: 0,
    padding: 0,
    display: 'block',
    marginLeft: 10,
    marginRight: -10,
    backgroundColor: 'transparent',
  },
  overflowIcon: {
    fill: '#B4B4B4',
    '&:hover': {
      fill: '#2A96FF',
    },
  },
}))