import React from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";

// Style
import styles from "./Login.module.scss";
import {ReactComponent as Logo} from "common/assets/logo-light.svg";

// Components
import LoginForm from "./LoginForm/LoginForm";
import ChallengeForm from "./PasswordChallenge/ChallengeForm";

// Auth
import {authChallengeTypes} from 'context/Auth/AuthNContext';
import {useAuthNContext} from 'context/Auth/useAuthContext';

const Login = () => {

   const {authStatus} = useAuthNContext();
   console.log('[Auth] authStatus', authStatus)
   const AuthForm = () => {
      if (authStatus === authChallengeTypes.NEW_PASSWORD_REQUIRED) return <ChallengeForm />
      return <LoginForm />
   };

   return (
      <Grid 
         container 
         justifyContent="center"
         alignItems="center"
         className={styles.container}
      >
         <Grid item>
            <Logo className={styles.logo}/>
         </Grid>

         <Grid item lg={12} xs={12} md={12} sm={12} className={styles.wrapper}>
            <form className={styles.form}>
               <AuthForm />
            </form>
         </Grid>

         <p className={styles.copyright}>&copy; {moment().year()} inRecovery Co.</p>
      </Grid>
   );
};

export default Login;
