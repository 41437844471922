import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import cn from 'classnames';
import { Field as Input } from 'formik';
import { Button } from '@material-ui/core';

import styles from './Form.module.scss';
import Autocomplete from './FormikAutocomplete/FormikAutocomplete';
import Datepicker from 'common/components/InputFields/Datepicker/Datepicker';
// import RadioGroup from 'common/components/InputFields/Radio/RadioGroup';
// import RadioButton from 'common/components/InputFields/Radio/Radio';
//import FormikRadio from './FormikRadio/FormikRadio';

const Form = ({
	onCancel,
	handleSubmit,
	handleChange,
	setFieldValue,
	values, errors,
	formFields,
	hasDateRange,
	resetForm,
}, ref) => {
	useImperativeHandle(ref, () => ({
		resetForm,
		handleSubmit,
		setFieldValue,
	}));

	const [minDateLimit, setMinDateLimit] = useState({});
	const [maxDateLimit, setMaxDateLimit] = useState({});

	// On Mount
	useEffect(() => {
		if ('dateMin' in values && 'dateMax' in values) {
			setMinDateLimit({ maxDate: values.dateMax });
			setMaxDateLimit({ minDate: values.dateMin });
		}
	}, []);

	// On Date Change
	useEffect(() => {
		if (values.dateMax) { setMinDateLimit({ maxDate: values.dateMax }) }
		setMaxDateLimit({ minDate: values.dateMin });
	}, [values.dateMin]);

	useEffect(() => {
		if (values.dateMin) { setMaxDateLimit({ minDate: values.dateMin }) }
		setMinDateLimit({ maxDate: values.dateMax });
	}, [values.dateMax]);

	
	// const radioProps = {
	// 	name: 'activated',
	// 	options: [
	// 		{ value: true, label: 'Yes' },
	// 		{ value: false, label: 'No' },
	// 	]
	// }
	
	return (
		<form noValidate className={styles.filtersForm} onSubmit={handleSubmit}>
	
			{Object.keys(formFields).map((key, i) => {
				
				// Check if options contains objects
				const fieldOptions = formFields[key].options
				const hasObjects = fieldOptions.some(o => typeof o === 'object')
				//const hasBoolean = fieldOptions.some(o => typeof o === 'boolean');
				
				const { label, placeholder, options } = formFields[key];
				return (
					<div className={styles.formGroup} key={key}>
						<div className={styles.label}>{label}</div>
						<div className={styles.control}>
							<Autocomplete
								multiple
								name={key}
								value={values[key]}
								options={options}
								label={placeholder}
								hasObjects={hasObjects}
								setFieldValue={setFieldValue}/>
								
							{/* {!hasBoolean 
								? <Autocomplete
										multiple
										name={key}
										value={values[key]}
										options={options}
										label={placeholder}
										hasObjects={hasObjects}
										setFieldValue={setFieldValue}/>

								: <Input row name="activated" component={RadioGroup}>
										<Input
											name="activated"
											type="radio"
											value={true}
											label="Yes"
											component={RadioButton}
										/>
										<Input
											name="activated"
											type="radio"
											value={false}
											label="No"
											component={RadioButton}
										/>
									</Input>
							} */}
						</div>
					</div>
				)
			})}
	
			{hasDateRange && <>
				<div className={styles.formGroup}>
					<div className={styles.label}>Start Date</div>
					<div className={styles.control}>
						<Input {...minDateLimit}
							name="dateMin"
							placeholder="Choose start date"
							component={Datepicker}/>
					</div>
				</div>
				<div className={styles.formGroup}>
					<div className={styles.label}>End Date</div>
					<div className={styles.control}>
						<Input {...maxDateLimit}
							name="dateMax"
							placeholder="Choose end date"
							component={Datepicker}/>
					</div>
				</div>
			</>}
			
	
			<div className={cn(styles.formGroup, styles.formFooter)}>
				<div className={styles.label}></div>
				<div className={styles.control}>
					
					<Button 
						type="submit" 
						color="primary"
						variant="contained" 
						className={cn(styles.btn, styles.btnSubmit)} 
					>Ok</Button>

					<Button
						variant="text" 
						onClick={onCancel}
						className={styles.btn}
					>Cancel</Button>

				</div>
			</div>
	
		</form>
	)
}

export default forwardRef(Form);