import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Chip from "../../Chip/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import keyBy from "lodash/keyBy";
import styles from "./styles.module.scss";
import { Box, Typography, Avatar, TextField } from "@material-ui/core";

const UserChip = ({
  suggestions,
  field,
  form: { setFieldValue, setFieldTouched, touched, errors },
  label,
  onItemAdd,
  onItemRemove,
  isUserObject,
  ...props
}) => {

  const [chipData, setChipData] = React.useState(field.value ? field.value : null);

  useEffect(() => {
    setFieldValue(field.name, chipData);
  }, [chipData]);

  const getUserLabel = user => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return "???";
  };

  const getOptionLabel = option => isUserObject ? getUserLabel(option) : option

  const hasError = errors[field.name] && touched[field.name];

  const onDelete = (option) => () => {

    setFieldTouched(field.name, true);

    setChipData(chipData
      .filter(chip => chip._id !== option._id)
    );

    if (typeof onItemRemove === "function") {
      onItemRemove(option);
    }
  };

  const onChange = (_, selectedOptions) => {

    setFieldTouched(field.name, true);

    const options = selectedOptions ? Object.values(keyBy(selectedOptions, '_id')) : []

    setChipData(options);

    if (options && chipData) {
      if (options.length > chipData.length) {
        const newChip = options.find(option =>
          !chipData.find(chip => chip._id === option._id)
        );

        if (typeof onItemAdd === "function") {
          onItemAdd(newChip);
        }
      } else if (options.length < chipData.length) {
        const removedChip = chipData.find(chip =>
          !options.find(option => chip._id === option._id)
        );

        if (typeof onItemRemove === "function") {
          onItemRemove(removedChip);
        }
      }
    }
  };

  return (
    <Box className={styles.container}>
      <Autocomplete
        multiple
        options={suggestions}
        freeSolo
        includeInputInList
        getOptionLabel={getOptionLabel}
        {...props}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            classes={{ root: styles.root }}
            variant="outlined"
            InputProps={{ ...params.InputProps, endAdornment: null }} />
        )}
        renderTags={() =>
          chipData.map(data => (
            <Chip
              key={data._id}
              onDelete={onDelete(data)}
              label={getOptionLabel(data)}
              avatar={
                isUserObject && data.image ?
                  <Avatar
                    src={data.image}
                    className={styles.avatar}
                    alt={"avatar"}
                  /> : null
              }
            />
          ))
        }
      />
      {hasError ? <Typography className={styles.error}>{errors[field.name]}</Typography> : null}
    </Box>
  );
};

UserChip.propTypes = {
  form: PropTypes.any.isRequired,
  suggestions: PropTypes.array.isRequired,
  onItemAdd: PropTypes.func,
  onItemRemove: PropTypes.func,
};

export default UserChip;