import React, { useState } from 'react';
import Button from 'common/components/Button/ButtonSimple';
import styles from './DialogContainer.module.scss';

const DialogContainer = ({component: Component, data, title, isUpdate = false}) => {
 const [open, setOpen] = useState(false)
 const handleDialogOpen = () => setOpen(true)
 const handleDialogClose = () => setOpen(false)
 return (
   <>
     <Button onClick={handleDialogOpen} className={styles.view_more}>{title}</Button>
     <Component data={data} handleClose={handleDialogClose} open={open} isUpdate={isUpdate} />
   </>
 )
}

export default DialogContainer;