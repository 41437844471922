import React from 'react';
import PropTypes from 'prop-types';

const TypeLabel = ({ label }) => {
    let iconColor;
    let title;

    switch (label) {
        case null:
            title = "--"
            break;

        case "CALL":
            iconColor = "#630460"
            title = "Call"
            break;

        case "CARE":
            iconColor = "#386FA4"
            title = "Care"
            break;

        case "LAB":
            iconColor = "#E90100"
            title = "Lab"
            break;

        default: 
            iconColor = "rgba(0,0,0,0.25)"
            title = "n/a"

    }

    const dotStyles = {
        marginRight: '6px',
        borderRadius: '100%',
        backgroundColor: iconColor,
        width: '8px', height: '8px',
    }

    return (
    <div style={{ display:'flex', alignItems: 'center' }}>
        {label && <span style={dotStyles}/>}
        {title}
    </div>
    )
}

TypeLabel.propTypes = {
    label: PropTypes.oneOfType([
        () => null, 
        PropTypes.string
    ]).isRequired
}

export default TypeLabel;