import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'

// HOC
import { makeClient } from 'data/apollo/apolloClient'
import WithQuery from 'hoc/Dashboard/DashboardQueryWrapper'
import AgreementChecker from 'hoc/AgreementChecker'
import ErrorHandler from 'hoc/ErrorHandler'

// Context
import SessionContext from 'context/Session/SessionContext'
import AuthZContext from 'context/Auth/AuthZContext'
import AccountContext from 'context/Account/AccountContext'
import GuideContext from 'context/Guides/GuideContext'
import ChatContext from 'context/Chat/Chat'
import MembersContext from 'context/Members/MembersContext'

// Components
import Layout from 'common/components/Layout/Layout'
import SetupGuide from 'screens/Support/SetupGuide'

// Utils
import { useCountRenders } from 'common/hooks/useCountRenders'
import { useAuthNContext } from 'context/Auth/useAuthContext'

const AppLoader = ({ children }) => {
  useCountRenders('AppLoader')
  const { handleRefreshToken } = useAuthNContext()

  // Setup
  const apolloClient = makeClient(handleRefreshToken)

  return (
    <ApolloProvider client={apolloClient}>
      <WithQuery>
      <ErrorHandler>
        <SessionContext>
          <AuthZContext>
            <AccountContext>
              <MembersContext>
                <AgreementChecker />
                <ChatContext>
                  <GuideContext>
                    <SetupGuide />
                    <Layout>{children}</Layout>
                  </GuideContext>
                </ChatContext>
              </MembersContext>
            </AccountContext>
          </AuthZContext>
        </SessionContext>
      </ErrorHandler>
      </WithQuery>
    </ApolloProvider>
  )
}

AppLoader.propTypes = {
  children: PropTypes.element.isRequired,
}

export default AppLoader
