import { Messages } from 'config/settings';

/**
 * Patient Member Activation Warning Messages 
 * @param {Object} data Patient member data object 
 * @returns {Array} warningDetails with warning data 
 */
export const activationWarnings = (data) => {

  const warningMessage = {
    email: {
      item: Messages.PATIENT_ACTIVATION_WARNING_EMAIL,
      help: Messages.PATIENT_ACTIVATION_WARNING_EMAIL_HELP,
      route: `/patients/${data?._id}/edit/profile`,
    },
    careTeam: {
      item: Messages.PATIENT_ACTIVATION_WARNING_CARETEAM,
      help: Messages.PATIENT_ACTIVATION_WARNING_CARETEAM_HELP,
      route: `/patients/${data?._id}/edit/caron`,
    },
    phone: {
      item: Messages.PATIENT_ACTIVATION_WARNING_PHONE,
      help: Messages.PATIENT_ACTIVATION_WARNING_PHONE_HELP,
      route: `/patients/${data?._id}/edit/profile`,
    },
    device: {
      item: Messages.PATIENT_ACTIVATION_WARNING_DEVICE,
      help: Messages.PATIENT_ACTIVATION_WARNING_DEVICE_HELP,
      route: `/patients/${data?._id}/edit/profile`,
    }
  }

  const warningDetails = [
    ...(!!!data?.email) ? [warningMessage.email] : [],
    ...(!!!data?.phone) ? [warningMessage.phone] : [],
    ...(data?.careTeamDetails?.length < 1) ? [warningMessage.careTeam] : [],
    // ...(!(data?.appleId || data?.googleId)) ? [warningMessage.device] : [],
  ];
  return warningDetails;

}

/**
 * Generates Module Tabs
 * @param {String} basePath 
 * @param {String} memberId 
 * @param {Boolean} isActivated 
 * @param {Boolean} isDischarged 
 * @returns Array of module tab navigation setup
 */
export const generateModuleTabs = (baseUrl, memberId, isActivated, isDischarged) => {

  const moduleOverview = {
    label: 'Overview',
    url: `${baseUrl}/${memberId}`,
    value: '/patients/:id',
    componentId: 'overview',
  };

  const moduleMessaging = {
    label: 'Messaging',
    url: `${baseUrl}/${memberId}/messaging`,
    value: '/patients/:id/messaging',
    componentId: 'messaging',
  };

  const moduleSchedule = {
    label: 'Schedule',
    url: `${baseUrl}/${memberId}/activities`,
    value: '/patients/:id/activities',
    componentId: 'activities',
  };

  const moduleNotes = {
    label: 'Notes',
    url: `${baseUrl}/${memberId}/notes`,
    value: '/patients/:id/notes',
    componentId: 'notes',
    // permission: {
    //   module: 'PATIENTS',
    //   patientId: memberId,
    //   perform: 'VIEW_PATIENT_NOTES',
    // },
  };

  // const moduleDraftNotes = {
  //   label: 'Drafts',
  //   url: `${baseUrl}/${memberId}/notes/drafts`,
  //   value: '/patients/:id/notes/drafts',
  //   componentId: 'noteDrafts',
  // };

  const moduleLabs = {
    label: 'Labs',
    url: `${baseUrl}/${memberId}/labs`,
    value: '/patients/:id/labs',
    componentId: 'labs',
  };

  const moduleTabs = [
    moduleOverview,
    ...(isDischarged ? [moduleNotes, moduleLabs] : []),
    ...(isActivated && !isDischarged ? [
      moduleMessaging,
      moduleSchedule,
      moduleNotes,
      // moduleDraftNotes,
      moduleLabs,
    ]: []),
  ];

  return moduleTabs
}