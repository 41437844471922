
import PatientEditPeers from 'screens/Patient/Edit/SoberCircle';
import React from 'react';

const EditPeers = () => {
  
  return (
    <PatientEditPeers />
  )
  
}

export default EditPeers;