import React, { Fragment, useState } from 'react';

import { 
  Popover, List, ListItem, 
  ListItemText, Link as LinkUI 
} from '@material-ui/core';

import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import MoreIcon from '../../assets/more-vert.svg';

const Button = ({ children, color, onClick, classes = "", disabled = false }) => {
  return (
    <button
      className={`${styles.button} ${classes}`}
      style={{ backgroundColor: color }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;

export const ButtonWithIcon = ({ icon, classes, children, onClick, hideText, ...props }) => (
  <button className={`${styles.buttonWithIcon} ${classes}`} onClick={onClick} {...props}>
    <ReactSVG className={styles.icon} src={icon} />
    { !hideText && <span  className={styles.text}>{children}</span>}
  </button>
);

export const ButtonPrimary = ({ children, color, size, to, classes = "", onClick, ...props }) => (
  <button
    onClick={onClick}
    style={{
      color,
      fontSize: size,
    }}
    className={`${styles.primary} ${classes}`}
    {
      ...props
    }
  >
    <span>{children}</span>
  </button>
);

export const ButtonDots = ({ children, classes, onClick, list }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Render
  const popoverProps = {
    id, open, anchorEl,
    onClose: handleClose,
    className: styles.options,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  }

  return (
    <Fragment>
      {list && (
        <div className={`${styles.button_dots} ${classes}`}>
          <button onClick={e => handleClick(e)} className={styles.dots}>
            <ReactSVG src={MoreIcon}/>
          </button>
          <Popover {...popoverProps}>
            <List>
              {list && list.map(item => (
                <ListItem key={item.name}>
                  <ListItemText children={
                    ('onClick' in item) ? (
                      <LinkUI style={{ cursor: 'pointer' }} onClick={item.onClick}>{item.name}</LinkUI>
                    ) : (
                      <Link to={item.src} onClick={onClick}>{item.name}</Link>
                    )
                  }/>
                </ListItem>
              ))}
            </List>
          </Popover>
        </div>
      )}
    </Fragment>
  )
}
