import { Messages } from 'config/settings';

/**
 * Family Member Activation Warning Messages 
 * @param {Object} data Family member data object 
 * @returns {Array} warningDetails with warning data 
 */
export const activationWarnings = (data) => {
  const warningMessage = {
    email: {
      item: Messages.FAMILY_ACTIVATION_WARNING_EMAIL,
      help: Messages.FAMILY_ACTIVATION_WARNING_EMAIL_HELP,
      route: `/family/${data?._id}/edit/profile`,
    },
    careTeam: {
      item: Messages.FAMILY_ACTIVATION_WARNING_CARETEAM,
      help: Messages.FAMILY_ACTIVATION_WARNING_CARETEAM_HELP,
      route: `/family/${data?._id}/edit/organization`,
    },
    relatedPatient: {
      item: Messages.FAMILY_ACTIVATION_WARNING_PATIENT,
      help: Messages.FAMILY_ACTIVATION_WARNING_PATIENT_HELP,
      route: `/patients`,
    },
    device: {
      item: Messages.FAMILY_ACTIVATION_WARNING_DEVICE,
      help: Messages.FAMILY_ACTIVATION_WARNING_DEVICE_HELP,
      route: `/family/${data?._id}/edit/profile`,
    }
  }

  const warningDetails = [
    ...(!!!data?.email) ? [warningMessage.email] : [],
    ...(data?.careTeamDetails?.length < 1) ? [warningMessage.careTeam] : [],
    ...(data?.relatedPatients?.length < 1) ? [warningMessage.relatedPatient] : [],
    // ...(!(data?.appleId || data?.googleId)) ? [warningMessage.device] : [],
  ];
  return warningDetails;

}

// @Bogi this works too :P
// const warningDetails = [];
// if (!!!data?.email) warningDetails.push(warningMessage.email)
// if (data?.careTeamDetails?.length < 1) warningDetails.push(warningMessage.careTeam)
// if (data?.relatedPatients?.length < 1) warningDetails.push(warningMessage.relatedPatient)