import React, { Fragment, useState } from 'react'
import moment from 'moment'

import { 
  Box,
  Avatar,
  Button,
  Tooltip,
  Typography,
  ListItemIcon,
  Link as LinkMui,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import VideocamIcon from '@material-ui/icons/Videocam'
import LoopIcon from '@material-ui/icons/Loop'
import CheckIcon from '@material-ui/icons/Check'
import styles from './TooltipContent.module.scss'

// Components
import ButtonSimple from 'common/components/Button/ButtonSimple'
import CoreIcon from 'common/assets/activity/core.svg'
import TherapyIcon from 'common/assets/activity/therapy-session.svg'
import PersonalIcon from 'common/assets/activity/personal.svg'
import ParticipationList from 'common/components/Participations/List'
import DisplayHTML from 'common/components/DisplayHTML'
import UINote from '../../../Member/Actions/UINote'

// Utils
import { mySessionTypes } from 'config/settings'
import { truncateString } from 'common/helpers/dataHelpers'

// Data
import { useQuery } from '@apollo/client'
import { GET_ACTIVITY_BEFORE_EDIT } from 'data/graphql/activity'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'
import useMembersContext from 'context/Members/useMembersContext'
import useAccountContext from 'context/Members/useMembersContext'
import { getMemberById, getMembersById } from 'context/Members/MembersContext'

const participateChecker = (appointment, memberId) => {
  let participate = {}
  if (!appointment?.participationInfo?.length) return participate

  appointment.participationInfo.forEach((item) => {
    if (item.memberId === memberId) {
      participate = { checkedIn: item.checkedIn, feedback: item.feedback, responseStatus: item.responseStatus }
    }
  })

  return participate
}

const TooltipContent = ({
  appointmentData,
  toggleVisibility,
  toggleTooltipVisibility,
  commitChanges,
}) => {
  console.log('[Scheduler > Appointments]tooltipcontent Appointment data for Tooltip: ', appointmentData)
  
  const [additionalInfo, setAdditionalInfo] = useState({})
  
  // Context Data
  const { setEditActivity, setIsSeries, setEditAppointmentOpen } = useActivitiesContext()
  const { activePatients, activeFamily, staff } = useMembersContext()
  const membersCollection = { 
    patient: {
      active: activePatients,
      discharged: []
    },
    family: {
      active: activeFamily,
      discharged: []
    },
    staff: {
      active: staff || [],
    }
  }

  // Queries
  const { data: getActivityForEdit } = useQuery(GET_ACTIVITY_BEFORE_EDIT, {
    variables: { activityId: appointmentData.id },
    onCompleted: (data) => {
      if (data.getActivityForEdit) {
        setAdditionalInfo(data.getActivityForEdit)
      }
      console.log('getActivityForEdit', data.getActivityForEdit)
    },
  })
  console.log('getActivityForEdit why is this undefined?', getActivityForEdit) //useQuery returns response data in 'data' variable

  // Data
  const {userId: currentUserId} = useAccountContext();
  const host = getMemberById(staff, appointmentData?.host)

  const startDate = appointmentData.startDate
    ? appointmentData.startDate
    : appointmentData.scheduling
    ? appointmentData.scheduling.start
    : moment()
  
    // const endDate = moment(appointmentData.scheduling.recurrence.end).format('ll');
  const { canEdit, canDelete, uiNotes, isSeries, isSynced, isMaster, appointment } = additionalInfo
  
  React.useEffect(() => {
    setIsSeries(isSeries)
  }, [isSeries])

  const addressText = appointmentData.address?.displayLine
  ? appointmentData.address?.displayLine
  : ''.concat(
      appointmentData.address?.street1,
      appointmentData.address?.city
        ? appointmentData.address?.state
          ? `(${appointmentData.address?.city}, ${appointmentData.address?.state})`
          : `(${appointmentData.address?.city})`
        : appointmentData.address?.state
        ? `(${appointmentData.address?.state})`
        : ''
    )

  // Conditionals
  const isActivityPast = moment(appointmentData.endDate).isBefore(moment())
  const isActivityFuture = moment(startDate).isAfter(moment())
  const isTherapySession = appointmentData.type === 'therapySession'
  const isFamilySession = appointmentData.type === 'familySession'
  const isWebExCall = appointmentData?.webexLink?.length > 0
  const isCall = isTherapySession || isFamilySession
  const isHost = host?._id === currentUserId
  const isScheduled = appointmentData.status === 'SCHEDULED';
  const isCompleted = appointmentData.status === 'COMPLETED';
  const isCreatedByMe = appointmentData.createdBy === currentUserId;
  
  const hasRSVP =  isScheduled && !isCreatedByMe && isActivityFuture && isHost &&  mySessionTypes.includes(appointmentData.type);
  const hasUINotes = uiNotes && uiNotes?.length > 0;
  const hasConference = isWebExCall;
  const hasLocation = appointmentData?.address && addressText !== undefined;

  
  // Icons
  let typeIcon
  if (appointmentData.type === 'core') typeIcon = CoreIcon
  else if (appointmentData.type === 'personal') typeIcon = PersonalIcon
  else typeIcon = TherapyIcon

  // Setup
  // START - TO CLEAN UP
  let patient
  const invitedMembersIds = appointmentData?.invitedMembers || []
  const invitedMembers = invitedMembersIds.map(memberId => getMembersById({members: membersCollection, id: memberId}))
  console.log('[Tooltip] invitedMembers', invitedMembers)

  let members = invitedMembers?.map((member) => {
    if (member?.type === 'patient') patient = member // given that for now we only have one patient for both private sessions and family sessions we don't need additional conditions
    const participate = participateChecker(appointmentData, member?._id)
    return {
      _id: member?._id,
      ...member,
      participate,
    }
  })

  const cohostsIds = appointmentData?.cohosts || []
  const cohosts = cohostsIds.map(memberId => getMembersById({members: membersCollection, id: memberId, memberType: 'staff'}))
  // let cohosts = useSelector((state) => getUsersByIds(state, appointmentData?.cohosts))

  // TODO issue
  if (isFamilySession && patient && patient?.relations) {
    //get the relation names
    members = members.map((m) => {
      if (m.type === 'family') {
        let relation = patient?.relations?.find((r) => r?._id === m?._id)
        return { ...m, relation: relation ? relation?.relation?.name : '' }
      }
      return m
    })
  }
  // END - TO CLEAN UP

  // Content
  const appointmentDateTime = `${moment(startDate).format('MMM D, YYYY')} at ${moment(startDate).format('h:mm a')}`
  const appointmentDuration = `Duration: ${appointmentData?.duration} minutes`

  // Handlers
  const handleAcceptActivity = () => {
    commitChanges({ accepted: appointmentData?.id })
    toggleTooltipVisibility()
  }

  const handleCancelActivity = () => {
    commitChanges({
      canceled: appointmentData.id,
      appointment: appointmentData,
    })
    toggleTooltipVisibility()
  }

  // edit activity popup
  const handleEditActivity = (appointmentData) => {
    setEditActivity({
      ...appointmentData,
      additional: { members, cohosts, host, patient },
      isSeries,
      isMaster,
      nextInSeriesStartTime: appointment?.nextInSeries?.start,
    })
    if (isCall) {
      setEditAppointmentOpen(true)
    } else {
      toggleVisibility('editingFormVisible')
    }
  }

  return (
    <Fragment>
      <Box className={styles.wrapper}>
        <Box className={styles.header}>
          <div className={styles.header_left}>
            <ListItemIcon>
              <Avatar src={typeIcon} className={styles.icon} />
            </ListItemIcon>
            <span className={styles.title}>{appointmentData.title}</span>

            {isSynced ? (
              <Tooltip title="External activity is synced up" arrow classes={{ tooltip: styles.tooltipContent }}>
                <CheckIcon />
              </Tooltip>
            ) : null}
          </div>
          {!isCompleted ? 
            <PopupActions 
              past={isActivityPast} 
              canEdit={canEdit}
              canCancel={canDelete}
              handleEdit={handleEditActivity} 
              handleCancel={handleCancelActivity} 
              handleClose={toggleTooltipVisibility} 
            /> 
          : null}
        </Box>

        {hasUINotes ? <UINotes uiNotes={uiNotes} data={appointmentData} /> : null}

        <Box className={styles.main}>
          {isSeries ? (
            <div className={styles.reccurentMsg}>
              <div>
                <Typography variant="subtitle1">
                  <LoopIcon />
                  Recurring Event
                </Typography>
                {appointmentData.scheduling?.recurrenceText ? (
                  <div>{`Repeats ${appointmentData.scheduling?.recurrenceText}`}</div>
                ) : null}
                {/* {endDate ? <div>Ends {endDate}</div> : null} */}
              </div>
              {isSeries && !isMaster ? <div className={styles.markSeries}>Series</div> : null}
              {isSeries && isMaster ? <div className={styles.markMaster}>Master</div> : null}
            </div>
          ) : null}
          <Typography>{appointmentDateTime}</Typography>
          <Typography>{appointmentDuration}</Typography>

          {hasLocation ? <AppointmentLocation address={addressText} /> : null }
          {hasConference ? <ConferenceDetails link={appointmentData?.webexLink} title="WebEx Call" /> : null }

          <Box className={styles.members}>
            <span className={styles.members_title}>Host:</span>
            <Avatar src={host?.image} className={styles.avatar} alt={'avatar'}>
              {host?.firstName?.[0]}
              {host?.lastName?.[0]}
            </Avatar>
            <span className={styles.member_name}>
              {host?.title} {host?.firstName} {host?.lastName}
            </span>
          </Box>
          {cohosts?.length > 0 ? (
            <Box className={styles.members}>
              <span className={styles.members_title}>Co-hosts:</span>
              {cohosts?.map((cohost) => (
                <Fragment>
                  <Avatar src={cohost?.image} className={styles.avatar} alt={'avatar'}>
                    {cohost?.firstName?.[0]}
                    {cohost?.lastName?.[0]}
                  </Avatar>
                  <span className={styles.member_name}>
                    {cohost?.title} {cohost?.firstName} {cohost?.lastName}
                  </span>
                </Fragment>
              ))}
            </Box>
          ) : null}

          {patient && (
            <Box className={styles.members}>
              <span className={styles.members_title}>Patient:</span>
              <Avatar src={patient?.image} className={styles.avatar} alt={'avatar'}>
                {patient?.firstName?.[0]}
                {patient?.lastName?.[0]}
              </Avatar>
              <span className={styles.member_name}>
                {patient?.firstName} {patient?.lastName?.[0]}
              </span>
            </Box>
          )}

          {appointmentData?.description && (
            <Box className={styles.description}>
              <span className={styles.description_title}>Description:</span>
              <span>
                <DisplayHTML text={appointmentData?.description}></DisplayHTML>
              </span>
            </Box>
          )}
        </Box>

        <ParticipantsFeeback past={isActivityPast} members={members} />
      </Box>
      {hasRSVP ? <AppointmentRSVP handleAccept={handleAcceptActivity} handleCancel={handleCancelActivity} /> : null}
    </Fragment>
  )
}

export default TooltipContent

const PopupActions = ({past, handleClose, canEdit, handleEdit, canCancel, handleCancel}) => {
  return (
    <div className={styles.header_actions}>
      <Box className={styles.actions}>
        {canEdit ? (
          <Button
            style={{ marginRight: '7px' }}
            classes={{ root: styles.button, text: styles.button_text }}
            disabled={past}
            onClick={handleEdit}
          >
            <CreateIcon fontSize="small" />
            <div className={styles.iconTitle}>Edit</div>
          </Button>
        ) : null}

        {canCancel ? (
          <Button classes={{ root: styles.button, text: styles.button_text }} onClick={handleCancel}>
            <DeleteIcon fontSize="small" />
            <div className={styles.iconTitle}>Cancel</div>
          </Button>
        ) : null}

        <Button
          onClick={handleClose}
          className={styles.button_close}
          classes={{ root: styles.button, text: styles.button_text }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </div>
  )
}


const AppointmentLocation = ({address}) => {
  return (
    <div className={styles.location}>
      <LocationOnIcon fontSize="small" style={{ opacity: '0.5' }} />
      <Tooltip title={address} arrow classes={{ tooltip: styles.tooltipContent }}>
        <Typography>{truncateString(address, 30)}</Typography>
      </Tooltip>
    </div>
  )
}

const ConferenceDetails = ({link, title}) => {
  return (
    <div className={styles.location}>
      <VideocamIcon fontSize="small" style={{ opacity: '0.5' }} />
      <Typography>
        <LinkMui href={link} target="_conferenceLink" style={{ marginLeft: 5, fontSize: 14 }}>
          {title}
        </LinkMui>
      </Typography>
    </div>
  )
}

const UINotes = ({uiNotes, data = []}) => {
  return (
    uiNotes?.map((note, i) => <UINote key={i} data={data} {...note} />)
  )
};

const ParticipantsFeeback = ({members, past}) => {
  return (
  <div className={styles.participants_list}>
    <span className={styles.participants_title}>Participants:</span>
    {members && <ParticipationList data={members} past={past} />}
  </div>
  )
}

const AppointmentRSVP = ({handleAccept, handleCancel}) => {
  return ( 
    <Box className={styles.confirm_box}>
      <Typography>Going?</Typography>
      <Box className={styles.options}>
        <ButtonSimple onClick={handleAccept}>Yes</ButtonSimple>
        <ButtonSimple onClick={handleCancel}>No</ButtonSimple>
      </Box>
    </Box>
  )
}