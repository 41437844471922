import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Avatar} from '@material-ui/core';

// Styles
import cn from 'classnames';
import styles from './MemberAvatar.module.scss';

// Helpers
import { avatarDimensions, defaultColors } from '../helper';
import { generateColors } from 'common/helpers/textHelpers';

const UserAvatar = ({ size = "large", firstName = "", lastName = "", ...props }) => {
  
  const avatarSize = avatarDimensions[size];
  
  return <Avatar 
    style={{ 
      height: avatarSize,
      width: avatarSize,
      background: generateColors(defaultColors, `${firstName?.[0]}${lastName?.[0]}`),
    }}
    className={cn(styles.user_avatar, {
      [styles.small_avatar]: size === 'small' || size === 'xsmall',
      [styles.xsmall_avatar]: size === 'xxsmall'
    })}
    {...props}
  >
    {firstName?.[0]}{lastName?.[0]}
  </Avatar>
}

UserAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'small2', 'medium', 'large', 'xlarge']),
};

export default UserAvatar;
