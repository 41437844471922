import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Material
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Components
import OnCall from './OnCall';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import NotificationsPopup from '../Notification/NotificationsPopup/NotificationsPopup';
import styles from './Header.module.scss';

// GraphQL
import { useMutation } from '@apollo/client';
import { ENABLE_ONCALL, DISABLE_ONCALL } from 'data/graphql';

// Data
import { Messages } from 'config/settings';
import {useAuthNContext} from 'context/Auth/useAuthContext';
import {useAuthZContext} from 'context/Auth/useAuthContext';
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';

// Utils
import {checkPermissions} from 'data/permissions';
import useSessionContext from 'context/Session/useSessionContext';
import { Skeleton } from '@material-ui/lab';

const UserMenu = () => {
  const {permissions} = useAuthZContext();
  const {refetchMembers} = useMembersContext();

  const {
    user,
    loading,
    loadingUserData,
    loadingAccount,
    isOnCall,
    organizationName, 
    refetchUser,
  } = useAccountContext()
  
  // Setup
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  // Data
  const displayName = `${user?.title || ''} ${user?.firstName || ''} ${user?.lastName || ''}`;

  // On Call
  const hasPermissionToGoOnCall = checkPermissions({
    permissions, 
    module: 'STAFF',
    action: 'SELF_ENABLE_ONCALL',
    memberType: 'staff',
    memberId: user?.id,
    policy: 'all',
    origin: 'User Menu > On Call',
  });

  const avatarProps = {
    _id: user?._id,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    type: user?.type || 'staff',
    image: user?.image || user?.emrPic,
    disabled: true,
    size: 'small',
  };

  const refetchDashboardData = async () => {
    await refetchMembers();
    await refetchUser();
  }

  // TODO ? Would this be better used as a dispatch action?
  // At the time of the creation we didn't have any staff actions yet but probably it would be better
  const [enableOnCALL, enableOnCallResult] = useMutation(ENABLE_ONCALL, {
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      refetchDashboardData();
      enqueueSnackbar(Messages.STAFF_ONCALL_SUCCESSFULL, {
        variant: 'success',
      });
    },
  });
  const [disableOnCall, disableOnCallResult] = useMutation(DISABLE_ONCALL, {
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      refetchDashboardData();
      enqueueSnackbar(Messages.STAFF_ONCALL_DISABLE_SUSESSFULL, {
        variant: 'success',
      });
    },
  });

  // Handlers
  const handleClose = () => setAnchorEl(null);
  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleEnableOnCall = () => {
    enableOnCALL();
    handleClose();
  };
  const handleDisableOnCall = () => {
    disableOnCall();
    handleClose();
  };

  const userMenuProps = {
    anchorEl,
    handleClose,
    handleEnableOnCall,
    handleDisableOnCall,
    isOnCall,
    hasPermissionToGoOnCall,
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} className={styles.user_menu}>
      <OnCall isOnCall={isOnCall} />
      <div className={styles.menu_notifications}>
        <NotificationsPopup className={styles.ring} />
      </div>
      <div className={styles.menu_data}>
        <Button
          onClick={handleOpen}
          aria-haspopup="true"
          aria-controls="user-menu"
          className={styles.user_button}
        >
          {!loading 
            ? <MemberAvatar {...avatarProps} />
            : <Skeleton animation="wave" variant="circle" width={40} height={40} /> 
          }
          {/* <UserInfo
            loading={loading}
            name={displayName}
            organization={organizationName}
          /> */}
          <ArrowDropDownIcon className={styles.arrow} />
        </Button>
        <UserMenuDropdown {...userMenuProps} />
      </div>
    </div>
  );
};

export default UserMenu;

const UserInfo = ({loading, name, organization}) => {
  return (
    <div className={styles.user_info}>
      {!loading ? <span>{name}</span> : <Skeleton animation="wave" variant="text" width={120} height={20} />}
      {!loading ? <span>{organization}</span> : <Skeleton animation="wave" variant="text" width={60} height={20} />} 
    </div>
  )
};

const UserMenuDropdown = ({
  anchorEl,
  handleClose,
  handleEnableOnCall,
  hasPermissionToGoOnCall,
  handleDisableOnCall,
  isOnCall,
}) => {

  const {handleClearSession} = useSessionContext();
  const {handleUserSignOut} = useAuthNContext();

  const handleSignout = async () => {
    await handleClearSession()
    await handleUserSignOut()
  }

  const menuProps = {
    anchorEl,
    elevation: 1,
    id: 'user-menu',
    open: !!anchorEl,
    keepMounted: true,
    onClose: handleClose,
    getContentAnchorEl: null,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    transformOrigin: { vertical: 'top', horizontal: 'right' },
  };

  return (
    <Menu {...menuProps}>
      <MenuItem onClick={handleClose}>
        <span className={styles.popup_item}>
          <Link to="/account">{Messages.HEADER_MENU_ACCOUNT_SETTINGS}</Link>
        </span>
      </MenuItem>
      {isOnCall ? (
        <MenuItem onClick={handleDisableOnCall}>
          <span className={styles.popup_item}>
            {Messages.HEADER_MENU_DISABLE_ONCALL}
          </span>
        </MenuItem>
      ) : (
        hasPermissionToGoOnCall && (
          <MenuItem onClick={handleEnableOnCall}>
            <span className={styles.popup_item}>
              {Messages.HEADER_MENU_ENABLE_ONCALL}
            </span>
          </MenuItem>
        )
      )}
      <MenuItem onClick={() => handleSignout()}>
        <span className={styles.popup_item}>
          {Messages.AUTH_BUTTON_SIGNOUT}
        </span>
      </MenuItem>
    </Menu>
  );
};
