import React from 'react';

// Material UI
import { 
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import ReactSVG from 'react-svg';
import Congrats from 'common/assets/icon-party.svg';

// TODO: David: make Welcome Screen version. Maybe pass a prop for big/minimized size.

const CompletedGuide = (props) => {

  const { handleFinish } = props;
  const classes = useStyles();

  return (
    <div className={classes.completed}>
      
      <ReactSVG className={classes.icon} src={Congrats} />
      
      <div className={classes.title}>
        Great Job! You're all set!
      </div>
      
      <Button color="primary" variant="contained" onClick={handleFinish}>
        Go to Dashboard
      </Button>
    
    </div>
  )
}

export default CompletedGuide;

const useStyles = makeStyles(theme => ({
  completed: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-evenly', 
    alignItems: 'center', 
    flex: 1,
    width: '100%'
  },
  icon: {
    '& svg': {
      height: '140px',
      width: '140px',
    },
  },
  title: {
    fontSize: 16, 
    fontWeight: 'bold'
  },
}));