import React from 'react'
import PropTypes from 'prop-types'

// Components
import AppointmentBooker from "./AppointmentBooker";

// Context
import BookingContextProvider from 'context/BookingContext';

// Hooks
import {useCountRenders} from 'common/hooks/useCountRenders';

const AppointmentBookerWrapper = () => {
  useCountRenders('AppointmentBooker Loaded')
  return (
    <BookingContextProvider value={null}>
      <AppointmentBooker />
    </BookingContextProvider>
  )
}

AppointmentBookerWrapper.propTypes = {

}

export default AppointmentBookerWrapper;

// selectedMember={this.props.selectedMember}
// originalMember={originalMember}
// handlers = {{onSubmitProps}}
