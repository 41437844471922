import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'react-svg';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Check } from '@material-ui/icons';
import { IconButton, Typography } from '@material-ui/core';

import DownloadIcon from 'common/assets/download.svg';
import { bytesToSize } from 'common/helpers/textHelpers';

const useStyles = makeStyles({
  root: {
    height: '84px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: '10px',
    transition: '.3s ease',
    justifyContent: 'center',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%232A96FFFF' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
  },
  active: {
    backgroundColor: '#f6f6f6',
  },
  text: {
    display: 'flex',
    color: '#2A96FF',
    fontSize: '16px',
    fontWeight: '500',
    alignItems: 'center',
    fontFamily: '"Avenir Next"',
  },
  icon: {
    fill: '#2A96FF',
    marginRight: '22px',
    transform: 'scaleY(-1)',
    '& svg': {
      width: '20px',
      height: 'auto',
    }
  },
  filesList: {
    marginTop: '22px',
  },
  addedFile: {
    display: 'flex',
    alignItems: 'center',
  },
  addedFileText: {
    fontSize: '16px',
    fontWeight: '500',
    margin: '0 8px 0 16px',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: '"Avenir Next"',
  },
  addedFileIconCheck: {
    width: '22px',
    color: '#00B500',
  },
  addedFileButtonDelete: {
    padding: '6px'
  },
  addedFileIconDelete: {
    width: '24px',
    color: '#848484',
    cursor: 'pointer',
  },
});

const RenderAcceptedFiles = ({ data, onRemove }) => {
  const classes = useStyles();
  return !!data.length && <div className={classes.filesList}>
    {data.map(({ name }, i) => 
      <div key={i} className={classes.addedFile}>
        <Check className={classes.addedFileIconCheck}/>
        <Typography className={classes.addedFileText} noWrap children={name}/>
        <IconButton className={classes.addedFileButtonDelete} onClick={() => onRemove(i)}>
          <Delete className={classes.addedFileIconDelete}/>
        </IconButton>
      </div>
    )}
  </div>
}

const DropZone = ({ 
  title = "Attach Files Here", 
  onChange, 
  accept,
  maxSize, minSize,
  limitCount,
  ...props 
}) => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [filesList, setFilesList] = useState([]);

  const onDropAccepted = useCallback(([ file ]) => {

    if (limitCount && filesList.length >= limitCount) {
      return enqueueSnackbar(
        `You can't add more than ${limitCount} file${filesList.length > 1 ? 's' : ''}.`, 
        { variant: 'error' }
      );
    }

    const { name, type } = file;
    const reader = new FileReader();
        
    reader.onload = () => {
      const addedFile = { content: reader.result, name, type };
      setFilesList(prevState => {
        onChange([...prevState, addedFile]);
        return [...prevState, addedFile];
      });
    }

    reader.readAsDataURL(file);

  }, [onChange]);

  // Validate & show error message
  const onDropRejected = ([ file ]) => {
    let errorMessage = 'Unsupported file';

    const wrongMimeType = [...accept.split(',')].indexOf(file.type) === -1;
    if (accept && wrongMimeType) { errorMessage = `Unsupported file extension` }
    if (maxSize && file.size > maxSize) { errorMessage = `File cant be more than ${bytesToSize(maxSize)}` }

    enqueueSnackbar(errorMessage, { variant: 'error' });
  }

  const removeFile = i => {
    const updatedFilesList = [...filesList];
    updatedFilesList.splice(i, 1);
    onChange(updatedFilesList);
    setFilesList(updatedFilesList);
  }

  const dropzoneProps = {};

  if (accept) { dropzoneProps.accept = accept };
  if (minSize) { dropzoneProps.minSize = minSize };
  if (maxSize) { dropzoneProps.maxSize = maxSize; };
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDropAccepted, onDropRejected, ...dropzoneProps });

  

  return <div {...props}>

    <div {...getRootProps()} className={cn(classes.root, { 
      [classes.active]: isDragActive
    })}><input {...getInputProps()}/>

      <div className={classes.text}>
        <Svg className={classes.icon} src={DownloadIcon}/>
        <div>{title}</div>
      </div>

    </div>

    <RenderAcceptedFiles data={filesList} onRemove={removeFile}/>

  </div>
}

DropZone.propTypes = {
  onChange: PropTypes.func.isRequired,
  limitCount: PropTypes.number,
}

export default DropZone;