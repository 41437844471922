import React, {useState} from 'react';
import {FormControl, Select, MenuItem} from '@material-ui/core';

const FamilyEditField = ({handleUpdate, options = [], defaultValue = "", idKey = "_id", valueKey = "name"}) => {
  const [value, setValue] = useState(defaultValue);
  
  const handleChange = (e) => {
    handleUpdate(e.target.value);
    setValue(e.target.value);
  }

  return (
    <FormControl variant="outlined">
      <Select onChange={handleChange} value={value}>
        {options.map(item => (
          <MenuItem key={item[idKey]} value={item[valueKey]}>
            {item[valueKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FamilyEditField;