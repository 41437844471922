import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { GET_LABS } from 'data/graphql/labs';

import LabStatusMap from 'common/components/Labs/LabStatusMap';
import SortableTable from 'common/components/Table/SortableTable';
import Filters from 'common/components/PanelFilters/PanelFilters';

import styles from './LabsTable.module.scss';
import {LabAction} from "common/components/Labs/LabAction"

const columns = [
  { id: 'date', label: 'Date', width: 100 },
  { id: 'patient', label: 'Patient', width: 175 },
  { id: 'result', label: 'Result', width: 125 },
  { id: 'type', label: 'Type', width: 200 },
  { id: 'more', label: 'Actions', width: 200 }
];



const toTableData = data => !data ? [] : data.map((item, i) => {
  return ({
    date: moment(item.dueDate).format('YYYY-MM-DD'),
    patient: `${item.patient.firstName} ${item.patient.lastName}`,
    result: <LabStatusMap name="resultLabel" label={item.result} />,
    type: item.panel.name,
    more: <LabAction name="moreLabel" item={item} />
  })
});

const LabsTable = () => {

  // State
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const [labsData, setLabsData] = useState([]);
  const [filteredLabsData, setfilteredLabsData] = useState([]);

  const [momentToday] = useState(moment());
  const [momentSubstractMonth] = useState(moment().subtract(1, 'months'));

  console.log('raw data>>', labsData)
  console.log('filtered data>>', filteredLabsData)

  // Fetch Data
  const { /* data,  */error, loading } = useQuery(GET_LABS, {
    variables: {
      filters: {
        startDate: momentSubstractMonth.format(format),
        endDate: momentToday.format(format),
      }
    },
    onCompleted: data => {
      try {
        const converted = toTableData(data.getLabs);
        setLabsData(converted);
        setfilteredLabsData(converted);
      } catch (err) {
        console.log(err)
      }
    },
  });

  if (loading || error) return <div className={styles.loading}>Loading Labs...</div>;

  // Methods
  const handleFiltersSubmit = updatedList => setfilteredLabsData(updatedList);

  const filterProps = {
    data: labsData,
    hasSearch: true,
    searchLocalBy: ['patient', 'type'],
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: GET_LABS,
      mutate: toTableData,
      variables: { filters: {} },
      variablesPath: 'filters',
      queryName: 'getLabs',
    },
    fields: {
      patient: {
        label: 'Patient',
        placeholder: 'Choose a patient',
        initialValue: [],
        fieldPath: 'patient',
        fieldValPath: ''
      },
      types: {
        label: 'Panel Type',
        placeholder: 'Choose a type',
        initialValue: [],
        fieldPath: 'type',
        fieldValPath: ''
      },
      results: {
        label: 'Result',
        placeholder: 'Choose a result',
        initialValue: [],
        fieldPath: 'result',
        fieldValPath: 'props.label'
      },
    },
    initDates: {
      minDate: momentSubstractMonth,
      maxDate: momentToday,
    }
  }

  return (
    <Box className={styles.wrapper}>

      <Filters {...filterProps} />

      {/* { parsedData.length > 0 && <DataFilters /> } */}

      <SortableTable
        columns={columns}
        data={filteredLabsData}
        emptyTitle="No lab results yet."
        emptyMessage="Once we receive results, you know where to find them."
      />

    </Box>
  );
};

export default LabsTable;
