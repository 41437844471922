import * as Yup from 'yup';

const validateEndTime = (test, value) => {
  return parseInt(test) < parseInt(value);
};

const HOURSDIFF_ERROR_MESSAGE = 'Ending hours are before starting hours';

export const ClinicianProfileSchema = Yup.object().shape({
  endHours: Yup.string().test(
    'min-max',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.startHours, value);
    }
  ),
  mondayEnd: Yup.string().test(
    'monday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.mondayStart, value);
    }
  ),
  tuesdayEnd: Yup.string().test(
    'tuesday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.tuesdayStart, value);
    }
  ),
  wednesdayEnd: Yup.string().test(
    'wednesday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.wednesdayStart, value);
    }
  ),
  thursdayEnd: Yup.string().test(
    'thursday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.thursdayStart, value);
    }
  ),
  fridayEnd: Yup.string().test(
    'friday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.fridayStart, value);
    }
  ),
  saturdayEnd: Yup.string().test(
    'saturday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.saturdayStart, value);
    }
  ),
  sundayEnd: Yup.string().test(
    'sunday',
    HOURSDIFF_ERROR_MESSAGE,
    function (value) {
      return validateEndTime(this.parent.sundayStart, value);
    }
  ),
});