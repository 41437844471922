import {ApolloLink} from '@apollo/client';
import {Observable} from '@apollo/client/utilities';
import {Auth} from 'aws-amplify';

// Auth Setup
const AUTH_TYPE = {
  COGNITO_OPENID: 'COGNITO_OPENID',
  AWS_LAMBDA: 'AWS_LAMBDA',
}
const authOptions = {
  COGNITO_OPENID: {
    type: AUTH_TYPE.COGNITO_OPENID,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  AWS_LAMBDA: {
    type: AUTH_TYPE.AWS_LAMBDA,
    token: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  }
}

// Auth Header
const headerBasedAuth = async ({header, value}, operation, forward) => {
  const origContext = operation.getContext();
  let headers = {...origContext.headers};
  if (header && value) {
    const headerValue = (await value.call(undefined));
    headers = {
      ...{[header]: `Bearer ${headerValue}`},
      ...headers
    };
  }
  operation.setContext({...origContext, headers});
  return forward(operation);
};

// AuthLink
const createAuthLink = ({auth}) => {
  return new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      let handle;
      let promise;

      if (auth.type === AUTH_TYPE.COGNITO_OPENID) promise = headerBasedAuth({header: 'Authorization', value: auth.jwtToken}, operation, forward);
      if (auth.type === AUTH_TYPE.AWS_LAMBDA) promise = headerBasedAuth({header: 'Authorization', value: auth.token}, operation, forward);
      
      promise.then(observable => {
        handle = observable.subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      return () => {
        if (handle) handle.unsubscribe();
      };
    });
  });
}

export {createAuthLink, authOptions, AUTH_TYPE}
