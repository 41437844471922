import {gql} from '@apollo/client';

export const SURVEY_RESPONSE_FIELDS = `
  response {
    question {
      id
      text
      number
    }
    response {
      label
      timestamp
      value
    }
  }
`

export const LIST_SURVEY_RESPONSES = gql`
 query ListSurveyResponses($filter: SurveyResponseFilter) {
    listSurveyResponses(filter: $filter) {
      member {
        _id
        firstName
        lastName
        image
      }
      survey {
        id
        meta {
          name
        }
      }
      startedAt
      completedAt
      ${SURVEY_RESPONSE_FIELDS}
    }
  }
`;
