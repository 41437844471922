import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

const BaseSelect = ({ items, usePlural, inputRef, value, error, helperText, inputProps, ...props }) => {
  return (
    <Select
      variant="outlined"
      size="small"
      inputRef={inputRef}
      value={value}
      error={error}
      // helperText={helperText}  according to MUI 4.x documentation this doesn't support helperText.
      data-testid="select-root"
      {...inputProps}
      {...props}
    >
      {items &&
        items.map(({ value, label, plural }) => {
          return (
            <MenuItem value={value} key={value} size="small">
              {usePlural && plural ? plural : label}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default BaseSelect;
