import React, {useState} from 'react';
import classNames from 'classnames';

import { 
  Grid, 
  List, 
  Divider, 
  makeStyles, 
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import styles from './Navigation.module.scss';

// Router
import routes from 'common/routes';

// Components
import ListItemLink from 'common/components/Nav/ListItemLink';


// Icons
import IconBolt from 'common/assets/icons/hero-icon/solid/lightning-bolt.svg';
import IconHeart from 'common/assets/icons/hero-icon/solid/heart.svg';
import IconPatient from 'common/assets/icons/hero-icon/solid/user.svg';
import IconFamily from 'common/assets/icons/hero-icon/solid/user-group.svg';
import IconStaff from 'common/assets/icons/hero-icon/solid/users.svg';
import IconIntake from 'common/assets/icons/hero-icon/solid/user-add.svg';
import IconDischarge from 'common/assets/icons/hero-icon/solid/user-remove.svg';
import IconLabs from 'common/assets/icons/hero-icon/solid/beaker.svg';
import IconAdmin from 'common/assets/icons/hero-icon/solid/database.svg';
import IconMessages from 'common/assets/icons/hero-icon/solid/chat-alt.svg';
import IconHelp from 'common/assets/icons/hero-icon/solid/question-mark-circle.svg';
import IconChevronUp from 'common/assets/icons/hero-icon/solid/chevron-up.svg'
import IconChevronDown from 'common/assets/icons/hero-icon/solid/chevron-down.svg'

// Permissions
import {moduleChecker, dashboardChecker} from 'data/permissions';
import {useAuthZContext} from 'context/Auth/useAuthContext';

// Data
import useMembersContext from 'context/Members/useMembersContext';
import ReactSVG from 'react-svg';

const Navigation = () => {

  const classes = useStyles();
  const {
    loadingPatients, 
    activePatientCount, 
    loadingFamily,
    activeFamilyCount, 
  } = useMembersContext()

  const [openIntake, setOpenIntake] = useState(false);
  const [openDischarge, setOpenDischarge] = useState(false);

  const menus = {
    intake: {
      open: openIntake,
      setOpen: setOpenIntake,
    },
    discharge: {
      open: openDischarge,
      setOpen: setOpenDischarge,
    }
  }
  
  return (
    <Grid className={classes.sideNav}>
      <List component="nav" aria-label="navigation list" className={styles.navigation}>

        <ListDivider title="Dashboard" first />

        <VerifyNav dashboard="INTAKE_DASHBOARD">
          <ListItemLink to={routes.dashboardDirector} primary="Director" icon={IconBolt} />
        </VerifyNav>

        <VerifyNav dashboard="COUNSELOR_DASHBOARD">
          <ListItemLink to={routes?.dashboard} primary="Specialist" icon={IconHeart} />
        </VerifyNav>

        <VerifyNav dashboard="LAB_DASHBOARD">
          <ListItemLink to={routes?.dashboardLabs} primary="Labs" icon={IconLabs} />
        </VerifyNav>
      
        
        <ListDivider title="Members" />
        <VerifyNav module="PATIENTS">
          <ListItemLink to={routes.patientList} primary={'Patients'} hasCount count={activePatientCount} loading={loadingPatients} icon={IconPatient} />
        </VerifyNav>
        <VerifyNav module="FAMILY" action="VIEW_FAMILY">
          <ListItemLink to={routes.familyList} primary={'Family'} hasCount count={activeFamilyCount} loading={loadingFamily} icon={IconFamily} />
        </VerifyNav>
        <VerifyNav module="PATIENTS">
          <ListItemLink to={routes.monitoringPatientList} primary={`Monitoring`} icon={IconPatient} />
        </VerifyNav>
        
        <ListItem button onClick={() => menus['intake'].setOpen(prev => !prev)}>
          <ListItemIcon className={classes.listItemIcon}>
            <ReactSVG src={IconIntake} className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Intake" className={classes.listItemText} />
          {menus['intake'].open 
            ? <ReactSVG src={IconChevronUp} className={classes.icon} />
            : <ReactSVG src={IconChevronDown} className={classes.icon} />
          }
        </ListItem>
        <Collapse in={menus['intake'].open} timeout="auto" unmountOnExit>
          <List component="div" className={classes.listNested}>
            <VerifyNav module="PATIENTS">
              <ListItemLink to={routes.intakePatientList} primary={`Patients`} icon={IconPatient} />
            </VerifyNav>
          </List>
        </Collapse>
       
        <ListItem button className={classes.listItemText} onClick={() => menus['discharge'].setOpen(prev => !prev)}>
          <ListItemIcon className={classes.listItemIcon}>
            <ReactSVG src={IconDischarge} className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Discharged" />
          {menus['discharge'].open 
            ? <ReactSVG src={IconChevronUp} className={classes.icon} />
            : <ReactSVG src={IconChevronDown} className={classes.icon} />
          }
        </ListItem>
        <Collapse in={menus['discharge'].open} timeout="auto" unmountOnExit>
          <List component="div" className={classes.listNested}>
            <VerifyNav module="PATIENTS">
              <ListItemLink to={routes.dischargedPatientList} primary={`Patients`} icon={IconPatient} />
            </VerifyNav>
            <VerifyNav module="FAMILY">
                <ListItemLink to={routes.dischargedFamilyList} primary={`Family`} icon={IconFamily} />
            </VerifyNav>
          </List>
        </Collapse>
        
        <VerifyNav module="STAFF" action="VIEW_STAFF">
          <ListItemLink to={routes.staffList} primary="Staff" icon={IconStaff} />
        </VerifyNav>

        <ListDivider title="Modules" />
        <VerifyNav module="MESSAGES">
          <ListItemLink to={routes.messages} primary="Messages" icon={IconMessages} />
        </VerifyNav>
        <VerifyNav module="LABS">
          <ListItemLink to={routes.labs} primary="Lab Results" icon={IconLabs} />
        </VerifyNav>
      </List>
      
      <VerifyNav module="ADMIN">
        <AdminMenu />
      </VerifyNav>
      
      <List component="nav" aria-label="help-desk" className={styles.navigation}>
        <ListItemLink to={routes.helpDesk} primary="Help Desk" icon={IconHelp} />
      </List>
      
    </Grid>
  );
};

const AdminMenu = () => {
  return <div>
    <List component="nav" aria-label="admin" className={styles.navigation}>
      <ListDivider title="Admin" />
      <ListItemLink to={routes.admin} primary="Admin" icon={IconAdmin} />
    </List>
  </div>
};

export default Navigation;

const VerifyNav = ({children, module, action, dashboard}) => {
  const {permissions} = useAuthZContext()

  if (dashboard) {
    const hasDashAccess = dashboardChecker(permissions, dashboard)
    return hasDashAccess ? children : null
  }

  const hasAccess = moduleChecker(permissions, module, action)
  return hasAccess ? children : null
};

const ListDivider = ({title, divider}) => {
  const classes = useStyles();

  return <>  
    <li className={classes.listDivider}>{title}</li>
    
    { divider &&  <Divider className={classes.listDividerBar} /> }
  </>
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: 'transparent',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  listItemIcon: {
    minWidth: 30,
  },
  listItemText: {
    '& span': {
      color: '#F0F0F0',
    },
  },
  sideNav: {
    width: '100%',
    padding: '0 10px',
  },
  icon: {
    '& svg > path': {
      fill: 'rgba(255,255,255,0.5)',
    },
  },
  listDividerBar: {
    backgroundColor: 'rgba(255,255,255,0.10)',
  },
  listDivider: {
    margin: '25px 5px 10px 5px', 
    color: 'rgba(255,255,255,0.5)', 
    fontSize: 11, 
    textTransform: 'uppercase',
    '&:first-child': {
      margin: '10px 5px',
    },
  },
  listNested: {
    marginLeft: 10,
  }
}));
