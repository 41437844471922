import React, { useState } from 'react';
import styles from './CollabseNotification.module.scss';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import _ from 'lodash';
import MemberIcon from '../../../assets/activity/personal.svg';
import ActivityIcon from '../../../assets/rescheduled.svg';
import LabIcon from '../../../assets/navigation/Lab.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import {Link} from 'react-router-dom'

const Item = ({ notification }) => {
  const [expanded, setExpanded] = useState(false);

  const reversedNotifications = _.reverse(_.clone(notification.items));

  let necessaryIcon;
  let backgroundColor;

  if (_.includes(notification.tags, 'patient' || 'member')) {
    necessaryIcon = MemberIcon;
    backgroundColor = '#2AAF71';
  } else if (_.some(notification.tags, 'lab')) {
    necessaryIcon = LabIcon;
    backgroundColor = '#EF5450';
  } else {
    necessaryIcon = ActivityIcon;
    backgroundColor = '#386FA4';
  }

  const Avatar = ({member}) => {
    const { _id, firstName, lastName, image, emrPic, type } = member;
    const avatarProps = { 
      _id,
      firstName, 
      lastName,
      image: image || emrPic,
      type,
      size: 'small',
    }
    return <MemberAvatar {...avatarProps} />
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={styles.type}
      classes={{ expanded: styles.expanded, root: styles.expanded }}
    >
      <AccordionSummary
        expandIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        aria-controls="notification-content"
        className={styles.title}
      >
        <ReactSVG
          style={{
            backgroundColor,
          }}
          src={necessaryIcon}
          className={styles.icon}
          fallback={() => (
            <Typography variant="body1">{`${notification.tags?.[0]}'s icon`}</Typography>
          )}
        />
        <Typography className={styles.name}>{notification.name}</Typography>
        <Box className={styles.count}>{notification.items.length}</Box>
      </AccordionSummary>
      <AccordionDetails className={styles.content}>
        {notification.items &&
          reversedNotifications.map(item => (
            <Box className={styles.item} key={item._id}>
              <Avatar member={item.member} />
              {
                item.sourceCollection === 'Labs' ?
                <Link to={`${item.parsedLink}/labs`}
                className={styles.link_text}
                >
                  {item.parsedContent}
                  </Link>
                 : <Typography variant="body1">{item.parsedContent}</Typography>
              }
            </Box>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

Item.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default Item;
