import React from 'react';
import { Link } from 'react-router-dom';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export const CheckListIcon = (props) => {
  const { checkIf } = props;
  return (
    <div>
    {checkIf 
      ? <CheckCircleIcon style={{ color: '#00B500' }} />
      : <RadioButtonUncheckedIcon color="disabled" />
    }
    </div>
  )
}

export const CheckListItem = ({ 
  title, 
  checkIf, 
  link, 
  location, 
  description, 
  showLink, 
  showLocation, 
  showDescription 
}) => {

  const classes = useStyles();

  return (
  <div className={classes.checkList}>

    <CheckListIcon checkIf={checkIf} /> 
    
    <div className={classes.content}>
      <div className={classes.title}>{title} </div>
      {showLink && <ConditionalLink to={link} condition={checkIf} titleA={'Go to Page'} titleB={'Done'} />}
      {showLocation && <ConditionalLink condition={checkIf}  titleA={location} titleB={location} />}
      {showDescription && <div className={classes.description}>{description}</div> }
    </div>

  </div>
  )
};

const ConditionalLink = (props) => {
  
  const classes = useStyles(props)

  const {to, condition, titleA, titleB } = props;
  const conditionalTitle = !condition ? titleA : titleB;

  return <React.Fragment>
    {to 
      ? <Link to={to} className={classes.link}>{conditionalTitle}</Link>
      : <span className={classes.link}>{conditionalTitle}</span> 
    }
    </React.Fragment>
}

const useStyles = makeStyles(theme => ({
  checkList: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
  },
  content: {
    marginLeft: 15
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold', 
    marginBottom: 2,
  },
  link: {
    fontSize: 14, 
    color: props => !props.condition ? '#2D9BF0' : '#ccc',
  },
  description: {
    fontSize: 14, 
    fontWeight: 'light', 
    color: 'rgba(0,0,0,0.5)',
  },
}));