import React from 'react';
import styles from './index.module.scss';

const LabStatusMap = ({ label }) => {
  let iconColor;
  let resultTitle;

  switch (label) {
    case null:
      resultTitle = "--"
      break;
    case "INCONCLUSIVE":
      iconColor = "rgba(0,0,0,0.25)"
      resultTitle = "Inconclusive"
      break;
    case "POSITIVE":
      iconColor = "#DB0D41"
      resultTitle = "Positive"
      break;
    case "POSITIVE_DILUTE":
      iconColor = "#DB0D41"
      resultTitle = "Positive (D)"
      break;
    case "NEGATIVE_DILUTE":
      iconColor = "#00B318"
      resultTitle = "Complaint (D)"
      break;
    case "NEGATIVE":
      iconColor = "#00B318"
      resultTitle = "Compliant"
      break;
    case "MISSED":
      iconColor = "rgba(0,0,0,0.25)"
      resultTitle = "MISSED";
      break;
    default:
      iconColor = "rgba(0,0,0,0.25)"
      resultTitle = ""
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label && <span className={styles.icon} style={{ backgroundColor: iconColor }} />}
      {resultTitle}
    </div>
  )
}

export default LabStatusMap;