import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const SubstanceAutocomplete = ({ value, onChange }) => {

  const data = require('./druglevels.json');
  let options = [];

  Object.keys(data).forEach(groupName => {

    const items = data[groupName].map(item => {
      item.group = groupName

      return item
    });

    options = options.concat(items);
  });

  const onSelectChange = (e, selected) => {
    onChange(selected);
  };

  return <Autocomplete
    options={options}
    onChange={onSelectChange}
    groupBy={option => option.group}
    getOptionLabel={item => `${item.name || ""}`.trim()}
    inputValue={value ? `${value.name || ""}`.trim() : undefined}
    renderInput={params => (
      <TextField
        {...params}
        fullWidth
        variant="outlined"
        placeholder="Type name ..."/>
    )}/>;
};

export default SubstanceAutocomplete;