import React from 'react';
import PropTypes from 'prop-types';
import {useActivitiesContext} from 'context/Activities/useActivitiesContext';

import Popup from 'common/components/Popup/Popup';

// Data
import { withApollo } from '@apollo/client/react/hoc';

// Utils
import _ from 'lodash';


const AppointmentFormPopup = ({ client, open, handleClose, selectedPatientId }) => {
  const {
    component,
    payload: { title },
    reset,
  } = useActivitiesContext();
  const onClose = e => {
    reset();
    handleClose(e);
  };

  return (
    <Popup title={title} onClose={onClose} open={open} maxWidth={'md'} style={{ minWidth: '825px' }}>
      {component({ onClose })}
    </Popup>
  );
};

AppointmentFormPopup.propTypes = {
  selectedPatientId: PropTypes.string,
};

export default withApollo(AppointmentFormPopup);
