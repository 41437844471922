import React from 'react';

// Components
import HeaderContainer from './HeaderContainer';
import HeaderAvatar from './HeaderAvatar';
import MemberStatus from 'common/components/Member/MemberStatus';
import PatientAlerts from 'common/components/Patient/Alerts/PatientAlerts';

// Data
import { useMemberStateContext } from 'context/Member/useMemberContext';

const MemberHeader = ({children}) => {
  const {memberData} = useMemberStateContext()

  console.log('MemberHeader memberData', memberData)
  const avatarProps = {
    _id: memberData?._id,
    firstName: memberData?.firstName,
    lastName: memberData?.lastName,
    image: memberData?.image || memberData?.emrPic,
    type: memberData?.type,
    mrn: memberData?.mrn,
    disabled: true,
  }

  return (
    <HeaderContainer>
      <HeaderAvatar avatarProps={avatarProps}>
        <MemberStatus status={memberData?.status} lastActive={memberData?.lastActive} />
        <PatientAlerts data={memberData?.alerts} />
      </HeaderAvatar>
      {children}
    </HeaderContainer>
  )
}

export default MemberHeader;