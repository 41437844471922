import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Box, TextField } from '@material-ui/core';

import styles from '../Form.module.scss';
import { resetPasswordReset, initRequestCode } from 'actions';

const ForgotPasswordForm = ({
  history: { push: navigate },
  resetPasswordReset,
  initRequestCode,
  codeError,
  codeLoading,
  codeSuccessMessage,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    if (codeError) {
      setEmailError(codeError);
    }
  }, [codeError]);

  const isValid = () => {
    let error = null;

    if (!validator.isEmail(email)) {
      error = 'Please enter correct e-mail';
    }
    if (validator.isEmpty(email)) {
      error = 'The field is required';
    }

    setEmailError(error);
    return !error;
  };

  const handleCancel = () => {
    resetPasswordReset();
    navigate("/login");
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (isValid()) {
      initRequestCode(email);
    }
  };

  return (
    <div className={styles.login}>
      <h4 className={styles.title}>Forgot my password.</h4>
      <Box mb={2}>
        <p className={styles.text}>No problem, let's reset it. Enter your email address to get started.</p>
      </Box>
      <div className={styles.group}>
        <TextField label="Email" value={email} variant="outlined" onChange={e => setEmail(e.target.value.trim())} />
        <p className={styles.error}>{emailError}</p>
      </div>

      <Box display="flex" justifyContent="space-between" mb={3}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color="primary" variant="contained" disabled={codeLoading} onClick={handleSubmit}>
          {codeLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  codeError: state.forgotPassword.codeError,
  codeLoading: state.forgotPassword.codeLoading,
  codeSuccessMessage: state.forgotPassword.codeSuccessMessage,
});

const mapDispatchToProps = dispatch => ({
  resetPasswordReset: () => dispatch(resetPasswordReset()),
  initRequestCode: email => dispatch(initRequestCode(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordForm));
