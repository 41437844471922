import { createSelector } from 'reselect';

const _getOrganizationName = state => state.dashboard.dashboardData.organization.name;
const locationsList = state => state.dashboard.dashboardData.organization.locations;
const _getOrganizationSetup = state => state.dashboard.dashboardData.organization.setup;
const _myUser = state => state.dashboard.dashboardData.user;

export const getMyUser = createSelector(
  _myUser, user => user
)

export const getOrganizationSettings = createSelector(
  _getOrganizationSetup, setup => setup
)
export const getOrganizationWebex = createSelector(
  _getOrganizationSetup, setup => {
    let prefix = setup.find(s => s.name === "webexLinkPrefix")
    return prefix ? prefix.value : "";
  }
)
export const getOrganizationName = createSelector(
  _getOrganizationName, name => name
)

export const getLocationsList = createSelector(
  locationsList, locations => locations?.length ? locations : []
  // locationsList, locations => locations ? locations.map(l => l.name) : []
)

export const getCurrentLocation = createSelector(
  locationsList, (_, id) => id,
  (locations, id) => {
    if (!locations?.length) return '';
    const findResult = locations.find(i => i._id === id);
    return findResult ? findResult.name : '';
  }
)