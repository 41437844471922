import React, {Fragment} from 'react';

// Data
import useGuideContext from 'context/Guides/useGuideContext';

// Components
import SetupWelcome from './Welcome';
import SetupGuideBox from './GuideBox';

const SetupGuideWrapper = () => {

  const {setupGuideOpen} = useGuideContext();

  const setupGuideProps = {
      enabled: setupGuideOpen,
      height: 400,
      width: 250,
      step: 0
  }

  return (
  <Fragment>
      <SetupWelcome />
      <SetupGuideBox {...setupGuideProps} />        
  </Fragment>
  )
}

export default SetupGuideWrapper;