import { createAction } from 'redux-actions';

import * as types from './types';
import * as activityAction from 'data/graphql/activity';
import { GET_OUTLOOK_EVENTS } from 'data/graphql/outlook';
import { GET_GOOGLE_EVENTS } from 'data/graphql/googleCalendar';
import * as activityHelper from 'components/Scheduler/utils/ActivitySchedulerHelper';

// Actions
export const cleanReducerState = createAction(types.CLEAN_REDUCER_STATE);
export const setToggleLoading = createAction(types.TOGGLE_LOADING, loading => ({
  loading,
}));
export const setActivities = createAction(types.SET_ACTIVITIES, allData => ({
  allData,
}));
export const setEditingActivity = createAction(types.SET_EDITING_ACTIVITY);
export const toggleMySchedule = createAction(types.TOGGLE_MY_SCHEDULE);
export const toggleGoogleCalendar = createAction(types.TOGGLE_GOOGLE_CALENDAR);
export const toggleOutlookCalendar = createAction(types.TOGGLE_OUTLOOK_CALENDAR);
export const toggleMyAvailability = createAction(types.TOGGLE_MY_AVAILABILITY);
export const toggleVisibility = createAction(types.TOGGLE_VISIBILITY);
export const setIsNewAppointment = createAction(types.SET_IS_NEW_APPOINTMENT, isNewAppointment => ({
  isNewAppointment,
}));
export const setAddedAppointment = createAction(types.SET_ADDED_APPOINTMENT, addedAppointment => ({
  addedAppointment,
}));
export const setAppointmentToBeCanceled = createAction(
  types.SET_APPOINTMENT_TO_BE_CANCELED,
  appointmentToBeCanceled => ({ appointmentToBeCanceled })
);
export const setAppointmentToBeCanceledFlag = createAction(
  types.SET_APPOINTMENT_TO_BE_CANCELED_FLAG,
  deletedAppointmentFlag => ({ deletedAppointmentFlag })
);
export const setViewName = createAction(types.SET_VIEW_NAME, currentViewName => ({ currentViewName }));
export const setCurrentDateScheduler = createAction(types.SET_CURRENT_DATE, currentDate => ({ currentDate }));

export const changeFilter = (field, value) => ({
  type: types.FILTER_CHANGE,
  payload: { [field]: value },
});

export const changeFieldId = (field, id) => ({
  type: types.CHANGE_FIELD_ID,
  payload: { [field]: id },
});

// Thunks
export const getActivities = (
  client,
  currentDate,
  currentViewName,
  type,
  patient,
  outlookCalendar,
  googleCalendar,
  myAvailability,
  isStaffModule
) => async dispatch => {
  dispatch(setToggleLoading(true));
  const variables = activityHelper.makeQueryParams(
    currentDate,
    currentViewName,
    isStaffModule ? 'staff' : 'patient',
    patient
  );
  // console.log(
  //   '[Actions > Scheduler]: makeQueryParams for GET_ACTIVITIES query. variables: ',
  //   variables,
  //   'type: ',
  //   type,
  //   'patient: ',
  //   patient,
  //   'currentViewName',
  //   currentViewName
  // );
  const { data } = await client.query({
    query: activityAction.GET_ACTIVITIES,
    variables,
    fetchPolicy: 'no-cache',
  });
  // console.log(
  //   '[Actions > Scheduler]: data from GET_ACTIVITIES: ',
  //   variables,
  //   data
  // );

  let formattedData = [];
  let outlookEvents;
  let googleEvents;

  if (outlookCalendar)
    try {
      outlookEvents = await fetchOutlookEvents(client, currentDate, currentViewName);
    } catch (error) {
      console.log('[GraphQL fetch outlook error:] ', error);
    }
  if (googleCalendar)
    try {
      googleEvents = await fetchGoogleEvents(client, currentDate, currentViewName);
    } catch (error) {
      console.log('[GraphQL fetch google error:] ', error);
    }

  if (currentViewName === 'Month')
    // TODO create appointment layout for external calendars then uncomment line below
    // formattedData = [activityHelper.formatDataToMonthView(data.activities), outlookEvents.getOutlookEvents.map(activityHelper.mapExternalEventsData)].flat();
    formattedData = activityHelper.formatDataToMonthView(data.activities);
  else {
    // console.log(
    //   'formatteddata with outlook1',
    //   outlookEvents,
    //   googleEvents,
    //   data?.activities
    // );
    formattedData = [
      outlookEvents ? outlookEvents?.getOutlookEvents?.map(activityHelper.mapExternalEventsData) : [],
      googleEvents ? googleEvents?.getGoogleEvents?.map(activityHelper.mapExternalEventsData) : [],
      data?.activities?.map(activityHelper.mapAppointmentData),
    ].flat();
  }

  dispatch(
    filterOutActivities({
      allData: formattedData,
      type,
      patient,
      outlookCalendar,
      googleCalendar,
      myAvailability,
      isStaffModule,
    })
  );

  dispatch(setActivities(formattedData));

  // console.log(
  //   '[Action > Scheduler] incoming data: ',
  //   data,
  //   ' formattedData: ',
  //   formattedData
  // );

  dispatch(setToggleLoading(false));
};

export const filterOutActivities = ({
  allData,
  type,
  patient,
  outlookCalendar,
  googleCalendar,
  myAvailability,
  isStaffModule = false,
}) => {
  const data = activityHelper.sortData({
    allData,
    type,
    patient,
    outlookCalendar,
    googleCalendar,
    myAvailability,
    isStaffModule,
  });
  return {
    type: types.FILTER_OUT_ACTIVITY,
    payload: { data },
  };
};

export const fetchOutlookEvents = async (client, currentDate, currentViewName, staff) => {
  const variables = activityHelper.makeQueryParams(currentDate, currentViewName, 'onlyDate', staff);
  const { data, errors } = await client.query({
    query: GET_OUTLOOK_EVENTS,
    variables,
  });

  if (errors) console.error(`[GraphQL fetchOutlookEvents error] ${errors}`);
  if (data) console.log('[Outlook events]', data.getOutlookEvents);

  return data;
};

export const fetchGoogleEvents = async (client, currentDate, currentViewName, staff) => {
  const variables = activityHelper.makeQueryParams(currentDate, currentViewName, 'onlyDate', staff);
  const { data, errors } = await client.query({
    query: GET_GOOGLE_EVENTS,
    variables,
  });

  if (errors) console.error(`[GraphQL fetchGoogleEvents error] ${errors}`);
  if (data) console.log('[Google events]', data.getGoogleEvents);

  return data;
};

export const addAppointment = (client, appointment) => async dispatch => {
  const variables = {
    activity: appointment,
  };
  // const result = await client.mutate({
  await client.mutate({
    mutation: activityAction.INSERT_ACTIVITY,
    variables,
    refetchQueries: [],
  });
  console.log('ADD_APPOINTMENT');
  return {
    type: types.ADD_APPOINTMENT,
    payload: { appointment },
  };
};

export const editAppointment = (client, appointmentInfo) => async dispatch => {
  const { appointmentId, appointment } = appointmentInfo;
  const variables = {
    activity: { _id: appointmentId, ...appointment },
  };

  // const result = await client.mutate({
  await client.mutate({
    mutation: activityAction.EDIT_ACTIVITY,
    variables,
    refetchQueries: [{ query: activityAction.GET_ACTIVITIES }],
  });
  return {
    type: types.ADD_APPOINTMENT,
    payload: { appointment },
  };
};

export const cancelAppointment = (client, appointment, enqueueSnackbar, seriesOption = 'this') => async dispatch => {
  //TODO: ask for deleteAllRecurrentActivities before cancel'
  /**
   *   deleteAllRecurrentActivities can take one of the following options
   * all -> all activities in the series",
   * future -> all future activities in the series
   * none -> only this activity (only works if the activity is not the master, if the activity is the master please let the user know they will loose all activities anyways)
   */
  const variables = {
    _id: appointment,
    seriesOption, // 'all' 'this' or 'future'
  };
  const { data, error } = await client.mutate({
    mutation: activityAction.CANCEL_ACTIVITY,
    variables,
    refetchQueries: [{ query: activityAction.GET_ACTIVITIES }],
  });
  if (data)
    setTimeout(
      () => enqueueSnackbar('This event has been canceled and removed from your calendar.', { variant: 'success' }),
      2500
    );
  if (error) enqueueSnackbar(JSON.stringify(error).slice(0, 40), { variant: 'error' });
  return {
    type: types.CANCEL_APPOINTMENT,
    payload: { appointment },
  };
};

export const acceptAppointment = (client, appointment) => async dispatch => {
  const variables = {
    activityId: appointment,
  };
  // const result = await client.mutate({
  await client.mutate({
    mutation: activityAction.ACCEPT_ACTIVITY_INVITE,
    variables,
    refetchQueries: [{ query: activityAction.GET_ACTIVITIES }],
  });
  return {
    type: types.ACCEPT_APPOINTMENT,
    payload: { appointment },
  };
};
