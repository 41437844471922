import React, {useEffect} from 'react'

// Styles
import cn from 'classnames';
import { makeStyles } from '@material-ui/styles';
import HtmlTooltip from 'common/components/UI/HtmlTooltip';

/**
 * BookingSetup Type Tabs
 * @param {Array} types - Types (Array of Objects).
 * @param {string} type.id - Booking Type ID.
 * @param {string} type.title - Booking Type Title.
 * @param {function} type.handler - Booking Type handler function.
 * @returns {Component} BookingTypeTabs Component.
 */
const BookingTypeTabs = (props) => {
  const { 
    types, 
    bookingType,
    setBookingType,
  } = props;

  const classes = useStyles();
  const currentTab = types.find(t => t.id === bookingType);
  
	useEffect(() => {
    if (currentTab && currentTab.handler) currentTab.handler(); 
  }, [currentTab]);
  
  const handleBookingType = (value, handler) => {
    setBookingType(value);
    if (handler) return handler();
  }

  const Tab = ({ id, title = '', handler, helperTooltip }) => {
    return <>
      <a href={`#${id}`} 
        className={classes.tabLink} 
        onClick={() => handleBookingType(id, handler)}
      >
      {title}
      </a>
      <span className={cn(classes.tabIndicator, bookingType === id && classes.tabIndicatorOn)}></span>
    </>
  }

  return (
  <div className={classes.tabStyle}>
    {types.map(t => (
      <div key={`${t.id}`}>

        {t.enabled 
          ? <Tab id={t.id} title={t.title} handler={t.handler} helperTooltip={t.helperTooltip} />
          : <span className={classes.tabLinkDisabled}>{t.title}</span>
        }

      </div>
    ))}
  </div>
  )
}

export default BookingTypeTabs;

const useStyles = makeStyles(theme => ({
  tabStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabLink: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.8)',
    '&:focus': {
      outline: 'none',
    },
  },
  tabIndicator: {
    display: 'flex',
    height: 6,
    marginTop: 4,
    borderRadius: 3,
  },
  tabIndicatorOn: {
    backgroundColor: '#2A96FF',
  },
  tabLinkDisabled: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.25) !important'
  }
}));
