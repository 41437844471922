import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { statusOptions } from './helper';

const MemberStatus = ({status = 'IMPORTED', lastActive }) => {

  const classes = useStyles();
  const lastSeen = lastActive ? moment(lastActive).fromNow() : null;
  const renderStatus = statusOptions.find(x => x.status === status);
  const showLastSeen = status === 'ACTIVE' && lastSeen

  // Components
  const StatusDot = ({color = '#ccc'}) => <span className={classes.statusDot} style={{backgroundColor: color}} /> 

  if (!renderStatus) return null;
  return (
    <div className={classes.smallText}>
      <StatusDot color={renderStatus?.color} /> 
      {renderStatus?.title}
      {showLastSeen ? <div className={classes.lastSeen}>Last Seen: {lastSeen}</div> : null}
    </div>
  )
}

export default MemberStatus;

MemberStatus.propTypes = {
  status: PropTypes.string,
  lastActive: PropTypes.string,
};

const useStyles = makeStyles({
  smallText: {
    color: '#999',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Avenir Next Regular',
  },
  statusDot: {
    display: 'inline-flex', 
    height: 8, 
    width: 8, 
    marginRight: 5, 
    borderRadius: '50%',
  },
  lastSeen: {
    fontStyle: 'italic',
  },
});

