import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom-v5-compat';

import moment from 'moment';
import _ from 'lodash';

// Data
import { GET_FILTERED_NOTES } from 'data/graphql/notes';

// Components
import Filters from 'common/components/PanelFilters/PanelFilters';
import SortableTable from 'common/components/Table/SortableTable';
import { toTableData } from '../common/utils';

// Insights
import NoteInsights from '../Insights';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { radius, shadows } from 'styles/variables';

// Context
import {useMemberStateContext} from 'context/Member/useMemberContext';

const columns = [
  { id: 'date', label: 'Date', width: 100 },
  { id: 'note', label: 'Note', width: 'auto' },
  { id: 'type', label: 'Category', width: 40 },
  { id: 'subType', label: 'Note Type', width: 100 },
  { id: 'emotion', label: 'Emotions', width: 40 },
  { id: 'engagement', label: 'Engagement', width: 20 },
  { id: 'actions', label: 'Actions', width: 125 },
];

const NotesList = ({notes}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // Context Data
  const {memberId: id} = useMemberStateContext();

  // State
  const [careNotes, setCareNotes] = useState([]);
  const [filteredCareNotes, setfilteredCareNotes] = useState([]);
  const [hasNotes, setHasNotes] = useState();

  // Dates
  const momentToday = moment();
  const momentSubstractMonth = moment().subtract(6, 'months');
  const initDates = {
    maxDate: momentToday,
    minDate: momentSubstractMonth,
  };

  useEffect(() => {
    try {
      const converted = toTableData(notes, navigate, id);
      _.isEmpty(converted) ? setHasNotes(false) : setHasNotes(true);
      setCareNotes(converted);
      setfilteredCareNotes(converted);
    } catch (err) {
      console.log(err);
    }
  }, [notes]);

  const handleFiltersSubmit = updatedList => setfilteredCareNotes(updatedList);

  const filterFields = {
    types: {
      label: 'Note Type',
      placeholder: 'Choose a sub type',
      initialValue: [],
      fieldPath: 'subType',
      fieldValPath: '',
    },
    emotions: {
      label: 'Emotion',
      placeholder: 'Choose a type',
      initialValue: [],
      fieldPath: 'emotion',
      fieldValPath: '',
    },
  };

  const filterProps = {
    data: careNotes,
    fields: filterFields,
    initDates: initDates,
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: GET_FILTERED_NOTES,
      variables: {
        filters: {
          memberId: id,
        },
      },
      variablesPath: 'filters',
      mutate: toTableData,
      queryName: 'getNotes',
    },
    hasSearch: true,
    searchLocalBy: ['note', 'emotion'],
  };

  const tableProps = {
    columns: columns,
    data: filteredCareNotes,
    emptyTitle: 'No notes yet.',
    emptyMessage: 'Stay tuned. Any new note will show up here.',
  };

  return (
    <div className={classes.root}>
      <Filters {...filterProps} />
      <NoteInsights hasNotes={hasNotes} notes={filteredCareNotes} />
      <SortableTable {...tableProps} />
    </div>
  );
};

export default NotesList;

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F6F8',
  },
  insightsBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
    margin: '20px 30px 0 30px',
    minHeight: 75,
    backgroundColor: '#fff',
    borderRadius: radius.normal,
    boxShadow: shadows.google,
  },
  insightSelector: {
    width: 175,
  },
  insightChart: {
    flex: 1,
    height: 120,
  },
});
