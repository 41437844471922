import React, {useEffect, useState} from 'react';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import {ReactComponent as Logo} from '../../assets/light-logo-icon.svg';
import { makeStyles } from '@material-ui/styles';
import { useAuthNContext } from 'context/Auth/useAuthContext';
import { CancelOutlined, CancelRounded } from '@material-ui/icons';

const Loading = () => {
  const classes = useStyles();
  const [loadingTimeout, setLoadingTimeout] = useState()

  const {handleUserSignOut} = useAuthNContext()

  const initLoadTimeout = setTimeout(() => {
    return setLoadingTimeout(true)
  }, 10000)

  useEffect(() => {
    console.log('[LoadingScreen] mounted')
    return () => {
      console.log('[LoadingScreen] Unmounted')
      clearTimeout(initLoadTimeout)
    }
  }, [])

  const handleBackToLogin = () => {
    handleUserSignOut()
  }

  return ( 
    <Grid 
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Grid item>
          <Logo className={classes.logo} />
      </Grid>
      <Grid item>
        {!loadingTimeout ? (
          <>
            <LinearProgress className={classes.progress} /> 
            <div className={classes.loadingText}>Loading... 🚀</div>
          </>
          ) : (
          <>
            <LinearProgress variant='determinate' value={0} className={classes.progress} /> 
            <div className={classes.loadingText}>Opps... couldn't load 🤦‍♀️</div>
          </>
        )}
        <div className={classes.backToLogin}>
          <Button 
            variant={loadingTimeout ? "contained" : "outlined"} 
            className={loadingTimeout ? classes.buttonContained : classes.buttonOutlined} 
            children={loadingTimeout ? "Please Login Again" : "Cancel Login"}
            startIcon={loadingTimeout ? <CancelRounded /> : <CancelOutlined />}
            onClick={handleBackToLogin}
          /> 
        </div>
      </Grid>
    </Grid>
  )
}

export default Loading;

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100vh',
  },
  logo: {
    width: 190,
    height: 190,
    justifyContent: 'center',

  },
  progress: {
    height: 8,
    margin: '5px 0',
    borderRadius: 50,
  },
  buttonOutlined: {
    borderColor: 'rgba(255,255,255,0.1)',
    color: 'rgba(255,255,255,0.75)',
    '& :hover': {
      borderColor: 'rgba(255,255,255,0.5)',
      color: 'rgba(255,255,255,1)'
    }
  },
  buttonContained: {
    color: '#2A96FF',
    backgroundColor: '#fff',
  },
  timeoutError: {
    fontSize: '2em',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: 30,
    fontSize: '1.3em',
    textAlign: 'center',
    color: '#fff',
  },
  backToLogin: {
    margin: 25,
  }
}));
