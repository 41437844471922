import React from 'react';

// UI
import { makeStyles } from '@material-ui/core/styles';

const TagsList = ({ tags }) => {
  const classes = useStyles();

  return (
    <div className={classes.tagsList}>
        {tags?.map(tag => (
            <div className={classes.tag}>{tag}</div>
        ))}
    </div>
  )
}

// Styles
const useStyles = makeStyles({
  tagsList: {
    display: 'flex',
    flexDirection: 'row'
  },
  tag: {
      padding: '5px 15px 5px 15px',
      marginRight: '10px',
      borderRadius: '12px',
      backgroundColor: 'lightgray',
      display: 'flex',
      justifyContent: 'center',
  },
});

export default TagsList;