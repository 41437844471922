import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.scss';
import { 
    Button, 
    Select, 
    MenuItem, 
    withStyles, 
    FormControl, 
} from '@material-ui/core';
import { default as PaginationMui } from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem';


const Pagination = ({ 
    handlePageChange, 
    total, current, perPage, 
    perPageOptions, handlePerPageChange, 
    showItems = true, showToggle = true, showText = true 
}) => {

    const numberOfPages = Math.ceil(total / perPage);
    const showNumber = perPage >= total ? total : perPage;
    const isAllResults = showNumber === total;

    const TogglePerPage = () => (<>
        <div className={styles.text}>Items per page:</div>
        <FormControl variant="outlined" classes={{ root: styles.selectWrapper }}>
            <Select classes={{ select: styles.select }} value={perPage} onChange={handlePerPageChange}>
                {perPageOptions.map((item, i) => (
                    typeof item === 'number' 
                        ? <MenuItem key={i} value={item} children={item}/>
                        : <MenuItem key={i} value={item.value} children={item.label}/>
                ))}
            </Select>
        </FormControl>
    </>)
    
    const ResultsText =  isAllResults ? `all` : `${showNumber} of ${total}`
    const PaginationText = `Showing ${ResultsText} results`;

    return (
    <div className={styles.root}>
        
        {showToggle && <TogglePerPage/> }

        {showText && <div className={styles.text}>{PaginationText}</div> }
        
        {!isAllResults && showItems && 
            <div className={styles.paginationList}> 
                <PaginationMui
                    page={current}
                    count={numberOfPages} 
                    renderItem={item => (
                        <PaginationItem
                            {...item}
                            component={CircleButton}
                            onClick={() => handlePageChange(item.page)}
                        />
                    )}
                />
            </div>
        }

    </div>
    )
}

Pagination.propTypes = {
    total: PropTypes.number, 
    perPage: PropTypes.number,
    current: PropTypes.number,
    showText: PropTypes.bool,
    showItems: PropTypes.bool,
    showToggle: PropTypes.bool,
    perPageOptions: PropTypes.array,
    handlePerPageChange: PropTypes.func,
    handlePageChange: PropTypes.func,
}

export default Pagination;

const CircleButton = withStyles({
    root: {
        width: '22px',
        height: '22px',
        padding: '0',
        minWidth: 'auto',
        borderRadius: '50%',
    },
    label: {
        marginTop: '2px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
        color: '#2A96FF',
    }
})(Button);