import React from "react";
import LabsTable from './LabsTable/LabsTable';
import Header from 'common/components/PanelHeader/PanelHeader';

const Labs = () => {

  const listBreadcrumbs = [{
    name: "Lab Results",
    url: "",
    disabled: true
  }];

  const listButtons = [{}];
 
  return (
    <React.Fragment>

      <div>
        <Header
          listBreadcrumbs={listBreadcrumbs}
          listButtons={listButtons}/>
      </div>

      <LabsTable/>

    </React.Fragment>
  );
}

export default Labs;