import moment from 'moment';
import _ from 'lodash';
import { mySessionTypes } from '../../../config/settings';

export const makeQueryParams = (
  currentDate,
  currentViewName,
  type,
  member = false
) => {
  // console.log('makeQueryParams', currentDate, currentViewName, type, member);
  const start = moment(currentDate).startOf(currentViewName.toLowerCase());
  const end = start.clone().endOf(currentViewName.toLowerCase());
  let filters = {
    startDate: start.format(),
    endDate: end.format(),
  };
  switch (type) {
    case 'onlyDate':
      if (member) filters.staffId = member;
      break;
    case 'patient':
      filters.status = ['SCHEDULED', 'CONFIRMED', 'MISSED'];
      if (member) filters.memberId = member;
      break;
    case 'staff':
      filters.status = ['SCHEDULED', 'CONFIRMED', 'MISSED'];
      filters.type = mySessionTypes;
      if (member) filters.staffId = member;
      break;
  }
  return {
    filters,
  };
};

export const formatDataToMonthView = data => {
  // filter appointments by date => [{key === day, value === the day's appointments}]
  let filteredAppointments = _.groupBy(data, e =>
    moment(e.scheduling.start).format('D')
  );

  // transform to simple array (replace keys to array's indexes )
  let transformedData = _.transform(
    filteredAppointments,
    (n, v, k) => n.push(v),
    []
  );

  // select first 3 appointments per day
  let slicedData = [];
  _.forEach(transformedData, e => slicedData.push(_.take(e, 3)));

  // flat sliced array to vector
  let flattenData = _.flatten(slicedData);

  return flattenData.map(mapAppointmentData);
};

export const mapAppointmentData = appointment => {
  return {
    id: appointment._id,
    startDate: appointment.scheduling.start,
    endDate: appointment.scheduling.end,
    scheduling: {
      start: appointment.scheduling.start,
      end: appointment.scheduling.end,
      duration: appointment.scheduling.duration,
      originalActivityId: appointment.scheduling.originalActivityId,
      isPrimaryActivity: appointment.scheduling.isPrimaryActivity,
      recurrenceText: appointment.scheduling.recurrenceText,
    },
    title: appointment.name,
    name: appointment.name,
    description: appointment.description,
    type: appointment.type,
    webexLink: appointment.webexLink,
    address: appointment?.address || [],
    status: appointment.status,
    duration: appointment.scheduling.duration,
    createdBy: appointment.createdBy,
    host: appointment.host,
    processType: appointment.processType,
    invitedMembers: appointment?.invitedMembers || [],
    participationInfo: appointment?.participationInfo || [],
    locationId: appointment.locationId,
    points: appointment.points,
    tags: appointment?.tags || [],
    questions: appointment?.worksheetQuestions || [],
    mandatory: appointment.mandatory,
    cohosts: appointment.cohosts ? appointment.cohosts : [],
  };
};

export const mapExternalEventsData = event => ({
  id: event._id,
  startDate: event.startDate,
  endDate: event.endDate,
  invitedMembers: event.invitedMembers || [],
  scheduling: {
    start: event.startDate,
    end: event.endDate,
  },
  title: event.name,
  type: event.type,
  name: event.name,
  host: event.host,
});

export const sortData = ({
  allData,
  type,
  patient,
  outlookCalendar,
  googleCalendar,
  myAvailability,
  isStaffModule,
}) => {
  let data =
    type && type !== '' && type !== '0'
      ? allData.filter(appointment => {
          let typeFilter =
            type === 'mySessions'
              ? mySessionTypes.includes(appointment.type)
              : appointment.type === type;
          return (
            typeFilter ||
            (outlookCalendar && appointment.type === 'outlook') ||
            (googleCalendar && appointment.type === 'google')
          );
        })
      : allData;
  data =
    patient &&
    !isStaffModule &&
    patient !== '' &&
    patient !== '0' &&
    !myAvailability
      ? data.filter(
          appointment =>
            appointment.invitedMembers &&
            appointment.invitedMembers.includes(patient)
        )
      : data;
  data = !outlookCalendar
    ? data.filter(appointment => appointment.type !== 'outlook')
    : data;

  return data;
};

export const getCurrentDate = () => moment().format();

/**
 * This function will filter out the events that we create in outlook from the short schedule view
 * @param {Array} data list of events
 * @returns {Array} list of unique events
 */
export const filterOutDuplicateEvents = data => {
  return data.filter(
    (item, pos, self) =>
      self.findIndex(v => {
        return (
          (v.name === item.name ||
            (v.name && v.name.startsWith(item.name)) || (item.name && item.name.startsWith(v.name))) &&
          v.host === item.host &&
          moment(v.scheduling?.start).isSame(item.scheduling?.start) &&
          moment(v.scheduling?.end).isSame(item.scheduling?.end)
        );
      }) === pos
  );
};
