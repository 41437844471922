import React, { useState, Fragment } from 'react';
import { Box, Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const hasSuicideRisk = alerts => {
  let isHasRisk = false;
  if (!alerts) return isHasRisk;

  alerts.forEach(item => {
    if (item?.toLowerCase().includes('suicid')) isHasRisk = true;
  });

  return isHasRisk;
};

const PatientAlerts = ({ data }) => {

  // remove falsy values from array;
  let alertsList = data?.filter(i => !!i);

  // * Hooks
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  // * Methods
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // * Helpers
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const totalAlerts = alertsList?.length || 0;
  // let totalAlerts;
  // data ? (totalAlerts = data.length) : (totalAlerts = 0);

  return (
    <Fragment>
      {totalAlerts !== 0 && (
        <Fragment>
          <Button
            classes={{ root: classes.button }}
            {...totalAlerts > 1 ? { onClick: handleClick } : {}}
            // onClick={totalAlerts > 1 ? handleClick : null}
          >
            <Box className={classes.container}>
              {hasSuicideRisk(alertsList) ? 'Suicide Risk' : alertsList?.[0]}{' '}
              {totalAlerts > 1 ? `(${totalAlerts})` : ''}
            </Box>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ margin: '0 25px' }}
          >
            {alertsList.map((alert, i) => (
              <div key={i} style={{ minWidth: 200, padding: 20 }}>
                {alert}
              </div>
            ))}
          </Popover>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PatientAlerts;

const useStyles = makeStyles({
  button: {
    padding: 0,
    marginTop: 5,
    borderRadius: 50,
    backgroundColor: '#ef4444',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#dc2626',
    },
  },
  container: {
    margin: 0,
    padding: '2px 10px',
    fontFamily: 'Avenir Next Regular',
    fontSize: 14,
  },
});