import {gql} from '@apollo/client';

const USER_FIELDS = `
  _id
  firstName
  lastName
  title
  image
  phone
  email
  webexLink
  termsAndConditionsAccepted
  termsAndConditionsAcceptedOn
`;

const ACCOUNT_FIELDS = `
  account {
    hasOutlookToken
    hasGoogleToken
    openingHours {
      monday {
        closed
        from
        to
      }
      tuesday {
        closed
        from
        to
      }
      wednesday {
        closed
        from
        to
      }
      thursday {
        closed
        from
        to
      }
      friday {
        closed
        from
        to
      }
      saturday {
        closed
        from
        to
      }
      sunday {
        closed
        from
        to
      }
    }
    userGuides {
      code
      status
      lastActiveStep
    }
  }
`;

const PERMISSIONS_FIELDS = `
  permissions {
    moduleActions {
      code
      actions
    }
    patientIds
    staffIds
    UI
    code
  }
`;

const CASELOAD_FIELDS = `
  careTeam {
    _id
    type
    firstName
    lastName
    image
    emrPic
    lastActive
  }
`

export const GET_ACCOUNT_USER = gql`
  query getAccountUser {
    meStaff {
      ${USER_FIELDS}
      ${ACCOUNT_FIELDS}
      ${PERMISSIONS_FIELDS}
      ${CASELOAD_FIELDS}
    }
  }
`

// @deprecare when added to meStaff
export const PERMISSION_FIELDS = `
  user {
    _id
    permissions {
      moduleActions {
        code
        actions
      }
      patientIds
      staffIds
      UI
      code
    }
  }
`;

export const GET_ACCOUNT_PERMISSIONS = gql`
  query getAccountPermissions {
    getDashboardData {
       ${PERMISSION_FIELDS}
    }
  }
`