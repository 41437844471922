import React, { useState, Fragment } from 'react';

// UI
import {
  Grid, Box, 
  Typography, Dialog, IconButton,
  DialogTitle, DialogContent, TextField,
  Button, Checkbox, InputLabel, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './LabsEntry.module.scss';

// Utils
import moment from 'moment';
import {controlDialogClose} from 'common/helpers/material';

// Data
import { addLabRequest } from 'redux/actions/udsLabs';
import { useMutation } from '@apollo/client';
import { useDispatch } from "react-redux";
import { ADD_LAB_REQUEST, GET_LABS } from 'data/graphql';

// Forms
import { useSnackbar } from 'notistack';
import { Formik, Field, Form } from 'formik';
import { FormDevTools } from "common/components/Formik/FormDevTools";
import FormikTextField from 'common/components/Formik/Fields/TextField';
import FormikAutocomplete from 'common/components/Formik/Fields/Autocomplete';
import Datepicker from 'common/components/InputFields/KeyboardDatepicker/Datepicker';
import { LabRequestSchema } from './schema';

// Components
import DropZone from 'common/components/Dropzone/DropZone';

// Context
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';

const ButtonLarge = withStyles({
  root: {
    borderRadius: '24px',
    padding: '6px 20px',
  },
  label: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
  }
})(Button);

const RegisterNewLab = ({
  handleClose,
  open
}) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Context Data
  const {user, organization} = useAccountContext();
  const {patients = []} = useMembersContext();

  // State
  const [attachedFiles, setAttachedFiles] = useState([]);
  const panelList = organization?.labPanels || [];

  // Cleanup for Autocomplete 
  const patientListAC = patients
    //.filter(p => p.status === 'ACTIVATED')
    .map(patient => (
      { 
        "_id": patient._id,
        "name": `${patient.firstName} ${patient.lastName}` 
      }
    ))
  const panelListAC = panelList?.map(panel => ({ "_id": panel._id,  "name": panel.name }))

  // Mutations
  const [insertNewQuestRequest, addLabQuery] = useMutation(ADD_LAB_REQUEST, {
    refetchQueries: [{ query: GET_LABS }],
    onCompleted: result => {
      console.log('[Labs] onCompleted result: ', result);
      enqueueSnackbar('Lab Request Entered', { variant: 'success' });
      dispatch(addLabRequest(result.insertNewQuestRequest?.newLab?._id));
      handleClose();
    },
    onError: e => {
      enqueueSnackbar(e && e.message
        ? e.message
        : 'Error: Lab Request not saved.', { variant: 'error' });
    }
  });

  const isLoading = addLabQuery.loading;

  const handleSaveLab = async (data) => {

    const labRequestEntry = {
      lab: {
        patientId: data.patientId?._id,
        panelId: data.panelId?._id,
        specialNote: data.specialNote,
        location: data.location,
        withETG: data.withETG,
        authorizationNumber: data.authorizationNumber.trim(),
        dueDate: data.dueDate,
        documents: attachedFiles
      }
    };

    console.log('[Lab] Lab Entry: ', labRequestEntry)
    
    await insertNewQuestRequest({ variables: labRequestEntry });
  };

  const handleFilesChange = files => {
    console.log('[Labs] attachedFiles: ', files)
    setAttachedFiles(files);
  };

  const handleClearState = () => {
    console.log('[Labs] Clearing state: attachedFiles ', attachedFiles)
    setAttachedFiles([]);
  }

  // Render
  const dialogProps = {
    open: open,
    maxWidth: 'sm',
    onClose: (_, reason) => controlDialogClose(reason, handleClose),
    onExit: handleClearState,
    className: styles.popup,
    "aria-labelledby": "customized-dialog-title",
  }

  const initialValues = {
    patientId: '',
    panelId: panelListAC[0], // Full Panel: 5c9cba56a560242975a272e9 -- TODO: Push this to config.js
    withETG: true,
    specialNote: '',
    location: '',
    authorizationNumber: '',
    dueDate: moment().format("MM/DD/YYYY"),
  }

  const formikProps = {
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: handleSaveLab,
    validationSchema: LabRequestSchema,
  }

  const textareaProps = {
    rows: 3,
    as: TextField,
    multiline: true,
    fullWidth: true,
    variant: "outlined",
  }

  const btnSubmitProps = {
    type: "submit",
    color: "primary",
    variant: "contained",
    disabled: isLoading,
    style: { width: '230px' },
  }

  const dropZoneProps = {
    limitCount: 1,
    maxSize: 20 * 1024 * 1024 // 20mb
  }

  const RenderRow = ({ title, children }) => ( 
      <Fragment>
        <Grid item xs={4}>{title}</Grid>
        <Grid item xs={8}>{children}</Grid>
      </Fragment>
  );
          
  return (
    <Dialog {...dialogProps}>

      <DialogTitle id="customized-dialog-title" className={styles.title}>
        <Typography component="span">Register Lab Request</Typography>
        <IconButton aria-label="close"  style={{position: 'absolute', top: '8px', right: '8px'}} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className={styles.content}>

        <Formik {...formikProps}>
          {({ values, errors, touched, isValidating }) => (
            <Form autoComplete={"off"}>
              <Grid container spacing={3}>

                <RenderRow title="Authorization #">
                  <Field
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Auth Number."
                    name="authorizationNumber"
                    component={FormikTextField}
                   />
                </RenderRow>

                <RenderRow title="Patient">
                  <Field
                    name="patientId"
                    value={values.patientId}
                    valueKey="_id"
                    labelKey={o => o.name || ''}
                    suggestions={patientListAC}
                    component={FormikAutocomplete}
                    label={"Choose patient"}
                  />
                </RenderRow>

                <RenderRow title="Expiration Date:" children={
                  <Field
                    name="dueDate"
                    placeholder="Choose a date"
                    disablePast={true}
                    component={Datepicker} />
                } />

                <RenderRow title="Drug Panel:">
                  <Field
                    name="panelId"
                    value={values.panelId}
                    valueKey="_id"
                    labelKey={o => o.name || ''}
                    suggestions={panelListAC}
                    component={FormikAutocomplete}
                    label={"Choose drug panel"}
                     />
                </RenderRow>

                <RenderRow title={null}>
                  <InputLabel style={{ color: 'rgba(0,0,0,0.8)' }}>
                    <Field
                      name="withETG"
                      checked={values.withETG}
                      variant="outlined"
                      as={Checkbox} />
                    Add EtG
                  </InputLabel>
                </RenderRow>

                <RenderRow title="Location(s):">
                  <Field {...textareaProps}
                    name="location"
                    placeholder="Enter locations." />
                </RenderRow>

                <Grid item xs={4}>Authorization Form:</Grid>
                <Grid item xs={8}>
                  <DropZone 
                    {...dropZoneProps}
                    title="Attach Forms Here"
                    accept="application/pdf,image/png,image/jpeg,text/plain"
                    onChange={handleFilesChange} 
                    />
                </Grid>

                <RenderRow title="Special Note:">
                  <Field {...textareaProps}
                    name="specialNote"
                    variant="outlined" />
                </RenderRow>

                <Grid item xs={12} />

                <RenderRow title="Signed By:">
                  <span className={styles.signature}>
                    {`${user?.title ? user?.title : ""} ${user?.firstName} ${user?.lastName}`}
                  </span>
                </RenderRow>

                <RenderRow title={null} children={moment().format('ll')} />

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" className={styles.formFooter}>
                    <ButtonLarge variant="text" onClick={handleClose}>Cancel</ButtonLarge>

                    <ButtonLarge {...btnSubmitProps}>
                      {isLoading ? 'Saving...' : 'Submit Request'}
                    </ButtonLarge>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <FormDevTools values={values} errors={errors} />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>

    </Dialog>
  );
};


export default RegisterNewLab;