import {gql} from '@apollo/client';
export const GET_GOOGLE_CALENDAR_URL = gql`
  query getGoogleAuthURL{
    getGoogleAuthURL
  }
`;
export const SAVE_GOOGLE_TOKEN = gql`
mutation saveGoogleCToken(
    $code: String!
  ) {
    saveGoogleCToken(
      code: $code
    ){
        hasGoogleToken
    }
  }
  `;

export const DISCONNECT_GOOGLE_TOKEN = gql`
  mutation disconnectGoogleCToken {
    disconnectGoogleCToken{
          hasGoogleToken
      }
  }
  `;

export const GET_GOOGLE_EVENTS = gql`
  query getGoogleEvents($filters:CalendarFilters) {
    getGoogleEvents(filters: $filters){
        _id  
        name
        startDate
        endDate
        type
        host
    }
  }
`;