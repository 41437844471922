import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Box } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import styles from '../../StaffItemEdit.module.scss';
import FormSkeleton from 'common/components/Formik/FormSkeleton/FormSkeleton';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';
import { emailValidator, trimObjectStrings, phoneValidator } from 'common/helpers/validations';
import { EDIT_STAFF, GET_FULL_STAFF_INFO } from 'data/graphql';

const Profile = ({ user, avatarFile, refetch }) => {
  const loading = !user;

  const { enqueueSnackbar } = useSnackbar();

  const [editStaff, { loading: editLoading }] = useMutation(EDIT_STAFF, {
    refetchQueries: [{ query: GET_FULL_STAFF_INFO, variables: { memberId: user._id } }],
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Account updated', { variant: 'success' });
    }
  });

  const onFormSubmit = values => {
    let updatedStaff = { ...values };
    delete updatedStaff.email;
    updatedStaff = trimObjectStrings(updatedStaff);

    if (avatarFile) {
      updatedStaff.imageContent = avatarFile;
    }

    editStaff({
      variables: {
        staffId: user?._id,
        staff: updatedStaff
      }
    });
  }

  const fieldsGrid = [
    [{
      name: 'title',
      title: 'Title',
      value: user?.title || '',
      grid: { xs: 12 }
    }],

    [{
      name: 'firstName',
      title: 'First Name',
      value: user?.firstName || '',
      grid: { xs: 12, sm: 6 }
    }, {
      name: 'lastName',
      title: 'Last Name',
      value: user?.lastName || '',
      grid: { xs: 12, sm: 6 }
    }],

    [{
      name: 'username',
      title: 'Username',
      value: user?.username || '',
      grid: { xs: 12, sm: 6 }
    }, {
      type: 'radio',
      name: 'gender',
      title: 'Sex',
      value: user?.gender || 'f',
      options: [
        { value: 'f', label: 'Female' },
        { value: 'm', label: 'Male' },
      ],
      grid: { xs: 12, sm: 6 }
    }],

    [{
      type: 'email',
      name: 'email',
      title: 'Email',
      readOnly: true,
      value: user?.email || '',
      grid: { xs: 12 }
    }],

    [{
      name: 'phone',
      type: "phoneinput",
      title: 'Phone Number',
      value: user?.phone || '',
      grid: { xs: 12 }
    }],

    [{
      type: 'date',
      name: 'birthDate',
      title: 'Birthdate',
      openTo: "year",
      views:["year", "month", "date"],
      format:"YYYY-MM-DD",
      value: user?.birthDate || null,
      grid: { xs: 12, sm: 6 }
    }],

  ];

  const validationSchema = Yup.object().shape({
    email: emailValidator('email', 'Email is not valid'),
    firstName: Yup.string().trim().required('First Name can\'t be empty'),
    lastName: Yup.string().trim().required('Last Name can\'t be empty'),
    phone: Yup.string()
      .test('phone', 'Invalid phone number', val => phoneValidator(val)),
  });

  const formikProps = {
    onSubmit: onFormSubmit,
    initialValues: toInitialValues(fieldsGrid),
    validationSchema: validationSchema,
  }

  return <React.Fragment>

    <p className={styles.formTitle}>Personal Details</p>

    {loading ? <FormSkeleton /> : (

      <Formik {...formikProps}>
        {({ values, handleChange, setFieldValue, errors }) => {
          const hasErrors = !!(errors && Object.keys(errors).length);
          return <Form>

            {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

            <FormBuilder
              values={values}
              errors={errors}
              data={fieldsGrid}
              handleChange={handleChange}
              setFieldValue={setFieldValue} />

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={editLoading || hasErrors}
                children={editLoading ? 'Loading...' : 'Update'} />
            </Box>

            {/* <pre>values: {JSON.stringify(values, null, 2)}</pre>
          <pre>errors: {JSON.stringify(errors, null, 2)}</pre> */}

          </Form>
        }}
      </Formik>

    )}

  </React.Fragment>
}

Profile.propTypes = {

}

export default Profile;
