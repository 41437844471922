import { Messages } from 'config/settings';

/**
 * Patient Member Activation Warning Messages 
 * @param {Object} data Staff member data object 
 * @returns {Array} warningDetails with warning data 
 */
export const activationWarnings = (data) => {
    console.log("STAFF activation warnings", data)
    const warningMessage = {
        email: {
            item: Messages.STAFF_ACTIVATION_WARNING_EMAIL,
            help: Messages.STAFF_ACTIVATION_WARNING_EMAIL_HELP,
            route: `/staff/${data?._id}/edit/profile`,
        },
        roles: {
            item: Messages.STAFF_ACTIVATION_WARNING_ROLES,
            help: Messages.STAFF_ACTIVATION_WARNING_ROLES_HELP,
            route: `/staff/${data?._id}/edit/caron`,
        }
    }

    const warningDetails = [
        ...(!!!data?.email) ? [warningMessage.email] : [],
        ...(data?.roles_new?.length < 1) ? [warningMessage.roles] : [],
    ];
    return warningDetails;

}
