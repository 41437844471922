import React from 'react';
import ReactQuill from 'react-quill';
import styles from "./index.module.scss";

const DisplayHTML = (({text, style = {}}) => 
    <ReactQuill
      readOnly
      value={text || ''}
      theme={null}
      className={styles.noteQuill}
      style={style}
    />
  );

export default DisplayHTML;