import React, { Fragment } from 'react';
// import LabItem from '../LabItem/LabItem';
import { List, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import dayjs from 'dayjs';

const LabList = ({ labs, limit }) => {
  if (limit) labs.length = limit;

  return (
    <div style={{overflowX: 'hidden', overFlowY: 'scroll', maxHeight: '50vh', minHeight: 300 }}>
      <List>
        {labs.map(item => (
          <LabItem lab={item} key={item._id} />
        ))}
      </List>
    </div>
  );
};

export default LabList;


const LabItem = ({ lab }) => {
  const styles = useStyle();
  console.log('[Lab] lab', lab)
  return (
    <div className={styles.wrapper}>
      
      <div className={styles.indicator} />

      <div className={styles.memberInfo}>
        <div className={styles.memberName}>
          {`${lab?.patient.firstName} ${lab?.patient.lastName}`}
        </div>
        <div className={styles.result}>
          {lab?.result === 'POSITIVE' ? 'Positive' : lab?.result}
        </div>
        <div>
          {lab?.panel.name} {lab?.withETG ? '+ ETG' : null}
        </div>
        <div>{dayjs(lab?.dueDate).format('MMM D, YYYY')}</div>
      </div>

      <div className={styles.results}>
        
        {lab?.detailedResults?.filter(v => v.value > 0)?.map(item => {
          return <div className={styles.detailedResults}>
            <div>
              <div className={styles.value}>
                {item?.value} <span style={{fontSize: 14}}>ng/mL</span>
              </div>
              <div>
                {item?.substance}
              </div>
            </div>
          </div>
        })}
        
        <Link to={`/patients/${lab?.patient._id}/labs`} style={{color: '#2A96FF'}}>
          View Report
        </Link>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    fontSize: 14,
    borderRadius: 10,
    border: '1px solid #e0e0e0',
  },
  indicator: {
    backgroundColor: '#FF103B33',
    width: 8,
    margin: 5,
    borderRadius: 10,
  },
  memberInfo: {
    flex: 3,
    padding: 10,
  },
  memberName: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  results: {
    flex: 2,
    padding: 10,
  },
  detailedResults: {
    marginBottom: 10,
  },
  value: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FF103B',
  }
})