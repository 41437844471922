import React from 'react';
import Item from './Item';
import PropTypes from 'prop-types';

const CollabseNotification = ({ notifications = [], ...restProps }) => {
  return notifications.map((notification, index) => (
    <Item key={index} notification={notification}/>
  ));
};

CollabseNotification.propTypes = {
    notifications: PropTypes.array.isRequired
}

export default CollabseNotification;
