import React, { useState, Fragment } from 'react';
import moment from 'moment';

// Material
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AddIcon from '@material-ui/icons/Add';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectMember } from 'actions/appointmentBooker';
import { toggleVisibility, setAddedAppointment } from 'actions/scheduler';

// Components
import AppointmentBooker from 'components/Scheduler/Booker/';
import AppointmentFormPopup from 'components/Scheduler/components/AppointmentForm/AppointmentFormPopup';

// Context
import {useScheduleModuleContext} from 'context/Activities/useActivitiesContext'
import {useMemberStateContext} from 'context/Member/useMemberContext';

const ActivitiesHeader = () => {

  const {memberId, isStaff} = useMemberStateContext();
  const {bookAppointmentOpen, setBookAppointmentOpen} = useScheduleModuleContext();
    
  //State - Temporary, move to DialogContainer later
  const [open, setOpen] = useState(false);
  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const scheduler = useSelector(state => state.scheduler);

  // console.log('SCHEDULER>>>>!>!>!>!!> ', scheduler);

  const onAddActivity = () => {
    console.log('[Add Activity] Open Dialog');
    handleDialogOpen();
    dispatch(
      setAddedAppointment({
        startDate: moment()
          .add(1, 'hour')
          .set({ minute: 0 })
          .format(),
      })
    );
  };

  const onAddActivityClose = () => {
    console.log('[Add Activity] Close Dialog');
    handleDialogClose();
  };

  const onScheduleCall = () => {
    setBookAppointmentOpen(true)
    dispatch(toggleVisibility('reschedulingVisible', true));
    dispatch(selectMember(memberId));
  };

  const onBookerClose = () => {
    setBookAppointmentOpen(false)
    dispatch(toggleVisibility('reschedulingVisible', false));
  };

  const scheduleCallProps = {
    color: "primary",
    variant: "contained",
    onClick: onScheduleCall,
    startIcon: <EventAvailableIcon />,
    className: classes.button,
  }

  const addActivityProps = {
    color: "primary",
    variant: "text",
    onClick: onAddActivity,
    startIcon: <AddIcon />,
    className: classes.button,
  }

  const bookerProps = {
    selectedMember: memberId,
    onClose: onBookerClose,
    reschedulingVisible: scheduler.reschedulingVisible,
  };

  const activityBookerProps = {
    selectedMember: memberId,
    open: open,
    handleClose: onAddActivityClose,
  };

  const showCallButton = !isStaff;
  const showAddActivity = !isStaff;

  // const isBookerOpen = bookAppointmentOpen || scheduler.reschedulingVisible;

  return (
    <div className={classes.root}>

      {showCallButton ? (
        <Fragment>
          <Button {...scheduleCallProps} children="Schedule a Call" />
          {bookAppointmentOpen ? <AppointmentBooker {...bookerProps}/> : null}
        </Fragment>
      ) : null}

      {showAddActivity ? (
        <Fragment>
          <Button {...addActivityProps} children="Add Activity" />
          {open ? <AppointmentFormPopup {...activityBookerProps}  /> : null}
        </Fragment>
      ) : null}
    </div>
  );
};

export default ActivitiesHeader;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 25,
    flex: 1,
  },
  addBtn: {
    marginTop: 12,
    fill: '#2A96FF',
  },
  button: {
    margin: '5px 0',
  }
}));