import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'

// Styles
import { makeStyles } from '@material-ui/core/styles'

//Components
import Button from '../../../common/components/Forms/Button'
import { Box, Input, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ActivitiesTable from 'screens/Activities/Templates/TemplatesList'
import ActivitiesTableSkeleton from '../../../common/components/LoadingSkeleton/LoadingSkeleton'

//Helpers
import { groupByFiltered } from 'common/helpers/dataHelpers'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'

const processCategoryList = (templatesList, searchValue) => {
  if (!templatesList) return
  const grouped = groupByFiltered(templatesList, 'category', ['name', 'code', '_id'])

  const processed = Object.keys(grouped).map((item, index) => {
    let templates = grouped[item].sort((a, b) => a.name.localeCompare(b.name))
    if (searchValue?.length)
      templates = templates.map((item) => {
        return { ...item, highlight: item.name.toLowerCase().includes(searchValue.toLowerCase()) }
      })
    return {
      category: item,
      templates: templates,
    }
  })

  return processed
}

const CategoryListView = ({ next }) => {
  const { templatesList, getActivityTemplates, loading, reset } = useActivitiesContext()

  useEffect(() => {
    getActivityTemplates()
  }, [getActivityTemplates])

  useEffect(() => {
    setProcessedList(processCategoryList(templatesList))
  }, [templatesList])

  const [processedList, setProcessedList] = useState(processCategoryList(templatesList))

  const classes = useStyles()

  const handleBackToManual = () => {
    reset()
    next()
  }
  const handleNext = () => next()

  const handleSearch = debounce((value) => {
    setProcessedList(processCategoryList(templatesList, value))
  }, 300)

  return (
    <div className={classes.wrapper}>
      <div className={classes.filterBar}>
        <div className={classes.filterTitle}>Choose A Template</div>
        <Input
          type="text"
          disableUnderline
          className={classes.searchInput}
          placeholder={'Search'}
          onChange={(e) => handleSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon color="action" />
            </InputAdornment>
          }
        />
      </div>
      <div className={classes.body}>
        {loading ? (
          <ActivitiesTableSkeleton />
        ) : (
          <ActivitiesTable data={processedList} categoryLimit={4} templateLimit={5} />
        )}
        <Box className={classes.buttonsBox}>
          <Button type="submit" variant="contained" className={classes.cancelBtn} onClick={handleBackToManual}>
            Start from scratch
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
            Next, Setup Activity
          </Button>
        </Box>
      </div>
    </div>
  )
}

// Styles
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 30px 15px 30px',
    borderBottom: 'solid 1px lightgray',
    borderTop: 'solid 1px lightgray',
    backgroundColor: '#FBFBFB',
  },
  filterTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    // border: 'solid 1px red',
  },
  searchInput: {
    width: '100px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    // border: 'solid 1px red',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  buttonsBox: {
    // border: 'solid 1px red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  cancelBtn: {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: 'gray',
  },
})

export default CategoryListView
