import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const Toggle = ({ control, items, name }) => {
  const styles = useStyles();
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  return (
    <ToggleButtonGroup
      {...inputProps}
      inputRef={ref}
      onChange={(e, i) => onChange(i)}
      aria-label="device"
      className={styles.toggleButtonGroup}
      error={invalid}
      helperText={error?.message}
      data-testid="toggle-group"
    >
      {items &&
        items.map(({ value, label }) => (
          <ToggleButton
            color="primary"
            value={value}
            aria-label={label}
            className={styles.toggleButton}
            key={value}
            role="button"
          >
            {label}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles({
  toggleButtonGroup: {},
  toggleButton: {
    borderRadius: '50% !important',
    padding: '1px 7px',
    margin: '5px',
    border: '1px solid !important',
    width: '28px',
    lineHeight: '25px',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#2A96FF',
    },
  },
});

export default Toggle;
