import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Material
import { Button } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// Dialog Components
import NotificationDialog from 'common/components/Notification/NotificationDialog/NotificationDialog';

// Utils
import { activationWarnings } from './helpers';
import { useMemberStateContext } from 'context/Member/useMemberContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 25,
    flex: 1,
  },
  button: {
    margin: '5px 0',
  }
}));

const MessagingHeader = () => {

  const classes = useStyles();

  // State
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  // Context
  const {memberId, isStaff, deviceTokens} = useMemberStateContext();
  const showNotification = deviceTokens && !isStaff;

  const chatMessageProps = {
    color: "primary",
    variant: "contained",
    startIcon: <MessageIcon />,
    className: classes.button,
  }

  const notificationProps = {
    color: "primary",
    variant: "text",
    onClick: handleClickOpen,
    startIcon: <NotificationsNoneIcon />,
    className: classes.button,
  }

  const dialogProps = {
    open,
    handleClose,
    patientId: memberId,
    warningDetails: activationWarnings({ memberId, deviceTokens })
  }

  return (
    <div className={classes.root}>
      <Button {...chatMessageProps} children="Send Chat Message" /> 

      {showNotification && 
        <>
          <Button {...notificationProps} children="Push Notification" />
          <NotificationDialog {...dialogProps} />
        </>
      }
      
    </div>    
  )
}

export default MessagingHeader;