import React, { useState } from 'react'
import moment from 'moment'

// Components
import { ResponsiveLine } from '@nivo/line';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { radius, shadows } from 'styles/variables';

const responsiveLineProps = {
  margin: { top: 0, right: 20, bottom: 20, left: 20 },
  xScale: { type: 'point' },
  yScale: {
    type: 'linear',
    min: 0,
    max: 100,
    reverse: false
  },
  axisLeft: null,
  axisBottom: { 
    tickValues: "every month",
    tickSize: 6,
    tickPadding: 0,
    format: d => `${moment(d).format(`MMM 'YY`)}`,
  },
  pointSize: 8,
  pointColor: 'white',
  pointBorderWidth: 2,
  pointBorderColor: { from: 'serieColor' },
  enableSlices: 'x',
  enableArea: true,
  useMesh: true, 
  curve: 'monotoneX',
  colors: {"scheme": "category10"},
}

const NoteInsights = ({data, selectors, hasNotes, displayMin = 2}) => {
  
  const classes = useStyles();
  const [dataset, setDataset] = useState(selectors[0]);
  //console.log('[Note Insights] props: hasNotes', hasNotes, 'data:', data, 'dataset: ', dataset)
  
  if (!hasNotes || data?.length < displayMin) return null;

  return (
    <div className={classes.insightsBox}>
      <div className={classes.insightSelector}>
        <div className={classes.selectorSub}>Note Insights</div>
        <div className={classes.selectorTitle}>{dataset.title}</div>
      </div>
      <div className={classes.insightChart}>
        <ResponsiveLine 
          data={[{id: "Engagement", data}]}
          {...responsiveLineProps}
        />
      </div>
    </div>
  )
}

export default NoteInsights;

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  insightsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
    minHeight: 75,
    margin: '20px 30px 0 30px',
    borderRadius: radius.normal,
    boxShadow: shadows.google,
    backgroundColor: '#fff',
  },
  insightSelector: {
    width: 170,
  },
  selectorTitle: {
    fontWeight: 600,
  },
  selectorSub: {
    marginBottom: 5,
    fontSize: 12, 
  },
  insightChart: {
    flex: 1,
    height: 120,
  }
});