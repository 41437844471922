import React from 'react';
import moment from 'moment';

// UI
import cn from 'classnames';
import styles from './CallItem.module.scss';
import { Box, Grid, ListItem } from '@material-ui/core';

// Redux
import { useSelector } from 'react-redux';
import {getActivityMembers} from "selectors"

//
import StatusAction from './StatusAction';
import AvatarGroup from 'common/components/Member/AvatarGroup/';

// Utils
import { truncateString } from 'common/helpers/dataHelpers';
import dayjs from 'dayjs';

const CallItem = ({ 
  call, 
  index, 
  // hosts, 
  // mergedMembers, 
  // hasInvitedMembers, 
  // hasMultiHosts,
 }) => {

  const currentTime = dayjs().format();
  const endCall = dayjs(call.scheduling?.end).format();
  const startCall = dayjs(call.scheduling?.start).format('h:mm a');

  const listItemProps = {
    className: cn(styles.listItem, {
      [styles.completed]: currentTime > endCall || (call.status === 'ENDED'),
    }),
  }

  // const invitedMembers = call?.invitedMembers || [];
  // const hosts = call?.hosts || [];
  // console.log('CallItem', invitedMembers, hosts)
  // const attendees = useSelector(state => getActivityMembers(state, call));
  // const members = attendees.map(m => { 
  //   return {
  //     _id: m._id,
  //     type: m.type,
  //     image: m.image,
  //     lastName: m.lastName,
  //     firstName: m.firstName,
  //   }
  // });

  const listContentProps = {
    alignItems: "center",
    className: cn(styles.listItemContent, {
      [styles.darkenBackground]: index % 2 !== 1
    }),
  }

  return (
    <ListItem {...listItemProps}>
    
      <Grid container spacing={0} {...listContentProps}>
        <Grid item xs={12} md={3}>{startCall}</Grid>
        {/* <Grid item xs={2} sm={2}><AvatarGroup members={members} size='small' disabled /></Grid> */}
        <Grid item xs={8} md={9}>{truncateString(call.name, 40)}</Grid>
        {/* <Grid item xs={4} md={2}><StatusAction {...call} /></Grid> */}
      </Grid>
      
    </ListItem>
  );
};

export default CallItem;
