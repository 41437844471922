import React from 'react';
import Box from '@material-ui/core/Box';
import styles from './Urgent.module.scss';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import RedIcon from '../../../assets/status/red.svg';
import ReactSVG from 'react-svg';
import classnames from 'classnames';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import {
  CLEAR_URGENT_ISSUE,
  GET_NOTIFICATIONS,
} from '../../../../data/graphql/notifications';
import { readUrgentNotification } from 'actions/notifications';
import _ from 'lodash';

const Item = ({ notification, ...restProps }) => {
  const { enqueueSnackbar } = useSnackbar();
  const urgent = useSelector(state => state.notifications.urgent);
  const dispatch = useDispatch();

  const [clearNotification] = useMutation(CLEAR_URGENT_ISSUE, {
    variables: {
      all: false,
      notificationId: notification.notificationId,
      itemId: notification._id,
    },

    onCompleted: () => {
      // console.log('notification cleared');
      enqueueSnackbar('Your notification has been cleared', {
        variant: 'success',
      });
      // setPlayNotification(Sound.status.STOPPED)
      const notificationToRemove = _.find(urgent, { _id: notification._id });

      dispatch(readUrgentNotification(notificationToRemove._id));
    },
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
  });

  const { _id: memberId, image, firstName, lastName } = notification.member;
  const avatarProps = {
    _id: memberId,
    image,
    firstName,
    lastName,
    disabled: true,
    size: 'small',
  };

  return (
    <Box
      component="li"
      className={classnames(styles.item, restProps.className)}
      {...restProps}
    >
      <ReactSVG src={RedIcon} className={styles.icon} wrapper="span" />
      <MemberAvatar {...avatarProps} className={styles.avatar} />
      {notification.sourceCollection === 'Labs' ? (
        <Link
          to={`${notification.parsedLink}/labs`}
          className={styles.link_text}
        >
          {notification.parsedContent}
        </Link>
      ) : (
        <Typography variant="body1" className={styles.text}>
          {notification.parsedContent}
        </Typography>
      )}
      <Button onClick={clearNotification} className={styles.button_read_all}>
        dismiss
      </Button>
    </Box>
  );
};

export default Item;
