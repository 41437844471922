import { handleActions } from 'redux-actions';
import { 
    addLabRequest, 
    editLabStatus, 
    editLabResults,  
    addLabDetails,
} from 'redux/actions/udsLabs';

const defaultState = { 
    addedRequestsIds: [],
    addedStatusIds: [],
    addedResultsIds: [],
    addedDetailsIds: []
};

export default handleActions({
    
    [addLabRequest]: (state, { payload }) => ({...state,
        addedRequestsIds: [...state.addedRequestsIds, payload]
    }),

    [editLabStatus]: (state, { payload }) => ({...state,
        addedStatusIds: [...state.addedStatusIds, payload]
    }),

    [editLabResults]: (state, { payload }) => ({...state,
        addedResultsIds: [...state.addedResultsIds, payload]
    }),

    [addLabDetails]: (state, { payload }) => ({...state,
        addedDetailsIds: [...state.addedDetailsIds, payload]
    }),

},
defaultState);
