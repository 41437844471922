import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const materialTheme = createTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        borderRadius: '10px',
        marginBottom: '15px',         
      },
    },
    MuiPickersBasePicker: {
      container: { 
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        borderRadius: '10px',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        order: -1,
        fontFamily: '"Avenir Demi Bold"',
        textAlign: 'left'
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#2A96FF',
      },
    },
  },
});



const Datepicker = ({ currentDate, currentDateChange, className, ...restProps }) => {

  const datePickerProps = {
    autoOk: true,
    openTo: "date",
    variant: "static",
    className: className,
    orientation: "landspace",
    onChange: currentDateChange,
    value: moment(currentDate).format()
  }
  
  return (
  <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker 
        {...datePickerProps}
        ToolbarComponent={() => <></>}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
  )
};

export default Datepicker;
