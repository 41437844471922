import {gql} from '@apollo/client';
export const GET_STAFF_REPORT = gql`
  query getStaffStatusReport($filters: AnalyticsFilters) {
    getStaffStatusReport(filters: $filters) {
      _id
      firstName
      lastName
      department{
        name
      }
      status
      lastActive
      totalCaseLoad
      activatedCaseLoad
      totalCalls
      upcomingCalls
      totalNotes
      totalLabs
    }
  }
`;
