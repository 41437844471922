import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { EditOutlined, CloseOutlined, DoneAllOutlined, DeleteOutline } from '@material-ui/icons';
import { shadows } from 'styles/variables';

// Components
import MemberAvatar from "common/components/Member/Avatar/MemberAvatar";
import FamilyEditField from './FamilyEditField';

// Data
import {familyRelationTypes} from '../utils';
import useAccountContext from 'context/Account/useAccountContext';


const FamilyMemberOption = ({
  _id, 
  firstName,
  lastName,
  image,
  type,
  relation,
  releaseStatus,
  handleUpdate,
  handleRemove,
  editing,
  isProcessing,
}) => {
  
  // Hooks
  const {organization} = useAccountContext();

  // State
  const [anchorEl, setAnchorEl] = useState(false);
  const [relationValue, setRelationValue] = useState(relation);
  const [releaseValue, setReleaseValue] = useState(releaseStatus);
  const [isEditing, setIsEditing] = useState(editing);

  const releasesTypes = organization?.familyRelease || [];
  const releaseOptions = [...releasesTypes, {_id: "none", name: "None"}];

  const memberData = {
    _id,
    firstName,
    lastName,
    image,
    type,
  }

  const hasRelationChanged = relation !== relationValue;
  const hasReleaseChanged = releaseStatus !== releaseValue;
  const hasChanges = hasRelationChanged || hasReleaseChanged;
  
  // Handlers
  const handleEditRelation = () => setIsEditing(true);

  const handleUpdateRelation = async () => {
    setIsEditing(false)
    if(hasChanges) handleUpdate(_id, relationValue, releaseValue)
  };

  return (
    <Grid container alignItems="center" spacing={2} style={{minHeight: 80, borderRadius: 10, boxShadow: shadows.light, margin: '15px 0'}}>
      <Grid item xs={4}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10}}><MemberAvatar size="small" disabled {...memberData} /> <span style={{marginLeft: 10}}>{firstName} {lastName}</span></div>
      </Grid>
      <Grid item xs={3}>
        {isEditing 
          ? <FamilyEditField options={familyRelationTypes} idKey="_id" valueKey="name" defaultValue={relationValue} handleUpdate={setRelationValue} />
          : <div style={{textAlign: 'center', fontSize: '1em', color: isProcessing ? '#ccc' : '#000', backgroundColor: '#e2e8f0', padding: '6px 10px', width: 50, borderRadius: 50, width: 75}}>{relationValue}</div>
        }
      </Grid>
      <Grid item xs={3}>
        {isEditing 
          ? <FamilyEditField options={releaseOptions} idKey="_id" valueKey="name" defaultValue={releaseValue} handleUpdate={setReleaseValue} />
          : <div style={{color: isProcessing ? '#ccc' : '#000'}}>{releaseValue}</div>
        }
      </Grid>
      <Grid item xs={2}>
        {isEditing 
          ? <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button startIcon={hasChanges ? <DoneAllOutlined /> : <CloseOutlined />} onClick={handleUpdateRelation}>{hasChanges ? "Update" : "Cancel"}</Button>
            </div>
          : <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button startIcon={<EditOutlined />} disabled={isProcessing} onClick={handleEditRelation}>Edit</Button>
              <Button disabled={isProcessing} onClick={() => handleRemove(_id)}><DeleteOutline /></Button>
            </div>
        }
      </Grid>
    </Grid>
  )
}

export default FamilyMemberOption;