import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useMemberStateContext } from 'context/Member/useMemberContext';

const NoteAddActions = ({formikRef, noteEntryRef, isDraft}) => {
  
  const navigate = useNavigate();
  const {memberId} = useMemberStateContext();

  const baseUrl = `/patients/${memberId}/notes`;

  const handleSaveDraft = async () => {
    console.log('[Notes] Saving Draft')
    try {
      await noteEntryRef.current.triggerSaveDraft()
      navigate(`${baseUrl}/create`)
    } catch (error) {
      console.log('[Notes] Error Saving Draft', error)
    }
  }

  const handleSubmitNote = async () => {
    console.log('[Notes] Submitting Note')
    try {
      await formikRef.current.handleSubmit()
      navigate(`${baseUrl}`)
    } catch (error) {
      console.log('[Notes] Error Submitting Note', error)
    }
  }

  useEffect(() => {
    console.log('[NoteAddActions] formik values', formikRef.current?.values)
  }, [formikRef.current?.values])
  
  
  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
        {isDraft ? <Button color="secondary" onClick={() => navigate(-1)}>Delete</Button> : null}
      </div>

      <div style={{margin: 10}}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          style={{width: 250, marginRight: 10}}
          onClick={handleSaveDraft}
        >
          Save in Drafts
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{width: 350}}
          onClick={handleSubmitNote}
        >
          Sign and Submit
        </Button>
      </div>
      
    </div>
  )
}

export default NoteAddActions;