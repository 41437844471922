
import React from 'react';
import PatientEditFamily from 'screens/Patient/Edit/Family';

const EditFamily = () => {
  return (
    <PatientEditFamily />
  )
}

export default EditFamily;