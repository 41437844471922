import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MSwitch, FormControlLabel } from '@material-ui/core';
import { useController } from 'react-hook-form';

const Switch = ({ control, name, defaultValue = false, label }) => {
  const {
    field: { ref, ...inputProps },
    //   fieldState: { invalid, isTouched, isDirty },
    //   formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
  });

  return (
    <FormControlLabel
      control={<MSwitch {...inputProps} inputRef={ref} data-testid="switch" defaultChecked={defaultValue} />}
      label={label}
    />
  );
};

Switch.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
};

export default Switch;
