import React, { Fragment, useRef, useState, useEffect } from 'react';
import moment from 'moment';

import { useHistory, useRouteMatch } from 'react-router-dom';

// Util
import Can from 'hoc/Can';
import { Messages } from 'config/settings';
import {activationWarnings, generateModuleTabs} from './helpers';

// UI
import { Grid, Typography, Button, Box } from '@material-ui/core';
import styles from './PatientModule.module.scss';

// Components
import EmptyState from 'common/components/EmptyState/EmptyState';
import MemberSingle from 'components/Member/View/View';
import Profile from '../Edit/PatientEditProfile';
import Caron from '../Edit/PatientEditCaron';
import ClinicalPath from '../Edit/PatientEditClinicalPath';
import SoberCircle from '../Edit/SoberCircle';
import FamilyTab from '../Edit/Family';

// Context
import {useMemberStateContext, useMemberUpdaterContext} from 'context/Member/useMemberContext';
import useAccountContext from 'context/Account/useAccountContext';

const PatientModule = ({memberId, baseUrl, status, activatedAccount = true}) => {

  // Router
  const history = useHistory();

  // const routeMatch = useRouteMatch([
  //   "/patients/:id/messaging",
  //   "/patients/:id/activities",
  //   "/patients/:id/notes/drafts", 
  //   "/patients/:id/notes",
  //   "/patients/:id/labs",
  //   "/patients/:id",
  // ]);

  // const routeMatchEditMode = [
    //   "/patients/:id/edit/soberCircle",
    //   "/patients/:id/edit/family",
    //   "/patients/:id/edit/caron",
    //   "/patients/:id/edit/clinical",
    //   "/patients/:id/edit/profile",
    // ]
  // console.log('RouterHooks: history', history, 'routeMatch', routeMatch)
      
  // Data: Account
  const {organizationName} = useAccountContext();
  
   // Data: MemberContext State
   const { 
    memberData: data, 
    patientLoading: loading, 
    patientError: error, 
    activateLoading,
    showSuccess,
    activateOpen,    
  } = useMemberStateContext();

   // Data: MemberContext Handlers
  const {
    patientRefetch: refetch,
    handleActivate,
    handleResendActivation,
    handleActivateClose,
    handleActivateOpen,
  } = useMemberUpdaterContext()
  
  // State
  const [listTabs, setListTabs] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isActivated, setIsActivated] = useState(activatedAccount);
    
  // Module Setup
  useEffect(() => {
    if (data?.activatedAccount) setIsActivated(data?.activatedAccount);
  }, [data]);  

  // Setup
  const isEditMode = history.location.pathname.split('/')[3] === 'edit';
  const isDischarged = status === 'DISCHARGED' || data?.status === 'DISCHARGED';

  useEffect(() => {
    generateModuleTabs(baseUrl, memberId, isActivated, isDischarged)
  }, [data, isActivated, isDischarged])

  console.log('[PatientModule] isEditMode', isEditMode, 'isActivated', isActivated, 'discharged', isDischarged, 'data raw', data, 'listTabs', listTabs)

  // Refs
  const headerEl = useRef(null);

  // useEffect(() => {
  //   if (resendError)
  //     enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
  //       variant: 'error',
  //     });
  // }, [resendError]);

  // Navigation Tabs
  const listTabsDefault = generateModuleTabs(baseUrl, memberId, isActivated, isDischarged)

  const successMessage = {
    title: Messages.PATIENT_ACTIVATION_SUCCESS_TITLE,
    text: Messages.PATIENT_ACTIVATION_SUCCESS_TEXT,
  };

  const listTabsEditing = [
    {
      label: 'Profile',
      componentId: 'edit',
      component: () => <Profile />,
      url: `${baseUrl}/${memberId}/edit/profile`,
      value: '/patients/:id/edit/profile',
    },
    {
      label: organizationName,
      componentId: 'edit',
      component: () => <Caron />,
      url: `${baseUrl}/${memberId}/edit/caron`,
      value: '/patients/:id/edit/caron',
    },
    {
      label: 'Clinical Path',
      componentId: 'edit',
      component: () => <ClinicalPath />,
      url: `${baseUrl}/${memberId}/edit/clinical`,
      value: '/patients/:id/edit/clinical',
    },
    {
      label: 'Sober Circle',
      componentId: 'edit',
      component: () => <SoberCircle />,
      url: `${baseUrl}/${memberId}/edit/soberCircle`,
      value: '/patients/:id/edit/soberCircle',
    },
    {
      label: 'Family',
      componentId: 'edit',
      component: () => <FamilyTab />,
      url: `${baseUrl}/${memberId}/edit/family`,
      value: '/patients/:id/edit/family',
    },
  ];

  const showResendBtn = data?.cognitoInfo?.status === 'FORCE_CHANGE_PASSWORD';

  // On isEditing update
  useEffect(() => {
    const resetTabs = headerEl?.current?.resetTabIndex;
    resetTabs && resetTabs.hasOwnProperty('resetTabIndex') && resetTabs.resetTabIndex();
  }, [isEditMode]);

  const dialogProps = {
    showSuccess,
    successMessage,
    warningDetails: activationWarnings(data),
    open: activateOpen,
    title: Messages.PATIENT_ACTIVATION_TITLE,
    loading: activateLoading,
    handleAccept: handleActivate,
    handleClose: handleActivateClose,
    acceptBtnText: Messages.PATIENT_ACTIVATION_BUTTON_ACCEPT,
    text: <Fragment>{Messages.PATIENT_ACTIVATION_DETAILS}</Fragment>,
  };

  useEffect(() => {
    if (data) setListTabs(isEditMode ? listTabsEditing : listTabsDefault);
  }, [data, isEditMode]);
  
  // Methods
  const generateOverviewAside = () => {
    let drugChoice = data.drugChoice;
    if (drugChoice instanceof Array) {
      drugChoice = data.drugChoice.map((item, index) => (
        <li key={index}>{item?.name || ''}</li>
      ));
    }

    let coDisorders = data.coDisorders;
    if (coDisorders instanceof Array) {
      coDisorders = data.coDisorders.map((item, index) => (
        <li key={index}>
          <strong>{item?.code || ''}</strong> {item?.name || ''}
        </li>
      ));
    }

    let medication = data.medication;
    if (medication instanceof Array) {
      medication = data.medication.map((item, index) => (
        <li key={index} className={styles.medication}>
           {item?.medicinalproduct || ''}
        </li>
      ));
    }

    const address = data.address?.state ? `${data.address?.city}, ${data.address?.state}` : data.address?.city;
    const birthDate = data?.birthDate || null;
    const age = birthDate !== null ? moment().diff(birthDate, 'years') : null;
    const gender = <span className={styles.gender}>{data.gender}</span>;

    // const handleTestContextRefetch = () => {
    //   console.log('[PatientModule] refetching');
    //   return refetch();
    // }
    const renderOverview = (
      <Grid container>
        {/* <Button onClick={handleTestContextRefetch} /> */}
        {!!data.clinicalOverview && (
          <Grid item xs={12}>
            <Typography className={styles.short_desc}>
              {showMore ? data.clinicalOverview : `${String(data.clinicalOverview).substring(0, 120)}...`}
            </Typography>
          </Grid>
        )}

        {data.clinicalOverview
          ? data.clinicalOverview.length > 119 && (
              <Button className={styles.more_link} onClick={() => setShowMore(!showMore)}>
                {!showMore ? 'View More' : 'View Less'}
              </Button>
            )
          : null}

        <Grid item xs={12}>
          <Box className={styles.overview_data}>
            <div>
              <h4 className={styles.title}>Primary Substance(s)</h4>
              <ul className={styles.values}>{drugChoice}</ul>
            </div>

            <div>
              <h4 className={styles.title}>Co-occurring Disorders</h4>
              <ul className={styles.values}>{coDisorders}</ul>
            </div>

            {medication && (
              <div>
                <h4 className={styles.title}>Medications</h4>
                <ul className={styles.values}>{medication}</ul>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    );
    
    return [
      {
        value: renderOverview,
      },
      {
        title: 'Phone',
        value: data.phone ? data.phone : 'N/A',
      },
      {
        title: 'City',
        value: address,
      },
      {
        title: 'Age / Sex',
        value: (
          <div>
            {age && `${age} /`} {gender}
            {birthDate && <span style={{ marginLeft: 10, color: 'rgba(0, 0, 0, 0.6)' }}>{`(DOB: ${birthDate})`}</span>}
          </div>
        ),
      },
      {
        hasSublist: true,
        title: organizationName,
        value: [
          {
            title: 'MRN',
            value: data.mrn ? data.mrn : 'N/A',
          },
          {
            title: 'Admit Date',
            value: data.admitDate ? data.admitDate : 'N/A',
          },
          ...(data.projectedDischargeDate
            ? [
                {
                  title: 'Projected Discharge Date',
                  value: data.projectedDischargeDate ? data.projectedDischargeDate : 'N/A',
                },
              ]
            : []),
        ],
      },
    ];
  };

  const moduleTabs = [
    {
      label: 'Overview',
      url: `${baseUrl}/${memberId}`,
      value: '/patients/:id',
      componentId: 'overview',
    },
    {
      label: 'Messaging',
      url: `${baseUrl}/${memberId}/messaging`,
      value: '/patients/:id/messaging',
      componentId: 'messaging',
    },
    {
      label: 'Schedule',
      url: `${baseUrl}/${memberId}/activities`,
      value: '/patients/:id/activities',
      componentId: 'activities',
    },
    {
      label: 'Notes',
      url: `${baseUrl}/${memberId}/notes`,
      value: '/patients/:id/notes',
      componentId: 'notes',
    },
    {
      label: 'Drafts',
      url: `${baseUrl}/${memberId}/notes/drafts`,
      value: '/patients/:id/notes/drafts',
      componentId: 'notes',
    },
    {
      label: 'Labs',
      url: `${baseUrl}/${memberId}/labs`,
      value: '/patients/:id/labs',
      componentId: 'labs',
    },
  ]

  const moduleTabsNotActivated = [
    {
      label: 'Overview',
      url: `${baseUrl}/${memberId}`,
      value: '/patients/:id',
      componentId: 'overview',
    },
  ]

  const moduleNavProps = {
    title: 'Patients',
    breadcrumbs: [
      {
        name: 'Patients',
        url: `${baseUrl}`,
      },
      {
        name: 'Profile',
        url: `${baseUrl}/${memberId}`,
      },
    ],
    navigationTabs: isActivated ? moduleTabs : moduleTabsNotActivated,
    overflowMenu: [],
    // routeMatch,
  };

  const headerProps = {
    listBreadcrumbs: [
      {
        name: 'Patients',
        url: `${baseUrl}`,
      },
      {
        name: 'Profile',
        url: `${baseUrl}/${memberId}`,
      },
    ],
    listTabs: isEditMode ? listTabsEditing : listTabsDefault,
    overflowMenu: [
      ...(!isActivated
        ? [
            {
              name: Messages.PATIENT_ACTIVATE_LINK_TEXT,
              onClick: handleActivateOpen,
              // permission: {
              //  perform: 'ACTIVATE_PATIENT',
              //  module: 'PATIENTS',
              //  memberId: memberId,
              //  memberType: memberType,
              //  policy: 'module_action'
              // },
            },
          ]
        : []),
      {
        name: isEditMode ? 'Back to Patient' : 'Edit Patient',
        src: isEditMode ? {to: `${baseUrl}/${memberId}`} : {to: `${baseUrl}/${memberId}/edit`, state: {isEditing: true}},
        // permission: {
        //   perform: 'EDIT_PATIENT',
        //   patientId: memberId,
        //   module: 'PATIENTS',
        // },
      },
      ...(showResendBtn
        ? [
            {
              name: Messages.MEMBER_RESEND_INVITATION_BUTTON,
              onClick: handleResendActivation,
              // permission: {
              //   perform: 'RESEND_PATIENT_INVITE',
              //   patientId: memberId,
              //   module: 'PATIENTS',
              // },
            },
          ]
        : []),
    ],
  };

  const overviewHeaderProps = {
    hasRating: true,
    hasDaysSober: true,
    hasEmotionState: true,
    hasProgramTime: true,
    showAddActivity: true,
    showSendNotification: true,
    progressData : {
      goals: isActivated ? 30 : 0,
      engagement: isActivated ? 60 : 0,
      riskLevel: data?.riskLevel ? data.riskLevel : 0,
    },
    showCallButton: true,
  };

  const asideCollapseProps = [
    {
      title: 'Clinical Overview', // todo move to translations
      name: 'overview',
      contentType: 'overview',
      list: !!data ? generateOverviewAside() : [],
    },
    {
      title: 'Care Team', // todo move to translations
      name: 'careTeam',
      emptyText: 'Not yet defined.', // todo move to translations
      contentType: 'memberList',
      list: data?.careTeamDetails,
      listProps: {
        // disabled: true,
        isCareTeam: true,
        memberType: 'staff',
      },
    },
    {
      title: 'Family', // todo move to translations
      name: 'family',
      emptyText: 'Family not defined.',
      contentType: 'memberList',
      list: data?.relations || [],
      listProps: {
        isFamily: true,
        memberType: 'family',
      },
    },
    {
      title: 'Sober Circle', // todo move to translations
      name: 'soberCircle',
      emptyText: 'Not yet defined.', // todo move to translations
      contentType: 'memberList',
      list: data?.soberCircle?.members || [],
      listProps: {
        isSoberCircle: true,
        memberType: 'patient', // Temp: get actual member type
      },
    },
  ];

  const memberSingleProps = {
    memberId,
    memberType: 'patient',
    data,
    error,
    loading,
    refetch,
    isEditMode,
    headerProps,
    moduleNavProps,
    overviewHeaderProps,
    listTabs,
    asideCollapseProps,
    dialogProps,
  };

  return (<MemberSingle {...memberSingleProps} />
    // <Can
    //   module="PATIENTS"
    //   perform="VIEW_PATIENT"
    //   memberId={memberId}
    //   memberType="patient"
    //   policy="all"
    //   origin="Patient Module"
    //   yes={() => <MemberSingle {...memberSingleProps} />}
    //   no={() => <EmptyState title="Sorry, can't open this 😕" text="You don't have access to this member"/>}
    // />
  );
};

export default PatientModule;
