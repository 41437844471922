import React from 'react';
import {makeStyles} from '@material-ui/styles';

const HeaderContainer = ({children}) => {
  const styles = useStyles();
  return (
    <div className={styles.header}>
      {children}
    </div>
  ) 
};

export default HeaderContainer; 

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 25,
    minHeight: 100,
    backgroundColor: '#fff',
    borderBottom: '1px solid #E0E0E0',
  },
}));