import React, { useState, createRef } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { getIn } from 'formik';
import styles from "./PhoneInputField.module.scss";
import CustomPhoneInput from "./PhoneInputHelper"

const PhoneInputField = (props) => {
    const {
        className,
        field: { name,
            value },
        form: {
            errors,
            handleBlur,
            setFieldValue,
            touched
        },
        label,
        country,
        onChange,
        disabled,
        mandatory
    } = props;

    const [isFocused, setFocused] = useState(false);
    const isError = getIn(touched, name) && getIn(errors, name);
    const errorStyle = isError ? 'error' : '';
    const filledStyle = (isFocused || value) ? 'filled' : '';
    const disabledStyle = disabled ? 'disabled' : '';

    const handleInputBlur = (e) => {
        setFocused(false);
        handleBlur(e);
    };

    const handleInputFocus = () => setFocused(true);

    const onValueChange = (phoneNumber) => {
        setFieldValue(name, phoneNumber);
        if (onChange !== null && phoneNumber) {
            onChange(phoneNumber);
        }
    };
    const phoneRef = createRef();
    return (
        <div className={`${styles.wrapper} ${className} ${errorStyle} ${filledStyle} ${disabledStyle} form-control`}>
            <PhoneInput
                placeholder="Enter phone number"
                name={name}
                value={value}
                onChange={onValueChange}
                countryOptionsOrder={["US", "CA"]}
                defaultCountry="US"
                country={country}
                ref={phoneRef}
                error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') :
                    mandatory ? 'Phone number required' : undefined}
                inputComponent={CustomPhoneInput}
            />
        </div>
    );
};

PhoneInputField.propTypes = {
    className: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    name: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    country: PropTypes.string,
    disabled: PropTypes.bool,
};

PhoneInputField.defaultProps = {
    className: '',
    label: '',
    onChange: null,
    country: 'US',
    disabled: false,
};

export default PhoneInputField;