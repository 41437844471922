import React from 'react';

// UI
import { makeStyles } from '@material-ui/core/styles';

const QuestionsList = ({ questions, processType }) => {
  const classes = useStyles();
  const showQuestions = processType === 'Worksheet';
  return (
    <div className={classes.value}>
      <div className={classes.subtitle}>{processType}</div>
      {showQuestions ? (
        <div>
          {questions?.map(question => (
            <div className={classes.question}>{question}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

// Styles
const useStyles = makeStyles({
  value: {
    // width: '180px',
    // border: 'solid 1px green',
  },
  subtitle: {
    // fontWeight: 'bold',
    marginBottom: '10px',
  },
  question: {
    marginBottom: '5px',
  },
});

export default QuestionsList;
