import React from 'react';
import { Box, Grid } from '@material-ui/core';
import styles from './PatientEdit.module.scss';
import classnames from 'classnames';

// Data
import { EDIT_PATIENT, GET_FULL_PATIENT_INFO } from 'data/graphql/patients';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { fuzzyDateToMoment } from 'common/helpers/timeHelpers';

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';

// Formik
import { Formik, Field, Form } from 'formik';
import { FormDevTools } from 'common/components/Formik/FormDevTools';
import Autocomplete from 'common/components/PanelFilters/Form/FormikAutocomplete/FormikAutocomplete';
import Datepicker from 'common/components/InputFields/KeyboardDatepicker/Datepicker';
import Dropdown from 'common/components/InputFields/Dropdown/Dropdown';
import TextField from 'common/components/InputFields/TextField/TextField';

// Components
import SigmundFamily from './Family/Sigmund';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import { ButtonPrimary } from 'common/components/Button/Button';

const PatientEditCaron = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Data
  const {memberData: patient} = useMemberStateContext(); 
  const { staff, refetchMembers } = useMembersContext()
  const {
    organizationName, 
    locations: organizationLocations,
  } = useAccountContext();

  // Temp: Add fullName to staff object for Autocomplete
  const cliniciansList = staff?.map(member => {
    return {
      ...member,
      fullName: `${member?.firstName} ${member?.lastName}`, 
    }
  });

  const locations = organizationLocations?.map(c => c.name);
  const location = organizationLocations
    .filter(l => l._id === patient.locationId)
    .map(l => l.name)[0] || '';

  const [editPatient] = useMutation(EDIT_PATIENT, {
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient._id } },
    ],
    onError: () => enqueueSnackbar('Error Updating Member', { variant: 'error' }),
    onCompleted: () => {
      refetchMembers()
      enqueueSnackbar('Profile Updated', { variant: 'success' })
    }
  });

  const handlePatientCaronEdit = data => {
    const locationSelected = organizationLocations
      .filter(l => l.name === data.location)
      .map(l => l._id)[0];
    const primaryClinicianId = getIdByName(data.primaryClinician);

    const secondaryClinicianIds = data.secondaryClinician
      .map(name => getIdByName(name))
      .filter(id => !!id && id !== primaryClinicianId);
    const patientData = {
      patient: {
        _id: patient._id,
        locationId: locationSelected,
        admitDate: data.admitDate,
        dischargeDate: data.dischargeDate,
        primaryClinicianId,
        secondaryClinicianIds,
      },
    };
    editPatient({ variables: patientData });
  };

  const { _id, firstName, lastName, image, emrPic, type } = patient;
  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type,
    label: `${firstName} ${lastName}`,
    showLabel: true,
    disabled: true,
    size: 'xlarge',
  };
  
  const getIdByName = name => {
    const result = cliniciansList.find(c => c.fullName === name);
    if (result) return result._id;
    return '';
  };
  const getPrimaryClinician = (data) => {
    const result = patient?.careTeamDetails.find(m => m.primary);
    if (!result) return '';
    return data ? data.primaryClinician : `${result?.firstName} ${result?.lastName}`;
  }
  const getSecondaryClinician = (data) => {
    const result = patient?.careTeamDetails?.filter(m => !m.primary) || [];
    return data ? data.secondaryClinician : result.map(m => `${m?.firstName} ${m?.lastName}`)
  }

  const initialValues = {
    mrn: patient.mrn,
    location: location,
    encounterId: patient.encounterId,
    admitDate: patient.admitDate
      ? fuzzyDateToMoment(patient.admitDate)
      : '',
    dischargeDate: patient.dischargeDate
      ? fuzzyDateToMoment(patient.dischargeDate)
      : '',
    primaryClinician: getPrimaryClinician(),
    secondaryClinician: getSecondaryClinician(),
    sharetekId: patient?.devices?.sharetek?.sharetekId,
    soberlinkId: patient?.devices?.soberlink?.soberlinkId,
    bactrackId: patient?.devices?.bactrack?.bactrackId,
  }

  return (
    <Grid className={styles.wrapper}>
      <Grid container className={styles.module_edit}>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handlePatientCaronEdit}
        >
          {({ values, errors,handleChange, setFieldValue  }) => (
            <Form>
              <Grid container className={styles.wrapper}>
                <Grid item xs={3}>
                  <div className={styles.avatar_wrapper}>
                    <MemberAvatar {...avatarProps} />
                  </div>
                </Grid>

                <Grid item xs={9} direction="column" justify="space-between">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        {organizationName} Setup
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label htmlFor="mrn">MRN</label>
                      <Field
                        name="mrn"
                        component={TextField}
                        disabled
                        id="mrn"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label htmlFor="location">Location</label>
                      <Field
                        name="location"
                        component={Dropdown}
                        items={locations}
                        value={values.location}
                        renderValue={v => `${v}`}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Encounter
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label htmlFor="encounterId">Encounter ID</label>
                      <Field
                        name="encounterId"
                        component={TextField}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} />

                    <Grid item xs={12} md={6}>
                      <label htmlFor="admitDate">Admit Date</label>
                      <Field
                        name="admitDate"
                        component={Datepicker}
                        disablePast={false}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label htmlFor="dischargeDate">Discharge Date</label>
                      <Field
                        name="dischargeDate"
                        component={Datepicker}
                        disablePast={false}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Care Team
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <label>Primary Clinician</label>
                      <Field
                        name="primaryClinician"
                        as={Autocomplete}
                        value={getPrimaryClinician(values)}
                        setFieldValue={setFieldValue}
                        options={cliniciansList.map(c => c.fullName)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <label>Secondary Clinician</label>
                      <Field
                        name="secondaryClinician"
                        multiple={true}
                        as={Autocomplete}
                        value={getSecondaryClinician(values)}
                        options={cliniciansList.map(c => c.fullName)}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SigmundFamily patient={patient} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2} className={classnames(styles.section_title)}>
                        Devices
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <label htmlFor="sharetekId">Sharetek ID</label>
                      <Field
                        name="sharetekId"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <label htmlFor="bactrackId">BacTrack ID</label>
                      <Field
                        name="bactrackId"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <label htmlFor="soberlinkId">Soberlink ID</label>
                      <Field
                        name="soberlinkId"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12} />

                    <Grid item xs={12}>
                      <ButtonPrimary type="submit">Update</ButtonPrimary>
                    </Grid>

                    <Grid item xs={12}>
                      <FormDevTools values={values} errors={errors} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PatientEditCaron;
