/**
 * Check Permission to access Module, Action and Member
 * @param {string} module resource on platform (eg. 'NOTES')
 * @param {string} action operation to perform on module (eg. 'ADD_NOTE')
 * @param {string} dashboard custom dashboard (eg. 'LAB_DASHBOARD')
 * @param {string} memberType type of member (patient, family, staff)
 * @param {string} memberId id of member to evaluate 
 * @param {Object} policy access policy (eg. 'module_only')
 * @param {string} origin identifies component calling the function (optional)
 * @param {Object} permissions hash map of permissions, processed in Context (listens to user update)
 * @param {string} fetch permission refetch policy, set 'true' to always refetch, or minutes since last query 'expire.after(10)'
 * @returns {boolean} permission access (true/false)
 */
const checkPermissions = ({permissions, module, action, dashboard, memberType, memberId, policy = 'all', origin}) => {
  
  // Validate: Dashboard
  if (dashboard && policy === 'dashboard_only') {
    const dashboardAccess = permissions?.dashboards.includes(dashboard);
    console.log(`[Permissions] Dashboard Access: ${dashboard}: ${dashboardAccess}`)
    return dashboardAccess
  }

  // Validate: Permissions and params
  if(!permissions || !module) {
    if(!Array.isArray(permissions) && !permissions?.length) {
      console.log('[Permissions] Permissions not yet loaded or returned empty array.', permissions)
      return false
    };
    return false
  }
  
  // Validate: Policy
  if (policy) {
    const validPolicy = Object.keys(accessPolicies);
    if (!validPolicy.includes(policy)) {
      console.log('[Permissions] Invalid access policy 🖕 \n Policy:', policy, '\n Origin:', origin)
      return false
    }
  }

  // Data
  const moduleActions = permissions?.moduleActions;
  const members = permissions?.members || [];
  
  // Setup
  const hasMemberData = memberType || memberId;
  const moduleToProcess = moduleActions?.get(module)
  const policyRule = accessPolicies?.[policy].rule;

  console.time('Permissions Check v2')
  const canMember = hasMemberData ? members?.[memberType].includes(memberId) : false;
  const canModule = module ? !!moduleToProcess : false;
  const canAction = action ? !!moduleToProcess?.[action] : false;

  // accessMap must maintain order: module, action, member
  const accessMap = {module: canModule, action: canAction, member: canMember};

  // Evaluate
  const userAccess = Object.keys(accessMap).filter((key) => accessMap[key]);
  const hasPermission = policyRule.check.toString() === userAccess.toString();
  console.timeEnd('Permissions Check v2')

  // console.log(`
  // [Permissions] 
  //   Allow: ${hasPermission}
  //   Access Policy: ${policy}
  //   User Access: ${userAccess}
  //   Rule: ${policyRule.check.toString()}
  //   Can access member ${memberType} ${memberId}: ${canMember}
  //   Can access module ${module}: ${canModule}
  //   Can perform action ${action}: ${canAction}
  //   Origin: ${origin}
  // `)

  return hasPermission
}

// Verify access to moduleActions 
const moduleChecker = (permissions, module, action) => {
  if (!permissions) return false;
  const modulePath = permissions?.moduleActions?.get(module);
  const moduleActionPath = modulePath?.[action];
  const hasAccess = action ? moduleActionPath : modulePath;

  return !!hasAccess
}

// Verify access to dashboards
const dashboardChecker = (permissions, dashboard) => {
  if (!permissions) return false
  const hasAccess = permissions?.dashboards?.includes(dashboard)

  return !!hasAccess
}

export {checkPermissions, moduleChecker, dashboardChecker};


/**
 * accessPolicies describe RBAC rules along with match policies
 * Maintains structure: module, action, member
 * Rapid authz checks rely on Array.toString() operator
*/
const accessPolicies = {
  all: {
    description: 'Evaluate module, action and member access',
    match: policy => policy.all,
    rule: {
      check: ['module', 'action', 'member'],
      ignore: null,
    },
    version: 1.0,
  },
  // Module Policies
  module_only: {
    description: 'Evaluate module access',
    match: policy => policy.module,
    rule: {
      check: ['module'],
      ignore: ['member', 'action'],
    },
    version: 1.0
  },
  module_action: {
    description: 'Evaluate module and action access',
    match: policy => policy.module.action,
    rule: {
      check: ['module', 'action'],
      ignore: ['member'] 
    },
    version: 1.0,
  },
  // Dashboards
  dashboard_only: {
    description: 'Evaluate dashboard access',
    match: policy => policy.dashboard,
    rule: {
      check: ['dashboard'],
      ignore: ['module', 'action', 'member'],
    },
    version: 1.0,
  },
  // Member Policies
  member_only: {
    description: 'Evaluate member access',
    match: policy => policy.member,
    rule: {
      check: ['member'],
      ignore: ['module', 'action'] 
    },
    version: 1.0,
  },
  member_module: {
    description: 'Evaluate member and module access',
    match: policy => policy.member.module,
    rule: {
      check: ['module', 'member'],
      ignore: ['action'], 
    },
    version: 1.0,
  },
  member_action: {
    description: 'Evaluate member and action access',
    match: policy => policy.member.action,
    rule: {
      check: ['action', 'member'],
      ignore: ['module'] 
    },
    version: 1.0,
  },
};
