import moment from 'moment';
import _ from 'lodash';

/**
 * Check if input date has passed than X days, including X.
 * @param {*Date|String} testDueDate date to test
 * @param {*Number} days number of days 
 * @returns {Boolean} if date is past X days
 */
export const checkIfDaysOld = (testDueDate, days) => {
  const checkNumberOfDays = moment().clone().subtract(days, 'days').startOf('day');
  return moment(testDueDate).isSameOrAfter(checkNumberOfDays)
}

/**
 * Duration between two time slots. Returns minutes.
 * @param {Date|String} end string/date with end time
 * @param {Date|String} end string/date with end time
 * @returns {Number} time difference in minutes
 */
export const getDuration = (end, start) => {
  const endTime = moment(end, "hh:mm");
  const startTime = moment(start, "hh:mm");
  return (
    moment
      .duration(endTime.diff(startTime))
      .asMinutes()
  )
};

/**
 * split time to hours and minutes
 * @param {Date|String} time string/date string with time
 * @returns {{hours: String, minutes: String}}  object with hours and minutes keys
 */
export const splitTime = time => {
  const [hours, minutes] = (time || '').split(':');
  return { hours, minutes };
};

export const timeAgo = time => {
  return moment(new Date(time)).fromNow();
};

/**
 * toUTCTime
 * @param {Date|String} time string/date string with time
 * @param {String} format moment format string
 * @param {Boolean} keepLocalTime
 * @returns {Date} utc time
 */
export const toUTCTime = (time, format = 'HH:mm', keepLocalTime = false) =>
  moment()
    .set(splitTime(time))
    .utc(keepLocalTime)
    .format(format);

/**
 * fromUTCToLocal
 * @param {Date|String} time string/date string with time
 * @param {String} format moment format string
 * @returns {Date} local time
 */
export const fromUTCToLocal = (time, format = 'HH:mm') =>
  moment()
    .utc()
    .set(splitTime(time))
    .utcOffset(moment().utcOffset())
    .format(format);

export const getArrMinDate = (arr, pathToDate) => {

  const result = arr.sort((a, b) => {
    a = new Date(_.get(a, pathToDate));
    b = new Date(_.get(b, pathToDate));
    return a > b ? -1 : a < b ? 1 : 0;
  });

  return moment(_.get(result[arr.length - 1], pathToDate));
}

export const getArrMaxDate = (arr, pathToDate) => {
  const result = arr.sort((a, b) => {
    a = new Date(_.get(a, pathToDate));
    b = new Date(_.get(b, pathToDate));
    return a > b ? -1 : a < b ? 1 : 0;
  });

  return moment(_.get(result[0], pathToDate));
}

export const fuzzyDateToMoment = date =>
  moment(date, date.indexOf("/") === -1 ? "YYYY-MM-DD" : "MM-DD-YYYY")
