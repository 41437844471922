import React from 'react';
import {useAuthZContext} from 'context/Auth/useAuthContext';

export const check = (
  permissions,
  module,
  action,
  component,
  patientId,
  staffId
) => {
  if (!permissions) {
    return true;
  }
  if (component) {
    return permissions.find(p => p.UI.indexOf(component) >= 0);
  }
  // console.log(
  //   'CAN check function',
  //   permissions,
  //   module,
  //   action,
  //   component,
  //   patientId,
  //   staffId
  // );
  //return inside foreach wont trigger return
  console.time('Time: Permissions Check Old')
  let found = false;
  let permissionsLen = permissions.length;
  for (let index = 0; index < permissionsLen; index++) {
    let p = permissions[index];
    let moduleActionsLen = p.moduleActions.size;
    if (module) {
      //if module is given as a parameter then module has to exists
      let m = p.moduleActions.find(ma => ma.code === module);
      if (!m) continue;
      if (!action) return true; //if module exists and no action is specified
      if (m.actions.find(a => a === action)) {
        if (!patientId && !staffId) return true;
        if (patientId) found = p.patientIds.find(p => p === patientId);
        if (staffId) found = p.staffIds.find(p => p === staffId);
        if (found) return true;
      }
    } else if (action)
      for (let ii = 0; ii < moduleActionsLen; ii++) {
        //if module is not specified we need to look for the action in all the modules
        let m = p.moduleActions[ii];
        if (m.actions.find(a => a === action)) {
          if (!patientId && !staffId) return true;
          if (patientId) found = p.patientIds.find(p => p === patientId);
          if (staffId) found = p.staffIds.find(p => p === staffId);
          if (found) return true;
        }
      }
  }
  console.timeEnd('Time: Permissions Check Old')
  return found;
};

export const PermissionDeny = ({ title }) => (
  <div
    style={{
      display: 'flex',
      height: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0,0,0,0.5)',
    }}
  >
    {title}
  </div>
);

const Can = ({
  module,
  perform,
  component,
  patientId,
  staffId,
  yes,
  no,
}) => {
  const {permissions} = useAuthZContext();
  return check(permissions, module, perform, component, patientId, staffId)
    ? yes()
    : no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
