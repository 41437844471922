import React from 'react'
import { useForm } from 'react-hook-form'
// import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux'

//Data
import { TYPES } from 'components/Scheduler/components/AppointmentForm/data'

// Styles
import { makeStyles } from '@material-ui/core/styles'
// import styles from '../../../components/Scheduler/components/AppointmentForm/stylesNew.module.scss';

//Components
import Input from '../../../common/components/Forms/Input'
import Textarea from '../../../common/components/Forms/Textarea'
import Button from '../../../common/components/Forms/Button'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'
import { Box } from '@material-ui/core'
import SummaryItem from '../../../common/components/Forms/SummaryItem'
import SummaryItemValue from '../../../common/components/Forms/SummaryItemValue'
import TagsList from '../../../common/components/Forms/TagsList'
import QuestionsList from '../../../common/components/Forms/QuestionsList'
import { SelectAutocomplete } from '../../../common/components/Forms/Autocomplete/SelectAutocomplete'
import { RadioWoControl } from '../../../common/components/Forms/Radio'

const SaveAsTemplate = ({ next, previous, onClose }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const { activityTemplate, saveNewTemplate, processTypes: rawProcessTypes } = useActivitiesContext()

  const processTypes = rawProcessTypes?.map(({ name, description }) => ({
    value: name,
    label: description,
  }))

  const categoriesList = useSelector((state) => state.dashboard.dashboardData.organization.templateCategories)
  const categoryItems = categoriesList?.map((item) => item.name)

  const onSubmit = (data) => {
    next()
    const { category, name, detail } = data
    const variables = {
      activityTemplate: {
        name,
        category: [category],
        description: detail,
        template: {
          scheduling: { duration: activityTemplate.duration },
          type: activityTemplate.type.toLowerCase(),
          description: data.description,
          name: activityTemplate.title,
          points: activityTemplate.points,
          tags: activityTemplate.tags,
          processType: activityTemplate.processType,
          ...(activityTemplate.processType === 'worksheet'
            ? { addWorksheetQuestions: activityTemplate.addWorksheetQuestions }
            : {}),
        },
      },
    }
    saveNewTemplate({ variables })
    onClose()
  }

  const handleCancel = () => onClose()
  const getProcessTypeLabel = (value) => {
    const type = processTypes.find((item) => item.value === value)
    return type.label
  }

  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.activityDetaits}>
        <div className={classes.activityHeader}>
          <div className={classes.sectionTitle}>Activity Details</div>
          <Button type="submit" variant="text" className={classes.editBtn} color="primary" size="small">
            {' '}
            Edit{' '}
          </Button>
        </div>
        <div className={classes.detailsBody}>
          <SummaryItem title="Type">
            <RadioWoControl name="type" items={TYPES} value={activityTemplate.type} disabled="true" />
          </SummaryItem>
          <SummaryItem title="Title">
            <SummaryItemValue value={activityTemplate.title} />
          </SummaryItem>
          <SummaryItem title="Detail">
            <SummaryItemValue value={activityTemplate.description} />
          </SummaryItem>
          <SummaryItem title="Points">
            <SummaryItemValue value={activityTemplate.points} />
          </SummaryItem>
          <SummaryItem title="Tags">
            <TagsList tags={activityTemplate.tags} />
          </SummaryItem>
          <SummaryItem title="After">
            <QuestionsList
              questions={activityTemplate.addWorksheetQuestions}
              processType={getProcessTypeLabel(activityTemplate.processType)}
            />
          </SummaryItem>
        </div>
      </div>
      <form className={classes.templateDetails} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputBox}>
          <div className={classes.sectionTitle}>Template details</div>
          <div className={classes.sectionSubtitle}>Save your template for quick entry</div>
          <SummaryItem title="Category">
            <SelectAutocomplete
              control={control}
              name="category"
              required={true}
              error={errors.category}
              errorText={'Category is required'}
              items={categoryItems}
              classes={classes.categoryPreselect}
              fullWidth
            />
          </SummaryItem>
          <SummaryItem title="Name">
            <Input
              control={control}
              name="name"
              fullWidth
              required={true}
              error={errors.name}
              errorText={'Name is required'}
            />
          </SummaryItem>
          <SummaryItem title="Detail">
            <Textarea
              control={control}
              name="detail"
              fullWidth
              required={true}
              error={errors.detail}
              errorText={'Detail is required'}
            />
          </SummaryItem>
        </div>
        <Box className={classes.buttonsBox}>
          <Button variant="contained" className={classes.cancelBtn} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save Template
          </Button>
        </Box>
      </form>
    </div>
  )
}

// Styles
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 'solid 1px lightgray',
    padding: 20,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '17px',
    marginBottom: '5px',
    marginTop: '2px',
  },
  detailsBody: {
    marginTop: '20px',
    marginBottom: '40px',
  },
  templateDetails: {
    padding: '10px',
    // margin: '10px',
  },
  inputBox: {
    border: '1px solid lightgray',
    borderRadius: '10px',
    boxShadow: '2px 2px 2px lightgray, -2px -2px 2px lightgray',
    width: '300px',
    height: '300px',
    padding: '10px',
    // margin: '10px',
  },
  activityDetaits: {
    flexGrow: 1,
    padding: '10px',
  },
  activityHeader: {
    display: 'flex',
    flexDirection: 'row',
    // border: 'solid 1px green',
    alignItems: 'center',
  },
  // editBtn: {
  //     backgroundColor: 'white',
  //     color: 'blue',
  //     boxShadow: 'none',
  //     marginLeft: '5px',
  // },
  sectionSubtitle: {
    marginBottom: '20px',
    // border: '1px solid orange',
  },
  categoryTitle: {
    width: '100px',
    fontWeight: 'bold',
  },
  inputTitle: {
    width: '100px',
    fontWeight: 'bold',
    paddingTop: '10px',
  },
  buttonsBox: {
    // border: 'solid 1px red',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    backgroundColor: 'white',
    marginRight: '10px',
    boxShadow: 'none',
    color: 'gray',
  },
})

export default SaveAsTemplate
