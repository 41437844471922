import React from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import TypeLabel from './TypeLabel';
// import ViewNote from '../View/Dialog';
// import { Link } from 'react-router-dom-v5-compat';
import * as Yup from "yup";

// Notes Table
export const toTableData = (data, navigate, id = null) => !data ? [] : data.map((item, i) => ({
    date: moment(item.noteDate).format('MM-DD-YYYY'), // MMM D
    note: item.note?.replace(/<\/?[^>]+(>|$)/g, ""),
    type: <TypeLabel name='typeLabel' label={item.type} />,
    subType: item.subType?.displayName || '',
    emotion: item.analysis?.emotionalState || '',
    engagement: item.analysis?.engagementLevel || null,
    more: <Button onClick={() => navigate(`view/${item?._id}`)}>View</Button>,
}));

// Caron Config
export const NOTE_TYPES = {
    CLIENT: 'CLIENT_NOTE',
    FAMILY: 'FAMILY_NOTE',
    CONFERENCE: 'CONFERENCE_NOTE',
    CASE_MANAGEMENT: 'CASE_MANAGEMENT_NOTE',
    OUTSIDE_PROVIDER: 'OUTSIDE_PROVIDER_NOTE',
    CARE_CONSULT: 'CARE_CONSULT_NOTE',
    TRASNFER_CONSULT: 'TRANSFER_CONSULT_NOTE',
    SOBERLINK: 'SOBERLINK_NOTE',
    UDS: 'UDS_NOTE',
};
export const withAnalysisData = [NOTE_TYPES.CLIENT, NOTE_TYPES.FAMILY]
export const withGroupNote = [
    NOTE_TYPES.CONFERENCE,
    NOTE_TYPES.FAMILY,
    NOTE_TYPES.CASE_MANAGEMENT,
    NOTE_TYPES.SOBERLINK,
    NOTE_TYPES.UDS,
];

export const callNoteTypes = [NOTE_TYPES.CLIENT, NOTE_TYPES.CONFERENCE, NOTE_TYPES.FAMILY];

export const noteTypeSchema = {
    groupValue: "code",
    groupLabel: "displayName",
    subOptions: "subTypes",
    subValue: "code", 
    subLabel: "displayName"
}
export const getFieldsForSubType = (subType, noteSetup, memberType) => {
    if (subType) {
        const subT = noteSetup.map(t => t.subTypes
            .find(st => st.code === subType))
            .filter(a => a);
        const fields = subT ? subT[0].fields : [];
        const familyFields = fields.filter(f => !f.name?.includes("relapse"))
        return memberType === "family" ? familyFields : fields;
    }
    return []
}

export const mapCallTypeToNoteType = (callType, NOTE_TYPES) => {
  return NOTE_TYPES[callType.replace(/_CALL$/, '')];
}

// Formik Validation Schema
export const FormikCareNoteSchema = Yup.object().shape({
    note: Yup.string()
      .label("Note Content")
      .required("Note is required."),
    subType: Yup.string()
      .label("Sub Type")
      .required("Please select the note type."),
    engagementLevel: Yup.number()
      .label("Engagement Levels")
      .min(0)
      .max(100)
      .nullable(),
    noteDate: Yup.date()
      .max(moment().add(1, 'day'), "Observation cannot be in the future."),
    relapsedAt: Yup.date()
      .max(moment().add(1, 'day'), "Relapse cannot be in the future.")
  });

  
/**
* Merge Multi-episode notes
* @param {Array} data raw member data from member query 
* @returns {Array} merged notes including current and all past notes
*/
export const mergeEpisodeNotes = (data = []) => {
    
  const currentNotes = data?.notes || [];

  const pastEncounters = data?.episodeOfCare
    .map(ep => ep)
    .filter(ep => ep.status === 'finished')
    .map(ep => ep.encounters)

  const pastEncounterNotes = pastEncounters?.map(en => { 
    return { 
      period: en?.[0]?.period, 
      notes: en?.[0]?.notes 
    }
  });
  const pastNotes = pastEncounterNotes?.[0]?.notes || [];

  // Merge Notes
  return [...currentNotes, ...pastNotes]
  
}

/**
 * Sanitize and Prepare Note Data for GraphQL Mutation 
 * @param {Object} values Raw form values
 * @param {Object} data EntryForm prop data
 * @param {Boolean} isDraft unsigned draft note
 * @param {Boolean} isCopy copy for member in group note 
 * @returns object for NoteInput or NoteDraftInput
 */
export const prepareNoteEntry = ({
  values = {},
  data = {},
  targetMemberId,
  isCopy = false,
  isDraft = false,
  isDraftToNote = false,
}) => {
  
  // Safety Checks
  const hasGroupNote = withGroupNote.includes(values.subType);
  const hasAnalysis = withAnalysisData.includes(values.subType);

  // Input Types
  const analysisInput = {
    analysis: {
      emotionalState: hasAnalysis ? values.emotionalState : null,
      engagementLevel: hasAnalysis ? values.engagementLevel : null,
    },
  }
  const relapsedInput = {
    relapsed: values.relapsed,
    ...(values.relapsed && { relapsedAt: values.relapsedAt }),
  }
  const relatedMemberInput = {
    relatedMemberIds: hasGroupNote ? values.relatedMembers : [],
    ...(data?.selected?._id ? { activityId: data?.selected?._id } : {}),
  } 
  const callNoteInput = {
    actualDuration: values.actualDuration,
  }
  const signedNoteInput = {
    signed: true,
    signedByEmail: values.signedByEmail,
  }

  // Note Entry Types
  const noteEntry = {
    memberId: values.memberId,
    note: {
      note: values.note,
      subType: values.subType,
      noteDate: values.noteDate,
      ...signedNoteInput,
      ...relapsedInput,
      ...analysisInput,
      ...relatedMemberInput,
      ...callNoteInput,
    }
  }
  const draftNoteEntry = {
    memberId: values.memberId,
    draft: {
      _id: values.id,
      note: values.note,
      subType: values.subType,
      noteDate: values.noteDate,
      ...relapsedInput,
      ...analysisInput,
      ...relatedMemberInput,
      ...callNoteInput,
    }
  }
  const draftToNoteEntry = {
    draftToNote: {
      draftId: values.id,
      ...signedNoteInput,
    }
  }
  const noteCopyEntry = {
    memberId: targetMemberId,
    note: {
      note: values.note,
      subType: values.subType,
      noteDate: values.noteDate,
      ...signedNoteInput,
    }
  }
  
  if (isDraft) { 
    console.log('[Note Utils] Draft Note > Data Ready', draftNoteEntry)
    return draftNoteEntry;
  }

  if (isDraftToNote) {
    console.log('[Note Utils] Convert Draft to Note > Data Ready', draftToNoteEntry)
    return draftToNoteEntry;
  }

  if (isCopy) { 
    console.log('[Note Utils] Group Note > Data Ready', noteCopyEntry)
    return noteCopyEntry;
  }

  console.log('[Note Utils] Note > Data Ready', noteEntry)
  return noteEntry;
}