import React from 'react';
import { useField } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './QuillNote.module.scss';

const QuillNote = ({ 
  placeholder, 
  ...props
}) => {

  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (    
    <>
    <div className={styles.quill_error}>{errorText}</div>
    <div className={styles.quill_container}>
      <ReactQuill
        value={field.value} 
        onChange={field.onChange(field.name)}
        placeholder={placeholder}
        modules={QuillNote.modules}
        formats={QuillNote.formats}
        className={styles.quill_note}
        theme={'snow'}
      />
    </div>
    </>
  );
}

QuillNote.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

QuillNote.formats = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'align',
];

export default QuillNote;