import React, { useState } from 'react';

// UI
import {
  Box, Grid,
  Button, IconButton,
  Dialog, DialogContent, DialogTitle,
  FormControlLabel, FormHelperText, Radio,
  RadioGroup, Switch, TextField, withStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import styles from './LabsEntry.module.scss';

// Utils
import _ from 'lodash';
import moment from 'moment';
import {controlDialogClose} from 'common/helpers/material';

// Data
import { useMutation } from '@apollo/client';
import { ADD_LAB_DETAILS, EDIT_LAB_RESULT, GET_LABS } from 'data/graphql/labs';
import { addLabDetails, editLabResults } from 'redux/actions/udsLabs';
import { useDispatch } from "react-redux";

// Forms
import * as Yup from "yup";
import { FieldArray, Form, Formik, Field } from 'formik';
import { FormDevTools } from 'common/components/Formik/FormDevTools';
import { useSnackbar } from 'notistack';

// Components
import DropZone from 'common/components/Dropzone/DropZone';
import SubstanceAutocomplete from 'common/components/Labs/SubstanceAutocomplete';

const ButtonLarge = withStyles({
  root: {
    borderRadius: '24px',
    padding: '6px 20px',
  },
  label: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
  }
})(Button);

const EnterLabResults = ({
  data: {
    _id,
    patient,
    dueDate,
    panel,
    status: labStatus,
    result: labResult,
    authorizationNumber,
  },
  open,
  handleClose
}) => {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const addSubstance = () => ({
    name: '',
    result: 'NEGATIVE',
    baseLevel: 0,
    value: 0,
    confirmLevel: 0
  });

  const formInitValues = {
    _id,
    status: labStatus,
    result: labResult,
    substance: []
  };

  const [attachedFiles, setAttachedFiles] = useState([]);

  const [editLab, labQuery] = useMutation(EDIT_LAB_RESULT, {
    refetchQueries: [{ query: GET_LABS }],
    onCompleted: result => {
      // console.log('[Labs] editLab Mutation result: ', result);

      enqueueSnackbar('Lab Result saved', { variant: 'success' });

      dispatch(
        editLabResults(_.get(
          result,
          ['editLab', '_id']),
          null
        )
      );

      handleClose();
    },
    onError: e => {
      enqueueSnackbar(e && e.message
        ? e.message
        : 'Error: Lab Result not saved.', { variant: 'error' });
    }
  });

  const [addLabDetailedResultEntry, addResultQuery] = useMutation(ADD_LAB_DETAILS, {
    refetchQueries: [{ query: GET_LABS }],
    onCompleted: result => {
      // console.log('add lab detail ======> ', result);

      if (result.addLabDetailedResultEntry) {

        const { substance } = result.addLabDetailedResultEntry

        enqueueSnackbar(`Lab Result details for ${substance} saved`, { variant: 'success' });

        dispatch(
          addLabDetails(_.get(
            result,
            ['addLabDetailedResultEntry', '_id']),
            null
          )
        );

      }
    },
    onError: e => {
      enqueueSnackbar(e && e.message
        ? e.message
        : 'Error: Lab Result details could not saved', { variant: 'error' });
    }
  });

  const isLoading = addResultQuery.loading || labQuery.loading;

  const LabResultSchema = Yup.object().shape({
    result: Yup.string()
      .required('Result is required.'),
    substance: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        baseLevel: Yup.number(),
        value: Yup.number(),
        result: Yup.string(),
        confirmLevel: Yup.number(),
      })
    )
  });

  const handleEnterResults = async (data, { resetForm }) => {

    // TODO: Run query for status before running
    // console.log('[Labs] On Submit Handler - handleEnterResults: ', data);

    // Temporary while backend fixed
    const resultMissed = labResult === 'MISSED';

    // Validators
    const allowedStatus = ['COLLECTED', 'EXTENDED']
    const validateStatusChange = allowedStatus.some(i => i === data.status);
    const validateLabResult = labResult === null || resultMissed;

    if (validateStatusChange && validateLabResult) {

      const labDetailMissing = data.result !== 'NEGATIVE' && data.substance.length === 0;
      const labResultStatus = labDetailMissing ? 'PARTIAL' : 'COMPLETED';

      const labResultEntry = {
        labId: data._id,
        lab: {
          result: data.result,
          status: labResultStatus,
          documents: attachedFiles
        }
      };

      // console.log('[Labs] Raw data for labResultEntry: ', labResultEntry);
      await editLab({ variables: labResultEntry });

    }

    if (data.substance) {

      for (let i = 0; i < data.substance.length; i++) {
        const s = data.substance[i];

        const labEntry = {
          labId: data._id,
          entry: {
            substance: s.name,
            value: Number(s.value),
            baseLevel: Number(s.baseLevel),
            confirmLevel: Number(s.confirmLevel),
            result: s.result
          }
        };

        // console.log('[Labs] addLabDetailedResultEntry: ', labEntry);
        await addLabDetailedResultEntry({ variables: labEntry });
      }
    }

    resetForm();
  };

  const onResultChange = ({ target: { value } }, setFieldValue) => {
    setFieldValue('substance', []);
  };

  const handleFilesChange = files => {
    setAttachedFiles(files);
  };

  const handleClearState = () => {
    console.log('[Labs] Clearing state: attachedFiles ', attachedFiles)
    setAttachedFiles([]);
  }

  const labInfo = [
    { title: 'Date', content: moment(dueDate).format('ll') },
    { title: 'Drug Panel', content: panel.name },
    { title: 'Authorization #', content: authorizationNumber },
  ];

  const formikProps = {
    initialValues: formInitValues,
    validationSchema: LabResultSchema,
    onSubmit: handleEnterResults,
  }

  // Render
  const LabInfoList = () => (
    <div className={styles.resultInfo}>
      {labInfo.map(({ title, content }, i) => (
        <div className={styles.dl} key={i}>
          <div className={cn(styles.dt, styles.section_title)}>{title}</div>
          <div className={styles.dd}>{content}</div>
        </div>
      ))}
    </div>
  );

  const FormRadioToggle = ({ values, handleChange, setFieldValue }) => (
    <div className={styles.dl}>
      <div className={cn(styles.dt, styles.section_title)} style={{ alignSelf: 'flex-start' }}>Test Result</div>
      <div className={styles.dd}>
        <div className="radios">
          <RadioGroup
            className={styles.result}
            name="result" value={values['result']}
            onChange={e => {
              handleChange(e);
              addSubstance();
              onResultChange(e, setFieldValue);
            }}>

            <div className={cn(styles.result_row, styles.result_compliant)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='NEGATIVE' label='Compliant'
                className={styles.result_item} />

              <FormControlLabel
                control={<Radio color="primary" />}
                value='NEGATIVE_DILUTE' label='Compliant Dilute'
                className={styles.result_item} />
            </div>

            <div className={cn(styles.result_row, styles.result_positive)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='POSITIVE' label='Positive'
                className={styles.result_item} />
              <FormControlLabel
                control={<Radio color="primary" />}
                value='POSITIVE_DILUTE' label='Positive Dilute'
                className={styles.result_item} />
            </div>

            <div className={cn(styles.result_row, styles.result_inconclusive)}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value='INCONCLUSIVE' label='Inconclusive'
                className={styles.result_item} />
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={(_, reason) => controlDialogClose(reason, handleClose)}
      onExit={handleClearState}
      disableBackdropClick={true}
      className={styles.dialog}>

      <DialogTitle disableTypography className={styles.dialogTitle}>
        Enter Results for {patient.firstName} {patient.lastName}
        <IconButton aria-label="close" style={{ position: 'absolute', top: '8px', right: '8px' }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className={styles.DialogContent}>

        <LabInfoList />

        <Formik {...formikProps}>
          {({ values, errors, handleChange, setFieldValue }) => {

            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormRadioToggle
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue} />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.section_title} style={{ marginBottom: 10 }}>Lab Details</div>
                    <FieldArray
                      name="substance"
                      render={({ push, remove }) => {

                        return <>
                          {values.substance?.length
                            ? <div className={styles.substance_container}>

                              <Grid container spacing={1} className={styles.substance_row}>
                                <Grid item xs={4}>
                                  <div className={styles.title}>Substance</div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className={styles.title}>Initial <div className={styles.measurement}>ng/ml</div></div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className={styles.title}>Confirm <div className={styles.measurement}>ng/ml</div></div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className={styles.title}>Results <div className={styles.measurement}>ng/ml</div></div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className={styles.title}>Positive</div>
                                </Grid>
                              </Grid>

                              {values.substance.map(({ name, baseLevel, value, result, confirmLevel }, i) => {

                                const isPositive = result === 'POSITIVE';

                                const onSubstanceChanged = value => {
                                  setFieldValue(`substance[${i}].name`, value ? value.name : '');
                                  setFieldValue(`substance[${i}].baseLevel`, value ? value.initial : '');
                                  setFieldValue(`substance[${i}].value`, value ? value.confirm : '');
                                  setFieldValue(`substance[${i}].result`, value ? value.result : '');
                                };

                                return <div key={i}>

                                  <Grid container spacing={1} className={styles.substanceRow}>

                                    <Grid item xs={4}>

                                      <div className={styles.input_row}>
                                        <Field
                                          value={name ? { name, baseLevel, value } : null}
                                          component={SubstanceAutocomplete}
                                          name={`substance[${i}].name`}
                                          onChange={onSubstanceChanged} />
                                      </div>

                                    </Grid>


                                    <Grid item xs={2}>
                                      {baseLevel ?
                                        <div className={cn(styles.input_row, styles.test_values)}>
                                          {baseLevel ? `${baseLevel}` : ''}
                                        </div>
                                        : null}
                                    </Grid>

                                    <Grid item xs={2}>
                                      {value ?
                                        <div className={cn(styles.input_row, styles.test_values)}>
                                          {value ? `${value}` : ''}
                                        </div>
                                        : null}
                                    </Grid>

                                    <Grid item xs={2}>
                                      <div className={styles.input_row}>
                                        <Field
                                          component={TextField}
                                          disabled={!name}
                                          variant="outlined"
                                          onChange={e => {
                                            setFieldValue(`substance[${i}].confirmLevel`, e.target.value);
                                          }}
                                          name={`substance[${i}].confirmLevel`} />
                                      </div>
                                    </Grid>

                                    <Grid item xs={2}>
                                      <Field
                                        component={Switch}
                                        checked={isPositive}
                                        name={`substance[${i}].result`}
                                        onChange={(e) => {
                                          setFieldValue(`substance[${i}].result`, e.target.checked ? 'POSITIVE' : 'NEGATIVE');
                                        }} />
                                    </Grid>

                                  </Grid>

                                  {!name
                                    ? <FormHelperText error>Substance required</FormHelperText>
                                    : null}

                                  {isPositive && !confirmLevel
                                    ? <FormHelperText error>Positive Result levels required</FormHelperText>
                                    : null}

                                </div>
                              })}
                            </div>
                            : null}

                          <Button
                            startIcon={<Add />}
                            disabled={!values['result']}
                            variant='outlined'
                            color='primary'
                            onClick={() => push(addSubstance())}>
                            Add Substance
                      </Button>
                        </>;
                      }} />
                  </Grid>

                  <Grid item xs={12}>
                    <DropZone
                      maxSize={25 * 1024 * 1024} // 25mb
                      style={{ marginTop: '36px' }}
                      title="Attach Lab Report"
                      accept="application/pdf,image/png,image/jpeg,text/plain"
                      onChange={handleFilesChange} />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                      <ButtonLarge
                        variant="text"
                        onClick={handleClose}
                      >Cancel</ButtonLarge>

                      <ButtonLarge
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ width: '230px' }}
                        disabled={isLoading}
                      >{isLoading ? 'Saving...' : 'Confirm Lab Result'}</ButtonLarge>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormDevTools values={values} errors={errors} />
                  </Grid>
                </Grid>

              </Form>
            )
          }}
        </Formik>

      </DialogContent>

    </Dialog>
  )
};

export default EnterLabResults;