const dev = {
  endpoint: 'https://j4g1iv8be5.execute-api.us-east-1.amazonaws.com/dev',
  cognito: {
    USER_POOL_ID: 'us-east-1_yClEsqDmM',
    USER_POOL_WEB_CLIENT_ID: '2kt2tt66el3amtros42tdi6ugv',
  },
  googleAPI: {
    places: {
      API_KEY: 'AIzaSyBZIl4OquvJ9bWHhbka89sjnVtoWQfiDTw',
    },
  },
  gtm: {
    enabled: true,
    code: 'GTM-NF7NXPQ',
  },
  sendbird: {
    APP_ID: '39B1F21B-2151-4CE6-BF95-6E4C594C70DD',
  },
};

const prod = {
  endpoint: 'https://bvzy3c8hq4.execute-api.us-east-1.amazonaws.com/prod',
  cognito: {
    USER_POOL_ID: 'us-east-1_XVG2TZex6',
    USER_POOL_WEB_CLIENT_ID: '6mco5bp09hqjnav196cpqirjqv',
  },
  googleAPI: {
    places: {
      API_KEY: 'AIzaSyBZIl4OquvJ9bWHhbka89sjnVtoWQfiDTw',
    },
  },
  gtm: {
    enabled: false,
    code: 'xxx', //TODO Bogi set this up for production
  },
};


const local = {
  // endpoint: 'https://j4g1iv8be5.execute-api.us-east-1.amazonaws.com/dev',
  endpoint: 'http://localhost:3000',
  cognito: {
    USER_POOL_ID: 'us-east-1_pQsmjj6jx',
    USER_POOL_WEB_CLIENT_ID: '5qk2taf3k9iq0uv8bh6rsvj4pa',
    // USER_POOL_ID: 'us-east-1_yClEsqDmM',
    // USER_POOL_WEB_CLIENT_ID: '2kt2tt66el3amtros42tdi6ugv',
  },
  googleAPI: {
    places: {
      API_KEY: 'AIzaSyBZIl4OquvJ9bWHhbka89sjnVtoWQfiDTw',
    },
  },
  gtm: {
    enabled: false,
  },
  sendbird: {
    APP_ID: '39B1F21B-2151-4CE6-BF95-6E4C594C70DD',
  },
};
const staging = {
  endpoint: 'https://zrb3qinnef.execute-api.us-east-1.amazonaws.com/staging',
  cognito: {
    USER_POOL_ID: 'us-east-1_pQsmjj6jx',
    USER_POOL_WEB_CLIENT_ID: '5qk2taf3k9iq0uv8bh6rsvj4pa',
  },
  googleAPI: {
    places: {
      API_KEY: 'AIzaSyBZIl4OquvJ9bWHhbka89sjnVtoWQfiDTw',
    },
  },
  gtm: {
    enabled: true,
    code: 'GTM-WH5GX2W',
  },
  sendbird: {
    APP_ID: '4A5C645A-8633-4658-B92D-8329C8A6F502',
  },
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'dev':
    config = dev;
    break;
  case 'local':
    config = local;
    break;
  case 'staging':
    config = staging;
    break;
  default:
    config = staging;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
