import React, {Fragment} from 'react';
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom-v5-compat';

const MemberModule = ({
  children,
  moduleContext,
}) => {

  return (
    <ModuleWrapper context={moduleContext}>
      {children}
      <Outlet />
    </ModuleWrapper>
  )
}

export default MemberModule;

MemberModule.propTypes = {
  isEditing: PropTypes.bool,
  moduleHeader: PropTypes.elementType,
  moduleContext: PropTypes.elementType,
}

const ModuleWrapper = ({context, children}) => {
  const ModuleContext = context ? context : Fragment;

  return(
    <ModuleContext>
      {children}
    </ModuleContext>
  )
};
