import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

//Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import MemberStatus from 'common/components/Member/MemberStatus';
import PatientAlerts from 'common/components/Patient/Alerts/PatientAlerts';

const useStyles = makeStyles(theme => ({
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: '0 15px',
  },
  avatar: {
    width: 85,
    height: 85,
  },
  avatarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginLeft: 25,
  },
  avatarText: {
    color: '#000',
    fontSize: '20px',
    lineHeight: '25px',
    fontFamily: 'Avenir Next Medium',
  },
  smallText: {
    color: '#999',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Avenir Next Regular',
  },
}));

const HeaderAvatar = mainAvatarProps => {
  const classes = useStyles();
  const { firstName, lastName, mrn, emrAlerts, memberStatus, lastActive } = mainAvatarProps;
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className={classes.avatarBox}>
      <MemberAvatar {...mainAvatarProps} />
      <div className={classes.avatarContent}>
        <div className={classes.avatarText}>{fullName}</div>
        {mrn ? <div className={classes.smallText}>MRN: {mrn}</div> : null}
        {memberStatus ? <MemberStatus status={memberStatus} lastActive={lastActive} /> : null}

        {emrAlerts ? <PatientAlerts data={emrAlerts} /> : null}
      </div>
    </div>
  );
};

HeaderAvatar.propTypes = {
  mainAvatarProps: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mrn: PropTypes.string.isRequired,
    emrAlerts: PropTypes.array,
    memberStatus: PropTypes.string,
    lastActive: PropTypes.string,
  }),
};

HeaderAvatar.defaultProps = {
  mainAvatarProps: null,
};

export default HeaderAvatar;
