import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Chip } from '@material-ui/core';
import styles from './FormikAutocomplete.module.scss';
import { stringCapitalize } from 'common/helpers/textHelpers';
import _ from 'lodash';

const RenderACInput = ({ label, props }) => <TextField {...props} fullWidth variant="outlined" label={label} />;

const RenderTags = ({ tags }) => (
  <div className={styles.tagsWrapp}>
    {tags.map((tag, i) => (
      <Chip key={i} size="small" className={styles.tag} label={stringCapitalize(tag)} />
    ))}
  </div>
);

const FormikAutocomplete = p => {
  console.log('FormikAutocomplete', p)
  const { name, label, options, setFieldValue, value, hasObjects = false, multiple = false } = p;
  const autocompleteProps = {
    name,
    value,
    options,
    multiple,
    filterSelectedOptions: true,
    classes: { inputRoot: styles.autocomplete },
    renderTags: tags => <RenderTags tags={tags} />,
    renderOption: option => option,
    onChange: (e, selected) => setFieldValue(name, selected),
    renderInput: props => <RenderACInput label={label} props={props} />,
  };

  const hasObjectProps = hasObjects ? { filterOptions: o => o } : {};

  return <Autocomplete {...autocompleteProps} {...hasObjectProps} />;
};

export default FormikAutocomplete;
