import React from 'react';
import { useSelector } from 'react-redux';
import { checkIfUserHasPermissionToAction } from 'selectors';

// Material UI
import { Box, Typography } from '@material-ui/core';

// DX-Scheduler
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

// UI Components
import ReactSVG from 'react-svg';
import styles from './Appointments.module.scss';
import OutlookIcon from 'common/assets/outlook.svg';
import GoogleIcon from 'common/assets/google-calendar.svg';
import InRecoveryIcon from 'common/assets/inrecovery-round.svg';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

// Utils
import { generateColors } from 'common/helpers/textHelpers';
import { truncateString } from 'common/helpers/dataHelpers';
import { getMemberById } from 'context/Members/MembersContext';
import useMembersContext from 'context/Members/useMembersContext';
import useAccountContext from 'context/Account/useAccountContext';

// Cell Colors
const cellColors = ['#EBEBEB', '#EDA9F3', '#A9D9F3', '#D7F3A9', '#F3B6A9', '#A9BEF3'];

const MultiSchedulerAppointmentLayout = ({ classes, data, style, children, ...restProps }) => {
  // Redux
  const {staff} = useMembersContext()
  const { userId } = useAccountContext()
  const hostUser = getMemberById(staff, data.host);
  
  const canViewDetails = useSelector(state =>
    checkIfUserHasPermissionToAction(state, {
      action: 'VIEW_EXTERNAL_CALENDAR_DETAILS',
      isStaff: true,
      user: userId,
    })
  );

  // Appointment Cell Icon
  const appointmentType = data?.type;
  const typeIcon =
    appointmentType === 'outlook' ? OutlookIcon : appointmentType === 'google' ? GoogleIcon : InRecoveryIcon;
  const external = appointmentType === 'outlook' || appointmentType === 'google';
  const myActivity = data.host === userId;
  const showNoDetails = external && !myActivity && !canViewDetails;
  // Avatar and Colors
  const { image, firstName, lastName } = hostUser;
  const userInitials = `${firstName?.[0]}${lastName?.[0]}`;

  const avatarProps = {
    image,
    firstName,
    lastName,
    disabled: true,
    size: 'xsmall',
  };

  // Appointment Cell Setup
  const userCellColor = generateColors(cellColors, userInitials);
  const scheduleCellProps = {
    ...restProps,
    data,
    type: data.type,
    onDoubleClick: null,
    ...(showNoDetails ? { onClick: null } : {}),
    className: styles.external_appointment_multi,
    style: {
      ...style,
      border: `1px solid ${userCellColor}`,
      borderLeft: `10px solid ${userCellColor}`,
      background: `
        linear-gradient(${userCellColor}55, ${userCellColor}55), 
        linear-gradient(#fff,#fff)
        `,
      // solution for blended cells: create solid white background to prevent blending
    },
  };

  return (
    <Appointments.Appointment {...scheduleCellProps}>
      <Box className={styles.wrapper}>
        <MemberAvatar {...avatarProps} />
        {showNoDetails ? null : <Typography variant="caption">{truncateString(data?.title, 40)}</Typography>}
      </Box>
      <ReactSVG src={typeIcon} className={styles.icon} />
    </Appointments.Appointment>
  );
};

export default MultiSchedulerAppointmentLayout;
