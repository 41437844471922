import React, { useState, useEffect, useRef } from 'react';
import { ButtonWithIcon } from '../Button';
import styles from './SearchButton.module.scss';
import SearchIcon from '../../../assets/search.svg';
import SearchInput from '../../InputFields/SearchInput/SearchInput';

// TODO implement auto-suggest

const SearchButton = ({ onChange, hideText }) => {
  const [open, setOpen] = useState(false);
  const inputEl = useRef(null);

  useEffect(() => {
    if (open) {
      try {
        inputEl.current.focus();
      } catch (error) { console.log('error', error) }
    }
  }, [open]);

  return (
    <div className={styles.root}>
      {open ? (
        <SearchInput inputRef={inputEl} setOpen={setOpen} onChange={onChange || null}/>
      ) : (
        <ButtonWithIcon
          icon={SearchIcon}
          onClick={() => setOpen(true)}
          classes={styles.search_button}
        >{hideText && 'Search'}</ButtonWithIcon>
      )}
    </div>
  )
};

export default SearchButton