// Auth
import Login from '../screens/Auth/main';
import ForgotPassword from '../screens/Auth/ForgotPassword/main';
import OutlookAuth from '../screens/Auth/Integrations/Outlook';

// Dashboards
import { DashRouter } from './navigation/dashboardRouter';
import { default as LabDashboard } from 'screens/Dashboards/UDSLab';

// Modules
import PatientList from '../screens/Patient/List/List';
import PatientMember from '../screens/Patient/';
import PatientEdit from '../screens/Patient/Edit/';
import DischargedPatientList from '../screens/Patient/List/Discharged';
import MonitoringPatientList from '../screens/Patient/List/Monitoring';
import IntakePatientList from '../screens/Patient/List/Intake';

import FamilyList from '../screens/Family/List/List';
import FamilyMember from '../screens/Family/';
import FamilyMemberEdit from '../screens/Family/Edit/Edit';
import DischargedFamilyList from '../screens/Family/List/Discharged';

import StaffList from '../screens/Staff/List/List';
import StaffMember from '../screens/Staff/';
import StaffItemEdit from '../screens/Staff/Edit/Edit';

import Schedule from '../components/Scheduler/main';
import Messages from '../screens/Messages';
import Labs from '../screens/Labs';
import Reports from '../screens/Reports';

// Utils
import MyAccount from '../components/MyAccount/main';
import PageNotFound from '../screens/ErrorPages/PageNotFound';

import HelpDesk from '../screens/Support/HelpDesk';

//admin
import { default as MemberDashboard } from 'screens/Admin/Report/Member';
import DirectorDashboard from 'components/Dashboards/Director/DirectorDashboard';

//!IMPORTANT: data.name is mandatory
export const routeSettings = [
  {
    path: '/',
    component: DashRouter,
    exact: true,
    data: {
      authenticationNeeded: true,
      name: 'root',
    },
  },
  {
    path: '/dashboard', // To Do: Dynamically serve using DashRouter
    exact: true,
    component: DashRouter,
    data: {
      authenticationNeeded: true,
      name: 'dashboard',
    },
  },
  {
    path: '/dashboard-lab', // To Do: Dynamically serve using DashRouter
    component: LabDashboard,
    exact: true,
    data: {
      authenticationNeeded: true,
      role: 'LAB',
      name: 'dashboardLabs',
    },
  },
  {
    path: '/dashboard-director', // To Do: Dynamically serve using DashRouter
    exact: true,
    component: DirectorDashboard,
    data: {
      authenticationNeeded: true,
      role: 'DIRECTOR',
      name: 'dashboardDirector',
    },
  },
  {
    path: '/family',
    exact: true,
    component: FamilyList,
    data: {
      authenticationNeeded: true,
      name: 'familyList',
    },
  },
  {
    path: '/family/discharged',
    basename: '/family/discharged',
    exact: true,
    component: DischargedFamilyList,
    data: {
      authenticationNeeded: true,
      name: 'dischargedFamilyList',
    },
  },
  {
    path: '/family/:id/edit',
    basename: '/family',
    component: FamilyMemberEdit,
    data: {
      authenticationNeeded: true,
      name: 'familyEdit',
    },
  },
  {
    path: '/family/:id',
    basename: '/family',
    component: FamilyMember,
    data: {
      authenticationNeeded: true,
      name: 'familyMember',
    },
  },
  {
    path: '/patients',
    basename: '/patients',
    exact: true,
    component: PatientList,
    data: {
      authenticationNeeded: true,
      name: 'patientList',
    },
  },
  {
    path: '/patients/monitoring',
    basename: '/patients/monitoring',
    exact: true,
    component: MonitoringPatientList,
    data: {
      authenticationNeeded: true,
      name: 'monitoringPatientList',
    },
  },
  {
    path: '/patients/discharged',
    basename: '/patients/discharged',
    exact: true,
    component: DischargedPatientList,
    data: {
      authenticationNeeded: true,
      name: 'dischargedPatientList',
    },
  },
  {
    path: '/patients/intake',
    basename: '/patients/intake',
    exact: true,
    component: IntakePatientList,
    data: {
      authenticationNeeded: true,
      name: 'intakePatientList',
    },
  },
  {
    path: '/patients/:id',
    basename: '/patients',
    component: PatientMember,
    data: {
      authenticationNeeded: true,
      name: 'patientMember',
    },
  },
  // {
  //   path: '/patients/:id/edit',
  //   basename: '/patients',
  //   component: PatientEdit,
  //   data: {
  //     authenticationNeeded: true,
  //     name: 'patientEdit',
  //   },
  // },
  {
    path: '/staff',
    basename: '/staff',
    exact: true,
    component: StaffList,
    data: {
      authenticationNeeded: true,
      name: 'staffList',
    },
  },
  {
    path: '/staff/:id/edit',
    basename: '/staff',
    component: StaffItemEdit,
    data: {
      authenticationNeeded: true,
      name: 'staffEdit',
    },
  },
  {
    path: '/staff/:id',
    basename: '/staff',
    component: StaffMember,
    data: {
      authenticationNeeded: true,
      name: 'staffMember',
    },
  },
  {
    path: '/messages/:roomId?',
    basename: '/messages',
    component: Messages,
    data: {
      authenticationNeeded: true,
      name: 'messages',
      collapsedNav: true,
    },
  },
  {
    path: '/labs',
    component: Labs,
    data: {
      authenticationNeeded: true,
      name: 'labs',
    },
  },
  {
    path: '/schedule',
    exact: true,
    component: Schedule,
    data: {
      authenticationNeeded: true,
      name: 'schedule',
    },
  },
  {
    path: '/assesments',
    component: PageNotFound,
    data: {
      authenticationNeeded: true,
      name: 'assesments',
    },
  },
  {
    path: '/help',
    component: HelpDesk,
    data: {
      authenticationNeeded: true,
      name: 'helpDesk',
    },
  },
  {
    path: '/admin',
    component: MemberDashboard,
    data: {
      authenticationNeeded: true,
      name: 'admin',
    },
  },
  {
    path: '/reports',
    component: Reports,
    data: {
      authenticationNeeded: true,
      name: 'reports',
    },
  },
  {
    path: '/login',
    component: Login,
    data: {
      authenticationNeeded: false,
      name: 'login',
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    data: {
      authenticationNeeded: false,
      name: 'forgotPassword',
    },
  },
  {
    path: '/account',
    basename: '/account',
    component: MyAccount,
    data: {
      name: 'account',
    },
  },
  {
    path: '/outlookAuth',
    basename: '/outlookAuth',
    component: OutlookAuth,
    data: {
      name: 'outlookAuth',
    },
  },
];

const publicRoutesDef = {};
routeSettings
  .filter(route => !route.data.authenticationNeeded)
  .forEach(route => {
    publicRoutesDef[route.data.name] = route.basename ? route.basename : route.path;
  });

export const publicRoutes = publicRoutesDef;

const routes = {};
routeSettings
  .filter(route => route.data.authenticationNeeded)
  .forEach(route => {
    routes[route.data.name] = route.basename ? route.basename : route.path;
  });

export default routes;
