import React from 'react';
import Item from './Item';
// import styles from './Urgent.module.scss'
import MaterialList from '@material-ui/core/List';
import PropTypes from 'prop-types';

const List = ({ notifications, ...restProps }) => (
  <MaterialList disablePadding>
    {notifications.map((n, i) => (
      <Item notification={n} key={n._id} {...restProps} />
    ))}
  </MaterialList>
);

List.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default List;
