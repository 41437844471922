import React, { useState } from 'react';

// Data
import useGuideContext from 'context/Guides/useGuideContext';
import useAccountContext from 'context/Account/useAccountContext';

// Material-UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../Support.module.scss';

// Icons
import ReactSVG from 'react-svg';
import GetStartedIcon from 'common/assets/icon-start.svg';

// Screenshots
import ScreenAvatar from 'common/assets/guide/screenshot_profile-avatar.png';
import ScreenPhone from 'common/assets/guide/screenshot_profile-phone.png';
import ScreenOutlookSync from 'common/assets/guide/screenshot_outlook-sync.png';
import ScreenWorkingHours from 'common/assets/guide/screenshot_working-hours.png';
import ScreenWebEx from 'common/assets/guide/screenshot_webex-link.png';

// Components
import DialogTitle from 'common/components/Dialog/DialogTitle';
import { CheckListItem } from 'common/components/Support/SetupCheckList';
import SetupProgress from 'common/components/Support/SetupProgress';
import CompletedGuide from './CompletedGuide';

const taskImages = [ScreenAvatar, ScreenPhone, ScreenOutlookSync, ScreenWorkingHours, ScreenWebEx];

const SetupWelcome = () => {
  // Context Data
  const {
    totalSteps,
    progressMessage,
    handleRouteTo,
    isSetupComplete,
    welcomeOpen, setWelcomeOpen,
    setSetupGuideOpen,
    completedSteps,
    setupProgress,
    setActiveStep,
    nextStep,
    setupGuideConfig,
    setupGuideCheckList,
  } = useGuideContext();
  const {user: currentUser} = useAccountContext();

  // Hooks 
  const classes = useStyles();

  // Internal Data
  const [selectedTask, setSeletedTask] = useState(0);
  const guideConfig = setupGuideConfig;
  const guideList = setupGuideCheckList;

  // TODO: 
  // - Check activeStep if we want to remember and route to active step 
  // - Need to handle clearing activeStep upon leaving Setup Guide
  // const firstStep = activeStep ? activeStep : nextStep;

  // Handlers
  const handleDialogClose = () => {
    setWelcomeOpen(false);
    setSetupGuideOpen(false);
  };

  const handleGetStarted = () => {
    handleDialogClose()
    setSetupGuideOpen(true)
    handleRouteTo(guideList[nextStep].link)
    setActiveStep(nextStep)
  }

  const handleSelectTask = (item) => {
    setSeletedTask(item);
  }
  
  const handleFinish = () => {
    setWelcomeOpen(false)
    setSetupGuideOpen(false)
    handleRouteTo('/dashboard')
  }

  const handleGoToTask = (item, link) => {
    setActiveStep(item)
    setWelcomeOpen(false)
    setSetupGuideOpen(true)
    handleRouteTo(link)
  }

  // Component Props
  const setupProgressProps = {
    setupProgress,
    progressMessage,
    completedSteps,
    totalSteps,
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={welcomeOpen}
      onClose={handleDialogClose}
    >

    <DialogTitle
      title={`Hi ${currentUser?.firstName}! ${guideConfig.welcomeTitle}`}
      onClose={handleDialogClose}
    />

    <DialogContent>
    { !isSetupComplete 
    ?
    <div className={classes.welcomeContent}>
        <div style={{ flex: 1 }}>
          <div className={classes.welcomeTitle}>
            <ReactSVG src={GetStartedIcon} className={styles.setup_icon} /> {guideConfig.welcomeMessage}
          </div>
          <ul>
            {guideList.map((item, i) => {
              const {checkIf, title, description, location, link} = item;
              return(
                <li className={classes.guideList} onClick={() => handleGoToTask(i, link)} onMouseEnter={() => handleSelectTask(i)} key={i}>
                  <CheckListItem 
                    checkIf={checkIf}
                    title={title}
                    description={description}
                    location={location}
                    link={link}
                    showLocation
                  />
                </li>
              )  
            })}
          </ul>
        </div>
        <div 
          className={classes.welcomeImages}
          style={{backgroundImage: `url(${taskImages[selectedTask]})`}}
        >
      </div>
    </div>
    :
      <CompletedGuide handleFinish={handleFinish} />
    }

      <div className={classes.progress}>
        <SetupProgress {...setupProgressProps} />
      </div>
    </DialogContent>

    <DialogActions>
      <div className={classes.actionButtons}>
        <Button color="primary" onClick={handleDialogClose}>
          {guideConfig.exitButton}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGetStarted}
        >
          {setupProgress > 25
            ? `${guideConfig.continueButton} ${nextStep+1}`
            : `${guideConfig.startButton}`}
        </Button>
      </div>
    </DialogActions>
    
    </Dialog>
  );
};

export default SetupWelcome;

const useStyles = makeStyles({
  welcomeContent: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'flex-start', 
    flex: 1
  },
  welcomeTitle: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    fontSize: 18, 
    fontWeight: 'bolder', 
    marginBottom: 30
  },
  guideList: {
    margin: '0 0 25px 15px',
  },
  welcomeImages: {
    display: 'flex',
    height: 350, 
    width: 350, 
    border: '1px solid #ededed',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  progress: {
    marginTop: 20
  },
  actionButtons: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 20px 20px 10px',
  }
});