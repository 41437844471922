import React, { Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const LoadingSkeleton = ({ columns = 3, rows = 8 }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {[...Array(columns).keys()].map((_, i) => (
        <div key={i} className={styles.column}>
          {[...Array(rows).keys()].map((_, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  skeleton: {
    width: '100%',
    height: 30,
  },
  column: {
    width: '100%',
    padding: 10,
  },
});


export default LoadingSkeleton;