import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Material UI
import { Box, Card, Button, CardContent } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import ConditionalComp from 'common/components/Render/ConditionalComp';

const Item = ({
  mrn,
  firstName,
  lastName,
  image,
  emrPic,
  _id,
  email,
  phone,
  type,
  relatedPatients,
  status,
  activatedAccount,
  showRelated,
  hideChat,
  // hideNotes = false,
  triggerDialog,
  baseUrl,
}) => {
  const classes = useStyles();
  const fullName = `${firstName} ${lastName}`;

  console.log('[MemberList] Item',`${firstName} ${lastName}`, 'status', status, 'activatedAccount', activatedAccount)
  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type,
    disabled: true,
    alt: `${firstName}'s avatar`,
  };

  const linkProps = { 
    to: location => ({
      ...location, 
      pathname: `${baseUrl}/${_id}`,
      state: { 
        status,
        activatedAccount,
      },
    })
  }

  const chatProps = {
    color: 'primary',
    variant: 'text',
    className: classes.chat,
    startIcon: <ChatOutlinedIcon />,
    component: Link,
    to: `${baseUrl}/${_id}/messaging`,
  };

  const notesProps = {
    color: 'primary',
    variant: 'text',
    className: classes.chat,
    startIcon: <AssignmentOutlinedIcon />,
    component: Link,
    to: `${baseUrl}/${_id}/notes`,
  };

  // const activateProps = {
  //   color: 'primary',
  //   variant: 'text',
  //   className: classes.chat,
  //   startIcon: <AddOutlinedIcon />,
  // };

  // const activateData = {
  //   _id,
  //   mrn,
  //   firstName,
  //   lastName,
  //   email,
  //   phone,
  //   type,
  // }

  //const handleActivatePopup = useCallback(() => triggerDialog(activateData), [activateData, triggerDialog]);
  // const activatePermission = false;

  const renderRelatedPatients = showRelated && !!relatedPatients;

  return (
    <Link {...linkProps}>
      <Card className={classes.root}>
        <CardContent className={classes.content}>

          <MemberAvatar {...avatarProps} />
          <div className={classes.fullName}>{fullName}</div>
          
          <ConditionalComp
            condition={mrn}
            Component={() => <div className={classes.smallText}>{mrn}</div>}
          />

          {/* <div style={{display: 'flex', flexDirection: 'row'}}>
            <ConditionalComp 
              condition={activatedAccount}
              Component={() => <>{<Button {...notesProps} children="Notes" />}</>}
            />
            <ConditionalComp 
              condition={activatedAccount}
              Component={() => <>{!hideChat && <Button {...chatProps} children="Chat" />}</>}
              FalseComp={() => <p className={classes.activationNotice}>Not Activated</p>}
            />
          </div>
          <div>
            <ConditionalComp 
              condition={!activatedAccount & activatePermission}
              Component={() => <>{<Button onClick={handleActivatePopup} {...activateProps} children="Activate" />}</>}
            />
          </div> */}
          
          <ConditionalComp
            condition={renderRelatedPatients}
            Component={() => <>
              <div className={classes.divider} />
              <RelatedMembersMemo {...{ relatedPatients }} />
            </>}
          />

        </CardContent>
      </Card>
    </Link>
  );
};

Item.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default Item;

const RelatedMembers = ({ relatedPatients }) => {
  // Hooks
  const classes = useStyles();

  const isEmpty = !relatedPatients?.length;
  const patientCount = relatedPatients?.length;
  const multiPatient = patientCount > 1 ? `s (${patientCount})` : null;

  if (isEmpty) return null;

  return (
    <div style={{ width: '80%' }}>
      <Box display="flex" mt={1}>
        <div className={classes.capTitle}>Related Patient{multiPatient}</div>
      </Box>
      <Box display="flex" mt={1}>
        <div>
          {relatedPatients.slice(0, 2).map((member, i) => {
            const fullName = `${member?.firstName} ${member?.lastName?.[0]}`;
            const lastItem = relatedPatients?.length - 1 === i;

            return (
              <Box {...(!lastItem ? { style: { marginBottom: 6 } } : {})} display="flex" alignItems="center" key={i}>
                <RelatedAvatar member={member} />
                <div style={{ fontSize: 14, marginLeft: 6 }}>{fullName}</div>
              </Box>
            );
          })}
        </div>
      </Box>
    </div>
  );
};
const RelatedMembersMemo = React.memo(RelatedMembers)

const RelatedAvatar = ({member}) => {
  const { _id, type, image, firstName, lastName, emrPic } = member;

  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type,
    disabled: true,
    size: 'xxsmall'
  };
  return <MemberAvatar {...avatarProps} />
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #e0e0e0',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '28.5px 0',
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginBottom: '16px',
  },
  fullName: {
    color: '#000',
    fontSize: '18px',
    marginTop: '16px',
  },
  smallText: {
    color: '#ccc',
    fontSize: '14px',
    marginTop: 10,
    fontFamily: 'Avenir Next Regular',
  },
  chat: {
    marginTop: 10,
    color: '#2A96FF !important',
    fontFamily: 'Avenir Next Regular',
    '& svg': {
      fill: '#ccc',
    },
  },
  capTitle: {
    fontSize: '11px',
    color: 'rgba(0,0,0,0.1)',
    textTransform: 'uppercase',
  },
  activationNotice: {
    color: '#ccc',
    fontSize: '14px',
    marginTop: '23px',
  },
  divider: {
    height: '1px',
    width: '100%',
    display: 'flex',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: '#e0e0e0',
  },
});