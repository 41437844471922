import {gql} from "@apollo/client";

export const GET_GOOGLE_PLACE_DETAILS = gql`
   query get($id: String!) {
      findPlaceDetails(id: $id) {
         street1
         street2
         city
         zip
         state
         country {
            code
            name
         }
      }
   }
`;