import React, {useState} from 'react';
import styles from './TextInput.module.scss'
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const TextInput = ({placeholder, label, ...props}) => {
    const [text, setText] = useState('')

    const handleChange = e => {

        setText(e.target.value)
    }
    return (
        <TextField id="outlined-name"
        label={label}
        className={styles.wrapper}
        value={text}
        onChange={e => handleChange(e.target.value)}
        placeholder={placeholder}
        variant="outlined"
        {...props}
        />
    );
}

TextInput.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string
}

export default TextInput;