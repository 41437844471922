import React, {useEffect, useRef} from "react";
import Amplify from "aws-amplify";
import config from './config/config';

// UI
import {SnackbarProvider} from "notistack";
import {Button} from "@material-ui/core";
import {MuiThemeProvider} from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import customTheme from 'common/helpers/muiTheme';

// Nav
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from "react-router-dom-v5-compat";

// Components
import AuthLoader from './AuthLoader';

// Context
import AuthNContext from "context/Auth/AuthNContext";

const App = () => {
  
  const notistackRef = useRef();

  useEffect(() => {
    Amplify.configure({
        Auth: {
          mandatorySignIn: false,
          region: 'us-east-1',
          userPoolId: config.cognito.USER_POOL_ID,
          userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID,
        },
      });
  }, [])

  const onClickDismiss = key => {
    console.log('onClickDismiss key:', key);
    notistackRef.current.closeSnackbar(key);
  };

  const notificationAction = key => (
    <Button onClick={() => onClickDismiss(key)}>
      <CloseIcon style={{ color: "#fff" }} />
    </Button>
  )

  const SnackbarProviderProps = {
    maxSnack: 4,
    ref: notistackRef,
    hideIconVariant: true,
    action: notificationAction,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
  }

  console.log('[App] SnackbarProviderProps: ', SnackbarProviderProps);

  return (
    <MuiThemeProvider theme={customTheme}>
      <BrowserRouter>
        <CompatRouter>
          <AuthNContext>
            <SnackbarProvider {...SnackbarProviderProps}>
                <AuthLoader />
            </SnackbarProvider>
          </AuthNContext>
        </CompatRouter>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
