import { gql } from '@apollo/client'
export const USER_FIELDS = `
    user {
      _id
      firstName
      lastName
      title
      image
      email
      phone
      webexLink
      phone
      account {
        hasOutlookToken
        hasGoogleToken
        openingHours {
          monday {
            closed
            from
            to
          }
          tuesday {
            closed
            from
            to
          }
          wednesday {
            closed
            from
            to
          }
          thursday {
            closed
            from
            to
          }
          friday {
            closed
            from
            to
          }
          saturday {
            closed
            from
            to
          }
          sunday {
            closed
            from
            to
          }
        }
        userGuides {
          code
          status
          lastActiveStep
        }
      }
      termsAndConditionsAccepted
      termsAndConditionsAcceptedOn
      permissions {
        moduleActions {
          code
          actions
        }
        patientIds
        staffIds
        UI
        code
      }
      careTeam {
        _id
        firstName
        lastName
        image
        emrPic
        type
      }
    }
`
export const ORGANIZATION_FIELDS = `
  organization {
    _id
    name
    templateCategories {
      name
    }
    termsAndConditions
    setup {
      value
      name
    }
    locations {
      _id
      name
    }
    program {
      _id
      caronId
      name
      displayName
    }
    levelOfCare {
      _id
      caronId
      name
    }
    familyRelease {
      _id
      caronId
      name
    }
    labPanels {
      name
      _id
      caronId
    }
    userGuides {
      _id
      code
      name
    }
    noteSetup {
      displayName
      types {
        code
        displayName
        subTypes {
          displayName
          code
          description
          memberTypes
          fields {
            name
            type
            timeline
            options
            title
          }
        }
      }
    }
  }
`

export const ENUM_FIELDS = `
  enumValues {
    name
    values
  }
`

export const GET_DASHBOARD_DATA_PRELOAD = gql`
  query dashBoardData {
    getDashboardData {
      ${USER_FIELDS}
      ${ORGANIZATION_FIELDS} 
    }
  }
`
export const ACCEPT_TERMS_AND_CONDITION_AGREEMENT = gql`
  mutation AcceptAgreement {
    acceptTermsAndConditions {
      _id
      termsAndConditionsAccepted
      termsAndConditionsAcceptedOn
    }
  }
`

export const GET_ENUMS_PROCESS_TYPES = gql`
  query processTypesEnum {
    __type(name: "ProcessTypeEnum") {
      enumValues {
        name
        description
      }
    }
  }
`
