import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Schedule from './Schedule/Schedule';
import LatestActivities from './LatestActivities/LatestActivities';

import moment from 'moment';

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    border: '1px solid #E0E0E0',
  },
  title: {
    height: 64,
    display: 'flex',
    fontSize: '18px',
    padding: '0 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    fontFamily: 'Avenir Next Demi Bold',
  },
  body: {
    height: 425,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  encounters: {
    height: 400,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  }
});


const ProfileBody = ({ _id, showSchedule, firstName, mrn, episodeOfCare, showEpisodes = false}) => {
  
  const classes = useStyles();

  if (showSchedule) {
    return <Schedule {...{firstName}} id={_id}/>
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <div className={classes.title}>Latest Activity</div>
        <div className={classes.body}>
          <LatestActivities id={_id}/>
        </div>
      </Paper>
      {showEpisodes && <EpisodeView episodeOfCare={episodeOfCare}/>}
    </React.Fragment>
  )
}

export default ProfileBody;

const EpisodeView = ({episodeOfCare}) => {

  const classes = useStyles();

  return <Paper className={classes.root} style={{marginTop: 25}} elevation={0}>
    <div className={classes.title}>Episodes of Care</div>
    <div className={classes.encounters}>
      {episodeOfCare.map((episode, i) => {

        return (
          <div key={i} style={{border: '1px solid #ccc', borderLeft: '5px solid lightblue', padding: 10, margin: 20}}>
            <div>Episode ID: {episode.episodeId}</div>
            <div>
              Start Date: {moment(episode.period.startDate).format('LL')} <br/>
              End Date: {episode.period.endDate !== null && moment(episode.period.endDate).format('LL')}
            </div>
            <div>Status: {episode.status}</div>
            <div style={{marginTop: 10}}>
              Encounters
              {episode.encounters.map((encounter, i) => {
                return <div style={{border: '1px solid #ddd', borderLeft: '5px solid #ddd',borderRadius: 5, padding: 10, margin: 10}}>
                <div>
                  Encounter ID: {encounter.encounterId}
                </div>
                <div>Program: {encounter.propram?.name} ({encounter.propram?.caronId})</div>
                <div>
                  Start Date: {moment(encounter.period.startDate).format('LL')} <br/>
                  End Date: {episode.period.endDate !== null && moment(episode.period.endDate).format('LL')}
                </div>
                </div>
              })
              }
            </div>
            {/* <pre style={{color: '#ccc'}}>{JSON.stringify(episode, null, 2)}</pre> */}
          </div>
        )
      })
      }
    </div>
  </Paper>
}