/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete } from './Autocomplete';

export const Multiselect = ({
  control,
  name,
  defaultValue = [],
  required = false,
  items,
  labelKey,
  style,
  errorText,
  loading,
  maxLength = 5,
  ...props
}) => {
  const classes = useStyles();
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  // if (loading) return <Loading />;

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        loading={loading}
        style={style}
        options={items || []}
        defaultValue={defaultValue}
        {...inputProps}
        {...props}
        onSelect={(event, value) =>
          onChange({
            ...event,
            target: {
              value: value.slice(0, maxLength),
            },
          })
        }
        // renderInput={params => (
        //   <TextField
        //     {...params}
        //     variant="outlined"
        //     inputRef={ref}
        //     error={invalid}
        //     helperText={error?.message}
        //     size="small"
        //     {...props}
        //   />
        // )}
        renderTags={value =>
          value.map((option, index) => (
            <Chip
              key={index}
              data-tag-index={index}
              tabIndex={-1}
              label={option?.label || option}
              className={classes.chip}
            />
          ))
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  chip: {
    margin: '0 3px',
  },
});

Multiselect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  required: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelKey: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  errorText: PropTypes.string,
};
