import React from 'react';
import {
  Tooltip, 
  withStyles,
} from '@material-ui/core';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        padding: "20px",
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default HtmlTooltip;