import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

// Material UI
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// GraphQL
import { useMutation } from '@apollo/client';
import {CANCEL_DISCHARGE_MEMBER, RESEND_ACTIVATE_MEMBER} from 'data/graphql/member';
import { Messages } from 'config/settings';

// Permissions
import {useAuthZContext} from 'context/Auth/useAuthContext';
import {checkPermissions} from 'data/permissions';

const UINote = ({ 
    type, 
    title, 
    message, 
    actions, 
    data,
    refetch,
    handleDischargeOpen,
}) => {
    
    // Hooks
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    
    // Data
    const {permissions} = useAuthZContext();

    // Setup
    const isDischarging = actions?.includes("EDIT_PATIENT_DISCHARGE");
    const isCancelling = actions?.includes("EDIT_PATIENT_CANCEL_DISCHARGE");
    const isPendingApp = actions?.includes("RESEND_INVITE");

    // Mutations
    const [cancelDischargePatient] = useMutation(
        CANCEL_DISCHARGE_MEMBER, {
            variables: { 
                memberId: data?._id,
            },
            onCompleted: () => {
                enqueueSnackbar('Discharge has been canceled', { variant: 'success' })
                setTimeout(() => {
                    refetch();
                }, 250);
            },
            onError: (e) => {
                console.log("CANCEL_DISCHARGE_MEMBER", e);
                enqueueSnackbar('Error during cancel', { variant: 'error' })
            },
        }
    );

    const handleSendAppInvite = () => resendAppInvite();

    const [resendAppInvite, { error: resendError }] = useMutation(
            RESEND_ACTIVATE_MEMBER, 
            {
            variables: { memberId: data?._id },
            onCompleted: ({ resendCognitoInvitation }) => {
                if (resendCognitoInvitation) {
                    enqueueSnackbar(`${Messages.MEMBER_RESEND_INVITATION_SUCCESS} to: ${data?.email}`, {
                        variant: 'success',
                    });
                    console.log('[UI Note Actions] Sending App Invite')
                }
            },
            onError: (error) => {
                enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {variant: 'error'})
                console.log('[UI Note Actions] Error Sending App Invite', error, 'useMutation resendError', resendError)
            },
        }
    );

    // Action Setup
    const dischargeData = {
        name: "Discharge",
        onClick: isDischarging ? handleDischargeOpen : null,
        permission: {
            module: "PATIENTS",
            memberId: data?._id,
            memberType: data?.type,
            perform: "EDIT_PATIENT_DISCHARGE"
        }
    };
    const cancelDischargeData = {
        name: "Cancel Discharge",
        onClick: isCancelling ? cancelDischargePatient : null,
        permission: {
            module: "PATIENTS",
            memberType: data?.type,
            memberId: data?._id,
            perform: "EDIT_PATIENT_CANCEL_DISCHARGE"
        }
    };
    const pendingAppData = {
        name: Messages.MEMBER_RESEND_INVITATION_BUTTON,
        onClick: isPendingApp ? handleSendAppInvite : null,
        // permission: {
        //     module: "PATIENTS",
        //     memberType: data?.type,
        //     memberId: data?._id,
        //     perform: "INVITE_MEMBER_APP"
        // }
    };
    
    // Action List
    const actionList = [
        isDischarging ? dischargeData : null,
        isCancelling ? cancelDischargeData : null,
        isPendingApp ? pendingAppData : null,
    ]?.filter(Boolean);

    console.log('[UINote] actionList', actionList)
    
    // Permission Filter
    const permissionFilter = (action) => {
        console.log('[UINote] checking permissions for action', action)
        if (!action.permission) return true;
        return checkPermissions({
            permissions, 
            module: action.permission.module,
            action: action.permission.perform,
            memberType: action.permission.memberType,
            memberId: action.permission.memberId,
            policy: 'all',
            origin: 'UI Note > Action'
        });
    }
    const filteredActions = actionList?.filter(action => permissionFilter(action))
    console.log('[UINote] filteredActions', filteredActions);

    // Components
    const showActions = actionList.length > 0 || filteredActions.length > 0;

    return (
    <>
        <div className={classes.wrapper}>
            <Alert 
                severity={type} 
                action={showActions ? <ActionButtons data={actionList} /> : null}
            >
                {title && <AlertTitle>{title}</AlertTitle>}
                <div className="message">{message}</div>
            </Alert>
        </div>
    </>
    );
};

export default UINote;


const ActionButtons = ({data = []}) => {
    const classes = useStyles();
    return data?.map((action, i) => 
        <Button key={i} onClick={action?.onClick} className={classes.actionButton}>
            {action?.name}
        </Button>
    )
}

// Styles
const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderBottom: '1px solid #E0E0E0',
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    actionButton: {
        backgroundColor: 'rgba(255,255,255,0.75)', 
        marginRight: 5,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));