import React from 'react';
import { TextField } from '@material-ui/core';

export const BaseInput = React.forwardRef(({ invalid, error, disabled, inputProps, ...props }, ref) => (
  <TextField
    inputProps={inputProps}
    {...props}
    inputRef={ref}
    variant="outlined"
    size="small"
    error={invalid}
    helperText={error?.message}
    disabled={disabled}
  />
));
