import React, {Fragment} from 'react';

// Components
import TermsAndConditions from 'screens/Auth/TermsAndConditions/TermsAndConditions';

// Data
import {useAuthNContext} from 'context/Auth/useAuthContext';
import useAccountContext from 'context/Account/useAccountContext';

const AgreementChecker = () => {
  const {isAuthenticated} = useAuthNContext();
  const {termsAccepted} = useAccountContext()

  const showTerms = isAuthenticated && !termsAccepted; 

  return (
  <Fragment>
    {showTerms ? <TermsAndConditions /> : null}
  </Fragment>
  )
};

export default AgreementChecker;