import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from '../../Reports.module.scss';

const ReportCard = ({ type, _id, name, description }) => {

    let cardTypeClass = '';

    switch (type) {
        case 'PATIENT':
            cardTypeClass = styles['card-report--patient']
            break;

        case 'LAB':
            cardTypeClass = styles['card-report--lab']
            break;

        case 'ADMIN':
            cardTypeClass = styles['card-report--admin']
            break;

        case 'COMPLIANCE':
            cardTypeClass = styles['card-report--compliance']
            break;
        default:
            console.log("type not found") 
            break;
    }

    const rootClass = classNames(styles['card-report'], cardTypeClass);

    return (
        <div className={rootClass}>
            <div className={styles['card-title']}>{name}</div>
            <div className={styles['card-text']}>{description}</div>
        </div>
    )
}

ReportCard.propTypes = {
    type: PropTypes.oneOf(['PATIENT', 'LAB', 'ADMIN', 'COMPLIANCE']).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default ReportCard;
