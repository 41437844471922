import React, {useState, useEffect} from 'react';
import NotesList from './NotesList';

// Data
import { useMemberStateContext } from 'context/Member/useMemberContext';
import { mergeEpisodeNotes } from '../common/utils';

const Notes = () => {
      // Data: State
    const {
        memberData: data,
        isPatient,
        isDischarged,
        hasMultiEpisodes,
        hasPastEpisode,
    } = useMemberStateContext();
    
    // State
    const [mergedNotes, setMergedNotes] = useState([]);
    
    // Handlers
    const handleNotesMerge = async (data = []) => {
        const allNotes = mergeEpisodeNotes(data);
        setMergedNotes(allNotes);
    };

    // Effect: Note Setup
    useEffect(() => {
        if (isPatient && isDischarged) {
        console.log('Discharged member');
        handleNotesMerge(data);
        } else if (isPatient && hasMultiEpisodes) {
        console.log('Multi-episode patient');
        handleNotesMerge(data);
        }
    }, [data, hasMultiEpisodes, isPatient, isDischarged]);
    
    const notesData = hasPastEpisode ? mergedNotes : data?.notes || [];

    return (
        <NotesList notes={notesData} />
    )
};

export default Notes;