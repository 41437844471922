import React from 'react';
import styles from './Participation.module.scss';
// import Typography from '@material-ui/core/Typography';
import {Box, withStyles} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import HtmlTooltip from 'common/components/UI/HtmlTooltip';
import moment from 'moment';


const Item = ({ item, past, ...restProps }) => {
  const participation = item.participate;
  const checkedInTime = `Checked in on ${moment(participation?.checkedIn).format(
    'MMM D, YYYY'
  )} at ${moment(participation?.checkedIn).format('h:mm a')}`;

  return (
   
    <Box className={styles.item} {...restProps}>
       <HtmlTooltip title={checkedInTime} placement="top">
          <CheckIcon
            fontSize="medium"
            style={{ fill: participation?.checkedIn ? '#2EC12F' : '#8A8A8A' }}
          />
        </HtmlTooltip>
        <Box className={styles.main}>
          <div className={styles.member_info}>
            <Avatar
              src={item.image}
              className={styles.avatar}
              alt={"avatar"}
            >
              {item.firstName?.[0]}{item.lastName?.[0]}
            </Avatar>
            <Box component="span" className={styles.member_name}>
              {item.firstName} {item.lastName?.[0]} {item.relation ? ` (${item.relation})` : null}
            </Box>
          </div>
        </Box>
      { !participation.feedback && participation?.responseStatus === "INVITE_ACCEPTED" &&
          <Typography className={styles.response}>Going</Typography>
        }
      { participation.feedback && <Rating readOnly value={participation.feedback.rating}></Rating>
      }
    </Box>
)};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  past: PropTypes.bool,
};

export default Item;
