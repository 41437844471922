import React from 'react'
import { useController } from 'react-hook-form'
import { Autocomplete } from './Autocomplete'

export const SelectAutocomplete = ({
  control,
  name,
  defaultValue = '',
  required = false,
  items,
  labelKey,
  style,
  errorText,
  className,
}) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  })
  return (
    <Autocomplete
      className={className}
      defaultValue={defaultValue}
      options={items}
      getOptionLabel={(option) => {
        return (labelKey && labelKey[option]) || option
      }}
      style={style}
      {...inputProps}
      onSelect={(event, value) =>
        onChange({
          ...event,
          target: {
            value,
          },
        })
      }
    />
  )
}
