import React from 'react';
import { useController } from 'react-hook-form';

//Components
import { FormHelperText } from '@material-ui/core';
import Loading from '../Loading';
import BaseSelect from './BaseSelect';

const Select = ({ control, name, defaultValue = '', required = false, items, loading, usePlural, ...props }) => {
  const {
    field: { ref, value = defaultValue, ...inputProps },
    fieldState: { invalid, error },
    //   formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  if (loading) return <Loading />;

  return (
    <>
      <BaseSelect
        items={items}
        usePlural={usePlural}
        inputRef={ref}
        value={value}
        error={invalid}
        helperText={error?.message}
        inputProps={inputProps}
        {...props}
      />
      {invalid && (
        <FormHelperText style={{ marginLeft: '14px' }} error>
          {error?.message}
        </FormHelperText>
      )}
    </>
  );
};

export default Select;
