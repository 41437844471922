import { makeStyles } from '@material-ui/core';
import { getHumanizedTime } from 'common/helpers/dateHumanize';
import useAccountContext from 'context/Account/useAccountContext';
import dayjs from 'dayjs';
import React from 'react';

const WelcomeWidget = ({}) => {

  const {userFirstName} = useAccountContext()
  const styles = useStyles()
  const timeOfDay = getHumanizedTime()

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.date}>{dayjs().format('dddd, MMMM DD')}</div>
        <div className={styles.welcomeMessage}>Good {timeOfDay}, {userFirstName}</div>
        <div className={styles.welcomeStats}>
          <div className={styles.textRow}>My Week</div>
          <div className={styles.divider}></div>
          <div className={styles.textRow}>
            <span style={{fontSize: 22, marginRight: 8}}>12</span> Tasks Completed
          </div>
        </div>
      </div>
    </div>
  )
};

export default WelcomeWidget;

const useStyles = makeStyles({
  wrapper: {
    marginBottom: 20,
    color: 'white'
  },
  content: {
    display: 'flex',
    flex: 1,
    alignItems:'center',
    flexDirection: 'column',
  },
  divider: {
    height: 20,
    borderRight: '1px solid white',
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 15px'
  },
  date: {
    marginBottom: 5,
  },
  welcomeMessage: {
    fontSize: 32,
  },
  welcomeStats: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 450,
    padding: 10,
    marginTop: 10,
    borderRadius: 25,
    backgroundColor: 'rgba(255,255,255,0.15)',
  }
})