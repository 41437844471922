import React, {Fragment, useEffect, useState} from "react";
import styles from "../PatientEdit.module.scss";
import classnames from "classnames";

import { AddOutlined, DeleteOutlined } from "@material-ui/icons";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";

// Components
import MemberAvatar from "common/components/Member/Avatar/MemberAvatar";
import SigmundFamily from "./Sigmund";

// Data
import { SET_PATIENT_RELATIONS } from "data/graphql/family";
import { GET_FULL_PATIENT_INFO } from "data/graphql/patients";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {extractMemberProps} from './utils';

// Context
import useMembersContext from "context/Members/useMembersContext";
import { useMemberStateContext } from "context/Member/useMemberContext";

import FamilyMemberOption from './components/FamilyMemberOption';

const randomMembers = [
  {_id: 12395, firstName: 'Josh', lastName: 'Cohen', type: 'family', image: null, releaseType: {name: 'Active'}, relation: {name: 'Father'} },
  {_id: 23423, firstName: 'Sarah', lastName: 'Cohen', type: 'family', image: null, releaseType: {name: 'None'}, relation: {name: 'Mother'}},
  {_id: 64372, firstName: 'Steve', lastName: 'Rinaldi', type: 'family', image: null, releaseType: {name: 'Active'}, relation: {name: 'Cousin'}},
  {_id: 75202, firstName: 'Paul', lastName: 'Roman', type: 'family', image: null, releaseType: {name: 'Resinded'}, relation: {name: 'Step Brother'}},
]

const FamilyTab = () => {

  const { enqueueSnackbar } = useSnackbar();
  
  // State
  const {refetchMembers} = useMembersContext();
  const {memberData: patient = {}, memberId, memberRelations: relations} = useMemberStateContext(); 
  const [selectedFamily, setSelectedFamily] = useState(relations);
  const [addMemberOpen, setAddMemberOpen] = useState(false);

  useEffect(() => {
    setSelectedFamily(relations)
  }, [relations])

  // Mutations
  const [setMemberRelations, setRelationsResults] = useMutation(SET_PATIENT_RELATIONS, {
    onError: e => {
      enqueueSnackbar(e?.message ? e.message : "Error saving", { variant: "error" });
    },
    onCompleted: () => {
      enqueueSnackbar("Saved", { variant: "success" });
      refetchMembers();
    },
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: memberId } },
    ],
  });

  const isProcessing = setRelationsResults?.called && setRelationsResults?.loading || setRelationsResults?.loading
  

  // // Methods
  const removeMember = (memberId, members, idKey = "_id") => {
    const filterByMemberId = member => member?.[idKey] !== memberId;
    return members.filter(filterByMemberId)
  }
  const getMember = (memberId, members, idKey = "_id") => {
    const findByMemberId = member => member?.[idKey] === memberId;
    return members.find(findByMemberId)
  }
  const extractRelationsData = (member, idKey = 'memberId') => {
    return {
      memberId: member?.[idKey],
      relation: { name: member?.relation?.name },
      releaseType: { name: member?.releaseType?.name },
    } 
  }

  // Add Member
  const handleAddMember = () => {
    setAddMemberOpen(true);
  }
  const handleAddMemberClose = () => setAddMemberOpen(false);

  // Remove Member
  const handleRemoveRelation = (memberId) => {
    // 1. set warning modal
    // 2. adjust family array (filter) -- done
    // 3. trigger mutation -- 
  
    console.log('handleRemoveRelation', memberId)
    const filterByMemberId = member => member.memberId !== memberId;
    const updatedFamily = selectedFamily.filter(filterByMemberId)
    setSelectedFamily(updatedFamily)

    
  }

  const handleUpdateRelation = async (_id, relationValue, releaseValue) => {
    
    const unchangedRelations = removeMember(_id, relations, "memberId");
    const familyMembers = unchangedRelations.map(member => extractRelationsData(member, 'memberId'))

    const updatedMember = {
      memberId: _id,
      relation: { name: relationValue },
      releaseType: { name: releaseValue },
    }
    const mutationVariables = {
      patientId: memberId,
      content: [
        updatedMember,
        ...familyMembers,
      ]
    }

    try {
      await setMemberRelations({
        variables: mutationVariables,
      });
    } catch (error) {
      console.log('Error: setMemberRelations', error)
    }

  }
  
    // Handle Submit
  // const handleAddRelation = (member = {}) => {
    
  //   setSelectedFamily(prev => [...prev, randomMembers[1]])
  //   console.log('handleAddRelation', member)
    // setFamily(prevFamily => ({prevFamily, ...newFamily}));

    //setFamily
    // dispatch({
    //   type: PATIENT_FAMILY_ADD_RELATION,
    //   payload: {
    //     _id: null,
    //     cid: cid(), //temporary `client id` aka `cid` for new rows
    //     memberId: null,
    //     relation: null,
    //     releaseType: null,
    //     lastName: null,
    //     firstName: null,
    //   }
    // });
  // };
  // const hasErrors = selectedFamily.length === 0// || !!selectedFamily.find(relation =>
  //   !(relation.memberId && relation.relation)
  // );

  const {_id, firstName, lastName, image, emrPic, type} = patient;
  const avatarProps = {
    _id,
    firstName: firstName,
    lastName: lastName,
    image: image || emrPic,
    type,
    label: `${firstName} ${lastName}`,
    showLabel: true,
    disabled: true,
    size: 'xlarge',
  }

  return (
    <Grid className={styles.wrapper}>
      <Grid container className={styles.module_edit}>
        <Grid container className={styles.wrapper}>
          <Grid item xs={3}>
            <div className={styles.avatar_wrapper}>
              <MemberAvatar {...avatarProps} />
            </div>
          </Grid>
          
          <Grid item xs={9} className={styles.container}>

            <Grid container>

              {patient.familyReleaseText &&
                <Grid item xs={12}>
                  <SigmundFamily patient={patient} />
                </Grid>
              }

              <Grid item xs={12}>
                <Box mb={2} className={classnames(styles.section_title)}>
                  Family Relations
                </Box>
              </Grid>

              <Grid item xs={12}>
                {relations?.length === 0
                  ? <Typography variant="subtitle1">Family not linked.</Typography>
                  : <Fragment>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>Member</Grid>
                      <Grid item xs={3}>Relationship</Grid>
                      <Grid item xs={3}>Release Status</Grid>
                      <Grid item xs={2} />
                    </Grid>
                    {relations?.map((member) => {
                      const memberData = extractMemberProps(member)
                      console.log('member relation data', member, 'memberData', memberData)
                      return <FamilyMemberOption key={member?.memberId} {...memberData} isProcessing={isProcessing} handleUpdate={handleUpdateRelation} handleRemove={handleRemoveRelation} />
                    })}
                  </Fragment>
                }
              </Grid>

              <Grid item xs={12}>
                <Button
                  startIcon={<AddOutlined />}
                  variant="outlined"
                  color="primary"
                  disabled={isProcessing}
                  onClick={handleAddMember}
                >
                  Add New Member
                </Button>
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <SelectMembers 
        open={addMemberOpen}
        handleClose={handleAddMemberClose}
      />
    </Grid>
  )
};

export default FamilyTab;


const SelectMembers = ({open, handleClose}) => {

  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
    >
      <DialogTitle><span className={styles.warningTitle}>Select Member</span></DialogTitle>
      <DialogContent>
        <p className={styles.warningContent}>[....]</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Add Family Member</Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    fontFamily: 'Averta Semibold',
  },
  label: {
    margin: 5,
  },
  warningTitle: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
  },
  warningContent: {
    minHeight: 100,
    fontSize: '1em',
    lineHeight: 1.5,
  }
})