import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { Avatar, Chip } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useSelector } from 'react-redux'
import { getPatientRelations } from 'selectors'
import _ from 'lodash'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

//Components
import Button from 'common/components/Forms/Button'
import Input from 'common/components/Forms/Input'
import Date from 'common/components/Forms/Date/Date'
import Time from 'common/components/Forms/Date/Time'
import Select from 'common/components/Forms/Select'
import SummaryItem from 'common/components/Forms/SummaryItem'
import Radio from 'common/components/Forms/Radio'

// Styles
import { makeStyles } from '@material-ui/core/styles'
import ChipAutosuggest from 'common/components/InputFields/ChipInput/ChipAutosuggest'
import CheckboxList from 'common/components/Forms/CheckboxList'

const durationOptions = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 45, label: '45' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 90, label: '90' },
  { value: 120, label: '120' },
]

const validationSchema = Yup.object().shape({
  cohosts: Yup.array(Yup.object()).notRequired(),
})

const resolver = yupResolver(validationSchema)

const EditAppointmentComponent = ({ type = '', invitedMembers, onClose, onUpdated, ...data }) => {
  const { editActivityAppointmentMutation, organizationWebexPrefix } = useActivitiesContext()
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({
    resolver,
    defaultValues: {
      ...data,
      update: 'this',
      date: moment(data.startDate).format('MMM D, YYYY'),
      time: moment(data.startDate).format('MMM D, YYYY h:mm a'),
      cohosts: data?.additional?.cohosts,
      webexLink: data?.webexLink && data?.webexLink.replace(organizationWebexPrefix, ''),
    },
  })
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const { host } = data?.additional

  const maxDate = data.nextInSeriesStartTime ? moment(data.nextInSeriesStartTime) : undefined

  // Redux
  const staff = [
    ...useSelector((state) => state.dashboard.dashboardData.staff),
    ...useSelector((state) => state.dashboard.dashboardData.careProviders),
  ].filter((item) => item._id !== host._id)

  const relatedPatients = useSelector((state) => getPatientRelations(state, data?.additional?.patient._id))

  useEffect(() => {
    reset({
      ...data,
      update: 'this',
      date: moment(data.startDate).format('MMM D, YYYY'),
      time: moment(data.startDate).format('MMM D, YYYY h:mm a'),
      cohosts: data?.additional?.cohosts,
      attendees: data?.additional?.members?.map((v) => v._id),
      webexLink: data?.webexLink && data?.webexLink.replace(organizationWebexPrefix, ''),
    })
  }, [])

  const onSubmit = (data) => {
    const changedData = Object.fromEntries(Object.keys(dirtyFields || {}).map((field) => [field, data[field]]))

    if (!dirtyFields) return

    // activity info
    let activityInfo = {}

    if (dirtyFields.cohosts) {
      activityInfo.cohosts = changedData.cohosts.map((item) => item._id)
    }

    activityInfo = _.omitBy(
      {
        ...activityInfo,
        invitedMembers: changedData.attendees || null,
        webexLink: changedData.webexLink
          ? changedData.webexLink.length
            ? organizationWebexPrefix + changedData.webexLink
            : ''
          : '',
      },
      _.isNull
    )

    // ScheduleInput
    let scheduling = {}

    if (changedData.date) {
      const changedDate = moment(changedData.date)
      scheduling.start = moment(data.startDate)
        .set({
          year: changedDate.get('year'),
          month: changedDate.get('month'),
          day: changedDate.get('day'),
        })
        .toISOString()
    }

    if (changedData.time) {
      const changedDate = moment(changedData.time)
      scheduling.start = moment(scheduling?.start || data.startDate)
        .set({
          hour: changedDate.get('hour'),
          minute: changedDate.get('minute'),
        })
        .toISOString()
    }

    if (
      scheduling.start &&
      data.nextInSeriesStartTime &&
      (scheduling.start > data.nextInSeriesStartTime || moment(scheduling.start).isSame(data.nextInSeriesStartTime))
    ) {
      return enqueueSnackbar('The date should be no later then the next appointment in sequence', { variant: 'error' })
    }

    scheduling = _.omitBy(
      {
        ...scheduling,
        duration: changedData.duration || null,
      },
      _.isNull
    )

    const variables = _.omitBy(
      {
        activityId: data.id,
        activityInfo,
        scheduling,
        // seriesFlag: data.update
        ...(data.isSeries && dirtyFields.update ? { seriesFlag: 'future' } : { seriesFlag: 'this' }),
      },
      _.isEmpty
    )

    console.log('variables', variables)

    editActivityAppointmentMutation({ variables })
    onUpdated()
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="base-form">
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.column}>
            <SummaryItem title="Type">{type}</SummaryItem>
            {['familySession'].indexOf(type) !== -1 && (
              <SummaryItem title="Attendees">
                <CheckboxList items={relatedPatients} control={control} name="attendees" />
              </SummaryItem>
            )}
            <SummaryItem title="Specialists">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Chip
                  label={`${host.firstName} ${host.lastName} (host)`}
                  className={classes.chip}
                  avatar={<Avatar src={host.image} />}
                />
                <ChipAutosuggest
                  suggestions={staff}
                  field="member"
                  name="cohosts"
                  control={control}
                  // loading={loading}
                />
              </div>
            </SummaryItem>
            <SummaryItem title="WebEx">
              <Input
                name="webexLink"
                control={control}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{organizationWebexPrefix.substring(14)}</InputAdornment>
                  ),
                }}
              />
            </SummaryItem>
          </div>
          <div className={classes.column}>
            <SummaryItem title="Date">
              <Date control={control} name="date" fullWidth maxDate={maxDate} />
            </SummaryItem>
            <SummaryItem title="Time">
              <Time control={control} name="time" fullWidth maxDate={maxDate} />
            </SummaryItem>
            <SummaryItem title="Duration">
              <Select
                // id="duration"
                control={control}
                name="duration"
                items={durationOptions}
                // loading={loading}
                // disabled={disabled?.duration}
              />
            </SummaryItem>
            <SummaryItem title="Recurrence">
              <p>{data?.scheduling?.recurrenceText || 'no recurrence'}</p>
              {data.isSeries && !data.isMaster && (
                <p>If you need to edit master recurrence, please delete and create new event.</p>
              )}
            </SummaryItem>
            {data.isSeries && (
              <SummaryItem title="Update">
                <Radio
                  name="update"
                  items={[
                    { label: 'Only this event', value: 'this' },
                    { label: 'This and all future events', value: 'all' },
                  ]}
                  control={control}
                  className={classes.radio}
                />
              </SummaryItem>
            )}
          </div>
        </div>
        <div className={classes.buttonsBox}>
          <Button variant="contained" className={classes.cancelBtn} onClick={onClose}>
            Cancel Edit
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
            Update & Resend Invites
          </Button>
        </div>
      </div>
    </form>
  )
}

// Styles
const useStyles = makeStyles({
  wrapper: {
    borderTop: 'solid 1px lightgray',
    maxWidth: '825px',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    // padding: '20px 20px 0 20px',
  },
  column: {
    padding: 20,
    flexDirection: 'column',
    // border: 'solid 1px green',
    flex: 1,
  },
  radio: {
    flexDirection: 'column',
    // marginBottom: 20,
  },
  buttonsBox: {
    // border: 'solid 1px red',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 20px 0',
  },
  cancelBtn: {
    backgroundColor: 'white',
    marginRight: '10px',
    boxShadow: 'none',
    color: 'gray',
  },
  picruteItem: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    margin: '0 3px',
    backgroundColor: 'white',
  },
})

export default EditAppointmentComponent
