import { createTheme } from '@material-ui/core/styles';

const breakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280, //1440
    xl: 1920,
};
const breakpoints = { values: breakpointValues };

const theme = createTheme({
    breakpoints,
    palette: {
        primary: { main: '#2A96FF' },
        brandPrimary: { main: '#386FA4' },
        brandRed: { main: '#E90100' },
        brandPurple: { main: '#630460' },
    },
    typography: {
        fontFamily: "Averta, Avenir Next, Helvetica, Arial, sans-serif",
    },
    spacing: 10
});

// https://material-ui.com/api/button/
const MuiButton = {
    MuiButton: {
        root: {
            border: 0,
            borderRadius: "18px",
            padding: "8.5px 22px 7.5px",
        },
        label: {
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "22px",
            textTransform: "initial"
        },
        text: {
            color: "rgba(0,0,0,0.4)",
            padding: "8.5px 22px 7.5px",
        },
        textSecondary: {
            
        },
        contained: {
            boxShadow: "none",
        },
        containedPrimary: {
            color: "#fff",
        }
    }
}

const MuiOutlinedInput = {
    MuiOutlinedInput: {
        root: {
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                borderColor: '#2482dd',
                borderWidth: "1px",
            },
            '&$focused $notchedOutline': {
                borderColor: '#2482dd',
                borderWidth: "1px",
            },
            borderRadius: '3px',
            backgroundColor: '#FFFFFF',
        },
        input: {
            fontSize: '16px',
            lineHeight: "22px",
            padding: '17px 16px',
            fontFamily: '"Avenir Next Medium"',
        },
        notchedOutline: {
            borderRadius: '3px',
            borderColor: '#CDD4DA',

        },
        disabled: {
            color: '#000',
            '& > $notchedOutline': {
                opacity: '.5',
                backgroundColor: '#efefef',
                borderColor: '#CDD4DA !important',
            }
        }
    },
    MuiInputLabel: {
        root: {
            fontSize: '16px',
            color: 'rgba(0,0,0,0.3)',
            fontFamily: '"Avenir Next Medium"',
        },
        outlined: {
        }
    },
}

const MuiPickers = {
    MuiPickersStaticWrapper: {
        staticWrapperRoot: {
            marginLeft: '10px',
            marginTop: '10px',
            borderRadius: '10px',
        },
    },
    MuiPickersCalendarHeader: {
        transitionContainer: {
            order: -1,
            textAlign: 'left',
        },
    },
    MuiPickersDay: {
        daySelected: {
            backgroundColor: '#2A96FF',
        },
    },
}

const MuiChip = {
    MuiChip: {
        root: {
            padding: '2px',
        },
        sizeSmall: {
            minHeight: '32px',
            borderRadius: '5px',
            backgroundColor: '#EEF2F4',
        },
        labelSmall: {
            fontSize: '16px',
            lineHeight: '32px',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: '16px',
            paddingRight: '16px',
        }
    }
}

const MuiSkeleton = {
    MuiSkeleton: {
        text: {
            '&:empty:before': {
                content: ''
            }
        }
    }
}

const MuiDialog = {
    MuiDialog: {
        paper: {
            borderRadius: '10px',
        }
    },
    MuiDialogTitle: {
        root: {
            color: '#000',
            fontSize: '24px',
            lineHeight: '33px',
            fontFamily: 'Avenir Next Demi Bold',
        }
    }
}

const MuiRadio = {
    MuiRadio: {
        colorPrimary: {
            '&$checked': {
                color: '#2a96ff'
            }
        }
    }
}

const MuiAutocomplete = {
    MuiAutocomplete: {
        root: {
            width: '100%',
            '& $inputRoot[class*="MuiOutlinedInput-root"]': {
                paddingTop: '6.5px',
                paddingBottom: '6.5px',
            },
        },
        popper: {
            textTransform: 'normal'
        }
    }
}

const MuiTabs = {
    MuiTab: {
        textColorInherit: {
            opacity: 1,
        },
    },
}
  
theme.overrides = {
    ...MuiOutlinedInput,
    ...MuiButton,
    ...MuiPickers,
    ...MuiChip,
    ...MuiSkeleton,
    ...MuiDialog,
    ...MuiRadio,
    ...MuiAutocomplete,
    ...MuiTabs,
};

export default theme;
