import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SplitButton from 'common/components/Button/SplitButton/SplitButton';
import Title from 'common/components/Popup/Title';
import {useActivitiesContext} from 'context/Activities/useActivitiesContext';

const ConfirmDelete = ({ confirmationVisible = false, toggleConfirmationVisible, commitDeletedAppointment }) => {
  const { isSeries } = useActivitiesContext();

  //TODO: "TODO Add here a question if it's recurrent: only this? all in the series? this and all future events?"
  const appointmentToBeCanceled = useSelector(state => state.scheduler.appointmentToBeCanceled);
  // console.log('appointmentToBeCanceled', appointmentToBeCanceled);
  const options = [];
  // if (appointmentToBeCanceled && appointmentToBeCanceled.scheduling) {
  //   if (appointmentToBeCanceled.scheduling.isPrimaryActivity)
  //     options.push({ name: 'All events in the series', flag: 'all' });
  //   if (appointmentToBeCanceled.scheduling.originalActivityId) {
  //     options.push({ name: 'This event', flag: 'none' });
  //     options.push({
  //       name: 'This and all following events',
  //       flag: 'future',
  //     });
  //     options.push({
  //       name: 'All events in this series',
  //       flag: 'all',
  //     });
  //   }
  // }
  return (
    <Dialog open={confirmationVisible} onClose={toggleConfirmationVisible}>
      {/* <DialogTitle>Cancel Appointment</DialogTitle> */}
      <Title onClose={toggleConfirmationVisible} title="Cancel Appointment" />
      <DialogContent>
        <DialogContentText>Are you sure you want to cancel this appointment?</DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button color="primary" variant="outlined" onClick={toggleConfirmationVisible}>
          Cancel
        </Button> */}
        {/* {options.length > 0 ? (
          <SplitButton options={options} mainTitle="Delete" />
        ) : ( */}
        <Button color="primary" variant="outlined" onClick={e => commitDeletedAppointment('this')}>
          Yes, cancel this event
        </Button>
        {isSeries ? (
          <Button color="secondary" variant="contained" onClick={e => commitDeletedAppointment('future')}>
            Yes, cancel this and future events
          </Button>
        ) : null}

        {/* )}  */}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
