import React from 'react';

// UI
import { makeStyles } from '@material-ui/core/styles';

const SummaryItem = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <label htmlFor={title?.toLowerCase()}>
        <div className={classes.title}>{title}</div>
      </label>
      <div className={classes.value}>{children}</div>
    </div>
  );
};

// Styles
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
  },
  title: {
    width: '100px',
    fontWeight: 'bold',
  },
  value: {
    flex: 1,
    justifyContent: 'flex-start',
  },
});

export default SummaryItem;
