import React, { createContext, useState, useEffect } from 'react'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { getActivities } from 'actions/scheduler'

//components
import CategoryListView from 'screens/Activities/Templates/CategoryListView'
import NewActivity from 'screens/Activities/ActivityForm/NewActivity'
import SaveAsTemplate from 'screens/Activities/Templates/SaveAsTemplate'
import EditActivity from 'screens/Activities/ActivityForm/EditActivity'

//graphql
import {
  GET_ACTIVITY_TEMPLATES,
  GET_ACTIVITY_TEMPLATE_DETAILS,
  ADD_ACTIVITY,
  GET_ACTIVITIES,
  SAVE_NEW_TEMPLATE,
  EDIT_ACTIVITY,
  EDIT_ACTIVITY_APPOINTMENT,
} from 'data/graphql/activity'
import { GET_ENUMS_PROCESS_TYPES } from 'data/graphql/dashboard'

//hooks
import useStepState from 'common/hooks/useStepState'
import useMembersContext from 'context/Members/useMembersContext'
import useAccountContext from 'context/Account/useAccountContext'

export const ScheduleModuleContext = createContext()
export const ActivitiesContext = createContext({})

const editActivitySteps = [
  {
    status: 'EDIT_ACTIVITY',
    nextStatus: null,
    prevStatus: null,
    component: (props) => <EditActivity {...props} />,
    payload: {
      title: 'Edit activity',
    },
  },
]
const addActivityWithSaveTemplateSteps = [
  {
    status: 'CHOOSE_FROM_TEMPLATE',
    nextStatus: 'ADD_NEW_ACTIVITY',
    prevStatus: null,
    component: (props) => <CategoryListView {...props} />,
    payload: {
      title: 'Add new activity',
    },
  },
  {
    status: 'ADD_NEW_ACTIVITY',
    nextStatus: 'SAVE_AS_TEMPLATE',
    prevStatus: 'CHOOSE_FROM_TEMPLATE',
    component: (props) => <NewActivity {...props} />,
    payload: {
      title: 'Add new activity',
    },
  },
  {
    status: 'SAVE_AS_TEMPLATE',
    nextStatus: null,
    prevStatus: 'ADD_NEW_ACTIVITY',
    component: (props) => <SaveAsTemplate {...props} />,
    payload: {
      title: 'Save as Template',
    },
  },
]

const ActivitiesContextProvider = ({ children }) => {
  // Hooks
  const dispatch = useDispatch()
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()

  // Context
  const { patients: patientsList } = useMembersContext()
  const { webexPrefix } = useAccountContext()

  // State: Action Dialogs
  const [bookAppointmentOpen, setBookAppointmentOpen] = useState(false)
  const [editAppointmentOpen, setEditAppointmentOpen] = useState(false)
  const [addActivityOpen, setAddActivityOpen] = useState(false)
  const [editActivityOpen, setEditActivityOpen] = useState(false)
  const [completed, setCompleted] = useState(false)

  // TODO: Move this closer to components. It does not belong there
  // State: Templates
  const [templatesList, setTemplatesList] = useState([])
  const [templateId, setTemplateId] = useState() //the id of the template that was selected in the CHOOSE_FROM_TEMPLATE
  const [selectedTemplateCode, setSelectedTemplateCode] = useState()
  const [templateDetails, setTemplateDetails] = useState()
  const [activityTemplate, setActivityTemplate] = useState() //template information that was changed in the step ADD_NEW_ACTIVITY

  // State: Activity
  const [editActivity, setEditActivity] = useState() // the activity that is rendered in the EDIT_ACTIVITY?
  const [isSeries, setIsSeries] = useState() // the activity that is rendered in the EDIT_ACTIVITY?
  const [isEditState, setEditState] = useState(false)
  const [recurrence, setRecurrence] = useState()

  const dateDefault = moment()
    .minute(moment().minute() > 30 ? 0 : 30)
    .second(0)
  const scheduler = useSelector((state) => state.scheduler)

  const refreshScheduler = () => {
    //reload activities
    const { currentDate, currentViewName, type, patient } = scheduler
    dispatch(getActivities(client, currentDate, currentViewName, type, patient))
  }

  // Patients array reduction
  const patients = patientsList
    ?.map((p) => ({
      _id: p?._id,
      firstName: p?.firstName,
      lastName: p?.lastName,
      image: p?.image,
      type: p?.type,
    }))
    .sort((a, b) => a.firstName.localeCompare(b.firstName))

  const [getActivityTemplates, { loading: templatesDataLoading, error: templatesDataError }] = useLazyQuery(
    GET_ACTIVITY_TEMPLATES,
    {
      variables: { filters: { categoryListView: true } },
      onCompleted: (data) => {
        setTemplatesList(data.getActivityTemplates || [])
        //TODO: if the list is empty trigger an notification screen to let the user know the app is working there are just no templates to be shown
      },
    }
  )

  const [getActivityTemplateDetails, { loading: templateDetailsLoading, error: templateDetailsError, data: smth }] =
    useLazyQuery(GET_ACTIVITY_TEMPLATE_DETAILS, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setSelectedTemplateCode(data.getActivityTemplate && data.getActivityTemplate.code)
        setTemplateDetails((data.getActivityTemplate && data.getActivityTemplate.template) || {})
      },
    })

  const [addNewActivity, addNewQuery] = useMutation(ADD_ACTIVITY, {
    refetchQueries: [{ query: GET_ACTIVITIES }],
    // ], add here a way to trigger a refetch on the scheduler from the back
    onError: (e) => {
      enqueueSnackbar('Error adding the activity', { variant: 'error' })
      console.error(e)
    },
    onCompleted: () => {
      enqueueSnackbar('Activity was added', { variant: 'success' })
      refreshScheduler()
      setCompleted(true)
    },
  })

  const [saveNewTemplate, addNewTemplateQuery] = useMutation(SAVE_NEW_TEMPLATE, {
    onError: (e) => enqueueSnackbar(e && e.message ? e.message : 'Error adding the template', { variant: 'error' }),
    onCompleted: () => enqueueSnackbar('Template was saved', { variant: 'success' }),
  })

  const [editActivityMutation, { loading: isEditing }] = useMutation(EDIT_ACTIVITY, {
    onCompleted: () => {
      enqueueSnackbar('Activity updated', { variant: 'success' })
      refreshScheduler()
      setCompleted(true)
    },
    onError: (e) => enqueueSnackbar(e && e.message ? e.message : 'Error. Activity not updated', { variant: 'error' }),
  })

  const [editActivityAppointmentMutation, { loading: isEditingAppointment }] = useMutation(EDIT_ACTIVITY_APPOINTMENT, {
    onCompleted: () => {
      enqueueSnackbar('Activity updated', { variant: 'success' })
      refreshScheduler()
      setCompleted(true)
    },
    onError: (e) => enqueueSnackbar(e && e.message ? e.message : 'Error. Activity not updated', { variant: 'error' }),
  })

  const [getEnums, { called, loading: loadingProcessTypes, data: dataProcessTypes, error: errorProcessTypes }] =
    useLazyQuery(GET_ENUMS_PROCESS_TYPES)

  useEffect(() => {
    if (!called) getEnums()
  }, [])

  console.log('dataProcessTypes', dataProcessTypes)

  const [component, payload, resetSteps] = useStepState(
    isEditState ? editActivitySteps : addActivityWithSaveTemplateSteps
  )

  const [editComponent, editPayload] = useStepState(editActivitySteps)

  const resetComponent = function () {
    setRecurrence(null)
    setTemplateId(null)
    setSelectedTemplateCode(null)
    setTemplateDetails(null)
  }

  const reset = () => {
    resetSteps()
    resetComponent()
  }

  const contextValues = {
    templatesList,
    loading: templatesDataLoading || templateDetailsLoading || isEditing || isEditingAppointment,
    error: templatesDataError || templateDetailsError,
    component,
    payload,
    reset,
    templateId,
    setTemplateId,
    getActivityTemplates,
    getActivityTemplateDetails,
    templateDetails,
    patients,
    defaultPatient: scheduler.patient,
    dateDefault: dateDefault,
    activityTemplate,
    setActivityTemplate,
    selectedTemplateCode,
    addNewActivity,
    saveNewTemplate,
    editComponent,
    editPayload,
    setRecurrence,
    recurrence,
    editActivity,
    setEditActivity,
    editActivityMutation,
    editActivityAppointmentMutation,
    completed,
    isSeries,
    setIsSeries,
    // editAppointmentOpen,
    // setEditAppointmentOpen,
    organizationWebexPrefix: webexPrefix,

    // Module Context: Temporarily here
    bookAppointmentOpen,
    setBookAppointmentOpen,
    editAppointmentOpen,
    setEditAppointmentOpen,
    addActivityOpen,
    setAddActivityOpen,
    editActivityOpen,
    setEditActivityOpen,
    // Process Types Enum
    loadingProcessTypes,
    processTypes: dataProcessTypes?.__type.enumValues,
    errorProcessTypes: !!errorProcessTypes,
  }

  const moduleContextValues = {
    bookAppointmentOpen,
    setBookAppointmentOpen,
    editAppointmentOpen,
    setEditAppointmentOpen,
    addActivityOpen,
    setAddActivityOpen,
    editActivityOpen,
    setEditActivityOpen,
  }

  return (
    <ScheduleModuleContext.Provider value={moduleContextValues}>
      <ActivitiesContext.Provider value={contextValues}>{children}</ActivitiesContext.Provider>
    </ScheduleModuleContext.Provider>
  )
}

export default ActivitiesContextProvider
