import React from 'react';
import styles from './styles.module.scss';

import {
  Scheduler,
  // Header
  Toolbar,
  TodayButton,
  ViewSwitcher,
  DateNavigator,
  // Views
  DayView,
  WeekView,
  MonthView,
  // Components
  Appointments,
  AppointmentTooltip,
  Resources,
  // AppointmentForm,
  // GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ViewState,
  EditingState,
  CurrentTimeIndicator,
  IntegratedEditing,
  // GroupingState,
  // IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';

//Components
import { TimeIndicator } from './CurrentTimeIndicator';
import { ToolbarWithLoading } from 'common/components/Schedule/ToolBar/ToolBar';
import ConfirmDelete from 'common/components/Schedule/ConfirmDelete/ConfirmDelete';
      import IRAppointments, {
        IRAppointmentContent,
        MultiScheduleAppointments,
        MultiScheduleAppointmentContent,
      } from '../Appointments/Appointments';
// import IRAppointmentForm from 'components/Scheduler/components/AppointmentForm/index_new';
// import AddNewFab from 'common/components/Schedule/AddNewFab/AddNewFab';


import { useCountRenders } from 'common/hooks/useCountRenders';

const ActivityScheduler = props => {
  // const { appointmentForm } = props;

  useCountRenders('ActivityScheduler')

  
  
  // console.log('%c MAIN ACTIVITYSCHEDULER', 'color:red', props);
  return (
    <div className={styles.calendar_container}>
      {/* <appointmentForm /> */}
      <Scheduler
        data={props.data}
        height={props.schedulerHeight}
        onValueChange={e => {
          // console.log('%c onValueChange', e);
        }}
      >
        <ViewState
          currentDate={props.currentDate}
          currentViewName={props.currentViewName}
          onCurrentViewNameChange={props.currentViewNameChange}
          onCurrentDateChange={props.currentDateChange}
        />
        <EditingState
          onCommitChanges={props.commitChanges}
          onEditingAppointmentChange={props.onEditingAppointmentIdChange}
          onAddedAppointmentChange={props.onAddedAppointmentChange}
        />
        {props.noDayView ? null : (
          <DayView
            startDayHour={props.startDayHour}
            endDayHour={props.endDayHour}
            {...(props.dayViewTimeTableCell
              ? {
                  timeTableCellComponent: props.dayViewTimeTableCell,
                }
              : null)}
          />
        )}
        <WeekView
          startDayHour={props.startDayHour}
          endDayHour={props.endDayHour}
          {...(props.weekViewTimeTableCell
            ? {
                timeTableCellComponent: props.weekViewTimeTableCell,
              }
            : null)}
        />
        {props.noMonthView ? null : (
          <MonthView
            startDayHour={props.startDayHour}
            endDayHour={props.endDayHour}
          />
        )}
        <Appointments
          appointmentComponent={
            props.origin === 'multischedule'
              ? MultiScheduleAppointments
              : IRAppointments
          }
          appointmentContentComponent={
            props.origin === 'multischedule'
              ? MultiScheduleAppointmentContent
              : IRAppointmentContent
          }
        />
        <Toolbar
          className={styles.activity}
          flexibleSpaceComponent={props.flexibleSpace}
          {...(props.loading ? { rootComponent: ToolbarWithLoading } : null)}
        />
        <DateNavigator />
        <CurrentTimeIndicator indicatorComponent={TimeIndicator} />
        <TodayButton
          messages={{ today: 'Today' }}
          buttonComponent={props => (
            <TodayButton.Button className={styles.outlined_button} {...props}>
              Today
            </TodayButton.Button>
          )}
        />
        <ViewSwitcher
          switcherComponent={props => (
            <ViewSwitcher.Switcher
              {...props}
              className={styles.outlined_button}
            >
              {props.children}
            </ViewSwitcher.Switcher>
          )}
        />
        <IntegratedEditing />
        <AppointmentTooltip
          {...(props.tooltipContent
            ? { contentComponent: props.tooltipContent }
            : null)}
          headerComponent={() => <></>}
          visible={props.tooltipVisible}
          showOpenButton
          onVisibilityChange={props.toggleTooltipVisibility}
          showCloseButton
        />
        {props.resources && props.mainResourceName ? (
          <Resources
            data={props.resources}
            mainResourceName={props.mainResourceName}
          />
        ) : null}
      </Scheduler>

      <ConfirmDelete
        confirmationVisible={props.confirmationVisible}
        commitDeletedAppointment={props.commitDeletedAppointment}
        toggleConfirmationVisible={props.toggleConfirmationVisible}
      />
      {/* <AddNewFab props={props} /> */}
    </div>
  );
};

export default ActivityScheduler;
