import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SetupProgress = ({ 
  setupProgress,
  progressMessage, 
  completedSteps, 
  totalSteps, 
}) => {

  const classes = useStyles();
  
  return (
  <>
    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>{`${progressMessage} ${completedSteps} / ${totalSteps}`}</div>
    <div style={{ display: 'flex', flex: 1 }}>
      <LinearProgress
        classes={{
          root: classes.root,
          bar: classes.bar,
        }}
        className={classes.progress}
        value={setupProgress}
        variant="determinate"
      />
    </div>
  </>
  )
}

export default SetupProgress;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F6F6F6',
  } ,
  bar: { 
   backgroundColor: '#00B500',
   borderRadius: 20,
  },
  progress: {
    display: 'flex',
    flex: 1,
    height: '10px !important',
    margin: '15px auto',
    borderRadius: 10,
  },
});

