import React, { useState } from 'react';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import styles from '../Form.module.scss';

// Auth
import {useAuthNContext} from 'context/Auth/useAuthContext';
import validator from 'validator';

const ChallengeForm = () => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  
  const {handleCompleteNewPassword, authError} = useAuthNContext();
  console.log('[Auth] ChallengeForm authError', authError)


  // To Do: Fix this retarded #dima-fix
  const isValid = () => {
    let error = {};

    if (validator.isEmpty(password)) {
      error.password = 'The field is required';
    }

    if (validator.isEmpty(confirmPassword)) {
      error.confirmPassword = 'The field is required';
    }
    if (password !== confirmPassword) {
      error.confirmPassword = 'Please match the password you provided.';
    }
    if (Object.keys(error).length) {
      setErrors(error);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleChallenge = e => {
    e.preventDefault();

    if (isValid()) {
      handleCompleteNewPassword(password);
    }
  };

  return (
    <div className={styles.login}>
      <h4 className={styles.title}>Please set a password for your account.</h4>

      <Box mb={2}>
        <ul>
          <li>
            <Typography color="default" component="p">
              Must be at least 8 characters long and include a number
            </Typography>
          </li>
          {/* <li>
            <Typography color="default" component="p">
              Include an uppercase and lowercase letter
            </Typography>
          </li> */}
          {/* <li>
            <Typography color="default" component="p">
              Include a number and a special character
            </Typography>
          </li> */}
        </ul>
      </Box>

      {authError ? (
        <Box mb={2}>
          <p className={styles.error}>{authError.message}</p>
        </Box>
      ) : null}

      <Grid container>
        <Grid item xs={12} className={styles.group}>
          <label htmlFor="password">New password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={evt => setPassword(evt.target.value.trim())}
          />
          {errors.password ? <p className={styles.error}>{errors.password}</p> : null}
        </Grid>
        <Grid item xs={12} className={styles.group}>
          <label htmlFor="password">Confirm password</label>
          <input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={evt => setConfirmPassword(evt.target.value.trim())}
          />
          {errors.confirmPassword ? <p className={styles.error}>{errors.confirmPassword}</p> : null}
        </Grid>
        <Grid item xs={12}>
          <Box mb={4}>
            <Button
              variant="contained"
              color="primary"
              // disabled={loading}
              onClick={handleChallenge}
              // classes={`${loading ? styles.loading : ''}`}
            >
              Set password
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChallengeForm