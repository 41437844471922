import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLazyQuery } from '@apollo/client';
import { GET_DASHBOARD_DATA_PRELOAD } from 'data/graphql/dashboard';

import LoadingScreen from 'common/components/LoadingScreen/LoadingScreen';
import { dashboardToStore, getStatusAgreement, storeDashboardRefetch } from 'actions/dashboard';

// const DEV_TEST_AGREEMENT = false;

const WithQuery = ({ children, dashboardToStore, getStatusAgreement, storeDashboardRefetch }) => {
  const [getPreload, {loading, data, refetch, error, called }] = useLazyQuery(GET_DASHBOARD_DATA_PRELOAD);
  //we need this GET_ALL only for the refetch to work for each section but this has to be updated with the store containing patients: not dashboardData.patients and then we can have refetchPatients, refetchFamily etc...
  //TODO instead of store.dashboardData.patients -> store.patients and instead of refetch for the whole store we should have refetch patients refetch family etc
  //For this we need the reducers to contain SET_PATIENTS view reference in reducers/dashboard.js
  // Once we have broken down the store.dashboardData into pieces like patients/family/discharged... then we can take out the lazyload completely
  
  useEffect(() => {
    if (!called) getPreload()
  }, [])

  useEffect(() => {
    if (data) {
      setupReduxData(data, error, refetch);
    }
  }, [data]);

  function setupReduxData(data, error, refetch) {
    if (data && data.getDashboardData) {
      dashboardToStore(data?.getDashboardData, error);
      storeDashboardRefetch(refetch);
      getStatusAgreement(data?.getDashboardData?.user?.termsAndConditionsAccepted);
    }
  }

  if (loading) return <LoadingScreen />;

  return children;
};

export default connect(null, dispatch => ({
  dashboardToStore: bindActionCreators(dashboardToStore, dispatch),
  getStatusAgreement: bindActionCreators(getStatusAgreement, dispatch),
  storeDashboardRefetch: bindActionCreators(storeDashboardRefetch, dispatch),
}))(WithQuery);
