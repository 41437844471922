import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './QuillNote.module.scss';

/**
 * Quill note without formiq
 * @param {*} param0 
 */
const QuillNoteBare = ({ 
    placeholder, 
    value,
    onChange,
    errorText,
  ...props
}) => {
  return (    
    <>
    <div className={styles.quill_error}>{errorText}</div>
    <div className={styles.quill_container}>
      <ReactQuill
        value={value} 
        onChange= {onChange}
        placeholder={placeholder}
        modules={QuillNoteBare.modules}
        formats={QuillNoteBare.formats}
        className={styles.quill_note}
        theme={'snow'}
      />
    </div>
    </>
  );
}

QuillNoteBare.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

QuillNoteBare.formats = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'align',
];

export default QuillNoteBare;