import React from 'react';
import { TextField } from '@material-ui/core';

const FormikTextField = ({
  field,
  disabled,
  form: { 
    touched,
    errors 
  },
  ...props
}) => {

  const fieldErrors = errors[field?.name];
  const hasErrors = !!fieldErrors && touched[field?.name];

  return (
    <TextField
      {...props}
      {...field}
      variant="outlined"
      error={hasErrors}
      helperText={hasErrors ? fieldErrors : props.helperText}
    />
  )
}

export default FormikTextField;