import React, { useLayoutEffect } from 'react';
import CareTeamDashboard from 'components/Dashboards/CareTeam';

const Dashboard = () => {
  
  useLayoutEffect(() => { window.scrollTo(0, 0)}, [])

  return <CareTeamDashboard />

};

export default Dashboard;
