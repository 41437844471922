import React from 'react';
import moment from 'moment';
import LabStatusMap from 'common/components/Labs/LabStatusMap';
import { LabAction } from "common/components/Labs/LabAction"


export const toTableData = data => !data ? [] : data.map((item, i) => ({
  date: moment(item.dueDate).format('MM-DD-YYYY'), // MMM D
  authorizationNumber: item.authorizationNumber,
  result: <LabStatusMap name="resultLabel" label={item.result} />,
  type: item.panel.name,
  notes: item.specialNote,
  more: <LabAction name="moreLabel" item={item} />
}));