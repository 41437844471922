import {gql} from '@apollo/client';

export const GET_ACCESS_TOKEN = gql`
  query getMyAccessToken {
    getMyAccessToken {
      token
      type
    }
  }
`;

export const CREATE_GROUP_CHANNEL = gql`
  mutation createGroupChannel($groupChannel: GroupChannelInput!) {
    createGroupChannel(groupChannel: $groupChannel) {
      url
    }
  }
`;

export const GET_ONLINE_USERS = gql`
  query getOnlineUsers {
    getOnlineUsers
  }
`;


export const SEND_MESSAGE_NOTIFICATION = gql`
  mutation sendMessageNotification($receivers: [ObjectID]!, $messageContent: ChatMessage) {
    sendMessageNotification(receivers: $receivers, messageContent: $messageContent ) 
  }
`;


