import {useContext} from 'react';
import { AccountContext } from './AccountContext';

const useAccountContext = () => {
    
    const context = useContext(AccountContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the AccountContext`) 
    };

    return context

};

export default useAccountContext;