import React, { useContext } from 'react';

import styles from './ChatRooms.module.scss';

import { List } from '@material-ui/core';
import { ChatContext } from 'context/Chat/Chat';
import ChatRoomItemNew from './ChatRoomItemNew';

const ChatRoomsList = ({ height }) => {

  const { channelsList } = useContext(ChatContext);
  return (
    <List className={styles.messages_list} children={(
      channelsList.map(channel => (
        channel.members.length > 1 ? <ChatRoomItemNew key={channel.url} {...{ channel }} /> : null
      ))
    )} />
  );
}
export default ChatRoomsList;
