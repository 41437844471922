import {gql} from '@apollo/client';

export const ME_STAFF = gql`
  query MyQuery {
    meStaff {
      _id
      firstName
      lastName
      active
      jobTitle
      image
      gender
      title
      type
      email
      phone
      webexLink
      activatedAccount
      updatedAt
      birthDate
      staffNo
      roles_new
      account {
        hasGoogleToken
        hasOutlookToken
        maxHours
        minHours
        outlookExpiresAt
        paddingTime
        timezone
        openingHours {
          monday {
            closed
            from
            to
          }
          friday {
            closed
            from
            to
          }
          saturday {
            closed
            from
            to
          }
          sunday {
            closed
            from
            to
          }
          thursday {
            closed
            from
            to
          }
          tuesday {
            closed
            to
            from
          }
          wednesday {
            closed
            from
            to
          }
        }
      }
      address {
        city
        state
        street1
        street2
        zip
        country {
          code
          name
        }
      }
      department {
        _id
        name
      }
      location {
        _id
        name
      }
      organizationId
      careTeam {
        _id
        firstName
        lastName
        image
      }
    }
  }
`;

export const EDIT_STAFF = gql`
  mutation editStaff($staffId: ObjectID!, $staff: StaffEditInput) {
    editStaff(staffId: $staffId, staff: $staff) {
      firstName
      lastName
      locationId
      birthDate
      type
      description
      phone
      gender
      title
      staffNo
      jobTitle
      roles_new
      admitDate
      dischargeDate
      supervisor
    }
  }
`;

export const EDIT_STAFF_ACCOUNT = gql`
  mutation editStaffAccount($account: AccountInput) {
    editStaffAccount(account: $account) {
      minHours
      maxHours
      timezone
      hasGoogleToken
      hasOutlookToken
      outlookExpiresAt
      openingHours {
        monday {
          closed
          from
          to
        }
        tuesday {
          closed
          from
          to
        }
        wednesday {
          closed
          from
          to
        }
        thursday {
          closed
          from
          to
        }
        friday {
          closed
          from
          to
        }
        saturday {
          closed
          from
          to
        }
        sunday {
          closed
          from
          to
        }
      }
      userGuides {
        code
        status
        completedSteps
        startedAt
        completedAt
        skippedAt
      }
    }
  }
`;

export const EDIT_STAFF_TIMEZONE = gql`
  mutation editStaffAccount($account: AccountInput) {
    editStaffAccount(account: $account) {
      timezone
    }
  }
`;

export const GET_STAFF_BY_ID = gql`
  query staff($_id: ObjectID!) {
    staff(_id: $_id) {
      _id
      firstName
      lastName
      email
      active
      title
      jobTitle
      image
      phone
      gender
      username
      staffNo
      description
      type
      locationId
      roles_new
      birthDate
      admitDate
      dischargeDate
      supervisor
      patients {
        _id
        firstName
        lastName
        image
        locationId
        emotionalState
        engagementLevel
      }
      department {
        _id
        name
      }
      address {
        city
        state
        street1
        street2
        zip
        country {
          code
          name
        }
      }
    }
  }
`;



export const GET_DEPARTMENTS = gql`
  query getDepartments {
    getDepartments {
      name
      _id
    }
  }
`;

export const GET_FULL_STAFF_INFO = gql`
  query getFullStaffInfo($memberId: ObjectID!) {
    getFullStaffInfo(memberId: $memberId) {
      staffNo
      description
      username
      webexLink
      jobTitle
      firstName
      lastName
      image
      coverImage
      email
      gender
      title
      phone
      type
      status
      roles_new
      active
      activatedAccount
      termsAndConditionsAccepted

      admitDate
      dischargeDate
      birthDate
      lastActive
      createdAt
      updatedAt
      termsAndConditionsAcceptedOn

      _id
      createdBy
      locationId
      supervisor
      organizationId

      myTeam

      program {
        _id
        caronId
        name
      }

      department {
        _id
        caronId
        name
      }

      careTeam {
        _id
        firstName
        lastName
        image
      }

      patients {
        _id
        firstName
        lastName
        image
      }

      familyMembers {
        _id
        firstName
        lastName
      }

      statistics {
        totalDeptPatients
        callsThisWeek
      }

      cognitoInfo {
        status
      }
    }
  }
`;

export const ENABLE_ONCALL = gql`
  mutation selfEnableOnCall {
    selfEnableOnCall
  }
`;

export const DISABLE_ONCALL = gql`
  mutation selfDisableOnCall {
    selfDisableOnCall
  }
`;
