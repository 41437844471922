import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../Loading';

import { BaseRadio } from './BaseRadio';

export const Radio = ({ name, control, items, className, loading }) => {

  if(loading) return <Loading />

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...inputProps }, fieldState: { invalid, error } }) => {
        return (
          <BaseRadio
            onChange={onChange}
            value={value || items[0]}
            invalid={invalid}
            error={error}
            items={items}
            className={className}
            {...inputProps}
          />
        );
      }}
    />
  );
};


Radio.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  labelKey: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};
