import React from 'react';
import {
  Grid, Box, Link,
  Dialog, DialogTitle, DialogContent,
  Table, TableBody, TableCell, TableRow, TableHead
} from '@material-ui/core';
import moment from 'moment';
import cn from 'classnames';
import styles from './index.module.scss';
import LabStatusMap from './LabStatusMap';

const LabDetails = ({
  data,
  open,
  handleClose
}) => {

  const {
    patient,
    dueDate,
    panel,
    authorizationNumber,
    result,
    detailedResults,
    withETG,
    documents
  } = data;

  const isPositive = result === 'POSITIVE' || result === 'POSITIVE_DILUTE';
  const isCompliant = result === 'NEGATIVE' || result === 'NEGATIVE_DILUTE';
  const isInconclusive = result === 'INCONCLUSIVE';

  const labDocuments = documents.filter(d => d.tags?.includes("labResult"));
  const hasDocuments = (labDocuments && labDocuments.length > 0) ? true : false;

  return (

    <Dialog
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className={styles.popup}>

      <DialogTitle className={styles.title}>
        UDS Lab Report
      </DialogTitle>

      <DialogContent dividers className={styles.content}>
        <div style={{ marginBottom: '50px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cn(styles.result_area, {
                [styles.result_positive]: isPositive,
                [styles.result_compliant]: isCompliant,
                [styles.result_inconclusive]: isInconclusive,
              })}>
                <Grid container spacing={2}>
                  <Grid item xs={3}><span className={styles.heading}>Test Result</span></Grid>
                  <Grid item xs={4}>
                    <LabStatusMap label={result} style={{ fontWeight: 'bold' }} />
                  </Grid>
                  <Grid item xs={1}><span className={styles.heading}>Date</span></Grid>
                  <Grid item xs={4}>{moment(dueDate).format('ll')}</Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={3}><span className={styles.heading}>Patient</span></Grid>
            <Grid item xs={9}>{patient.firstName} {patient.lastName}</Grid>

            <Grid item xs={3}><span className={styles.heading}>Auth #</span></Grid>
            <Grid item xs={9}>{authorizationNumber}</Grid>

            <Grid item xs={3}><span className={styles.heading}>Drug Panel</span></Grid>
            <Grid item xs={9}>{panel.name} {withETG && ' + EtG'}</Grid>

          </Grid>
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box mb={1} className={styles.heading}>Lab Details</Box>
            <Box mb={1}>
              {hasDocuments
                ? <Link href={labDocuments[0].url}
                  target="_viewReport"
                  title={labDocuments[0].name}
                  className={styles.downloadLink}>
                  Download Full Report
                    </Link>
                : null
              }
            </Box>
          </div>

          {detailedResults && detailedResults.length > 0
            ?
            <Box className={styles.content_footer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Substance</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Initial</TableCell>
                    <TableCell>Confirm</TableCell>
                    <TableCell>Actual <span style={{ fontSize: '10px', color: '#999' }}>ng/mL</span></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {detailedResults.map(detail => {
                    const isPositiveSub = detail.result === 'POSITIVE';
                    return <TableRow key={detail._id}>
                      <TableCell>{detail.substance || '-'}</TableCell>
                      <TableCell>
                        <LabStatusMap label={detail.result} />
                      </TableCell>
                      <TableCell>{detail.baseLevel || '-'}</TableCell>
                      <TableCell>{detail.value || '-'}</TableCell>
                      <TableCell>
                        <span style={{ color: isPositiveSub ? '#f50057' : null }}>
                          {detail.confirmLevel ? Number(detail.confirmLevel).toLocaleString('en') : '-'}
                        </span>
                      </TableCell>
                    </TableRow>;
                  })}
                </TableBody>
              </Table>
            </Box>
            :
            <Box className={cn(styles.content_footer, styles.empty_footer)}>
              Substance details not available.
                {/* Add "Enter Details" link here if has permissions */}
            </Box>
          }
        </div>
      </DialogContent>

    </Dialog>

  );
};

export default LabDetails;