import React from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete } from './Autocomplete';

const filter = createFilterOptions();

export const MultiselectInput = ({
  control,
  name,
  defaultValue = [],
  required = false,
  items,
  labelKey,
  style,
  errorText,
  loading,
  maxLength = 5,
  ...props
}) => {
  const classes = useStyles();
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  return (
    <div className={classes.root}>
      <Autocomplete
        {...inputProps}
        loading={loading}
        multiple
        value={value || []}
        onSelect={(event, newValue) => {
          const arr = newValue.map(item => {
            if (typeof item === 'string') return item;
            if (typeof item.text === 'string') return item.text;
          });
          return onChange(arr.slice(0, maxLength));
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              text: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={[]}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }

          return option.text;
        }}
        renderOption={option => option.title}
        style={{ width: 300 }}
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            inputRef={ref}
            error={invalid}
            helperText={error?.message}
            variant="outlined"
            size="small"
            {...props}
          />
        )}
      />
    </div>
  );
};

const useStyles = makeStyles({
  chip: {
    margin: '0 3px',
  },
});

MultiselectInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  required: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelKey: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.string,
  errorText: PropTypes.string,
};
