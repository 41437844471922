import React, { useContext } from 'react';

import cn from 'classnames';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { Avatar, Box, ListItem, ListItemIcon, ListItemText, } from '@material-ui/core';
import styles from './ChatRooms.module.scss';
import soberCircleImage from "common/assets/soberCircleCropped.png";
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

import { ChatContext } from 'context/Chat/Chat';
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';
import { getMemberById } from 'context/Members/MembersContext';

const ChatRoomItem = ({ channel: {
  url,
  name,
  members,
  coverUrl,
  customType,
  lastMessage,
  unreadMessageCount,
} }) => {

  const { typingUsers } = useContext(ChatContext);
  const { userId } = useAccountContext();
  const {activePatients, activeFamily, staff} = useMembersContext();
  
  const isSoberCircle = customType === 'SoberCircle';
  const isPrivateSession = customType === "PrivateSession";
  
  const memberCollection = [...activePatients, ...activeFamily, ...staff]
  const chatUser = members.find(u => u.userId !== userId);
  const user = getMemberById(memberCollection, chatUser?.userId) || {}
  console.log('[ChatRoomItemNew] user', user, 'chatUser', chatUser, 'members', members, 'memberCollection', memberCollection, )
  
  const userTyping = typingUsers.indexOf(user?._id) !== -1;
  const userName = `${user?.firstName} ${user?.lastName}`;
  const isOnline = chatUser.connectionStatus === 'online';

  const lastMessagePreview = lastMessage?.message ? (
    lastMessage.message.length > 50 ? (
      lastMessage.message.substr(0, 50) + '...'
    ) : lastMessage.message
  ) : '';

  const { _id, firstName, lastName, image, emrPic, type } = user;
  const avatarProps = { 
    _id,
    firstName, 
    lastName,
    image: image || emrPic,
    type,
    disabled: true,
    size: 'small2',
    isOnlineProp: isOnline,
  }


  let renderAvatar = null;

  if (isSoberCircle) {
    renderAvatar = (
      <Avatar src={soberCircleImage} className={styles.image} />
    )
  } else if (user) {
    renderAvatar = (
      <MemberAvatar {...avatarProps} />
    )
  }

  return (
    <Link to={`/messages/${url}`}>
      <ListItem component="li" className={styles.message}>

        {renderAvatar}

        <Box className={styles.content}>

          <Box className={styles.top_module}>

            <ListItemText className={styles.name} children={
              (isSoberCircle && name) ? name : userName
            } />

            {!!lastMessage && (
              <ListItemText className={styles.timeago} children={moment(lastMessage.createdAt).fromNow()} />
            )}

          </Box>

          <Box className={styles.bottom_module}>
            <ListItemText
              className={cn(styles.text, {
                [styles.isTyping]: userTyping,
                [styles.text]: !unreadMessageCount,
                [styles.textUnread]: !!unreadMessageCount,
              })}
              children={userTyping ? 'Is typing...' : lastMessagePreview}
            />

            {!!unreadMessageCount && (
              <ListItemIcon className={styles.count}>
                <p>{unreadMessageCount}</p>
              </ListItemIcon>
            )}
          </Box>

        </Box>

      </ListItem>
    </Link>
  );
};

export default ChatRoomItem;
