import React from 'react';
import {useParams, useLocation} from 'react-router-dom';

// Components
import PatientModule from './View/View';

// Context
import MemberContextProvider from 'context/Member/MemberContext';

const PatientMember = () => {

  // Route Data
  const {id: memberId} = useParams();
  const location = useLocation();

  // Setup Context
  const contextValues = {
    memberId,
    memberType: 'patient',
  }

  return(
    <MemberContextProvider value={contextValues}>
      <PatientModule
        memberId={memberId}
        status={location?.state?.status}
        activatedAccount={location?.state?.activatedAccount}
        baseUrl="/patients"
      />
    </MemberContextProvider>
  )
}

export default PatientMember;