import React, { useContext } from 'react';

import ReactSVG from 'react-svg';
import { Box } from '@material-ui/core';
import styles from './ChatPage.module.scss';
import TypingBubble from 'common/assets/typingBubble.svg';

import { ChatContext } from 'context/Chat/Chat';

const UsersTyping = ({ memberId , member }) => {

    const { typingUsers } = useContext(ChatContext);
    const memberTyping = typingUsers.indexOf(memberId) !== -1;

    if (!memberId || !memberTyping) return null;

    return (
        <Box key={member?.firstName} className={styles.typing_indicator}>
            <span className={styles.text}>{member?.firstName}</span>
            <ReactSVG src={TypingBubble} className={styles.bubble}/>
        </Box>
    )
};

export default UsersTyping;