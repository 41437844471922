import {gql} from '@apollo/client';
export const GET_OUTLOOK_URL = gql`
  query getOutlookAuthURL{
    getOutlookAuthURL
  }
`;
export const SAVE_OUTLOOK_TOKEN = gql`
mutation saveOutlookToken(
    $code: String!
  ) {
    saveOutlookToken(
      code: $code
    ){
        hasOutlookToken
        outlookExpiresAt
    }
  }
  `;
export const REFRESH_OUTLOOK_TOKEN = gql`
  mutation refreshOutlookToken {
    refreshOutlookToken{
          hasOutlookToken
          outlookExpiresAt
      }
  }
  `;
export const DISCONNECT_OUTLOOK_TOKEN = gql`
  mutation disconnectOutlookToken {
    disconnectOutlookToken{
          hasOutlookToken
          outlookExpiresAt
      }
  }
  `;

export const GET_OUTLOOK_EVENTS = gql`
    query getOutlookEvents(
        $filters: CalendarFilters
      ) {
        getOutlookEvents(
          filters: $filters
        ){
          _id
          name
          startDate
          endDate
          type
          host
        }
      }
  `;
