import { handleActions } from 'redux-actions';
import { 
  setMemberType, 
  setMemberData, 
  setEditMode 
} from 'redux/actions/memberModule';

const defaultState = { 
    memberType: null,
    memberData: {},
    editMode: false,
};

export default handleActions({

    [setMemberType]: (state, { payload }) => ({...state, 
      memberType: payload 
    }),

    [setMemberData]: (state, { payload }) => ({...state, 
      memberData: payload 
    }),

    [setEditMode]: (state, { payload }) => ({...state, 
      editMode: payload 
    }),

},
defaultState);