import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ChatRoomsContainer from 'common/components/Messages/main';
import { ButtonWithIcon } from 'common/components/Button/Button';
import AddIcon from 'common/assets/add.svg';
import styles from './Messages.module.scss';
import Can from '../../hoc/Can';
const RoomsList = () => {
  return (
    <Box className={styles.rooms_wrapper}>
      <Box className={styles.header}>
        <Typography variant="h5" className={styles.title}>
          Messages
        </Typography>
        <Can
          module="MESSAGES"
          perform="ADD_NEW_CHAT_ROOM"
          policy="module_action"
          origin="Messages > Room List"
          yes={() => (
            <ButtonWithIcon classes={styles.new_message} icon={AddIcon}>
              New Message
            </ButtonWithIcon>
          )}
        />

      </Box>
      <ChatRoomsContainer />
    </Box>
  );
};


export default RoomsList;