import React from 'react';
import styles from './MyAccount.module.scss';
import { Grid, Button } from '@material-ui/core';

// Formik
import * as Yup from 'yup';
import { Form, Field, Formik } from 'formik';
import Datepicker from 'common/components/InputFields/KeyboardDatepicker/Datepicker';
import TextField from 'common/components/InputFields/TextField/TextField';
import Dropdown from 'common/components/InputFields/Dropdown/Dropdown';
import { FormDevTools } from 'common/components/Formik/FormDevTools';

// Data
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { EDIT_STAFF, ME_STAFF } from 'data/graphql/staff';
import { getOrganizationName, getOrganizationWebex } from 'selectors';

// Utils
import { useSnackbar } from 'notistack';
import { fuzzyDateToMoment } from 'common/helpers/timeHelpers';
import PhoneInputField from 'common/components/InputFields/PhoneInput/PhoneInputField';
import { phoneValidator } from 'common/helpers/validations';

const AccountProfile = ({ staff }) => {
  const organizationName = useSelector(getOrganizationName);
  const organizationWebexPrefix = useSelector(getOrganizationWebex);

  const titles = ['Dr', 'Mr', 'Mrs', 'Ms', 'Miss'];
  const { enqueueSnackbar } = useSnackbar();

  const [editStaff] = useMutation(EDIT_STAFF, {
    refetchQueries: [{ query: ME_STAFF }],
    onError: () => enqueueSnackbar('Error Updating', { variant: 'error' }),
    onCompleted: () =>
      enqueueSnackbar('Account Updated', { variant: 'success' }),
  });

  const handleAccountProfileEdit = async data => {
    console.log('[Account] handleEdit raw data: ', data);
    const staffDataToMutate = {
      staffId: staff._id,
      staff: {
        firstName: data.firstName,
        lastName: data.lastName,
        jobTitle: data.jobTitle,
        title: data.title,
        birthDate: data.birthDate,
        phone: data.phone,
        webexLink: data.webexLink,
      },
    };
    console.log('[Account] handleEdit variables: ', staffDataToMutate);
    try {
      await editStaff({ variables: staffDataToMutate });
    } catch (err) {
      console.log('[Account] Update Profile mutation failed: ', err);
    }
  };

  const ClinicianProfileSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'Please enter a longer First Name')
      .max(50, 'Please enter a short First Name')
      .required('Required'),
    lastName: Yup.string()
      .min(1, 'Please enter a longer Last Name')
      .max(50, 'Please enter a short Last Name')
      .required('Required'),

    phone: Yup.string().test('phone', 'Invalid phone number', val =>
      phoneValidator(val)
    ),
  });

  const initialValues = {
    title: staff.title,
    jobTitle: staff.jobTitle,
    firstName: staff.firstName,
    lastName: staff.lastName,
    accreditations: staff.accreditations,
    birthDate: staff.birthDate ? fuzzyDateToMoment(staff.birthDate) : '',
    phone: staff.phone,
    location: staff?.location?.name,
    department: staff?.department?.name,
    webexLink: staff.webexLink,
  };

  const formikProps = {
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ClinicianProfileSchema,
    onSubmit: handleAccountProfileEdit,
  };

  return (
    <Formik {...formikProps}>
      {({ values, errors }) => (
        <Form>
          <Grid
            container
            xs={9}
            spacing={1}
            direction="column"
            justifyContent="space-between"
            className={styles.container}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className={styles.section_title}>Personal Details</label>
              </Grid>
              <Grid item xs={12} />

              <Grid item xs={12}>
                <label htmlFor="title">Title</label>
                <Field
                  name="title"
                  component={Dropdown}
                  value={values.title || ''}
                  items={titles}
                  renderValue={v => `${v}`}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="firstName">First Name</label>
                <Field name="firstName" component={TextField} />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="lastName">Last Name</label>
                <Field name="lastName" component={TextField} />
              </Grid>
              <Grid item xs={12} md={12}>
                <label htmlFor="jobTitle">Job Title</label>
                <Field name="jobTitle" component={TextField} />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="birthDate">Date of birth</label>
                <Field
                  name="birthDate"
                  component={Datepicker}
                  disablePast={false}
                  openTo= {"year"}
                  views= {["year", "month", "date"]}
                  format={"YYYY-MM-DD"}
                />
              </Grid>
              <Grid item xs={12} />

              <Grid item xs={12}>
                <label className={styles.section_title}>Contact Details</label>
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="phone">Phone Number</label>
                <Field name="phone" component={PhoneInputField} />
              </Grid>

              <Grid item xs={12}>
                <label className={styles.section_title}>
                  {organizationName}
                </label>
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="location">Location</label>
                <Field name="location" component={TextField} disabled={true} />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="department">Department</label>
                <Field
                  name="department"
                  component={TextField}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <label htmlFor="webexLink">Personal Webex Link</label>

                <Field
                  name="webexLink"
                  component={TextField}
                  prefix={organizationWebexPrefix}
                />
              </Grid>

              <Grid item xs={12}>
                <Button color="primary" variant="contained" type="submit">
                  Update Profile
                </Button>
              </Grid>

              <Grid item xs={12}>
                <FormDevTools values={values} errors={errors} />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AccountProfile;
