import React, {useState} from 'react';
import { default as MUIAutocomplete } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loading from '../Loading';


export const Autocomplete = ({ options = [], invalid, error, onChange, onSelect, onOpen, value = '', inputRef, loading, loadingCircle, ...props }) => {
  const [open, setOpen] = useState(false);
  
  if (loading) return <Loading />;

  return (
    <MUIAutocomplete
      // filterOptions={options => options}
      open={open}
      onOpen={() => {
        setOpen(true);
        onOpen && onOpen();
        // setEditing(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // getOptionSelected={option => option.label}
      getOptionLabel={item => item?.label || item}
      options={options}
      loading={loading}
      onChange={onSelect}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          // placeholder={placeholder}
          variant="outlined"
          inputRef={inputRef}
          error={invalid}
          helperText={invalid && error?.message}
          size="small"
          onChange={onChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingCircle ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...props}
    />
  );
}