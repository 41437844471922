import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AvatarGroup as AvatarGroupMui } from '@material-ui/lab/';
import PropTypes from 'prop-types';
import MemberAvatar from '../Avatar/MemberAvatar';

const MemberAvatarGroup = withStyles({
    avatar: {
        border: '2px solid #fff',
        borderRadius: '50%' 
    }
})(AvatarGroupMui)

const AvatarGroup = ({members, max, size, disabled}) => {
    return (
        <MemberAvatarGroup max={max || 3}>
            {members.map( (member, index) => 
                <MemberAvatar key={index} {...member} size={size} disabled={disabled} />
            )}
        </MemberAvatarGroup>
    )
}

export default AvatarGroup;

AvatarGroup.propTypes = {
    members: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    max: PropTypes.number,
    size: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'small2', 'medium', 'large', 'xlarge']),
};  
