import React, { useEffect } from 'react';
import moment from 'moment';

// MUI
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import CallList from 'common/components/Schedule/ShortSchedule/CallList/CallList';

// Data
import { useQuery } from '@apollo/client';
import { GET_CALL_ACTIVITIES } from 'data/graphql/activity';
import useActivities from 'common/hooks/useActivitiesState';
import { Messages } from 'config/settings';

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    border: '1px solid #E0E0E0',
  },
  title: {
    height: 64,
    display: 'flex',
    fontSize: '18px',
    padding: '0 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    fontFamily: 'Avenir Next Demi Bold',
  },
  body: {
    height: 425,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  nocalls: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    fontSize: 24,
    opacity: '.6',
    fontFamily: 'Avenir Next Regular',
  }
});

const Schedule = ({ id, firstName }) => {

  const classes = useStyles();

  const [events, setEvents] = useActivities([]);
  
  const scheduleRange = 'day';

  const { data: activitiesList, loading, error } = useQuery(GET_CALL_ACTIVITIES, {
    variables: {
      filters: {
        staffId: id,
        startDate: moment().startOf(scheduleRange),
        endDate: moment().endOf(scheduleRange),
        type: ['therapySession', "familySession"],
      },
    },
  });

  useEffect(() => {
    if (activitiesList) {
      const { getCallActivities } = activitiesList;

      const filteredEvents = getCallActivities.filter(
        ({ status }) => status !== 'CANCELLED'
      );

      setEvents(filteredEvents);

    }
  }, [activitiesList]);

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.title}>{firstName ? `${firstName}'s ` : ''}Schedule</div>
      <div className={classes.body}>
        {events.length ? (
          <CallList calls={events}/>
        ) : (
          <div className={classes.nocalls}>{Messages.CALLDASH_SCHEDULE_EMPTY}</div>
        )}
      </div>
    </Paper>
  )
}

export default Schedule;