import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingBottom: '20px',
    minHeight: '300px',
    textAlign: 'center',

  },
  dialogTitle: {
    color: '#000',
    fontSize: '32px',
    marginBottom: 27,
    fontWeight: '600',
    lineHeight: '26px',
    fontFamily: 'Avenir Next',
  },
  dialogText: {
    color: '#000',
    fontSize: '16px',
    padding: '0 120px',
    lineHeight: '22px',
    fontFamily: 'Avenir Next',
  },
  dialogWarning: {
    width: '75%',
    margin: '10px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: 'rgb(253, 236, 234)',
  },
  warningText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#f44336',
    paddingTop: 15,
  },
  warningHelp: {
    fontSize: 12,
    color: '#828282',
    '&:hover': {
      color: '#2A96FF'
    },
  },
  footerActions: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: 58,
    }
  },
  button: {
    minWidth: 138,
    paddingTop: 11,
    borderRadius: 24,
    paddingBottom: 10,
    borderColor: theme.palette.primary.main,
  },
  buttonLabel: {
    fontSize: 20,
  },
}));

const ActivateMember = ({
  open, 
  handleAccept,
  handleClose, 
  loading, 
  title, 
  text, 
  showSuccess, 
  successMessage,
  acceptBtnText = 'Submit', warningDetails
}) => {

  const classes = useStyles();

  const btnProps = {
    color: "primary",
    classes: {
      root: classes.button,
      label: classes.buttonLabel,
    }
  }

  const btnCancelProps = {
    ...btnProps,
    variant: "outlined",
    onClick: handleClose,
  }

  const btnSubmitProps = {
    ...btnProps,
    disabled: loading,
    variant: "contained",
    onClick: handleAccept,
  }
  const warningActive = warningDetails?.length > 0;

  return (
    <Dialog open={open} onClose={handleClose}>

      <DialogContent className={classes.root} children={
        showSuccess ? (
          <Fragment>
            <div className={classes.dialogTitle} children={successMessage.title} />
            {successMessage.text && (
              <div className={classes.dialogText} children={successMessage.text} />
            )}
          </Fragment>
        ) : (
            <Fragment>

              <div className={classes.dialogTitle} children={title} />
              <div className={classes.dialogText} children={text} />

              {warningActive &&
                <Box className={classes.dialogWarning}>
                  You must setup the following before activating:
                {warningDetails.map(({ item, help, route }) => (
                  <div key={item}>
                    <span className={classes.warningText}>{item}</span>
                    <Link to={route} onClick={handleClose} className={classes.warningHelp}>{help}</Link>
                  </div>
                ))}
                </Box>
              }

              <div className={classes.footerActions}>
                <Button {...btnCancelProps} children="Cancel" />
                <Button {...btnSubmitProps} disabled={warningActive ? true : false} children={loading ? 'Submitting' : acceptBtnText} />
              </div>

            </Fragment>
          )
      } />

    </Dialog>
  )
}

ActivateMember.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  acceptBtnText: PropTypes.string,
}

export default ActivateMember;
