import React from 'react';

/**
 * ConditionalComponent renders appropriate component based on condition
 * @param {*} condition condition to check
 * @param {Function} Component component to render if true
 * @param {Function} FalseComp component to render if false
 * @returns {Function} component based on condition
 */
const ConditionalComponent = ({
  condition, 
  Component = () => null, 
  FalseComp = () => null,
}) => {
  return <>
  {condition 
    ? <Component /> 
    : <FalseComp />
  }
  </>
}

export default ConditionalComponent;