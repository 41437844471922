import React from 'react';
import PropTypes from 'prop-types';
import momentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { useController } from 'react-hook-form';
import Loading from '../Loading';

const Time = ({
  control,
  name,
  defaultValue = '',
  required = false,
  loading,
  className,
  disable,
  onChange,
  ...props
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  if (loading) return <Loading />;

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <KeyboardTimePicker
        {...inputProps}
        autoOk
        variant="inline"
        inputVariant="outlined"
        size="small"
        // format="MM/dd/yyyy : hh:mm"
        InputAdornmentProps={{ position: 'start' }}
        inputRef={ref}
        error={invalid}
        helperText={error?.message}
        className={className}
        minutesStep="15"
        disabled={disable ? true : false}
        {...props}
        // onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

Time.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};

export default Time;
