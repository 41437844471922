import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import cn from 'classnames';
import MomentUtils from '@date-io/moment';
import styles from './Datepicker.module.scss';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const materialTheme = createTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        marginLeft: '10px',
        marginTop: '10px',
        borderRadius: '10px',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        order: -1,
        fontFamily: '"Avenir Demi Bold"',
        textAlign: 'left',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#2A96FF',
      },
    },
  },
  input: {
    fontSize: '12px',
  },
});

const Datepicker = ({
  field,
  currentDate,
  format = "MM/DD/YYYY",
  form: { setFieldValue },
  ...restProps
}) => {

  const handleOnChange = (value) => {
    setFieldValue(field.name, moment(value).format(format));
    if (restProps.onChange) restProps.onChange(restProps.id, moment(value).format(format))
  }

  return(
  <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        clearable
        disableToolbar
        format={format}
        orientation="landscape"
        variant="outlined"
        placeholder="Type a date"
        value={field.value || null}
        selected={field.value ? moment(field.value) : null}
        onChange={handleOnChange}
        className={cn(restProps.className, {
          [styles.disabled]: restProps.disabled
        })}
        InputProps={{
          disableUnderline: true,
        }}
        InputAdornmentProps={{
          position: 'start',
          classes: { root: styles.filled },
        }}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
)
};

export default Datepicker;
