import React, { useEffect } from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import styles from './CalendarsBox.module.scss';
import Checkbox from 'common/components/InputFields/LabelCheckbox/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleMySchedule,
  toggleGoogleCalendar,
  toggleOutlookCalendar,
} from 'actions/scheduler';
import { selectMember } from 'actions/appointmentBooker';
import _ from 'lodash';

const theme = createTheme({});

theme.overrides = {
  MuiOutlinedInput: {
    root: {
      borderColor: '#CDD4DA',
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#2482dd',
        borderWidth: 1,
      },
      '&$focused $notchedOutline': {
        borderColor: '#2482dd',
        borderWidth: 1.8,
      },
    },
    input: {
      padding: '12px 18px',
      fontFamily: 'Avenir Next Regular',
    },
  },
};

const CalendarsBox = props => {
  const { patients, onSelectChange } = props.patientSelector;
  const dispatch = useDispatch();
  const scheduler = useSelector(state => state.scheduler);
  const {
    myAvailability,
    mySchedule,
    googleCalendar,
    outlookCalendar,
    type,
    patient,
  } = scheduler;

  useEffect(() => {
    if (
      _.some([googleCalendar, outlookCalendar], i => i === true) &&
      !myAvailability
    ) {
      onSelectChange('patient', null);
      dispatch(selectMember(null));
    }
  }, [
    googleCalendar,
    outlookCalendar,
    myAvailability,
    dispatch,
    onSelectChange,
    type,
  ]);

  useEffect(() => {
    if (mySchedule && !myAvailability) {
      dispatch(selectMember(null));
      onSelectChange('type', 'mySessions');
      onSelectChange('patient', null);
    } else {
      onSelectChange('type', null);
    }
  }, [mySchedule, onSelectChange, dispatch, type, myAvailability]);

  useEffect(() => {
    if (patient && !myAvailability) {
      dispatch(toggleMySchedule(false));
      dispatch(toggleGoogleCalendar(false));
      dispatch(toggleOutlookCalendar(false));
    }
  }, [myAvailability, patient, dispatch]);

  useEffect(() => {
    if (patient) {
      if (
        _.some([mySchedule, googleCalendar, outlookCalendar], i => i === true)
      ) {
        dispatch(toggleMySchedule(false));
        dispatch(toggleGoogleCalendar(false));
        dispatch(toggleOutlookCalendar(false));
      }
    }
  }, [patient, dispatch]);

  const handleChangeCheckbox = (value, action) => {
    if (scheduler.myAvailability && scheduler.patient) return;
    else dispatch(action(value));
  };

  const handleACOnChange = (_, selected) => {
     if (!selected) {
      onSelectChange('patient', null);
      dispatch(toggleMySchedule(true));
    } else {
      onSelectChange('patient', selected._id);
    }
  }

  const patientCalACProps = {
    options: patients,
    value: !patient ? null : patients.find(p => p._id === patient),
    getOptionLabel: option => option && `${option.firstName} ${option.lastName}`,
  }
  return (
    <Box className={styles.wrapper}>

      <Typography className={styles.title}>
        Calendars
      </Typography>

      <Box>
        <MuiThemeProvider theme={theme}>
          <Autocomplete
            {...patientCalACProps}
            onChange={handleACOnChange}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder="View Patient Calendar"
              />
            )}
          />
        </MuiThemeProvider>
      </Box>

      <Box className={styles.list}>
      
        <span className={styles.section_title}>
          My Calendars
        </span>

        <Checkbox
          label="inRecovery"
          checked={mySchedule}
          disabled={myAvailability && patient}
          onClick={e =>
            handleChangeCheckbox(e.target.checked, toggleMySchedule)
          }
        />
        <Checkbox
          label="Outlook Calendar"
          checked={outlookCalendar}
          disabled={myAvailability && patient}
          onClick={e =>
            handleChangeCheckbox(e.target.checked, toggleOutlookCalendar)
          }
        />
        <Checkbox
          label="Google Calendar"
          checked={googleCalendar}
          disabled={myAvailability && patient}
          onClick={e =>
            handleChangeCheckbox(e.target.checked, toggleGoogleCalendar)
          }
        />
      </Box>
      
    </Box>
  );
};

export default CalendarsBox;
