import React, { useState } from 'react';
import styles from './styles.module.scss';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Typography from '@material-ui/core/Typography'

/**
 * GroupedDropdown with flexible optgroup schema
 * @param {string} groupSchema.groupValue - Optgroup React Key.
 * @param {string} groupSchema.groupLabel - Optgroup Label.
 * @param {Array} groupSchema.subOptions - Sub Options Array.
 * @param {string} groupSchema.subValue - Sub Option Value (also used as React Key).
 * @param {string} groupSchema.subLabel - Sub Option Label.
 * @param {Object} groupSchema - Schema for GroupedDropdown Component.
 * @param {Array} options - Options (Array of Objects).
 * @returns {Component} Formik GroupedDrowdown Component.
 */
const GroupedDropdown = ({
    field: { name, value },
    form: { setFieldValue, touched, errors },
    renderValue,
    width,
    options,
    labelKey,
    groupSchema: { 
        groupValue, groupLabel, 
        subOptions, subValue, subLabel 
    },
    ...props
}) => {
    const [localValue, setValue] = useState(value);
    const handleChange = event => {
        setValue(event.target.value);
        setFieldValue(name, event.target.value);
        //console.log('Selected value', localValue);
        //console.log('Setting value to =>', value);
    };
    return (
        <FormControl className={styles.container}>
            <NativeSelect
                {...props}
                value={localValue}
                displayEmpty={true}
                id={`${name}-dropdown`}
                onChange={e => handleChange(e)}
                classes={{ root: styles.input }}>
                <option value="">Choose an option</option>
                {options?.map(op => 
                    <optgroup key={op[groupValue]} label={op[groupLabel]}>
                        {op[subOptions]?.map(sub => 
                            <option key={sub[subValue]} value={sub[subValue]}>{sub[subLabel]}</option>
                        )}
                    </optgroup>
                )}
            </NativeSelect>
            {touched[name] && errors[name] && (
                <Typography className={styles.error}>{errors[name]}</Typography>
            )}
        </FormControl>
    );
};

export default GroupedDropdown;
