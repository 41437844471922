import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'next':
      const { nextStatus } = state.currentStep;
      return {
        ...state,
        data: action.data,
        currentStep: state.steps.find(step => step.status === nextStatus) || state.currentStep,
      };
    case 'prev':
      const { prevStatus } = state.currentStep;
      return {
        ...state,
        data: action.data,
        currentStep: state.steps.find(step => step.status === prevStatus) || state.currentStep,
      };
    case 'reset':
      return {
        ...state,
        data: action.data,
        currentStep: state.steps[0]
      }
    case 'goto':
      return {
        ...state,
        data: action.data,
        currentStep: state.steps.find(step => step.status === action.step) || state.currentStep,
      };
    default:
      return { ...state };
  }
}

const useStepState = (steps, initialIndex = 0) => {
  const initialState = {
    steps,
    currentStep: steps[initialIndex],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const next = (data) => {
    dispatch({ type: 'next', data });
  };

  const previous = data => {
    dispatch({ type: 'prev', data });
  };

  const reset = data => {
    dispatch({ type: 'reset', data });
  };

  const goTo = (data, step) => {
    dispatch({ type: 'goto', data, step})
  }

  return [
    props =>
      state.currentStep.component({
        next,
        previous,
        goTo,
        hasNext: state.currentStep.nextStatus ? true : false,
        hasPrev: state.currentStep.prevStatus ? true : false,
        data: state.data,
        ...props,
      }),
    state.currentStep.payload,
    reset
  ];
};

export default useStepState;
