import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import {checkPermissions} from 'data/permissions';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Box, Tabs, Tab, Breadcrumbs } from '@material-ui/core';

import styles from './PanelHeader.module.scss';
import { ButtonWithIcon } from 'common/components/Button/Button';
import OverflowMenu from '../Nav/OverflowMenu';
import {useAuthZContext} from 'context/Auth/useAuthContext';

const Header = (
  { listBreadcrumbs, overflowMenu, listButtons, listTabs, onTabChange},
  ref
) => {
  // State
  const { pathname } = useLocation();
  const hasTabs = !!(listTabs && listTabs.length);
  const [tabValue, setTabValue] = React.useState(0);
  const {permissions} = useAuthZContext();

  const [dotsMenu, setDotsMenu] = useState([]);
  const [listTabsState, setListTabsState] = useState([]);
  const [listButtonsState, setListButtonsState] = useState([]);

  useEffect(() => {
    //Set active tab index if has url match
    hasTabs &&
      listTabs.forEach((tab, i) => {
        const hasUrl = tab.hasOwnProperty('url');
        if (hasUrl && tab.url === pathname) {
          setTabValue(i);
        }
      });

    filterTabs();
    filterButtons();
    filterDots();
  }, [listTabs, overflowMenu, listButtons]);


  // Methods
  const filterTabs = () => {
    const updatedTabs = (listTabs || []).filter(({ permission }) => {
      if (!permission) return true;
      return checkPermissions({
        permissions, 
        module: permission.module,
        action: permission.perform,
        memberType: permission.memberType, 
        memberId: permission.memberId, 
        policy: permission.policy,
        origin: permission.origin,
      });
    });

    setListTabsState(updatedTabs);
  };

  const filterButtons = () => {
    const updatedButtons = (listButtons || []).filter(({ permission }) => {
      if (!permission) return true;
      return checkPermissions({
        permissions,
        module: permission.module,
        action: permission.perform,
        memberType: permission.memberType, 
        memberId: permission.memberId, 
        policy: permission.policy,
        origin: permission.origin,
      });
    });

    setListButtonsState(updatedButtons);
  };

  const filterDots = () => {
    const updatedDots = (overflowMenu || []).filter(({ permission }) => {
      if (!permission) return true;
      return checkPermissions({
        permissions,
        module: permission.module,
        action: permission.perform,
        memberType: permission.memberType, 
        memberId: permission.memberId, 
        policy: permission.policy,
        origin: permission.origin,
      });
    });

    setDotsMenu(updatedDots);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useImperativeHandle(ref, () => ({
    resetTabIndex(index = 0) {
      handleTabChange(null, index);

      setDotsMenu(overflowMenu);
      setListTabsState(listTabs);
      setListButtonsState(listButtons);
    },
  }));

  // Outsourced properties
  const breadcrumbsProps = {
    'aria-label': 'breadcrumb',
    className: styles.breadcrumbs,
    separator: <NavigateNextIcon fontSize="small" />,
  };

  const tabsProps = {
    value: tabValue,
    onChange: handleTabChange,
    variant: "scrollable",
    scrollButtons: "auto",
    textColor: 'primary',
    indicatorColor: 'primary',
    classes: { indicator: styles.custom_indicator },
    className: styles['header-tabs'],
  };

  const tabProps = (index, label) => ({
    label: label,
    classes: {
      root: styles.custom_tab,
      wrapper: styles.custom_tab_label,
    },
  });

  // Breadcrumbs
  const RenderBreadcrumbs = () =>
    listBreadcrumbs && listBreadcrumbs.length ? (
      <Breadcrumbs {...breadcrumbsProps}>
        {listBreadcrumbs.map(({ name, url, disabled }, index) => (
          <Link
            key={index}
            to={url}
            className={classNames({
              [styles.disabled]: disabled,
            })}
          >
            {name}
          </Link>
        ))}
      </Breadcrumbs>
    ) : null;

  // Overflow Menu
  const RenderDotsMenu = () => {
    const hasMenu = !!(dotsMenu && dotsMenu.length);
    if (!hasMenu) return null;
    return !!dotsMenu.length && <OverflowMenu list={dotsMenu} />;
  };

  // Action Buttons: Eg. + Register Lab Request
  const RenderButtons = () => {
    if (!listButtonsState || !listButtonsState.length) return null;

    const ButtonItem = ({ title, url, icon, onClickProps, disabled }) =>
      icon ? (
        <ButtonWithIcon
          {...onClickProps}
          icon={icon}
          disabled={disabled}
          classes={styles['btn-header']}
        >
          {title}
        </ButtonWithIcon>
      ) : url ? (
        <Link to={url}>
          <button
            {...onClickProps}
            disabled={disabled}
            className={styles['btn-header']}
          >
            {title}
          </button>
        </Link>
      ) : (
        <button
          {...onClickProps}
          disabled={disabled}
          className={styles['btn-header']}
        >
          {title}
        </button>
      );

    return (
      <React.Fragment>
        {listButtonsState.map((item, index) => {
          const onClickProps = {};
          if (item.onClick) {
            onClickProps.onClick = item.onClick;
          }
          return (
            <ButtonItem key={index} {...item} onClickProps={onClickProps} />
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Box className={styles.wrapper}>
      <RenderBreadcrumbs />

      {hasTabs && (
        <Tabs {...tabsProps}>
          {listTabsState.map((tab, index) => {
            const urlProps = {};
            const tabIndex = tab.index ? tab.index : index;

            if (tab.url) {
              urlProps.to = tab.url;
              urlProps.component = Link;
            }

            return (
              <Tab
                key={tabIndex}
                {...tabProps(tabIndex, tab.label)}
                {...urlProps}
              />
            );
          })}
        </Tabs>
      )}

      <Box className={styles.menu}>
        <RenderButtons />
        <RenderDotsMenu />
      </Box>
    </Box>
  );
};

// Header.propTypes = {
//   listBreadcrumbs: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       url: PropTypes.any,
//       disabled: PropTypes.bool,
//     })
//   ).isRequired,
//   overflowMenu: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       src: PropTypes.string,
//       onClick: PropTypes.func,
//       permission: PropTypes.shape({
//         module: PropTypes.string.isRequired,
//         perform: PropTypes.string.isRequired,
//         patientId: PropTypes.string.isRequired,
//       }),
//     })
//   ),
//   listButtons: PropTypes.arrayOf(
//     PropTypes.shape({
//       icon: PropTypes.any,
//       disabled: PropTypes.bool,
//       title: PropTypes.string.isRequired,
//       onClick: PropTypes.func,
//     })
//   ),
//   listTabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       index: PropTypes.number,
//       label: PropTypes.string.isRequired,
//       url: PropTypes.any,
//     })
//   ),
//   onTabChange: PropTypes.func,
// };

export default forwardRef(Header);
