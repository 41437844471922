import React from 'react';
import moment from 'moment';
import { LabAction } from "common/components/Labs/LabAction"


export const toTableData = data => !data ? [] : data.map((item, i) => ({
  date: moment(item.completedAt).format('MM-DD-YYYY'), // MMM D
  surveyId: item.survey?.id,
  status: <StatusIcon label="Completed" />,
  response: JSON.stringify(item.response), //<LabStatusMap name="resultLabel" label={item.result} />,
  more: "View Response", //<LabAction name="moreLabel" item={item} />
}));


const StatusIcon = ({label}) => {
  return <div style={{ display: 'flex', alignItems: 'center' }}>
    {label && <span style={{ height: 8, width: 8, marginRight: 6, borderRadius: '50%', backgroundColor: "#00B318" }} />}
    {label}
  </div>
}

