import {gql} from '@apollo/client';
import { NOTE_FIELDS } from './notes';

export const GET_FAMILY_MEMBERS = gql`
  query getFamilyMembersList($filters: FamilyFilter!) {
    getFamilyMembersList(filters: $filters) {
      _id
      firstName
      lastName
    }
  }
`;

export const GET_PATIENT_RELATIONS = gql`
  query getPatientRelations($patientId: ObjectID!) {
    getPatientRelations(patientId: $patientId) {
      _id
      relation {
        name
      }
      releaseType {
        name
      }
      memberId
      firstName
      lastName
    }
  }
`;

export const SET_PATIENT_RELATIONS = gql`
  mutation put($patientId: ObjectID!, $content: [MemberRelationInput!]) {
    setPatientRelations(patientId: $patientId, content: $content) {
      _id
      relation {
        name
      }
      releaseType {
        name
      }
      memberId
      firstName
      lastName
    }
  }
`;

export const REMOVE_RELATION = gql`
  mutation remove($patientId: ObjectID!, $relationId: ObjectID!) {
    removePatientRelation(patientId: $patientId, relationId: $relationId) {
      _id
    }
  }
`;

export const GET_FAMILY_MEMBER = gql`
  query getFullFamilyMemberInfo($memberId: ObjectID!) {
    getFullFamilyMemberInfo(memberId: $memberId) {
      _id
      status
      username
      averageRating
      # program
      firstName
      lastName
      image
      type
      title
      mrn
      phone
      active
      email
      gender
      tags
      alerts
      admitDate
      birthDate
      locationId
      dischargeDate
      emotionalState
      activatedAccount
      organizationId
      encounterId
      # primaryStaffNo
      # secondaryStaffNo
      relatedFamily {
        _id
        lastName
        firstName
        image
        phone
        email
        activatedAccount
        # address {
        #   street1
        #   street2
        #   city
        #   country {
        #     code
        #     name
        #   }
        #   state
        #   zip
        # }
      }
      relatedPatients {
        _id
        lastName
        firstName
        image
        phone
        email
        # address {
        #   street1
        #   street2
        #   city
        #   country {
        #     code
        #     name
        #   }
        #   state
        #   zip
        # }
      }
      careTeamDetails {
        _id
        lastName
        firstName
        image
        primary
        locationName
        phone
        email
      }
      address {
        street1
        street2
        city
        country {
          code
          name
        }
        state
        zip
      }
      notes {
        ${NOTE_FIELDS}
      }
      deviceType
      appleId
      googleId
      cognitoInfo {
        status
      }
      program{
        caronId
        name
        displayName
      }
      uiNotes{
        type
        title
        message
        actions
      }
    }
  }
`;

export const EDIT_FAMILY_MEMBER = gql`
  mutation updateFamilyMember($memberId: ObjectID!, $familyInfo: FamilyMemberUpdate) {
    updateFamilyMember(memberId: $memberId, familyInfo: $familyInfo) {
      _id
    }
  }
`;
