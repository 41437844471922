import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, Box } from '@material-ui/core';

// Components
import HeaderContainer from '../TopModule/HeaderContainer';

const MemberTabsSkeleton = ({items = 4}) => {
  return (
    <Box height={60} display="flex" alignItems="center" style={{
      paddingLeft: 40,
      backgroundColor: '#fff',
      borderRadius: '8px 8px 0 0',
      borderBottom: '1px solid #E0E0E0',
    }}>
      <Skeleton animation="wave" width={140} height={30} variant="text"/>
      <Box ml={2} children={<Skeleton animation="wave" width={140} height={30} variant="text"/>}/>
      <Box display="flex" style={{ marginLeft: 90 }} children={
        Array(items).fill().map((_, i) => (
          <Box key={i} mr={2} children={<Skeleton animation="wave" width={70} height={26} variant="text"/>}/>
        ))
      }/>
    </Box>
  )
}

const MemberHeaderSkeleton = ({progressItems = 3, actionItems = 3, minHeight = 150}) => {
  return (
    <HeaderContainer>
      <HeaderAvatarSkeleton />
      <HeaderProgressSkeleton items={progressItems} />
      <HeaderActionsSkeleton items={actionItems} />
    </HeaderContainer>
  )
}

const HeaderAvatarSkeleton = () => {
  return (
    <Box display="flex" alignItems="center">
      <Skeleton variant="circle" width={85} height={85}/>
      <Box display="flex" flexDirection="column" justifyContent="center" style={{ marginLeft: 25 }}>
        <Box children={<Skeleton animation="wave" style={{ margin: 0 }} variant="text" width={140} height={30}/>}/>
        <Box children={<Skeleton animation="wave" style={{ margin: 0 }} variant="text" width={100} height={30}/>}/>
      </Box>
    </Box>
  )
}

const HeaderProgressSkeleton = ({items}) => {
  return (
    <Box ml={6}>
      {Array(items).fill().map((_, i) => <Skeleton key={i} style={{ margin: '10px 0' }} variant="text" width={150} height={16}/>)}
    </Box>
  )
}

const HeaderActionsSkeleton = ({items}) => {
  return (
    <Box ml='auto' display="flex" style={{marginTop: -15}}>
      {Array(items).fill().map((_, i) => (
        <Box key={`actions-${i}`} flexDirection="column" justifyContent="center" alignItems="center" style={{marginRight: 15}}>
          <Box key={`count-${i}`} mt={1} children={<Skeleton animation="wave" variant="text" width={100} height={80}/>}/>
          <Box key={`text-${i}`} mt={1} children={<Skeleton animation="wave" variant="text" width={100} height={20}/>}/>
        </Box>
      ))}
    </Box>
  )
}

const MemberBodySkeleton = () => {
  return (
  <Grid container spacing={3} style={{ padding: '0 30px' }}>
      <Grid item sm={4}><Skeleton animation="wave" height={500}/></Grid>
      <Grid item sm={8}><Skeleton animation="wave" height={500}/></Grid>
  </Grid>
  )
}

const MemberFullSkeleton = () => {
  return (
    <Box>
      <MemberTabsSkeleton />
      <MemberHeaderSkeleton />
      <MemberBodySkeleton />
    </Box>
  )
}

export {
  MemberFullSkeleton, 
  MemberTabsSkeleton, 
  MemberBodySkeleton, 
  MemberHeaderSkeleton, 
  HeaderActionsSkeleton, 
  HeaderProgressSkeleton,
};