import React from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Box } from '@material-ui/core';
import { EDIT_FAMILY_MEMBER, GET_COUNTRIES } from 'data/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  emailValidator,
  trimObjectStrings,
  phoneValidator,
} from 'common/helpers/validations';
import FormSkeleton from 'common/components/Formik/FormSkeleton/FormSkeleton';
import {
  FormBuilder,
  toInitialValues,
} from 'common/components/Formik/FormBuilder/FormBuilder';
import { Texture } from '@material-ui/icons';
import { FormDevTools } from 'common/components/Formik/FormDevTools';

const useStyles = makeStyles({
  formTitle: {
    fontSize: 20,
    marginBottom: 28,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, .8)',
  },
});

const Profile = ({
  loading,
  error,
  data,
  avatarFile,
  refetch,
  refetchDashboard,
}) => {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Methods
  const onFormSubmit = ({
    firstName,
    lastName,
    street1,
    street2,
    city,
    state,
    zip,
    country,
    birthDate,
    phone,
    email,
    gender,
    deviceType,
    appleId,
    googleId,
  }) => {
    const updatedMember = {
      firstName,
      lastName,
      phone,
      birthDate,
      gender,
      address: {
        street1,
        street2,
        city,
        state,
        zip,
        country: {
          //code: ,
          name: country,
        },
      },
      ...(!data?.activatedAccount ? { email } : {}),
      ...(avatarFile ? { imageContent: avatarFile } : {}),
      deviceType: deviceType,
      appleId: deviceType === 'ios' ? appleId : null,
      googleId: deviceType === 'android' ? googleId : null,
    };

    editMember({
      variables: {
        memberId: data?._id,
        familyInfo: trimObjectStrings(updatedMember),
      },
    });
  };

  // Query
  const { data: dataCountries } = useQuery(GET_COUNTRIES);
  const countries = dataCountries?.getCountries.map(c => c.name) || [];

  // Mutations
  const [editMember, { loading: editLoading }] = useMutation(
    EDIT_FAMILY_MEMBER,
    {
      onError: () =>
        enqueueSnackbar('Error during update', { variant: 'error' }),
      onCompleted: () => {
        enqueueSnackbar('Account updated', { variant: 'success' });

        setTimeout(() => {
          try {
            refetch();
            refetchDashboard();
          } catch (error) {
            console.log(error);
          }
        }, 250);
      },
    }
  );

  // Form Validation
  const validationSchema = Yup.object().shape({
    email: emailValidator('email', 'Email is not valid'),
    firstName: Yup.string()
      .trim()
      .required("First Name can't be empty"),
    lastName: Yup.string()
      .trim()
      .required("Last Name can't be empty"),
    zip: Yup.number().typeError('Should be a number'),
    phone: Yup.string().test('phone', 'Invalid phone number', val =>
      phoneValidator(val)
    ),
    appleId: emailValidator('appleId', 'Apple ID has to be a valid email ').nullable(),
    googleId: emailValidator('googleId', 'Google ID has to be a valid email').nullable(),
  });

  // Render Props
  const fieldsGridDetails = [
    [
      {
        name: 'firstName',
        title: 'First Name',
        value: data?.firstName || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'lastName',
        title: 'Last Name',
        value: data?.lastName || '',
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        type: 'date',
        name: 'birthDate',
        title: 'Birthdate',
        value: data?.birthDate || new Date(),
        openTo: "year",
        views:["year", "month", "date"],
        format:"YYYY-MM-DD",
        grid: { xs: 12, sm: 6 },
      },
      {
        type: 'radio',
        name: 'gender',
        title: 'Sex',
        value: data?.gender || 'f',
        options: [
          { value: 'f', label: 'Female' },
          { value: 'm', label: 'Male' },
        ],
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        type: 'email',
        name: 'email',
        title: 'Email',
        disabled: !!data?.activatedAccount,
        value: data?.email || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'phone',
        title: 'Phone Number',
        type: 'phoneinput',
        value: data?.phone || '',
        grid: { xs: 12, sm: 6 },
      },
    ],
  ];

  const fieldsGridDevice = [
    [
      {
        type: 'radio',
        name: 'deviceType',
        title: 'Device Type',
        value: data?.deviceType,
        options: [
          { value: 'ios', label: 'Apple' },
          { value: 'android', label: 'Android' },
          { value: 'unknown', label: 'Unknown' },
        ],
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'appleId',
        title: 'Apple ID',
        value: data?.appleId || '',
        disabled: data?.deviceType !== 'ios',
        disabledCondition: values => values['deviceType'] !== 'ios',
        hiddenCondition: values => values['deviceType'] !== 'ios',
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'googleId',
        title: 'Google ID',
        value: data?.googleId || '',
        disabled: data?.deviceType !== 'android',
        disabledCondition: values => values['deviceType'] !== 'android',
        hiddenCondition: values => values['deviceType'] !== 'android',
        grid: { xs: 12, sm: 6 },
      },
    ],
  ];

  const fieldsGridAddress = [
    [
      {
        name: 'street1',
        title: 'Address Line 1',
        value: data?.address?.street1 || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'street2',
        title: 'Address Line 2',
        value: data?.address?.street2 || '',
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        name: 'city',
        title: 'City',
        value: data?.address?.city || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        name: 'state',
        title: 'State',
        value: data?.address?.state || '',
        grid: { xs: 12, sm: 6 },
      },
    ],

    [
      {
        name: 'zip',
        title: 'Postal',
        value: data?.address?.zip || '',
        grid: { xs: 12, sm: 6 },
      },
      {
        type: 'autocomplete',
        name: 'country',
        title: 'Country',
        value: data?.address?.country?.name || '',
        options: countries,
        grid: { xs: 12, sm: 6 },
      },
    ],
  ];

  const formikProps = {
    onSubmit: onFormSubmit,
    initialValues: toInitialValues([
      ...fieldsGridDetails,
      ...fieldsGridDevice,
      ...fieldsGridAddress,
    ]),
    validationSchema: validationSchema,
  };

  const btnSubmitProps = {
    type: 'submit',
    color: 'primary',
    variant: 'contained',
  };
  return loading ? (
    <FormSkeleton />
  ) : (
    <Formik {...formikProps}>
      {({ values, handleChange, setFieldValue, errors }) => {
        const hasErrors = !!(errors && Object.keys(errors).length);
        return (
          <Form>
            <p className={classes.formTitle}>Personal Details</p>
            <FormBuilder
              data={fieldsGridDetails}
              {...{ setFieldValue, values, errors, handleChange }}
            />

            <Box
              mt={4}
              children={<p className={classes.formTitle}>Mobile Device</p>}
            />
            <FormBuilder
              data={fieldsGridDevice}
              {...{ setFieldValue, values, errors, handleChange }}
            />

            <Box
              mt={4}
              children={<p className={classes.formTitle}>Address</p>}
            />
            <FormBuilder
              data={fieldsGridAddress}
              {...{ setFieldValue, values, errors, handleChange }}
            />

            <Box
              /* display="flex" justifyContent="flex-end" */ mt={3}
              children={
                <Button
                  {...btnSubmitProps}
                  disabled={editLoading || hasErrors}
                  children={editLoading ? 'Loading...' : 'Update'}
                />
              }
            />

            <FormDevTools values={values} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};

Profile.propTypes = {};

export default Profile;
