import React, { useState, Fragment } from 'react';
import styles from './NotificationDialog.module.scss';
import {
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextInput from '../../InputFields/TextInput/TextInput';
import { ButtonPrimary } from '../../Button/Button'
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { SEND_PATIENT_NOTIFICATION } from '../../../../data/graphql/patients';

const NotificationDialog = ({ open, handleClose, patientId, warningDetails }) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const warningActive = warningDetails?.length > 0;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="send-notification-dialog-title"
      aria-describedby="send-notification-dialog-description"
      className={styles.wrapper}
    >

      <Box id="send-notification-dialog-title" className={styles.title}>
        <Typography variant="h5">Send Notification</Typography>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Box>


      <DialogContent className={styles.content}>
        {warningActive ?
          <Box className={styles.dialogWarning}>
            {warningDetails.map(({ item, help, route }) => (
              <div key={item}>
                <div className={styles.warningText}>{item}</div>
                <div className={styles.warningHelp}>{help}</div>
              </div>
            ))}
          </Box>
          :
          <Fragment>
            <TextInput placeholder="Write title here ..." value={title} onChange={evt => setTitle(evt.target.value)} />
            <TextInput
              placeholder="Write message here ..."
              multiline
              rows="4"
              maxLength={40}
              rowsMax="4"
              value={body}
              onChange={evt => setBody(evt.target.value)}
            />
            <Typography variant='caption' className={styles.caption}>40 symbols limit</Typography>
          </Fragment>}
      </DialogContent>
      {!warningActive &&
        <DialogActions>
          <Mutation
            mutation={SEND_PATIENT_NOTIFICATION}
            variables={{ patientId, notification: { body, title } }}
            onCompleted={({ data }) => {
              console.log("oncompleted SEND_PATIENT_NOTIFICATION", data);
              //TODO success popup
              handleClose();
            }}
          >
            {(sendNotification, { result, error }) => {
              console.log("sendNotification", result, error)
              return (
                <ButtonPrimary classes={styles.send} onClick={sendNotification}>
                  Send
                </ButtonPrimary>)
            }}
          </Mutation>

        </DialogActions>
      }
    </Dialog>
  )
};

NotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NotificationDialog;
