import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Loading from '../Loading';
import { BaseInput } from './BaseInput';

export const Input = ({ control, name, disabled, required, loading, ...props }) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
  });

  if (loading) return <Loading />;

  const BaseInputProps = {
    ...props,
    ...inputProps,
    ref,
    invalid,
    error,
    disabled,
  };

  return <BaseInput {...BaseInputProps} />;
};

Input.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};
