export const sortCallsByTime = calls => {
  const sortedCalls = [...calls] 
  sortedCalls.sort((a, b) => {
    if (!a.scheduling || !b.scheduling) return 0;
    if (a.scheduling.start > b.scheduling.start) {
      return 1;
    }
    if (a.scheduling.start < b.scheduling.start) {
      return -1;
    }
    return 0;
  });
  return sortedCalls
}

export const reverseSortCallsByTime = calls => {
  
  if (!Array.isArray(calls) || calls?.length === 0) return []
  const sortedCalls = [...calls]
  
  sortedCalls.sort((a, b) => {
    if (!a.scheduling || !b.scheduling) return 0;
    if (a.scheduling.start < b.scheduling.start) {
      return 1;
    }
    if (a.scheduling.start > b.scheduling.start) {
      return -1;
    }
    return 0;
  });
  return sortedCalls
}
