import {gql} from '@apollo/client';

export const NOTE_FIELDS = `
  _id
  note
  type
  noteDate
  relapsed
  relapsedAt
  signed
  signedBy
  createdAt
  createdBy
  actualDuration
  activityId
  analysis {
    engagementLevel
    emotionalState
  }
  subType{
    displayName
    code
  }
`;

export const EPISODE_FIELDS = `
  episodeOfCare {
    _id
    episodeId
    period {
      startDate
      endDate
    }
    status
    type
    encounters {
      _id
      encounterId
      locationId
      propram {
        _id
        caronId
        name
      }
      levelOfCare
      period {
        startDate
        endDate
      }
      notes {
       ${NOTE_FIELDS}
      }
    }
  }
`
export const GET_ALL_PATIENT_NOTES = gql`
query patientFullInfo($_id: String!) {
  patientFullInfo(_id: $_id) {
    _id
    mrn
    encounterId
    firstName
    lastName
    image
    careTeam {
      _id
      primary
    }
    ${EPISODE_FIELDS}
  }
}
`;

export const GET_PATIENT_NOTES = gql`
  query patient($_id: String!) {
    patient(_id: $_id) {
      _id
      mrn
      encounterId
      firstName
      lastName
      image
      careTeam {
        _id
        primary
      }
      notes {
        ${NOTE_FIELDS}
      }
      attachments {
        note
        files {
          url
          name
          type
        }
      }
    }
  }
`;

export const GET_FILTERED_NOTES = gql`
  query getNotes($filters: NoteFilters) {
    getNotes(filters: $filters) {
      ${NOTE_FIELDS}
    }
  }
`;

export const ADD_MEMBER_NOTE = gql`
  mutation saveMemberNote($memberId: ObjectID!, $note: NoteInput!) {
    saveMemberNote(memberId: $memberId, note: $note) {
      _id
    }
  }
`;


export const GET_FAMILY_NOTES = gql`
  query getFullFamilyMemberInfo($memberId: ObjectID!) {
    getFullFamilyMemberInfo(memberId: $memberId) {
      _id
      notes {
        ${NOTE_FIELDS}
      }
    }
  }
`;

export const GET_DRAFT_NOTES = gql`
  query getNoteDrafts($filters: NoteFilters) {
    getNoteDrafts(filters: $filters) {
      ${NOTE_FIELDS},
      memberId
      relatedMemberIds
    }
  }
`;

export const ADD_DRAFT_NOTE = gql`
  mutation saveMemberNoteDraft($memberId: ObjectID!, $draft: NoteDraftInput!) {
    saveMemberNoteDraft(memberId: $memberId, draft: $draft) {
      _id
      note
    }
  }
`;

export const EDIT_DRAFT_NOTE = gql`
  mutation saveMemberNoteDraft($memberId: ObjectID!, $draft: NoteDraftInput!) {
   saveMemberNoteDraft(memberId:$memberId, draft: $draft) {
     note
    }
  }
`;

export const SET_DRAFT_TO_NOTE = gql`
mutation saveNoteFromDraft($draftToNote: DraftToNote!){
  saveNoteFromDraft(draftToNote: $draftToNote) {
      _id
      note
    }
  }
`;