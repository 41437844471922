import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const BadgeCount = ({loading, count, style = {}}) => {
  const classes = useStyles();
  return (
    <div style={{display: 'flex', justifyContent: 'center', margin: style?.margin}}>
      {loading 
        ? <CircularProgress size={16} />
        : <span className={classes.countBubble} style={{...style}}>{count}</span>
      }
    </div>
  )
};

export default BadgeCount;

const useStyles = makeStyles({
  countBubble: {
    fontSize: 12, 
    padding: '5px 8px 4px 7px',
    borderRadius: 100,
  },
});