import React, { Fragment, useState, useEffect } from 'react';

// Util
import Can from 'hoc/Can';
import { Messages } from 'config/settings';
import { activationWarnings } from './helpers';
import { useSnackbar } from 'notistack';

// Components
import MemberSingle from 'components/Member/View/ViewOld';
import EmptyState from 'common/components/EmptyState/EmptyState';

// GraphQL
import { useMutation } from '@apollo/client';
import {
  RESEND_ACTIVATE_MEMBER,
  ACTIVATE_MEMBER,
} from 'data/graphql/member';

// Context
import {useMemberStateContext, useMemberUpdaterContext} from 'context/Member/useMemberContext';

const Single = ({memberId, baseUrl, activatedAccount}) => {

  // Data
  const { memberData: data, staffLoading: loading, staffError: error} = useMemberStateContext();
  const { staffRefetch: refetch} = useMemberUpdaterContext();
  
  // Setup
  const isActivated = activatedAccount || data?.activatedAccount;
  const showResendBtn = data?.cognitoInfo?.status === 'FORCE_CHANGE_PASSWORD';

  // State
  const [listTabs, setListTabs] = useState([]);
  const [activateOpen, setActivateOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  
  // Handlers
  const handleActivateOpen = () => setActivateOpen(true);
  const handleActivateClose = () => setActivateOpen(false);

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  const workloadCalculation = () => {
    if (!data?.statistics?.totalDeptPatients) return 0;
    const workload = data?.patients?.length * 100 / data?.statistics?.totalDeptPatients
    return workload;
  }

  // Lifecycle
  useEffect(() => {
    setListTabs([
      {
        label: 'Overview',
        componentId: 'schedule',
        url: `${baseUrl}/${memberId}`,
        value: '/staff/:id',
      },
      ...(isActivated
        ? [
            {
              label: 'Messaging',
              componentId: 'messaging',
              url: `${baseUrl}/${memberId}/messaging`,
              value: '/staff/:id/messaging',
            },
            // {
            //   label: "Activities",
            //   componentId: 'activitiesStaff',
            //   url: `${baseUrl}/${memberId}/activities`,
            //   value: '/staff/:id/activities',
            // }
          ]
        : []),
    ]);
  }, [data]);

  const overviewHeaderProps = {
    hasCaseload: data?.patients.length ? true : false,
    progressData: {
      workload: workloadCalculation(),
      wellness: 50,
    },
    textList: [
      {
        value: data?.patients.length,
        title: (
          <Fragment>
            Patient
            <br />
            Case Load
          </Fragment>
        ),
      },
      {
        value: data?.statistics.callsThisWeek,
        title: (
          <Fragment>
            Calls
            <br />
            this Week
          </Fragment>
        ),
      } /* , {
      value: 9.5,
      title: (<Fragment>Avg<br/>Satisfaction</Fragment>)
    } */,
    ],
  };
  const [handleActivate, { loading: activateLoading }] = useMutation(
    ACTIVATE_MEMBER,
    {
      variables: { memberId: data?._id },
      onCompleted: () => {
        setShowSuccess(true);

        setTimeout(() => {
          refetch();
          // refetchDashboard();
        }, 250);

        setTimeout(() => handleActivateClose(), 3000);
        setTimeout(() => setShowSuccess(false), 3500);
      },
    }
  );
  // Dialog -- Resend activation mutation
  const [handleResendActivation] = useMutation(
    RESEND_ACTIVATE_MEMBER,
    {
      variables: { memberId: data?._id },
      onCompleted: ({ resendCognitoInvitation }) => {
        if (resendCognitoInvitation) {
          enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_SUCCESS, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
            variant: 'error',
          });
        }
      },
    }
  );

  const headerProps = {
    listBreadcrumbs: [
      { name: 'Staff', url: "/staff" },
      { name: 'Profile', disabled: true, url: '' },
    ],
    listTabs,
    overflowMenu: [
      ...(!isActivated
        ? [
            {
              name: 'Activate Member',
              onClick: handleActivateOpen,
              // permission: {
              //   module: 'STAFF',
              //   perform: 'ACTIVATE_STAFF',
              //   memberType: 'staff',
              //   memberId: data?._id,
              //   policy: 'module',
              //   origin: 'Staff > Profile > Overflow Menu'
              // },
            },
          ]
        : []),
      ...(showResendBtn
        ? [
            {
              name: "Send inRecovery Invite",
              onClick: handleResendActivation,
              // permission: {
              //   perform: 'RESENT_STAFF_INVITE',
              //   staffId: data?._id,
              //   module: 'STAFF',
              // },
            },
          ]
        : []),
      {
        name: 'Edit Profile',
        src: `${baseUrl}/${memberId}/edit/profile`,
        // permission: {
        //   perform: 'EDIT_STAFF',
        //   staffId: data?._id,
        //   module: 'STAFF',
        // },
      },
    ],
  };

  const asideCollapseProps = [
    {
      title: 'Overview',
      name: 'overview',
      contentType: 'overview',
      list: [
        {
          title: 'Email',
          value: data?.email ? data.email : 'N/A',
        },
        {
          title: 'Phone',
          value: data?.phone ? data.phone : 'N/A',
        },
        {
          title: 'Job title',
          value: data?.jobTitle ? data.jobTitle : 'N/A',
        },
        {
          title: 'Supervisor',
          value: data?.supervisor ? data.supervisor : 'N/A',
        },
      ],
    },
  ];
  const successMessage = {
    title: Messages.STAFF_ACTIVATION_SUCCESS_TITLE,
    text: Messages.STAFF_ACTIVATION_SUCCESS_TEXT,
  };
  const dialogProps = {
    showSuccess,
    successMessage,
    warningDetails: activationWarnings(data),
    open: activateOpen,
    title: Messages.STAFF_ACTIVATION_TITLE,
    loading: activateLoading,
    handleAccept: handleActivate,
    handleClose: handleActivateClose,
    acceptBtnText: Messages.STAFF_ACTIVATION_BUTTON_ACCEPT,
    text: <Fragment>{Messages.STAFF_ACTIVATION_DETAILS}</Fragment>,
  };

  const memberSingleProps = {
    memberId,
    memberType: 'staff',
    data,
    error,
    loading,
    refetch,
    headerProps,
    overviewHeaderProps,
    listTabs,
    asideCollapseProps,
    dialogProps,
  };

  return <Can
    module="STAFF"
    perform="VIEW_STAFF"
    memberId={memberId}
    memberType="staff"
    policy="all"
    origin="Staff Module"
    yes={() => <MemberSingle {...memberSingleProps} />}
    no={() => <EmptyState title="Sorry, can't open this 😕" text="You don't have access to this member"/>}
  />
};

export default Single;