import React, {useState} from 'react';
import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline, FlagRounded } from '@material-ui/icons';

const TodoItem = ({date, label, id, isChecked = false, urgent}) => {
  const styles = useStyles();
  const [checked, setChecked] = useState(isChecked);

  const handleChange = (event) => setChecked(event.target.checked);
  
  return <Grid container className={styles.todoRow}>
    <Grid item xs="8">
      <FormControlLabel 
        control={
          <Checkbox 
            name={id}
            onChange={handleChange}
            color="primary"
            checked={checked}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
          />
        }
        label={label}
      />
    </Grid>
    <Grid item xs={"3"}>
      {date}
    </Grid>
    <Grid item xs="1">
      {urgent ? <FlagRounded htmlColor='red' /> : null}
    </Grid>
  </Grid>
}

export default TodoItem;

const useStyles = makeStyles({
  todoRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
  },
});