export const PROCESS_TYPES = ['none', 'journal', 'worksheet' /*"meditation"*/];
export const DURATION_OPTIONS = [15, 30, 45, 50, 60, 90, 120];
export const POINT_OPTIONS = [10, 25, 50, 75, 100];
export const TAG_DATA = [
  '12-Step',
  'AA',
  'NA',
  'Sponsor',
  'Recovery Coach',
  'Al-Anon',
  'Therapist',
  'Phsychiatrist',
  'Family Counseling',
  'Couples Therapy',
  'Trauma Therapy',
  'Gym',
  'Running',
  'Swimming',
  'Running',
  'Swimming',
  'Yoga',
  'Meditation',
  'Accupuncture',
];

export const TYPES = [
  {
    value: 'core',
    label: 'Recovery',
  },
  {
    value: 'personal',
    label: 'Personal',
  },
];

export const DEFAULT_ACTIVITY = {
  type: 'core',
  patients: [],
  date: '',
  time: '',
  duration: 30,
  repeat: 'none',
  activityName: '',
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: {
      code: '',
      name: '',
    },
  },
  points: 50,
  tags: [],
  processType: 'none',
  questions: [],
  hosts: '',
  description: '',
  mandatory: false,
  onlyWeekDays: false,
};

// const TAG_DATA2 = [
//   { _id: 0, group: "Support", name: "12-Step", },
//   { _id: 1, group: "Support", name: "AA", },
//   { _id: 2, group: "Support", name: "NA", },
//   { _id: 3, group: "Support", name: "Sponsor" },
//   { _id: 4, group: "Support", name: "Recovery Coach" },
//   { _id: 5, group: "Support", name: "Al-Anon" },
//   { _id: 6, group: "Clinical", name: "Therapist" },
//   { _id: 7, group: "Clinical", name: "Phsychiatrist" },
//   { _id: 8, group: "Clinical", name: "Family Counseling" },
//   { _id: 9, group: "Clinical", name: "Couples Therapy" },
//   { _id: 10, group: "Clinical", name: "Trauma Therapy" },
//   { _id: 11, group: "Exercise", name: "Gym" },
//   { _id: 12, group: "Exercise", name: "Running" },
//   { _id: 13, group: "Exercise", name: "Swimming" },
//   { _id: 14, group: "Exercise", name: "Yoga" },
//   { _id: 15, group: "Exercise", name: "Running" },
//   { _id: 16, group: "Exercise", name: "Swimming" },
//   { _id: 17, group: "Mindfulness", name: "Yoga" },
//   { _id: 18, group: "Mindfulness", name: "Meditation" },
//   { _id: 19, group: "Mindfulness", name: "Accupuncture" },
//   { _id: 20, group: "Mindfulness", name: "Yoga", },
//   { _id: 21, group: "Mindfulness", name: "Yoga", }
// ]
