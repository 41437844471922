import React from 'react';
import {useActivitiesContext} from 'context/Activities/useActivitiesContext';


// UI
import { makeStyles } from '@material-ui/core/styles';

const ActivitiesTable = ({ data, categoryLimit, templateLimit }) => {
  const classes = useStyles();

  const { templateId, setTemplateId } = useActivitiesContext();

  const handleClick = id => {
    setTemplateId(id);
  };

  if (!data)
    return (
      <h2 className={classes.message}>
        You don’t have any templates saved, don’t worry you can add your first one after you finish setting up your
        activity.
      </h2>
    );

  return (
    <div className={`${classes.body}`}>
      {data
        ?.filter((_, index) => index < categoryLimit)
        .map((item, index) => {
          return (
            <div className={`${classes.column}`} key={index}>
              <div className={classes.title}>{item.category}</div>
              <div>
                {item.templates
                  .filter((_, index) => index < templateLimit)
                  .map(template => {
                    const classHighlighted = template.highlight ? classes.highlighted : '';
                    const classSelected = template._id === templateId ? classes.selected : '';

                    return (
                      <div
                        className={`${classes.item} ${classHighlighted} ${classSelected}`}
                        key={template._id}
                        id={template._id}
                        onClick={() => handleClick(template._id)}
                      >
                        {template.name}

                        {/* <Button
                            // style={}
                            // classes={}
                            disabled={false}
                            onClick={console.log}
                          >
                          <CreateIcon fontSize="small" />
                        </Button>

                        <Button
                          // classes={{ root: styles.button, text: styles.button_text }}
                          onClick={console.log}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button> */}

                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

// Styles
const useStyles = makeStyles({
  body: {
    // border: 'solid 1px green',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-around',
    height: '250px',
  },
  // xScroll: {
  //   overflowX: 'scroll',
  //   justifyContent: 'flex-start',
  // },
  // xNonScroll: {
  //   justifyContent: 'space-around',
  // },
  // yScroll: {
  //   overflowY: 'scroll',
  // },
  column: {
    display: 'flex',
    flexDirection: 'column',
    // border: 'solid 1px blue',
    // width: '150px',
    flexShrink: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '17px',
    // border: 'solid 1px yellow',
    margin: '20px 0 20px 0',
  },
  message: {
    width: '80%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  item: {
    // margin: '20px 0 20px 0',
    // backgroundColor: '#3A9BFC',
    '&:hover, &:focus': {
      backgroundColor: '#3A9BFC',
      color: 'white',
    },
    padding: '10px',
    borderRadius: '20px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& button': {
      minWidth: '30px',
      padding: '1px',
    },
  },
  highlighted: {
    backgroundColor: '#3A9BFC',
    color: 'white',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#3A9BFC',
    color: 'white',
    cursor: 'pointer',
  },
});

export default ActivitiesTable;
