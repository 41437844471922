import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './styles.module.scss';
import classnames from 'classnames';

const RadioButton = ({
  field: { name, value },
  label,
  form: { setFieldValue, values },
  disabled,
  labelPlacement = 'end',
  ...props
}) => (
  <FormControlLabel
    control={
      <Radio
        classes={{
          checked: styles.checked,
          disabled: styles.disabled,
        }}
        onChange={() => {
          console.log('🙋‍♂️ setting value to => ', value);
          setFieldValue(name, value);
        }}
        value={value}
        name={name}
        checked={value === values[name]}
        disabled={disabled}
        {...props}
      />
    }
    label={label}
    className={classnames(styles.container, props.className)}
    classes={{
      label: classnames(styles.label, labelPlacement === 'end' ? styles.margin_left : null)
    }}
    {...props}
  />
);

export default RadioButton;
