import React, { Fragment, useState, useEffect } from 'react';

import {
  Fade, Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import SmallList from "common/components/SmallList/List";

const ExpansionItem = ({ name, title, expanded, handleChange, children }) => {

  const classes = useStyles();

  const panelProps = {
    classes: {
      root: classes.collapsePanel,
      expanded: classes.collapsePanelExpanded,
    },
    expanded: expanded === name,
    onChange: handleChange(name),
  }

  const titleProps = {
    elevation: 0,
    className: classes.title,
    expandIcon: <Fade in={expanded !== name} children={
      <AddIcon fontSize='small'/>
    }/>,
  }
  
  const bodyProps = {
    className: classes.content
  }

  return (
    <Accordion {...panelProps}>
      <AccordionSummary {...titleProps} children={title}/>
      <AccordionDetails {...bodyProps} children={children}/>
    </Accordion>
  )
}

const ProfileAside = ({ asideCollapseProps = [] }) => {

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  // Lifecycle -- Set first collapse item to open
  useEffect(() => {
    if (asideCollapseProps.length) {
      setExpanded(asideCollapseProps[0].name);
    }
  }, [asideCollapseProps]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>

      {asideCollapseProps.map((item, i) => {

        let renderContent = null;

        if (item.contentType === 'memberList') {
          renderContent = item.list?.length 
            ? <SmallList data={item.list} {...item.listProps || {}}/> 
            : item.emptyText
        }

        if (item.contentType === 'groupedMemberList') {
          renderContent = (
            <Grid container direction="column">
              {item.list.map((groupList, j) => (
                <Fragment key={j}>
                  <Grid item children={<p>{groupList.title}</p>}/>
                  <Grid item children={(
                    groupList.list?.length 
                      ? <SmallList data={groupList.list} {...groupList.listProps || {}}/> 
                      : groupList.emptyText
                  )}/>
                </Fragment>
              ))}
            </Grid>
          )
        }

        if (item.contentType === 'overview') {
          renderContent = (
            <div className={classes.overviewList}>
              {item.list.map(({ title, value, hasSublist }, i) => (
                <div className={classes.overviewListItem} key={i}>
                  
                  {!!title && (
                    <div className={classes.overviewListTitle}>{title}</div>
                  )}

                  {!hasSublist ? (
                    <div className={classes.overviewListValue}>{value}</div>
                  ) : value.map((subValue, j) => (
                    <div className={cn(
                      classes.overviewListSubValue, 
                      classes.overviewListValue
                    )} key={j}>
                      <span>{`${subValue.title}: ${subValue.value}`}</span>
                    </div>
                  ))}
                  
                </div>
              ))}
            </div>
          )
        }

        return (
          <ExpansionItem key={i}
            {...{expanded, handleChange}} 
            name={item.name} title={item.title}
            children={renderContent}
          />
        )
      })}

    </div>
  )
}

ProfileAside.propTypes = {

}

export default ProfileAside;


const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    border: '1px solid #E0E0E0',
  },
  collapsePanel: {
    boxShadow: 'none',
    //borderRadius: '10px !important',
    '&:before': {
      display: 'none',
    },
    '&:first-child:not(:last-child)': {
      borderRadius: '10px 10px 0px 0px !important',
    },
    '&:last-child:not(:first-child)': {
      borderRadius: '0px 0px 10px 10px !important',
    },
    '&:last-child:not(:first-child) $title': {
      borderBottom: 0,
    },
    '&:not(:last-child) $content': {
      borderBottom: '1px solid #E0E0E0',
    }
  },
  collapsePanelExpanded: {
    margin: '0 !important'
  },
  title: {
    borderBottom: '1px solid #E0E0E0',
    height: 64,
    display: 'flex',
    fontSize: '18px',
    padding: '0 20px',
    boxShadow: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Avenir Next Demi Bold',
  },
  content: {
    
  },
  overviewList: {
    paddingTop: 20
  },
  overviewListItem: {
    '& + &': {
      marginTop: 25,
    }
  },
  overviewListTitle: {
    color: '#000',
    fontSize: '14px',
    marginBottom: 10,
    fontFamily: 'Avenir Next Demi Bold',
  },
  overviewListValue: {
    fontSize: '14px',
    fontFamily: 'Avenir Next Regular',
  },
  overviewListSubValue: {
    '& + &': {
      marginTop: 4,
    }
  }
});