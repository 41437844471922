import React from 'react';
import moment from 'moment';

// UI
import stylesNew from 'components/Scheduler/styles.module.scss';

// Scheduler Components
import Scheduler from 'components/Scheduler/components/Scheduler/Scheduler';
import { ToolBarFlexibleSpace } from 'common/components/Schedule/ToolBar/ToolBar';
import { DayView, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
// Redux State
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { connectProps } from '@devexpress/dx-react-core';

// Scheduler: Actions
import {
  getMultiSchedulerActivities,
  setBookerViewName,
  toggleMSCHVisibility,
  clearState,
  setCurrentDate,
} from 'actions/appointmentBooker';

// Data
import { BookingContext } from 'context/BookingContext';

/* TODO For multi staff scheduler mode
  X figure why we need this flexibleSpace and make optimize it
*/

class Container extends React.PureComponent {
  static contextType = BookingContext;
  constructor(props) {
    super(props);

    this.flexibleSpace = connectProps(ToolBarFlexibleSpace, () => {
      return {
        hideAvailability: this.props.isEmbedded,
      };
    });
  }

  // Visibility Toggles
  toggleTooltipVisibility = () => this.props.toggleMSCHVisibility('tooltipVisible');

  currentViewNameChange = currentViewName => {
    // console.log('[Actions > MultiScheduler] currentViewName', currentViewName);
    this.props.setBookerViewName(currentViewName);
  };

  currentDateChange = currentDate => {
    // console.log('[Actions > MultiScheduler] currentDateChange', currentDate);
    const {handleLoadMemberEvents} = this.context;
    this.props.setCurrentDate(currentDate);
    handleLoadMemberEvents(
      currentDate,
      this.props.currentViewName
    );
  };

  componentDidUpdate() {
    this.flexibleSpace.update();
  }

  componentWillUnmount() {
    // clear all appointment booker data
    this.props.clearState();
  }
  
  dayViewTimeTableCell = ({ startDate, ...restProps }) => {
    const {selectedTime, setSelectedTime} = this.context;
    let isShaded =
      selectedTime &&
      moment(selectedTime).isSame(startDate)
        ? true
        : false;
    let hasRightBorder = isShaded ? true : false;
    
    return (
      <DayView.TimeTableCell
        onClick={e => {
          setSelectedTime(startDate);
        }}
        {...restProps}
        isShaded={isShaded}
        hasRightBorder={hasRightBorder}
      />
    );
  };
  weekViewTimeTableCell = ({ startDate, ...restProps }) => {
    const {selectedTime, setSelectedTime} = this.context;
    //TODO use duration to decide if partly shaded or not
    let isShaded =
      selectedTime &&
      moment(this.props.selectedTime).isSame(startDate)
        ? true
        : false;
    let hasRightBorder = isShaded ? true : false;
    return (
      <WeekView.TimeTableCell
        onClick={e => {
          setSelectedTime(startDate);
        }}
        {...restProps}
        isShaded={isShaded}
        hasRightBorder={hasRightBorder}
      />
    );
  };

  render() {
    const {
      isEmbedded,
      currentDate,
      currentViewName,
      startDayHour,
      endDayHour,
      tooltipVisible,
    } = this.props;
    const {activities, activitiesLoading} = this.context;

    console.log('MultiScheduler Data', this.props);

    return (
      <div className={stylesNew.schedulerNew_container}>
        <div className={stylesNew.schedulerNew_body}>
          <div className={stylesNew.schedulerNew_calendar}>
            <Scheduler
              data={activities}
              loading={activitiesLoading}
              appointmentForm={null}
              flexibleSpace={this.flexibleSpace}
              tooltipVisible={tooltipVisible}
              toggleTooltipVisibility={this.toggleTooltipVisibility}
              currentViewName={currentViewName}
              currentViewNameChange={this.currentViewNameChange}
              currentDate={currentDate}
              currentDateChange={this.currentDateChange}
              editingFormVisible={false}
              startDayHour={startDayHour}
              endDayHour={endDayHour}
              schedulerHeight={isEmbedded ? 550 : 800}
              isStaffModule={this.props.isStaffModule}
              origin="multischedule"
              dayViewTimeTableCell={this.dayViewTimeTableCell}
              weekViewTimeTableCell={this.weekViewTimeTableCell}
              noMonthView={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.appointmentBooker,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  getMultiSchedulerActivities,
  setCurrentDate,
  toggleMSCHVisibility,
  setBookerViewName,
  clearState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Container));
