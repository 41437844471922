import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Skeleton } from '@material-ui/lab';
import { Switch, Route } from 'react-router-dom';
import { Grid, Avatar, Box, Button } from '@material-ui/core';

import styles from './StaffItemEdit.module.scss';

//components
import Header from 'common/components/PanelHeader/PanelHeader';
import AvatarDialog from 'common/components/AvatarDialog/AvatarDialog';

import { GET_STAFF_BY_ID } from 'data/graphql';

import Profile from './Tabs/Profile/Profile';
import Caron from './Tabs/Caron/Caron';
import ErrorScreen from 'common/components/Errors/DefaultError';

import Svg from 'react-svg';
import IconPlus from 'common/assets/plus-rounded.svg';
import useAccountContext from 'context/Account/useAccountContext';

const StaffItemEdit = ({ staffMember, match: { url, params } }) => {

  const { error, loading, staff: data, refetch } = staffMember;
  
  // State
  const [avatarOpen, setAvatarOpen] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  
  const {organizationName} = useAccountContext();
  
  // Handlers
  const handleAvatarOpen = () => setAvatarOpen(true);
  const handleAvatarClose = () => setAvatarOpen(false);

  // Mutations
  const handleAvatarSubmit = result => {
    setAvatarPreview(result.content);
    setAvatarFile(result);
    handleAvatarClose();
  }


  // Set render props
  const listTabs = [
    { label: "Profile", component: () => <Profile avatarFile={avatarFile} user={data} refetch={refetch} />, url: `${url}/profile` },
    { label: organizationName, component: () => <Caron avatarFile={avatarFile} user={data} refetch={refetch} />, url: `${url}/caron` },
    // { label: "Security", component: () => <Security user={data} />, url: `${url}/security` },
  ];
  const headerProps = {
    listBreadcrumbs: [
      { name: "Staff", url: "/staff" },
      { name: "Edit", disabled: true, url: "" }
    ],
    listTabs,
    overflowMenu: [{
      name: 'Back to Profile',
      src: `/staff/${params.id}`,
    }]
  }

  // Render
  if (error) {
    return <ErrorScreen />
  }

  const User = () => {

    let renderAvatar = (
      <Box display="flex" flexDirection="column" alignItems="center" width="200px">
        <Skeleton animation="wave" variant="circle" width={160} height={160} />
        <Skeleton animation="wave" width={170} />
      </Box>
    )

    if (data) {
      const buttonTitle = (data.image || avatarPreview) ? 'Change photo' : 'Add photo';
      const fullName = `${data.title ? `${data.title} ` : ''}${data.firstName} ${data.lastName}`;

      const avatarProps = {
        classes: { root: styles.avatar },
        ...data.image ? {
          src: avatarPreview ? avatarPreview : data.image
        } : {
            style: { cursor: 'pointer' },
            onClick: handleAvatarOpen,
            ...avatarPreview ? {
              src: avatarPreview
            } : {
                children: <Svg className={styles.plusIcon} src={IconPlus} />,
              }
          }
      }

      renderAvatar = (
        <div className={styles.user}>
          <Avatar {...avatarProps} />
          <div className={styles.avatarText} children={fullName} />
          <Box mt={1} children={
            <Button
              color="primary"
              children={buttonTitle}
              onClick={handleAvatarOpen} />
          } />
        </div>
      )
    }

    return renderAvatar;
  }

  const Layout = ({ aside, children }) => (
    <div className={styles.pageContent}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>{aside}</Grid>
        <Grid item xs={12} md={8} lg={5}>{children}</Grid>
      </Grid>
    </div>
  );

  return (
    <div className={styles.root}>

      <Header {...headerProps} />

      {!loading && (
        <Layout aside={<User />}>
          <Switch>
            {listTabs.map(({ url, component }, i) => (
              <Route exact key={i} path={url} component={component} />
            ))}
          </Switch>
        </Layout>
      )}
      <AvatarDialog
        open={avatarOpen}
        handleClose={handleAvatarClose}
        handleSubmit={handleAvatarSubmit} />
    </div>
  )
}

StaffItemEdit.propTypes = {

}

export default graphql(GET_STAFF_BY_ID, {
  name: 'staffMember',
  options: props => ({
    variables: { _id: props.match.params.id }
  })
})(StaffItemEdit);
