import React, {useState, useEffect, Fragment} from 'react'

// Context
import useMembersContext from 'context/Members/useMembersContext';

// Components
import TopModule from './TopModule';
import HeaderContainer from './HeaderContainer';
import {MemberHeaderSkeleton, HeaderActionsSkeleton} from '../Skeleton/Skeleton';

const PreloadHeader = ({memberId, memberType}) => {
  
  const [memberData, setMemberData] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const isPatient = memberType === 'patient';
  const isFamily = memberType === 'family';
  const isStaff = memberType === 'staff';

  const {patients, family, staff} = useMembersContext();
 
  useEffect(() => {
    console.log('PreloadHeader mounted')
    return () => console.log('PreloadHeader unmounted')
  }, [])

  useEffect(() => {
    if(isPatient && patients?.length) return setIsDataLoaded(true);
    if(isFamily && family?.length) return setIsDataLoaded(true);
    if(isStaff && staff?.length) return setIsDataLoaded(true);
  }, [patients, family, staff])

  useEffect(() => {
    if(!isDataLoaded) return;
    if(isPatient) return setMemberData(patients.find(member => member._id === memberId))
    if(isFamily) return setMemberData(family.find(member => member._id === memberId))
    if(isStaff) return setMemberData(staff.find(member => member._id === memberId))
  }, [isDataLoaded]);
  
  const headerElements = {
    hasPatients: isFamily ? true : false,
    hasCaseload: isStaff ? true : false,
  };

  const topModuleProps = {
    _id: memberData?._id,
    firstName: memberData?.firstName || '',
    lastName: memberData?.lastName || '',
    image: memberData?.image,
    emrPic: memberData?.emrPic,
    mrn: memberData?.mrn,
    status: memberData?.status,
    lastActive: memberData?.lastActive,
    type: memberData?.type,
    alerts: memberData?.alerts,
    relatedPatients: memberData?.relatedPatients || [],
    patients: [],
    overviewHeaderProps: headerElements,
  };

  return (
    <Fragment>
      {isDataLoaded ? (
        <HeaderContainer>
          <TopModule {...topModuleProps} />
          <HeaderActionsSkeleton items={isPatient ? 3: 2} />
        </HeaderContainer>
      ) : (
      <MemberHeaderSkeleton minHeight={200} progressItems={isStaff ? 2 : 3} />
      )}
    </Fragment>
  )
}

export default PreloadHeader;
