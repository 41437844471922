import React, { useContext } from "react";

// Data
import { GuideContext } from 'context/Guides/GuideContext';

// Material UI
import { Box, Grid, Button } from '@material-ui/core';

// Styles
import styles from './Support.module.scss';

// Components
import Header from 'common/components/PanelHeader/PanelHeader';
import SupportCard from 'common/components/Support/SupportCard';

// Icons
import ReactSVG from 'react-svg';
import AddIcon from 'common/assets/add.svg';
import GetStartedIcon from 'common/assets/icon-start.svg';
import InstructionsIcon from 'common/assets/icon-instructions.svg';
import GuideIcon from 'common/assets/icon-guidebook.svg';
import TrainingIcon from 'common/assets/icon-training.svg';
import FAQsIcon from 'common/assets/icon-faq.svg';

const HelpDesk = () => {

  // Context Data
  const { 
    isSetupComplete,
    setWelcomeOpen,
    setSetupGuideOpen,
    setLaunchedGuide,
  } = useContext(GuideContext); 

  // Handlers
  const handleLaunchSetupGuide = () => {
    setLaunchedGuide(true)
    setWelcomeOpen(true)
    setSetupGuideOpen(false)
  }

  const handleNewRequest = () => {
    console.log('[Help Desk] Routing to Jira Help Desk');
    return window.location.href = 'https://inrecovery.atlassian.net/servicedesk/customer/portal/3/group/-1'
  };

  const listBreadcrumbs = [{
    name: "Help Desk",
    url: "",
    disabled: true
  }];

  const listButtons = [{
    title: "New Support Request",
    onClick: handleNewRequest,
    icon: AddIcon,
    style: 'fill: #fff'
  }];

  const supportOptions = [{
    title: "Cheat Sheets",
    icon: InstructionsIcon,
    link: { type: 'external', url: 'https://inrecovery.atlassian.net/servicedesk/customer/portals' },
  },
  {
    title: "Knowledge Base",
    icon: GuideIcon,
    link: { type: 'external', url: 'https://inrecovery.atlassian.net/servicedesk/customer/portals' },
  },
  {
    title: "Training Modules",
    icon: TrainingIcon,
    link: { type: 'external', url: 'https://inrecovery.atlassian.net/servicedesk/customer/portals' },
  },
  {
    title: "FAQs",
    icon: FAQsIcon,
    link: { type: 'internal', url: '/help/faq' },
  }];

  return (
    <React.Fragment>

      <div>
        <Header
          listBreadcrumbs={listBreadcrumbs}
          listButtons={listButtons}/>
      </div>

      <Box className={styles.wrapper}>

        {!isSetupComplete && 
          <div className={styles.banner}>
            <div className={styles.title}>
              <ReactSVG src={GetStartedIcon} className={styles.icon} />
              Setup your inRecovery Account in under 10 minutes!
            </div>
            <Button color="primary" variant="contained" onClick={handleLaunchSetupGuide} className={styles.button}>
              Launch Setup Guide
            </Button>
          </div>
        }

        <Grid container spacing={3}>
          {supportOptions.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <SupportCard 
                title={item.title} 
                icon={item.icon} 
                link={item?.link}
              />
            </Grid>
          ))}
        </Grid>

      </Box>

    </React.Fragment>
  );
}

export default HelpDesk;
