import {useContext} from 'react';
import { MembersContext } from './MembersContext';

const useMembersContext = () => {
    
    const context = useContext(MembersContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the MembersContext`) 
    };

    return context

};

export default useMembersContext;