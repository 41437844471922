import React, { Fragment } from 'react';

import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import MemberAside from './MemberAside/MemberAside';
import MemberBody from './MemberBody/MemberBody';
import { useMemberStateContext } from 'context/Member/useMemberContext';

const useStyles = makeStyles({
  bottomContainer: {
    padding: 30,
  },
});

const Overview = ({ 
  // data,
  showSchedule = false, 
  asideCollapseProps, 
}) => {
  const classes = useStyles();
  const {memberData: data} = useMemberStateContext();

  return (
    <Fragment>
      <Grid container spacing={3} className={classes.bottomContainer}>
        <Grid item sm={4} children={<MemberAside {...{ asideCollapseProps }} {...data} />} />
        <Grid item sm={8} children={<MemberBody {...{ showSchedule }} {...data} />} />
      </Grid>
    </Fragment>
  );
};


export default Overview;
