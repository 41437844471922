import * as React from 'react';
import { withSnackbar } from 'notistack';

// UI
import styles from './components/Scheduler/styles.module.scss';
import stylesNew from './styles.module.scss';
import Header from 'common/components/PanelHeader/PanelHeader';

// Scheduler Components
import EditActivityPopup from 'components/Scheduler/components/AppointmentForm/EditActivityPopup';
import Scheduler from './components/Scheduler/Scheduler';
import CalendarsBox from './components/CalendarsBox/CalendarsBox';
import TooltipContent from './components/TooltipContent/TooltipContent';
import Datepicker from 'common/components/Schedule/Datepicker/Datepicker';
import { ToolBarFlexibleSpace } from 'common/components/Schedule/ToolBar/ToolBar';

// Redux State
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { connectProps } from '@devexpress/dx-react-core';

// Comtext
import {ScheduleModuleContext} from 'context/Activities/ActivitiesContext';



// Scheduler: Actions
import {
  setToggleLoading,
  setActivities,
  getActivities,
  filterOutActivities,
  changeFilter,
  toggleVisibility,
  changeFieldId,
  setIsNewAppointment,
  setAddedAppointment,
  setViewName,
  setCurrentDateScheduler,
  addAppointment,
  editAppointment,
  cancelAppointment,
  acceptAppointment,
  cleanReducerState,
} from 'actions/scheduler';
import EditAppointmentPopup from './components/AppointmentForm/EditAppointmentPopup';

const types = [
  { value: 'core', text: 'Core' },
  { value: 'personal', text: 'Personal' },
  { value: 'therapySession', text: 'Therapy Session' },
  { value: 'familySession', text: 'Family Session' },
];

/* TODO For embedded mode
  X Set default patient ID
  X Set default view
  X Set and pass height via prop: height={550}
  X isEmbedded Conditional for Removing Aside Tools
  X Adjust CSS - amazing!! it wasn't even needed :D
*/

/* TODO for Scheduler Update
  - Enable "Schedule a Call" from main Scheduler view
*/

class SchedulerContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.flexibleSpace = connectProps(ToolBarFlexibleSpace, () => {
      return {
        type: this.props.type,
        types,
        hideAvailability: this.props.isEmbedded,
        patient: this.props.patient,
        patients: this.props.patients,
        onSelectChange: this.onSelectChange,
      };
    });

    this.patientSelector = {
      patient: this.props.patient,
      patients: this.props.patients,
      onSelectChange: this.onSelectChange,
    };

    this.tooltipContent = connectProps(TooltipContent, () => {
      return {
        reschedulingVisible: this.props.reschedulingVisible,
        onSubmit: this.getDataFromServer,
        toggleVisibility: this.props.toggleVisibility,
        toggleTooltipVisibility: this.toggleTooltipVisibility,
        toggleRescheduling: this.toggleRescheduling,
        commitChanges: this.commitChanges,
      };
    });

    // State
    this.state = {
      listBreadcrumbs: [
        {
          name: 'Schedule',
          url: '',
          disabled: true,
        },
      ],
      // listButtons: [
      //   {
      //     title: 'Schedule a Call',
      //     onClick: this.toggleScheduleCall,
      //     icon: CalendarIcon,
      //   }
      // ],
    };
  }

  // Data Fetcher
  // IMPORTANT: The order of this object matters. Don't re-order!
  getDataFromServer = props => {
    // console.log('%c [Scheduler] getDataFromServer 🖕🖕🖕🖕', "color:yellow", props)

    const { client, currentDate, currentViewName, type, patient, outlookCalendar, googleCalendar, myAvailability } = {
      ...this.props,
      ...props,
    };

    if (this.props.isEmbedded && this.props._id) {
      // console.log(`%c 👋 Im a Member Module > Activities Schedule with Member ID: ${this.props._id}... shooting this over to getMemberActivities()`, "color:yellow")
      // console.log(`%c 👋 Figure out if member is staff or patient Member ID: ${this.props._id}... `, "color:red")

      this.getMemberActivities(this.props._id, client, currentDate, currentViewName);
    } else if (!this.props.isEmbedded && !this.props.isMemberModule) {
      if (this.props.patient) {
        // console.log(`%c 👋 Im on Main Scheduler with a member selected: ${this.props.patient}`, "color:yellow")
        this.props.getActivities(
          client,
          currentDate,
          currentViewName,
          type,
          this.props.patient, // selected patient
          outlookCalendar,
          googleCalendar,
          myAvailability
        );
      } else {
        // console.log(`%c 👋 Im on Main Scheduler without a member selected :P`, "color:yellow")
        this.props.getActivities(
          client,
          currentDate,
          currentViewName,
          type,
          patient,
          outlookCalendar,
          googleCalendar,
          myAvailability
        );

        this.props.changeFilter('testingDispatch', ':(');
      }
    }
  };

  getMemberActivities = (
    memberId,
    client = this.props.client,
    currentDate = this.props.currentDate,
    currentViewName = this.props.currentViewName,
    isStaffModule = this.props.isStaffModule
  ) => {
    this.props.getActivities(
      client,
      currentDate,
      currentViewName,
      null, // type
      memberId,
      null, // outlook
      null, // google
      null, // myAvailability
      isStaffModule
    );
  };

  // Visibility Toggles
  toggleScheduleCall = () => this.props.toggleVisibility('reschedulingVisible', true);
  toggleRescheduling = () => this.props.toggleVisibility('reschedulingVisible');
  toggleTooltipVisibility = () => this.props.toggleVisibility('tooltipVisible');
  toggleConfirmationVisible = () => this.props.toggleVisibility('confirmationVisible');
  toggleEditingFormVisibility = () => this.props.toggleVisibility('editingFormVisible');

  // Handlers
  onSelectChange = (field, value) => {
    const { type, patient, allData, outlookCalendar, googleCalendar, myAvailability } = this.props;
    this.props.filterOutActivities({
      ...{ allData, type, patient },
      [field]: value,
      outlookCalendar,
      googleCalendar,
      myAvailability,
    });
    this.props.changeFilter(field, value);
  };

  onAddedAppointmentChange = addedAppointment => {
    this.props.setAddedAppointment(addedAppointment);
    const { editingAppointmentId } = this.props;
    if (editingAppointmentId !== undefined) {
      this.props.changeFieldId('previousAppointmentId', editingAppointmentId);
    }
    this.props.changeFieldId('editingAppointmentId', undefined);
    this.props.setIsNewAppointment(true);
  };

  currentViewNameChange = currentViewName => {
    this.props.setViewName(currentViewName);
    this.getDataFromServer({ currentViewName });
  };

  currentDateChange = currentDate => {
    this.props.setCurrentDateScheduler(currentDate);
    this.getDataFromServer({ currentDate });
  };

  // Editing Handlers
  setDeletedAppointmentId = id => {
    this.props.changeFieldId('deletedAppointmentId', id);
  };

  onEditingAppointmentIdChange = editingAppointment => {
    if (editingAppointment) {
      this.props.changeFieldId('editingAppointmentId', editingAppointment.id);
      this.props.setIsNewAppointment(false);
    } else {
      this.props.changeFieldId('editingAppointmentId', undefined);
    }
  };

  // Commit Handlers
  commitChanges = ({ added, changed, canceled, accepted }) => {
    if (added) this.commitAddedAppointment(added);
    if (changed) this.commitUpdatedAppointment(changed);
    if (accepted !== undefined) this.commitAcceptedAppointment(accepted);
    if (canceled !== undefined) {
      this.setDeletedAppointmentId(canceled);
      this.toggleConfirmationVisible();
    }
  };

  commitAcceptedAppointment = async id => {
    const { client, acceptedAppointmentId } = this.props;
    try {
      await this.props.acceptAppointment(client, id ? id : acceptedAppointmentId);
      this.getDataFromServer();
    } catch (err) {
      console.log('[Scheduler Error] commitAcceptedAppointment: ', err);
    }
  };

  commitDeletedAppointment = async seriesFlag => {
    const { client, deletedAppointmentId, enqueueSnackbar } = this.props;
    try {
      await this.props.cancelAppointment(client, deletedAppointmentId, enqueueSnackbar, seriesFlag);
      this.toggleConfirmationVisible();
      this.getDataFromServer();
    } catch (err) {
      console.log('[Scheduler Error] commitDeletedAppointment: ', err);
    }
  };

  commitUpdatedAppointment = async changed => {
    const { client /* , currentDate, currentViewName, type, patient */ } = this.props;
    try {
      await this.props.editAppointment(client, changed);
      this.getDataFromServer();
    } catch (err) {
      console.log('[Scheduler Error] commitUpdatedAppointment: ', err);
    }
    this.toggleTooltipVisibility();
  };

  commitAddedAppointment = async newAppointment => {
    const { client /* , currentDate, currentViewName, type, patient */ } = this.props;
    try {
      await this.props.addAppointment(client, newAppointment);
      this.getDataFromServer();
    } catch (err) {
      console.log('[Scheduler Error] commitAddedAppointment: ', err);
    }
  };

  /*
   NOTE: Don't worry, this will not look like this after we finish the useScheduler hook. 
   but will require full refactoring of Redux setup for Scheduler, will be fun!!! :)
  */
  // Lifecycle Methods
  componentDidMount() {
    if (this.props.isEmbedded && this.props.isMemberModule) {
      // console.info(`%c[Embedded Scheduler] Full view with id: ${this.props._id} type: ${this.props.type}`, "color:#3399ff")
      // console.log(`%c 👋 Figure out if member is staff or patient Member ID: ${this.props._id}... `, "color:red", this.props)

      // Update Redux
      this.props.changeFilter('patient', this.props._id);
      this.props.changeFilter('type', null);
      // console.log('[Embedded Scheduler] props:2', this.props);

      // Fetch Member Activities
      this.getMemberActivities(this.props._id);
    } else {
      // console.info(`%c[Scheduler] Full view with id: ${this.props._id} type: ${this.props.type} myAvailability: ${this.props.myAvailability}`, "color:#3399ff")

      // Update Redux
      this.props.changeFilter('patient', null);
      this.props.changeFilter('type', 'mySessions');
      // console.log('[Scheduler] props:', this.props);

      //Fetch Clinician's Calls
      this.props.getActivities(
        this.props.client,
        this.props.currentDate,
        this.props.currentViewName,
        'mySessions', // type
        null, // memberId
        this.props.outlookCalendar,
        this.props.googleCalendar,
        this.props.myAvailability
      );
    }
  }

  componentDidUpdate() {
    // console.log('[Scheduler] props:', this.props);
    this.flexibleSpace.update();
    this.tooltipContent.update();
  }

  componentWillUnmount() {
    // clear all scheduler's data
    this.props.cleanReducerState();
  }

  render() {
    const { listBreadcrumbs, listButtons } = this.state;
    // const {bookAppointmentOpen} = this.context;

    const bookAppointmentOpen = false;
    
    const {
      data,
      loading,
      isEmbedded,
      currentDate,
      currentViewName,
      startDayHour,
      endDayHour,
      editingFormVisible,
      tooltipVisible,
      confirmationVisible,
      reschedulingVisible,
      // getActivities,
    } = this.props;
    // console.log('scheduler - data >>>>>', data, loading, isEmbedded, this, currentViewName, currentDate, confirmationVisible, startDayHour, endDayHour)

    return (
      <div className={stylesNew.schedulerNew_container}>
        {!isEmbedded && (
          <div className={styles.schedulerNew_header}>
            <Header listBreadcrumbs={listBreadcrumbs} listButtons={listButtons} />
          </div>
        )}

        <div className={stylesNew.schedulerNew_body}>
          {!isEmbedded && (
            <div className={stylesNew.schedulerNew_tools}>
              <Datepicker
                className={stylesNew.datepicker}
                currentDate={currentDate}
                currentDateChange={this.currentDateChange}
              />
              <CalendarsBox patientSelector={this.patientSelector} />
            </div>
          )}

          <div className={stylesNew.schedulerNew_calendar}>
          { !bookAppointmentOpen ? 
            <Scheduler
              data={data}
              loading={loading}
              // appointmentForm={AppointmentForm}
              flexibleSpace={this.flexibleSpace}
              tooltipContent={this.tooltipContent}
              tooltipVisible={tooltipVisible}
              toggleTooltipVisibility={this.toggleTooltipVisibility}
              currentViewName={currentViewName}
              currentViewNameChange={this.currentViewNameChange}
              currentDate={currentDate}
              currentDateChange={this.currentDateChange}
              editingFormVisible={editingFormVisible}
              toggleEditingFormVisibility={this.toggleEditingFormVisibility}
              confirmationVisible={confirmationVisible}
              toggleConfirmationVisible={this.toggleConfirmationVisible}
              onEditingAppointmentIdChange={this.onEditingAppointmentIdChange}
              onAddedAppointmentChange={this.onAddedAppointmentChange}
              startDayHour={startDayHour}
              endDayHour={endDayHour}
              commitChanges={this.commitChanges}
              commitDeletedAppointment={this.commitDeletedAppointment}
              schedulerHeight={isEmbedded ? 550 : 800}
              isStaffModule={this.props.isStaffModule}
            />
          : null}
          </div>
          <EditActivityPopup
            open={editingFormVisible}
            handleClose={() => {
              this.toggleEditingFormVisibility();
              this.toggleTooltipVisibility();
            }}
          />
          <EditAppointmentPopup handleUpdated={() => {
            this.toggleTooltipVisibility()
          }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.scheduler,
    ...ownProps,
    ...state.dashboard.dashboardData,
  };
};

const mapDispatchToProps = {
  setToggleLoading,
  setActivities,
  getActivities,
  filterOutActivities,
  changeFilter,
  toggleVisibility,
  changeFieldId,
  setIsNewAppointment,
  setAddedAppointment,
  setViewName,
  setCurrentDateScheduler,
  addAppointment,
  editAppointment,
  cancelAppointment,
  acceptAppointment,
  cleanReducerState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(withSnackbar(SchedulerContainer)));

SchedulerContainer.contextType = ScheduleModuleContext;