import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import styles from '../Form.module.scss';
import cn from 'classnames';

// Nav
import {withRouter} from 'react-router-dom'
import {publicRoutes} from 'common/routes'

// Auth
import { useAuthNContext } from 'context/Auth/useAuthContext';
import validator from 'validator';

const LoginForm = ({history: { push: navigate }}) => {

  const {isProcessing, handleUserLogin, authError} = useAuthNContext();
  const [userName, setSetUserName] = useState('');
  const [password, setUserPassword] = useState('');

  const [errors, setErrors] = useState({ username: '', password: '' });
  
  const isValid = () => {
    let error = {};

    if (!validator.isEmail(userName)) {
      error.username = 'Please enter correct e-mail';
    }

    if (validator.isEmpty(userName)) {
      error.username = 'The field is required';
    }

    if (validator.isEmpty(password)) {
      error.password = 'The field is required';
    }

    if (Object.keys(error).length) {
      setErrors(error);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleLogin = e => {
    e.preventDefault();
    handleUserLogin(userName, password)
  };

  let onKeyPressed = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin(e);
    }
  };
  return (
    <div className={styles.login}>
      <h4 className={styles.title}>Welcome 👋</h4>

      {authError ? (
        <Box mb={2}>
          <p className={styles.error}>
            {authError.message} {authError.icon ? <span style={{fontSize: 21}}>{authError.icon}</span> : null}
          </p>
        </Box>
      ) : null}

      <div className={styles.group}>
        <label htmlFor="userName">Email</label>
        <input id="userName" type="text" value={userName} onChange={evt => setSetUserName(evt.target.value.trim())} />
        {/* <p className={styles.error}>{errors.username}</p> */}
      </div>
      <div className={styles.group}>
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={password}
          onKeyPress={onKeyPressed} // Only for "Enter" purpose
          onChange={evt => setUserPassword(evt.target.value.trim())}
        />
        <p className={styles.error}>{errors.password}</p>
      </div>
      <Box className={styles.buttons} mb={3}>
        <Button size="small" onClick={() => navigate(publicRoutes.forgotPassword)}>
          Forgot password?
        </Button>
        <Button disabled={isProcessing} color="primary" variant="contained" onClick={handleLogin} className={cn({ [styles.loading]: isProcessing })}>
          {isProcessing ? 'Signing In...' : 'Sign In'}
        </Button>
      </Box>
    </div>
  );
};


export default withRouter(LoginForm);
