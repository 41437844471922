
import React, { Fragment } from 'react';
import cn from 'classnames';

// Material
import { Grid, makeStyles} from '@material-ui/core';
import { DragHandleRounded } from '@material-ui/icons';

// UI Components
// import Canvas from 'components/Dashboards/elements/Canvas';

// Components
import ShortSchedule from 'common/components/Schedule/ShortSchedule/ShortSchedule';
import LabResults from 'common/components/LabResult/LabResult';
import MemberList from 'common/components/Patient/List';
import WelcomeWidget from '../elements/WelcomeWidget';
import TodoList from 'components/ToDo/List';
import Agenda from 'common/components/Schedule/ShortSchedule/Agenda';

const CareTeamDashboard = () => {
  const styles = useStyles()
  const dateSelect = new Date();

  return (
    <Fragment>
    
    <Grid container>
      
      <Grid xs={12}>
        <WelcomeWidget />
      </Grid>
    </Grid>

    <div className={styles.wrapper}>
      
    <Grid container spacing={0}>
      <Grid xs={12}>
        <div className={styles.caseLoad}>
          <MemberList max={6} />
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className={cn(styles.sectionNode, styles.firstNode)}>
          <div className={styles.roundedWhite}>
            <div className={styles.sectionLabel}>
              <h4>My Agenda</h4>
              <DragHandleRounded color="disabled" />
            </div>
            <ShortSchedule dateSelect={dateSelect} />
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <div className={cn(styles.sectionNode)}>
          <div className={styles.roundedWhite} style={{height: 450}}>
            <div className={styles.sectionLabel}>
              <h4>Positive Lab Results</h4>
              <DragHandleRounded color="disabled" />
            </div>
            <LabResults />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className={cn(styles.sectionNode)}>
          <div className={styles.roundedWhite}>
            <div className={styles.sectionLabel}>
              <h4>My Tasks</h4>
              <DragHandleRounded color="disabled" />
            </div>
            <TodoList />
          </div>
        </div>
      </Grid>
    
    </Grid>
    </div>

  </Fragment>
  )

};

export default CareTeamDashboard;

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: '#F5F6F8',
    borderRadius: 8,
  },
  firstNode: {
    paddingLeft: 15,
  },
  sectionNode: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  caseLoad: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 10,
    paddingTop: 15,
    minHeight: 180,
    backgroundColor: '#fff',
    borderBottom: '1px solid #E0E0E0',
    borderRadius: '8px 8px 0 0',
  },
  roundedWhite: {
    backgroundColor: '#fff',
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    padding: 10,
  },
  sectionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 10,
    fontWeight: 'bold',
  },
});
