import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { useGooglePlacesSearch, geocodeByAddress } from 'react-google-places-search';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { truncateString } from 'common/helpers/dataHelpers';

import config from '../../../../config/config';
import { Autocomplete } from './Autocomplete';

export const LocationInput = ({
  control,
  name,
  defaultValue,
  required = false,
  placeholder,
  style,
  className,
  loading: loadingProgress,
  onLocationChange,
  item,
  ...props
}) => {
  const [{ places, loading }, autocomplete] = useGooglePlacesSearch({
    apiKey: config.googleAPI.places.API_KEY,
    debounce: 500,
    minLengthAutocomplete: 3,
    // autocompletionRequest: {
    //   types: ['(regions)'],
    // },
  });
  const [editing, setEditing] = useState(false);
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  const handleChange = event => {
    const string = event.target.value;
    if (!string) return;
    autocomplete(string);
  };

  const handleSelect = (event, item) => {
    if (!item) return onChange(null);
    //.then((data) => getLatLng(data[0]))
    geocodeByAddress(item.label).then(data => {
      onChange({
        ...item,
        geo: data,
      });
      onLocationChange && onLocationChange(item);
    });
  };

  if (value && typeof value === 'string' && !editing) {
    return (
      <div style={{ display: 'flex' }}>
        <LocationOnIcon fontSize="small" style={{ opacity: '0.5' }} />
        <Tooltip title={value} arrow>
          <Typography>{truncateString(value, 30)}</Typography>
        </Tooltip>
        <Button style={{ padding: '1px' }} onClick={() => setEditing(true)}>
          <CreateIcon fontSize="small" />
        </Button>
      </div>
    );
  }

  return (
    <Autocomplete
      {...inputProps}
      {...props}
      filterOptions={options => options}
      options={places.length ? places : item ? [item] : []}
      onChange={handleChange}
      onSelect={handleSelect}
      invalid={invalid}
      error={error}
      style={style}
      value={item || value}
      loadingCircle={loading}
      onOpen={() => setEditing(true)}
      loading={loadingProgress}
    />
  );
};

LocationInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  styole: PropTypes.object,
  className: PropTypes.string,
};
