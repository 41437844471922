import React, { useState } from 'react';
import Button from 'common/components/Button/ButtonSimple';
import styles from './index.module.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LabDetails from './LabDetails';

export const LabDetailDialog = ({ data }) => {
  const [open, setOpen] = useState(false)
  const handleDialogOpen = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  return (
    <>
      <Button onClick={handleDialogOpen} className={styles.view_more}>View Report</Button>
      <LabDetails data={data} handleClose={handleDialogClose} open={open} />
    </>
  )
}
export const LinkToChat = ({ id }) => (
  <Link to={`/patients/${id}/messaging`} className={styles.view_more}>Message Patient</Link>
)


export const LabAction = ({ item
}) => {
  let iconColor;
  let resultTitle;
  let resultComponent;
  switch (item.status) {

    case "NEW":
      resultComponent = <div className={styles.action_text}>Scheduled</div>; //!moment(item.dueDate).isAfter() ?
      break;

    case "MISSED":
      resultComponent = <LinkToChat id={item.patient._id} />;
      break;

    case "EXCUSED":
      resultComponent = <div className={styles.action_text}>Excused</div>; //!moment(item.dueDate).isAfter() ?
      break;
    case "EXTENDED":
      resultComponent = <div className={styles.action_text}>Extended for {moment(item.dueDate).format("DD MMM")}</div>;
      break;
    case "COLLECTED":
      resultComponent = <div className={styles.action_text}>Collected</div>;
      break;
    case "PARTIAL":
    case "COMPLETED":
      resultComponent = <LabDetailDialog data={item} />;
      break;
  }
  return (
    <div style={
      {
        display: 'flex', alignItems: 'center'
      }
    }>
      {resultComponent}

    </div>
  )
}
