import _ from 'lodash';

/**
 * Line Break Text Parser
 * @param {string} textString 
 * @returns {string[]|[]} array of family members
 */
export const lineBreakParser = textString => {
    if (!textString.length) return []
    const [...parsedString] = textString.split('\n')
    return parsedString
}

/**
 * Helper for Array.sort to sort by date
 * @param {string} pathToDate object path with date 
 * @param {boolean} decs sort descending
 * @returns {Object} Array.sort configuration
 */
export const sortByDate = (pathToDate, desc = false) => (a, b) => desc 
    ? new Date(_.get(b, pathToDate)) - new Date(_.get(a, pathToDate))
    : new Date(_.get(a, pathToDate)) - new Date(_.get(b, pathToDate));

/**
 * Truncate if String
 * @param {string|number|Object} content original content 
 * @param {number} trimmer character count for truncating
 * @returns {string} truncated string if content typeof String 
 */
export const truncateString = (content, trimmer) => (
    _.isString(content) 
    ? content.length > trimmer ? `${String(content).substring(0,trimmer)}...` : content 
    : content
)

/**
 * Push elements to array if condition true (internal)
 * @param {Any} condition check if true 
 * @param {Any} elements element to store in array
 * @returns {Array} if condition true returns elements, else empty array 
 */
export const insertIf = (condition, ...elements) => {
    return condition ? elements : []
}

/**
 * Convert Object to Array
 * @param {Object} obj Object to convert
 * @returns {Array} Array from Object 
 */
export const objectToArray = (obj) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);
  
    while(i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];    
    return resArray
  
  }

/**
 * Filter object by keys
 * @param {array} data raw object
 * @param {string[]} keys keys to filter
 * @returns fitlered object
 */
 export const filteredObject = (data, keys) => {
    if (!Array.isArray(keys)) return data;
    return keys.reduce((obj, key) => ({ ...obj, [key]: data[key] }), {});
  };
  
/**
 * Group array of objects by object key. Optional filter for keys to return in array
 * @param {array} data raw array of objects
 * @param {string} key grouping key
 * @param {array} filter keys to return in array (optional)
 */
 export const groupByFiltered = (data, key, filter) => {
    const hasFilter = Array.isArray(filter) && filter?.length;
    const grouped = data?.reduce((result, item) => {
      if (!result[item[key]]) result[item[key]] = [];
  
      result[item[key]].push(hasFilter ? filteredObject(item, filter) : item);
      return result;
    }, {});
    return grouped;
  };
  