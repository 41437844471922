// Avatar Sizes
export const avatarDimensions = {
  xxsmall: '26px',
  xsmall: '30px',
  small: '40px',
  small2: '65px',
  medium: '80px',
  large: '100px',
  xlarge: '160px',
}

// Colors based on Material Design 2014 / 400
export const defaultColors = [
  '#EC407A',
  '#EF5350',
  '#29B6F6',
  '#26A69A',
  '#9CCC65',
  '#FFCA28',
  '#5C6BC0',
  '#FF7043',
  '#8D6E63',
  '#78909C',
];

// Options for MemberStatus
export const statusOptions = [
  {status: 'IMPORTED', title: 'Imported', color: '#2A96FF'},
  {status: 'INSERTED', title: 'Inserted', color: '#2A96FF'},
  {status: 'SET_UP', title: 'Set Up', color: '#2A96FF'},
  {status: 'PRE_ENROLLED', title: 'Pre-Enrolled', color: '#2A96FF'},
  {status: 'ACTIVATED', title: 'Activated', color: '#00B500'},
  {status: 'ACTIVE', title: 'Active with App', color: '#00B500'},
  {status: 'DISCHARGED', title: 'Discharged', color: '#E90100'},
  {status: 'SEMI_DISCHARGED', title: 'Semi Discharged', color: '#E90100'},
  {status: 'FLAGGED_FOR_DISCHARGE', title: 'Flagged for Discharge', color: '#E90100'},
  {status: 'QUEUED_FOR_DISCHARGE', title: 'Queued for Discharge', color: '#E90100'},
];