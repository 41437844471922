import React, {useEffect} from 'react';
import _ from 'lodash';

// Components
import Skeleton from './Skeleton';

// Material
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// GraphQl
import { useQuery } from '@apollo/client';
import { GET_LABS_RESULTS } from 'data/graphql/labs';

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';

// Utils
const LAB_RESULTS = {
  POSITIVE: 'POSITIVE',
  POSITIVE_DILUTE: 'POSITIVE_DILUTE',
  NEGATIVE_DILUTE: 'NEGATIVE_DILUTE',
  NEGATIVE: 'NEGATIVE',
  INCONCLUSIVE: 'INCONCLUSIVE',
  MISSED: 'MISSED',
};

const labResultChecker = labs => {
  console.log('labResultChecker', labs);
  let positive = 0,
    dilute = 0,
    positiveD = 0,
    negative = 0,
    missed = 0,
    inconclusive = 0;
  _.forEach(labs, lab => {
    if (_.isEqual(lab.result, LAB_RESULTS.POSITIVE)) positive++;
    if (_.isEqual(lab.result, LAB_RESULTS.NEGATIVE_DILUTE)) dilute++;
    if (_.isEqual(lab.result, LAB_RESULTS.POSITIVE_DILUTE)) positiveD++;
    if (_.isEqual(lab.result, LAB_RESULTS.NEGATIVE)) negative++;
    if (_.isEqual(lab.result, LAB_RESULTS.INCONCLUSIVE)) inconclusive++;
    if (_.isEqual(lab.result, LAB_RESULTS.MISSED)) missed++;
  });
  console.log('labResultChecker dilue', dilute);
  return { positive, positiveD, dilute, negative, missed, inconclusive };
};

const LabsHeaderActions = () => {
  const classes = useStyles();

  // Data
  const {memberId} = useMemberStateContext();
  const {data, error, loading } = useQuery(GET_LABS_RESULTS, {
    variables: { filters: { patientId: [memberId] } },
  });

  if (loading || error)
    return (
      <Box className={classes.root}>
        <Skeleton />
      </Box>
    );

  if (data) {
    const results = labResultChecker(data.getLabs);

    const mapArray = [
      {
        title: results.positive,
        text: 'Positive',
      },
      ...(results.positiveD ? 
      [{
        title: results.positiveD,
        text: 'Positive (D)',
      }] : []),
      ...(results.dilute ? 
      [{
        title: results.dilute,
        text: 'Dilute',
      }] : []),
      {
        title: results.negative,
        text: 'Negative',
      },
      {
        title: results.missed,
        text: 'Missed',
      },
      {
        title: results.inconclusive,
        text: 'Inconclusive',
      },
    ];

    return (
      <Box className={classes.root}>
        <Box className={classes.list} component="ul">
          {mapArray.map(({ title, text }, i) => (
            <Box component="li" className={classes.item} key={i}>
              <Typography variant="h4" children={title} />
              <Typography variant="h6" children={text} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
};

LabsHeaderActions.propTypes = {
};

export default LabsHeaderActions;

const useStyles = makeStyles({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 80,
    '& h4, & h6': {
      opacity: '.5',
      fontFamily: '"Avenir Next Medium"',
    },
    '& h4': {
      fontSize: 40,
      textAlign: 'center',
    },
    '& h6': {
      fontSize: 14,
    },
  },
});