import React from "react";
import styles from "./TextField.module.scss";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputAdornment from '@material-ui/core/InputAdornment';

const TextFieldComponent = ({
  field,
  form: { touched, errors },
  width,
  height,
  hideErrorBox,
  prefix,
  ...props
}) => {
  const hasError = touched?.[field.name] && errors?.[field.name];
  return (
    <Box className={styles.wrapper}>
      <TextField
        id="outlined-name"
        error={hasError}
        variant="outlined"
        InputProps={prefix ? {
          startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>
        } : null}
        {...props}
        {...field}
      />

      {hasError
        ? <Typography className={styles.error}>{errors?.[field.name]}</Typography>
        : null}
    </Box>
  );
};

export default TextFieldComponent;