import React, { createContext, useState, useEffect } from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCountRenders } from 'common/hooks/useCountRenders'

// Apollo
// import { withApollo } from '@apollo/client/react/hoc';
import { useApolloClient, useMutation, useLazyQuery } from '@apollo/client'

// Redux
import { useDispatch, useSelector } from 'react-redux'

import { checkIfUserHasPermissionToAction } from 'selectors'

//GQL
import { GET_ALL_PARTICIPANT_ACTIVITIES, ADD_ACTIVITY, GET_ACTIVITIES } from 'data/graphql/activity'
import { getActivities } from 'actions/scheduler'

//Helpers
import { mapAppointmentData } from 'components/Scheduler/utils/ActivitySchedulerHelper'

// Data
import { useMemberStateContext } from './Member/useMemberContext'
import { useScheduleModuleContext } from './Activities/useActivitiesContext'
import useAccountContext from './Account/useAccountContext'
import useMembersContext from './Members/useMembersContext'
import { getMemberById } from 'context/Members/MembersContext';

export const BookingContext = createContext()

const BookingContextProvider = ({ children, value }) => {
  // Hooks
  const dispatch = useDispatch()
  const client = useApolloClient()

  useCountRenders('[BookingContext]')

  // Hooks
  const { enqueueSnackbar } = useSnackbar()

  // Context Values
  const { setBookAppointmentOpen } = useScheduleModuleContext()
  const { memberData, memberId, memberType } = useMemberStateContext()
  const { patients, family, staff } = useMembersContext();
  const { userId: currentUserId, webexPrefix } = useAccountContext()
  console.log('BookingContext', memberData)

  // Props
  const selectedMember = memberId

  //initialValues
  const initialStateValues = {
    hostEditMode: false,
    memberEditMode: false,
    bookingType: 'single',
    attendeeConfig: null,
    hasRegardingMember: false,
    hasInvitedFamily: false,
    templateCode: null,
    mainPatient: null,
    invitedMembers: [],
    host: null,
    cohosts: [],
    webexLink: '',
    recurrence: null,
    activities: [],
    activitiesLoading: false,
    duration: 30,
    selectedTime: null,
    description: '',
    bookingInProgress: false,
    submitDisabled: false,
  }

  // Context State
  const [hostEditMode, setHostEditMode] = useState(false)
  const [memberEditMode, setMemberEditMode] = useState(false)
  const [bookingType, setBookingType] = useState(initialStateValues.bookingType)
  const [attendeeConfig, setAttendeeConfig] = useState(null)
  const [hasRegardingMember, setHasRegardingMember] = useState(false)
  const [hasInvitedFamily, setHasInvitedFamily] = useState(false)
  const [hasFamilyMembers, setHasFamilyMembers] = useState(false)
  const [templateCode, setTemplateCode] = useState(null)
  const [mainPatient, setMainPatient] = useState(memberId)
  const [invitedMembers, setInvitedMembers] = useState([])
  const [host, setHost] = useState(currentUserId)
  const [cohosts, setCohosts] = useState(initialStateValues.cohosts)
  const [webexLink, setWebexLink] = useState(initialStateValues.webexLink)
  const [recurrence, setRecurrence] = useState(initialStateValues.recurrence)
  const [duration, setDuration] = useState(initialStateValues.duration)
  const [selectedTime, setSelectedTime] = useState(initialStateValues.selectedTime)
  const [description, setDescription] = useState(initialStateValues.description)
  const [bookingInProgress, setBookingInProgress] = useState(initialStateValues.bookingInProgress)
  const [submitDisabled, setSubmitDisabled] = useState(initialStateValues.submitDisabled)

  // Context State: Calendar
  const [activities, setActivities] = useState([])

  const scheduler = useSelector((state) => state.scheduler)
  const refreshScheduler = () => {
    //reload activities
    const { currentDate, currentViewName, type, patient } = scheduler
    dispatch(getActivities(client, currentDate, currentViewName, type, patient))
  }

  // Setup
  const mainFamily = memberType === 'family' ? memberData : null

  // Redux
  const membersList = [...patients, ...family];
  const memberForRelations = getMemberById(membersList, mainPatient?._id);
  const relatedPatients = memberForRelations?.relations;

  // const relatedPatients = useSelector((state) => getPatientRelations(state, mainPatient)) || []
  const canEditHost = false; //useSelector((state) => checkIfUserHasPermissionToAction(state, { action: 'EDIT_HOST' }))

  const [
    getParticipantEvents,
    { called: calledEvents, loading: activitiesLoading, data: appointments, refetch: refetchEvents, error: errors },
  ] = useLazyQuery(GET_ALL_PARTICIPANT_ACTIVITIES, {
    nextFetchPolicy: 'cache-and-network',
  })

  const [scheduleACall] = useMutation(ADD_ACTIVITY, {
    refetchQueries: [{ query: GET_ACTIVITIES }],
    onCompleted: (result) => {
      console.log('Call has been scheduled ', result)
      enqueueSnackbar('Call scheduled', { variant: 'success' })
      // handleLoadMemberEvents(true);
      // GET_ACTIVITIES //refresh
      refreshScheduler()
      setBookAppointmentOpen(false)
    },
    onError: (e) => {
      enqueueSnackbar(e && e.message ? e.message : 'Error: Call was not scheduled.', { variant: 'error' })
    },
  })

  const bookingOptions = [
    {
      id: 'single',
      title: 'Client',
      enabled: memberType === 'patient',
      handler: () => {},
      helperTooltip: 'Patient Only',
    },
    {
      id: 'conference',
      title: 'Conference',
      enabled:
        (memberType === 'patient' && hasFamilyMembers) || (memberType === 'family' && relatedPatients?.length > 0),
      handler: () => {},
      helperTooltip: 'Patient and Family',
    },
    {
      id: 'family',
      title: 'Family',
      enabled: (memberType === 'patient' && hasFamilyMembers) || memberType === 'family',
      handler: () => {},
      helperTooltip: 'Family Only',
    },
    {
      id: 'team',
      title: 'Team',
      enabled: memberType === 'patient' || (memberType === 'family' && relatedPatients?.length > 0),
      handler: () => {},
      helperTooltip: 'Care Team Only',
    },
  ]

  // TO DO:
  // Changes to mainPatient rely on side effects and useState. This can easily get out of sync and convolutes the data flow
  // Instead, let's create a a mainPatient change handler, which will process the data necessary and set state
  // useEffect can then be set to keep track of changes to mainPatient, and trigger the updater function

  // Side Effects
  /**initialization */
  useEffect(() => {
    const mainPatient = memberType === 'family' ? memberData?.relatedPatients[0]._id : selectedMember
    setMainPatient(mainPatient)
    // if we have no host yet then the current user is the host otherwise what is selected in the apointmentBooker.host field
    // Changing the messy implementation. Again, let's make it a practice to not have convoluted logic.
    // It cost more to decrypt and test logic than to define functions and variables that guide the logic (a MAJOR issue on the backend code base).
    // Set Host
    const getHostFromCareTeam = () => memberData?.careTeamDetails?.find((member) => member.primary)?._id
    const currentHost = !host && !canEditHost ? currentUserId : getHostFromCareTeam()
    setHost(currentHost)
  }, [selectedMember])

  useEffect(() => {
    const hasFamilyMembers = relatedPatients?.length > 0
    setHasFamilyMembers(hasFamilyMembers)
  }, [relatedPatients])

  useEffect(() => {
    setHostEditMode(canEditHost)
  }, [hostEditMode])

  useEffect(() => {
    const isRegarding =
      (memberType === 'family' && bookingType !== 'conference') || bookingType === 'family' || bookingType === 'team'
    setHasRegardingMember(isRegarding)
    handleSetBookingType(bookingType)
  }, [bookingType])

  useEffect(() => {
    // when the appointment participants change we need to load their data from the server
    handleLoadMemberEvents()
  }, [mainPatient, invitedMembers, host, cohosts])

  useEffect(() => {
    const submitDisabled =
      bookingInProgress ||
      !mainPatient ||
      !selectedTime ||
      (hasInvitedFamily && invitedMembers?.length === 0) ||
      (bookingType === 'team' && cohosts?.length == 0)
    setSubmitDisabled(submitDisabled)
  }, [mainPatient, bookingInProgress, hasInvitedFamily, invitedMembers, selectedTime, cohosts, bookingType])

  useEffect(() => {
    if (activitiesLoading) return console.log('[BookingContext] activitiesLoading')
    if (appointments && appointments.getAllParticipantActivities) {
      console.log('[BookingContext] setActivities ran')
      const activitiesData = appointments.getAllParticipantActivities.map(mapAppointmentData)
      console.log('[BookingContext] activitiesData raw', appointments, 'mapped', activitiesData)
      setActivities(activitiesData)
    }
  }, [appointments, activitiesLoading])

  // BookingType Setup
  const handleSetBookingType = (type) => {
    switch (type) {
      case 'single':
        setAttendeeConfig([
          { type: 'patient', label: 'Patient' },
          { type: 'primaryClinician', label: 'Specialist (Host)' },
          { type: 'invitedCareTeam', label: 'Invited Specialists' },
        ])
        setHasInvitedFamily(false)
        setTemplateCode('THERAPY_SESSION')
        setInvitedMembers([])
        break
      case 'conference':
        setAttendeeConfig([
          { type: 'patient', label: 'Patient' },
          { type: 'invitedFamily', label: 'Family' },
          { type: 'primaryClinician', label: 'Specialist (Host)' },
          { type: 'invitedCareTeam', label: 'Invited Specialists' },
        ])
        setHasInvitedFamily(true)
        setTemplateCode('THERAPY_SESSION_FAMILY')
        setInvitedMembers(mainFamily ? [mainFamily] : [])
        break
      case 'family':
        setAttendeeConfig([
          { type: 'invitedFamily', label: 'Family' },
          { type: 'primaryFamilyClinician', label: 'Family Specialist (Host)' },
          { type: 'invitedCareTeam', label: 'Invited Specialists' },
        ])
        setHasInvitedFamily(true)
        setTemplateCode('FAMILY_SESSION')
        setInvitedMembers(mainFamily ? [mainFamily] : [])
        break
      case 'team':
        setAttendeeConfig([{ type: 'invitedCareTeam', label: 'Invited Specialists' }])
        setHasInvitedFamily(false)
        setTemplateCode('TEAM_CONSULTATION')
        setInvitedMembers([])
        break
      case 'singleFamily':
        setAttendeeConfig([
          { type: 'familyMember', label: 'Family Member' },
          { type: 'primaryFamilyClinician', label: 'Family Specialist (Host)' },
          { type: 'invitedCareTeam', label: 'Invited Specialists' },
        ])
        setHasInvitedFamily(true)
        break
      default:
        console.log('[BookingSetup] BookingType Default')
    }
  }

  // TEMP: Debug mounts
  useEffect(() => {
    console.log('[BookingContext] Has mounted')
    return () => console.log('[BookingContext] Has unmounted')
  }, [])

  // Validators

  // Handlers
  const handleClearData = () => {
    setInvitedMembers(initialStateValues.invitedMembers)
    setCohosts(initialStateValues.cohosts)
    setHost(initialStateValues.host)
    setMainPatient(initialStateValues.mainPatient)
    setActivities(initialStateValues.activities)
    setDuration(initialStateValues.duration)
    setSelectedTime(initialStateValues.selectedTime)
    setDescription(initialStateValues.description)
  }

  const handleOnSubmit = async () => {
    setBookingInProgress(true)

    const prefix = webexPrefix
    const includeMainPatient = bookingType === 'single' || bookingType === 'conference'

    try {
      const variables = {
        activityDetails: {
          duration,
          startDate: moment(selectedTime).format(),
          invitedMembers: includeMainPatient ? [...invitedMembers, mainPatient] : invitedMembers,
          templateCode,
          description,
          webexLink: prefix && webexLink ? `${prefix.value}${webexLink}` : webexLink,
          recurrence: recurrence ? recurrence : null,
          host: host,
          regardingMember: mainPatient,
          cohosts,
        },
      }
      scheduleACall({ variables })
    } catch (error) {
      console.error('[BookingContext] ScheduleByTemplate Error', error)
    }
  }

  const handleLoadMemberEvents = (currentDate = new Date(), currentViewName = 'week') => {
    console.log('[BookingContext] handleLoadMemberEvents started')
    //TODO for this to work we need the week currentViewName, currentDate from the multischeduler
    const start = moment(currentDate).startOf(currentViewName.toLowerCase())
    const end = moment(start).endOf(currentViewName.toLowerCase())
    let variables = {
      filters: {
        startDate: start.format(),
        endDate: end.format(),
        invitedMembers: [...invitedMembers, ...(!hasRegardingMember && mainPatient ? [mainPatient] : [])],
        staffList: [...(host ? [host] : []), ...cohosts],
      },
    }
    getParticipantEvents({ variables })
    console.log('[BookingContext] handleLoadMemberEvents done')
    if (calledEvents) handleRefetchMemberEvents()
  }

  const handleRefetchMemberEvents = () => {
    console.log('[BookingContext] refetching member events')
    setTimeout(() => refetchEvents(), 0)
  }

  const contextValues = {
    // Calendar
    activities,
    activitiesLoading,
    setActivities,
    handleClearData,
    handleLoadMemberEvents,
    handleRefetchMemberEvents,

    // BookingSetup
    relatedPatients,
    hasRegardingMember,
    bookingOptions,
    handleOnSubmit,
    handleSetBookingType,
    submitDisabled,
    bookingInProgress,
    canEditHost,
    hasFamilyMembers,
    hasInvitedFamily,
    bookingType,
    setBookingType,
    attendeeConfig,
    hostEditMode,
    setHostEditMode,
    memberEditMode,
    setMemberEditMode,
    mainPatient,
    setMainPatient,
    invitedMembers,
    setInvitedMembers,
    webexLink,
    setWebexLink,
    recurrence,
    setRecurrence,
    host,
    setHost,
    cohosts,
    setCohosts,
    description,
    setDescription,
    duration,
    setDuration,
    selectedTime,
    setSelectedTime,
  }

  return <BookingContext.Provider value={contextValues}>{children}</BookingContext.Provider>
}

export default BookingContextProvider
