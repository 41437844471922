import {gql} from '@apollo/client';
import {NOTE_FIELDS} from './notes';
import {ENCOUNTER_FIELDS, MEMBER_DEVICE_FIELDS} from './member';

export const GET_PATIENTS = gql`
  query patients($filters: PatientsFilter) {
    patients(filters: $filters) {
      _id
      firstName
      lastName
      phone
      email
      image
      type
      todaysGoal
      engagementLevel
      riskLevel
      status
      active
    }
  }
`;
export const GET_HIGH_RISK_PATIENTS = gql`
  query getHighRiskPatients($riskLevel: Int) {
    getHighRiskPatients(riskLevel: $riskLevel) {
      _id
      firstName
      lastName
      image
      type
      todaysGoal
      engagementLevel
      riskLevel
    }
  }
`;
export const GET_PATIENT = gql`
  query patient($_id: String!) {
    patient(_id: $_id) {
      _id
      firstName
      lastName
      email
      image
      birthDate
      locationId
      careTeam {
        _id
        primary
        chatRoomId
      }
      generatedPsw
      levelOfCare
      lastUsed
      drugChoice {
        code
        name
      }
      program {
        _id
        name
      }
      address {
        street1
        street2
        city
        country {
          code
          name
        }
        state
        zip
      }
      coDisorders {
        code
        name
      }
      gender
      mrn
      encounterId
      dischargeDate
      lastActive
      clinicalOverview
      phone
      notes {
       ${NOTE_FIELDS}
      }
      attachments {
        note
        files {
          url
          name
          type
        }
      }
      roles_new
      admitDate
      highProfile
      alerts
      tags
      familyRelease
      engagementLevel
      riskLevel
    }
  }
`;
export const GET_FULL_PATIENT_INFO = gql`
  query patientFullInfo($_id: String!) {
    patientFullInfo(_id: $_id) {
      _id
      type
      firstName
      lastName
      emotionalState
      familyReleaseText
      averageRating
      email
      image
      emrPic
      status
      birthDate
      locationId
      lastUsed
      drugChoice {
        code
        name
      }
      nickName
      gamificationDisabled
      address {
        street1
        street2
        city
        country {
          code
          name
        }
        state
        zip
      }
      coDisorders {
        code
        name
      }
      gender
      mrn
      encounterId
      dischargeDate
      lastActive
      phone
      clinicalOverview
      ${ENCOUNTER_FIELDS}
      notes {
        ${NOTE_FIELDS}
      }
      devices {
       ${MEMBER_DEVICE_FIELDS}
      }
      attachments {
        note
        createdAt
        files {
          url
          name
          type
        }
      }
      todaysActivities {
        name
      }
      todaysGoal
      engagementLevel
      riskLevel
      roles_new
      medication {
        medicinalproduct
      }
      careTeamDetails {
        _id
        firstName
        lastName
        image
        locationName
        primary
        phone
        email
      }
      soberCircle {
        _id
        createdAt
        teamName
        members {
          _id
          lastName
          firstName
          image
          isTeamLead
          address {
            country {
              code
              name
            }
            city
          }
        }
      }
      relations {
        _id
        memberId
        firstName
        lastName
        image
        email
        phone
        activatedAccount
        relation {
          caronId
          name
        }
        releaseType {
          name
        }
      }
      admitDate
      highProfile
      alerts
      tags
      familyReleaseE {
        _id
        name
        caronId
      }
      levelOfCareE {
        _id
        name
        caronId
      }
      program {
        _id
        name
        caronId
        displayName
      }
      deviceType
      appleId
      googleId
      activatedAccount
      cognitoInfo {
        status
      }
      deviceTokens
      uiNotes{
        type
        title
        message
        actions
      }
      projectedDischargeDate
    }
  }
`;
export const INSERT_PATIENT = gql`
  mutation insertPatient($patient: PatientInput) {
    insertPatient(patient: $patient) {
      _id
      firstName
      lastName
      image
    }
  }
`;
export const EDIT_PATIENT = gql`
  mutation editPatient($patient: PatientEditInput) {
    editPatient(patient: $patient) {
      _id
      firstName
      lastName
      email
      phone
      gender
    }
  }
`;
export const DELETE_PATIENT = gql`
  mutation deletePatient($patientId: ObjectID!) {
    deletePatient(patientId: $patientId)
  }
`;

export const SEND_PATIENT_NOTIFICATION = gql`
  mutation sendPatientNotification($patientId: ObjectID!, $notification: NotificationInput) {
    sendPatientNotification(patientId: $patientId, notification: $notification)
  }
`;

export const SEARCH_DISORDERS = gql`
  query getDisorders($searchTerm: String) {
    getDisorders(searchTerm: $searchTerm) {
      name
      code
    }
  }
`;
