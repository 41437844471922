import {useContext} from 'react';
import {AuthNContext} from './AuthNContext';
import {AuthZContext} from './AuthZContext';

const useAuthNContext = () => {
    const context = useContext(AuthNContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the AuthNContext`) 
    };

    return context

};

const useAuthZContext = () => {
    const context = useContext(AuthZContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the AuthZContext`) 
    };

    return context

};

export {useAuthNContext, useAuthZContext};