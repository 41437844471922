import React from 'react';
import cn from 'classnames';

import { ArrowDownwardRounded, ArrowUpwardRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';


const StatisticWidget = ({number, label, change, trending}) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.label}>{label}</div>
      </div>
      <div className={cn([styles.change])}>
        <div className={styles.number}>{number}</div>
        <div className={styles.pill}>
          {trending === 'up' ? <ArrowUpwardRounded htmlColor="green" height={5} style={{marginRight: 2}} /> : null }
          {trending === 'down' ? <ArrowDownwardRounded htmlColor="red" height={5} style={{marginRight: 2}} /> : null }
          {change}
        </div>
      </div>
    </div>
  )
}

export default StatisticWidget;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 20,
    borderRadius: 10,
  },
  number: {
    fontSize: 42,
    fontWeight: 'bold',
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
  },
  change: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: '#ffcc0033',
    paddingRight: 10,
    paddingLeft: 5,
    marginLeft: 20,
  }
})