/**
 * Takeing location data received from google places and create a return object that matched the
 * AddressInput type used in the backend
 * @param {object} location object received from google places API
 * @returns {Object} AddressInput type object used to save addresses in the backend
 */
export const shapeAddressInputObj = location => {
  console.log('Location object NEEDS MORE TESING', location);
  if (!location || !location.geo || !location.geo[0]) return {};
  const geo = location.geo[0];
  const findCountry = geo.address_components.find(a => a.types.includes('country'));
  const findZip = geo.address_components.find(a => a.types.includes('postal_code'));
  const findState = geo.address_components.find(a => a.types.includes('administrative_area_level_1'));
  const findCity = geo.address_components.find(
    a => a.types.includes('administrative_area_level_3') || a.types.includes('locality')
  );
  //TODO handle the street as well by using different types for the location input, need more info here TODO @Vlad
  return {
    //     street1: String
    // street2: String
    ...(findCity ? { city: findCity.long_name } : {}),
    ...(findCountry ? { country: { code: findCountry.short_name, name: findCountry.long_name } } : {}),
    ...(findState ? { state: findState.short_name } : {}),
    ...(findZip ? { zip: findZip.long_name } : {}),
    displayLine: geo.formatted_address || location.label,
    coordinates: {
      lng: geo.geometry.location.lng(),
      lat: geo.geometry.location.lat(),
    },
  };
};
