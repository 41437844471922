import React from 'react';
import { Avatar } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


const AvatarTitle = ({ member, title }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={member.image} style={{ margin: '0 5px 0 0' }} />
      {member.firstName} {member.lastName} <ArrowForwardIosIcon style={{ color: 'rgba(132, 132, 132, 0.5)' }} />{' '}
      {title}
    </div>
  );
};

export default AvatarTitle;