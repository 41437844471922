import {useContext} from 'react';
import {MemberStateContext, MemberUpdaterContext} from './MemberContext';

const useMemberStateContext = () => {
    const context = useContext(MemberStateContext);
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the MemberStateContext`) 
    };
    return context
};

const useMemberUpdaterContext = () => {
    const context = useContext(MemberUpdaterContext);
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the MemberUpdaterContext`) 
    };
    return context
};

export {useMemberStateContext, useMemberUpdaterContext};