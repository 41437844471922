import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styles from './LabsTablePatient.module.scss';

// Data
import { useQuery } from '@apollo/client';
import { LIST_SURVEY_RESPONSES } from 'data/graphql/assessments';

// Table
import { toTableData } from './helpers';
import SortableTable from 'common/components/Table/SortableTable';
import Filters from 'common/components/PanelFilters/PanelFilters';
import { Messages } from 'config/settings';

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';

const columns = [
  { id: 'date', label: 'Date', width: 120 },
  { id: 'surveyId', label: 'Survey ID', width: 120 },
  { id: 'status', label: 'Status', width: 100},
  { id: 'response', label: 'Response', width: 140 },
  { id: 'actions', label: 'Actions', width: 200 }
];

const SurveyTableMember = () => {

  // Context
  const {memberId} = useMemberStateContext();

  // State
  const [labsList, setLabsList] = useState([]);
  const [filteredLabsList, setFilteredLabsList] = useState([]);
  const momentTwoWeeksAhead = moment().add(2, 'week');
  const momentSubstractMonth = moment().subtract(3, 'months');

  // Data Request
  const { data, error, loading } = useQuery(LIST_SURVEY_RESPONSES, {
    variables: {
      filter: {
        startDate: "2022-01-01",
        memberId: memberId,
      }
    }
  });


  console.log("[Surveys] query", data)

  useEffect(() => {
    if (data) {
      try {
        const converted = toTableData(data.listSurveyResponses, memberId);
        setLabsList(converted);
        setFilteredLabsList(converted);
      } catch (err) {
        console.log(err)
      }
    }
  }, [data])

  // Methods
  const handleFiltersSubmit = updatedList => setFilteredLabsList(updatedList);

  const filterProps = {
    data: labsList,
    hasSearch: true,
    searchLocalBy: ['surveyId', 'date'],
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: LIST_SURVEY_RESPONSES,
      variables: { filters: { startDate: "2022-01-01", memberId: memberId } },
      variablesPath: 'filters',
      queryName: 'listSurveyResponses',
      mutate: toTableData,
    },
    fields: {
      types: {
        label: 'Panel Type',
        placeholder: 'Choose a type',
        initialValue: [],
        fieldPath: 'type',
        fieldValPath: ''
      },
      results: {
        label: 'Result',
        placeholder: 'Choose a result',
        initialValue: [],
        fieldPath: 'result',
        fieldValPath: 'props.label'
      },
    },
    initDates: {
      minDate: momentSubstractMonth,
      maxDate: momentTwoWeeksAhead,
    }
  };

  return (
    <Box className={styles.wrapper}>

      <Filters {...filterProps} />

      <SortableTable
        columns={columns}
        data={filteredLabsList}
        emptyTitle={"No survey responses yet."}
        emptyMessage={"Once we responses come in, you know where to find them."}
        loading={loading}
        loadingMessage={Messages.CALLDASH_LABS_LOADING} />

    </Box>
  );
};

SurveyTableMember.propTypes = {

};


export default SurveyTableMember;
