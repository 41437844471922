import React, { useState } from 'react';
import { Box, Collapse, TextField, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';

import { useSelector } from 'react-redux';
import { getOrganizationWebex, getMyUser } from 'selectors';

const WebexLink = ({ webexLink = '', setWebexLink }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const organizationWebexPrefix = useSelector(getOrganizationWebex);
  const myUser = useSelector(getMyUser);

  const handleToggle = value => () => {
    setChecked(value);
    setWebexLink(value ? myUser.webexLink : null);
  };

  return (
    <Box className={classes.root}>
      <FormControlLabel
        label="WebEx Call"
        control={<Switch color="primary" onChange={handleToggle(!checked)} checked={checked} />}
      />
      <Collapse in={checked}>
        <TextField
          variant="outlined"
          name="webexLink"
          value={webexLink}
          fullWidth
          classes={{ root: classes.webexInput }}
          onChange={e => setWebexLink(e.target.value)}
          InputProps={
            organizationWebexPrefix
              ? {
                  startAdornment: (
                    <InputAdornment style={{ fontSize: 12 }} position="start">
                      {organizationWebexPrefix.substring(14)}
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
      </Collapse>
    </Box>
  );
};

export default WebexLink;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
}));
