import React from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { useSelector } from 'react-redux';

const EmptyAppointmentLayout = ({
  classes,
  data,
  style,
  children,
  ...restProps
}) => {

  console.log("EmptyAppointmentLayout", data);

  const appointmentBooker = useSelector(state => state.appointmentBooker);
  const staff = appointmentBooker.selectedStaff.find(a => a._id == data.host);
  const color = staff ? staff.color : "#ebebeb";
  console.log("EmptyAppointmentLayout", color);
  return <Appointments.Appointment
    {...restProps}
    className={classes.appointment}
    type={data.type}
    style={{
      ...style,
      width: '100%',
      border: `1px solid ${color}`,
      background: `repeating-linear-gradient(-45deg,${color},${color} 5px,#fff 6px)`,
    }}
    onDoubleClick={null}
    onClick={null}
    data={data}
  />
};

export default EmptyAppointmentLayout;
