import React from 'react';

const ErrorScreen = () => (
  
  <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    Woops. Something went wrong! <br/>
    <a href="/">Try reloading.</a>
  </div>

)

export default ErrorScreen;