import React, { useEffect } from 'react'
import moment from 'moment'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'

import BaseActivityForm from './BaseActivityForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { shapeAddressInputObj } from 'common/helpers/addressHelper'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  participants: Yup.array(Yup.object()).required('Choose a patient'),
  date: Yup.date().required('Choose activity date').nullable(),
  duration: Yup.number().min(10, 'Too short').max(400, 'Too long').required('Enter duration of activity (in minutes)'),
  points: Yup.number()
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximim value is 100')
    .required('Fill this field')
    .nullable(),
  tags: Yup.array(Yup.string()).required('Choose a tags'),
  // location: Yup.object()
  //   .required('Select a location')
  //   .nullable(),
  // after: Yup.string().required('After is required'),
})

const formDefaultValues = {
  type: 'core',
  title: '',
  participants: [],
  date: moment().format('yyyy-MM-DDTHH:mm'),
  duration: undefined,
  location: undefined,
  points: 50,
  tags: [],
  after: undefined,
  processType: [],
  saveAsTemplate: false,
}

const onSubmit = ({
  data: { saveAsTemplate, ...data },
  addNewActivity,
  setActivityTemplate,
  next,
  onClose,
  recurrence,
  selectedTemplateCode,
}) => {
  console.log('[NewActivity] submit ', data)
  const variables = {
    activityDetails: {
      duration: data.duration,
      type: data.type.toLowerCase(),
      startDate: moment(data.date).format(),
      invitedMembers: data.participants.map((p) => p._id),
      templateCode: selectedTemplateCode,
      description: data.description,
      name: data.title,
      points: data.points,
      tags: data.tags,
      recurrence: recurrence,
      processType: data.processType.length ? data.processType : null,
      address: shapeAddressInputObj(data.location),
      //add worksheetquestions if we decide it will be editable and not take it from the tamplate as they come in
      ...(data.processType === 'worksheet' ? { addWorksheetQuestions: data.addWorksheetQuestions } : {}),
    },
  }
  console.log('[NewActivity] variables', variables)
  addNewActivity({ variables })

  if (saveAsTemplate) {
    setActivityTemplate(data)
    next()
  } else {
    onClose()
  }
}

const NewActivity = ({ next, previous, onClose }) => {
  const {
    templateId,
    getActivityTemplateDetails,
    templateDetails,
    activityTemplate,
    setActivityTemplate,
    patients,
    defaultPatient,
    dateDefault,
    selectedTemplateCode,
    addNewActivity,
    loading,
    setRecurrence,
    recurrence,
    processTypes: rawProcessTypes,
  } = useActivitiesContext()

  const processTypes = rawProcessTypes?.map(({ name, description }) => ({
    value: name,
    label: description,
  }))

  formDefaultValues.participants = [patients?.find((item) => item._id === defaultPatient)]
  formDefaultValues.date = dateDefault

  const resolver = yupResolver(validationSchema)

  useEffect(() => {
    if (templateId) getActivityTemplateDetails({ variables: { _id: templateId } })
  }, [templateId, getActivityTemplateDetails])

  return (
    <BaseActivityForm
      loading={loading}
      onSubmit={(data) =>
        onSubmit({ data, addNewActivity, setActivityTemplate, next, onClose, recurrence, selectedTemplateCode })
      }
      onBack={previous}
      patients={patients}
      setRecurrence={setRecurrence}
      processTypes={processTypes}
      templateDetails={templateDetails}
      submitButtonTitle="Add activity"
      backButtonTitle="Choose templates"
      resolver={resolver}
      defaultValues={formDefaultValues}
      templateId={templateId}
    />
  )
}

export default NewActivity
