import {gql} from '@apollo/client';
import { NOTE_FIELDS } from './notes';

// Fields
export const ENCOUNTER_FIELDS = `
  episodeOfCare {
    _id
    episodeId
    period {
      startDate
      endDate
    }
    status
    type
    encounters {
      _id
      encounterId
      locationId
      propram {
        _id
        caronId
        name
      }
      levelOfCare
      period {
        startDate
        endDate
      }
      notes {
       ${NOTE_FIELDS}
      }
    }
  }
`
export const MEMBER_DEVICE_FIELDS = `
  sharetek { 
    sharetekId
    deviceName
    status
    type
  }
  soberlink { 
    soberlinkId
    deviceName
    status
    type
  }
  bactrack { 
    bactrackId
    deviceName
    status
    type
  }
`

// Queries
export const GET_DISCHARGED_PATIENTS = gql`
  query dashBoardData {
    getDashboardData {
      dischargedPatients{
        _id
        firstName
        lastName
        mrn
        status
        relations {
          _id
          activatedAccount
          relation {
            name
          }
        }
      }
    }
  }
`;
export const GET_DISCHARGED_FAMILY = gql`
  query dashBoardData {
    getDashboardData {
      dischargedFamilyList{
        _id
        firstName
        lastName
        mrn
        status
      }
    }
  }
`;

// Mutations
export const ACTIVATE_MEMBER = gql`
  mutation activateMember($memberId: ObjectID!) {
    activateMember(memberId: $memberId) {
      _id
    }
  }
`;
export const UPDATE_MEMBER_STATUS = gql`
  mutation updateMemberStatus($statusUpdateInput: statusUpdateInput!) {
    updateMemberStatus(statusUpdateInput: $statusUpdateInput) {
      _id
      status
    }
  }
`;

export const RESEND_ACTIVATE_MEMBER = gql`
  mutation resendCognitoInvitation($memberId: ObjectID!) {
    resendCognitoInvitation(memberId: $memberId)
  }
`;
export const DISCHARGE_MEMBER = gql`
  mutation dischargeMember(
    $memberId: ObjectID!
    $familyMembers: [ObjectID]
    $options: DischargeOptions
  ) {
    dischargeMember(memberId: $memberId, familyMembers: $familyMembers, options: $options)
  }
`;
export const CANCEL_DISCHARGE_MEMBER = gql`
  mutation cancelDischargeMember(
    $memberId: ObjectID!
  ) {
    cancelDischargeMember(memberId: $memberId)
  }
`;