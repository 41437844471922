import React from 'react';
import {Box} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

export const AccountFormSkeleton = ({width, height}) => (
  <Box style={{marginBottom: 100}}>
      <Skeleton animation="wave" variant='text' height={height} width={width}/>
      <Skeleton animation="wave" variant='text' height={height} width={width}/>
      <Skeleton animation="wave" variant='text' height={height} width={width}/>
      <Skeleton animation="wave" variant='text' height={height} width={width}/>
      <Skeleton animation="wave" variant='text' height={height} width={width}/>
  </Box>
);

export const AccountAvatarSkeleton = () => (
  <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 250}}>
      <Skeleton animation="wave" variant='circle' height={160} width={160}/>
      <Skeleton animation="wave" variant='text' height={50} width={150} />
      <Skeleton animation="wave" variant='text' height={30} width={150} />
  </Box>
);