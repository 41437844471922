import React from 'react';
import TodoItem from './Item';

const TodoList = ({children}) => {
  return <div>
    <TodoItem
      id={"todoA"}
      label={'Finalize Weekly Report'}
      date={'Jan 12'}
      isChecked
      urgent
    />
    <TodoItem
      id={"todoB"}
      label={'Follow up with Carl C.'}
      date={'Jan 13'}
    />
    <TodoItem
      id={"todoC"}
      label={'Schedule family calls'}
      date={'Jan 15'}
      urgent
    />
    <TodoItem
      id={"todoC"}
      label={'Prepare ELT report'}
      date={'Jan 16'}
    />
  </div>
};

export default TodoList;