import React from 'react'
import moment from 'moment'
import { useActivitiesContext } from 'context/Activities/useActivitiesContext'
import { useSnackbar } from 'notistack'

import BaseActivityForm from './BaseActivityForm'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { shapeAddressInputObj } from 'common/helpers/addressHelper'
import _ from 'lodash'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  participants: Yup.array(Yup.object()).required('Choose a patient'),
  date: Yup.date().required('Choose activity date').nullable(),
  duration: Yup.number().min(10, 'Too short').max(400, 'Too long').required('Enter duration of activity (in minutes)'),
  points: Yup.number()
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximim value is 100')
    .required('Fill this field')
    .nullable(),
  tags: Yup.array(Yup.string()).required('Choose a tags'),
})

const formDefaultValues = {
  type: undefined,
  title: undefined,
  participants: [],
  date: moment().format('yyyy-MM-DDTHH:mm'),
  duration: undefined,
  location: undefined,
  points: 50,
  tags: [],
  after: undefined,
  processType: [],
}

const EditActivity = ({ onClose }) => {
  const {
    templateDetails,
    patients,
    editActivity,
    setEditActivity,
    editActivityMutation,
    loading,
    setRecurrence,
    recurrence,
    processTypes: rawProcessTypes,
  } = useActivitiesContext()

  const processTypes = rawProcessTypes?.map(({ name, description }) => ({
    value: name,
    label: description,
  }))

  const { enqueueSnackbar } = useSnackbar()

  const onActivtySubmit = ({ data, editActivity, editActivityMutation, onClose, dirtyFields, recurrence }) => {
    console.log('[EDIT][EditActivity] submit', editActivity, data, dirtyFields)
    const changedData = Object.fromEntries(Object.keys(dirtyFields || {}).map((field) => [field, data[field]]))

    if (!dirtyFields) return

    // ScheduleInput
    let scheduling = {}

    if (changedData.date) {
      const changedDate = moment(changedData.date)
      scheduling.start = moment(changedDate)
        .set({
          year: changedDate.get('year'),
          month: changedDate.get('month'),
          day: changedDate.get('day'),
          hour: changedDate.get('hour'),
          minute: changedDate.get('minute'),
        })
        .toISOString()
    }

    if (
      scheduling.start &&
      editActivity.nextInSeriesStartTime &&
      (scheduling.start > editActivity.nextInSeriesStartTime ||
        moment(scheduling.start).isSame(editActivity.nextInSeriesStartTime))
    ) {
      return enqueueSnackbar('The date should be no later then the next appointment in sequence', { variant: 'error' })
    }

    scheduling = _.omitBy(
      {
        ...scheduling,
        duration: changedData.duration || null,
        recurrence: recurrence || null,
      },
      _.isNull
    )

    let location = {}
    if (dirtyFields.location) {
      location = changedData?.location ? { address: shapeAddressInputObj(data.location) } : { address: {} }
    }

    let participants = []
    if (dirtyFields.participants) {
      participants = changedData.participants.map((item) => item._id)
    }

    const variables = {
      activityId: editActivity.id,
      scheduling,
      activityInfo: _.omitBy(
        {
          ...changedData,
          ...location,
          ...(changedData?.processType === 'worksheet'
            ? { addWorksheetQuestions: changedData.addWorksheetQuestions }
            : {}),
          ...(changedData?.title ? { name: changedData.title } : {}),
          ...(changedData?.participants ? { invitedMembers: participants } : {}),
          title: null,
          location: null,
          date: null,
          participants: null,
        },
        _.isNull
      ),
    }

    console.log('variables', variables)
    editActivityMutation({ variables }).then(onClose)
  }
  const resolver = yupResolver(validationSchema)

  const handleCancel = () => {
    setEditActivity(null)
    onClose()
  }

  return (
    <BaseActivityForm
      loading={loading}
      onSubmit={(data, dirtyFields) => {
        return onActivtySubmit({ data, editActivity, editActivityMutation, onClose, dirtyFields, recurrence })
      }}
      onBack={handleCancel}
      submitButtonTitle="Save activity"
      backButtonTitle="Cancel"
      disabled={{ date: true, duration: true }}
      patients={patients}
      processTypes={processTypes}
      templateDetails={templateDetails}
      resolver={resolver}
      defaultValues={formDefaultValues}
      editActivity={editActivity}
      setRecurrence={setRecurrence}
    />
  )
}

export default EditActivity
