import React from 'react';
import { connect } from 'react-redux';
import DownloadApp from "../screens/Auth/DownloadApp";
import AuthError from "../screens/Auth/AuthError"
const ErrorHandler = ({ children, hasError, isNotStaff, hasDashboardData }) => {

    if (hasError) return <AuthError />;
    if (isNotStaff) return <DownloadApp />;
    if (!hasDashboardData) return <AuthError />;

    return children
}

export default connect(state => ({
    hasDashboardData: state?.dashboard?.dashboardData ? true : false,
    hasError: state?.dashboard?.hasError === true,
    isNotStaff: state?.dashboard?.isNotStaff === true
}))(ErrorHandler)