import React, {useState, useRef} from 'react';
import { ButtonPrimary } from 'common/components/Button/Button';
import Cropper from 'common/components/Cropper';
import MyDropzone from 'common/components/Dropzone';
import moment from 'moment';
import { any } from 'prop-types';
import Popup from 'common/components/Popup/Popup';

import styles from './index.module.scss';
import useAccountContext from 'context/Account/useAccountContext';

const AvatarDialog = ({open, handleClose, editStaffMutation}) => {
  
  const cropperRef = useRef();
  
  const {user, userId} = useAccountContext();
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarFile, setAvatarFile] = useState();

  const handleSetAvatar = result => {
    setAvatarPreview(result.content)
    setAvatarFile(result)
  }

  const crop = async (e) => {
    e.preventDefault();

    try {
      const canvas = cropperRef.current.getCroppedCanvas({
        fillColor: '#fff',
      });

      let ext = avatarFile.name.split('.');
      ext = ext[ext.length - 1];

      const imageContent = {
        content: canvas.toDataURL(),
        name: moment().format('X') + '.' + ext,
        type: avatarFile.type,
      };

      await editStaffMutation({
        variables: {
          staffId: userId,
          staff: { imageContent },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };


  return (
    <Popup title={'Upload Profile Photo'} onClose={handleClose} open={open} maxWidth={'sm'}>
      <div className="drop-zone">
        {!avatarPreview ? (
          <MyDropzone accept={'image/jpg,image/png'} minSize={1} maxSize={1} onChange={handleSetAvatar} />
        ) : (
          <div className="cropper-wrapper">
            <Cropper
              ref={cropperRef}
              src={avatarPreview}
              aspectRatio={1}
              zoomable={false}
              style={{ width: '400px', height: '400px' }}
            />
          </div>
        )}
      </div>
      <div className="action-buttons">
        <ButtonPrimary classes={styles['btn-close']} onClick={handleClose}>
          Cancel
        </ButtonPrimary>

        {avatarFile ? (
          <ButtonPrimary classes={styles['btn-save']} onClick={crop}>
            Save
          </ButtonPrimary>
        ) : null}
      </div>
    </Popup>
  )
}

AvatarDialog.propTypes = {
  editStaffMutation: any.isRequired,
};

export default AvatarDialog;
