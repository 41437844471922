import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { getWeekOfMonth } from 'date-fns';

// UI
import { makeStyles } from '@material-ui/core/styles';

//Components
import { Box, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Button from 'common/components/Forms/Button';
// import Radio from 'common/components/Forms/Radio';
import Select from 'common/components/Forms/Select/Select';
import Input from 'common/components/Forms/Input';
import Date from 'common/components/Forms/Date/Date';
import Toggle from 'common/components/Forms/Toggle/Toggle';

//Constants
import { DAYS_ENUM, INTERVALS_OPTIONS, WEEKS_OPTIONS, WEEKDAYS_OPTIONS } from 'common/constants/activityConstants';

const CustomRecurrenceSetter = ({ selectedTime, onClose, setRecurrence, onCancel }) => {
  const classes = useStyles();

  const date = moment(selectedTime);
  const selectedDay = WEEKDAYS_OPTIONS[date.day()].value;

  const maxDateCases = {
    daily: 1 / 12, // sets end date in 1 month
    weekly: 0.5, // sets end date in 6 month
    monthly: 1, // sets end date in 1 year
    yearly: 3, // sets end date in 3 years
  };

  const formDefaultValues = {
    intervalAmount: 1,
    repeatInterval: 'weekly',
    end: 'on',
    endDate: moment(selectedTime).add(maxDateCases['weekly'], 'years'),
    ocurrenceAmount: 1,
    weekDays: [selectedDay],
  };

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: formDefaultValues,
  });

  const repeatInterval = watch('repeatInterval');
  const maxDate = maxDateCases[repeatInterval];

  useEffect(() => {
    setValue('endDate', moment(selectedTime).add(maxDate, 'years'));
  }, [maxDate, selectedTime]);

  const index = getWeekOfMonth(moment(selectedTime).toDate());
  const weekCount = WEEKS_OPTIONS[index - 1].weekIndex;
  const dayOfWeek = DAYS_ENUM[moment(selectedTime).day()];

  const usePluralIntervals = watch('intervalAmount') > 1;
  const usePluralOccurrences = watch('ocurrenceAmount') > 1;
  const showWeekDaySelect = watch('repeatInterval') === 'weekly';
  const showMonthlySelect = watch('repeatInterval') === 'monthly';
  const enableDatePicker = watch('end') === 'on';
  const enableOcurrenceAmount = watch('end') === 'after';

  const dayOfMonth = moment(selectedTime).format('DD');
  const dayOfMonthFormatted = dayOfMonth > 9 ? dayOfMonth : dayOfMonth.substring(1);

  const MONTHLYON_OPTIONS = [
    { label: `Monthly on day ${dayOfMonthFormatted}`, value: 'onDay' },
    { label: `Monthly on the ${weekCount} ${dayOfWeek}`, value: 'onThe' },
  ];

  const handleSetCustomRecurrence = data => {
    console.log('data onSubmit Recurrence', data);
    // const isDaily = data.repeatInterval === 'daily';
    const isWeekly = data.repeatInterval === 'weekly';
    const isMonthly = data.repeatInterval === 'monthly';
    const isEndDate = data.end === 'on';
    const isEndAfter = data.end === 'after';
    const interval = parseInt(data.intervalAmount);
    const index = getWeekOfMonth(moment(selectedTime).toDate());

    const weekCount = WEEKS_OPTIONS[index - 1].weekIndex;
    const bysetpos = WEEKS_OPTIONS[index - 1].BYSETPOS;
    console.log('recurrence index, ', index, 'weekCount', weekCount, 'bysetpos', bysetpos, 'byweekday', data.weekDays);
    const recurrenceInputData = {
      frequency: data.repeatInterval,
      ...(isEndAfter ? { count: parseInt(data.ocurrenceAmount) } : {}),
      endDate: isEndDate ? data.endDate : null,
      interval,
      ...(isWeekly ? { byweekday: isWeekly ? data.weekDays : null } : {}),
      ...(isMonthly ? { ...(data.monthlyOn === 'onThe' ? { bysetpos } : {}) } : {}),
    };
    setRecurrence(recurrenceInputData);
    onClose({
      variables: {
        startDate: selectedTime,
        recurrence: recurrenceInputData,
      },
    });
  };

  return (
    <form className={classes.wrapper}>
      <SelectorRow>
        <div className={classes.title}>Starts</div>
        <div>{date.format(`dddd, MMM D, YYYY`)}</div>
      </SelectorRow>
      <SelectorRow>
        <div className={classes.title}>Repeat every</div>
        <Input
          type="number"
          name="intervalAmount"
          control={control}
          className={classes.amount}
          InputProps={{ inputProps: { min: 1, max: 100 } }}
        />
        <Select
          name="repeatInterval"
          control={control}
          items={INTERVALS_OPTIONS}
          className={classes.interval}
          usePlural={usePluralIntervals}
        />
      </SelectorRow>
      {showWeekDaySelect && (
        <div className={classes.weekSelectSection}>
          <div className={classes.title}>Repeat on</div>
          <Toggle name="weekDays" control={control} items={WEEKDAYS_OPTIONS} />
        </div>
      )}
      {showMonthlySelect && (
        <Select
          name="monthlyOn"
          className={classes.monthlySelectSection}
          control={control}
          items={MONTHLYON_OPTIONS}
          // className={classes.interval}
        />
      )}
      <div className={classes.endSelectSection}>
        <div className={classes.title}>Ends</div>
      </div>
      <Controller
        name="end"
        control={control}
        render={({ field: { onChange, value, ...inputProps }, fieldState: { invalid, error } }) => {
          return (
            <RadioGroup {...inputProps} onChange={onChange} value={value}>
              <div className={classes.radioOption}>
                <FormControlLabel value="on" control={<Radio color="primary" value="on" />} label="On" />
                <Date
                  control={control}
                  name="endDate"
                  disabled={enableDatePicker ? false : true}
                  format="DD MMM, YYYY"
                />
              </div>
              <div className={classes.radioOption}>
                <FormControlLabel value="after" control={<Radio color="primary" value="after" />} label="After" />
                <Input
                  type="number"
                  name="ocurrenceAmount"
                  control={control}
                  className={classes.amount}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  disabled={enableOcurrenceAmount ? false : true}
                />
                <div className={`${enableOcurrenceAmount ? '' : classes.disabledOccurence}`}>
                  {usePluralOccurrences ? 'occurrences' : 'occurrence'}
                </div>
              </div>
            </RadioGroup>
          );
        }}
      />

      <Box className={classes.buttonsBox}>
        <Button variant="contained" className={classes.cancelBtn} onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleSetCustomRecurrence)} variant="contained" color="primary">
          OK
        </Button>
      </Box>
    </form>
  );
};

// Styles
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 390,
    // width: '100%',
    // border: 'solid 1px lightgray',
    // borderRadius: 12,
    padding: 20,
  },
  header: {
    marginBottom: 20,
    fontWeight: 'bold',
  },
  title: {
    marginRight: 10,
    fontFamily: 'Avenir Next Demi Bold',
  },
  weekSelectSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  monthlySelectSection: {
    marginBottom: 20,
    height: 40,
    // width: 300,
  },
  endSelectSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  radioOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  amount: {
    width: 70,
    marginRight: 10,
  },
  interval: {
    width: 100,
    height: 40,
  },
  disabledOccurence: {
    color: 'gray',
  },
  buttonsBox: {
    alignSelf: 'flex-end',
  },
  cancelBtn: {
    backgroundColor: 'white',
    marginRight: '10px',
    boxShadow: 'none',
    color: 'gray',
  },
});

export default CustomRecurrenceSetter;

// To Extract
const SelectorRow = ({children, direction}) => {
  const styles = useSelectorStyles();
  return <div className={styles.wrapper}>{children}</div>
};

const useSelectorStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    '&> div': {
      fontFamily: 'Avenir Next Regular',
    }
  },
});
