/**
 * Sort members array by firstName
 * @param {array} data members data array
 * @param {string} key property to sort by 
 * @param {string} direction sort direction ASC or DSC
 * @returns sorted members array
 */
const sortMembers = (data = [], key = 'firstName', direction = 'ASC') => {
    const sortedData = [...data]?.sort((a, b) => a?.[key].localeCompare(b?.[key]))
    return sortedData
}

/**
 * Process Member Data by adding programName, emotionalState, locationName
 * Restrictions: For memberType: family, patient 
 * @param {array} data members data array
 * @param {array} locations orgnization locations array
 * @returns processed members array
 */
const processMembers = (data, locations) => {
    const dataProcessed = [...data]?.map(item => {
        const locationIndex = locations.findIndex(l => l._id === item.locationId);
        return {
            ...item,
            programName: item.program ? item.program.name : '--',
            emotionalState: item.emotionalState ? item.emotionalState : '--',
            locationName: locationIndex === -1 ? '--' : locations[locationIndex].name,
        }
    });
    return dataProcessed
};

export {
    sortMembers,
    processMembers,
}