const extractMemberProps = (member) => {
  return {
    _id: member?.memberId || 0,
    firstName: member?.firstName || ".",
    lastName: member?.lastName || ".",
    image: member?.image || null,
    type: 'family',
    relation: member?.relation?.name || '',
    releaseStatus: member?.releaseType?.name || 'None',
  }
}

const familyRelationTypes = [
  {_id: 'mother', name: 'Mother'},
  {_id: 'father', name: 'Father'},
  {_id: 'daughter', name: 'Daughter'},
  {_id: 'son', name: 'Son'},
  {_id: 'wife', name: 'Wife'},
  {_id: 'husband', name: 'Husband'},
  {_id: 'sister', name: 'Sister'},
  {_id: 'brother', name: 'Brother'},
  {_id: 'cousin', name: 'Cousin'},
  {_id: 'aunt', name: 'Aunt'},
  {_id: 'uncle', name: 'Uncle'},
  {_id: 'Niece', name: 'Niece'},
  {_id: 'nephew', name: 'Nephew'},
  {_id: 'stepSister', name: 'Step Sister'},
  {_id: 'stepBrother', name: 'Step Brother'},
  {_id: 'partner', name: 'Partner'},
  {_id: 'spouse', name: 'Spouse'},
  {_id: 'friend', name: 'Friend'},
  {_id: 'other', name: 'Other'},
]

const memberSampleData = [
  {_id: 12395, firstName: 'Josh', lastName: 'Cohen', type: 'family', image: null, releaseType: {name: 'Active'}, relation: {name: 'Father'} },
  {_id: 23423, firstName: 'Sarah', lastName: 'Cohen', type: 'family', image: null, releaseType: {name: 'None'}, relation: {name: 'Mother'}},
  {_id: 64372, firstName: 'Steve', lastName: 'Rinaldi', type: 'family', image: null, releaseType: {name: 'Active'}, relation: {name: 'Cousin'}},
  {_id: 75202, firstName: 'Paul', lastName: 'Roman', type: 'family', image: null, releaseType: {name: 'Resinded'}, relation: {name: 'Step Brother'}},
]

export {extractMemberProps, familyRelationTypes, memberSampleData}