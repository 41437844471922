import React, {Fragment} from 'react';
import PropTypes from 'prop-types'

// Modules
import TopModule from './TopModule/TopModule';
import HeaderContainer from './TopModule/HeaderContainer';
import PreloadHeader from './TopModule/PreloadHeader';

// TODO: Create validation helpers to dynamic components (check if component, if context, etc)
const MemberModule = ({
  data,
  loading,
  memberId,
  memberType,
  isEditing,
  moduleBody,
  moduleHeader,
  moduleContext,
  overviewHeaderProps,
}) => {

  // Dynamic Components
  const ModuleHeader = moduleHeader ? moduleHeader : Fragment;
  const ModuleBody = moduleBody ? moduleBody : Fragment;

  return (
    <ModuleWrapper context={moduleContext}>
      {!isEditing ? 
        <Fragment>
        {loading 
          ? <PreloadHeader memberId={memberId} memberType={memberType} /> 
          : (
            <HeaderContainer>
              <TopModule {...{ overviewHeaderProps }} {...data} />
              <ModuleHeader />
            </HeaderContainer>
          )
        }
        </Fragment>
      : null}
      <ModuleBody />
    </ModuleWrapper>
  )
}

export default MemberModule;

MemberModule.propTypes = {
  isEditing: PropTypes.bool,
  moduleHeader: PropTypes.elementType,
  moduleBody: PropTypes.elementType,
  moduleFilters: PropTypes.elementType,
  moduleContext: PropTypes.elementType,
}

const ModuleWrapper = ({context, children}, ...restProps) => {
  const ModuleContext = context ? context : Fragment;

  return(
    <ModuleContext>
      {children}
    </ModuleContext>
  )
};
