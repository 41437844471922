import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

import { Messages } from 'config/settings';

import { GET_PATIENTS } from 'data/graphql/patients';
import AddIcon from 'common/assets/add.svg';

import MemberList from 'components/Member/List/List';
import useAccountContext from 'context/Account/useAccountContext';
import useMembersContext from 'context/Members/useMembersContext';
import { processMembers, sortMembers } from 'common/helpers/memberHelpers';

const PatientListScreen = () => {
  
  const { 
    activePatients: data,
    loadingPatients: dataLoading, 
    errorPatients: dataError,
  } = useMembersContext();

  const {locations} = useAccountContext()
  const [processedData, setProcessedData] = useState([]);
  
  const navigate = useNavigate()
  const onAddNewPatient = () => navigate('/patientInsert');

  useEffect(() => {
    if(data?.length && locations?.length) {
      const sortedData = sortMembers(data, 'firstName')
      const processed = processMembers(sortedData, locations)
      setProcessedData(processed)
    }
  }, [data, locations])

  const filterQuery = {
    reqQuery: GET_PATIENTS,
    variables: { filters: {} },
    variablesPath: 'filters',
    queryName: 'patients',
  };

  const filterFields = {
    location: {
      label: 'Locations',
      initialValue: [],
      placeholder: 'Choose location',
      fieldPath: 'locationName',
      fieldValPath: '',
    },
    program: {
      label: 'Program',
      initialValue: [],
      placeholder: 'Choose a program',
      fieldPath: 'programName',
      fieldValPath: '',
    },
    status: {
      label: 'Status',
      initialValue: [],
      placeholder: 'Choose a status',
      fieldPath: 'status',
      fieldValPath: '',
    },
  };

  const headerProps = {
    listBreadcrumbs: [
      {
        name: 'Patients',
        url: '/patients',
      },
    ],
    listButtons: [
      {
        title: 'Add New Patient',
        icon: AddIcon,
        onClick: onAddNewPatient,
        permission: {
          module: 'PATIENTS',
          perform: 'ADD_PATIENT',
        },
      },
    ],
  };
  const filterToggles = [
    {
      // active: true,
      filterCondition: false,
      title: 'Pending Activation',
      filterPath: 'activatedAccount',
    },
    {
      // active: true,
      filterCondition: 'FLAGGED_FOR_DISCHARGE',
      title: 'Flagged for discharge',
      filterPath: 'status',
    },
  ];

  const filtersProps = {
    data: processedData,
    hasSearch: true,
    fields: filterFields,
    filterQuery: filterQuery,
    filterToggles: filterToggles,
    searchLocalBy: ['firstName', 'lastName', 'mrn', 'phone', 'email'],
    initialFilterCondition: {
      filterCondition: 'FLAGGED_FOR_DISCHARGE',
      condition: 'ne',
      filterPath: 'status',
    },
  };

  const memberListProps = {
    data: processedData,
    dataLoading,
    dataError,

    headerProps,
    filtersProps,
    baseUrl: '/patients',

    emptyData: {
      title: Messages.PATIENT_LIST_EMPTY_TITLE,
      text: Messages.PATIENT_LIST_EMPTY_TEXT,
    },

    emptySearch: {
      title: Messages.PATIENT_LIST_SEARCH_EMPTY_TITLE,
      text: Messages.PATIENT_LIST_SEARCH_EMPTY_TEXT,
    },
  };

  return <MemberList {...memberListProps} />;
};

export default PatientListScreen;
