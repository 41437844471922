
import { EN } from './lang_EN';
import { ES } from './lang_ES';

// Calls
export const mySessionTypes = ['therapySession', 'familySession'];

// Clinician Dashboard
export const agendaRange = 'day';

// Laguage Controller
export const INRECOVERY_LANGUAGE = 'EN';
export let Messages = {};

switch (INRECOVERY_LANGUAGE) {
  case 'EN':
    Messages = { ...EN }
    break;
  case 'ES':
    Messages = { ...ES }
    break;
  default:
    Messages = { ...EN }
}  