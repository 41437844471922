import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// UI
import { FormControlLabel, Switch } from '@material-ui/core';
import styles from './ShortSchedule.module.scss';

// HOC
import WithEvents from 'hoc/WithEvents/WithEvents';

// Components
import CallList from './CallList/CallList';
import CallListSkeleton from './CallList/Skeleton/Skeleton';

// Util
import { Messages } from 'config/settings';
import { sortByDate } from 'common/helpers/dataHelpers';
import * as activityHelper from 'components/Scheduler/utils/ActivitySchedulerHelper';

const ShortSchedule = ({ 
  range, 
  events = [], 
  externalEvents = [], 
  eventsLoading, 
  loadingGoogle, 
  loadingOutlook 
}) => {
  const loadingAgenda = eventsLoading || loadingOutlook || loadingGoogle;
  console.log('[Agenda] events: ', events, 'eventsLoading: ', eventsLoading, 'loadingAgenda', loadingAgenda)
  
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState(null);
  const [showFull, setShowFull] = useState(true);
  
  useEffect(() => {
    const allEventsMerge = activityHelper.filterOutDuplicateEvents([...events, ...externalEvents]).sort(sortByDate('scheduling.start'))
    if (!loadingAgenda) { 
      setIsLoading(false);
      setEventsList(showFull ? allEventsMerge : events);
    }
  }, [loadingAgenda,  showFull, events, externalEvents])

  const onToggle = ({ target: { checked } }) => {
    setShowFull(checked);
  } 

  const rangeDayText = dayjs().format('dddd MMM. D');
  const rangeWeekText = `Week of ${dayjs().startOf('week').format('MMM. D')}`;

  const activitiesListProps = {
    data: eventsList,
    isLoading,
    range,
  }

  return (
    <div className={styles.shedule}>

      {/* <div className={styles.header}>
        <h4>My Agenda</h4> 
        <span className={styles.headerDate}>{range === 'week' ? rangeWeekText : rangeDayText}</span>
        <FormControlLabel
          label={Messages.CALLDASH_SCHEDULE_TOGGLE_FULL}
          labelPlacement="start"
          style={{ flex: 1 }}
          control={<Switch color="primary" checked={showFull} onChange={onToggle} />} />
      </div> */}

      <ActivitiesList {...activitiesListProps} />

    </div>
  );
}

const ActivitiesList = ({data, isLoading, range}) => {
  const hasEvents = data?.length;
  const rangeWeekText = `Week of ${dayjs().startOf('week').format('MMM. D')}`;

  return (
    isLoading 
      ? <CallListSkeleton items={5} />
      : hasEvents
        ? <CallList range={range} calls={data} />
        : <div className={styles.nocalls}>{Messages.CALLDASH_SCHEDULE_EMPTY}</div>
  );
};

export default WithEvents()(ShortSchedule);
