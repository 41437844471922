import React, { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const FormikAutocomplete = ({
  suggestions = [],
  label,
  field,
  form: {
    setFieldValue,
    setFieldTouched,
    touched,
    errors
  },
  placeholder,
  valueKey,
  labelKey,
  ...props
}) => {

  const fieldErrors = errors[field?.name];
  const hasErrors = !!fieldErrors && touched[field?.name];

  const handleOnChange = useCallback(
    (_, value) => {
      setFieldValue(field.name, value)
    }, [field.name, setFieldValue]);

  const handleOnBlur = useCallback(
    () => {
      setFieldTouched(field.name, true)
    }, [field.name, setFieldTouched]);
    
  //Tests
  // const testComponent = labelKey ? suggestions?.map(labelKey) : suggestions;
  // const invalidLabKey = testComponent.includes("undefined");
  // console.log(
  //   '[Autocomplete Test] labelKey test: ', testComponent, 
  //   '\n labelKey: ', labelKey, 
  //   '\n label: ', label,
  //   '\n labelKey undefined? ', invalidLabKey, 
  //   '\n field: ', field,
  // );
    
  const textFieldProps = {
    label,
    fullWidth: true,
    variant: 'outlined',
    //required: true,
  }

  return (
    <Autocomplete
      autoHighlight
      options={suggestions}
      value={field.value}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      getOptionLabel={labelKey}
      renderInput={params => {
        return <TextField
          {...params}
          {...textFieldProps}
          error={hasErrors}
          helperText={hasErrors ? fieldErrors : props.helperText}
          inputProps={{...params.inputProps, autoComplete: "new-password"}}
        />
      }}
    />
  )
};

export default FormikAutocomplete;