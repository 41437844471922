import React from 'react';
import PropTypes from 'prop-types';
import momentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useController } from 'react-hook-form';

const Date = ({
  control,
  name,
  defaultValue = '',
  required = false,
  className,
  disabled,
  format,
  maxDate,
  ...props
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        size="small"
        format={format}
        InputAdornmentProps={{ position: 'start' }}
        {...inputProps}
        inputRef={ref}
        error={invalid}
        helperText={error?.message}
        className={className}
        disabled={disabled}
        maxDate={
          maxDate
            ? maxDate
            : moment()
                .add(5, 'years')
                .format('YYYY-MM-DD')
        }
        data-testid="date-testid"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

Date.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};

export default Date;
