import { handleActions } from 'redux-actions';
import * as types from 'actions/types';
import * as activityHelper from 'components/Scheduler/utils/ActivitySchedulerHelper';

const colors = ['#ebebeb', '#A5D7E9', '#e6ffe6', '#ffffe6'];
const initialState = {
  currentDate: new Date(),
  selectedTime: '',
  days: [],
  loading: false,
  activitiesLoading: false,
  sending: false,
  selectedMember: null,
  duration: 30,
  selectedActivity: null,
  data: [],
  selectedStaff: [],
  tooltipVisible: false,
  confirmationVisible: false,
  editingFormVisible: false,
  recurrence: false,
  currentViewName: 'Week',
  relatedInvitedMembers: [],
  allData: [],
  host: null,
  startDayHour: 7,
  endDayHour: 19
};

export default handleActions(
  {
    [types.CLEAR_STATE]: (state, { payload }) => ({ ...initialState }),
    [types.SET_MULTI_SCHEDULER_ACTIVITIES]: (state, { payload }) => {
      //add new data to existing data
      return {
        ...state,
        data: activityHelper.filterOutDuplicateEvents([...state.data, ...payload.allData]),
      };
    },
    [types.RESET_MULTI_SCHEDULER_ACTIVITIES]: state => {
      return { ...state, data: [] };
    },
    [types.SET_SENDING]: (state, { payload }) => ({
      ...state,
      sending: payload,
    }),
    [types.SET_CALL_DURATION]: (state, { payload }) => ({
      ...state,
      duration: payload,
    }),
    [types.SELECT_MEMBER]: (state, { payload }) => ({
      ...state,
      selectedMember: payload,
    }),
    // [types.ADD_STAFF]: (state, { payload }) => {
    //   const { staff } = payload;
    //   if (
    //     state.selectedStaff.find(a => a._id == staff) ||
    //     state.selectedStaff.length === 4
    //   )
    //     return { ...state };
    //   let selectedStaff = isArray(state.selectedStaff)
    //     ? [
    //         ...state.selectedStaff,
    //         { _id: staff, color: colors[state.selectedStaff.length] },
    //       ]
    //     : [staff];
    //   return { ...state, selectedStaff };
    // },
    // [types.REMOVE_STAFF]: (state, { payload }) => {
    //   const { staff } = payload;
    //   if (!state.selectedStaff.find(a => a._id == staff)) return { ...state };
    //   return {
    //     ...state,
    //     selectedStaff: state.selectedStaff.filter(s => s._id != staff),
    //   };
    // },
    [types.SET_STAFF]: (state, { payload }) => {
      return {
        ...state,
        selectedStaff: payload,
      };
    },
    [types.SET_HOST]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [types.SET_TEMPLATE_CODE]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [types.SET_RESCHEDULED_ACTIVITY]: (state, { payload }) => ({
      ...state,
      selectedActivity: payload,
    }),
    [types.SET_INVITED_MEMBERS]: (state, { payload }) => {
      return { ...state, relatedInvitedMembers: payload };
    },
    [types.SET_BOOKER_VIEW_NAME]: (state, { payload }) => ({
      ...state,
      currentViewName: payload,
    }),
    [types.SET_DATE]: (state, { payload }) => ({ ...state, ...payload }),
    [types.LOAD_DATA_FROM_SERVER]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_RECURRENCE]: (state, { payload }) => ({ ...state, ...payload }),
    [types.CHANGE_TO_NEXT_WEEK]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.CHANGE_TO_PREV_WEEK]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_SELECTED_TIME]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_DAYS]: (state, { payload }) => ({ ...state, ...payload }),
    [types.SET_LOADING]: (state, { payload }) => ({ ...state, ...payload }),
    [types.SET_ACTIVITIES_LOADING]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.RESCHEDULE]: (state, { payload }) => ({ ...state, ...payload }),
    [types.CREATE_NEW_SESSION]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.TOGGLE_MSCH_VISIBILITY]: (state, { payload }) => ({
      ...state,
      [payload]: !state[payload],
    }),
  },
  initialState
);
