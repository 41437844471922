import React from "react";
import { Box, Grid, Button } from "@material-ui/core";

import styles from "./index.module.scss";
import ReactSVG from "react-svg";
import imgLogo from "common/assets/light-logo.svg";
import Footer from "common/components/Footer/Footer";

import {useAuthNContext} from 'context/Auth/useAuthContext';

const DownloadApp = () => {

  const {handleUserSignOut} = useAuthNContext();
  const handleLogout = () => {
    handleUserSignOut();
  };

  return <Grid container component="main">
    <Grid container className={styles.container}>
      <Grid item xs={12}>
        <ReactSVG src={imgLogo} className={styles.logo}/>
      </Grid>

      <Grid item xs={12} className={styles.wrapper}>
        <Grid item xs={11} sm={10} md={6}>

          <Box>
            <Box align="center" className={styles.greet}>
              <Box mb={4} fontSize={40}>Congratulations!</Box>
            </Box>
          </Box>

          <Box p={8} className={styles.box}>
            <Box textAlign="center">
              <Box mb={4} fontSize={24}>Your permanent password has been created.</Box>
              <Box mb={4} fontSize={24}>You can use it to log into the mobile app.</Box>

              <Button
                className={styles.btn}
                variant="outlined"
                color="primary"
                onClick={handleLogout}>
                Close
              </Button>

              <Button
                href="https://inrecovery.atlassian.net/servicedesk/customer/portal/6/topic/0147de6e-d996-49cd-a68b-f633f41be341/article/679248137"
                variant="contained"
                color="primary">
                How to get the app?
              </Button>
            </Box>
          </Box>

        </Grid>
      </Grid>

      <Footer/>
    </Grid>
  </Grid>;
};

export default DownloadApp;