import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';

// Components
import FamilyModule from './View/View';

// Context
import MemberContextProvider from 'context/Member/MemberContext';

const FamilyMember = () => {

  // Route Data
  const { id: memberId } = useParams();
  const location = useLocation();

  console.log('FamilyModule location', location?.state)

  // Setup Context
  const [contextValues, setContextValues] = useState();

  useEffect(() =>{
    setContextValues({
      memberId,
      memberType: 'family'
    })
  }, [memberId])

  return(
    <MemberContextProvider value={contextValues}>
      <FamilyModule 
        memberId={memberId}
        status={location?.state?.status}
        activatedAccount={location?.state?.activatedAccount} 
        baseUrl="/family"
      />
    </MemberContextProvider>
  )
}

export default FamilyMember;