import React, {useEffect} from 'react';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Box } from '@material-ui/core';
import { useMutation, useLazyQuery } from '@apollo/client';

import styles from '../../StaffItemEdit.module.scss';
import FormSkeleton from 'common/components/Formik/FormSkeleton/FormSkeleton';
import { trimObjectStrings } from 'common/helpers/validations';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';

import { EDIT_STAFF, GET_FULL_STAFF_INFO } from 'data/graphql';
import {GET_ROLES} from 'data/graphql/members/staff'
import useMembersContext from 'context/Members/useMembersContext';
import useAccountContext from 'context/Account/useAccountContext';
import { useAuthZContext } from 'context/Auth/useAuthContext';

const Caron = ({user, refetch, avatarFile}) => {

  const {staff} = useMembersContext()
  
  const { 
    loadingAccount,
    organizationName, 
    locations = [], 
    departments = [],
  } = useAccountContext()
  
  const { getPermissionsData } = useAuthZContext()

  const { enqueueSnackbar } = useSnackbar();
  
  // Data
  const [getRoles, {data: dataRoles, loading: loadingRoles, error: errorRoles, called: calledRoles}] = useLazyQuery(GET_ROLES);
  
  const [editStaff, { loading: editLoading }] = useMutation(EDIT_STAFF, {
    refetchQueries: [{ query: GET_FULL_STAFF_INFO, variables: { memberId: user._id } }],
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      refetch()
      getPermissionsData()
      enqueueSnackbar('Account updated', { variant: 'success' }); 
    }
  });

  useEffect(() => {
    if (!calledRoles) getRoles()
  }, [])

  const loading = !user || loadingRoles || loadingAccount;
  const staffListOptions = staff && staff?.map(s => `${s.firstName} ${s.lastName}`);
  const location = (user?.locationId && locations) ? locations?.find(l => l._id === user.locationId) : '';

  const userRoles = (user?.roles_new && dataRoles && !errorRoles)
    ? user.roles_new?.map(id => {
        const role = dataRoles?.getRoles.find(a => a._id === id);
        return role ? role.name : null;
      })
      .filter(r => r !== null)
    : [];

  const getStaffNameById = id => {
    const findResult = staff.find(s => s._id === id);
    return findResult ? `${findResult.firstName} ${findResult.lastName}` : '';
  }

  const getStaffIdByName = name => {
    if (name) {
      const [fName, lName] = name.split(' ');
      const findResult = staff.find(s => s.firstName === fName && s.lastName === lName);
      return findResult ? findResult._id : null;
    }

    return null;
  }

  const fieldsGrid = [
    [{
      type: 'autocomplete',
      multiple: true,
      name: 'roles_new',
      title: 'Role',
      value: userRoles,
      options: dataRoles ? dataRoles?.getRoles?.map(r => r.name) : [],
      grid: { xs: 12 }
    }],

    [{
      name: 'staffNo',
      readOnly: true,
      title: 'Staff Number',
      value: user?.staffNo || '',
      grid: { xs: 12 }
    }],

    [{
      type: 'checkbox',
      name: 'isCareProvider',
      title: ' Care Provider',
      value: user?.type === 'careProvider',
      grid: { xs: 12 }
    }],

    [{
      name: 'jobTitle',
      title: 'Job Title',
      value: user?.jobTitle || '',
      grid: { xs: 6 }
    }, {
      type: 'select',
      name: 'departmentByName',
      title: 'Department',
      value: user?.department?.name || '',
      options: departments ? departments?.map(d => d.name) : [],
      grid: { xs: 6 }
    }],

    [{
      type: 'select',
      name: 'locationByName',
      title: 'Location',
      value: location?.name || '',
      options: locations?.map(l => l.name),
      grid: { xs: 12 }
    }],

    [{
      type: 'autocomplete',
      name: 'supervisor',
      title: 'Supervisor',
      value: getStaffNameById(user?.supervisor),
      options: staffListOptions || [],
      grid: { xs: 12 }
    }],

    [{
      type: 'date',
      name: 'admitDate',
      title: 'Hire Date',
      readOnly: true,
      value: user?.admitDate || new Date(),
      grid: { xs: 6 }
    },
      // {
      //   type: 'date',
      //   name: 'dischargeDate',
      //   title: 'Termination Date',
      //   value: user?.dischargeDate || new Date(),
      //   grid: { xs: 6 }
      // }
    ],

  ];

  const onFormSubmit = values => {
    let updatedStaff = { ...values };
    delete updatedStaff.staffNo;
    // delete updatedStaff.hireDate;
    // delete updatedStaff.dischargeDate;
    updatedStaff = trimObjectStrings(updatedStaff);
    updatedStaff.supervisor = getStaffIdByName(updatedStaff.supervisor);
    updatedStaff.roles_new = updatedStaff.roles_new?.map(name => {
      return dataRoles?.getRoles.find(a => a.name === name)._id;
    });

    if (avatarFile) {
      updatedStaff.imageContent = avatarFile;
    }

    editStaff({
      variables: {
        staffId: user?._id,
        staff: updatedStaff,
      }
    });
  }

  const formikProps = {
    onSubmit: onFormSubmit,
    initialValues: toInitialValues(fieldsGrid),
  }

  return <React.Fragment>

    <p className={styles.formTitle}>{organizationName} Setup</p>

    {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

    {loading ? <FormSkeleton /> : (

      <Formik {...formikProps}>
        {({ values, handleChange, setFieldValue, errors }) => <Form>

          <FormBuilder
            values={values}
            errors={errors}
            data={fieldsGrid}
            handleChange={handleChange}
            setFieldValue={setFieldValue} />

          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={editLoading}
              children={editLoading ? 'Loading...' : 'Update'} />
          </Box>

          {/* <pre>values: {JSON.stringify(values, null, 2)}</pre>
          <pre>errors: {JSON.stringify(errors, null, 2)}</pre> */}

        </Form>}
      </Formik>

    )}

  </React.Fragment>
}

export default Caron;
