import React from "react";

// Styles
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// UI 
import {ReactComponent as Logo} from '../../assets/logo-light.svg';

// Components
import Header from "../Header/Header.js";
import Navigation from "common/navigation/SideNav";

// GraphQL
import { useQuery, useReactiveVar } from "@apollo/client";
import {isLoggedInVar} from 'data/apollo/cache';
import { GET_SESSION_DATA } from "data/graphql/account/session";


import {hasValidAuthSession} from 'context/Auth/AuthNContext';
import { NavLink } from "react-router-dom";
import routes from 'common/routes';

const Layout = ({children}) => {

  // const isLoggedIn = useReactiveVar(isLoggedInVar);
  const {data, loading, error} = useQuery(GET_SESSION_DATA)
  console.log('SessionData query', data, loading, error, hasValidAuthSession())

  const styles = useStyles();
  return (
    <Grid container component="main">
      <section className={styles.content}>
        <Grid item>
          <div className={styles.spacesList}>
            <div className={styles.spacesIcon} style={{backgroundColor: '#fff'}}>C</div>
          </div>
        </Grid>
        <Grid item>
          <div style={{height: '100vh', paddingTop: 20, paddingRight: 20}}>
            <div style={{paddingLeft: 20}}>
              <NavLink to={routes.dashboard}>
                <Logo className={styles.logo} />
              </NavLink>
            </div>
            <Navigation />
          </div>
        </Grid>
        <Grid item className={styles.main}>
          <Header />
          <Canvas type="dashboard">
            {children}
          </Canvas>
        </Grid>
      </section>
    </Grid>
  )
};

const Canvas = ({children}) => {
  const styles = useStyles();
  return (
    <div className={styles.dashboard}>
      {children}   
    </div>
  )
}

export default Layout;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  logo: {
    width: 175,
    height: 'auto',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 25px 25px 25px',
  },
  dashboard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacesList: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0D2137',
    height: '100vh',
    width: 50,
    paddingTop: 10
  },
  spacesIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 35,
    width: 35,
  },
})