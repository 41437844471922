
import React, { Fragment } from 'react';
import cn from 'classnames';

// Material
import {Grid, makeStyles} from '@material-ui/core';
import {DragHandleRounded} from '@material-ui/icons';

// UI Components
import StatisticWidget from '../elements/StatisticWidget';

// Components
import WelcomeWidget from '../elements/WelcomeWidget';
import ShortSchedule from 'common/components/Schedule/ShortSchedule/ShortSchedule';
import TodoList from 'components/ToDo/List';

const DirectorDashboard = () => {
  const styles = useStyles()
  const dateSelect = new Date();

  return (
    <Fragment>
    
    <Grid container>
      <Grid xs={12}>
        <WelcomeWidget />
      </Grid>
    </Grid>

    <div className={styles.wrapper}>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className={styles.topArea}>
            <StatisticWidget number={130} label="Active Clients" change="3 New" trending="up" />
            <StatisticWidget number={'$125K'} label="Monthly Revenue" change="5%" trending="down" />
            <StatisticWidget number={8} label="Relapses" change="2" trending="up" />
            <StatisticWidget number={7.5} label="NPS Score" change="0.5" trending="up" />
          </div>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <div className={cn(styles.sectionNode, styles.firstNode)}>
            <div className={styles.roundedWhite} style={{height: 450}}>
              <div className={styles.sectionLabel}>
                <h4>My Agenda</h4>
              </div>
              <ShortSchedule dateSelect={dateSelect} />
            </div>
          </div>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <div className={cn(styles.sectionNode)}>
            <div className={styles.roundedWhite} style={{height: 450}}>
              <div className={styles.sectionLabel}>
                <h4>My Tasks</h4>
                <DragHandleRounded color="disabled" />
              </div>
              <TodoList />
            </div>
          </div>
        </Grid>
      
      </Grid>
    </div>

  </Fragment>
  )

};

export default DirectorDashboard;

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: '#F5F6F8',
    borderRadius: 8,
  },
  firstNode: {
    paddingLeft: 15,
  },
  sectionNode: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  topArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 10,
    minHeight: 130,
    backgroundColor: '#fff',
    borderBottom: '1px solid #E0E0E0',
    borderRadius: '8px 8px 0 0',
  },
  roundedWhite: {
    backgroundColor: '#fff',
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    padding: 10,
  },
  sectionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 10,
    fontWeight: 'bold',
  },
});