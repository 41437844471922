import React, {useEffect} from 'react';
import queryString from 'query-string';

// Data
import { useMutation } from '@apollo/client';
import { SAVE_OUTLOOK_TOKEN } from '../../../data/graphql/outlook';
import { Messages } from 'config/settings';

// Components
import EmptyState from 'common/components/EmptyState/EmptyState';

// Hooks
import { useNavigate } from 'react-router-dom-v5-compat';

const OutlookAuth = ({location}) => {
  
  // Data
  const navigate = useNavigate();
  const { code } = queryString.parse(location.search);  

  // Mutation
  const [saveToken] = useMutation(SAVE_OUTLOOK_TOKEN, {
    variables: { code },
    onCompleted: () => {
      navigate('/account/externalCalendars');
    },
  });

  // Side Effects
  useEffect(() => {
    if (code) saveToken();
  }, []);

  const loadingStateData = {
    title: Messages?.OUTLOOK_LOADING_TITLE,
    text: Messages?.OUTLOOK_LOADING_TEXT,
    showLoading: true,
  };

  return <EmptyState {...loadingStateData} />

}

export default OutlookAuth;
