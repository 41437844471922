import { createSelector } from 'reselect';
import _ from 'lodash';
import { check as checkPermission } from 'hoc/Can-old';

const getUser = state => state.dashboard.dashboardData.user;
const getStaff = state => state.dashboard.dashboardData.staff;
const getPatients = state => state.dashboard.dashboardData.patients;
const getDischargedPatients = state => state.dashboard.dashboardData.dischargedPatients;
const getDischargedFamily = state => state.dashboard.dashboardData.dischargedFamilyList;
const getCurrentPatientId = state => state.callflow.currentPatientId;
const getCareProviders = state => state.dashboard.dashboardData.careProviders;
const getPermissions = state => state.dashboard.dashboardData.user.permissions;
const getLocations = state => state.dashboard.dashboardData.organization.locations;
const getFamilyList = state => state.dashboard.dashboardData.familyList;

export const getUserById = createSelector(
  getUser,
  getStaff,
  getPatients,
  getCareProviders,
  getFamilyList,
  (_, id) => id,
  (user, staff, patients, careProviders, familyList, id) => {
    const u = [user, ...staff, ...patients, ...careProviders, ...familyList].find(item => item._id === id);
    if (!u) return {};
    const title = u.title ? { title: u.title } : {};
    return {
      ...title,
      ...u,
    };
  }
);

export const getActivePatient = createSelector(getPatients, getCurrentPatientId, (patients = [], currentPatientId) =>
  patients.find(patient => patient._id === currentPatientId)
);

export const getStaffList = createSelector(
  getStaff,
  getCareProviders,
  getPermissions,
  (staff = [], careProviders = [], permissions) => {
    const data = _.uniqBy(
      [...staff.map(i => ({ ...i, type: 'staff' })), ...careProviders.map(i => ({ ...i, type: 'careProvider' }))],
      '_id'
    ).sort((a, b) => a.firstName.localeCompare(b.firstName));

    const withCanEdit = data?.map(item => ({
      ...item,
      canEdit: true, //checkPermission(permissions, 'STAFF', 'EDIT_STAFF', null, item._id)
    }));

    return {
      data: withCanEdit,
      isEmpty: !data.length,
    };
  }
);

export const getPatientsList = createSelector(
  getPatients,
  getPermissions,
  getLocations,
  (patients = [], permissions, locations = []) => {
    // Filter patients cant be viewed
    let patientsList = patients.filter(item => {
      return checkPermission(permissions, null, 'VIEW_PATIENT', null, item._id);
    });

    // Sort alphbetically
    patientsList.sort((a, b) => a.firstName.localeCompare(b.firstName));

    // Map: locationName, programName, emotionalState to patientsList
    patientsList = patientsList.map(item => {
      const locationIndex = locations.findIndex(l => l._id === item.locationId);
      return {
        ...item,
        programName: item.program ? item.program.name : '--',
        emotionalState: item.emotionalState ? item.emotionalState : '--',
        locationName: locationIndex === -1 ? '--' : locations[locationIndex].name,
      };
    });

    return patientsList;
  }
);

export const getMonitoringPatientsList = createSelector(getPatients, (_data = []) => {
  const data = _data
    .filter(i => i.firstName !== null)
    .filter(i => i.subType?.code === 'MONITORING_ONLY')
    .sort((a, b) => a.firstName.localeCompare(b.firstName));
  return { data, isEmpty: !data.length };
});

// Discharged Members
export const getDischargedPatientsList = createSelector(
  getDischargedPatients,
  getPermissions,
  getLocations,
  (patients = [], permissions) => {
    // Filter patients cant be viewed
    let patientsList = patients.filter(item => {
      return checkPermission(permissions, null, 'VIEW_PATIENT', null, item._id);
    });
    // Sort alphbetically
    patientsList.sort((a, b) => a.firstName.localeCompare(b.firstName));
    return patientsList;
  }
);

export const getCarePatients = createSelector(getUser, getPatients, (user, patients) =>
  user.careTeam.map(carePatient => patients.find(patient => patient._id === carePatient._id))
);

export const getFamily = createSelector(getFamilyList, (_data = []) => {
  const data = _data.filter(i => i.firstName !== null).sort((a, b) => a.firstName.localeCompare(b.firstName));
  return { data, isEmpty: !data.length };
});

export const getDischargedFamilyList = createSelector(getDischargedFamily, (_data = []) => {
  const data = _data.filter(i => i.firstName !== null).sort((a, b) => a.firstName.localeCompare(b.firstName));

  return { data, isEmpty: !data.length };
});

export const getClinicians = createSelector(getStaff, getCareProviders, (staff, providers) =>
  [...staff, ...providers]
    .map(({ _id, firstName, lastName, image }) => ({
      _id,
      image,
      fullName: `${firstName} ${lastName}`,
    }))
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
);

export const checkIfUserHasPermissionToAction = createSelector(
  getPermissions,
  (_, action) => action,
  (permissions = [], action) => {
    return checkPermission(
      permissions,
      action.module ? action.module : null,
      action.action,
      null,
      action.isPatient ? action.user : null,
      action.isStaff ? action.user : null
    );
  }
);

export const getUsersByIds = createSelector(
  getUser,
  getStaff,
  getPatients,
  getCareProviders,
  getFamilyList,
  (_, ids) => ids,
  (user, staff, patients, careProviders, familyList, ids) => {
    const u = [user, ...staff, ...patients, ...careProviders, ...familyList];
    return ids.map(id => u.find(item => item._id === id));
  }
);
