import React from "react";
import Chip from "../../Chip/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "../TextFieldAutocomplete/styles.module.scss";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


export default function TextChip({
  form: { 
    setFieldValue, 
    setFieldTouched, 
    touched, 
    errors 
  },
  suggestions = [],
  field,
  width,
  height,
  label,
  labelStyles,
  ...props
}) {
  const hasError = errors[field.name] && touched[field.name];
  return (
    <Box className={styles.container}>

      <Autocomplete
        {...props}
        multiple
        freeSolo
        includeInputInList
        options={suggestions}
        //filterOptions={o => o}
        classes={{ inputRoot: styles.inputRoot, root: styles.autocompleteRoot }}
        onChange={(_, selectedOptions) => {
          setFieldValue(field.name, selectedOptions?.map(i => i));
          setFieldTouched(field.name, true);
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            classes={{ root: styles.root }}
            style={{
              width,
              height,
            }}
            variant="outlined"
          />
        )}
        // renderTags={(chip) =>
        //   chip.map((item, index) => (
        //     <Chip
        //       key={index}
        //       data-tag-index={`data-tag-${index}`}
        //       tabIndex={index}
        //       label={item}
        //     />
        //   ))
        // }
      />
      {hasError ? <Typography className={styles.error}>{errors[field.name]}</Typography> : null}
    </Box>
  );
}
