import React from 'react';
import { FormHelperText, Grid } from '@material-ui/core';

import FormBuilderInput from './FormBuilderInput/FormBuilderInput';

export const FormBuilder = ({ data, handleChange, setFieldValue, values, errors, setErrors }) => (
  <Grid container spacing={2}>
    {data.map((row, i) => (
      <Grid item xs={12} key={i}>
        <Grid container key={i} spacing={(2)}>
          {row.map((col, i) => (
            <Grid item {...col.grid} key={i}>
              <FormBuilderInput {...col} {...{ values, handleChange, setFieldValue, errors, setErrors }} />
              {errors && errors[col.name] && <FormHelperText error children={errors[col.name]} />}
            </Grid>
          ))}
        </Grid>
      </Grid>
    ))}
  </Grid>
)

export const toInitialValues = data => data.flat().reduce((obj, { name, value }) => {
  obj[name] = value;
  return obj;
}, {});