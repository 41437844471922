import React from 'react';

import Popup from 'common/components/Popup/Popup';
import {useActivitiesContext} from 'context/Activities/useActivitiesContext';
import EditAppointmentComponent from './EditAppointmentComponent';
import AvatarTitle from 'common/components/Member/AvatarTitle';

const EditAppointmentPopup = ({ handleUpdated }) => {
  const {
    editAppointmentOpen,
    setEditAppointmentOpen,
    editActivity: activityData,
    //   editComponent,
    //   editPayload: { title },
  } = useActivitiesContext();

  const onClose = () => {
    setEditAppointmentOpen(false);
  };

  const onUpdated = () => {
    handleUpdated();
  };

  return (
    <Popup
      title={'Edit Appointment'}
      onClose={onClose}
      open={editAppointmentOpen}
      maxWidth={'md'}
      style={{ minWidth: '825px' }}
      titleComponent={<AvatarTitle member={activityData?.additional?.patient} title="Edit Appointment" />}
    >
      <EditAppointmentComponent {...activityData} onClose={onClose} onUpdated={onUpdated} />
    </Popup>
  );
};

// AppointmentForm.propTypes = {
// 	selectedPatientId: PropTypes.string
// };

// export default withApollo(EditActivityPopup);
export default EditAppointmentPopup;
