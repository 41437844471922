import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styles from './LabsTablePatient.module.scss';

// Data
import { useQuery } from '@apollo/client';
import { GET_LABS } from 'data/graphql/labs';

// Table
import { toTableData } from './helpers';
import SortableTable from 'common/components/Table/SortableTable';
import Filters from 'common/components/PanelFilters/PanelFilters';
import { Messages } from 'config/settings';

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';

const columns = [
  { id: 'date', label: 'Date', width: 120 },
  { id: 'authorizationNumber', label: 'Auth #', width: 120 },
  { id: 'result', label: 'Result', width: 140 },
  { id: 'type', label: 'Type', width: 200 },
  { id: 'notes', label: 'Notes', width: 'auto' },
  { id: 'actions', label: 'Actions', width: 200 }
];

const LabsTablePatient = () => {

  // Context
  const {memberId, hasPastEpisode} = useMemberStateContext();

  // State
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const [labsList, setLabsList] = useState([]);
  const [filteredLabsList, setFilteredLabsList] = useState([]);
  const momentTwoWeeksAhead = moment().add(2, 'week');
  const momentSubstractMonth = moment().subtract(3, 'months');

  // Data Request
  const { data, error, loading } = useQuery(GET_LABS, {
    variables: {
      filters: {
        patientId: [memberId],
        ...(!hasPastEpisode 
          ? { startDate: momentSubstractMonth.format(format), endDate: momentTwoWeeksAhead.format(format) } 
          : null
        ),
      }
    }
  });

  useEffect(() => {
    if (data) {
      try {
        const converted = toTableData(data.getLabs, memberId);
        setLabsList(converted);
        setFilteredLabsList(converted);
      } catch (err) {
        console.log(err)
      }
    }
  }, [data])

  // Methods
  const handleFiltersSubmit = updatedList => setFilteredLabsList(updatedList);

  // Render
  // TO DO: Setup less disruptive loading state using table skeleton. 
  // console.log("loading, error, data", loading, error, data)

  const filterProps = {
    data: labsList,
    hasSearch: true,
    searchLocalBy: ['type', 'notes'],
    onSubmit: handleFiltersSubmit,
    filterQuery: {
      reqQuery: GET_LABS,
      variables: { filters: { patientId: [memberId] } },
      variablesPath: 'filters',
      mutate: toTableData,
      queryName: 'getLabs',
    },
    fields: {
      types: {
        label: 'Panel Type',
        placeholder: 'Choose a type',
        initialValue: [],
        fieldPath: 'type',
        fieldValPath: ''
      },
      results: {
        label: 'Result',
        placeholder: 'Choose a result',
        initialValue: [],
        fieldPath: 'result',
        fieldValPath: 'props.label'
      },
    },
    initDates: {
      minDate: momentSubstractMonth,
      maxDate: momentTwoWeeksAhead,
    }
  };

  return (
    <Box className={styles.wrapper}>

      <Filters {...filterProps} />

      <SortableTable
        columns={columns}
        data={filteredLabsList}
        emptyTitle={Messages.CALLDASH_LABS_EMPTY}
        emptyMessage={Messages.CALLDASH_LABS_EMPTY_MESSAGE}
        loading={loading}
        loadingMessage={Messages.CALLDASH_LABS_LOADING} />

    </Box>
  );
};

LabsTablePatient.propTypes = {

};


export default LabsTablePatient;
