import { combineReducers } from 'redux';
import * as types from 'actions/types';

const isTokenExpiringSoon = (prev = false, action) => {
  switch (action.type) {
    case types.ON_AUTH_REQUEST:
    case types.ON_AUTH_SUCCESS:
    case types.REFRESH_TOKEN_SUCCESS:
      return false;
    case types.TOKEN_EXPIRES_SOON:
      return true;
    default:
      return prev;
  }
};

const authenticated = (prev = false, action) => {
  switch (action.type) {
    case types.ON_AUTH_CURRENT_SESSION:
      return action.authenticated;
    case types.ON_AUTH_FAIL:
    case types.ON_AUTH_SIGN_OUT:
      return false;
    case types.REFRESH_TOKEN_SUCCESS:
    case types.ON_AUTH_SUCCESS:
      return true;
    default:
      return prev;
  }
};

const loading = (prev = false, action) => {
  switch (action.type) {
    case types.ON_AUTH_CURRENT_SESSION:
    case types.ON_AUTH_SIGN_OUT:
    case types.ON_AUTH_SUCCESS:
    case types.ON_AUTH_CURRENT_USER:
    case types.ON_AUTH_FAIL:
    case types.ON_CHALLENGE_REQUESTED:
      return false;
    case types.ON_AUTH_START:
      return true;
    default:
      return prev;
  }
};

const error = (prev = null, action) => {
  switch (action.type) {
    case types.ON_AUTH_FAIL:
      if (action.payload !== undefined) {
        return action.payload;
      }
      return null;
    case types.ON_AUTH_REQUEST:
      return null;
    default:
      return prev;
  }
};

const cognitoUser = (prev = null, action) => {
  switch (action.type) {
    case types.ON_AUTH_SUCCESS:
      return action.signInResponse;
    case types.ON_AUTH_SIGN_OUT:
    case types.ON_AUTH_FAIL:
      return null;
    default:
      return prev;
  }
};

const cognitoSession = (prev = null, action) => {
  switch (action.type) {
    case types.REFRESH_TOKEN_SUCCESS:
      return action.payload;
    case types.ON_AUTH_CURRENT_SESSION:
      return action.session;
    case types.ON_AUTH_SUCCESS:
      return action.signInResponse.signInUserSession;
    case types.ON_AUTH_SIGN_OUT:
    case types.ON_AUTH_FAIL:
      return null;
    default:
      return prev;
  }
};

const currentScreen = (prev = 'LOGIN', action) => {
  switch (action.type) {
    case types.ON_AUTH_REQUEST:
      return 'LOGIN';
    case types.ON_CHALLENGE_REQUESTED:
      return 'CHALLENGE';
    default:
      return prev;
  }
};

const challenge = (prev = null, action) => {
  switch (action.type) {
    case types.ON_CHALLENGE_REQUESTED:
      return action.payload;
    default:
      return prev;
  }
};
const userLastClicked = (prev = null, action) => {
  switch (action.type) {
    case types.SET_LAST_CLICK_TIME:
      return action.payload
    case types.REFRESH_TOKEN_SUCCESS:
    case types.ON_AUTH_SUCCESS:
      return new Date()
    default:
      return prev;
  }
};

export default combineReducers({
  authenticated,
  loading,
  error,
  cognitoUser,
  cognitoSession,
  currentScreen,
  challenge,
  isTokenExpiringSoon,
  userLastClicked
});
