import React, {useState, useEffect} from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';

// UI
import { Grid, Box, Button } from '@material-ui/core';
import styles from './MyAccount.module.scss';
import Header from 'common/components/PanelHeader/PanelHeader';
import { AccountAvatarSkeleton, AccountFormSkeleton } from './Skeleton';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import { Skeleton } from '@material-ui/lab';

// Data
import { useMutation, useQuery } from '@apollo/client';
import { EDIT_STAFF, ME_STAFF } from 'data/graphql/staff';

// Account Edit Components
import Profile from './AccountProfile';
import WorkingHours from './AccountWorkingHours';
import ExternalCalendars from './AccountExternalCalendars';
import ResetPassword from './AccountResetPassword';
import AvatarDialog from './components/AvatarDialog';

import useAccountContext from 'context/Account/useAccountContext';

const headerBreadcrumbs = [
  {
    name: 'Account Settings',
    url: '/account',
  },
];

const listTabs = [
  {
    label: 'Profile',
    url: `/account`,
  },
  {
    label: 'Working Hours',
    url: `/account/workingHours`,
  },
  {
    label: 'External Calendars',
    url: `/account/externalCalendars`,
  },
  {
    label: 'Reset Password',
    url: `/account/resetPassword`,
  },
];

const overflowMenu = [
  {
    name: 'Back to Dashboard',
    src: `/`,
  },
];

const MyAccount = () => {

  const { user, userId } = useAccountContext()

  // State
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarFile, setAvatarFile] = useState();

  const [editStaff, editStaffState] = useMutation(EDIT_STAFF, {
    refetchQueries: [{ query: ME_STAFF }],
    awaitRefetchQueries: true,
  });

  // Handlers
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    console.log('closing avatar modal')
    setModalOpen(false);
  }

  const { data, loading, error, refetch } = useQuery(ME_STAFF);
  if (error) {
    return <div style={{ color: 'rgba(0,0,0,0.8)', fontSize: 16 }}>Something went wrong. Please reload :(</div>;
  }

  if (loading) return <MyAccountSkeleton />;

  const { _id, firstName, lastName, image, title } = data?.meStaff;
  const avatarProps = {
    _id,
    image,
    firstName,
    lastName,
    title: title || '',
    type: 'staff',
    size: 'xlarge',
    label: `${title} ${firstName} ${lastName}`,
    showLabel: true,
    disabled: true,
  };

  return (
    <div className={styles.wrapper}>
      <Header listBreadcrumbs={headerBreadcrumbs} listTabs={listTabs} overflowMenu={overflowMenu} />
      <Grid container className={styles.module_top}>
        <Grid item xs={3} className={styles.static_top}>
          <Box textAlign="center">
            <Box mb={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <MemberAvatar {...avatarProps} />
              <Button color="primary" variant="contained" onClick={handleModalOpen}>
                Change Photo
              </Button>
            </Box>

            <AvatarDialog
              open={modalOpen}
              handleClose={handleModalClose}
              editStaffMutation={editStaff}
            />

          </Box>
        </Grid>

        {data ? (
          <Grid item xs={9}>
            <Switch>
              <Route exact path={`/account`} component={() => <Profile staff={data.meStaff} />} />
              <Route exact path={`/account/workingHours`} component={() => <WorkingHours staff={data.meStaff} />} />
              <Route
                exact
                path={`/account/externalCalendars`}
                component={() => <ExternalCalendars staff={data.meStaff} refetch={refetch} />}
              />
              <Route exact path={`/account/resetPassword`} component={() => <ResetPassword user={data.meStaff} />} />
            </Switch>
          </Grid>
        ) : (
          <AccountFormSkeleton height={50} width={550} />
        )}
      </Grid>
    </div>
  );
};

export default withRouter(MyAccount);

const MyAccountSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Box className={styles.navpanel_wrapper}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            paddingTop: '30px',
            alignItems: 'center',
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0 0',
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height="30px" style={{ marginLeft: '30px' }} />
        </Box>
      </Box>

      <Grid container className={styles.module_top}>
        <Grid item xs={3} className={styles.static_top}>
          <AccountAvatarSkeleton />
        </Grid>
        <AccountFormSkeleton height={50} width={550} />
      </Grid>
    </div>
  );
};
