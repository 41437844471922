import React from 'react';
import { RadioGroup, Radio as MURadio, FormControlLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export const BaseRadio = React.forwardRef(({items, className, disabled, invalid, ...props}, ref) => {
    const stylesBase = useStylesBase();
    const classNames = clsx(className, stylesBase.radioGroup);

  return (
    <RadioGroup {...props} className={classNames} ref={ref}>
      {items &&
        items.map(({ value, label }, key) => (
          <FormControlLabel
            value={value}
            key={key}
            control={<MURadio color="primary" value={value} />}
            label={label}
            disabled={disabled}
          />
        ))}
    </RadioGroup>
  );
});

const useStylesBase = makeStyles({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
});
