import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './ChatMessage.module.scss';
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

import moment from 'moment';
import Highlighter from 'react-highlight-words';

const ChatMessageItem = ({
  userId,
  member,
  message,
  createdAt,
  searchText = [],
  isSoberCircle,
  textToHighlight = [],
  _sender: {
    userId: msgUserId
  },
}) => {
  const isCurrentUserMessage = userId === msgUserId;

  const sentTimeString = (isSoberCircle || !isCurrentUserMessage)
    ? `${member?.firstName} ${member?.lastName?.[0]}. - ${moment(createdAt).fromNow()}`
    : moment(createdAt).fromNow();

  const highlighterProps = {
    autoEscape: true,
    searchWords: searchText,
    textToHighlight: message,
    highlightClassName: styles.highlight,
  }

  const avatarProps = { 
    _id: member?._id,
    firstName: member?.firstName,
    lastName: member?.lastName,
    image: member?.image || member?.emrPic,
    type: member?.type,
    disabled: true,
    size: 'small',
  };

  const DivWrapper = ({ children }) => isCurrentUserMessage ? children : <div>{children}</div>;

  return (
    <div className={isCurrentUserMessage ? styles.item_right : styles.item_left}>
      <div className={styles.main}>
        {member && !isCurrentUserMessage && <MemberAvatar {...avatarProps}/>}
        <DivWrapper>
          <div className={styles.text} children={<Highlighter {...highlighterProps}/>}/>            
          <Typography variant="caption" className={styles.time} children={sentTimeString}/>
        </DivWrapper>
      </div>
    </div>
  )
};

export default ChatMessageItem;
