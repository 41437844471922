import React from 'react';
import moment from 'moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import mstyles from './Appointments.module.scss';
import { eventColors } from 'styles/variables';

import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { useSelector } from 'react-redux';
import ExternalAppointments from './ExternalAppointment'
import EmptyAppointmentLayout from './EmptyAppointmentLayout'
import MultiSchedulerAppointmentLayout from './MultiSchedulerAppointmentLayout'


const styles = theme => ({
  appointment: {
    width: '100%',
    height: '100%',
    color: 'rgba(0,0,0,0.75) !important',
  },
  monthAppointment: {
    height: 'max-content',
    backgroundColor: 'transparent',
    color: 'rgba(0,0,0,0.75) !important',
  },
});

const IRAppointments = withStyles(styles)(
  ({ children, classes, style, data, ...restProps }) => {
    const scheduler = useSelector(state => state.scheduler);

    // console.log('[IRAppointments]scheduler.myAvailability', scheduler, data);
    if (scheduler.myAvailability && !data?.invitedMembers.includes(scheduler.patient)) return <EmptyAppointmentLayout
      classes={classes}
      data={data}
      children={children}
      style={style}
      {...restProps}
    />

    if (scheduler.currentViewName === 'Month')
      return (
        <AppointmentLayoutMonthView
          classes={classes}
          data={data}
          children={children}
          style={style}
          {...restProps}
        />
      );
    else
      return (
        <AppointmentLayoutWeekView
          classes={classes}
          data={data}
          children={children}
          style={style}
          {...restProps}
        />
      );
  }
);

export const IRAppointmentContent = ({ data, classes, ...props }) => {

  const currentViewName = useSelector(state => state.scheduler.currentViewName);

  return currentViewName === 'Month' 
      ? <AppointmentContentMonthView data={data} {...props} />
      : <AppointmentContentWeekView data={data} {...props} />

};

export const MultiScheduleAppointmentContent = ({ 
  data, classes, ...props 
}) => <AppointmentContentWeekView data={data} hideDescription={true} {...props} />;
 
export default IRAppointments;

const AppointmentContentMonthView = ({ data, ...props }) => {
  const classes = useStyles();

  // Setup
  const eventType = data?.type || 'core';
  const appointmentTime = `${moment(data?.scheduling?.start).format('h:mmp')}`

  return (
    <Appointments.AppointmentContent 
      data={data} 
      className={classes.eventCard} 
      style={{ 
        borderColor: eventColors[eventType].outline,
        backgroundColor: eventColors[eventType].bg
      }}
      {...props} 
    >
      <span className={mstyles.titlename} style={{color: '#333'}}>
        <span style={{fontWeight: 600, marginRight: 3}}>{appointmentTime}</span> {data.title}
      </span>
    </Appointments.AppointmentContent>
  );
};

const AppointmentContentWeekView = ({ data, hideDescription, ...props }) => (
  <Appointments.AppointmentContent
    data={data}
    className={mstyles.content}
    {...props}
  >    
    <div style={{fontSize: '1em', fontWeight: 600, color: 'rgba(0,0,0,0.75)'}}>{data.title}</div>
    {!hideDescription && 
      <div style={{width: '50%', fontSize: '1.3em', color: 'rgba(0,0,0,0.75)'
      }}>
        {data?.description}
      </div>
    }
  </Appointments.AppointmentContent>
);

const AppointmentLayoutMonthView = ({
  classes,
  data,
  style,
  children,
  ...restProps
}) => (
    <Appointments.Appointment
      data={data}
      type={data.type}
      style={{...style}}
      className={classes.monthAppointment}
      {...restProps}
    >
      {children}
    </Appointments.Appointment>
  );

const AppointmentLayoutWeekView = ({
  classes,
  data,
  style,
  children,
  ...restProps
}) => {
  const eventType = data?.type || 'core';
  
  if (data.type === 'outlook' || data.type === 'google') 
    return <ExternalAppointments {...restProps} type={data.type} style={{...style}} data={data} />

  return (
    <Appointments.Appointment
      {...restProps}
      className={classes.appointment}
      type={data.type}
      style={{
        ...style,
        color: "rgba(0,0,0,0.75)",
        borderColor: eventColors[eventType].outline,
        backgroundColor: eventColors[eventType].bg,
      }}
      data={data}
    >
      {children}
    </Appointments.Appointment>
  );
};


// To Do
// create isThis, isThat for line 198

export const MultiScheduleAppointments = withStyles(styles)(
  ({ children, classes, style, data, ...restProps }) => {
    const appointmentBooker = useSelector(state => state.appointmentBooker);

    // console.log('[MultiScheduleAppointments]scheduler.myAvailability', appointmentBooker, data);
    // scheduler.myAvailability = true;
    const isFamilyActivity = 'this';
    const isPatientActivity = 'that';
    
    // checks if patient or family activity
    if (data?.invitedMembers.includes(appointmentBooker.selectedMember) || appointmentBooker.relatedInvitedMembers.find(a => data?.invitedMembers.includes(a)))
      return (
        <AppointmentLayoutWeekView
          classes={classes}
          data={data}
          children={children}
          style={style}
          {...restProps}
        />
      );
    return <MultiSchedulerAppointmentLayout
      classes={classes}
      data={data}
      children={children}
      style={style}
      {...restProps}
    />
  }
);

const useStyles = makeStyles(theme => ({
  eventCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 2,
    padding: 1,
    borderRadius: 5,
    border: '1px solid',
    color: 'rgba(0,0,0,0.75)',
  },
}))