import {gql} from '@apollo/client';

export const THERAPIST_LAYOUT_SUBSCRIPTION = gql`
  subscription dashboardUpdate {
    dashboardUpdate {
      user {
        _id
        firstName
      }
    }
  }
`;
export const PATIENT_UPDATE_SUB = gql`
  subscription patientUpdated($_id: ObjectID!) {
    patientUpdated(_id: $_id) {
      _id
    }
  }
`;
export const NOTIFICATION_SUB = gql`
  subscription newNotification {
    newNotification {
      _id
      notificationId
      sourceId
      source
      sourceCollection
      parsedContent
      parsedLink
    }
  }
`;
