import React, { Fragment, useState, useEffect } from 'react';

// Material UI
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Item from './Item/Item';
import Header from 'common/components/PanelHeader/PanelHeader';
import EmptyState from 'common/components/EmptyState/EmptyState';
import Filters from 'common/components/PanelFilters/PanelFilters';

// Dialog
import ActivateMemberPopup from 'components/Member/Actions/ActivateMemberNew';

// HOC
import WithPagination from 'hoc/WithPagination/WithPagination';

// Data
import {Messages} from 'config/settings';

const MemberList = ({
  data,
  dataLoading,
  dataQueryCalled,
  dataError,
  memberType,
  
  headerProps,
  filtersProps,
  baseUrl,

  emptyData,
  emptySearch,

  // Pagination props
  Pagination,
  paginCurrenPage,
  paginPerPage,
  paginTotalUpdate,
  paginTotal,

  // Card props
  showRelated,
  hideChat,
}) => {
      
  // Hooks
  const classes = useStyles();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [membersList, setMembersList] = useState(data);
  const [withData, setWithData] = useState(false)
  const [emptyState, setEmptyState] = useState('loading');

  // Effects: Query States
  useEffect(() => {
    setIsLoading(dataLoading);
    if(!dataQueryCalled && dataLoading) setEmptyState('loading')
  }, [dataLoading])
  
  useEffect(() => {
    setHasError(dataError);
    if(dataQueryCalled && dataError) setEmptyState('error')
  }, [dataError])

  // useEffect(() => {
  //   console.log('[Member/List] EmptyState changed:', emptyState)
  // }, [emptyState, data])
  
  useEffect(() => {
    // No Members
    const noMembers = data?.length === 0;
    if (dataQueryCalled && !dataLoading && noMembers) setEmptyState('members')

    if (data) setIsEmpty(!data?.length)
    
    if (data?.length > 0) {
      setMembersList(data)
      setWithData(true)
    }
  }, [data, dataLoading]);

  // Empty States
  const emptyStateData = {
    error: errorStateData,
    loading: loadingStateData,
    members: emptyData,
    search: emptySearch,
    none: {title:'', text:''},
  }
  
  const showEmptyState = isEmpty || isLoading || hasError; //|| noSearch;
  const showMemberList = !isEmpty && !isLoading && !hasError;
  

  // Dialog Control
  const [isOpen, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  // const triggerDialog = useCallback(memberData => {
  //   setUserData(() => memberData);
  //   setOpen(prev => !prev);
  // }, [])
  
  // Methods: Filters
  const handleFiltersSubmit = updatedList => {
    paginTotalUpdate(updatedList.length);
    setMembersList(updatedList);
  };

  // Pagination
  const fromPg = (paginCurrenPage - 1) * paginPerPage;
  const toPg = fromPg + paginPerPage;
  const isSinglePage = paginPerPage === paginTotal;

  const currentPageMembers = (array = [], from, to, singlePage) => {
    const membersArray = array?.length ? [...array] : [];
    return singlePage ? membersArray : membersArray.slice(from, to);
  };
  const membersDisplayed = currentPageMembers(membersList, fromPg, toPg, isSinglePage)
  
  // Render
  const localFiltersProps = {
    ...filtersProps,
    onSubmit: handleFiltersSubmit,
  };
  
  const activatePopupProps = {
    data: userData,
    isOpen,
    //triggerDialog,
  }

  console.log('raw data', data, 'dataLoading', dataLoading, 'dataQueryCalled', dataQueryCalled, 'dataError', dataError, 'membersList', membersList)
  
  return (
    <div className={classes.root}>
      {!!headerProps && <Header {...headerProps} />}
      {!!filtersProps ? <Filters {...localFiltersProps} /> : null}

      {showEmptyState ? <EmptyState {...emptyStateData[emptyState]} /> : null}
      
      {showMemberList && 
        <Fragment>
          <Grid container spacing={3} className={classes.gridContainer}>
            {membersDisplayed?.map((item, i) => {
              return <MemberCardMemo key={item?._id} item={item} baseUrl={baseUrl} showRelated hideChat /> 
            })}
          </Grid>
          <Pagination />
       </Fragment>
      }

      <ActivateMemberPopup {...activatePopupProps} />
    </div>
  );
};

export default WithPagination()(MemberList);

const MemberCard = ({
  item,  
  showRelated, 
  hideChat,
  baseUrl,
  //triggerDialog 
}) => {
  return <Grid item xs={12} sm={6} md={3}>
    <Item {...item} {...{ 
      showRelated, 
      hideChat, 
      baseUrl,
      //triggerDialog 
      }} />
  </Grid>
};
const MemberCardMemo = React.memo(MemberCard);

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    padding: 40,
  },
});

const loadingStateData = {
  title: Messages.MEMBER_MODULE_LOADING_TITLE,
  text: Messages.MEMBER_MODULE_LOADING_TEXT,
  showLoading: true,
}

const errorStateData = {
  title: Messages.MEMBER_MODULE_ERROR_TITLE,
  text: Messages.MEMBER_MODULE_ERROR_TEXT,
}