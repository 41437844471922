
import PatientEditProfile from 'screens/Patient/Edit/PatientEditProfile';
import React from 'react';

const EditProfile = () => {
  
  return (
    <PatientEditProfile />
  )
  
}

export default EditProfile;