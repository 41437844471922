import React from 'react';
import styles from './styles.module.scss';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box'

const ChipComponent = ({ ...restProps }) => (
    <Box className={styles.container}>
    <Chip
      classes={{ root: styles.background, avatar: styles.avatar, label: styles.label, ...restProps.classes }}
      {...restProps}
    />
    </Box>
  );

export default ChipComponent;