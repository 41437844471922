import React from 'react';
import {Box} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import {makeStyles} from '@material-ui/styles';

const BookerSkeleton = () => {
  const styles = useStyles();

  return <Box className={styles.skeleton}>
    <Skeleton animation="wave" variant='rect' width={890} height={85} style={{marginTop: -1, marginBottom: 15}}/>
    <Skeleton animation="wave" variant='rect' width={800} height={450}/>
  </Box>
}

export default BookerSkeleton;

const useStyles = makeStyles({
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
