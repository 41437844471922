import {useContext} from 'react';
import { GuideContext } from './GuideContext';

const useGuideContext = () => {
    
    const context = useContext(GuideContext);
    
    if(!context) { 
        throw new Error(`This component cannot be rendered outside the GuideContext`) 
    };

    return context

};

export default useGuideContext;