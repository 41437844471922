import React, {createContext, useState, useEffect} from 'react'

// Data
import {useApolloClient} from '@apollo/client';

export const SessionContext = createContext();
SessionContext.displayName = 'SessionContext';

const SessionContexProvider = ({children}) => {
    
    // Hooks
    const client = useApolloClient();

    const [isFocused, setIsFocused] = useState(true)

    // Side Efects
    useEffect(() => {
        console.log('[SessionContext] mounted')
        return () => console.log('[SessionContext] unmounted')
    }, [])

    useEffect(() => {
        const handleFocus = () => {
            console.log('[SessionContext] isFocused true');
            setIsFocused(true);
        };
        const handleBlur = () => {
            console.log('[SessionContext] isFocused false');
            setIsFocused(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };        
    }, [])

    // Handlers
    const handleClearSession = async () => {
      await client.clearStore()
      console.log('[SessionContext] Apollo client.clearStore() Completed')
    }

    const contextValues = {
        isFocused,
        handleClearSession,
    }

    console.log('[SessionContext] isFocused Value', isFocused)
    return (
        <SessionContext.Provider value={contextValues}>
            {children}
        </SessionContext.Provider>
    )
}


export default SessionContexProvider
