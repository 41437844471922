export const EN = {
  // UI
  HEADER_TITLE_UNREAD: 'Unread Messages',
  HEADER_TITLE_URGENT: 'Urgent Issues',
  HEADER_MENU_ACCOUNT_SETTINGS: 'Account Settings',
  HEADER_MENU_ENABLE_ONCALL: 'Enable On-Call',
  HEADER_MENU_DISABLE_ONCALL: 'Disable On-Call',
  // UI: Buttons
  UI_ACTION_CANCEL: 'Cancel',
  UI_ACTION_CLOSE: 'Close',
  UI_ACTION_CONFIRM: 'Confirm',
  UI_ACTION_CONTINUE: 'Continue',
  UI_ACTION_DISMISS: 'Dismiss',
  UI_ACTION_NEXT: 'Next',
  UI_ACTION_NO: 'No',
  UI_ACTION_OPEN: 'Open',
  UI_ACTION_SEND: 'Send',
  UI_ACTION_YES: 'Yes',

  // Dashboards
  CALLDASH_SCHEDULE_TITLE: 'My Agenda',
  CALLDASH_SCHEDULE_TOGGLE_FULL: 'Outlook Events',
  CALLDASH_SCHEDULE_TOGGLE_CANCELLED: 'Show Cancelled',
  CALLDASH_SCHEDULE_EMPTY: "You don't have any upcoming events.",
  CALLDASH_LABS_TITLE: 'Lab Results',
  CALLDASH_LABS_VIEWALL: 'View All',
  CALLDASH_LABS_CONTACTPATIENT: 'Message Patient',
  CALLDASH_LABS_EMPTY: 'No lab results yet.',
  CALLDASH_LABS_EMPTY_MESSAGE: 'Once we receive results, you know where to find them.',
  CALLDASH_LABS_LOADING: 'Please wait while we load the labs for you.',
  CALLDASH_FLOW_BUTTON_FIRSTCALL: 'First Call',
  CALLDASH_FLOW_BUTTON_NEXTCALL: 'Next Call',
  CALLDASH_FLOW_BUTTON_LASTCALL: 'Last Call',
  CALLDASH_FLOW_LINK: 'Launch Call Screen',
  CALLDASH_FLOW_NOTIFY: 'Send Notification',
  CALLDASH_FLOW_NEXTALERT: 'Your next call starts in 10 minutes.',
  CALLDASH_FLOW_NEXTALERT2: 'Your next call starts in less than 10 minutes.',

  // Schedule
  SCHEDULE_TITLE: 'Schedule',
  SCHEDULE_EVENTPOPUP_PRECALL: 'Prep Call',

  // Member Module
  MEMBER_MODULE_LOADING_TITLE: 'Loading... 🚀',
  MEMBER_MODULE_LOADING_TEXT: 'Just a sec, fetching your data.',
  MEMBER_MODULE_ERROR_TITLE: 'Oops! 😬',
  MEMBER_MODULE_ERROR_TEXT: 'Something happened, please reload :)',

  // Family Module
  FAMILY_HEADER_RELATEDPATIENT: 'Related Patient',
  FAMILY_LIST_EMPTY_TITLE: 'No members found.',
  FAMILY_LIST_EMPTY_TEXT: "You'll see Family Members here when imported from your EMR.",
  FAMILY_LIST_SEARCH_EMPTY_TITLE: 'No results found.',
  FAMILY_LIST_SEARCH_EMPTY_TEXT: 'Sorry, there were no results for your search.',
  FAMILY_LIST_CARD_NOTACTIVATED: 'Not Activated',
  FAMILY_ACTIVATION_TITLE: 'Activate Member',
  FAMILY_ACTIVATION_DETAILS: 'Turn on Family Module for family member. Enables to Booking, Chat and Progress Notes.',
  FAMILY_ACTIVATION_BUTTON_ACCEPT: 'Activate',
  FAMILY_ACTIVATION_SUCCESS_TITLE: 'Member Activated',
  FAMILY_ACTIVATION_SUCCESS_TEXT: "We've emailed the member to setup their inRecovery account.",
  FAMILY_ACTIVATION_WARNING_EMAIL: 'Specify Email Address',
  FAMILY_ACTIVATION_WARNING_EMAIL_HELP: 'Family > Edit',
  FAMILY_ACTIVATION_WARNING_CARETEAM: 'Add Care Team',
  FAMILY_ACTIVATION_WARNING_CARETEAM_HELP: 'Family > Edit > Caron',
  FAMILY_ACTIVATION_WARNING_PATIENT: 'Add Related Patient',
  FAMILY_ACTIVATION_WARNING_PATIENT_HELP: 'Patients > Edit > Family',
  FAMILY_ACTIVATION_WARNING_DEVICE: 'Specify the Mobile Device Type and the corresponding ID',
  FAMILY_ACTIVATION_WARNING_DEVICE_HELP: 'Family > Edit > Profile',

  MEMBER_RESEND_INVITATION_BUTTON: 'Invite to Mobile App',
  MEMBER_RESEND_INVITATION_SUCCESS: 'Sent inRecovery App Invite',
  MEMBER_RESEND_INVITATION_ERROR: 'Invitation failed to send',

  // Patient Module
  PATIENT_ACTIVATION_DETAILS:
    'Turn on the full set of inRecovery features for this patient. Enables to Booking, Chat, Progress Notes and Labs.',
  PATIENT_ACTIVATION_BUTTON_ACCEPT: 'Activate',
  PATIENT_ACTIVATION_TITLE: 'Activate Member',
  PATIENT_ACTIVATE_LINK_TEXT: 'Activate Member',
  PATIENT_ACTIVATION_SUCCESS_TITLE: 'Member Activated',
  PATIENT_ACTIVATION_SUCCESS_TEXT: "We've emailed the member to setup their inRecovery account.",
  PATIENT_ACTIVATION_WARNING_EMAIL: 'Specify Email Address',
  PATIENT_ACTIVATION_WARNING_EMAIL_HELP: 'Patient > Edit',
  PATIENT_ACTIVATION_WARNING_CARETEAM: 'Add Care Team',
  PATIENT_ACTIVATION_WARNING_CARETEAM_HELP: 'Patient > Edit > Caron',
  PATIENT_ACTIVATION_WARNING_PHONE: 'Add a valid Phone #',
  PATIENT_ACTIVATION_WARNING_PHONE_HELP: 'Patient > Edit > Profile',
  PATIENT_ACTIVATION_WARNING_DEVICE: 'Specify the Mobile Device Type and the corresponding ID',
  PATIENT_ACTIVATION_WARNING_DEVICE_HELP: 'Patient > Edit > Profile',
  PATIENT_LIST_EMPTY_TITLE: 'No members found.',
  PATIENT_LIST_EMPTY_TEXT: "You'll see Patients here when imported from your EMR.",
  PATIENT_LIST_SEARCH_EMPTY_TITLE: 'No results found',
  PATIENT_LIST_SEARCH_EMPTY_TEXT: 'Sorry, there are no results for this search',
  PATIENT_DEVICE_TOKEN_MISSING:
    "This member either didn't start using the app or didn't accept the notification permissions before installing.",
  PATIENT_DEVICE_TOKEN_MISSING_HELP:
    "Please make sure they accept the notification permissions or we can't send them push notifications.",
    
  // Auth
  AUTH_LOGIN_WELCOME: 'Welcome',
  AUTH_LOGIN_TITLE: 'Sign In',
  AUTH_LOGIN_FIELD_USER: 'User ID',
  AUTH_LOGIN_FIELD_PASS: 'Password',
  AUTH_FORGOT_TITLE: 'Forgot my password.',
  AUTH_FORGOT_TEXT: "No problem, let's reset it. Enter your email address to get started.",
  AUTH_RESET_TITLE: 'Reset your password',
  AUTH_RESET_TEXT: 'We just emailed you a 6-Digit Verification Code to enter below',
  AUTH_RESET_NEWPASS: 'Choose New Password',
  AUTH_RESET_NEWPASS_CONFIRM: 'Confirm Password',
  AUTH_VERIFY_ENTERCODE: 'Enter Verification Code',
  AUTH_VERIFY_TOOLTIP: '6-Digit Code',
  AUTH_TIMEOUT_WARNING_TITLE: 'Hi, are you still there?',
  AUTH_TIMEOUT_WARNING_TEXT: 'Your session is about to expire.',
  AUTH_BUTTON_SIGNIN: 'Sign In',
  AUTH_BUTTON_SIGNOUT: 'Sign out',
  AUTH_BUTTON_CONTINUE: 'Continue',
  AUTH_BUTTON_FORGOT: 'Forgot Password',

  // Staff
  STAFF_LIST_EMPTY_TITLE: 'No results found',
  STAFF_LIST_EMPTY_TEXT: 'Sorry, there are no Staff membeers yet',
  STAFF_LIST_SEARCH_EMPTY_TITLE: 'No results found',
  STAFF_LIST_SEARCH_EMPTY_TEXT: 'Sorry, there are no results for this search',
  STAFF_ACTIVATION_TITLE: 'Activate Staff',
  STAFF_ACTIVATION_DETAILS: "Turn on Dashboard access for the staff member based on the Roles they've received",
  STAFF_ACTIVATION_BUTTON_ACCEPT: 'Activate',
  STAFF_ACTIVATION_SUCCESS_TITLE: 'Staff Activated',
  STAFF_ACTIVATION_SUCCESS_TEXT: "We've emailed the staff member to setup their inRecovery account.",
  STAFF_ACTIVATION_WARNING_EMAIL: 'Specify Email Address',
  STAFF_ACTIVATION_WARNING_EMAIL_HELP: 'Staff > Edit',
  STAFF_ACTIVATION_WARNING_ROLES: 'Add Roles',
  STAFF_ACTIVATION_WARNING_ROLES_HELP: 'Staff > Edit > Caron',
  STAFF_ONCALL_SUCCESSFULL: 'You are On-Call now. Your on-call session ends in 1 day.',
  STAFF_ONCALL_DISABLE_SUSESSFULL: 'You are no longer On-Call.',

  // External Calendars
  OUTLOOK_LOADING_TITLE: 'Outlook connecting... 🚀',
  OUTLOOK_LOADING_TEXT: 'Just a sec, your calendars are connecting.',
};
