import React from 'react';
import CounselorDashboard from 'screens/Dashboards/CareTeam';
import { default as LabDashboard } from 'screens/Dashboards/UDSLab';
import PatientScreen from "screens/Patient/List/List";
import {useAuthZContext} from 'context/Auth/useAuthContext';

// TO DO
// - Change to user.roles when graphql updated
// - Set default other than Counselor 
// - Handle Multiple Dash options

export const DashRouter = () => {

  let role;
  let multiRole = false;
  const {dashboards} = useAuthZContext();

  const components = {
    COUNSELOR: CounselorDashboard,
    INTAKE: PatientScreen,
    DIRECTOR: CounselorDashboard,
    LAB: LabDashboard,
  }

  dashboards?.length === 1
    ? role = dashboards[0].split('_')[0]
    : multiRole = true

  if (multiRole) {
    dashboards?.find(i => 'DIRECTOR_DASHBOARD')
      ? role = 'DIRECTOR'
      : dashboards?.find(i => 'INTAKE_DASHBOARD')
        ? role = 'INTAKE'
        : role = 'COUNSELOR'  // TEMP: Claire's Counselor+Lab combo. Remove
  }


  const Dashboard = components[role];

  return <Dashboard />

}

