
import { createAction } from 'redux-actions';
import { GET_AGREEMENT, GET_DASHBOARD_QUERY, STORE_DASHBOARD_REFETCH, SET_LAST_CLICK_TIME } from "./types";

export const storeDashboardRefetch = createAction(STORE_DASHBOARD_REFETCH);

export const dashboardToStore = (dashboardData, error) => ({
  type: GET_DASHBOARD_QUERY,
  payload: { dashboardData, error }
})

export const getStatusAgreement = isAgreementTerms => ({
  type: GET_AGREEMENT,
  isAgreementTerms,
});

