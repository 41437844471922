import dayjs from 'dayjs';

const dayTimeRange = {
  morning: {start: '05', end: '12'},
  afternoon: {start: '12', end: '17'},
  evening: {start: '17', end: '21'},
  night: {start: '21', end: '05'},
};

const getHumanizedTime = (time = Date.now()) => {
  // Validate input
  const isValid = dayjs(time).isValid();
  if (!isValid) return 'hello';

  const {afternoon, evening, night} = dayTimeRange;
  const hour = dayjs(time).format('HH');

  if (hour >= afternoon.start && hour < afternoon.end) return 'afternoon';
  if (hour >= evening.start && hour < evening.end) return 'evening';
  if (hour >= night.start || hour < night.end) return 'night';
  return 'morning';
};

export {getHumanizedTime, dayTimeRange};