import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import styles from '../Reports.module.scss';
import ReportCard from './ReportCard/ReportCard';

const ReportsList = ({ cards }) => {
    return (
        <Grid container className={styles['container-cards']}>
            {cards.map((item, index) => index < 8 && (
                <Grid key={item._id} 
                    item xs={12} sm={6} md={4} lg={3}
                    className={styles.column}>
                    <ReportCard {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

ReportsList.propTypes = {
    cards: PropTypes.array
}

export default ReportsList;