import React, { useState, useEffect, useRef } from 'react';
import styles from './ConfirmPassword.module.scss';
import IconButton from '@material-ui/core/IconButton';
import { Grid,FormControl } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ReactSVG from 'react-svg';
import lockImg from '../../assets/lock.svg';
import Button from '../Button/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {checkCurrentUser} from './actions'

const ConfirmPassword = ({checkCurrentUser, user, errors}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const ref = useRef(null)
  // const sendForm = () => {
  //   if (password.length < 6) {
  //     errors.auth = 'password must be over 6 characters'
  //   } else if (!password.length) {
  //     errors.auth = 'Enter your password'
  //   } else checkCurrentSession(email)
  // }
  useEffect(() => {
    ref.current.focus()
  }, [])
  return (
    <Grid container className={styles.wrapper}>
      <Grid item lg={6} sm={10} md={8} className={styles.container}>
        <div className={styles.title}>
          <ReactSVG src={lockImg} className={styles.icon} />
          <span>Please enter you password:</span>
        </div>
        <FormControl error>
        <TextField
          inputRef={ref}
          id="therapist-password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={password}
          className={styles.password}
          onChange={e => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={e => handleMouseDownPassword(e)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {
          errors && (<FormHelperText className={styles.error}>{errors.auth}</FormHelperText>)
        }
        </FormControl>
        
        <Button classes={styles.button}
        onClick={() => checkCurrentUser(user.email, password)}
        >Confirm</Button>
        <h4 className={styles.addition}>Required for Compliance</h4>
      </Grid>
    </Grid>
  );
};

ConfirmPassword.propTypes = {
  user: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  checkCurrentUser: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const {user} = state.dashboard.dashboardData
  const {errors} = state.callflow

  return {
    user,
    errors
  }
}

const mapDisptchToProps = dispatch => ({
  checkCurrentUser: bindActionCreators(checkCurrentUser, dispatch)
})

export default connect(mapStateToProps, mapDisptchToProps)(ConfirmPassword);
