import React, {Fragment} from 'react';
import ReactSVG from 'react-svg';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import BadgeCount from '../Badge/BadgeCount';

const ListItemLink = ({icon, hasCount, count, loading, primary, to, drawerOpen = true}) => {
  
  const classes = useStyles();

  return (
    <li>
      <ListItem button component={RouterLink} to={to}>
        {icon ? <ListItemIcon className={classes.root}><ReactSVG src={icon} className={classes.icon} /></ListItemIcon> : null}
        {drawerOpen 
          ? <div style={{display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center'}}>
              <ListItemText className={classes.itemText}>{primary}</ListItemText>
              {hasCount ? <BadgeCount count={count} loading={loading} style={{marginLeft: 6, backgroundColor: 'rgba(255,255,255,0.25)'}} /> : null}
            </div>
          : null}
      </ListItem>
    </li>
  );
}

export default ListItemLink;

const useStyles = makeStyles({
  root: { 
    minWidth: 30,
  },
  itemText: {
    fontSize: 18,
  },
  countBubble: {
    fontSize: 12, 
    padding: '5px 8px 4px 7px',
    marginLeft: 6,
    borderRadius: 100,
    backgroundColor: 'rgba(255,255,255,0.25)',
  },
  icon: {
    '& svg > path': {
      fill: 'rgba(255,255,255,0.5)',
    },
  }
});

