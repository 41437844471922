import {
  SET_NOTIFICATIONS,
  SELECT_URGENT_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_AS_READ,
  // MARK_URGENT_NOTIFICATION_AS_READ,
  ADD_NOTIFICATION_TO_STORE,
} from 'actions/types';
// import _ from 'lodash';

const initialState = {
  all: [],
  urgent: [],
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case SELECT_URGENT_NOTIFICATIONS: {
      try {
        let urgent = [];
        state.all.forEach(n => {
          if (n.priority.name === 'HIGH')
            urgent.push(
              n.items.map(i => {
                return { ...i, notificationId: n._id };
              })
            );
        });
        urgent = urgent.flat();
        return {
          ...state,
          urgent,
        };
      } catch (error) {
        console.log('error', error);
        return state;
      }
    }
    case ADD_NOTIFICATION_TO_STORE: {
      let updAll = [...state.all];

      const updGroupIndex = updAll.findIndex(
        g => g._id === action.payload.notification._id
      );
      // console.log('notification', action.payload, updAll);
      if (updGroupIndex !== -1) {
        //if the notification has been triggered twice don't show it twice (this happens quite often)
        if( !updAll[updGroupIndex].items.find(i => i._id === action.payload.item._id)){
        updAll[updGroupIndex].items = [
          ...updAll[updGroupIndex].items,
          action.payload.item,
        ];
        if (action.payload.callback)  action.payload.callback(action.payload.item.parsedContent, { variant: 'info' });
      }
      } else {
        updAll = [...updAll, action.payload.notification];
        // console.error('notification Group Not Found');
      }

      return {
        ...state,
        all: updAll,
      };
    }

    case MARK_ALL_NOTIFICATION_AS_READ: {
      let toKeepData = [];
      state.all.forEach(n => {
        if (n.priority.name === 'HIGH') toKeepData.push(n);
      });

      return {
        ...state,
        all: toKeepData,
      };
    }
    default:
      return state;
  }
};

export default NotificationReducer;