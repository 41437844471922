import React, {Fragment} from "react";
import { shape } from "prop-types";
import classnames from 'classnames';
import { Box, Typography } from "@material-ui/core";
import styles from "../PatientEdit.module.scss";
import { lineBreakParser } from "common/helpers/dataHelpers";

const SigmundFamily = ({ patient }) => {

  const hasReleaseText = patient?.familyReleaseText;
  const family = hasReleaseText ? lineBreakParser(patient.familyReleaseText) : [];
  const hasFamily = family?.length > 0;

  if (!hasFamily) return null;
  
  return (
    <Fragment>
      <Box mb={2} className={classnames(styles.section_title)}>
        Sigmund Release Data
      </Box>

      <Box mb={2} className={styles.family_box}>
        {family?.map(member => {
            if (member.includes("Active")) {
              const [ text, status ] = member.split("-");
              return (
                <Typography className={styles.active_member}>
                  {text} {" "} — <Typography style={{ color: "#2A96FF", paddingLeft: 4 }}>{status}</Typography>
                </Typography>
              );
            }
            return <Typography>{member}</Typography>
          })
        }
      </Box> 
    </Fragment>
  )
};

SigmundFamily.propTypes = {
  patient: shape.isRequired
};

export default SigmundFamily;
