import React from 'react';
import {List as MaterialList} from '@material-ui/core'
import Item from './Item'
import PropTypes from 'prop-types';

const List = ({data, empty, ...props}) => { 
  // console.log('[SmallList] data', data, props)
  return (
    <MaterialList>
        { data.map((item, index) => (
            <Item 
                item={item}
                key={index} 
                {...props} 
            />
          ))
        }
    </MaterialList>  
  );
}
List.propTypes = {
    data: PropTypes.array.isRequired
}

export default List;