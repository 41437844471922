import React from 'react';
import WithEvents from 'hoc/WithEvents/WithEvents';
import dayjs from 'dayjs';

// Styles
import styles from './ShortSchedule.module.scss';

// Data
import { Messages } from 'config/settings';

// Components
import CallListSkeleton from './CallList/Skeleton/Skeleton';
import CallList from './CallList/CallList';

const ActivitiesList = ({data, isLoading, range}) => {
  const hasEvents = data?.length;
  const rangeWeekText = `Week of ${dayjs().startOf('week').format('MMM. D')}`;

  return ( <div style={{padding: 10}}>
    <div>{rangeWeekText}</div>
    {isLoading 
      ? <CallListSkeleton items={5} />
      : hasEvents
        ? <CallList range={range} calls={data} />
        : <div className={styles.nocalls}>{Messages.CALLDASH_SCHEDULE_EMPTY}</div>
    }
  </div>
  );
};

export default WithEvents()(ActivitiesList);