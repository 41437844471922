import React from 'react';

import { 
  Box, 
  LinearProgress 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Messages } from 'config/settings';

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';
import HeaderAvatar from './HeaderAvatar';

const TopModule = ({ 
  _id: memberId, firstName, lastName, image, emrPic, mrn, type,
  status, alerts = [], relatedPatients, patients, lastActive,
  overviewHeaderProps = {},
}) => {

  const classes = useStyles();

  const mainAvatarProps = {
    _id: memberId,
    image: image || emrPic, 
    firstName, 
    lastName, 
    type,
    mrn,
    memberStatus: status,
    disabled: true,
    emrAlerts: alerts,
    lastActive,
  }

  const hasPatients = overviewHeaderProps?.hasPatients;
  const hasCaseload = overviewHeaderProps?.hasCaseload;
  const hasProgressData = !!overviewHeaderProps?.progressData;

  return (
    <div className={classes.root}>
      <HeaderAvatar {...mainAvatarProps}/>
      
      {hasPatients ? <RelatedPatients {...{relatedPatients}}/> : null}
      {hasCaseload ? <RelatedPatients caseload {...{relatedPatients: patients}}/> : null}

      {hasProgressData ? (
        <UserProgress memberType={type} progressData={overviewHeaderProps?.progressData} />
      ) : <UserProgress memberType={type} />}
    </div>
  )
}

TopModule.propTypes = {

}

export default TopModule;


const UserProgress = ({ progressData, memberType }) => {
  const classes = useStyles();

  const progressLists = {
    patient: [
      {
        title: "Today's Goals",
        value: progressData?.goals || 0,
        barStyle: 'goals',
      },
      {
        title: 'Engagement',
        value: progressData?.engagement || 0,
        barStyle: 'engagement',
      },
      {
        title: 'Risk Level',
        value: progressData?.riskLevel || 0,
        barStyle: 'risk',
      },
    ],
    family: [
      {
        title: "Today's Goals",
        value: progressData?.goals || 0,
        barStyle: 'goals',
      },
      {
        title: 'Engagement',
        value: progressData?.engagement || 0,
        barStyle: 'engagement',
      },
    ],
    staff: [
      {
        title: "Workload",
        value: progressData?.workload || 0,
        barStyle: 'workload',
      },
      {
        title: 'Wellness',
        value: progressData?.wellness || 0,
        barStyle: 'wellness',
      },
    ],
  };

  const barColors = {
    risk: classes.indicatorRed,
    goals: classes.indicatorGreen,
    engagement: classes.indicatorBlue,
    workload: classes.indicatorGreen,
    wellness: classes.indicatorPink, 
  }

  const userProgressList = progressLists?.[memberType] || [];

  return (
    <div className={classes.progressBox}>
      {userProgressList.map(({ title, value, barStyle }, i) => (
        <div className={classes.progressItem} key={i}>
          <p className={classes.progressItemText}>{title}</p>
          <LinearProgress 
            value={value}
            variant="determinate"
            className={classes.progressItemBar}
            classes={{
              bar: barColors[barStyle],
              root: classes.progressItemBarContent,
            }}            
          />
        </div>
      ))}
    </div>
  )
}

const RelatedAvatar = ({member}) => {
  const { _id, image, firstName, lastName, emrPic } = member;

  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type: 'patient',
    size: 'xsmall'
  };
  return <MemberAvatar {...avatarProps} />
}

const RelatedPatients = ({ relatedPatients = [], caseload = false }) => {
  const classes = useStyles();
  const patientCount = relatedPatients.length;
  const multiPatient = patientCount > 1 ? `s (${patientCount})` : '';
  const multiCase = patientCount > 1 ? `(${patientCount})` : '';

  return (
    <Box ml={6} className={classes.relatedPatientsBox}>
      <div style={{ opacity: .5, marginBottom: 12 }}>
        {caseload ? `Caseload ${multiCase}` : `${Messages.FAMILY_HEADER_RELATEDPATIENT}${multiPatient}`}
      </div>
      <div>
        {relatedPatients.map((member, i) => {
          const fullName = `${member?.firstName} ${member?.lastName?.[0]}`;
          const lastItem = relatedPatients.length - 1 === i;
          return (
            <Box {...!lastItem ? { mb: 1 } : {}} display="flex" alignItems="center" key={i}>
              <RelatedAvatar member={member} />
              <div style={{ marginLeft: 8, fontSize: 16 }}>{fullName}</div>
            </Box>
          )
        })}
      </div>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  relatedPatientsBox: {
    minWidth: 170,
    maxHeight: 120,
    overflow: 'scroll',
    padding: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRight: '1px solid #efefef',
    marginLeft: 30,
  },
  progressBox: {
    margin: 20,
  },
  progressItem: {
    '& + &': {
      marginTop: 10,
    },
    display: 'flex',
    alignItems: 'center',
  },
  progressItemText: {
    opacity: '.5',
    width: '120px',
    fontSize: '14px',
  },
  progressItemBar: {
    height: 6,
    width: 160,
    borderRadius: 10,
    backgroundColor: '#F6F6F6',
    [theme.breakpoints.up('lg')]: {
      width: 150,
    },
    [theme.breakpoints.up('xl')]: {
      width: 250,
    },
  },
  progressItemBarContent: {
    borderRadius: 20,
  },
  indicatorGreen: { backgroundColor: '#00B500' },
  indicatorBlue: { backgroundColor: '#386FA4' },
  indicatorRed: { backgroundColor: '#E90100' },
  indicatorPink: { backgroundColor: '#ff5d8f' },
  stateBox: {
    display: 'flex',
    paddingRight: 60,
    marginLeft: 'auto',
    alignItems: 'center',
  },
}));