import { createAction } from 'redux-actions';

// Types
const LABS = "[Labs]";
const ADD_LAB_REQUEST = `${LABS} ADD_REQUEST`;
const EDIT_LAB_STATUS = `${LABS} EDIT_STATUS`;
const EDIT_LAB_RESULTS = `${LABS} EDIT_RESULTS`;
const ADD_LAB_DETAILS = `${LABS} ADD_DETAILS`;

// Action Creators
export const addLabRequest = createAction(ADD_LAB_REQUEST);
export const editLabStatus = createAction(EDIT_LAB_STATUS);
export const editLabResults = createAction(EDIT_LAB_RESULTS);
export const addLabDetails = createAction(ADD_LAB_DETAILS);