import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './Checkbox.module.scss';

const CheckBox = ({ checked, label, value, color, ...restProps }) => {

  return (
    <FormControlLabel
      classes={{ label: styles.label }}
      className={styles.wrapper}
      control={
        <Checkbox
          checked={checked}
          color={color}
          classes={{ checked: styles.checked }}
          {...restProps}
        />
      }
      label={label || null}
      {...restProps}
    />
  );
};

export default CheckBox;
