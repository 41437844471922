import React, { useState, useEffect } from 'react';

// Material
import { Collapse, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MemberAvatar from 'common/components/Member/Avatar/MemberAvatar';

// Redux
import { useSelector } from 'react-redux';
import { getPatientRelations, getUsersByIds } from 'selectors';





const FamilyToggles = ({
  isFamilySession,
  toReschedule = false,
  mainPatient,
  invitedMembers,
  setInvitedMembers,
  disabled = false,
}) => {
  const classes = useStyles();
  // State
  const [checksList, setChecksList] = useState([]);
  let relationsOfThePatient = useSelector(state => getPatientRelations(state, mainPatient));
  const a = useSelector(state => getUsersByIds(state, invitedMembers));
  const checkboxDisabled = disabled || toReschedule;
  // if there is some access issue meaning the user doesn't have access to the main patine the
  // relations array is empty in this case take the relations from the invitedMembers since the user has access to that
  // but issue we don't know who are the other family members related to this patient maybe the user has access to multiple ones
  // TODO: find a permission solution for this situation so we don't have to useSelector for invitedMembers
  if (relationsOfThePatient.length == 0 && invitedMembers.length > 0) {
    relationsOfThePatient = a;
  }
  useEffect(() => {
    setChecksList(
      relationsOfThePatient.map(({ _id, activatedAccount }) => ({
        checked: invitedMembers ? invitedMembers.includes(_id) : false,
        id: _id,
        disabled: !activatedAccount,
      }))
    );
  }, [relationsOfThePatient]);

  const handleUpdate = i => {
    const updatedList = [...checksList];
    updatedList[i].checked = !updatedList[i].checked;
    setChecksList(updatedList);
    const idsArray = updatedList.filter(i => i.checked).map(i => i.id);
    setInvitedMembers(idsArray);
  };

  return (
    <div className={classes.root}>
      <Collapse in={isFamilySession && !!checksList.length}>
        <span style={{ marginBottom: 5 }}>Family</span>

        <FormGroup row style={{ display: 'flex', flexDirection: 'column' }}>
          {checksList.map((item, i) => {
            const relatedMember = relationsOfThePatient[i]
            const label = `${relatedMember?.firstName} ${relatedMember?.lastName} (${relatedMember?.relation?.name})`;
            const avatarProps = {
              firstName: relatedMember?.firstName,
              lastName: relatedMember?.lastName,
              image: relatedMember?.image || relatedMember?.emrPic,
              size: 'xxsmall',
              type: 'patient',
            }
            return (<FormControlLabel
              key={i}
              control={
                <Checkbox
                  color="primary"
                  disabled={checkboxDisabled || item.disabled}
                  checked={item.checked}
                  onChange={() => handleUpdate(i)}
                />
              }
              label={<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><MemberAvatar {...avatarProps} /> <span style={{padding: 5}}>{label}</span></div>}
            />
            )}
          )}
        </FormGroup>
      </Collapse>
    </div>
  );
};

export default FamilyToggles;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
