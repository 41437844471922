import {gql} from "@apollo/client";

export const GET_SOBER_CIRCLE = gql`
  query get($patientId: ObjectID!) {
    getSoberCircle(patientId: $patientId) {
      _id
      createdAt
      members {
        _id
        firstName
        lastName
        image
        isTeamLead
      }
      roomId
      teamName
    }
  }
`;

export const GET_SOBER_CIRCLES_LIST = gql`
  query get {
    getSoberCirclesList {
      _id
      createdAt
      members {
        _id
        firstName
        lastName
        image
        isTeamLead
      }
      roomId
      teamName
    }
  }
`;

export const ADD_SOBER_CIRCLE = gql`
  mutation post($content: SoberCircleInput) {
    createSoberCircle(content: $content) {
      _id
      members {
        _id
        firstName
        lastName
        image
        isTeamLead
      }
      roomId
      teamName
    }
  }
`;

export const JOIN_SOBER_CIRCLE = gql`
  mutation put($soberCircleId: ObjectID!, $patientId: ObjectID!) {
    joinSoberCircle(soberCircleId: $soberCircleId, patientId: $patientId) {
      _id
      members {
        _id
        firstName
        lastName
        image
        isTeamLead
      }
      roomId
      teamName
    }
  }
`;

export const UPDATE_SOBER_CIRCLE = gql`
  mutation put($_id: ObjectID!, $content: SoberCircleInput) {
    updateSoberCircle(_id: $_id, content: $content) {
      _id
      members {
        _id
        firstName
        lastName
        image
        isTeamLead
      }
      roomId
      teamName
    }
  }
`;

export const REMOVE_SOBER_CIRCLE = gql`
  mutation del($_id: ObjectID!) {
    removeSoberCircle(_id: $_id) {
      _id
      teamName
    }
  }
`;
