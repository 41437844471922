import { createAction } from 'redux-actions';

// Types
const MEMBERS = "[Members]";
const SET_MEMBER_TYPE = `${MEMBERS} SET_TYPE`;
const SET_MEMBER_DATA = `${MEMBERS} SET_DATA`;
const SET_EDIT_MEMBER_MODE = `${MEMBERS} SET_EDIT_MODE`;

// Action Creators
export const setMemberType = createAction(SET_MEMBER_TYPE);
export const setMemberData = createAction(SET_MEMBER_DATA);
export const setEditMode = createAction(SET_EDIT_MEMBER_MODE);