import React from 'react';
import PropTypes from 'prop-types';

import { Button, Box } from '@material-ui/core';

import { Formik, Form } from 'formik';
import FormSkeleton from 'common/components/Formik/FormSkeleton/FormSkeleton';
import { FormBuilder, toInitialValues } from 'common/components/Formik/FormBuilder/FormBuilder';
import { FormDevTools } from 'common/components/Formik/FormDevTools'

import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import { EDIT_FAMILY_MEMBER } from 'data/graphql';

const useStyles = makeStyles({
  formTitle: {
    fontSize: 20,
    marginBottom: 28,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, .8)',
  }
});

const ClinicalPath = ({ loading, error, data, avatarFile, refetch, refetchDashboard }) => {

  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Mutations
  const [editMember, { loading: editLoading }] = useMutation(EDIT_FAMILY_MEMBER, {
    onError: () => enqueueSnackbar('Error during update', { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar('Account updated', { variant: 'success' })

      setTimeout(() => {
        try {
          refetch();
          refetchDashboard();
        } catch (error) { console.log(error) }
      }, 250);
    }
  });

  // Methods
  const onFormSubmit = values => {

    const updatedMember = {
      ...values,
      ...avatarFile ? { imageContent: avatarFile } : {}
    }

    editMember({
      variables: {
        memberId: data?._id,
        familyInfo: updatedMember,
      }
    });

  }

  // Render Props
  const fieldsGrid = [[{
    type: 'chipinput',
    name: 'alerts',
    title: 'Alerts',
    value: data?.alerts?.filter(i => !!i) || [],
    grid: { xs: 12 }
  }, {
    type: 'chipinput',
    name: 'tags',
    title: 'Tags',
    value: data?.tags?.filter(i => !!i) || [],
    grid: { xs: 12 }
  }]];

  const formikProps = {
    onSubmit: onFormSubmit,
    initialValues: toInitialValues(fieldsGrid),
    // validationSchema: validationSchema,
  }

  const btnSubmitProps = {
    type: "submit",
    color: "primary",
    variant: "contained",
  }

  return (
    loading ? <FormSkeleton repeat={1} /> : (
      <Formik {...formikProps}>
        {({ values, handleChange, setFieldValue, errors, setErrors }) => {
          const hasErrors = !!(errors && Object.keys(errors).length);
          return (
            <Form>

              <p className={classes.formTitle}>Clinical Path</p>
              <FormBuilder data={fieldsGrid} {...{ setFieldValue, values, handleChange, errors, setErrors }} />

              <Box display="flex" justifyContent="flex-end" mt={3} children={
                <Button {...btnSubmitProps}
                  disabled={editLoading || hasErrors}
                  children={editLoading ? 'Loading...' : 'Update'} />
              } />

              <FormDevTools values={values} errors={errors} />

            </Form>
          )
        }}
      </Formik>
    )
  )
}

ClinicalPath.propTypes = {

}

export default ClinicalPath;