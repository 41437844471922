import React, { Fragment, useEffect, useState } from 'react';

// Components
import { Switch, Route, useLocation } from 'react-router-dom';

// Main components
import { MemberBodySkeleton } from './Skeleton/Skeleton';
import MemberModule from './MemberModuleOld';
import ErrorScreen from 'common/components/Errors/DefaultError';
import PreloadHeader from './TopModule/PreloadHeader';

// Navigation
import TabNavigation from 'common/components/PanelHeader/PanelHeader';

// Action Dialogs
import DischargeMember from 'components/Member/Actions/DischargeMember';
import ActivateMember from 'components/Member/Actions/ActivateMember';

// Custom tabs components
import Overview from './Modules/Overview/Overview';
import OverviewHeader from './Modules/Overview/OverviewHeader';

import Messaging from './Modules/Messaging/MessagingBody';
import MessagingHeader from './Modules/Messaging/MessagingHeader';

import Activities from 'components/Scheduler/main';
import ActivitiesHeader from './Modules/Activities/ActivitiesHeader';

import Notes from './Modules/Notes/List';
// import NoteDrafts from './Modules/Notes/Drafts/List';
// import NotesHeader from './Modules/Notes/Entry/Dialog';

import Labs from './Modules/Labs/Labs';
import LabsHeader from './Modules/Labs/LabsHeader';

// Context
import {useMemberStateContext} from 'context/Member/useMemberContext';
import ActivitiesContextProvider from '../../../context/Activities/ActivitiesContext';

// Helpers
import { mergeEpisodeNotes } from './helpers';

/**
 * Single member component used from screens/Patient/Single, screens/Family/Single and screens/Staff/Single
 * 
 * @param {*} data
 * @param {*} error
 * @param {*} refetch
 * @param {*} loading
 * @param isEditMode
 * @param overviewHeaderProps
 * @param headerProps this is used in import Header from 'common/components/PanelHeader/PanelHeader';
 * @param listTabs - listTabs contains the items from which the Tabs in the header will be created
 * or if we are in the edit view it will contain the edit tabs
 * a mapstringToComponent function will be used to map the componentId into the component definition itself
 * {
    label: "Notes",
    url: `${match.url}/notes`,
    value: 'patients/:id/notes',
    componentId: 'notes',
    permission: {
      module: "PATIENTS",
      patientId: patientId,
      perform: "VIEW_PATIENT_NOTES",
    }
  }
 * @param asideCollapseProps [Object] - this content is parsed in the left hand side content
 * example objects in the asideCollpaseProps: {
    title: 'Care Team', // todo move to translations
    name: 'careTeam',
    emptyText: 'Not yet defined.', // todo move to translations
    contentType: 'patientsList',
    list: data?.careTeamDetails,
    listProps: {
      disabled: true,
      isCareTeam: true,
      memberType: "staff",
    }
  }
 * @param dialogProps Object - in case the page contains additional dialog 
 * content is will be sent as props for the ActivateDialog component
 * example use of dialogProps:
 * const dialogProps = {
    showSuccess,
    successMessage,
    warningDetails: activationWarnings(data),
    open: activateOpen,
    title: Messages.PATIENT_ACTIVATION_TITLE,
    loading: activateLoading,
    handleAccept: handleActivate,
    handleClose: handleActivateClose,
    acceptBtnText: Messages.PATIENT_ACTIVATION_BUTTON_ACCEPT,
    text: (<Fragment>{Messages.PATIENT_ACTIVATION_DETAILS}</Fragment>),
  }
 */
const Single = ({
  data,
  error,
  refetch,
  loading,
  isEditMode,
  overviewHeaderProps = {},
  headerProps,
  moduleNavProps,
  listTabs,
  asideCollapseProps,
  dialogProps,
  actionDialogProps,
  memberId,
  memberType,
}) => {

  const location = useLocation();
  console.log('[Member Module] Raw Member Data', data);
  console.log('[Member Module] useLocation', location)

  // Data: State
  const {
    isPatient,
    isDischarged,
    hasMultiEpisodes,
    hasPastEpisode,
  } = useMemberStateContext();
  
  // Setup
  const staffMetrics = overviewHeaderProps?.textList;
  
  // State
  const [routerComponents, setRouterComponents] = useState([]);
  const [mergedNotes, setMergedNotes] = useState([]);
  
  // Data: Handlers
  // const {handleDischargeOpen} = useMemberUpdaterContext();
  
  // Handlers
  const handleNotesMerge = async (data = []) => {
    const allNotes = mergeEpisodeNotes(data);
    setMergedNotes(allNotes);
  };

  // Effect: Note Setup
  useEffect(() => {
    if (isPatient && isDischarged) {
      console.log('Discharged member');
      handleNotesMerge(data);
    } else if (isPatient && hasMultiEpisodes) {
      console.log('Multi-episode patient');
      handleNotesMerge(data);
    }
  }, [data, hasMultiEpisodes, isPatient, isDischarged]);

  // Lifecycle -- Fill routerComponents
  useEffect(() => {
    if (listTabs) {
      const mapstringToComponent = (componentId, url, component) =>
        ({
          overview: {
            url,
            headerComponent: () => <OverviewHeader staffMetrics={staffMetrics} />,
            component: () => <Overview {...{ data, asideCollapseProps }} />,
          },
          messaging: {
            url,
            headerComponent: () => <MessagingHeader />,
            component: () => <Messaging />,
          },
          activities: {
            url,
            headerComponent: () => <ActivitiesHeader />,
            component: () => <Activities _id={memberId} isEmbedded isMemberModule />,
            context: ActivitiesContextProvider,
          },
          activitiesStaff: {
            url,
            headerComponent: () => <ActivitiesHeader />,
            component: () => <Activities _id={memberId} isEmbedded isMemberModule isStaffModule />,
          },
          notes: {
            url,
            // headerComponent: () => <NotesHeader />,
            component: () => <Notes notes={hasPastEpisode ? mergedNotes : data?.notes || []} />,
          },
          // noteDrafts: {
          //   url,
          //   headerComponent: () => <NotesHeader />,
          //   component: () => <NoteDrafts loading={loading} notes={data?.notes || []} />,
          // },
          labs: {
            url,
            headerComponent: () => <LabsHeader />,
            component: () => <Labs />,
          },
          // Edit Mode
          edit: {
            url,
            headerComponent: Fragment,
            component: component,
          },
          // Staff Profiles
          schedule: {
            url,
            headerComponent: () => <OverviewHeader {...{ overviewHeaderProps }} {...data} />,
            component: () => <MemberBodySkeleton />,
          },
          }[componentId] || {
            headerComponent: Fragment,
            component: Fragment,
        });

      const componentTabs = listTabs.map(({ componentId, component, url }) => mapstringToComponent(componentId, url, component))
      if (componentTabs?.length) setRouterComponents(componentTabs)
    }
  }, [listTabs]);

  console.log('[MemberModule] listTabs', listTabs)
  // Actions: Setup
  const hasActivateDialog = !data?.activatedAccount;
  const hasDischargeDialog = data?.status?.includes('FLAGGED_FOR_DISCHARGE');
  const hasActionDialogs = hasDischargeDialog || hasActivateDialog;

  const dischargeDialogProps = {};

  const ActionDialogs = props => {
    return (
      <>
        {hasDischargeDialog ? <DischargeMember {...dischargeDialogProps} /> : null}
        {hasActivateDialog ? <ActivateMember {...dialogProps} /> : null}
      </>
    );
  };

  // Render
  if (error) {
    return <ErrorScreen />;
  }

  const moduleTabsReady = !routerComponents.length;


  // Temporary: Family Discharge. When backend updated, remove line with <TempFamilyDischarge /> component
  // const familyUINote = {
  //   actions: ['EDIT_PATIENT_DISCHARGE'],
  //   message: 'This family member has been flagged for discharge.',
  //   title: 'Discharge',
  //   type: 'warning',
  // };
  // const TempFamilyDischarge = () => {
  //   return <UINote data={data} refetch={refetch} handleDischargeOpen={handleDischargeOpen} {...familyUINote} />;
  // };
  // Temporary: Family Discharge
  console.log('routerComponents array', routerComponents);

  const background = location.state && location.state.background;

  return (
    <Fragment>

      <TabNavigation {...headerProps} tabsLoading={moduleTabsReady} />
      {/* {!isEditMode &&
        isPatient &&
        data?.uiNotes?.map((note, i) => (
          <UINote key={i} data={data} refetch={refetch} handleDischargeOpen={handleDischargeOpen} {...note} />
        ))}
      {isFamily && hasDischargeDialog && <TempFamilyDischarge />} */}

      {/* <ModuleNavigation {...moduleNavProps} /> */}
      {loading && isPatient ? <PreloadHeader memberId={memberId} memberType={memberType} /> : null}
      {loading && isPatient ? <MemberBodySkeleton /> : null}
      
      <Fragment>
        <Switch location={background || location}>
          {routerComponents.map(({ url, headerComponent, component, context }, i) => {
            const memberModuleProps = {
              data,
              // testing
              loading,
              memberId,
              memberType,
              isEditing: isEditMode, // Temporary, move the Edit functionality to newer structure as in Family
              moduleNavProps,
              headerProps,
              overviewHeaderProps,
              moduleBody: component,
              moduleHeader: headerComponent,
              moduleContext: context,
            };

            return (
              <Route exact path={url} key={`member-module-${i}`}>
                <MemberModule {...memberModuleProps} />
              </Route>
            );
          })}
        </Switch>
      </Fragment>

      {hasActionDialogs ? <ActionDialogs /> : null}
    </Fragment>
  );
};

export default Single;
