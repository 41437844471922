import React, {useState} from "react";
import { Box, Button, Grid } from "@material-ui/core";
import styles from "../PatientEdit.module.scss";
import classnames from "classnames";

import { useSnackbar } from "notistack";
import SoberCircleAutocomplete from "./SoberCircleAutocomplete";

import { useMutation } from "@apollo/client";
import { JOIN_SOBER_CIRCLE } from "data/graphql/soberCircleQueries";
import { GET_FULL_PATIENT_INFO } from "data/graphql/patients";

const Join = ({ patient }) => {

  const { enqueueSnackbar } = useSnackbar();
  const [soberCircle, setSoberCircle] = useState();

  const [ joinCircle, joinQuery ] = useMutation(JOIN_SOBER_CIRCLE, {
    onCompleted: () => {
      enqueueSnackbar("Joined!", { variant: "success" });
    },
    onError: (e) => {
      enqueueSnackbar(e && e.message ? e.message
        : "Unable to join", { variant: "error" });
    },
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient?._id } }
    ]
  });


  const onChange = value => setSoberCircle(value);

  const onSubmit = async () => {
    if (!soberCircle) return;

    await joinCircle({
      variables: {
        soberCircleId: soberCircle?._id,
        patientId: patient?._id,
      }
    });
  };
  
  console.log('[SoberCircle] Joining', soberCircle);


  return <Grid container spacing={1}>

    <Grid item xs={12}>
      <Box mb={1} className={classnames(styles.section_title)}>
        Join existing Sober Circle
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>

      <Box mb={2}>
        <SoberCircleAutocomplete
          onChange={onChange}
          value={soberCircle}/>
      </Box>
      <Box mb={2}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={joinQuery.loading || !soberCircle}
          onClick={onSubmit}>
          Join
        </Button>
      </Box>
    </Grid>
  </Grid>;
};

export default Join;