import {gql} from '@apollo/client';

export const FAMILY_FIELDS = `
  familyList {
    _id
    mrn
    firstName
    lastName
    image
    type
    activatedAccount
    program {
      name
      displayName
      caronId
    }
    relatedPatients {
      firstName
      lastName
      image
    }
    status
  }
`;
export const DISCHARGED_FAMILY_FIELDS = `
  dischargedFamilyList {
    _id
    firstName
    lastName
    mrn
  }
`;

export const GET_MEMBERS_FAMILY = gql`
  query getMembersFamily {
    getDashboardData {
      ${FAMILY_FIELDS}
    }
  }
`

export const GET_MEMBERS_DISCHARGED_FAMILY = gql`
  query getMembersDischargedFamily {
    getDashboardData {
      ${DISCHARGED_FAMILY_FIELDS}
    }
  }
`;