import { createAction } from 'redux-actions';
import moment from 'moment';

import * as types from './types';

import {
  changeWeek,
  startOfWeek,
  endOfWeek,
  parseDays,
} from 'components/Scheduler/utils/dateHelper';
import { fetchOutlookEvents, fetchGoogleEvents } from './scheduler';
import * as activityHelper from 'components/Scheduler/utils/ActivitySchedulerHelper';

import { RESCHEDULE_ACTIVITY, CLONE_ACTIVITY, ADD_ACTIVITY } from 'data/graphql/activity';

//gql
import { GET_TERAPIST_AVAIBILITY } from 'data/graphql/therapist';
import * as activityAction from 'data/graphql/activity';

/**
 * TODO
 * clear out all the unused actions due to the bookercontext now that when the callflow is cleared,
 */
// Actions
export const clearState = createAction(types.CLEAR_STATE);
export const setSending = createAction(types.SET_SENDING);
export const selectMember = createAction(types.SELECT_MEMBER);
export const setCallDuration = createAction(types.SET_CALL_DURATION);
export const setSelectedActivity = createAction(types.SET_RESCHEDULED_ACTIVITY);
export const toggleMSCHVisibility = createAction(types.TOGGLE_MSCH_VISIBILITY);
export const setDays = createAction(types.SET_DAYS, days => ({ days }));
export const setBookerViewName = createAction(types.SET_BOOKER_VIEW_NAME);
export const setLoading = createAction(types.SET_LOADING, loading => ({
  loading,
}));
export const setInvitedMembers = createAction(types.SET_INVITED_MEMBERS, relatedInvitedMembers => {
  return relatedInvitedMembers;
});
export const setActivitiesLoading = createAction(types.SET_ACTIVITIES_LOADING, activitiesLoading => ({
  activitiesLoading,
}));
export const setCurrentDate = createAction(types.SET_DATE, currentDate => ({
  currentDate,
}));
export const setRecurrence = createAction(types.SET_RECURRENCE, recurrence => ({
  recurrence,
}));
export const setStaffList = createAction(types.SET_STAFF);
export const setHost = createAction(types.SET_HOST, host => ({
  host,
}));
export const setMultiSchedulerActivities = createAction(types.SET_MULTI_SCHEDULER_ACTIVITIES, allData => ({ allData }));
export const resetMultiSchedulerActivities = createAction(types.RESET_MULTI_SCHEDULER_ACTIVITIES);

export const setSelectedTime = createAction(types.SET_SELECTED_TIME, time => {
  return { selectedTime: time };
});
export const setTemplateCode = createAction(types.SET_TEMPLATE_CODE, templateCode => ({
  templateCode,
}));
// Thunks
export const getTherapistAvailability = (client, selectedDate) => async dispatch => {
  const start = startOfWeek(selectedDate).toDate();
  const end = endOfWeek(selectedDate).toDate();
  try {
    dispatch(setLoading(true));
    const { data /* , loading, error */ } = await client.query({
      query: GET_TERAPIST_AVAIBILITY,
      variables: {
        startDate: start,
        endDate: end,
        duration: 30,
      },
      fetchPolicy: 'network-only',
    });
    // console.log('[getTherapistAvailability]', data);
    const days = parseDays(data.getTherapistAvailability, start, end);
    dispatch(setDays(days));
    dispatch(setLoading(false));
  } catch (err) {
    console.error(err);
  }
};

export const scheduleByTemplate = ({
  client,
  selectedTime,
  mainPatient,
  duration,
  enqueueSnackbar,
  invitedMembers,
  webexLink,
  recurrence,
  cohosts,
  host,
  templateCode,
  description,
}) => async (dispatch, getState) => {
  try {
    console.log(
      'appointmentbooker scheduleByTemplate',
      selectedTime,
      mainPatient,
      duration,
      invitedMembers,
      webexLink,
      recurrence,
      host,
      templateCode,
      cohosts
    );
    dispatch(setSending(true));
    let prefix = getState().dashboard?.dashboardData?.organization?.setup?.find(s => s.name === 'webexLinkPrefix');
    let currentUser = getState().dashboard?.dashboardData?.user;
    const variables = {
      activityDetails: {
        duration,
        startDate: moment(selectedTime).format(),
        invitedMembers,
        templateCode,
        description,
        webexLink: prefix && webexLink ? `${prefix.value}${webexLink}` : webexLink,
        recurrence: recurrence ? recurrence : null,
        host: host,
        regardingMember: mainPatient,
        cohosts,
      },
    };
    const { data } = await client.mutate({
      mutation: ADD_ACTIVITY,
      variables,
    });
    enqueueSnackbar('Call Scheduled!', { variant: 'success' });
    dispatch(setSending(false));

    return data.addActivity;
  } catch (error) {
    console.log('Error: Call was not scheduled', error, error.message);
    enqueueSnackbar(error.message || 'Error: Call was not scheduled.', { variant: 'error' });
    dispatch(setSending(false));
  }
};

export const reschedule = (
  client,
  selectedDate,
  initialActivity,
  createNewSession,
  duration,
  enqueueSnackbar
) => async dispatch => {
  dispatch(setSending(true));
  // BAD useability this sometimes arrives with initialActivity._id and sometimes with initialActivity.id
  let id = initialActivity?._id ? initialActivity?._id : initialActivity?.id;
  const scheduling = {
    start: moment(selectedDate).format(),
    end: moment(moment(selectedDate).valueOf() + duration * 60000).format(),
    duration,
    repeat: 'none',
    onlyWeekDays: true,
  };

  if (createNewSession) {
    try {
      await client.mutate({
        mutation: CLONE_ACTIVITY,
        variables: {
          activityId: id,
          scheduling,
        },
      });
      enqueueSnackbar('Call is scheduled', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar("Call isn't scheduled. " + error, { variant: 'error' });
    }
    // dispatch(toBookNextCall(selectedDate));
  } else {
    try {
      console.log('RESCHEDULE_ACTIVITY', {
        variables: {
          activityId: id,
          scheduling,
        },
      });
      await client.mutate({
        mutation: RESCHEDULE_ACTIVITY,
        variables: {
          activityId: id,
          scheduling,
        },
      });
      enqueueSnackbar('Call is rescheduled', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar("Call isn't rescheduled. " + String(error).split(':')[2], { variant: 'error' });
    }
  }
  dispatch(setSelectedTime(selectedDate));
  dispatch(setSending(false));
};

export const getMultiSchedulerActivities = (
  client,
  currentDate,
  currentViewName,
  type,
  patient,
  staff
) => async dispatch => {
  dispatch(setActivitiesLoading(true));
  currentDate = currentDate ? currentDate : moment();
  const variables = activityHelper.makeQueryParams(
    currentDate,
    currentViewName,
    staff ? 'staff' : 'patient',
    staff ? staff : patient
  );
  console.log(
    '[Actions > MultiScheduler]: makeQueryParams for GET_ACTIVITIES query. variables: ',
    variables,
    'type: ',
    type,
    'patient: ',
    patient,
    'staff: ',
    staff,
    'currentViewName',
    currentDate,
    currentViewName
  );
  const { data } = await client.query({
    query: activityAction.GET_ACTIVITIES,
    variables,
    fetchPolicy: 'no-cache',
  });
  const isStaff = staff ? true : false;
  let formattedData = [];
  let outlookEvents;
  let googleEvents;
  if (isStaff) {
    try {
      outlookEvents = await fetchOutlookEvents(client, currentDate, currentViewName, staff, 'staff');
    } catch (error) {
      console.log('[GraphQL fetch outlook error:] ', error);
    }
    try {
      googleEvents = await fetchGoogleEvents(client, currentDate, currentViewName, staff, 'staff');
    } catch (error) {
      console.log('[GraphQL fetch google error:] ', error);
    }
  }
  if (currentViewName === 'Month')
    // TODO create appointment layout for external calendars then uncomment line below
    // formattedData = [activityHelper.formatDataToMonthView(data.activities), outlookEvents.getOutlookEvents.map(activityHelper.mapExternalEventsData)].flat();
    formattedData = activityHelper.formatDataToMonthView(data.activities);
  else {
    formattedData = [
      data?.activities?.map(activityHelper.mapAppointmentData),
      ...(isStaff ? outlookEvents?.getOutlookEvents?.map(activityHelper.mapExternalEventsData) : []),
      ...(isStaff ? googleEvents?.getGoogleEvents?.map(activityHelper.mapExternalEventsData) : []),
    ].flat();
  }

  const finalData = activityHelper.filterOutDuplicateEvents(
    activityHelper.sortData({
      allData: formattedData,
      type,
      patient,
      outlookCalendar: true,
      googleCalendar: true,
      myAvailability: true,
      isStaffModule: staff ? true : false,
    })
  );
  dispatch(setMultiSchedulerActivities(finalData));
  dispatch(setActivitiesLoading(false));
};

export const loadDataFromServer = (
  client,
  selectedMember,
  invitedMembers,
  host,
  cohosts,
  hasRegardingMember,
  currentDate,
  currentViewName
) => async (dispatch, getState) => {
  dispatch(setActivitiesLoading(true));
  dispatch(resetMultiSchedulerActivities());
  currentDate = !currentDate ? getState().appointmentBooker.currentDate : currentDate;
  currentViewName = !currentViewName ? getState().appointmentBooker.currentViewName : currentViewName;

  if (!hasRegardingMember) {
    dispatch(getMultiSchedulerActivities(client, currentDate, currentViewName, false, selectedMember));
  }

  invitedMembers.forEach(memberId => {
    dispatch(getMultiSchedulerActivities(client, currentDate, currentViewName, false, memberId));
  });

  if (host) dispatch(getMultiSchedulerActivities(client, currentDate, currentViewName, 'mySessions', null, host));

  //foreach cohost
  cohosts.forEach(staff => {
    dispatch(getMultiSchedulerActivities(client, currentDate, currentViewName, 'mySessions', null, staff._id));
  });
};
