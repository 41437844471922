import React from 'react';
import {
  Radio,
  MenuItem,
  TextField,
  RadioGroup,
  FormControl,
  Select,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import InputMask from 'react-input-mask';
import { Field, FieldArray } from 'formik';
import ChipInput from 'material-ui-chip-input';
import Datepicker from 'common/components/InputFields/KeyboardDatepicker/Datepicker';
import Autocomplete from 'common/components/PanelFilters/Form/FormikAutocomplete/FormikAutocomplete';
import PhoneInputField from 'common/components/InputFields/PhoneInput/PhoneInputField';
import RangeSlider from 'common/components/InputFields/Slider/Slider';

const useStyles = makeStyles({
  formControlLabel: {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '6px',
  },
  formControlWrapper: {
    display: 'flex',
    minHeight: '53px',
    alignItems: 'flex-end',
  },
  noteLabel: {
    fontFamily: 'Avenir Next Medium',
    marginBottom: '10px',
  },
});

const FormBuilderInput = ({
  mask,
  multiple,
  render,
  readOnly,
  disabled,
  placeholder,
  type,
  title,
  name,
  options,
  values,
  handleChange,
  setFieldValue,
  errors,
  setErrors,
  disabledCondition,
  hiddenCondition,
  labelClass,
  labelColumn,
  disableFuture,
  ...additionalProps
}) => {
  const classes = useStyles();

  if (render) {
    return render;
  }

  let Input;
  let hasLabel = true;

  const sharedProps = {
    name,
    disabled,
    placeholder,
    value: values[name],
  };

  const radiosProps = {
    ...sharedProps,
    onChange: handleChange(name),
  };

  const fieldProps = {
    ...sharedProps,
    fullWidth: true,
    variant: 'outlined',
    component: TextField,
    disabled: disabledCondition ? disabledCondition(values) : sharedProps.disabled,
    onChange: handleChange(name),
  };

  if (readOnly) {
    fieldProps.InputProps = { readOnly: true };
  }

  const autocompleteProps = {
    ...sharedProps,
    fullWidth: true,
    options,
    setFieldValue,
    ...(multiple ? { multiple: true } : {}),
  };
  console.log('autocompleteProps formbuilder', autocompleteProps, JSON.stringify(setFieldValue));
  const selectProps = {
    ...sharedProps,
    onChange: handleChange(name),
  };

  const checkboxProps = {
    checked: values[name],
    onChange: handleChange(name),
  };

  const phoneInputProps = {
    field: { name, value: fieldProps.value },
    form: {
      errors,
      setFieldValue,
    },
    ...fieldProps,
  };

  const sliderProps = {
    field: { name, value: fieldProps.value },
    form: {
      errors,
      setFieldValue,
    },
    ...fieldProps,
  };

  const datePickerProps = {
    ...additionalProps,
    value: fieldProps.value,
  };
  const isHidden = hiddenCondition ? hiddenCondition(values) : false;

  switch (type) {
    case 'password':
      Input = <Field type="password" {...fieldProps} />;
      break;

    case 'email':
      Input = <Field {...fieldProps} />;
      break;

    case 'date':
      Input = <Field name={name} component={Datepicker} disableFuture={disableFuture} {...datePickerProps} />;
      break;

    case 'radio':
      Input = (
        <RadioGroup row {...radiosProps}>
          {options.map((option, i) => (
            <FormControlLabel key={i} {...option} control={<Radio color="primary" />} />
          ))}
        </RadioGroup>
      );
      break;

    case 'autocomplete':
      Input = <Autocomplete {...autocompleteProps} />;
      break;

    case 'select':
      Input = (
        <FormControl fullWidth variant="outlined">
          <Select {...selectProps}>
            {options.map((val, i) => (
              <MenuItem key={i} value={val} children={val} />
            ))}
          </Select>
        </FormControl>
      );
      break;

    case 'checkbox':
      hasLabel = false;
      if (labelClass && labelColumn) {
        Input = (
          <>
            <div className={`${labelClass ? labelClass : classes.formControlLabel}`}>
              {title}
              {labelColumn ? ':' : ''}
            </div>
            <Checkbox color="primary" {...checkboxProps} />
          </>
        );
      } else {
        Input = <FormControlLabel label={title} control={<Checkbox color="primary" {...checkboxProps} />} />;
      }
      break;

    case 'masked':
      Input = (
        <InputMask mask={mask} maskChar="_" {...fieldProps}>
          {maskProps => <Field {...maskProps} />}
        </InputMask>
      );
      break;
    case 'phoneinput':
      Input = <PhoneInputField {...phoneInputProps} />;
      break;
    case 'slider':
      Input = <RangeSlider {...sliderProps}></RangeSlider>;
      break;
    case 'chipinput':
      Input = (
        <FieldArray
          name={name}
          render={({ push, remove }) => (
            <Field
              name={name}
              render={() => (
                <ChipInput
                  value={values[name]}
                  onAdd={chip => push(chip)}
                  fullWidth
                  variant="outlined"
                  onDelete={chip => {
                    const prevErrors = { ...errors };
                    const chipIndex = values[name].findIndex(c => c === chip);
                    remove(chipIndex);
                    setErrors(prevErrors);
                  }}
                />
              )}
            />
          )}
        />
      );
      break;

    default:
      Input = <Field {...fieldProps} />;
      break;
  }

  return (
    <React.Fragment>
      {!isHidden && (
        <>
          {hasLabel && title && (
            <div className={`${labelClass ? labelClass : classes.formControlLabel}`}>
              {title}
              {labelColumn ? ':' : ''}
            </div>
          )}
          <div className={classes.formControlWrapper}>{Input}</div>
        </>
      )}
    </React.Fragment>
  );
};

export default FormBuilderInput;
