import React from 'react';
import moment from 'moment';

// Material
import { makeStyles } from '@material-ui/core/styles';

// Components
import ReactSVG from 'react-svg';
import IconSad from 'common/assets/conditions/bwsad.svg';
import IconHappy from 'common/assets/conditions/happy-green.svg';
import IconConfused from 'common/assets/conditions/bwconfused.svg';

// Context
import { useMemberStateContext } from 'context/Member/useMemberContext';

const OverviewHeader = ({staffMetrics}) => {
  const classes = useStyles();
  const {memberData, isPatient, isFamily, isStaff} = useMemberStateContext();
  
  // Setup
  const isMember = isPatient || isFamily;
  const avgRating = memberData?.averageRating ? memberData?.averageRating.toFixed(1) : 0;
  const mapStateToIcon = emotion =>
    ({
      HAPPY: IconHappy,
      SAD: IconSad,
    }[emotion] || IconConfused);

    const showRating = memberData?.activatedAccount;
  const showEmotionalState = memberData?.activatedAccount;
  const showLastUsed = memberData?.lastUsed === 'Invalid date' || memberData?.lastUsed === null;
  const showStaffMetrics = isStaff && staffMetrics;

  // const programTimeText = () => {
  //   if (!admitDate || admitDate === 'Invalid date') return { value: 'N/A', timeMeasuredIn: '' };
  //   const nrOfMonthsFloat = moment().diff(admitDate, 'months', true);
  //   const numberOfMonthsInt = moment().diff(admitDate, 'months');
  //   const numberOfDays = moment().diff(admitDate, 'days');
  //   const numberOfYears = Math.floor(moment().diff(admitDate, 'months') / 12);
  //   const numberOfMonthsRemained = moment().diff(admitDate, 'months') % 12;

  //   if (nrOfMonthsFloat && nrOfMonthsFloat < 1)
  //     return {
  //       value: numberOfDays,
  //       timeMeasuredIn: numberOfDays === 1 ? 'day' : 'days',
  //     };
  //   if (nrOfMonthsFloat && nrOfMonthsFloat < 13)
  //     return {
  //       value: numberOfMonthsInt,
  //       timeMeasuredIn: numberOfMonthsInt === 1 ? 'month' : 'months',
  //     };
  //   if (nrOfMonthsFloat && nrOfMonthsFloat > 13) {
  //     return {
  //       value: numberOfYears,
  //       timeMeasuredIn: numberOfYears === 1 ? 'year' : 'years',
  //       additionalTime:
  //         numberOfMonthsRemained === 0
  //           ? null
  //           : `${numberOfMonthsRemained} ${numberOfMonthsRemained === 1 ? 'month' : 'months'}`,
  //     };
  //   }
  // };

  return (
    <div className={classes.root}>
      {isMember && (
        <div className={classes.stateItem}>
          {showEmotionalState
            ? <ReactSVG src={mapStateToIcon(memberData?.emotionalState)} className={classes.stateIcon} />
            : <div className={classes.stateText}>N/A</div>
          }
          <div className={classes.stateTitle}>
            Emotional
            <br />
            State
          </div>
        </div>
      )}

      {isMember && (
        <div className={classes.stateItem}>
          {showRating 
            ? <div className={classes.stateText}>{avgRating}</div>
            : <div className={classes.stateText}>N/A</div>
          }
          <div className={classes.stateTitle}>
            Average
            <br />
            Rating
          </div>
        </div>
      )}

      {isPatient && (
        <div className={classes.stateItem}>
          <div className={classes.stateText}>
          {showLastUsed 
            ? '0' 
            : moment().diff(memberData?.lastUsed, 'days')
          }
          </div>
          <div className={classes.stateTitle}>
            Days
            <br />
            Sober
          </div>
        </div>
      )}
      
      {/* 
      {isMember && admitDate && (
        <div className={classes.stateItem}>
          <div className={classes.stateText}>{programTimeText().value}</div>
          <div className={classes.stateTitle}>
            {programTimeText().timeMeasuredIn}
            <br />
            {programTimeText().additionalTime && <div>{programTimeText().additionalTime}</div>}
            in {program.displayName ? program.displayName : program.name}
          </div>
        </div>
      )} */}

      {showStaffMetrics &&
        staffMetrics.map(({ value, title }, i) => (
          <div className={classes.stateItem} key={i}>
            <div className={classes.stateText}>{value}</div>
            <div className={classes.stateTitle}>{title}</div>
          </div>
        ))}
    </div>
  );
};

export default OverviewHeader;

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  stateItem: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 120,
  },
  stateIcon: {
    height: '45px',
    '& g': {
      fill: '#828282',
    },
  },
  stateTitle: {
    color: '#828282',
    fontSize: '14px',
    lineHeight: '19px',
    fontFamily: 'Avenir Next Medium',
  },
  stateText: {
    fontSize: '40px',
    color: '#828282',
    lineHeight: '45px',
    fontFamily: 'Avenir Next Medium',
  },
}));