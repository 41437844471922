import React from 'react';

import {
  List, Box,
  ListItem, ListItemIcon,
} from '@material-ui/core';

import cn from 'classnames';
import moment from 'moment';
import Svg from 'react-svg';
import { Skeleton } from '@material-ui/lab';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

import { GET_ACTIVITIES } from 'data/graphql';

import CoreIcon from 'common/assets/activity/core.svg';
import PersonalIcon from 'common/assets/activity/personal.svg';
import TherapyIcon from 'common/assets/activity/therapy-session.svg';
import LabActivityDetails from "common/components/Labs/LabActivityDetails";
import { reverseSortCallsByTime } from 'common/common';
import { useQuery } from '@apollo/client';

const startDate = moment().subtract(1, 'months').format();
const endDate = moment().format();

const LatestActivities = ({props}) => {
  
  const classes = useStyles();

  const {data, loading, error} = useQuery(GET_ACTIVITIES, {
    variables: {
      filters: {
        patientId: [props?.id],
        startDate,
        endDate,
      },
    },
    pollInterval: 2 * 60000,
  })

  const activities = data?.activitiesQuery || [];

  let renderActivities = (
    <Box pt={3} pb={3}>
      {Array(4).fill().map((_, i) => (
        <Box display="flex" alignItems="center" key={i}>
          <Box pl={3} pr={3}>
            <Skeleton animation="wave" variant='circle' width={60} height={60} />
          </Box>
          <Box pr={8} style={{ flexGrow: '1' }}>
            <Skeleton animation="wave" variant='text' width='100%' height={30} />
            <Skeleton animation="wave" variant='text' width={180} height={15} />
          </Box>
        </Box>
      ))}
    </Box>
  )

  if (error) {
    renderActivities = (
      <div className={classes.msgStyle}>An error occured</div>
    )
  }

  if (!loading && !activities?.length) {
    renderActivities = (
      <div className={classes.msgStyle}>No activities yet</div>
    )
  }

  if (!loading && activities?.length) {
    const icon = type => (
      <ListItemIcon children={
        <Svg
          alt='icon'
          className={classes.listItemIcon}
          src={{ core: CoreIcon, personal: PersonalIcon }[type] || TherapyIcon}
        />
      } />
    )

    renderActivities = (
      <List className={classes.root}>
        {reverseSortCallsByTime(activities).map(({
          _id, patientParticipationInfo,
          name, type, scheduling: { end }, lab,
        }) => {
          const date = moment(end).format('MMM DD,YYYY - hh:mm a');

          let renderStatus = <div className={cn(classes.listItemStatus, 'noRating')}>No rating</div>;

          if (!patientParticipationInfo) {
            renderStatus = <div className={cn(classes.listItemStatus, 'missed')}> Missed</div>;
          };

          if (patientParticipationInfo?.feedback?.rating) {
            renderStatus = <Rating readOnly value={patientParticipationInfo.feedback.rating} />;
          };

          return (
            <ListItem className={classes.listItem} key={_id}>
              {icon(type)}
              <div className={classes.listItemContent}>
                <Box mr='auto'>
                  <div className={classes.listItemTitle}>{name}</div>
                  <div className={classes.listItemDate}>{date}</div>
                </Box>
                <div style={{ textAlign: "center" }}>
                  {lab ? <LabActivityDetails lab={lab}></LabActivityDetails> : renderStatus}
                </div>
              </div>
            </ListItem>
          )
        })}
      </List>
    )
  }

  return renderActivities;
}

export default LatestActivities;



const useStyles = makeStyles({
  root: {

  },
  listItem: {

  },
  listItemIcon: {
    width: 50,
    height: 50,
    display: 'flex',
    marginRight: 15,
    alignItems: 'center',
    borderRadius: '100%',
    justifyContent: 'center',
    backgroundColor: 'rgba(42, 150, 255, 0.07)',
  },
  listItemContent: {
    flexGrow: 1,
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    padding: '20px 30px',
    borderRadius: '10px',
    border: '1px solid #E0E0E0',
  },
  listItemTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: 'Avenir Next Medium',
  },
  listItemDate: {
    opacity: '.5',
    fontSize: '14px',
    lineHeight: '19px',
  },
  listItemStatus: {
    minWidth: 100,
    fontSize: '15px',
    lineHeight: '20px',
    fontFamily: 'Avenir Next Regular',
    '&.missed': {
      color: '#E02020',
    },
    '&.noRating': {
      opacity: '.5',
    },
  },
  actionText: {
    color: 'rgba(0,0,0,0.4)',
    fontStyle: "oblique"
  },
  msgStyle: {
    fontSize: 24,
    opacity: '.6',
    margin: 'auto',
  },
});
