import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';

// Utils
import Can from 'hoc/Can';
import { useRouteMatch } from 'react-router-dom';
import { Messages } from 'config/settings';
import { activationWarnings } from './helpers';
import { useSnackbar } from 'notistack';

// Components
import MemberSingle from 'components/Member/View/ViewOld';
import EmptyState from 'common/components/EmptyState/EmptyState';

// GraphQL
import { useMutation } from '@apollo/client';
import { 
  ACTIVATE_MEMBER, 
  RESEND_ACTIVATE_MEMBER 
} from 'data/graphql/member';

// Redux
import { useSelector } from 'react-redux';

// Context
import {useMemberStateContext, useMemberUpdaterContext} from 'context/Member/useMemberContext';
import useAccountContext from 'context/Account/useAccountContext';

// Util
import { pluralize } from 'common/helpers/textHelpers';

const FamilyMember = ({ memberId, status, activatedAccount }) => {

  // Router
  const match = useRouteMatch();
  const currentPath = match.url;
  const baseUrl = '/family'
  console.log('RouterHooks: currentPath', currentPath)

  // Dialog -- Data
  const [showSuccess, setShowSuccess] = useState(false);
  const [activateOpen, setActivateOpen] = useState(false);

  // Dialog -- Methods
  const handleActivateOpen = () => setActivateOpen(true);
  const handleActivateClose = () => setActivateOpen(false);

  const refetchDashboard = useSelector(state => state.dashboard.refetchFn);
  
  const [listTabs, setListTabs] = useState([]);
  
  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Data
  const {
    memberData: data,
    familyLoading: loading,
    familyError: error,
  } = useMemberStateContext();
  const {
    familyRefetch: refetch
  } = useMemberUpdaterContext();

  const {organizationName} = useAccountContext();
  const isActivated = activatedAccount || data?.activatedAccount;
  const isDischarged = status === 'DISCHARGED' || data?.status === 'DISCHARGED';

    
  useEffect(() => {
    setListTabs([
      {
        label: 'Overview',
        componentId: 'overview',
        url: `${baseUrl}/${memberId}`,
        value: '/family/:id',
      },
      ...(isDischarged ? [
            {
              label: 'Notes',
              componentId: 'notes',
              url: `${baseUrl}/${memberId}/notes`,
              value: '/family/:id/notes',
            },
          ] : []),
      ...(isActivated && !isDischarged
        ? [
            {
              label: 'Messaging',
              componentId: 'messaging',
              url: `${baseUrl}/${memberId}/messaging`,
              value: '/family/:id/messaging',
            },
            {
              label: 'Schedule',
              componentId: 'activities',
              url: `${baseUrl}/${memberId}/activities`,
              value: '/family/:id/activities',
            },
            {
              label: 'Notes',
              componentId: 'notes',
              url: `${baseUrl}/${memberId}/notes`,
              value: '/family/:id/notes',
            },
          ]
        : []),
    ]);
  }, [data]);

  const showResendBtn = data?.cognitoInfo?.status === 'FORCE_CHANGE_PASSWORD';

  // Dialog -- Activate mutation
  const [handleActivate, { loading: activateLoading }] = useMutation(ACTIVATE_MEMBER, {
    variables: { memberId: data?._id },
    onCompleted: () => {
      setShowSuccess(true);

      setTimeout(() => {
        refetch();
        refetchDashboard();
      }, 250);

      setTimeout(() => handleActivateClose(), 3000);
      setTimeout(() => setShowSuccess(false), 3500);
    },
  });

  // Dialog -- Resend activation mutation
  const [handleResendActivation, { error: resendError }] = useMutation(RESEND_ACTIVATE_MEMBER, {
    variables: { memberId: data?._id },
    onCompleted: ({ resendCognitoInvitation }) => {
      if (resendCognitoInvitation) {
        enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_SUCCESS, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
          variant: 'error',
        });
      }
    },
  });

  // Lifecycle -- resendError update listener
  useEffect(() => {
    if (resendError)
      enqueueSnackbar(Messages.MEMBER_RESEND_INVITATION_ERROR, {
        variant: 'error',
      });
  }, [resendError]);

  // Methods
  const generateOverviewAside = () => {
    const city = (data.address.city ? `${data.address.city} ` : '') + (data.address?.state ? data.address.state : '');

    const ageGender = [
      data.birthDate && moment().diff(moment(data.birthDate), 'years'),
      data.gender && data.gender?.toUpperCase(),
    ].filter(i => !!i).join(' / ');

    const overviewTable = [
      {
        title: 'Phone',
        value: data.phone ? data.phone : 'N/A',
      },
      {
        title: 'City',
        value: data.address?.city ? city : 'N/A',
      },
      {
        title: 'Age / Sex',
        value: (
          <div>
            {ageGender} 
            {data.birthDate ? 
              <span style={{ marginLeft: 10, color: 'rgba(0, 0, 0, 0.6)' }}>
                (DOB: {data.birthDate})
              </span> 
           : null}
          </div>
        ),
      },
      {
        hasSublist: true,
        title: organizationName,
        value: [
          {
            title: 'MRN',
            value: data.mrn ? data.mrn : 'N/A',
          },
          {
            title: 'Admit Date',
            value: data.admitDate ? data.admitDate : 'N/A',
          },
        ],
      },
    ];

    return overviewTable;
  };

  // Render
  const headerProps = {
    listBreadcrumbs: [
      { name: 'Family', url: '/family' },
      { name: 'Profile', disabled: true, url: '' },
    ],
    listTabs,
    overflowMenu: [
      {
        name: 'Edit Profile',
        src: {to: `${baseUrl}/${memberId}/edit`, state: {isEditing: true}},
        // permission: {
        //   perform: 'EDIT_FAMILY',
        //   patientId: data?._id,
        //   module: 'FAMILY',
        // },
      },
      ...(!isActivated
        ? [
            {
              name: 'Activate Member',
              onClick: handleActivateOpen,
              // permission: {
              //   perform: 'ACTIVATE_FAMILY',
              //   patientId: data?._id,
              //   module: 'FAMILY',
              // },
            },
          ]
        : []),
      ...(showResendBtn
        ? [
            {
              name: Messages.MEMBER_RESEND_INVITATION_BUTTON,
              onClick: handleResendActivation,
              // permission: {
              //   perform: 'RESEND_FAMILY_INVITE',
              //   patientId: data?._id,
              //   module: 'FAMILY',
              // },
            },
          ]
        : []),
    ],
  };

  const overviewHeaderProps = {
    hasRating: true,
    hasPatients: true,
    hasEmotionState: true,
    hasProgramTime: true,
    showAddActivity: true,
    showCallButton: true,
    showSendNotification: true,
    progressData : {
      goals: isActivated ? 30 : 0,
      engagement: isActivated ? 60 : 0,
    },
  };

  const asideCollapseProps = [
    {
      title: 'Overview', // todo move to translations
      name: 'overview',
      contentType: 'overview',
      list: !!data ? generateOverviewAside() : [],
    },
    {
      title: 'Care Team', // todo move to translations
      name: 'careTeam',
      emptyText: 'Care Team not yet assigned.', // todo move to translations
      contentType: 'memberList',
      list: data?.careTeamDetails,
      listProps: {
        isCareTeam: true,
        memberType: 'staff',
      },
    },
    {
      title: 'Family', // todo move to translations
      name: 'family',
      contentType: 'groupedMemberList',
      list: [
        {
          title: `${pluralize("Caron Patient", data?.relatedPatients)}`, // todo move to translations
          list: data?.relatedPatients,
          emptyText: 'Not yet defined.',
          listProps: {
            showContact: true,
            memberType: 'patient',
          },
        },
        {
          title: `${pluralize("Family Member", data?.relatedFamily)}`, // todo move to translations
          list: data?.relatedFamily,
          emptyText: 'Not yet defined.', // todo move to translations
          listProps: {
            isFamily: true,
            memberType: 'family',
          },
        },
      ],
    },
  ];

  // Dialog -- Component props
  const dialogProps = {
    title: Messages.FAMILY_ACTIVATION_TITLE,
    acceptBtnText: Messages.FAMILY_ACTIVATION_BUTTON_ACCEPT,
    successMessage: {
      title: Messages.FAMILY_ACTIVATION_SUCCESS_TITLE,
      text: Messages.FAMILY_ACTIVATION_SUCCESS_TEXT,
    },
    showSuccess,
    open: activateOpen,
    loading: activateLoading,
    handleAccept: handleActivate,
    handleClose: handleActivateClose,
    warningDetails: activationWarnings(data),
    text: <Fragment>{Messages.FAMILY_ACTIVATION_DETAILS}</Fragment>,
  };

  const memberSingleProps = {
    memberId: memberId,
    memberType: 'family',
    data,
    error,
    loading,
    refetch,
    headerProps,
    overviewHeaderProps,
    listTabs,
    asideCollapseProps,
    dialogProps,
  };

  return <Can
    module="FAMILY"
    perform="VIEW_FAMILY"
    memberId={memberId}
    memberType="family"
    policy="module_action"
    origin="Family Module"
    yes={() => <MemberSingle {...memberSingleProps} />}
    no={() => <EmptyState title="Sorry, can't open this 😕" text="You don't have access to this member"/>}
  />
};

FamilyMember.propTypes = {};

export default FamilyMember;