import React, { useEffect } from 'react';
import { Messages } from 'config/settings';

// Components
import MemberList from 'components/Member/List/List';

// Data
import { GET_MEMBERS_DISCHARGED_PATIENTS } from 'data/graphql/members/patients';
import useMembersContext from 'context/Members/useMembersContext';

const PatientList = () => {

  const {
    dischargedPatients: data,
    loadingDischargedPatients: dataLoading,
    errorDischargedPatients: dataError,
    calledGetDischargedPatients: dataQueryCalled,
  } = useMembersContext();
  
  const filterQuery = {
    reqQuery: GET_MEMBERS_DISCHARGED_PATIENTS,
    queryName: 'dischargedPatients',
  };

  const headerProps = {
    listBreadcrumbs: [
      {
        name: 'Discharged Patients',
        url: '/patients/discharged',
        disabled: true,
      },
    ],
  };

  const filtersProps = {
    data: data,
    hasSearch: true,
    filterQuery: filterQuery,
    searchLocalBy: ['firstName', 'lastName', 'mrn'],
  };

  const memberListProps = {
    data,
    dataLoading,
    dataQueryCalled,
    dataError,

    headerProps,
    filtersProps,
    baseUrl: '/patients',

    emptyData: {
      title: Messages.PATIENT_LIST_EMPTY_TITLE,
      text: Messages.PATIENT_LIST_EMPTY_TEXT,
    },

    emptySearch: {
      title: Messages.PATIENT_LIST_SEARCH_EMPTY_TITLE,
      text: Messages.PATIENT_LIST_SEARCH_EMPTY_TEXT,
    },
    useBaseMemberRoute: true,
    baseMemberRoute: '/patient',
  };

  return <MemberList {...memberListProps} />;
};

export default PatientList;
