import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const Loading = () => {
  const classes = useStyles();
	return <Skeleton className={classes.skeleton} />;
}

const useStyles = makeStyles({
  skeleton: {
    width: '100%',
    height: '20px',
  },
});

export default Loading;