import { handleActions } from 'redux-actions';
import * as types from 'actions/types';
import { getCurrentDate } from 'components/Scheduler/utils/ActivitySchedulerHelper';

const initialState = {
  loading: true,

  data: [],
  allData: [],

  currentDate: getCurrentDate(),
  currentViewName: 'Month',

  tooltipVisible: false,
  confirmationVisible: false,
  editingFormVisible: false,
  reschedulingVisible: false,

  startDayHour: 7,
  endDayHour: 24,

  deletedAppointmentId: undefined,
  editingAppointmentId: undefined,
  previousAppointmentId: undefined,

  appointmentToBeCanceled: undefined,
  deletedAppointmentFlag: undefined,

  editingAppointment: {},
  addedAppointment: {},
  isNewAppointment: false,

  type: null,
  patient: null,

  mySchedule: true,
  myAvailability: false,
  googleCalendar: false,
  outlookCalendar: false,
};

export default handleActions(
  {
    [types.CLEAN_REDUCER_STATE]: () => initialState,

    [types.SET_ACTIVITIES]: (state, { payload }) => ({ ...state, ...payload }),
    [types.TOGGLE_LOADING]: (state, { payload }) => ({ ...state, ...payload }),
    [types.FILTER_OUT_ACTIVITY]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.FILTER_CHANGE]: (state, { payload }) => ({ ...state, ...payload }),
    [types.CHANGE_FIELD_ID]: (state, { payload }) => ({ ...state, ...payload }),
    [types.SET_IS_NEW_APPOINTMENT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_ADDED_APPOINTMENT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_CURRENT_DATE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.SET_VIEW_NAME]: (state, { payload }) => ({ ...state, ...payload }),
    [types.ADD_APPOINTMENT]: (state, { payload }) => ({ ...state, ...payload }),
    [types.CANCEL_APPOINTMENT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.EDIT_APPOINTMENT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),

    [types.TOGGLE_MY_SCHEDULE]: (state, { payload }) => ({
      ...state,
      mySchedule: payload,
    }),
    [types.TOGGLE_VISIBILITY]: (state, { payload }) => ({
      ...state,
      [payload]: !state[payload],
    }),
    [types.TOGGLE_MY_AVAILABILITY]: (state, { payload }) => ({
      ...state,
      myAvailability: payload,
    }),
    [types.TOGGLE_GOOGLE_CALENDAR]: (state, { payload }) => ({
      ...state,
      googleCalendar: payload,
    }),
    [types.TOGGLE_OUTLOOK_CALENDAR]: (state, { payload }) => ({
      ...state,
      outlookCalendar: payload,
    }),
    [types.SET_EDITING_ACTIVITY]: (state, { payload }) => ({
      ...state,
      editingAppointment: payload,
    }),
    [types.SET_APPOINTMENT_TO_BE_CANCELED]: (state, { payload }) => ({
      ...state,
      appointmentToBeCanceled: payload,
    }),
    [types.SET_APPOINTMENT_TO_BE_CANCELED_FLAG]: (state, { payload }) => ({
      ...state,
      deletedAppointmentFlag: payload,
    }),
  },
  initialState
);
