import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const BookingPreview = ({ selectedTime, duration }) => {

  const classes = useStyles();
  const startDate = moment(selectedTime);
  const endDate = moment(selectedTime).add(duration, 'minutes');
  const [selectedDate, handleDateChange] = useState(selectedTime);

  console.log('BookingPreview - startDate', startDate, 'endDate', endDate, duration)
  return (
    <div>
      <div>
        <div className={classes.dateLabel}>{`${startDate.format('dddd, MMM D')}`}</div>
        <div className={classes.timeLabel}>{`${startDate.format('hh:mm a')} - ${endDate.format('hh:mm a')}`}</div>
      </div>
    </div>
  )
};

export default BookingPreview;

const useStyles = makeStyles(() => ({
  dateLabel: {
    marginBottom: 8, 
    fontSize: 22,
    fontFamily: 'Avenir Next Demi Bold',
  },
  timeLabel: {
    fontSize: 16,
    marginBottom: 2,
  },
}));