import * as Yup from 'yup';
import { isValidPhoneNumber } from "react-phone-number-input"

export const emailValidator = (name, message) => Yup.string().test(
  name,
  message,
  email => {
    if (!email) return true;
    email = email.trim();

    const nameRules = '^["“]?(([a-z\\d_])[\\.\\-_+]?)*[a-z\\d_]+[”"]?';
    const domainRules = '[a-z]+(\\-?[a-z]+)?';
    const topLevelRules = '([a-z]+\\.)?[a-z]{2,63}';
    const regex = new RegExp(`^((${nameRules})@(${domainRules})\\.(${topLevelRules}))$`);

    const isTooLong = email.trim().length >= 255;
    const regexErr = !regex.test(email);

    return !(!email || regexErr || isTooLong || email.endsWith('web'));
  }
)

// Should be used in Yup.test() as 3rd argument
export const passRequirementsValidator = (val, setError) => {
  try {
    const password = val ? val : '';
    const minLength = password.length > 7;
    const hasDigit = /(?=.*\d)/.test(password);
    const hasUppercase = /(?=.*[A-Z])/.test(password);
    const hasLowercase = /(?=.*[a-z])/.test(password);
    const hasSpecialChars = /(?=.*[!@#$%^&*(),.?":{}|<>~`+\-=_\\\/])/.test(password);

    const isValid = (hasDigit && hasUppercase && hasLowercase && hasSpecialChars && minLength);

    if (setError) {
      setError({ hasDigit, hasUppercase, hasLowercase, hasSpecialChars, minLength });
    }

    return isValid;

  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export const trimObjectStrings = values => Object.keys(values).reduce((obj, key) => {
  const updatedVal = typeof values[key] === 'string'
    ? values[key].trim() : values[key];
  obj[key] = updatedVal;
  return obj;
}, {});

export const phoneValidator = (val) => {
  try {

    const isValid = (!val || val && isValidPhoneNumber(val));

    return isValid;

  } catch (error) {
    console.log('error', error);
    return false;
  }
}