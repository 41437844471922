import React from 'react';
import { Box } from '@material-ui/core';
import ChatPage from "./ChatPage";
import styles from './ChatPage.module.scss'

import { useParams } from 'react-router-dom';

const ChatRoomContainer = ({ room }) => {

  const params = useParams();
  console.log('params', params);
  return (
      <Box className={styles.chatroom_wrapper}>
        {params?.roomId ?
          <ChatPage roomId={params.roomId} />
          : <EmptyPage />
        }
      </Box>
  );
};

export default ChatRoomContainer;

const EmptyPage = () => (<Box className={styles.empty_room}>Please select a chat to start <br/>messaging</Box>)