import React from 'react';
import { Button, Badge } from '@material-ui/core';
import { useNavigate } from 'react-router-dom-v5-compat';

const NotesHeaderActions = ({drafts = 0}) => {
  const navigate = useNavigate();

  return (
    <div style={{display: 'flex', flexDirection: 'column', width: 250}}>
      <Button variant="contained" color="primary" onClick={() => navigate("create/new")}>Add New Note</Button>
      <Button variant="outlined" color="primary" style={{marginTop: 10}} onClick={() => navigate("create")}>
        Pending Notes <Badge badgeContent={drafts} color="secondary" style={{marginLeft: drafts ? '1em' : 0}} />
      </Button>
    </div>
  )
};

export default NotesHeaderActions;
