import React, {Fragment, useEffect, useState} from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

const ModalRoute = ({
  title,
  breadcrumb, 
  memberAvatar,
  maxWidth = "md", 
  fullscreen = false, 
  handleClose, 
  warnClose,
  warnTitle,
  warnConfirm,
  warnMessage,
  actions, 
  children,
}) => {
    const styles = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
  
    const toggleFullscreen = () => setExpanded(prev => !prev);
  
    useEffect(() => {
        console.log('ModalRoute open')
        return () => console.log('ModalRoute close')
    }, [])

    useEffect(() =>{ 
        console.log('ModalRoute is fullscreen?', expanded)
    }, [expanded])

    const handleCloseDialog = () => {      
      if (warnClose) setAlertOpen(true)
      if (!warnClose) handleClose()
    }
    
    const handleCancelClose = () => {
      setAlertOpen(false)
    }

    const handleDestructiveClose = () => {
      setAlertOpen(false)
      handleClose()
    }
    return (
    <Fragment>
      <Dialog 
        fullWidth
        maxWidth={maxWidth}
        fullScreen={expanded}
        open={true}
        onClose={handleCloseDialog}
      >
        <div className={styles.dialogTitle}>
          <DialogTitle>
            <div className={styles.title}>
              {memberAvatar 
                ? <Fragment>{memberAvatar} <ChevronRightRoundedIcon color="disabled" /></Fragment> 
                : null 
              }
              {title}
              {breadcrumb 
                ? <Fragment><ChevronRightRoundedIcon color="disabled" /> <span>{breadcrumb}</span></Fragment> 
                : null 
              }
            </div>
          </DialogTitle>
          <div>
            {fullscreen 
              ? <Button onClick={toggleFullscreen}>
                {expanded 
                  ? <Fragment><span className={styles.label}>Compact</span> <FullscreenExitIcon /></Fragment>
                  : <Fragment><span className={styles.label}>Expand</span> <FullscreenIcon /></Fragment>
                } 
                </Button>
              : null}
            <Button onClick={handleCloseDialog}>
              <span className={styles.label}>Close</span><CloseRoundedIcon />
            </Button>
          </div>
        </div>
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <CloseWarning 
        open={alertOpen}
        handleCancel={handleCancelClose}
        handleDestroy={handleDestructiveClose}
        title={warnTitle}
        confirm={warnConfirm}
        message={warnMessage}
      />
    </Fragment>
    )
  }
  
  export default ModalRoute;

  const CloseWarning = ({open, handleDestroy, handleCancel, title, confirm, message}) => {

    const styles = useStyles();

    return (
      <Dialog
        open={open}
        onClose={handleCancel}
        maxWidth="xs"
      >
        <DialogTitle><span className={styles.warningTitle}>{title}</span></DialogTitle>
        <DialogContent>
          <p className={styles.warningContent}>{message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDestroy} color="secondary">{confirm}</Button>
          <Button onClick={handleCancel} color="primary">Cancel</Button> 
        </DialogActions>
      </Dialog>
    )
  }

  const useStyles = makeStyles({
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'center',
      fontFamily: 'Averta Semibold',
    },
    label: {
      margin: 5,
    },
    warningTitle: {
      fontFamily: 'Averta Bold',
      fontWeight: 600,
    },
    warningContent: {
      minHeight: 100,
      fontSize: '1em',
      lineHeight: 1.5,
    }
  })