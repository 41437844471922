import {
  InMemoryCache, 
  // makeVar
} from '@apollo/client';
// import {hasValidAuthSession} from 'context/Auth/AuthNContext';

// export const isLoggedInVar = makeVar(hasValidAuthSession());

export const cache = new InMemoryCache({
  typePolicies: {
    SessionData: {
      fields: {
        isLoggedIn: {
          read() {
            return 'I am logged in!';
          }
        },
      }
    }
  }
});