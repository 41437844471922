import React, { useState } from "react";

// UI
import AddIcon from 'common/assets/add.svg';

// Table
import Header from 'common/components/PanelHeader/PanelHeader';
import DataTables from './DataTables/';

// Dialogs
import RegisterNewLab from './LabsEntry/RegisterNewLab'

// Permission Check
import {useAuthZContext} from "context/Auth/useAuthContext";
import {checkPermissions} from "data/permissions";
import EmptyState from "common/components/EmptyState/EmptyState";

const RegisterLabAction = ({ open, close }) => <RegisterNewLab handleClose={close} open={open} />

const LabDashboard = () => {

  const {permissions} = useAuthZContext();

  const [open, setOpen] = useState(false)
  const handleRegisterLab = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const listBreadcrumbs = [{
    name: "Lab Dashboard",
    url: "",
    disabled: true
  }];

  const listButtons = [{
    title: "Register Lab Request",
    onClick: handleRegisterLab,
    icon: AddIcon
  }];
 
  const hasAccess = checkPermissions({
    permissions,
    module: 'LABS',
    action: 'ADD_LAB',
    policy: 'module_action',
    origin: 'Dashboard > Labs',
  });

  if (permissions && !hasAccess) return <EmptyState title="Sorry, can't open this 😕" text="You don't have access to this dashboard" />

  return (   
    <React.Fragment>
      <div>
        <Header
          listBreadcrumbs={listBreadcrumbs}
          listButtons={listButtons}
        />
      </div>
      <DataTables/>
      <RegisterLabAction open={open} close={handleDialogClose} />
    </React.Fragment>
  )
}

export default LabDashboard;