import React, {createContext, useState, useEffect, useReducer} from 'react'
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";

// Setup Context
export const AuthNContext = createContext();
AuthNContext.displayName = 'AuthNContext';


export const authChallengeTypes = {
  SMS_MFA: "SMS_MFA", 
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  SELECT_MFA_TYPE: "SELECT_MFA_TYPE",
  MFA_SETUP: "MFA_SETUP",
  PASSWORD_VERIFIER: "PASSWORD_VERIFIER",
  CUSTOM_CHALLENGE: "CUSTOM_CHALLENGE",
  DEVICE_SRP_AUTH: "DEVICE_SRP_AUTH",
  DEVICE_PASSWORD_VERIFIER: "DEVICE_PASSWORD_VERIFIER",
  ADMIN_NO_SRP_AUTH: "ADMIN_NO_SRP_AUTH",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED", 
}

const actionTypes = {
  setToken: 'SET_TOKEN',
  clearToken: 'CLEAR_TOKEN',
  setRestore: 'SET_RESTORE',
  clearRestore: 'CLEAR_RESTORE',
  setExpires: 'SET_EXPIRES',
  setAuthTime: 'SET_AUTH_TIME',
  setAuthStatus: 'SET_AUTH_STATUS',
  setAuthError: 'SET_AUTH_ERROR',
}

const authNReducer = (state, action) => {
  switch(action.type) {
    case actionTypes.setToken: {
      return {...state, hasToken: true};
    }
    case actionTypes.clearToken: {
      return {...state, hasToken: false}
    }
    case actionTypes.setRestore: {
      return {...state, restoreSession: true}
    }
    case actionTypes.clearRestore: {
      return {...state, restoreSession: false}
    }
    case actionTypes.setExpires: {
      return {...state, tokenExpires: action.payload}
    }
    case actionTypes.setAuthTime: {
      return {...state, authTime: action.payload}
    }
    case actionTypes.setAuthStatus: {
      return {...state, authStatus: action.payload};
    }
    case actionTypes.setAuthError: {
      return {...state, authError: action.payload};
    }
    case actionTypes.setAuthUser: {
      return {...state, authUser: action.payload};
    }
    default:
      throw new Error(`Unhandled AuthN type: ${action.type}. Current state:`, state)
  }
}

const initialState = {
  hasToken: false,
  restoreSession: false,
  tokenExpires: null,
  authTime: null,
  authStatus: null,
  authError: null,
  authUser: null,
}

const useAuthN = () => {
  const [{hasToken, restoreSession, tokenExpires, authTime, authStatus, authError, authUser}, dispatch] = useReducer(authNReducer, initialState);
  
  // Token
  const setToken = () => dispatch({type: actionTypes.setToken});
  const clearToken = () => dispatch({type: actionTypes.clearToken})
  const getToken = () => localStorage.getItem("hasToken")
  const setExpires = (expire) => dispatch({type: actionTypes.setExpires, payload: expire})
  const setAuthTime = (authAt) => dispatch({type: actionTypes.setAuthTime, payload: authAt})
  const setAuthStatus = (status) => dispatch({type: actionTypes.setAuthStatus, payload: status})
  const setAuthError = (error) => dispatch({type: actionTypes.setAuthError, payload: error})
  const setAuthUser = (user) => dispatch({type: actionTypes.setAuthUser, payload: user})

  // Restore Session
  const setRestore = () => dispatch({type: actionTypes.setRestore})
  const clearRestore = () => dispatch({type: actionTypes.clearRestore})

  return {
    hasToken, setToken,
    getToken, clearToken,
    restoreSession,
    setRestore, clearRestore,
    tokenExpires, setExpires,
    authTime, setAuthTime,
    authStatus, setAuthStatus,
    authError, setAuthError,
    authUser, setAuthUser,
  }
}

const AuthNContextProvider = ({children}) => {

  const {
    hasToken, setToken, getToken, clearToken, 
    restoreSession, setRestore, clearRestore, 
    tokenExpires, setExpires,
    authTime, setAuthTime,
    authStatus, setAuthStatus,
    authError, setAuthError,
    authUser, setAuthUser,
  } = useAuthN()

  console.log('authN reducer state hasToken', hasToken, 'restoreSession', restoreSession, 'tokenExpires', tokenExpires, 'authTime', authTime)

  // State
  const [isSignedOut, setIsSignedOut] = useState(false);
  
  // Nav
  const history = useHistory();

  // Init
  useEffect(() => {
    console.log('authN hasToken', hasToken)
    if(hasToken) localStorage.setItem("hasToken", hasToken);
  }, [hasToken])

  useEffect(() => {
    const hasTokenStored = getToken();
    if (hasTokenStored) {
      console.log('[AuthNContext] Attempt Cognito Session Restore');
      setRestore()
    }
  }, [])

  useEffect(() => {
    if(restoreSession) handleRefreshToken({silent: false})
  }, [restoreSession])

  useEffect(() => {
    if(isSignedOut === true) {
      const location = {
        pathname: '/',
        state: {
          from: history.location.pathname,
        }
      }
      history.push(location)
      console.log('[AuthNContext] Saving history in router state', history)
    }
  }, [isSignedOut])

  // Handlers
  const handleUserLogin = async (userName, password) => {
    try {
      const user = await Auth.signIn(userName, password)
      console.log('[AuthNContext] Cognito signIn success!', user);
      const currentIdToken = user?.signInUserSession?.idToken;
      const userRole = currentIdToken?.payload["custom:user_role"]
      const isValidRole = validateUserRole(userRole);
      const isResetRequired = checkAuthChallenge(user)

      if (!isValidRole) handleInvalidUserRole(userRole)
      if (isResetRequired) handleResetRequired(user)

      if (isValidRole && currentIdToken) { 
        storeSessionToState(currentIdToken, false);
        restoreLastUrl(false)
      }
    } catch (error) {
      setAuthError(error)
      console.warn('[AuthNContext] Cognito signIn() failed', error);
    }
  };
  const handleUserSignOut = async () => {
    try { 
      await Auth.signOut()
      destroySessionData()
      console.log('[AuthNContext] Signing Out')
    } catch (error) {
      console.warn('[AuthNContext] Error Signing Out')
    }
  }
  const handleCompleteNewPassword = async (password) => {
    try {
      const confirmed = await Auth.completeNewPassword(authUser, password, [])
      if (confirmed) setAuthUser(null)
      console.log('[AuthContext] Successfully Set New Password!', confirmed) 
    } catch (error) {
      setAuthError(error)
      console.warn('[AuthNContext] Error Setting New Password', error)
    }
  }
  const handleGetCurrentSession = async () => {
    try { 
      const session = await Auth.currentSession();
      console.log('[AuthNContext] Cognito currentSession()', session)
      return session;
    } catch (error) {
      console.warn('[AuthNContext] Cognito currentSession() Error', error)
    }
  }
  const handleRefreshToken = async ({silent}) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await handleGetCurrentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (err?.code === 'NotAuthorizedException') 
          return handleExpiredRefreshToken(err)
        // Valid Token
        const refreshSessionIdToken = session?.idToken;
        // console.log(
        //   `[AuthNContext] Cognito Authenticated and Refreshed Token. Welcome back!`, 
        //   'Silent refresh?', silent, 
        //   'idToken', refreshSessionIdToken);
        storeSessionToState(refreshSessionIdToken, silent);
      })
    } catch (error) {
      console.warn('[AuthNContext] No Cognito Session. Error:', error);
      handleExpiredRefreshToken(error)
    }
  };
  const handleExpiredRefreshToken = async (err) => {
    try {
      console.warn('[AuthNContext] Refresh Token Expired. Logging out.', err);
      handleUserSignOut()
    } catch (error) {
      console.warn('[AuthNContext] Expired Refresh Token Handler Error', error);
      destroySessionData()
      history.replace('/')
    }
  }
  const handleInvalidUserRole = async (userRole) => {
    console.warn('[AuthNContext] Invalid user role', userRole);
    setAuthError({message: `Sorry, this dashboard is only for staff`, icon: "👩‍⚕️"})
    return destroySessionData()
  }
  const handleResetRequired = async (user) => {
    console.warn('[AuthNContext] New password required. Re-routing')  
    setAuthStatus(authChallengeTypes.NEW_PASSWORD_REQUIRED)
    setAuthUser(user)
  }

  // Utils
  const destroySessionData = () => {
    clearToken()
    clearRestore()
    setIsSignedOut(true);
    localStorage.clear();
  }
  const storeSessionToState = (idToken, silent = false) => {
    const sessionExpires = idToken?.payload?.exp;
    const authTime = idToken?.payload?.auth_time
    setExpires(sessionExpires)
    setAuthTime(authTime)
    setAuthError(null)
    if (!silent) setToken()
  }
  const restoreLastUrl = (restore) => {
    if(!restore) return;
    const lastUrl = history?.location?.state?.from;
    history.replace(lastUrl)
  }
  
  // Values
  const contextValues = {
    hasToken,
    authStatus,
    authError,
    tokenExpires,
    restoreSession,
    handleUserLogin,
    handleUserSignOut,
    handleRefreshToken,
    handleCompleteNewPassword,
  }

return (
  <AuthNContext.Provider value={contextValues}>
    {children}
  </AuthNContext.Provider>
)}

export default AuthNContextProvider;

// Helpers
export const hasValidAuthSession = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const isValid = currentSession.isValid(); 
    const idToken = currentSession.getIdToken();
    const jwtToken = idToken.getJwtToken();
    const decodedPayload = idToken.decodePayload();
    const authTime = decodedPayload?.auth_time;

    // Refresh Token Validity
    const now = Math.floor(new Date() / 1000);
    const refreshExpires = 3600; // 60 minutes
    const refreshTokenValid = now < (authTime + refreshExpires);

    const isValidSession = isValid && refreshTokenValid && !!jwtToken;
    console.log('[AuthNContext] isUserLoggedIn Helper: isValidSession?', isValidSession, 'decodedPayload', decodedPayload)
    
    return isValidSession;

  } catch (error) {
    console.warn('[AuthNContext] isUserLoggedIn Helper: Error', error)
  }
}

export const getJwtToken = async () => {
  const jwtToken = await Auth.currentSession().getIdToken().getJwtToken();
  return jwtToken 
} 

export const validateUserRole = (userRole) => {
  const isValidUser = userRole === 'member' ? false : true;
  return isValidUser
}

export const checkAuthChallenge = (user) => {
  const resetRequired = user.challengeName === authChallengeTypes.NEW_PASSWORD_REQUIRED;
  return resetRequired;
}
