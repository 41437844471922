import React from 'react';

// Table
import Header from 'common/components/PanelHeader/PanelHeader';
import DataTables from './DataTables/Staff';

const AdminDashboard = () => {

  const listBreadcrumbs = [
    {
      name: 'Member Dashboard',
      url: '',
      disabled: true,
    },
  ];

  const listButtons = [];

  return (
    <React.Fragment>
      <div>
        <Header listBreadcrumbs={listBreadcrumbs} listButtons={listButtons} />
      </div>

      <DataTables />
    </React.Fragment>
  );
};

export default AdminDashboard;
