import * as Yup from 'yup';

const PATIENTID_REQUIRED_MESSAGE = "You must select a patient.";
const PANELID_REQUIRED_MESSAGE = "Panel is required.";
const AUTHORIZATION_REQUIRED_MESSAGE = "Authorization number required.";
const DUEDATE_REQUIRED_MESSAGE = "Due date is required.";

export const LabRequestSchema = Yup.object().shape({
  patientId: Yup.object()
    .shape({
      _id: Yup.string(),
      name: Yup.string(),
    })
    .nullable()
    .required(PATIENTID_REQUIRED_MESSAGE),
  panelId: Yup.object()
    .shape({
      _id: Yup.string(),
      name: Yup.string(),
    })
    .nullable()
    .required(PANELID_REQUIRED_MESSAGE),
  specialNote: Yup.string(),
  location: Yup.string(),
  authorizationNumber: Yup.string()
    .required(AUTHORIZATION_REQUIRED_MESSAGE),
  dueDate: Yup.date()
    .required(DUEDATE_REQUIRED_MESSAGE)
});