import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Material
import {Grid, CardContent, LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Skeleton} from '@material-ui/lab';

// Utils
// import Can from 'hoc/Can';
import {Link} from 'react-router-dom';

// Components
import MemberAvatar from '../../Member/Avatar/MemberAvatar';
// import ChatIcon from 'common/assets/chat.svg';
// import LinkWithIcon from '../../Link/LinkWithIcon/LinkWithIcon';

const Patient = ({ data, loading, bottomPaddding }) => {
  const styles = useStyles();
  
  const avatarProps = { 
    _id: data?._id,
    firstName: data?.firstName,
    lastName: data?.lastName,
    image: data?.image || data?.emrPic,
    type: data?.type,
  };

  const baseUrl = data?.type === 'patient' ? '/patients' : data?.type === 'family' ? '/family' : '/patients';

  return (
    <Grid item className={styles.wrapper}>
      {loading ? (
          <MemberSkeleton />
        ) : (
        <Fragment>
          <MemberAvatar {...avatarProps} />
          <div className={styles.content}>
            <Link to={`${baseUrl}/${data?._id}`} className={styles.name}>
              {data?.firstName} {data?.lastName}
            </Link>
            <LinearProgress
              classes={{
                root: styles.progressSecondary,
                bar: styles.progressPrimary,
              }}
              variant="determinate"
              className={styles.progress}
              value={data?.engagementLevel || 30}
            />
          </div>
        </Fragment>
      )}
    </Grid>
  );
};

Patient.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
}

export default Patient;

const MemberSkeleton = () => {
  const styles = useStyles();
  return (
    <Fragment>
      <Skeleton animation="wave" variant="circle" width={100} height={100} />
      <div className={styles.content}>
          <Skeleton animation="wave" variant="text" width={125} height={30}/>
          <Skeleton animation="wave" variant="text" width={125} height={15}/>
      </div>
    </Fragment>
  )
}

// const ChatButton = ({memberId, memberType}) => {
//   const styles = useStyles();
//   return <Can
//     module="PATIENTS"
//     perform="VIEW_PATIENT_CHAT"
//     // memberIdId={memberId}
//     // memberType={memberType}
//     policy="module_action"
//     origin="Patient Card > Chat Button"
//     yes={() => 
//       <LinkWithIcon
//         children="Chat"
//         icon={ChatIcon}
//         className={styles.chat}
//         to={`${routes.patientList}/${memberId}/messaging`} />
//     } />
// }

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Avenir Next Medium',  
  },
  content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      paddingTop: 20,
  },
  name: {
      color: '#000',
      fontSize: 18,
      alignSelf: 'center',
  },
  progress: {
      display: 'flex',
      margin: '15px auto',
      width: 125,
      height: 6,
      borderRadius: 10,
  },
  progressPrimary: {
      backgroundColor: '#00B500',
      borderRadius: 20,
  },
  progressSecondary: {
      backgroundColor: '#F6F6F6',
  },
  activationNotice: {
      color: '#ccc',
      fontSize: 14,
      marginTop: 23,
      textAlign: 'center',
  },
})