import { createAction, handleAction } from 'redux-actions';

export const addNewNote = createAction('ADD_NEW_NOTE');

const defaultState = { addedNotes: [] };

export default handleAction(addNewNote, 
    ({ addedNotes }, { payload }) => ({
        addedNotes: [...addedNotes, payload]
    }),
defaultState);
