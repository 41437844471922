import React from 'react';

import NotesItem from './NotesItem/NotesItem';
import classes from './Notes.module.scss'

const Notes = ({ notes }) => {
    

    return (
        <div>
            <div className={classes["notes-wrapp"]}>
                {notes && notes.map((item, index) => (
                    <NotesItem key={index} {...item}/>
                ))}
            </div>
        </div>
    )
}

export default Notes;
