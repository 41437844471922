import React, {createContext, useState, useEffect, useRef} from 'react'

import {useLazyQuery} from '@apollo/client';
import {GET_ACCOUNT_PERMISSIONS} from 'data/graphql/account/user';

export const AuthZContext = createContext();
AuthZContext.displayName = 'AuthZContext';

const AuthZContextProvider = ({children}) => {

  // State
  const initDataReady = useRef(false);
  const [initLoad, setInitLoad] = useState(true);
  
  // AuthZ
  const [rawPermissions, setRawPermissions] = useState();
  const [permissions, setPermissions] = useState();
  const [moduleActions, setModuleActions] = useState();
  const [dashboards, setDashboards] = useState();
  const [members, setMembers] = useState();
  const actionsMap = new Map();

  // Temp: Replace with a permissions specific query
  const [getPermissionsData, {loading,  data, called}] = useLazyQuery(GET_ACCOUNT_PERMISSIONS);
  
  // Init
  useEffect(() => {
    if(!called) getPermissionsData()
  }, [])

  useEffect(() => {
    if(called && !loading) {
        setInitLoad(false);
        initDataReady.current = true;
        console.log('[AuthZContext] User Data Ready')
    }
  }, [called, loading])

  useEffect(() => {
    const permissions = data?.getDashboardData?.user?.permissions;
    if(initDataReady.current === true) {
      setRawPermissions(permissions)
      console.log('[AuthZContext] Permissions Set')
      console.table(rawPermissions)
    }
  }, [data])

  useEffect(() => {
    if(!initLoad && rawPermissions?.length) {
      const patientList = mergeRoles(rawPermissions, 'patientIds') || [];
      const staffList = mergeRoles(rawPermissions, 'staffIds') || [];
      const members = {
        'patient': patientList,
        'staff': staffList,
        'family': [],
      };

      const dashboards = mergeRoles(rawPermissions, 'UI');
      const mergedActions = mergeRoles(rawPermissions, 'moduleActions')
       
      const runPermissionMap = () => {
        // console.log('[AuthZContext] runPermissionMap ran')
        console.time('Time: Permissions runPermissionMap')
        mergedActions.map(item => generatePermissionMap(actionsMap, item.code, item.actions));
        console.timeEnd('Time: Permissions runPermissionMap')
      }
      
      if(actionsMap.size === 0) runPermissionMap()
      
      const permissions = {
        members,
        moduleActions: actionsMap,
        dashboards,
      };

      setPermissions(permissions)
      setModuleActions(actionsMap)
      setDashboards(dashboards)
      setMembers(members)
      
      // console.log('[AuthZContext] Module Actions Set', moduleActions, 'Members Set', members, 'patientList', patientList, 'staffList', staffList, 'dashboards', dashboards, 'raw', rawPermissions)
    }
  }, [rawPermissions])

  const contextValues = {
    permissions,
    moduleActions,
    dashboards,
    members,
    getPermissionsData,
  }
  console.log('[AuthZContext] Final Permissions', permissions)

  return (
    <AuthZContext.Provider value={contextValues}>
      {children}
    </AuthZContext.Provider>
  )
}

export default AuthZContextProvider;

/**
 * Merge Roles: reduces array into Set to auto remove duplicates
 * @param {*} array 
 * @param {*} key 
 */
 const mergeRoles = (array = [], key) => {
  console.time('Time: Permissions mergeRoles')
  const mergeReduce = array?.reduce((prev, result)  => {
    const targetObject = result[key]
    return [...prev, ...targetObject]
  }, [])

  // Remove duplicates
  const cleanMerge = [...new Set(mergeReduce)]
  console.timeEnd('Time: Permissions mergeRoles')
  return cleanMerge
}

/**
 * Generate Map() with permissions from moduleActions
 * @param {*} map Map to build permissions
 * @param {*} type key from permissions object (ex. 'code')
 * @param {*} permissions merged array with permission objects {code, actions}
 * @returns sets actions with key => code and value => actions
 */
const generatePermissionMap = (map, type, permissions) => {
  const keyExist = map.has(type);
  const previous = keyExist ? map.get(type) : {};
  const merged = arrayNoDuplicates(permissions);
  const converted = arrayToTrueMap(merged);
  map.set(type, { ...converted, ...previous });
};

// Data Utilities
const arrayNoDuplicates = (arr) => {
  return [...new Set(arr)];
};

const arrayToTrueMap = (subArray = []) => {
  return subArray.reduce((map, obj) => {
    const trueMap = { ...map, [obj]: true }
    return trueMap;
  }, {});
};