import {gql} from '@apollo/client';
import {MEMBER_DEVICE_FIELDS} from '../member';

export const PATIENT_FIELDS = `
  patients {
    _id
    mrn
    firstName
    lastName
    image
    email
    phone
    type
    locationId
    program {
      _id
      name
      displayName
    }
    lastActive
    emotionalState
    engagementLevel
    riskLevel
    activatedAccount
    status
    relations {
      _id
      firstName
      lastName
      image
      activatedAccount
      relation {
        name
      }
    }
    status
    soberCircle {
      _id
    }
    active
    emrPic
    subType {
      title
      code
    }
    devices {
      ${MEMBER_DEVICE_FIELDS}
    }
  }
`;

export const DISCHARGED_PATIENT_FIELDS = `
  dischargedPatients {
    _id
    firstName
    lastName
    mrn
    relations {
      _id
      activatedAccount
      relation {
        name
      }
    }
  }
`;

export const GET_MEMBERS_PATIENTS = gql`
  query getMembersPatients {
    getDashboardData {
      ${PATIENT_FIELDS}
    }
  }
`

export const GET_MEMBERS_DISCHARGED_PATIENTS = gql`
  query getMembersDischargedPatients {
    getDashboardData {
      ${DISCHARGED_PATIENT_FIELDS}
    }
  }
`;