import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import Patient from './Card';

// Context
import useAccountContext from 'context/Account/useAccountContext';

const PatientList = ({max}) => {
  
  const {caseload, loadingUserData} = useAccountContext();
  
  const limit = max ? max - 1 : caseload?.length;
  const displayMemberFilter = ((_, i) => i <= limit);
  console.log('[SpecialistDashboard] caseload', caseload, 'loadingUserData', loadingUserData, 'displayMemberFilter', displayMemberFilter)

  return (
    <Fragment>
      {caseload?.filter(displayMemberFilter).map((member, index) => (
          <Patient
            key={member?._id || index}
            data={member}
            loading={loadingUserData}
            showChat={false}
          />
        ))
      }
    </Fragment>
  )
};

PatientList.propTypes = {
  max: PropTypes.number,
};

export default PatientList;
