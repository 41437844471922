
import React from 'react';
import PatientEditCaron from 'screens/Patient/Edit/PatientEditCaron';

const EditProviderSetup = () => {
  
  return (
    <PatientEditCaron />
  )
  
}

export default EditProviderSetup;