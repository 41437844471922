/**
 * Dialog: onClose wrapper to prevent close on backdropClick (deprecated prop: disableBackdropClick)
 * @param {*} reason prop from Dialog component onClose event
 * @param {*} closeHandler function to execute on valid onClose
 */
export const controlDialogClose = (reason, closeHandler) => {
  const preventMethods = ['backdropClick'];
  const allowClose = !preventMethods.some(i => i === reason);
  if (allowClose) closeHandler();
};
