import React from "react";
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import TextField from "common/components/InputFields/TextField/TextField";
import MemberAvatar from "common/components/Member/Avatar/MemberAvatar";
import UserChip from "common/components/InputFields/UserChip";
import styles from "../PatientEdit.module.scss";
import schema from "./schema";
import Join from "./Join";
import { ADD_SOBER_CIRCLE, REMOVE_SOBER_CIRCLE, UPDATE_SOBER_CIRCLE } from "data/graphql/soberCircleQueries";
import { GET_FULL_PATIENT_INFO } from "data/graphql/patients";
import { useMemberStateContext } from "context/Member/useMemberContext";
import useMembersContext from "context/Members/useMembersContext";

const SoberCircle = () => {

  // Data
  const {memberData: patient} = useMemberStateContext(); 
  const {patients} = useMembersContext();
  console.log('[SoberCircle] patient', patient)

  const availableMembers = patients.filter(p => p.activatedAccount && !p.soberCircle);
  //remove patients that are not activated and the ones that are already in a sober circle

  const { enqueueSnackbar } = useSnackbar();

  const [ removeSoberCircle, removeQuery ] = useMutation(REMOVE_SOBER_CIRCLE, {
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient?._id } }
    ],
    onError: (e) => enqueueSnackbar(e && e.message
      ? e.message
      : "Error removing", { variant: "error" }),
    onCompleted: () => enqueueSnackbar("Sober Circle was removed", { variant: "success" })
  });

  const [ createSoberCircle, createQuery ] = useMutation(ADD_SOBER_CIRCLE, {
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient?._id } }
    ],
    onError: (e) => enqueueSnackbar(e && e.message
      ? e.message
      : "Error Updating", { variant: "error" }),
    onCompleted: () => enqueueSnackbar("Sober Circle was created", { variant: "success" })
  });

  const [ updateSoberCircle, updateQuery ] = useMutation(UPDATE_SOBER_CIRCLE, {
    refetchQueries: [
      { query: GET_FULL_PATIENT_INFO, variables: { _id: patient?._id } }
    ],
    onError: (e) => enqueueSnackbar(e && e.message
      ? e.message
      : "Error Updating", { variant: "error" }),
    onCompleted: () => enqueueSnackbar("Sober Circle was updated", { variant: "success" })
  });

  const isSaving = createQuery.loading || updateQuery.loading;
  const isRemoving = removeQuery.loading;

  let soberCircle;
  if (patient.soberCircle) {
    soberCircle = {
      _id: patient.soberCircle._id,
      createdAt: patient.soberCircle.createdAt,
      teamName: patient.soberCircle.teamName || "",
      teamLeads: patient.soberCircle.members ? patient.soberCircle.members.filter(user => !!user.isTeamLead) : [],
      members: patient.soberCircle.members ? patient.soberCircle.members.filter(user => !user.isTeamLead) : [],
    };
  } else {
    soberCircle = {
      _id: null,
      createdAt: null,
      teamName: "",
      teamLeads: [],
      members: [
        {
          _id: patient._id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          image: patient.image,
        }
      ],
    };
  }

  const onSubmit = async (data) => {

    const teamLead = data.teamLeads[0];
    const members = data.members.map(item => item._id);

    if (!soberCircle._id) {
      await createSoberCircle({
        variables: {
          content: {
            teamLead: teamLead?._id,
            teamName: data.teamName,
            members
          }
        }
      });
    } else {

      await updateSoberCircle({
        variables: {
          _id: soberCircle._id,
          content: {
            teamLead: teamLead ? teamLead._id : null,
            teamName: data.teamName,
            members
          }
        }
      });

    }
  };

  const { _id, firstName, lastName, type, image, emrPic} = patient;
  const avatarProps = {
    _id,
    firstName,
    lastName,
    image: image || emrPic,
    type,
    label: `${firstName} ${lastName}`,
    showLabel: true,
    disabled: true,
    size: 'xlarge',
  }

  return (
    <Grid className={styles.wrapper}>
      <Grid container className={styles.module_edit}>
        <Grid container className={styles.wrapper}>
          <Grid item xs={3}>
          <div className={styles.avatar_wrapper}>
            <MemberAvatar {...avatarProps} />
          </div>
          </Grid>
          <Grid item xs={9} justify='space-between' className={styles.container}>

            {!soberCircle._id
              ? <Join patient={patient}/>
              : null}

            <Formik
              initialValues={soberCircle}
              validationSchema={schema}
              onSubmit={onSubmit}>
              {({ values, errors, touched, resetForm }) => {

                const isValid = Object.keys(errors).length === 0;
                const isChanged = Object.keys(touched).length > 0;

                const canBeUpdated = isValid && isChanged;

                const onRemove = async () => {
                  await removeSoberCircle({
                    variables: {
                      _id: soberCircle?._id
                    }
                  });
                  resetForm({
                    _id: null,
                    createdAt: null,
                    teamName: "",
                    teamLeads: [],
                    members: [],
                  });
                };

                return <Form>

                  <Grid container spacing={1}>

                    <Grid item xs={12}>
                      <Box mb={1} className={classnames(styles.section_title)}>
                        Create a Sober Circle
                      </Box>

                      {soberCircle?.createdAt
                        ? <Box mb={1}>
                          <Typography variant="subtitle2">
                            Created at: {moment(soberCircle.createdAt).format("YYYY-MM-DD HH:mm")}
                          </Typography>
                        </Box> : null}
                    </Grid>

                    <Grid item xs={12} md={6}>

                      <Box mb={2}>
                        <label htmlFor="teamName">Team Name *</label>
                        <Field
                          name='teamName'
                          component={TextField}
                          value={values.teamName}/>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box mb={2}>
                        <label htmlFor="teamLead">Team Leader *</label>
                        <Field
                          name="teamLeads"
                          component={UserChip}
                          placeholder="Type patient name ..."
                          isUserObject={true}
                          value={values.teamLeads}
                          suggestions={availableMembers}/>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <label htmlFor="soberCircleMembers">Members *</label>
                        <Field
                          name="members"
                          component={UserChip}
                          placeholder="Type patient name ..."
                          isUserObject={true}
                          value={values.members}
                          suggestions={availableMembers}/>
                      </Box>
                    </Grid>

                    <Grid item>
                      <ButtonGroup>
                        {soberCircle._id
                          ? <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaving || !canBeUpdated}>
                            {!isSaving ? "Update" : "Saving..."}
                          </Button>
                          : <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaving || !isValid}>
                            {!isSaving ? "Create" : "Saving..."}
                          </Button>}

                        {soberCircle._id
                          ? <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                            onClick={onRemove}
                            // disabled={isRemoving}
                            >
                            {!isRemoving ? "Disband" : "Saving..."}
                          </Button>
                          : null}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Form>;
              }}
            </Formik>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
  
};

export default SoberCircle;