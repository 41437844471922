export const DAYS_ENUM = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DAYS_WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const WEEKS_OPTIONS = [
  { label: 'First', BYSETPOS: 1, weekIndex: 'first' },
  { label: 'Second', BYSETPOS: 2, weekIndex: 'second' },
  { label: 'Third', BYSETPOS: 3, weekIndex: 'third' },
  { label: 'Fourth', BYSETPOS: 4, weekIndex: 'fourth' },
  { label: 'Last', BYSETPOS: -1, weekIndex: 'last' },
];

export const INTERVALS_OPTIONS = [
  { label: 'day', value: 'daily', plural: 'days' },
  { label: 'week', value: 'weekly', plural: 'weeks' },
  { label: 'month', value: 'monthly', plural: 'months' },
  { label: 'year', value: 'yearly', plural: 'years' },
];

export const WEEKDAYS_OPTIONS = [
  { label: 'S', value: 'Sunday' },
  { label: 'M', value: 'Monday' },
  { label: 'T', value: 'Tuesday' },
  { label: 'W', value: 'Wednesday' },
  { label: 'T', value: 'Thursday' },
  { label: 'F', value: 'Friday' },
  { label: 'S', value: 'Saturday' },
];

export const REPEAT_OPTIONS = [
  {
    key: 0,
    label: 'Never',
    frequency: null,
    interval: null,
    endDate: null,
  },
  // Ex: Daily for a week
  {
    key: 1,
    label: 'Daily for a week',
    repeatType: 'dailyForWeek',
    frequency: 'daily',
    interval: 1,
    endDate: { weeks: 1 },
  },
  // Ex: Daily on weekdays
  {
    key: 2,
    label: 'Daily on weekdays',
    repeatType: 'dailyWeekdays',
    frequency: 'daily',
    interval: 1,
    endDate: { weeks: 1 },
  },
  // Ex: Weekly on Tuesdays
  {
    key: 3,
    label: 'Weekly on',
    repeatType: 'weeklyOnDay',
    frequency: 'weekly',
    interval: 1,
    endDate: { months: 13 },
  },
  // Ex: Biweekly on Wednesday
  {
    key: 4,
    label: 'Biweekly on',
    repeatType: 'biweeklyOnDay',
    frequency: 'weekly',
    interval: 2,
    endDate: { months: 13 },
  },
  // Ex: Monthly every 2nd Friday
  {
    key: 5,
    label: 'Monthly on the',
    repeatType: 'monthlyOnDay',
    frequency: 'monthly',
    interval: 1,
    endDate: { months: 13 },
  },
  // Ex: Monthly every 2nd Friday
  {
    key: 6,
    label: 'Custom',
    repeatType: 'custom',
    frequency: 'custom',
    interval: 1,
    endDate: { months: 13 },
  },
];
