import React from 'react';
import Item from './Item';
import PropTypes from 'prop-types';

const List = ({ data, past, ...restProps }) =>
  data.map(item => (
    item.firstName ? < Item item={item} key={item._id} past={past} {...restProps} /> : 'No access to patient data' //Bogi this check is for the case when the patient 
  ));

List.propTypes = {
  data: PropTypes.array.isRequired,
  past: PropTypes.bool,
};

export default List;
