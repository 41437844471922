import {
  SET_NOTIFICATIONS,
  SELECT_URGENT_NOTIFICATIONS,
  MARK_URGENT_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATION_AS_READ,
  ADD_NOTIFICATION_TO_STORE
} from './types';

export const setNotifications = notifications => dispatch => {
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: notifications,
  });
  
  dispatch({
    type: SELECT_URGENT_NOTIFICATIONS,
  });
};

export const addNotificationToStore = notification => dispatch => {
  dispatch({
    type: ADD_NOTIFICATION_TO_STORE,
    payload: notification
  })

  dispatch({
    type: SELECT_URGENT_NOTIFICATIONS
  })
}

export const readUrgentNotification = id => ({
  type: MARK_URGENT_NOTIFICATION_AS_READ,
  payload: id,
});

export const readAllNotifications = () => ({
  type: MARK_ALL_NOTIFICATION_AS_READ,
});
