import React, { useState } from 'react';
import { TextField, Box, Button } from '@material-ui/core';
import styles from './MessageInput.module.scss';
import SendIcon from '@material-ui/icons/Send';

const MessageInput = ({ onClick, onTyping, classesname }) => {
  const [value, setValue] = useState('');
  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      onClick(value);
      event.preventDefault();
      setValue('')
    }
  }
  return (
    <Box className={`${styles.wrapper} ${classesname}`}>
      <TextField
        id="message-input"
        className={styles.input}
        value={value}
        placeholder="Write a message..."
        onChange={e => {
          onTyping();
          setValue(e.target.value)
        }}
        onKeyPress={ev => enterPressed(ev)}
        multiline={true}
        classes={{
          root: styles.underline,
        }}
      />
      <Button
        onClick={e => { onClick(value); setValue('') }}
        disabled={!value}
        className={styles.button}
      >
        <SendIcon color="primary" />
      </Button>
    </Box>
  );
};
export default MessageInput;
