import React, { createContext, useEffect, useState, useRef } from 'react';
import SendBird from 'sendbird';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ACCESS_TOKEN, GET_ONLINE_USERS, SEND_MESSAGE_NOTIFICATION } from 'data/graphql';
import config from '../../config/config';
import useAccountContext from 'context/Account/useAccountContext';
import useSessionContext from 'context/Session/useSessionContext';

export const ChatContext = createContext({
  onlineUsers: [],
});
ChatContext.displayName = 'ChatContext'

const ChatContextProvider = ({ children }) => {

  const {isFocused} = useSessionContext();

  // State
  const [totalUnread, setTotalUnread] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [channelsList, setChannelsList] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentChannelMessages, setCurrentChannelMessages] = useState([]);
  const [typingUsers, setTypingUsers] = useState([]);
  const [hasTypingUser, setHasTypingUser] = useState(false);

  // Ref
  const isConnected = useRef(false)

  console.log(
    "[Chat Context] Context State - Sendbird:",
    " \n totalUnread: " , totalUnread,
    " \n isConnected: " , isConnected.current,
    " \n onlineUsers: " , onlineUsers,
    " \n channelsList: " , channelsList,
    " \n currentChannel: " , currentChannel,
    " \n currentChannelMessages: " , currentChannelMessages,
    " \n typingUsers: " , typingUsers,
    " \n hasTypingUser: " , hasTypingUser,
  )

  // User ID
  const {userId: _id} = useAccountContext();
 
  // Create connection
  const sb = new SendBird({ appId: config.sendbird.APP_ID });

  // Get Sendbird Token
  const { loading, data } = useQuery(GET_ACCESS_TOKEN);

  // Get Online Users
  const { loading: loadingUsers, data: dataUsers } = useQuery(GET_ONLINE_USERS);
  // const [sendMessageNotification] = useMutation(SEND_MESSAGE_NOTIFICATION);
  // console.log("[Chat Context] dataUsers: ", dataUsers)
  useEffect(() => {
    if (dataUsers && !loadingUsers) setOnlineUsers(dataUsers.getOnlineUsers);
  }, [loadingUsers]);

  // On Mount
  useEffect(() => {
    // Connect to User
    if (isFocused && _id && !loading && data?.getMyAccessToken) {
      console.log('[ChatContext] Sendbird Connecting')
      sbConnect(_id, data?.getMyAccessToken?.token);
    }

    if (!isFocused) {
      console.log('[ChatContext] Sendbird Disconnecting')
      sbDisconnect();
      isConnected.current = false;
    }

    return () => {
      console.log('[ChatContext] Cleanup')
      sbDisconnect();
      isConnected.current = false;
    }
  }, [_id, loading, isFocused]);

  //
  useEffect(() => {
    if (isConnected.current) {
      // Add/Update events listeners
      addChannelHandlers();
    }
  }, [currentChannel]);

  useEffect(() => {
    if (isConnected.current) getChannelsList();
    const interval = setInterval(() => {
      if (isConnected.current) getChannelsList();
    }, 100000);
    return () => clearInterval(interval)
  }, [isConnected.current]);

  const sbConnect = (userId, accessToken) => {
    console.log('[Sendbird] Connecting')
    sb.connect(userId, accessToken, (user, error) => {
      if (error) return console.log('[Sendbird] Error Connecting to Sendbird: ', error);
      console.log('[Sendbird] Connection Successful', user);
      isConnected.current = true;
    });
  }
  
  const sbDisconnect = () => {
    console.log('[Sendbird] Disconnecting')
    sb.disconnect()
  }

  //======================================//
  //======================================//
  const enterChannel = channelId => {
    if (!isConnected.current) return console.log('[ChatContext] Connection must be established before enterChannel') 
    return sb.GroupChannel.getChannel(channelId, (groupChannel, error) => {
      if (error) return console.log('enterChannel => error', error);

      setCurrentChannel(groupChannel);
      getChannelMessages(groupChannel);
      groupChannel.markAsRead();
    });
  }
    
  //======================================//
  //======================================//
  const leaveChannel = channelId => {
    //DO some cleanup work if needed
    setCurrentChannel(null);
  };

  //======================================//
  //======================================//
  // const enterOpenedChannel = (groupChannel, cb) => {
  //   GroupChannel.enter((response, error) => {
  //     if (error) return console.log('openChannel.enter => error', error);

  //     console.log('response', response);
  //     setCurrentChannel(groupChannel);
  //     cb();
  //   });
  // }

  //======================================//
  //======================================//
  const getChannelMessages = openChannel => {
    if (!openChannel) return console.log('no openChannel');

    const messageListQuery = openChannel.createPreviousMessageListQuery();

    messageListQuery.load((messageList, error) => {
      if (error) return console.log('messageListQuery.load => error', error);

      setCurrentChannelMessages(messageList);
    });
  };

  //======================================//
  //======================================//
  const sendMessage = message => {
    if (!currentChannel) return console.log('current channel is missing');
    
    currentChannel.sendUserMessage(message, (message, error) => {
      if (error) return console.log('sendUserMessage => error', error);
      
      setCurrentChannelMessages(prevState => [...prevState, message]);
    });
    console.log(' sendMessage onlineUsers', currentChannel.members, onlineUsers);

    return console.log('@deprecated sendMessageNotification. Moving to Sendbird notifications')
    // const receivers = currentChannel.members
    //   .filter(m => m.userId !== _id && !onlineUsers.includes(m.userId))
    //   .map(m => m.userId);
    //   sendMessageNotification({ variables: { receivers, messageContent: { message } }, ignoreResults: true });
  };

  //======================================//
  //======================================//
  const addChannelHandlers = () => {
    const ChannelHandler = new sb.ChannelHandler();

    // onChannelChanged
    // ======================================================= //
    ChannelHandler.onChannelChanged = channel => {
      const updatedList = [...channelsList];
      const indexToUpdate = updatedList.findIndex(c => c.url === channel.url);

      if (indexToUpdate !== -1) {
        updatedList[indexToUpdate].unreadMessageCount = channel.unreadMessageCount;
        updatedList[indexToUpdate].lastMessage = channel.lastMessage;
        setChannelsList(updatedList);

        const unreadCount = updatedList.reduce((accum, curr) => accum + curr.unreadMessageCount, 0);
        setTotalUnread(unreadCount);
      }
    };

    // onMessageReceived
    // ======================================================= //
    ChannelHandler.onMessageReceived = (channel, message) => {
      // Check if message sent to current channel
      if (channel.url === currentChannel?.url) {
        setCurrentChannelMessages(prevState => [...prevState, message]);
        currentChannel.markAsRead();
      }

      const updatedList = [...channelsList];
      const indexToUpdate = updatedList.findIndex(c => c.url === channel.url);

      // updated channel list array with new data
      if (indexToUpdate !== -1) {
        updatedList[indexToUpdate].lastMessage = message;
        setChannelsList(updatedList);

        const unreadCount = updatedList.reduce((accum, curr) => accum + curr.unreadMessageCount, 0);
        setTotalUnread(unreadCount);
      }
    };

    // onTypingStatusUpdated
    // ======================================================= //
    ChannelHandler.onTypingStatusUpdated = groupChannel => {
      if (groupChannel.url === currentChannel?.url) {
        const members = groupChannel.getTypingMembers();

        setTypingUsers(members.map(m => m.userId));
        setHasTypingUser(members?.length > 0 ? true : false);
      }
    };

    ChannelHandler.onReadReceiptUpdated = groupChannel => {
      console.log(
        '[Bogi] onReadReceiptUpdated to show when someone read the messages in a group channel',
        groupChannel
      );
      if (isConnected.current) getChannelsList();
    };

    sb.addChannelHandler(`UNIQUE_HANDLER`, ChannelHandler);
  };

  //======================================//
  //======================================//
  const getChannelsList = () => {
    const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    channelListQuery.includeEmpty = true;
    channelListQuery.order = 'latest_last_message';

    if (channelListQuery.hasNext) {
      channelListQuery.next((channelList, error) => {
        if (error) return console.log('channelListQuery => error', error);

        // console.log('channelList', channelList);
        setChannelsList(channelList);

        const unreadCount = channelList.reduce((accum, curr) => accum + curr.unreadMessageCount, 0);
        setTotalUnread(unreadCount);
      });
    }
  };

  const contextValue = {
    // Data
    totalUnread,
    onlineUsers,
    typingUsers,
    channelsList,
    hasTypingUser,
    currentChannel,
    currentChannelMessages,

    // Methods
    sendMessage,
    enterChannel,
    getChannelsList,
    leaveChannel,
  };

  return <ChatContext.Provider children={children} value={contextValue} />;
};

export default ChatContextProvider;
