import React from 'react';
import MemberAvatar from "../Member/Avatar/MemberAvatar";

const DialogTitleAvatar = ({memberData = {}}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      <MemberAvatar size="xsmall" disabled {...memberData} /> <span style={{marginLeft: 10}}>{memberData.firstName} {memberData?.lastName}</span>
    </div>
  )
}

export default DialogTitleAvatar;