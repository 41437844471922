import React from "react";
import { Field } from 'formik';
import { Slider } from "@material-ui/core";

const RangeSlider = ({
  field: { name, value },
  form: { setFieldValue },
  width = 300,
  height,
  ...props
}) => {

  return (
    <div style={{width: width}}>
      <Field
        step={10}
        value={value}
        valueLabelDisplay="auto"
        onChange={(e, newVal) => setFieldValue(name, newVal)}
        component={Slider}
      />
    </div>
  );
}

export default RangeSlider;

