import React, { Component } from 'react';
// import { Grid } from '@material-ui/core';
import { Route } from "react-router-dom";

import Notes from './Notes/Notes';
import styles from './Reports.module.scss';
import ReportsList from './ReportsList/ReportsList';
import DownloadIcon from 'common/assets/download.svg';
import Header from 'common/components/PanelHeader/PanelHeader';
import Filters from 'common/components/PanelFilters/PanelFilters';

const cardObj = function(type, id, name, description) {
    this._id = id;
    this.type = type;
    this.name = name;
    this.description = description;
}

class Reports extends Component {

    state = {
        cards: [
            new cardObj('PATIENT', '1', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('LAB', '2', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('ADMIN', '3', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('COMPLIANCE', '4', 'Progress Notes', 'Patients\'s sobriety based on UDS, Soberlink, and clinician relapse notes.'),
            new cardObj('PATIENT', '5', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('LAB', '6', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('ADMIN', '7', 'Progress Notes', 'Full report of progress notes for a given patient.'),
            new cardObj('COMPLIANCE', '8', 'Progress Notes', 'Patients\'s sobriety based on UDS, Soberlink, and clinician relapse notes.'),
            new cardObj('COMPLIANCE', '9', 'Test limit 8', 'Patients\'s sobriety based on UDS, Soberlink, and clinician relapse notes.'),
        ],
        notesList: [
            {
                date: "Aug 12 at 12:30pm",
                type: "PATIENT",
                author: "Dr. Sara M.",
                noteType: "Call Note",
                text: "Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage.",
            }, {
                date: "Aug 12 at 12:30pm",
                type: "LAB",
                author: "Dr. Sara M.",
                noteType: "Call Note",
                text: "Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage.",
            }, {
                date: "Aug 12 at 12:30pm",
                type: "ADMIN",
                author: "Dr. Sara M.",
                noteType: "Call Note",
                text: "Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage.",
            }, {
                date: "Aug 12 at 12:30pm",
                type: "COMPLIANCE",
                author: "Dr. Sara M.",
                noteType: "Call Note",
                text: "Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage. Today's call with Jim noted that he was considering to quit his job. He's run into consistent conflicts with his colleage.",
            }, 
        ],
        listBreadcrumbs: [{
            name: 'Reports',
            url: '/reports',
        }]
    }

    handleFiltersSubmit = updatedList => {}

    updateBreadcrumbs = (match, update = false) => {
        const { listBreadcrumbs } = this.state;
        const updatedListBreadcrumbs = [...listBreadcrumbs];

        const progressNotes = {
            name: 'Progress Notes',
            url: `${match.path}/progress-notes`
        }

        // Mount  
        if (!update) {
            !match.isExact && updatedListBreadcrumbs.push(progressNotes);
        
            // Update
        } else {
            match.isExact 

                // Root route
                ? updatedListBreadcrumbs.splice(1)

                // Nested route
                : updatedListBreadcrumbs[1] = progressNotes; 
        }
        
        this.setState({ listBreadcrumbs: updatedListBreadcrumbs });
    }

    componentDidMount() {
        this.updateBreadcrumbs(this.props.match);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.isExact !== this.props.match.isExact) {
            this.updateBreadcrumbs(this.props.match, true);
        }
    }

    render() {
        const { cards, listBreadcrumbs, notesList } = this.state;
        const { match } = this.props;

        const overflowMenu = [{
            name: "Edit Patient",
            src: `/patients/{patient._id}/edit/profile`,
        }];

        const savedReportsButton = {
            title: "Save Reports",
            url: "reports/progress-notes"
        };

        const downloadButton = {
            disabled: false,
            title: "Download Report",
            icon: DownloadIcon
        };

        const listButtons = [];

        listButtons.push(match.isExact
            ? savedReportsButton
            : downloadButton);

        const filterFields = {
            patient: {
                label: 'Patient',
                placeholder: 'Choose patients',
                initialValue: [],
                fieldPath: 'type',
                fieldValPath: ''
            },
            type: {
                label: 'Date',
                placeholder: 'Choose a type',
                initialValue: [],
                fieldPath: 'result',
                fieldValPath: ''
            },
        }

        return (
            <div className={styles['view-reports']}>
                
                <Header 
                    listButtons={listButtons}
                    overflowMenu={overflowMenu}
                    listBreadcrumbs={listBreadcrumbs}/>

                <Filters
                    hasSearch
                    data={[{}]}
                    fields={filterFields}
                    onSubmit={this.handleFiltersSubmit}/>
                
                <Route path={match.path} exact component={() => <ReportsList cards={cards}/>} />
                <Route path={`${match.path}/progress-notes`} component={() => <Notes notes={notesList}/>} />

            </div>
        )
    }
}

export default Reports;