import React from 'react';
import styles from './index.module.scss';
import LabIcon from 'common/assets/lab-icon.png';
import Svg from 'react-svg';
const LabActivityDetails = ({ lab }) => {
    let iconColor, resultTitle, statusTitle;
    if (lab.result)
        switch (lab.result) {
            case "INCONCLUSIVE":
                iconColor = "rgba(0,0,0,0.25)"
                resultTitle = "Inconclusive"
                break;
            case "POSITIVE":
                iconColor = "#DB0D41"
                resultTitle = "Positive"
                break;
            case "POSITIVE_DILUTE":
                iconColor = "#DB0D41"
                resultTitle = "Positive (D)"
                break;
            case "NEGATIVE_DILUTE":
                iconColor = "#00B318"
                resultTitle = "Complaint (D)"
                break;
            case "NEGATIVE":
                iconColor = "#00B318"
                resultTitle = "Compliant"
                break;
            case "MISSED":
                iconColor = "rgba(0,0,0,0.25)"
                resultTitle = "Missed";
                break;
            default:
                iconColor = "rgba(0,0,0,0.25)"
                resultTitle = ""
        }
    else {
        iconColor = null;
        resultTitle = null;
        switch (lab.status) {
            case "NEW":
                statusTitle = "Scheduled"
                break;
            case "EXTENDED":
                statusTitle = "Extended"
                break;
            case "EXCUSED":
                statusTitle = "Excused"
                break;
            case "COLLECTED":
                statusTitle = "Collected"
                break;
            case "MISSED":
                statusTitle = "Missed";
                break;
            default:
                resultTitle = null
        }
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(resultTitle || statusTitle) && <img className={styles.listItemIcon} src={LabIcon} />}
            {iconColor && <span className={styles.icon} style={{ backgroundColor: iconColor }} />}
            {resultTitle}
            {statusTitle && <div className={styles.action_text}>{statusTitle}</div>}
        </div>
    )
}

export default LabActivityDetails;